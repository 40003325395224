.ej-conversation-container {
    width: 100%;
}

.ej-conversation-card {
    display: flex;
    padding: 16px 8px;
    flex-direction: column;
    gap: 32px;
    align-self: stretch;
    background: var(--gray-2, var(--color-secondary-02));
    width: 100%;

    &__com &__stats {
        display: flex;
        gap: 0.75rem;

        div {
            display: flex;
            gap: 0.75rem;
        }
    }

    &__title {
        margin-top: 1rem;

        div:first-child {
            color: $gray-color;
            text-transform: uppercase;
        }

        div:nth-child(2) {
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    &__comment {
        color: var(--pure-100, var(--color-secondary-09));
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 0;
        margin: 0;
    }

    &__votes {
        display: flex;
        flex-direction: column;
        gap: 8px;

        button {
            flex: 1;
            gap: 8px;
            text-transform: capitalize;
            padding: 10px;
        }

        button[id="agree"] {
            background-color: $green-color;
            color: $white-color;
        }

        button[id="disagree"] {
            background-color: $red-color;
            color: $white-color;
        }

        button[id="skip"] {
            background: transparent;
            color: var(--gray-80, --gray-80);
            border: 1px solid var(--gray-80);
        }
    }
}

.conversation-header {
    margin-bottom: 40px;
}

.ej-conversation-header {
    &__title {
        color: var(--gray-90);
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__subtitle {
        color: var(--gray-60);
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 1.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.ej-conversation-stats__card {
    display: flex;
    padding: 24px;
    gap: 16px;
    flex: 1;
    border-radius: 4px;
    background: var(--blue-warm-vivid-5);
}

.ej-conversation__general-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;

    i {
        color: var(--blue-warm-vivid-70);
        margin-top: 6px;
    }
}

.ej-conversation-stats__card-content {
    display: flex;
    flex-direction: column;
}

.ej-conversation-card__comments {
    color: var(--gray-60);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Rawline;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.conversation-quantity {
    margin: 16px 0;
}