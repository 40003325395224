.news-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  margin-bottom: 2rem;
}
.news-card-home {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 10px;
  align-items: flex-start;
  height: auto;
  gap: 16px;
  padding: 0 16px;
}
.news-img {
  width: 160px;
  height: 120px;
  border-radius: 10px;
  object-fit: cover;
}
.news-card-home .news-title {
  font-size: 12px;
  font-weight: 600;
  padding: 0;
  margin: 8px 0;
  max-height: 110px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: var(--blue-warm-vivid-80);
}
.news-card-home .news-title:hover {
  color: var(---blue-warm-vivid-70);
}
.centered-text {
  text-align: center;
}
#news-custom-home-section {
  background-color: var(--gray-2);
  text-align: center;
}

#news-custom-home-section h2 {
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .news-container {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 16px;
  }
  .news-card-home {
    width: 100%;
    height: 280px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    padding: 0;
  }
  .news-img {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    object-fit: cover;
  }
  .news-card-home .news-title {
    font-weight: 600;
    padding: 0;
    margin: 0 8px;
    font-size: 1rem;
    text-align: left;
  }

  #news-custom-home-section {
    padding: 2rem;
  }
}
