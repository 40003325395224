.search-filters {
    margin-bottom: 1.5rem;
}

.nav-search-filters {
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--gray-40);
    margin-bottom: 1rem;
    overflow-x: auto;
    white-space: nowrap;
}

.filters__section--general {
    display: flex;
    width: fit-content;
    align-items: center;
}

.filters__section--general button.flex--sbc,
.filters__section--general a.flex--sbc {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 1.25rem !important;
}
 
.filters__section--general button.flex--sbc strong,
.filters__section--general a.flex--sbc strong,
.filters__section--general {
    margin: 0;
    padding: 0;
}

.filters__section--general .flex--sbc strong{
    font-size: var(--font-size-scale-up-01);
    color: var(--gray-40);
    text-decoration: none;
}

.divider-search{
    width: 100%;
    height: 1px;
    background: var(--gray-20);
    margin: 10px;
    margin-top: 22px;
    margin-bottom: 22px;
}

.filters__section--general .flex--sbc:hover {
    font-size: var(--font-size-scale-up-01);
    color: var(--gray-40);
    text-decoration: underline;
}

.filters__section--general.selected-section {
    border-bottom: 4px solid var(--blue-warm-vivid-70);
}

.filters__section--general.selected-section .flex--sbc strong {
    font-weight: var(--font-weight-semi-bold);
    color: var(--blue-warm-vivid-90);
}

.filters__section--general.selected-section .flex--sbc span {
    color: var(--blue-warm-vivid-70);
}

/* Custom scrollbar styles for WebKit browsers */
.nav-search-filters::-webkit-scrollbar {
    height: 0.25rem; /* Height of the horizontal scrollbar */
}

.nav-search-filters::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
}

.nav-search-filters::-webkit-scrollbar-thumb {
    background: var(--gray-40); /* Color of the scrollbar thumb */
    border-radius: 10px; /* Round corners of the scrollbar thumb */
}

.nav-search-filters::-webkit-scrollbar-thumb:hover {
    background: var(--gray-70); /* Color of the scrollbar thumb when hovered */
}

.search-result-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
}

.search-result-section div {
    display: flex;
    align-items: center;
    font-size: var(--font-size-scale-up-01);
}

.search-result-section div p,
.search-result-section div > span {
    font-size: var(--font-size-scale-up-01);
    margin: 0;
}

.search-result-section div p strong,
.search-result-section div > span {
    font-weight: var(--font-weight-semi-bold);
    color: var(--gray-70);
}

.search-result-section div > span {
    margin-right: 0.5rem;
}

.search-result-section > h1 {
    font-size: 3.6rem;
    font-weight: var(--font-weight-semi-bold);
    color: var(--blue-warm-vivid-80);
    margin: 0;
}

.searches-input {
    margin-top: 1.25rem;
}

.search-see-all {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 1rem;
}