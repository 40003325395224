.logos__list-container a img {
  width: 160px;
}

.logos__list-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}

.logos-section h2 {
  font-size: 2.25rem;
  font-weight: var(--font-weight-bold);
  padding-bottom: 0;
  margin: 0;
}  

@media (min-width: 768px) {
  .logos__list-container a img {
    width: 160px;
  }
  .logos-section h2 {
    padding-bottom: 0.75rem;
    font-weight: var(--font-weight-semi-bold);
  }  
  .logos__list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    align-items: center;
  }
}