.participatory_cards-container {
  display: flex;
  justify-content: center;
  background-color: var(--blue-warm-60);
}

.participatory_cards-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 4.5rem;
  max-width: 1140px;
  gap: 2rem;
}

.participatory_cards-title {
  color: var(--pure-0);
  text-align: center;
  font-size: 2.25rem !important;
  font-weight: var(--font-weight-bold);
  padding: 0;
  margin: 0;
}

.participatory_cards-subtitle {
  font-size: 1.125rem;
  color: var(--pure-0);
  font-weight: var(--font-weight-regular);
  text-align: center;
  margin: 0;
}

.cards_icon-container {
  display: flex;
  min-width: 60px;
  min-height: 60px;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  border-radius: 4px;
  background: var(--pure-0);
}

.cards_icon-container .fa-solid {
  color: var(--blue-warm-80);
  font-size: 1.5rem;
}

.cards_info-container {
  display: flex;
  padding: 0 80px;
  justify-content: space-around;
  align-items: stretch;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 3.375rem;
}

.cards_info-content {
  display: flex;
  align-items: normal;
  // max-height: 60px;
  max-width: 400px;
  gap: 0.75rem;
  background: var(--pure-0);
  padding: 1rem;
  border-radius: 0.5rem;
  flex-grow: 1;
}

.cards_info-content:hover {
  transform: scale(1.025);
  transition: transform 0.3s, box-shadow 0.2s;
}


.cards_info-text {
  display: flex;
  flex-direction: column;
  white-space: wrap;
  overflow: hidden;
  gap: 0.5rem;
}

.cards_info-content h3 {
  color: var(--blue-warm-vivid-80);
  font-size: 1.5rem;
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  padding: 0;
  margin: 0;
}

.cards_info-content p {
  color: var(--blue-warm-vivid-80);
  font-size: 1.5rem;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
  margin: 0;
  word-break: break-all;
}

.blue-bp-divider {
  display: flex;
  width: 100%;
  height: 35px;
  background-image: url('https://brasilparticipativo.presidencia.gov.br/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcTVXIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--958ded6942dd87524d5b1852502383e4a10bbd3c/faixa-azul%20(1).png');
  background-size: cover;
  background-repeat: repeat;
  filter: brightness(0) saturate(100%) invert(33%) sepia(19%) saturate(1551%) hue-rotate(175deg) brightness(98%) contrast(95%);
}

@media (max-width: 768px) {
  .cards_info-container {
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex-wrap: nowrap;
    gap: 3.375rem;
  }
}