.map_location_address {
  font-size: 1rem;
  font-family: Rawline;
  color: #000;
}

.address__arrive_title {
  color: var(--blue-warm-vivid-80);
  margin: 0;
}

.address__info {
  display: flex;
  flex-grow: 1;
}

.address__details_container {
  float: left;
  max-width: 100%;
  align-self: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card__content,
.badge-card__content {
  padding: 1.5rem;
  background: var(--pure-0);
}

.address__map_container {
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.static__map-map {
  width: auto;
  height: 10.875rem;

  @media (min-width: 768px) {
    width: 26rem;
  }
}

.foreign_input input {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3E%3C!--%21Font%20Awesome%20Free%206.5.2%20by%20%40fontawesome%20-%20https%3A//fontawesome.com%20License%20-%20https%3A//fontawesome.com/license/free%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20fill='%230C326F'%20d='M256%2064C150%2064%2064%20150%2064%20256s86%20192%20192%20192c17.7%200%2032%2014.3%2032%2032s-14.3%2032-32%2032C114.6%20512%200%20397.4%200%20256S114.6%200%20256%200S512%20114.6%20512%20256v32c0%2053-43%2096-96%2096c-29.3%200-55.6-13.2-73.2-33.9C320%20371.1%20289.5%20384%20256%20384c-70.7%200-128-57.3-128-128s57.3-128%20128-128c27.9%200%2053.7%208.9%2074.7%2024.1c5.7-5%2013.1-8.1%2021.3-8.1c17.7%200%2032%2014.3%2032%2032v80%2032c0%2017.7%2014.3%2032%2032%2032s32-14.3%2032-32V256c0-106-86-192-192-192zm64%20192a64%2064%200%201%200-128%200%2064%2064%200%201%200%20128%200z'%20/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: left 0.5rem center !important;
  background-size: 16px !important;
  padding: 2rem !important;
  background-color: var(--gray-2) !important;
  border-color: transparent !important;
  box-shadow: none !important;
  margin: 1rem 0 0.5rem 0 !important;
  color: var(--gray-80);
  font-size: var(--font-weight-medium);
  font-weight: var(--font-weight-medium);

  &::placeholder {
    color: #0c326f80 !important;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Rawline;
    font-size: 0.875rem !important;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
}

.foreign_input input:hover {
  background-color: var(--gray-20) !important;
}

.foreign_input label {
  font-weight: var(--font-weight-semi-bold);
  color: var(--blue-warm-vivid-80);
  font-size: var(--font-size-scale-up-01);
}

.foreign_input label span {
  text-align: end;
  font-weight: 300;
  margin: 0;
  font-size: 0.875rem;
  color: var(--blue-warm-vivid-80);
}

.new-proposal-form.foreign-form {
  margin-bottom: 2rem;
}

.foreign_label {
  font-weight: var(--font-weight-semi-bold);
  color: var(--blue-warm-vivid-80);
  font-size: var(--font-size-scale-up-01);
}

#user_document_image_button {
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-2);
  color: var(--blue-warm-vivid-80);
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1);
}

.submit-foreign-form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}

.submit-foreign-form-container.help-buttons {
  align-items: center;

  & > a {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    color: var(--blue-warm-vivid-80);
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: none;
    transition: background-color 0.2s;
    &:hover {
      background-color: var(--gray-10);
    }
  }
}

.receive-informs {
  background-color: var(--blue-warm-vivid-5);
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 1.5rem;
  width: 100%;
}

.submit-foreign-form-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h3 {
    font-weight: 400;
    color: var(--blue-warm-vivid-80);
    font-size: 1.5rem;
    margin: 0;
  }
  p {
    margin: 0;
  }
}

.new-proposal-form .field.select-field-br > label select,
.new-meeting-form .meetings_form.new_meeting .field.select-field-br > label select {
  height: 63px;
  width: 100%;
  border-radius: 10px;
  margin-top: 0.25rem;
  background-color: #f6f6f6;
  padding: 8px 16px;
  border: 0;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2221%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%3E%0A%20%20%3Cpath%20d%3D%22M9.35263%2015.289L1.93529%207.87167C1.57756%207.51394%201.57756%206.93397%201.93529%206.57627L2.8004%205.71116C3.15753%205.35404%203.73632%205.35335%204.09428%205.70963L10.0003%2011.588L15.9064%205.70963C16.2643%205.35335%2016.8431%205.35404%2017.2002%205.71116L18.0654%206.57627C18.4231%206.93401%2018.4231%207.51398%2018.0654%207.87167L10.6481%2015.289C10.2903%2015.6467%209.71036%2015.6467%209.35263%2015.289Z%22%20fill%3D%22%230C326F%22%2F%3E%0A%3C%2Fsvg%3E") !important;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 16px;
}

.new-meeting-form .meetings_form.new_meeting .field.select-field-br > label select {
  margin-top: 1rem;
}

.new-proposal-form .field.select-field-br > label,
.new-meeting-form .meetings_form.new_meeting .field.select-field-br > label {
  color: var(--blue-warm-vivid-80);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-semi-bold);
  position: relative;
  display: inline-block;
  width: 100%;
}

.presential-meeting-inputs,
.proposal-address-inputs {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.25rem;
  background: var(--blue-warm-vivid-5);
}

.presential-meeting-inputs textarea,
.proposal-address-inputs textarea {
  background-color: var(--pure-0) !important;
}

.presential-meeting-inputs .field,
.proposal-address-inputs .field {
  width: 100%;
}

.presential-meeting-inputs {
  margin-top: 24px;
}

#presential-meeting .new-meeting-title,
.presential-meeting-inputs input,
.proposal-address-inputs input {
  margin-top: 24px !important;
}

.formbuilder-select.field-forma-org > label.formbuilder-select-label {
  color: #0c326f;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  width: 100%;
}

.new-proposal-form .rendered-form .formbuilder-select select.form-control {
  height: auto;
  overflow: auto;
  width: 100%;
  border-radius: 10px;
  margin-top: 0.25rem;
  background-color: #f6f6f6;
  padding: 8px 16px;
  border: 0;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 16px;
}

.new-proposal-form .rendered-form .formbuilder-checkbox-group:has(> .checkbox-group > .formbuilder-checkbox:only-child) {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
}