.inline-filters{
  @include flexgap($global-margin * 2);

  // Override gap display property
  display: inline-flex;
  justify-content: space-between;
  text-align: left;

  label{
    display: flex;
    align-items: baseline;

    @include flexgap($global-margin * .5);

    span{
      white-space: nowrap;
    }
  }

  $dropdown-width: 12rem;

  button[data-toggle]{
    color: $secondary;
    margin: 0;
    overflow: hidden;
    width: 100%;

    @extend select;

    &.hover{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .dropdown-pane{
    padding: 0;
    background-color: $white;
    width: $dropdown-width;

    li{
      a{
        display: block;
        padding: $global-padding;

        &:hover{
          text-decoration: underline;
        }
      }

      &:not(:last-child){
        border-bottom: $border;
      }

      &:hover{
        cursor: pointer;
        background-color: rgba($secondary, .05);
      }
    }

    &.is-open{
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}