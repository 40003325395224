.collapse-item {
    font-size: 16px;
}

.collapse-title {
    padding-left: 16px;
}

.collapse-item-content, .collapse-text {
    padding: 16px 0px 16px 0px;
    font-size: 16px;
}