.blog-title {
  font-size: 1.25rem !important;
  line-height: 1.65rem;
  font-weight: 600;
  color: var(--blue-warm-vivid-80);
  padding-bottom: 10px;
  margin: 0;
}

.blog-subtitle {
  font-size: 0.925rem !important;
  font-weight: 400;
  color: #555;
  line-height: normal;
  margin: 0;
  padding: 0.5rem 0 2rem;
}

.blog-content .section {
  margin: 2rem 0;
}

.ql-editor-display p img {
  display: flex;
  max-width: 250px;
  margin: 0 auto;
} 

.blog-content .section .ql-editor-display p {
  font-size: 16px;
}

.blog-content .section .ql-editor-display {
  min-height: 4.375rem;
}

.blog-content .section .ql-editor-display p:first-child:first-letter {
  color: var(--blue-warm-vivid-70);
  font-size: 3rem;
  display: block;
  font-weight: 700;
  float: left;
  line-height: 2rem;
  margin-right: 20px;
  text-transform: uppercase;
}

.blog-image-cover-container {
  display: flex;
  justify-content: center;
}

.news-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0;
  padding: 0;
}
.news-cover-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}
.news-item {
  list-style: none;
}
.news-card {
  display: flex;
  justify-content: left;
  align-items: start;
  gap: 16px;
}
.news-content {
  flex: 1;
}
.news-title,
.news-title a {
  display: block;
  margin: 0;
  padding: 0 0 0.5rem 0;
  line-height: 1.25rem;
  font-weight: 500;
  font-size: 1rem;
}
.news-title:hover,
.news-title a:hover {
  color: var(--blue-warm-vivid-50);
}
.br-card-content {
  padding: 16px 16px 0 16px;
  color: var(--pure-100);
}
.news-date {
  color: var(--gray-70);
}
.br-card-title {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-scale-up-02);
}
.br-card-date {
  font-size: var(--font-size-scale-down-01);
}

.social-links {
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.social-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  align-items: center;
  padding: 12px 14px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2.5rem;
  gap: 1rem;
}

.align-end {
  justify-content: flex-end;
}

.date-post {
  justify-content: flex-start;
}
.social-links label {
  font-size: 12px;
}
.news-list ul {
  margin: 0;
}
.pagination-container {
  margin: 1.5rem 0;
}

@media (min-width: 768px) {
  .blog-content .section .ql-editor-display p:first-child:first-letter {
    font-size: 5rem;
    line-height: 4rem;
  }

  .ql-editor-display p img  {
    display: flex;
    max-width: 648px;
    margin: 0 auto;
  }
  
  .news-cover-image {
    width: 200px;
    height: 133px;
    object-fit: cover;
  }

  .social-container {
    flex-direction: row;
  }

  .social-container:last-child {
    justify-content: flex-end;
  }

  .blog-title {
    font-size: 2.25rem !important;
    line-height: 2.65rem;
  }

  .blog-subtitle {
    font-size: 1.125rem !important;
  }
}
