.conversation-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 352px);
    grid-gap: 24px;
    justify-content: center;
}

.conversation-card {
    box-shadow: 0px 3px 6px 0px #33333329;
    display: flex;
    flex-direction: column;
    padding: 24px 16px 16px 16px;
    border-radius: 4px;
    border-top: solid 8px;
    border-top-color: var(--blue-warm-vivid-60);
    gap: 16px;

    >.conversation-card-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        color: var(--blue-warm-vivid-70);
    }

    >.conversation-profile-info {
        display: flex;
        gap: 8px;
        margin-top: auto;

        >img {
            border-radius: 100%;
            width: 20px;
            height: 20px;
        }

        >p {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0px;
            color: var(--blue-warm-vivid-70);
        }
    }

    >.conversation-theme {
        padding: 4px 8px;
        margin: 8px 0px;
        background: var(--gray-2);

        >p {
            color: var(--blue-warm-vivid-70);
            margin: 0;
            font-size: 12px;
            font-weight: 400;
        }
    }

    >.conversation-participants {
        display: flex;
        flex-direction: column;
        align-items: center;

        >p:first-child {
            margin-bottom: 4px;
            font-size: 20px;
            font-weight: 600;
        }

        >p:last-child {
            font-size: 14px;
            font-weight: 400;
        }
    }

    >.conversation-progress {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 16px 0;

        >p {
            margin-bottom: 8px;
            color: var(--blue-warm-vivid-80);
            font-size: 14px;
            font-weight: 600;
        }

        >.conversation-progress-bar {
            height: 10px;
            width: calc(100% + 32px);
            border-top: solid 1px;
            border-bottom: solid 1px;
            border-color: var(--gray-10);

            >.percentage-bar {
                height: 100%;
                background: var(--blue-warm-vivid-60);
            }
        }
    }
}