.percentage-container {
    display: flex;
    flex-direction: column;
}

.percentage-title {
    color: var(--blue-warm-vivid-80);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Rawline;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    line-height: normal;
    margin: 0;
}

.percentage-bar {
    background-color: var(--blue-warm-vivid-60);
    height: 10px;
    flex-shrink: 0;
}