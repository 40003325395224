

.survey_ended_warning {
  background-color: #555555;
  color: #fff;
  padding: 4px;
  width: 100%;
}


.ej-survey-background {
  padding: 16px 0;
}

.margin-top-2 {
  margin-top: 2rem;
}

.pie-chart-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.votes-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.vote-row {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.ej-conversation__general-stats .fa-thumbs-up {
  color: #7FB135;
}

.ej-conversation__general-stats .fa-thumbs-down {
  color: #FB5A47;
}

.ej-pie-chart-card {
  justify-content: center;
}

@media (min-width: 740px) {
  .ej-conversation__general-stats {
      align-items: stretch;
  }

  .ej-conversation-stats__card {
    align-items: center;
}
}