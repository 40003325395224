/* Variables */
$comment-padding: 1rem;

$comment-vote-up-color: $success;
$comment-vote-down-color: $alert;

$comment-nested-bg: $light-gray;
$comment-nested-alt-bg: $white;

/* Comments */

.comments {
  padding-top: 3rem;
}

.comment-thread {
  @extend .card;

  .show-comment-replies {
    display: none;
  }

  .hide-comment-replies {
    display: inline;
  }

  .comment__hide {
    float: left;
    margin-bottom: 0;
  }

  .no-comments {
    .show-comment-replies {
      display: inline;
    }

    .hide-comment-replies,
    .replies {
      display: none;
    }
  }
}

.comment-thread__title {
  font-weight: normal;
  font-size: 90%;
  text-transform: none;
  color: $muted;
}

.comment__header {
  padding: $comment-padding;
  display: flex;
  align-items: center;

  .comment__edited,
  .comment__moderated,
  .comment__deleted {
    font-style: italic;
    color: $muted;
  }

  &__context-menu {
    position: relative;
    margin-left: auto;

    label {
      transform: rotate(90deg);
      cursor: pointer;
    }

    &__content {
      list-style-type: none;
      width: max-content;
      min-width: 180px;
      padding: 0;
      margin: 0;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
      overflow: hidden;

      &-item {
        padding: $comment-padding * 0.5 $comment-padding;
        display: flex;
        align-items: baseline;
        gap: $comment-padding;
        cursor: pointer;

        span {
          line-height: $paragraph-lineheight;
        }
      }
    }

    &__content li:hover {
      background-color: $light-gray-dark;
    }

    &__content li:not(:first-child) {
      border-top: 1px solid $medium-gray;
    }
  }
}

.comment__content {
  padding: 1rem 0 0;
  overflow-wrap: break-word;
  hyphens: auto;

  > :last-child {
    margin-bottom: 0;
  }

  p:empty {
    display: none;
  }
}

.comment__additionalreply {
  padding: $comment-padding;
  font-size: 90%;

  @include clearfix;
}

.comment__footer {
  padding: $comment-padding;
  font-size: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
}

.comment--nested {
  background: var(--gray-3);
  padding: 16px;

  &.comment--nested--alt {
    background: $comment-nested-alt-bg;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.comment__actions {
  display: flex;
  flex-wrap: wrap;

  > button,
  a {
    cursor: pointer;
    margin-top: 0.5rem;
  }
}

.comment__reply,
.comment__reply[data-toggle] {
  float: left;
  color: $muted;
  margin-right: 0.75rem;
  margin-bottom: 0;

  span {
    margin-right: 0;
  }

  &.muted-link {
    color: $anchor-color;

    &:hover {
      color: $anchor-color-hover;
      text-decoration: none;
    }
  }
}

.comment__votes {
  float: right;
  margin-top: 0.5rem;

  form.button_to {
    display: inline-block;
  }
}

.comment__votes--up {
  color: $muted;

  .icon {
    color: $comment-vote-up-color;
  }

  &:hover,
  &.is-vote-selected {
    color: darken($comment-vote-up-color, 10);

    .icon {
      color: inherit;
    }
  }

  &.is-vote-notselected {
    color: $muted;
    opacity: 0.3;

    .icon {
      color: inherit;
    }
  }
}

.comment__votes--down {
  color: $muted;
  padding-left: 0.3rem;

  .icon {
    color: $comment-vote-down-color;
  }

  &:hover,
  &.is-vote-selected {
    color: darken($comment-vote-down-color, 10);

    .icon {
      color: inherit;
    }
  }

  &.is-vote-notselected {
    color: $muted;
    opacity: 0.3;

    .icon {
      color: inherit;
    }
  }
}

.comment__quote {
  border-left-width: 6px;
  font-size: 80%;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 1rem;
  margin-bottom: 1rem;

  > p:last-of-type {
    margin-bottom: 0;
  }

  > br {
    display: none;
  }
}

/* Comment form */

.comment__quote {
  border-left-width: 6px;
  font-size: 80%;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 1rem;
  margin-bottom: 1rem;

  > p:last-child {
    margin-bottom: 0;
  }
}

.add-comment {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 1rem;

  a:hover {
    text-decoration: underline;
  }

  .button {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    padding-right: 1rem;
  }
}

.add-comment-bg {
  border-radius: 10px;
  padding: 0;
}

.add-comment--reply {
  display: none;

  &.is-active {
    display: block;
  }
}

/* Comments toggle */

.comment {
  &__text-is-open {
    display: none;
  }

  &__is-open &__text-is-open {
    display: inline;
  }

  &__is-open &__text-is-closed {
    display: none;
  }
}

.comments-extra-info {
  display: none;
}

.comment-threads.participatory-text-initial-page {
  .comments-extra-info {
    display: block;
    background-color: var(--gray-3);
    padding: 0.5rem;
  }
}
