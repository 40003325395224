.br-switch input[type="checkbox"] + label::after {
  align-items: center;
  color: var(--color-dark);
  content: "\F00D";
  display: inline-flex;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-size: var(--switch-icon-size);
  font-weight: 900;
  justify-content: center;
}
.br-switch input[type="checkbox"]:checked + label::after {
  content: "\F00C";
}
.br-switch input[type="checkbox"]:checked:disabled + label::after {
  content: "\F023";
}
.br-cookie-modal {
  border-radius: 10px;
}
.br-cookie-group {
  font-weight: 600;
  color: var(--gray-80);
}
.br-cookie-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.bottom-cookies-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom-cookies {
  background-color: rgba(7, 29, 65, 0.8);
  color: var(--pure-0);
  width: 100%;
  position: fixed;
  bottom: 0;
}
.bottom-button-cookies {
  background-color: transparent !important;
}
.settings-bottom-cookies{
  gap: 5px;
}
.text-cookies-bottom {
  color: var(--pure-0);
  text-align: justify;
}

.cookies-button-container {
  flex-direction: column;
  gap: 8px;
 }

@media (min-width: 800px) {
 .bottom-cookies-container {
  flex-direction: row;
 }
 .cookies-button-container {
  flex-direction: row;
 }
}
