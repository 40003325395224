.description_cards-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 4rem 0;
}

.description_cards-title {
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
  color: var(--blue-warm-vivid-80);
  text-align: center;
  padding: 0;
  margin: 0;
}

.description_cards-subtitle {
  font-size: 1.125rem;
  color: var(--blue-warm-vivid-80);
  font-weight: var(--font-weight-regular);
  text-align: center;
  margin: 0;
}

.description_cards {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.description_card {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-1);
  max-width: 369px;
  min-width: 280px;
  width: auto;
  gap: 1rem;
}

.desc_card_label {
  background-color: var(--blue-warm-vivid-80);
  border-radius: 4px 4px 0 0;
  padding: 10px;
  font-size: var(--font-size-scale-down-01);
  font-weight: var(--font-weight-bold);
  color: var(--pure-0);
  line-height: 16.06px;
  text-align: center;
  margin: 0;
}

.desc_card_title {
  font-size: var(--font-size-scale-up-02);
  color: var(--blue-warm-vivid-80);
  padding: 0 1rem;
  font-weight: 600;
  line-height: 27.81px;
  text-align: left;
  margin: 0;
}

.desc_card_text {
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-regular);
  color: var(--pure-100);
  padding: 0 1rem;
  line-height: 19.38px;
  text-align: left;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
}

@media(max-width: 768px) {
  .description_cards-container h2 {
    font-size: 1.5rem;
  }

  .description_cards {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    padding-bottom: 1rem;
  }

  .description_card {
    width: 280px;
  }
}