.ra-button {
    padding: .3em .9em; 
    border-radius: .25em; 
    background: linear-gradient(#fff, #efefef); 
    box-shadow: 0 1px .2em gray; 
    display: inline-flex; 
    align-items: center; 
    cursor: pointer;
}

.ra-button img {
    height: 1em; 
    margin: 0 .5em 0 0;
}

#tts-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    position: fixed;
    max-width: 95vw;
    right: 0;
    top: 40%;
    transform: translateY(-50%);
    z-index: 99999999;
    margin: 10px !important;
}

#tts-button-icon {
    display: flex;
    width: 40px;
    height: auto;
}

#tts-container:hover #tts-question {
    display: flex;
}

#tts-question {
    display: none;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #1D8ADA;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    cursor: pointer;
}