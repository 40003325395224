.search-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px 0 0 0;
    margin-bottom: 40px;

    .search-input-wrapper {
        position: relative;
        width: 100%;
        margin-bottom: 40px;

        .search-button {
            border-radius: 1000px;
            position: absolute;
            top: 12px;
            right: 16px;
            color: var(--blue-warm-vivid-70);
            line-height: 32px;

            &:hover {
                background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)),
                        rgba(var(--interactive-rgb), var(--hover)));
            }
        }

        .search-input {
            height: 56px;
            width: 100%;
            background: var(--gray-2);
            border: 0;
            border-radius: 8px;
            padding: 20px 24px 16px 24px;
            margin-bottom: unset;
        }
    }
}

.search-status {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 40px;

    .search-status-text {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 16px;
        color: var(--gray-70);
    }

    .search-status-filters {
        display: flex;

        div:first-child label {
            font-weight: 600;
        }
    }
}