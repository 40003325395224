@import url("https://fonts.cdnfonts.com/css/rawline");

:root {
  --primary: var(--blue-warm-vivid-70);
  --banner-mobile: url("https://brasilparticipativo.presidencia.gov.br/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdmdxIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3ba97ce1e4e0cd6000149c10d9aa645a24751779/ppa_memorias_mob.jpg");
  --banner-desktop: url("https://brasilparticipativo.presidencia.gov.br/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdmNxIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a0b3ac30a4b6baa1294c0e1bfc3baf29bd62fcaa/ppa_memorias_desk.jpg");

  --hover: 0.16;
  --interactive-rgb: 19, 81, 180;
  --interactive-rgb-primary-btn: 255, 255, 255;
  --color-rgb: 51, 51, 51;

  /* Colors: */
  ---white: #ffffff;

  /* Font/text values */
  --unnamed-font-family-rawline: "rawline";
  --unnamed-font-family-font-awesome-5-free: Font Awesome 5 Free;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-normal: normal;

  // Valores inteiros spacing
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-25: 25px;
  --unnamed-line-spacing-40: 40px;
  --unnamed-line-spacing-60: 60px;
  --unnamed-line-spacing-120: 120px;

  // Valores reais spacing
  --unnamed-line-spacing-20-299999237060547: 20.299999237060547px;
  --unnamed-line-spacing-23-18400001525879: 23.18400001525879px;
  --unnamed-line-spacing-16-18000030517578: 16.18000030517578px;
  --unnamed-line-spacing-19-31999969482422: 19.31999969482422px;
  --unnamed-line-spacing-14-09000015258789: 14.09000015258789px;
  --unnamed-line-spacing-27-809999465942383: 27.809999465942383px;
  --unnamed-line-spacing-57-68000030517578: 57.68000030517578px;
  --unnamed-line-spacing-40-060001373291016: 40.060001373291016px;
  --unnamed-line-spacing-33-380001068115234: 33.380001068115234px;
  --unnamed-line-spacing-48-06999969482422: 48.06999969482422px;

  --input-medium: 40px;
  --input-size: var(--input-medium);
}

/* Character Styles */

.heading3 {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-scale-up-04);
  line-height: var(--unnamed-line-spacing-33-380001068115234);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--gray-80);
}

.heading2 {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--font-size-scale-up-02);
  line-height: var(--unnamed-line-spacing-23-18400001525879);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--gray-80);
}

.wrapper {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.heading4 {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--font-size-scale-up-02);
  line-height: var(--unnamed-line-spacing-23-18400001525879);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--gray-80);
}

p {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--font-size-scale-base);
  line-height: var(--unnamed-line-spacing-20-299999237060547);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--gray-80);
}

.blog-content p,
.blog-content h1,
.blog-content h2,
.blog-content h3,
.blog-content strong {
  color: var(--gray-70) !important;
}

a,
a:hover,
.card__link {
  color: var(--blue-warm-vivid-70);
  text-decoration: none;
}

.menu a,
.menu .button {
  padding: 0;
}

.button {
  --button-radius: 100em;
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;

  border: 1px solid var(--blue-warm-vivid-70);
  text-transform: none;
  align-items: center;
  border: 0;
  border-radius: var(--button-radius);
  color: var(---white);
  cursor: pointer;
  display: inline-flex;
  font-weight: var(--font-weight-semi-bold);
  height: var(--button-medium);
  justify-content: center;
  overflow: hidden;
  padding: 0 var(--button-xsmall);
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: wrap;
  width: auto;
  gap: 10px;
  line-height: 1.1rem;
  margin: 0;
}

.button:hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb-primary-btn), var(--hover)),
    rgba(var(--interactive-rgb-primary-btn), var(--hover))
  );
}

.topbar__search .input-group-button,
.filters__search .input-group-button {
  position: relative;
  height: 1px;
  vertical-align: top;
  background: var(--gray-2);
  padding: 6px 4px 0 4px;
}

.topbar__search:not(:focus-within) button,
.topbar__search:focus-within button,
.filters__search:not(:focus-within) button,
.filters__search:focus-within button {
  background: var(--gray-2);
}

::placeholder {
  color: var(--gray-80);
  font-style: italic;
  font-weight: var(--font-weight-light);
  opacity: 1;
}

.filters__search .input-group-button button .icon {
  width: 0.875em;
  height: 0.875em;
  fill: var(--blue-warm-vivid-70) !important;
}

.card--secondary {
  background: var(---white);
}

.topbar__search input,
.topbar__search input:focus,
.filters__search input,
.filters__search input:focus,
.input-group input,
.input-group input:focus {
  background: var(--gray-2);
  border-radius: var(--surface-rounder-sm) 0 0 var(--surface-rounder-sm);
  border-style: var(--border-style);
  border-width: 0px;
  color: var(--gray-80);
  display: block;
  font-size: var(--font-size-scale-base);
  line-height: var(--unnamed-line-spacing-23-18400001525879);
  font-weight: var(--font-weight-light);
  height: var(--input-size);
  padding-bottom: 0;
  padding-left: var(--spacing-scale-2x);
  padding-right: var(--spacing-scale-2x);
  padding-top: 0;
  width: 100%;
  box-shadow: none;
  border: 0;
}

.topbar__search button,
.filters__search .input-group .input-group-button button {
  background: var(--gray-2);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
}

.button-wrapper button svg {
  color: var(--blue-warm-vivid-70);
}

body {
  font-family: "rawline", sans-serif !important;
  color: var(--gray-80);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "rawline", sans-serif !important;
}

.heading-small {
  font-family: "rawline", sans-serif;
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--font-size-scale-down-01);
  line-height: var(--unnamed-line-spacing-16-18000030517578);
  letter-spacing: var(--unnamed-character-spacing-0);
}

.external-link-container > .external-link-indicator {
  height: 0.75rem;
  width: 0.75rem;
  margin: 0 4px -1px 0.175rem;
}

.input-group-button {
  background: var(--gray-2);
  padding: 0 6px;
}

.gallery__container {
  border: none !important;
  margin: 0 !important;
}
.gallery__container .column {
  padding-right: 0px;
  padding-left: 0px;
}

.input-group-button button {
  height: 2.5rem;
  padding: 0;
  background: var(--gray-2);
  height: 30px;
  width: 30px;
  border-radius: 50% !important;
}

.input-group-button button:hover {
  filter: brightness(95%);
  text-decoration: none;
  background-color: var(--gray-2);
}

.input-group-button button svg {
  fill: var(--blue-warm-vivid-70) !important;
}

.text-highlight {
  color: var(--gray-80);
  text-align: center;
  opacity: 1;
  text-shadow: none;
  margin: none;
}

.columns .heading3 {
  font-family: var(--unnamed-font-family-rawline);
  font-style: italic;
  font-weight: 500;
  font-size: var(--font-size-scale-up-02);
  line-height: var(--unnamed-line-spacing-23-18400001525879);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--gray-80);
}

.columns ul li {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-scale-up-01);
  line-height: var(--unnamed-line-spacing-19-31999969482422);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--gray-80);
}

.m-bottom {
  margin-bottom: 2rem;
}

.m-bottom.small {
  margin-bottom: 1.5rem;
}

.m-bottom a {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: 600;
  font-size: var(--font-size-scale-up-01);
  line-height: var(--unnamed-line-spacing-19-31999969482422);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--blue-vivid-50);
  border-radius: 20px;
  padding: 0.5rem 1rem;
  margin: 2rem 0;
}

.m-bottom a:hover {
  background: var(--blue-10);
}

.m-bottom a svg {
  margin: 0 8px;
}

.wrapper .view-header {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-scale-up-01);
  line-height: var(--unnamed-line-spacing-19-31999969482422);
  letter-spacing: var(--unnamed-character-spacing-0);
  margin-bottom: 0;
}

.view-header .author--inline {
  padding: 1rem;
  color: var(--blue-vivid-50);
}

.view-header .author-data__extra {
  font-weight: var(--font-weight-light);
  display: inline-flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  max-width: 500px;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.title-action {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
}

.title-action__action .icon {
  width: 11px;
  height: 11px;
  margin: 0.1rem 0 0 0.5rem;
}

.button.hollow {
  color: var(--blue-warm-vivid-70);
  border-color: var(--blue-warm-vivid-70);
  background-color: transparent;
  text-decoration: none;
  padding: 10px 20px;
  min-width: 180px;
}

.br-profile-buttons {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.button.hollow:hover {
  color: var(--blue-warm-vivid-70);
  border-color: var(--blue-warm-vivid-70);
  background-color: rgba(var(--interactive-rgb), var(--hover));
  text-decoration: none;
  padding: 10px 20px;
  min-width: 180px;
}

.author-data__extra > button,
.author-data__extra > span,
.author-data__extra > a {
  margin-right: 0;
}

.close-button:hover,
.close-button:focus {
  color: var(---white);
}

/* INICIAL PAGE */

.hero-heading {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--font-weight-semi-bold) !important;
  font-size: var(--font-size-scale-up-05);
  line-height: var(--unnamed-line-spacing-48-06999969482422);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--gray-80);
  text-align: center;
  opacity: 1;
}

.hero__container div div a {
  background: var(--green-cool-vivid-50) 0% 0% no-repeat padding-box;
  color: var(---white);
  text-transform: none;
  border: 12px solid var(--gray-cool-2);
  padding: 12px;
  box-shadow: 0px 1px 6px #00000029;
  margin: 0 auto;
}

.hero__container div div a:hover {
  opacity: 1;
  border: 12px solid var(---white);
  background: var(--green-cool-vivid-50) 0% 0% no-repeat padding-box;
}

.hero-cta {
  padding: 0px;
  margin: 0px;
}

.home-section:nth-child(2n) {
  background-color: var(--gray-3);
}

.home-section:nth-of-type(2n + 1) {
  background-color: var(---white);
}

.home-stats__highlight .statistic__data {
  padding: 1.5rem 1rem;
  background: var(---white);
  min-width: 280px;
  min-height: 69px;
}

.home-stats__highlight .statistic__number {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: 400 !important;
  font-size: 1.5rem;
  line-height: var(--unnamed-line-spacing-57-68000030517578);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--blue-warm-vivid-80);
}

.statistics-cell .statistic__title {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  line-height: "normal";
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--blue-warm-vivid-80);
  font-weight: var(--font-weight-semi-bold) !important;
  font-size: var(--font-size-scale-up-02);
  text-transform: none;
}

.home-stats__lowlight .statistic__data {
  margin: 0 auto;
}

.definition-data__title {
  text-transform: none;
  font-size: 1rem;
  color: var(--gray-90);
}
.extra__suport-text,
.progress__bar.progress__bar--vertical .progress__bar__text {
  font-size: 1rem;
  margin: 0;
}

.card.remaining-votes-counter {
  border: 0px;
  box-shadow: none;
  color: var(--gray-90);
}

.card.remaining-votes-counter .card__content {
  padding: 1rem !important;
}

.card {
  border-radius: 0;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
}

.card__content {
  padding: 1.5rem;
  background: var(--pure-0);
}

.card__content:not(:only-child) {
  padding-bottom: 1.5rem;
}

.button--social__text {
  margin-left: 0;
  text-align: center;
}

.button.primary,
.button.primary.disabled,
.button.primary[disabled],
.button.primary.disabled:hover,
.button.primary[disabled]:hover,
.button.primary.disabled:focus,
.button.primary[disabled]:focus {
  background-color: var(--blue-10);
}

.close-button:hover {
  color: #2c2930;
}

.card__text .text-medium span {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-scale-base);
  line-height: var(--unnamed-line-spacing-20-299999237060547);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--gray-80);
}

.card__text .text-medium span a {
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-scale-up-01);
  line-height: var(--unnamed-line-spacing-20-299999237060547);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--blue-vivid-50);
  display: block;
  margin: 5px 0;
}

.images .section-heading {
  position: relative;
  margin-bottom: 1rem;
  text-transform: none;
  font-family: var(--unnamed-font-family-rawline);
  font-style: italic;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-scale-up-02);
  line-height: var(--unnamed-line-spacing-23-18400001525879);
  letter-spacing: var(--unnamed-character-spacing-0);
}

.section {
  margin-bottom: 0;
}

.section-heading {
  color: var(--blue-warm-vivid-80);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
}
.attachments .section-heading {
  text-transform: capitalize;
}

.section-heading::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  background-color: var(--primary);
  margin-right: 0;
  margin-bottom: 0;
}

.columns.section.view-side.mediumlarge-4.large-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 15px;
  min-width: 300px;
}

.columns.section.view-side.mediumlarge-4.large-3 .card {
  box-shadow: none;
  border: none;
  margin: 0;
}

.columns.section.view-side.mediumlarge-4.large-3 .card .card__content {
  background-color: var(--blue-3);
  box-shadow: none;
}

.reveal {
  background-color: var(---white);
}

.pagination .current {
  padding: 0.1875rem 0.625rem;
  background: var(--blue-warm-vivid-80);
  color: var(---white);
  cursor: default;
  border-radius: 100rem;
  min-width: 30px;
}

textarea,
input[type="text"]:not(.input-group-field) {
  background: var(---white);
  border: 1px solid #ccc;
  border-radius: 6px;
  color: var(--gray-80);
  display: block;
  font-size: var(--font-weight-medium);
  font-weight: var(--font-weight-medium);
  padding: 12px;
  width: 100%;
}

textarea:hover,
input[type="text"]:hover:not(.input-group-field) {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}

textarea:focus,
input[type="text"]:focus:not(.input-group-field) {
  border-color: var(--highlight) !important;
  box-shadow: 0 0 0 2px var(--highlight);
  outline: none;
}

.tabs-panel {
  margin: 1rem 0;
}

/* AJUDA */

.tabs {
  background-color: var(---white);
}

.tabs-content {
  padding: 1rem 1.5rem;
}

.main-container {
  background-color: transparent;
  padding: 0;
  border: none;
}

.main-container__content {
  padding: 0;
}

@media (min-width: 640px) {
  .main-container__content {
    padding: 0 1.5rem;
  }
}

.page-title {
  color: var(--blue-warm-vivid-70);
  font-size: 2rem;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.page-subtitle {
  color: var(--blue-warm-vivid-80);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: 0;
  margin: 0;
}

.row-counter {
  max-width: 100% !important;
}

/* ------- Calendario ----- */

.button--icon.hollow.secondary .icon-wrap {
  border-right: 1px solid var(--blue-warm-vivid-70);
  background: var(--blue-warm-vivid-70);
  border-radius: 25px 0 0 25px;
  font-weight: var(--font-weight-extra-bold);
  color: var(---white);
}

.button--icon.hollow.secondary .icon-wrap {
  font-weight: var(--font-weight-extra-bold);
}

.button.secondary.hollow {
  background: var(---white);
  color: var(--blue-warm-vivid-70);
  border-color: var(--blue-warm-vivid-70);
}

.button.secondary.hollow:hover {
  background: var(--blue-warm-vivid-70);
  border-color: var(--blue-warm-vivid-70);
  color: var(---white);
}

.card__support {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

label > [type="checkbox"],
label > [type="radio"] {
  margin-right: 0.5rem;
  border-radius: 4px;
  color: var(--gray-80);
  border: 1px solid #cccccc;
  background: var(---white) 0% 0% no-repeat padding-box;
  accent-color: var(--blue-warm-vivid-70);
}

label {
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: var(--gray-80);
}

.view-header .author-data__extra {
  font-weight: var(--font-weight-light);
}

.author-data__extra::before {
  border-right: 0;
}

.button.active,
.button:active {
  filter: none;
}

.filters__section {
  border-bottom: 1px solid #e8e8e8;
  padding: 1rem 0 1.5rem 0;
}

.card__footer {
  background: var(---white);
}

a.button--govbr span.button--social__text::last-child::before {
  content: " ";
  font-weight: var(--font-weight-black);
}

a.button.button--social.button--govbr {
  gap: 0;
}

a.button--govbr span.button--social__text::last-child {
  font-weight: var(--font-weight-semi-bold);
}

img#unb-logo {
  max-width: 62px;
}

span.button--social__text strong {
  font-weight: var(--font-weight-black);
}

blockquote {
  border: 0;
}

.wrapper--inner {
  background: var(---white);
}

.tabs-content {
  padding: 1rem 1.5rem;
  margin: 1rem 0;
}

/* ACESSIBILIDADE */

div[vw] {
  margin: -45vh 10px 0 0;
}

/* DISPLAY NONES - NÃO ME ORGULHO DE NADA DISSO */

.logos__section span.external-link-indicator {
  display: none;
}

a[href="#comments"] {
  display: none !important;
}

.hero__link-vote {
  background: var(--green-cool-vivid-50);
  color: var(---white);
  text-transform: none;
  border: 10px solid var(--gray-cool-2);
  padding: 0.75rem 1.5rem;
  box-shadow: 0px 1px 6px 0px;
  height: var(--button-medium);
  font-size: var(--font-size-scale-up-02);
  height: 64px;
  width: 100%;
  align-self: center;
}

.hero__link-vote:hover {
  opacity: 1;
  border: 10px solid var(---white);
  padding: 0.5rem 1.5rem;
  background: var(--green-cool-vivid-50);
}

.hero__container__img {
  background-image: var(--banner-mobile);
  background-size: cover;
  width: 100%;
  padding-bottom: 57%; /* 60.5 */
  background-repeat: round;
  height: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
}

.hero__container__img::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  filter: blur(0px);
}

.callout {
  box-shadow: 0px 1px 6px rgb(0 0 0 / 16%);
  border: 0 !important;
  margin: 0;
}

.callout.warning {
  background-color: var(--blue-10);
}

.callout.warning p.heading5 {
  font-weight: var(--font-weight-medium);
}

.logos__container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
}

.home-stats .section-statistics .statistics-cell {
  display: flex;
  flex-flow: row;
  justify-content: center;
  min-width: auto;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 8px;
}

@media (min-width: 768px) {
  .home-stats .section-statistics .statistics-cell {
    justify-content: center;
    gap: 1.5rem;
    padding: 2rem 2.5rem 2.5rem 2.5rem;
  }

  .process-card #first-card {
    width: 210px;
  }

  .new-features-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }

  .hero__container__img {
    background-image: var(--banner-desktop);
    background-size: cover;
    width: 100%;
    padding-bottom: 34%; /* 35.5 */
    background-repeat: round;
    height: 0;

    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .hero__link-vote {
    background: var(--green-cool-vivid-50);
    color: var(---white);
    text-transform: none;
    border: 10px solid var(--gray-cool-2);
    padding: 1rem 1.5rem;
    box-shadow: 0px 3px 6px #00000029;
    margin: 0 auto;
    height: var(--button-medium);
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .hero__link-vote:hover {
    opacity: 1;
    border: 10px solid var(---white);
    padding: 1rem 1.5rem;
    background: var(--green-cool-vivid-50);
  }
}

@media (min-width: 1024px) {
  .hero__link-vote {
    background: var(--green-cool-vivid-50);
    color: var(---white);
    text-transform: none;
    border: 10px solid var(--gray-cool-2);
    padding: 1.5rem;
    box-shadow: 0px 3px 6px #00000029;
    margin: 0 auto;
    height: var(--button-medium);
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .hero__link-vote:hover {
    opacity: 1;
    padding: 1.5rem;
    font-size: 1.5rem;
    border: 10px solid var(---white);
    background: var(--green-cool-vivid-50);
  }
}

@media (min-width: 2048px) {
  .hero__link-vote {
    background: var(--green-cool-vivid-50);
    color: var(---white);
    text-transform: none;
    border: 10px solid var(--gray-cool-2);
    padding: 2.5rem;
    font-size: 2.5rem;
    box-shadow: 0px 3px 6px #00000029;
    margin: 0 auto;
    height: var(--button-medium);
    margin-bottom: 2rem;
  }

  .hero__link-vote:hover {
    opacity: 1;
    padding: 2.5rem;
    font-size: 2.5rem;
    border: 10px solid var(---white);
    background: var(--green-cool-vivid-50);
  }
}

.logos__section {
  background-color: var(---white);
  padding: 2rem 1rem;
}

.suporters_container,
.organizers_container {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  gap: 1.5rem;
  margin: 0 auto;
  flex-wrap: wrap;
  min-height: 40px;
}

#proposals a[href*="with_availability%5D=withdrawn"] {
  display: none;
}

div.columns.large-12 div.section.images h3.section-heading,
div.columns.large-12
  div.section.images
  .gallery.row
  .columns.small-6.medium-4.end {
  display: block;
}

/* Estatísticas na Home */
.section-statistics .statistic__data.processes_count,
.section-statistics .statistic__data.assemblies_count,
.section-statistics .statistic__data.conferences_count,
.home-stats .home-stats__lowlight {
  display: none;
}

.home-stats__highlight {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.home-stats {
  margin: 0;
  overflow: initial;
}

.statistics-cell .statistic__data {
  text-align: center;
  width: 100%;
  position: relative;
}

.hero-link,
.hero-link:hover,
.hero-link:visited {
  display: flex;
  padding: 12px 24px;
  gap: 0.5rem;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-radius: 100px;
  background: var(---blue-warm-vivid-80);
  color: var(---white);
  font-size: var(--font-size-scale-up-02);
  font-weight: var(--font-weight-semi-bold);
  border: none;
  margin: 2rem auto 1.5rem auto;
  max-width: 400px;
}

.hero-link-icon {
  color: var(---white);
}

.create-profile-link,
.create-profile-link:hover {
  display: flex;
  padding: 8px 48px;
  gap: 0.5rem;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-radius: 100px;
  background: var(---white);
  color: var(--blue-warm-vivid-70);
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-semi-bold);
  border: 1px solid var(--blue-warm-vivid-70);
  max-width: 207px;
  margin: 1.5rem auto;
}

.create-profile-link:hover {
  color: var(--blue-warm-vivid-70);
  border-color: var(--blue-warm-vivid-70);
  background-color: rgba(var(--interactive-rgb), var(--hover));
  text-decoration: none;
}

.create-profile-link-icon {
  color: var(--blue-warm-vivid-70);
}

.ppa-process-container {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0.75rem;
}

.ppa-process {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: auto;
}

.process-icon {
  color: var(---blue-warm-vivid-80);
}

.process-card {
  display: flex;
  width: 290px;
  height: 210px;
  max-width: 380px;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  background: var(---white);
  box-shadow: 0px 6px 6px 0px rgb(51 51 51 / 16%);
  text-align: center;
}

.card-name {
  color: var(--default-black);
  font-family: var(--unnamed-font-family-rawline);
  font-size: var(--font-size-scale-up-02);
  font-style: normal;
  font-weight: var(--font-weight-semi-bold);
  line-height: normal;
  flex-direction: column;
  margin-bottom: 8px;
}

.bp-logo-container {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.card-content {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  max-width: 400px;
  width: auto;
  color: var(--gray-80);
}

.ppa-info-text {
  color: var(--default-black);
  text-align: center;
  font-family: var(--unnamed-font-family-rawline);
  font-size: 1rem;
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: normal;
  max-width: 630px;
  margin: 32px 0;
  display: flex;
}

.new-feature-card {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.hero__link-vote {
  background: var(--green-cool-vivid-50);
  color: var(---white);
  text-transform: none;
  border: 10px solid var(--gray-cool-2);
  padding: 0.75rem 1.5rem;
  box-shadow: 0px 1px 6px 0px;
  height: var(--button-medium);
  font-size: var(--font-size-scale-up-02);
  height: 64px;
  width: 100%;
  align-self: center;
}

.home-section-title,
#statistics .heading3 {
  color: var(--blue-warm-vivid-80);
  text-align: center;
  line-height: normal;
  font-family: var(--unnamed-font-family-rawline);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--font-weight-semi-bold) !important;
  font-size: var(--font-size-scale-up-02);
}

.new-features-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 3rem 1.25rem 3rem 1.25rem;
}

.card-see-more {
  color: var(--blue-warm-vivid-50);
  font-size: var(--font-size-scale-up-01);
  font-style: normal;
  font-weight: var(--font-weight-semi-bold);
  margin-left: auto;
}

.new-feature-icon-background {
  width: 52px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: var(--green-cool-vivid-50);
  padding: 14px 6px 0 0;
  color: var(---white);
  margin-bottom: 1rem;
}

.ppa-process-arrow-icon {
  color: var(--blue-warm-vivid-70);
}

.bp-logo {
  width: 300px;
  margin: 0 auto;
}

.new-feature-divider {
  display: none;
}

.rich-text-display a {
  max-width: 320px;
  display: inline-block;
}

@media (min-width: 768px) {
  .rich-text-display a {
    max-width: 100%;
  }
  .ppa-process {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 630px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ppa-process-arrow-icon {
    transform: rotate(-90deg);
  }

  .bp-logo {
    width: 430px;
    margin: 0 auto;
  }

  .home-section-title,
  #statistics .heading3 {
    font-weight: var(--font-weight-semi-bold) !important;
    font-size: var(--font-size-scale-up-04);
  }
}

@media (min-width: 1310px) {
  .new-features-content {
    display: flex;
    margin: 0 auto;
    gap: 0px;
    flex-direction: column;
  }
}

@media (min-width: 1284px) {
  .ppa-process {
    width: auto;
  }

  .ppa-info-text {
    width: auto;
  }

  .new-features-content {
    display: flex;
    margin: 0 auto;
    gap: 5rem;
    flex-direction: row;
  }

  .new-feature-divider {
    display: block;
    width: 1px;
    height: 80px;
    background: var(--gray-cool-20);
  }

  .logos__container {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
  }

  .home-stats .section-statistics .statistics-cell {
    justify-content: center;
    min-width: 680px;
    flex-wrap: nowrap;
    gap: 1.5rem;
    padding: 2rem 2.5rem 2.5rem 2.5rem;
  }
}

#statistics {
  background: var(--gray-2);
  padding: 1.5rem 1rem;
}

.space-menu {
  justify-content: center;
}

.br-container-lg .row,
.wrapper.wrapper--inner .row {
  max-width: 100%;
}

.admin--links {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.social-register {
  display: flex;
  justify-content: center;
}

.meta-card {
  display: flex;
  flex-direction: column;
  background-color: var(--blue-3);
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.5rem;
  width: 50%;
  gap: 1rem;
}
.meta-card p {
  margin: 0;
}

.card__author {
  display: none;
}

.row.collapse.buttons__row {
  display: none;
}

.results-per-page__dropdown {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.see-index-container {
  justify-content: flex-start;
}

.tab-item a span {
  font-size: 16px;
}

.process-nav ul {
  justify-content: flex-start !important;
}

.progress__bar.progress__bar--vertical .progress__bar__title,
.progress__bar.progress__bar--vertical .progress__bar__subtitle {
  margin-bottom: 0;
}

.bp-divider {
  width: 100%;
  height: 1px;
  background-color: var(--gray-4);
}
