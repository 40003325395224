.news-title-container {
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.show-notice-btn {
  min-width: 170px;
}
