.flatpickr-calendar {
  -webkit-animation: none;
  animation: none;
  background: transparent;
  background: #fff;
  border: 0;
  border-radius: 5px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  direction: ltr;
  display: none;
  font-size: 14px;
  line-height: 24px;
  opacity: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  visibility: hidden;
  width: 307.875px;
}
.flatpickr-calendar.inline,
.flatpickr-calendar.open {
  max-height: 640px;
  opacity: 1;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  display: block;
  z-index: 999;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasTime .dayContainer,
.flatpickr-calendar .hasWeeks .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid #e6e6e6;
  height: 40px;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:after,
.flatpickr-calendar:before {
  border: solid transparent;
  content: "";
  display: block;
  height: 0;
  left: 22px;
  pointer-events: none;
  position: absolute;
  width: 0;
}
.flatpickr-calendar.arrowRight:after,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.rightMost:before {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowCenter:after,
.flatpickr-calendar.arrowCenter:before {
  left: 50%;
  right: 50%;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom:after,
.flatpickr-calendar.arrowBottom:before {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}
.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-months .flatpickr-month {
  -webkit-box-flex: 1;
  background: transparent;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 1;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.flatpickr-months .flatpickr-month,
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  fill: rgba(0, 0, 0, 0.9);
  color: rgba(0, 0, 0, 0.9);
  height: 34px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  cursor: pointer;
  padding: 10px;
  position: absolute;
  text-decoration: none;
  top: 0;
  z-index: 3;
}
.flatpickr-months .flatpickr-next-month.flatpickr-disabled,
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-next-month i,
.flatpickr-months .flatpickr-prev-month i {
  position: relative;
}
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 0;
}
.flatpickr-months .flatpickr-next-month.flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
  right: 0;
}
.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover {
  color: #959ea9;
}
.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-next-month svg,
.flatpickr-months .flatpickr-prev-month svg {
  height: 14px;
  width: 14px;
}
.flatpickr-months .flatpickr-next-month svg path,
.flatpickr-months .flatpickr-prev-month svg path {
  fill: inherit;
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
}
.numInputWrapper {
  height: auto;
  position: relative;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-inner-spin-button,
.numInputWrapper input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numInputWrapper span {
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  padding: 0 4px 0 2px;
  position: absolute;
  right: 0;
  width: 14px;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}
.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}
.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}
.numInputWrapper span svg {
  height: auto;
  width: inherit;
}
.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  color: inherit;
  display: inline-block;
  font-size: 135%;
  font-weight: 300;
  height: 34px;
  left: 12.5%;
  line-height: inherit;
  line-height: 1;
  padding: 7.48px 0 0;
  position: absolute;
  text-align: center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 75%;
}
.flatpickr-current-month span.cur-month {
  color: inherit;
  display: inline-block;
  font-family: inherit;
  font-weight: 700;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  display: inline-block;
  width: 6ch;
  width: 7ch\0;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month input.cur-year {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: transparent;
  border: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: 0;
  padding: 0 0 0 0.5ch;
  vertical-align: initial;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  background: transparent;
  color: rgba(0, 0, 0, 0.5);
  font-size: 100%;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:active,
.flatpickr-current-month .flatpickr-monthDropdown-months:focus {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: transparent;
  height: 28px;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.flatpickr-weekdays,
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer,
span.flatpickr-weekday {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
span.flatpickr-weekday {
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  cursor: default;
  display: block;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  margin: 0;
  text-align: center;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0;
}
.flatpickr-days {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  -ms-flex-pack: justify;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  max-width: 307.875px;
  min-width: 307.875px;
  opacity: 1;
  outline: 0;
  padding: 0;
  text-align: left;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 307.875px;
}
.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}
.flatpickr-day {
  -ms-flex-preferred-size: 14.2857143%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  display: inline-block;
  -webkit-flex-basis: 14.2857143%;
  flex-basis: 14.2857143%;
  font-weight: 400;
  height: 39px;
  -webkit-justify-content: center;
  justify-content: center;
  line-height: 39px;
  margin: 0;
  max-width: 39px;
  position: relative;
  text-align: center;
  width: 14.2857143%;
}
.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
  background: #e6e6e6;
  border-color: #e6e6e6;
  cursor: pointer;
  outline: 0;
}
.flatpickr-day.today {
  border-color: #959ea9;
}
.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
  background: #959ea9;
  border-color: #959ea9;
  color: #fff;
}
.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: #569ff7;
  border-color: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}
.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.endRange.endRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}
.flatpickr-day.endRange.startRange.endRange,
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.nextMonthDay,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.prevMonthDay {
  background: transparent;
  border-color: transparent;
  color: rgba(57, 57, 57, 0.3);
  cursor: default;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  color: rgba(57, 57, 57, 0.1);
  cursor: not-allowed;
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
  padding: 0 12px;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  line-height: 28px;
  width: 100%;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  background: transparent;
  border: none;
  color: rgba(57, 57, 57, 0.3);
  cursor: default;
  display: block;
  max-width: none;
  width: 100%;
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
.flatpickr-innerContainer,
.flatpickr-rContainer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
}
.flatpickr-time {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  outline: 0;
  overflow: hidden;
  text-align: center;
}
.flatpickr-time:after {
  clear: both;
  content: "";
  display: table;
}
.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  float: left;
  height: 40px;
  width: 40%;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: transparent;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  font-size: 14px;
  height: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: 700;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}
.flatpickr-time .flatpickr-am-pm,
.flatpickr-time .flatpickr-time-separator {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  color: #393939;
  float: left;
  font-weight: 700;
  height: inherit;
  line-height: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2%;
}
.flatpickr-time .flatpickr-am-pm {
  cursor: pointer;
  font-weight: 400;
  outline: 0;
  text-align: center;
  width: 18%;
}
.flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time input:hover {
  background: #eee;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fpFadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
:root {
  --blue-90: #11181d;
  --blue-80: #1f303e;
  --blue-70: #274863;
  --blue-60: #2c608a;
  --blue-50: #2378c3;
  --blue-40: #4f97d1;
  --blue-30: #73b3e7;
  --blue-20: #aacdec;
  --blue-10: #dbe8fb;
  --blue-5: #eff6fb;
  --blue-3: #f3f7fb;
  --blue-cool-90: #0f191c;
  --blue-cool-80: #14333d;
  --blue-cool-70: #224a58;
  --blue-cool-60: #2e6276;
  --blue-cool-50: #3a7d95;
  --blue-cool-40: #6499af;
  --blue-cool-30: #82b4c9;
  --blue-cool-20: #adcfdc;
  --blue-cool-10: #dae9ee;
  --blue-cool-5: #e7f2f5;
  --blue-warm-90: #13171f;
  --blue-warm-80: #252f3e;
  --blue-warm-70: #2f4668;
  --blue-warm-60: #345d96;
  --blue-warm-50: #4a77b4;
  --blue-warm-40: #7292c7;
  --blue-warm-30: #98afd2;
  --blue-warm-20: #c5d4eb;
  --blue-warm-10: #e1e7f1;
  --blue-warm-5: #ecf1f7;
  --blue-vivid-80: #112f4e;
  --blue-vivid-70: #0b4778;
  --blue-vivid-60: #005ea2;
  --blue-vivid-50: #0076d6;
  --blue-vivid-40: #2491ff;
  --blue-vivid-30: #58b4ff;
  --blue-vivid-20: #a1d3ff;
  --blue-vivid-10: #cfe8ff;
  --blue-vivid-5: #e8f5ff;
  --blue-cool-vivid-80: #002d3f;
  --blue-cool-vivid-70: #074b69;
  --blue-cool-vivid-60: #07648d;
  --blue-cool-vivid-50: #0d7ea2;
  --blue-cool-vivid-40: #28a0cb;
  --blue-cool-vivid-30: #59b9de;
  --blue-cool-vivid-20: #97d4ea;
  --blue-cool-vivid-10: #c3ebfa;
  --blue-cool-vivid-5: #e1f3f8;
  --blue-warm-vivid-90: #071d41;
  --blue-warm-vivid-80: #0c326f;
  --blue-warm-vivid-70: #1351b4;
  --blue-warm-vivid-60: #155bcb;
  --blue-warm-vivid-50: #2670e8;
  --blue-warm-vivid-40: #5992ed;
  --blue-warm-vivid-30: #81aefc;
  --blue-warm-vivid-20: #adcdff;
  --blue-warm-vivid-10: #d4e5ff;
  --blue-warm-vivid-5: #edf5ff;
  --blue-90-rgb: 17, 24, 29;
  --blue-80-rgb: 31, 48, 62;
  --blue-70-rgb: 39, 72, 99;
  --blue-60-rgb: 44, 96, 138;
  --blue-50-rgb: 35, 120, 195;
  --blue-40-rgb: 79, 151, 209;
  --blue-30-rgb: 115, 179, 231;
  --blue-20-rgb: 170, 205, 236;
  --blue-10-rgb: 219, 232, 251;
  --blue-5-rgb: 239, 246, 251;
  --blue-cool-90-rgb: 15, 25, 28;
  --blue-cool-80-rgb: 20, 51, 61;
  --blue-cool-70-rgb: 34, 74, 88;
  --blue-cool-60-rgb: 46, 98, 118;
  --blue-cool-50-rgb: 58, 125, 149;
  --blue-cool-40-rgb: 100, 153, 175;
  --blue-cool-30-rgb: 130, 180, 201;
  --blue-cool-20-rgb: 173, 207, 220;
  --blue-cool-10-rgb: 218, 233, 238;
  --blue-cool-5-rgb: 231, 242, 245;
  --blue-warm-90-rgb: 19, 23, 31;
  --blue-warm-80-rgb: 37, 47, 62;
  --blue-warm-70-rgb: 47, 70, 104;
  --blue-warm-60-rgb: 52, 93, 150;
  --blue-warm-50-rgb: 74, 119, 180;
  --blue-warm-40-rgb: 114, 146, 199;
  --blue-warm-30-rgb: 152, 175, 210;
  --blue-warm-20-rgb: 197, 212, 235;
  --blue-warm-10-rgb: 225, 231, 241;
  --blue-warm-5-rgb: 236, 241, 247;
  --blue-vivid-80-rgb: 17, 47, 78;
  --blue-vivid-70-rgb: 11, 71, 120;
  --blue-vivid-60-rgb: 0, 94, 162;
  --blue-vivid-50-rgb: 0, 118, 214;
  --blue-vivid-40-rgb: 36, 145, 255;
  --blue-vivid-30-rgb: 88, 180, 255;
  --blue-vivid-20-rgb: 161, 211, 255;
  --blue-vivid-10-rgb: 207, 232, 255;
  --blue-vivid-5-rgb: 232, 245, 255;
  --blue-cool-vivid-80-rgb: 0, 45, 63;
  --blue-cool-vivid-70-rgb: 7, 75, 105;
  --blue-cool-vivid-60-rgb: 7, 100, 141;
  --blue-cool-vivid-50-rgb: 13, 126, 162;
  --blue-cool-vivid-40-rgb: 40, 160, 203;
  --blue-cool-vivid-30-rgb: 89, 185, 222;
  --blue-cool-vivid-20-rgb: 151, 212, 234;
  --blue-cool-vivid-10-rgb: 195, 235, 250;
  --blue-cool-vivid-5-rgb: 225, 243, 248;
  --blue-warm-vivid-90-rgb: 7, 29, 65;
  --blue-warm-vivid-80-rgb: 12, 50, 111;
  --blue-warm-vivid-70-rgb: 19, 81, 180;
  --blue-warm-vivid-60-rgb: 21, 91, 203;
  --blue-warm-vivid-50-rgb: 38, 112, 232;
  --blue-warm-vivid-40-rgb: 89, 146, 237;
  --blue-warm-vivid-30-rgb: 129, 174, 252;
  --blue-warm-vivid-20-rgb: 173, 205, 255;
  --blue-warm-vivid-10-rgb: 212, 229, 255;
  --blue-warm-vivid-5-rgb: 237, 245, 255;
  --cyan-90: #111819;
  --cyan-80: #203133;
  --cyan-70: #2c4a4e;
  --cyan-60: #2a646d;
  --cyan-50: #168092;
  --cyan-40: #449dac;
  --cyan-30: #5dc0d1;
  --cyan-20: #99deea;
  --cyan-10: #ccecf2;
  --cyan-5: #e7f6f8;
  --cyan-vivid-80: #093b44;
  --cyan-vivid-70: #0e4f5c;
  --cyan-vivid-60: #00687d;
  --cyan-vivid-50: #0081a1;
  --cyan-vivid-40: #009ec1;
  --cyan-vivid-30: #00bde3;
  --cyan-vivid-20: #52daf2;
  --cyan-vivid-10: #a8f2ff;
  --cyan-vivid-5: #e5faff;
  --cyan-90-rgb: 17, 24, 25;
  --cyan-80-rgb: 32, 49, 51;
  --cyan-70-rgb: 44, 74, 78;
  --cyan-60-rgb: 42, 100, 109;
  --cyan-50-rgb: 22, 128, 146;
  --cyan-40-rgb: 68, 157, 172;
  --cyan-30-rgb: 93, 192, 209;
  --cyan-20-rgb: 153, 222, 234;
  --cyan-10-rgb: 204, 236, 242;
  --cyan-5-rgb: 231, 246, 248;
  --cyan-vivid-80-rgb: 9, 59, 68;
  --cyan-vivid-70-rgb: 14, 79, 92;
  --cyan-vivid-60-rgb: 0, 104, 125;
  --cyan-vivid-50-rgb: 0, 129, 161;
  --cyan-vivid-40-rgb: 0, 158, 193;
  --cyan-vivid-30-rgb: 0, 189, 227;
  --cyan-vivid-20-rgb: 82, 218, 242;
  --cyan-vivid-10-rgb: 168, 242, 255;
  --cyan-vivid-5-rgb: 229, 250, 255;
  --gold-90: #191714;
  --gold-80: #322d26;
  --gold-70: #4d4438;
  --gold-60: #6b5947;
  --gold-50: #8e704f;
  --gold-40: #ad8b65;
  --gold-30: #c7a97b;
  --gold-20: #dec69a;
  --gold-10: #f1e5cd;
  --gold-5: #f5f0e6;
  --gold-vivid-80: #3b2b15;
  --gold-vivid-70: #5c410a;
  --gold-vivid-60: #7a591a;
  --gold-vivid-50: #936f38;
  --gold-vivid-40: #c2850c;
  --gold-vivid-30: #e5a000;
  --gold-vivid-20: #ffbe2e;
  --gold-vivid-10: #ffe396;
  --gold-vivid-5: #fef0c8;
  --gold-90-rgb: 25, 23, 20;
  --gold-80-rgb: 50, 45, 38;
  --gold-70-rgb: 77, 68, 56;
  --gold-60-rgb: 107, 89, 71;
  --gold-50-rgb: 142, 112, 79;
  --gold-40-rgb: 173, 139, 101;
  --gold-30-rgb: 199, 169, 123;
  --gold-20-rgb: 222, 198, 154;
  --gold-10-rgb: 241, 229, 205;
  --gold-5-rgb: 245, 240, 230;
  --gold-vivid-80-rgb: 59, 43, 21;
  --gold-vivid-70-rgb: 92, 65, 10;
  --gold-vivid-60-rgb: 122, 89, 26;
  --gold-vivid-50-rgb: 147, 111, 56;
  --gold-vivid-40-rgb: 194, 133, 12;
  --gold-vivid-30-rgb: 229, 160, 0;
  --gold-vivid-20-rgb: 255, 190, 46;
  --gold-vivid-10-rgb: 255, 227, 150;
  --gold-vivid-5-rgb: 254, 240, 200;
  --gray-90: #1b1b1b;
  --gray-80: #333;
  --gray-70: #555;
  --gray-60: #636363;
  --gray-50: #757575;
  --gray-40: #888;
  --gray-30: #adadad;
  --gray-20: #ccc;
  --gray-10: #e6e6e6;
  --gray-5: #f0f0f0;
  --gray-4: #f3f3f3;
  --gray-3: #f6f6f6;
  --gray-2: #f8f8f8;
  --gray-1: #fcfcfc;
  --gray-cool-90: #1c1d1f;
  --gray-cool-80: #2d2e2f;
  --gray-cool-70: #3d4551;
  --gray-cool-60: #565c65;
  --gray-cool-50: #71767a;
  --gray-cool-40: #8d9297;
  --gray-cool-30: #a9aeb1;
  --gray-cool-20: #c6cace;
  --gray-cool-10: #dfe1e2;
  --gray-cool-5: #edeff0;
  --gray-cool-4: #f1f3f6;
  --gray-cool-3: #f5f6f7;
  --gray-cool-2: #f7f9fa;
  --gray-cool-1: #fbfcfd;
  --gray-warm-90: #171716;
  --gray-warm-80: #2e2e2a;
  --gray-warm-70: #454540;
  --gray-warm-60: #5d5d52;
  --gray-warm-50: #76766a;
  --gray-warm-40: #929285;
  --gray-warm-30: #afaea2;
  --gray-warm-20: #cac9c0;
  --gray-warm-10: #e6e6e2;
  --gray-warm-5: #f0f0ec;
  --gray-warm-4: #f5f5f0;
  --gray-warm-3: #f6f6f2;
  --gray-warm-2: #f9f9f7;
  --gray-warm-1: #fcfcfb;
  --gray-90-rgb: 27, 27, 27;
  --gray-80-rgb: 51, 51, 51;
  --gray-70-rgb: 85, 85, 85;
  --gray-60-rgb: 99, 99, 99;
  --gray-50-rgb: 117, 117, 117;
  --gray-40-rgb: 136, 136, 136;
  --gray-30-rgb: 173, 173, 173;
  --gray-20-rgb: 204, 204, 204;
  --gray-10-rgb: 230, 230, 230;
  --gray-5-rgb: 240, 240, 240;
  --gray-4-rgb: 243, 243, 243;
  --gray-3-rgb: 246, 246, 246;
  --gray-2-rgb: 248, 248, 248;
  --gray-1-rgb: 252, 252, 252;
  --gray-cool-90-rgb: 28, 29, 31;
  --gray-cool-80-rgb: 45, 46, 47;
  --gray-cool-70-rgb: 61, 69, 81;
  --gray-cool-60-rgb: 86, 92, 101;
  --gray-cool-50-rgb: 113, 118, 122;
  --gray-cool-40-rgb: 141, 146, 151;
  --gray-cool-30-rgb: 169, 174, 177;
  --gray-cool-20-rgb: 198, 202, 206;
  --gray-cool-10-rgb: 223, 225, 226;
  --gray-cool-5-rgb: 237, 239, 240;
  --gray-cool-4-rgb: 241, 243, 246;
  --gray-cool-3-rgb: 245, 246, 247;
  --gray-cool-2-rgb: 247, 249, 250;
  --gray-cool-1-rgb: 251, 252, 253;
  --gray-warm-90-rgb: 23, 23, 22;
  --gray-warm-80-rgb: 46, 46, 42;
  --gray-warm-70-rgb: 69, 69, 64;
  --gray-warm-60-rgb: 93, 93, 82;
  --gray-warm-50-rgb: 118, 118, 106;
  --gray-warm-40-rgb: 146, 146, 133;
  --gray-warm-30-rgb: 175, 174, 162;
  --gray-warm-20-rgb: 202, 201, 192;
  --gray-warm-10-rgb: 230, 230, 226;
  --gray-warm-5-rgb: 240, 240, 236;
  --gray-warm-4-rgb: 245, 245, 240;
  --gray-warm-3-rgb: 246, 246, 242;
  --gray-warm-2-rgb: 249, 249, 247;
  --gray-warm-1-rgb: 252, 252, 251;
  --green-90: #161814;
  --green-80: #293021;
  --green-70: #3c4a29;
  --green-60: #4c6424;
  --green-50: #607f35;
  --green-40: #7d9b4e;
  --green-30: #9bb672;
  --green-20: #b8d293;
  --green-10: #dfeacd;
  --green-5: #eaf4dd;
  --green-cool-90: #1a1f1a;
  --green-cool-80: #28312a;
  --green-cool-70: #37493b;
  --green-cool-60: #446443;
  --green-cool-50: #4d8055;
  --green-cool-40: #5e9f69;
  --green-cool-30: #86b98e;
  --green-cool-20: #b4d0b9;
  --green-cool-10: #dbebde;
  --green-cool-5: #ecf3ec;
  --green-warm-90: #171712;
  --green-warm-80: #2d2f21;
  --green-warm-70: #45472f;
  --green-warm-60: #5a5f38;
  --green-warm-50: #6f7a41;
  --green-warm-40: #8a984b;
  --green-warm-30: #a6b557;
  --green-warm-20: #cbd17a;
  --green-warm-10: #e7eab7;
  --green-warm-5: #f1f4d7;
  --green-vivid-80: #243413;
  --green-vivid-70: #2f4a0b;
  --green-vivid-60: #466c04;
  --green-vivid-50: #538200;
  --green-vivid-40: #719f2a;
  --green-vivid-30: #7fb135;
  --green-vivid-20: #98d035;
  --green-vivid-10: #c5ee93;
  --green-vivid-5: #ddf9c7;
  --green-cool-vivid-80: #19311e;
  --green-cool-vivid-70: #154c21;
  --green-cool-vivid-60: #216e1f;
  --green-cool-vivid-50: #168821;
  --green-cool-vivid-40: #00a91c;
  --green-cool-vivid-30: #21c834;
  --green-cool-vivid-20: #70e17b;
  --green-cool-vivid-10: #b7f5bd;
  --green-cool-vivid-5: #e3f5e1;
  --green-warm-vivid-80: #38380b;
  --green-warm-vivid-70: #4b4e10;
  --green-warm-vivid-60: #5a6613;
  --green-warm-vivid-50: #6a7d00;
  --green-warm-vivid-40: #7e9c1d;
  --green-warm-vivid-30: #a3b72c;
  --green-warm-vivid-20: #c5d30a;
  --green-warm-vivid-10: #e7f434;
  --green-warm-vivid-5: #f5fbc1;
  --green-90-rgb: 22, 24, 20;
  --green-80-rgb: 41, 48, 33;
  --green-70-rgb: 60, 74, 41;
  --green-60-rgb: 76, 100, 36;
  --green-50-rgb: 96, 127, 53;
  --green-40-rgb: 125, 155, 78;
  --green-30-rgb: 155, 182, 114;
  --green-20-rgb: 184, 210, 147;
  --green-10-rgb: 223, 234, 205;
  --green-5-rgb: 234, 244, 221;
  --green-cool-90-rgb: 26, 31, 26;
  --green-cool-80-rgb: 40, 49, 42;
  --green-cool-70-rgb: 55, 73, 59;
  --green-cool-60-rgb: 68, 100, 67;
  --green-cool-50-rgb: 77, 128, 85;
  --green-cool-40-rgb: 94, 159, 105;
  --green-cool-30-rgb: 134, 185, 142;
  --green-cool-20-rgb: 180, 208, 185;
  --green-cool-10-rgb: 219, 235, 222;
  --green-cool-5-rgb: 236, 243, 236;
  --green-warm-90-rgb: 23, 23, 18;
  --green-warm-80-rgb: 45, 47, 33;
  --green-warm-70-rgb: 69, 71, 47;
  --green-warm-60-rgb: 90, 95, 56;
  --green-warm-50-rgb: 111, 122, 65;
  --green-warm-40-rgb: 138, 152, 75;
  --green-warm-30-rgb: 166, 181, 87;
  --green-warm-20-rgb: 203, 209, 122;
  --green-warm-10-rgb: 231, 234, 183;
  --green-warm-5-rgb: 241, 244, 215;
  --green-vivid-80-rgb: 36, 52, 19;
  --green-vivid-70-rgb: 47, 74, 11;
  --green-vivid-60-rgb: 70, 108, 4;
  --green-vivid-50-rgb: 83, 130, 0;
  --green-vivid-40-rgb: 113, 159, 42;
  --green-vivid-30-rgb: 127, 177, 53;
  --green-vivid-20-rgb: 152, 208, 53;
  --green-vivid-10-rgb: 197, 238, 147;
  --green-vivid-5-rgb: 221, 249, 199;
  --green-cool-vivid-80-rgb: 25, 49, 30;
  --green-cool-vivid-70-rgb: 21, 76, 33;
  --green-cool-vivid-60-rgb: 33, 110, 31;
  --green-cool-vivid-50-rgb: 22, 136, 33;
  --green-cool-vivid-40-rgb: 0, 169, 28;
  --green-cool-vivid-30-rgb: 33, 200, 52;
  --green-cool-vivid-20-rgb: 112, 225, 123;
  --green-cool-vivid-10-rgb: 183, 245, 189;
  --green-cool-vivid-5-rgb: 227, 245, 225;
  --green-warm-vivid-80-rgb: 56, 56, 11;
  --green-warm-vivid-70-rgb: 75, 78, 16;
  --green-warm-vivid-60-rgb: 90, 102, 19;
  --green-warm-vivid-50-rgb: 106, 125, 0;
  --green-warm-vivid-40-rgb: 126, 156, 29;
  --green-warm-vivid-30-rgb: 163, 183, 44;
  --green-warm-vivid-20-rgb: 197, 211, 10;
  --green-warm-vivid-10-rgb: 231, 244, 52;
  --green-warm-vivid-5-rgb: 245, 251, 193;
  --indigo-90: #16171f;
  --indigo-80: #2b2c40;
  --indigo-70: #3d4076;
  --indigo-60: #4d52af;
  --indigo-50: #676cc8;
  --indigo-40: #8889db;
  --indigo-30: #a5a8eb;
  --indigo-20: #c5c5f3;
  --indigo-10: #e5e4fa;
  --indigo-5: #efeff8;
  --indigo-cool-90: #151622;
  --indigo-cool-80: #292d42;
  --indigo-cool-70: #374274;
  --indigo-cool-60: #3f57a6;
  --indigo-cool-50: #496fd8;
  --indigo-cool-40: #6b8ee8;
  --indigo-cool-30: #96abee;
  --indigo-cool-20: #bbc8f5;
  --indigo-cool-10: #e1e6f9;
  --indigo-cool-5: #eef0f9;
  --indigo-warm-90: #18161d;
  --indigo-warm-80: #2e2c40;
  --indigo-warm-70: #453c7b;
  --indigo-warm-60: #5e519e;
  --indigo-warm-50: #7665d1;
  --indigo-warm-40: #9287d8;
  --indigo-warm-30: #afa5e8;
  --indigo-warm-20: #cbc4f2;
  --indigo-warm-10: #e7e3fa;
  --indigo-warm-5: #f1eff7;
  --indigo-vivid-80: #212463;
  --indigo-vivid-70: #3333a3;
  --indigo-vivid-60: #4a50c4;
  --indigo-vivid-50: #656bd7;
  --indigo-vivid-40: #8289ff;
  --indigo-vivid-30: #a3a7fa;
  --indigo-vivid-20: #ccceff;
  --indigo-vivid-10: #e0e0ff;
  --indigo-vivid-5: #f0f0ff;
  --indigo-cool-vivid-80: #1b2b85;
  --indigo-cool-vivid-70: #222fbf;
  --indigo-cool-vivid-60: #3e4ded;
  --indigo-cool-vivid-50: #4866ff;
  --indigo-cool-vivid-40: #628ef4;
  --indigo-cool-vivid-30: #94adff;
  --indigo-cool-vivid-20: #b8c8ff;
  --indigo-cool-vivid-10: #dee5ff;
  --indigo-cool-vivid-5: #edf0ff;
  --indigo-warm-vivid-80: #261f5b;
  --indigo-warm-vivid-70: #3d2c9d;
  --indigo-warm-vivid-60: #5942d2;
  --indigo-warm-vivid-50: #745fe9;
  --indigo-warm-vivid-40: #967efb;
  --indigo-warm-vivid-30: #b69fff;
  --indigo-warm-vivid-20: #cfc4fd;
  --indigo-warm-vivid-10: #e4deff;
  --indigo-warm-vivid-5: #f5f2ff;
  --indigo-90-rgb: 22, 23, 31;
  --indigo-80-rgb: 43, 44, 64;
  --indigo-70-rgb: 61, 64, 118;
  --indigo-60-rgb: 77, 82, 175;
  --indigo-50-rgb: 103, 108, 200;
  --indigo-40-rgb: 136, 137, 219;
  --indigo-30-rgb: 165, 168, 235;
  --indigo-20-rgb: 197, 197, 243;
  --indigo-10-rgb: 229, 228, 250;
  --indigo-5-rgb: 239, 239, 248;
  --indigo-cool-90-rgb: 21, 22, 34;
  --indigo-cool-80-rgb: 41, 45, 66;
  --indigo-cool-70-rgb: 55, 66, 116;
  --indigo-cool-60-rgb: 63, 87, 166;
  --indigo-cool-50-rgb: 73, 111, 216;
  --indigo-cool-40-rgb: 107, 142, 232;
  --indigo-cool-30-rgb: 150, 171, 238;
  --indigo-cool-20-rgb: 187, 200, 245;
  --indigo-cool-10-rgb: 225, 230, 249;
  --indigo-cool-5-rgb: 238, 240, 249;
  --indigo-warm-90-rgb: 24, 22, 29;
  --indigo-warm-80-rgb: 46, 44, 64;
  --indigo-warm-70-rgb: 69, 60, 123;
  --indigo-warm-60-rgb: 94, 81, 158;
  --indigo-warm-50-rgb: 118, 101, 209;
  --indigo-warm-40-rgb: 146, 135, 216;
  --indigo-warm-30-rgb: 175, 165, 232;
  --indigo-warm-20-rgb: 203, 196, 242;
  --indigo-warm-10-rgb: 231, 227, 250;
  --indigo-warm-5-rgb: 241, 239, 247;
  --indigo-vivid-80-rgb: 33, 36, 99;
  --indigo-vivid-70-rgb: 51, 51, 163;
  --indigo-vivid-60-rgb: 74, 80, 196;
  --indigo-vivid-50-rgb: 101, 107, 215;
  --indigo-vivid-40-rgb: 130, 137, 255;
  --indigo-vivid-30-rgb: 163, 167, 250;
  --indigo-vivid-20-rgb: 204, 206, 255;
  --indigo-vivid-10-rgb: 224, 224, 255;
  --indigo-vivid-5-rgb: 240, 240, 255;
  --indigo-cool-vivid-80-rgb: 27, 43, 133;
  --indigo-cool-vivid-70-rgb: 34, 47, 191;
  --indigo-cool-vivid-60-rgb: 62, 77, 237;
  --indigo-cool-vivid-50-rgb: 72, 102, 255;
  --indigo-cool-vivid-40-rgb: 98, 142, 244;
  --indigo-cool-vivid-30-rgb: 148, 173, 255;
  --indigo-cool-vivid-20-rgb: 184, 200, 255;
  --indigo-cool-vivid-10-rgb: 222, 229, 255;
  --indigo-cool-vivid-5-rgb: 237, 240, 255;
  --indigo-warm-vivid-80-rgb: 38, 31, 91;
  --indigo-warm-vivid-70-rgb: 61, 44, 157;
  --indigo-warm-vivid-60-rgb: 89, 66, 210;
  --indigo-warm-vivid-50-rgb: 116, 95, 233;
  --indigo-warm-vivid-40-rgb: 150, 126, 251;
  --indigo-warm-vivid-30-rgb: 182, 159, 255;
  --indigo-warm-vivid-20-rgb: 207, 196, 253;
  --indigo-warm-vivid-10-rgb: 228, 222, 255;
  --indigo-warm-vivid-5-rgb: 245, 242, 255;
  --magenta-90: #1b1617;
  --magenta-80: #402731;
  --magenta-70: #66364b;
  --magenta-60: #8b4566;
  --magenta-50: #c84281;
  --magenta-40: #e0699f;
  --magenta-30: #e895b3;
  --magenta-20: #f0bbcc;
  --magenta-10: #f6e1e8;
  --magenta-5: #f9f0f2;
  --magenta-vivid-80: #4f172e;
  --magenta-vivid-70: #731f44;
  --magenta-vivid-60: #ab2165;
  --magenta-vivid-50: #d72d79;
  --magenta-vivid-40: #fd4496;
  --magenta-vivid-30: #ff87b2;
  --magenta-vivid-20: #ffb4cf;
  --magenta-vivid-10: #ffddea;
  --magenta-vivid-5: #fff2f5;
  --magenta-90-rgb: 27, 22, 23;
  --magenta-80-rgb: 64, 39, 49;
  --magenta-70-rgb: 102, 54, 75;
  --magenta-60-rgb: 139, 69, 102;
  --magenta-50-rgb: 200, 66, 129;
  --magenta-40-rgb: 224, 105, 159;
  --magenta-30-rgb: 232, 149, 179;
  --magenta-20-rgb: 240, 187, 204;
  --magenta-10-rgb: 246, 225, 232;
  --magenta-5-rgb: 249, 240, 242;
  --magenta-vivid-80-rgb: 79, 23, 46;
  --magenta-vivid-70-rgb: 115, 31, 68;
  --magenta-vivid-60-rgb: 171, 33, 101;
  --magenta-vivid-50-rgb: 215, 45, 121;
  --magenta-vivid-40-rgb: 253, 68, 150;
  --magenta-vivid-30-rgb: 255, 135, 178;
  --magenta-vivid-20-rgb: 255, 180, 207;
  --magenta-vivid-10-rgb: 255, 221, 234;
  --magenta-vivid-5-rgb: 255, 242, 245;
  --mint-90: #0d1a12;
  --mint-80: #193324;
  --mint-70: #204e34;
  --mint-60: #286846;
  --mint-50: #2e8367;
  --mint-40: #34a37e;
  --mint-30: #5abf95;
  --mint-20: #92d9bb;
  --mint-10: #c7efe2;
  --mint-5: #dbf6ed;
  --mint-cool-90: #111818;
  --mint-cool-80: #203131;
  --mint-cool-70: #2a4b45;
  --mint-cool-60: #376462;
  --mint-cool-50: #40807e;
  --mint-cool-40: #4f9e99;
  --mint-cool-30: #6fbab3;
  --mint-cool-20: #9bd4cf;
  --mint-cool-10: #c4eeeb;
  --mint-cool-5: #e0f7f6;
  --mint-vivid-80: #0d351e;
  --mint-vivid-70: #0c4e29;
  --mint-vivid-60: #146947;
  --mint-vivid-50: #008659;
  --mint-vivid-40: #00a871;
  --mint-vivid-30: #04c585;
  --mint-vivid-20: #0ceda6;
  --mint-vivid-10: #83fcd4;
  --mint-vivid-5: #c9fbeb;
  --mint-cool-vivid-80: #123131;
  --mint-cool-vivid-70: #0b4b3f;
  --mint-cool-vivid-60: #0f6460;
  --mint-cool-vivid-50: #008480;
  --mint-cool-vivid-40: #36a191;
  --mint-cool-vivid-30: #1dc2ae;
  --mint-cool-vivid-20: #40e0d0;
  --mint-cool-vivid-10: #7efbe1;
  --mint-cool-vivid-5: #d5fbf3;
  --mint-90-rgb: 13, 26, 18;
  --mint-80-rgb: 25, 51, 36;
  --mint-70-rgb: 32, 78, 52;
  --mint-60-rgb: 40, 104, 70;
  --mint-50-rgb: 46, 131, 103;
  --mint-40-rgb: 52, 163, 126;
  --mint-30-rgb: 90, 191, 149;
  --mint-20-rgb: 146, 217, 187;
  --mint-10-rgb: 199, 239, 226;
  --mint-5-rgb: 219, 246, 237;
  --mint-cool-90-rgb: 17, 24, 24;
  --mint-cool-80-rgb: 32, 49, 49;
  --mint-cool-70-rgb: 42, 75, 69;
  --mint-cool-60-rgb: 55, 100, 98;
  --mint-cool-50-rgb: 64, 128, 126;
  --mint-cool-40-rgb: 79, 158, 153;
  --mint-cool-30-rgb: 111, 186, 179;
  --mint-cool-20-rgb: 155, 212, 207;
  --mint-cool-10-rgb: 196, 238, 235;
  --mint-cool-5-rgb: 224, 247, 246;
  --mint-vivid-80-rgb: 13, 53, 30;
  --mint-vivid-70-rgb: 12, 78, 41;
  --mint-vivid-60-rgb: 20, 105, 71;
  --mint-vivid-50-rgb: 0, 134, 89;
  --mint-vivid-40-rgb: 0, 168, 113;
  --mint-vivid-30-rgb: 4, 197, 133;
  --mint-vivid-20-rgb: 12, 237, 166;
  --mint-vivid-10-rgb: 131, 252, 212;
  --mint-vivid-5-rgb: 201, 251, 235;
  --mint-cool-vivid-80-rgb: 18, 49, 49;
  --mint-cool-vivid-70-rgb: 11, 75, 63;
  --mint-cool-vivid-60-rgb: 15, 100, 96;
  --mint-cool-vivid-50-rgb: 0, 132, 128;
  --mint-cool-vivid-40-rgb: 54, 161, 145;
  --mint-cool-vivid-30-rgb: 29, 194, 174;
  --mint-cool-vivid-20-rgb: 64, 224, 208;
  --mint-cool-vivid-10-rgb: 126, 251, 225;
  --mint-cool-vivid-5-rgb: 213, 251, 243;
  --orange-90: #1b1614;
  --orange-80: #332d27;
  --orange-70: #524236;
  --orange-60: #775540;
  --orange-50: #a26739;
  --orange-40: #dd7533;
  --orange-30: #f09860;
  --orange-20: #f3bf90;
  --orange-10: #f2e4d4;
  --orange-5: #f6efe9;
  --orange-warm-90: #1c1615;
  --orange-warm-80: #3d2925;
  --orange-warm-70: #633a32;
  --orange-warm-60: #914734;
  --orange-warm-50: #bd5727;
  --orange-warm-40: #e17141;
  --orange-warm-30: #f3966d;
  --orange-warm-20: #f7bca2;
  --orange-warm-10: #fbe0d0;
  --orange-warm-5: #faeee5;
  --orange-vivid-80: #352313;
  --orange-vivid-70: #5f3617;
  --orange-vivid-60: #8c471c;
  --orange-vivid-50: #c05600;
  --orange-vivid-40: #e66f0e;
  --orange-vivid-30: #ff8c00;
  --orange-vivid-20: #ffbc78;
  --orange-vivid-10: #fce2c5;
  --orange-vivid-5: #fdf5e6;
  --orange-warm-vivid-80: #3d231d;
  --orange-warm-vivid-70: #782312;
  --orange-warm-vivid-60: #a72f10;
  --orange-warm-vivid-50: #cf4900;
  --orange-warm-vivid-40: #ff580a;
  --orange-warm-vivid-30: #fc906d;
  --orange-warm-vivid-20: #fbbaa7;
  --orange-warm-vivid-10: #ffe2d1;
  --orange-warm-vivid-5: #fff3ea;
  --orange-90-rgb: 27, 22, 20;
  --orange-80-rgb: 51, 45, 39;
  --orange-70-rgb: 82, 66, 54;
  --orange-60-rgb: 119, 85, 64;
  --orange-50-rgb: 162, 103, 57;
  --orange-40-rgb: 221, 117, 51;
  --orange-30-rgb: 240, 152, 96;
  --orange-20-rgb: 243, 191, 144;
  --orange-10-rgb: 242, 228, 212;
  --orange-5-rgb: 246, 239, 233;
  --orange-warm-90-rgb: 28, 22, 21;
  --orange-warm-80-rgb: 61, 41, 37;
  --orange-warm-70-rgb: 99, 58, 50;
  --orange-warm-60-rgb: 145, 71, 52;
  --orange-warm-50-rgb: 189, 87, 39;
  --orange-warm-40-rgb: 225, 113, 65;
  --orange-warm-30-rgb: 243, 150, 109;
  --orange-warm-20-rgb: 247, 188, 162;
  --orange-warm-10-rgb: 251, 224, 208;
  --orange-warm-5-rgb: 250, 238, 229;
  --orange-vivid-80-rgb: 53, 35, 19;
  --orange-vivid-70-rgb: 95, 54, 23;
  --orange-vivid-60-rgb: 140, 71, 28;
  --orange-vivid-50-rgb: 192, 86, 0;
  --orange-vivid-40-rgb: 230, 111, 14;
  --orange-vivid-30-rgb: 255, 140, 0;
  --orange-vivid-20-rgb: 255, 188, 120;
  --orange-vivid-10-rgb: 252, 226, 197;
  --orange-vivid-5-rgb: 253, 245, 230;
  --orange-warm-vivid-80-rgb: 61, 35, 29;
  --orange-warm-vivid-70-rgb: 120, 35, 18;
  --orange-warm-vivid-60-rgb: 167, 47, 16;
  --orange-warm-vivid-50-rgb: 207, 73, 0;
  --orange-warm-vivid-40-rgb: 255, 88, 10;
  --orange-warm-vivid-30-rgb: 252, 144, 109;
  --orange-warm-vivid-20-rgb: 251, 186, 167;
  --orange-warm-vivid-10-rgb: 255, 226, 209;
  --orange-warm-vivid-5-rgb: 255, 243, 234;
  --pure-100: #000;
  --pure-0: #fff;
  --pure-100-rgb: 0, 0, 0;
  --pure-0-rgb: 255, 255, 255;
  --red-90: #1b1616;
  --red-80: #3e2927;
  --red-70: #6f3331;
  --red-60: #a23737;
  --red-50: #d83933;
  --red-40: #e9695f;
  --red-30: #f2938c;
  --red-20: #f7bbb1;
  --red-10: #f8e1de;
  --red-5: #f9eeee;
  --red-cool-90: #1e1517;
  --red-cool-80: #40282c;
  --red-cool-70: #68363f;
  --red-cool-60: #9e394b;
  --red-cool-50: #cd425b;
  --red-cool-40: #e16b80;
  --red-cool-30: #e09aa6;
  --red-cool-20: #ecbec6;
  --red-cool-10: #f3e1e4;
  --red-cool-5: #f8eff1;
  --red-warm-90: #1f1c18;
  --red-warm-80: #332d29;
  --red-warm-70: #524236;
  --red-warm-60: #805039;
  --red-warm-50: #c3512c;
  --red-warm-40: #d27a56;
  --red-warm-30: #dca081;
  --red-warm-20: #ecc0a7;
  --red-warm-10: #f4e3db;
  --red-warm-5: #f6efea;
  --red-vivid-80: #5c1111;
  --red-vivid-70: #8b0a03;
  --red-vivid-60: #b50909;
  --red-vivid-50: #e52207;
  --red-vivid-40: #fb5a47;
  --red-vivid-30: #ff8d7b;
  --red-vivid-20: #fdb8ae;
  --red-vivid-10: #fde0db;
  --red-vivid-5: #fff3f2;
  --red-cool-vivid-80: #4f1c24;
  --red-cool-vivid-70: #822133;
  --red-cool-vivid-60: #b21d38;
  --red-cool-vivid-50: #e41d3d;
  --red-cool-vivid-40: #f45d79;
  --red-cool-vivid-30: #fd8ba0;
  --red-cool-vivid-20: #f8b9c5;
  --red-cool-vivid-10: #f8dfe2;
  --red-cool-vivid-5: #fff2f5;
  --red-warm-vivid-80: #3e2a1e;
  --red-warm-vivid-70: #63340f;
  --red-warm-vivid-60: #9c3d10;
  --red-warm-vivid-50: #d54309;
  --red-warm-vivid-40: #ef5e25;
  --red-warm-vivid-30: #f39268;
  --red-warm-vivid-20: #f6bd9c;
  --red-warm-vivid-10: #fce1d4;
  --red-warm-vivid-5: #fff5ee;
  --red-90-rgb: 27, 22, 22;
  --red-80-rgb: 62, 41, 39;
  --red-70-rgb: 111, 51, 49;
  --red-60-rgb: 162, 55, 55;
  --red-50-rgb: 216, 57, 51;
  --red-40-rgb: 233, 105, 95;
  --red-30-rgb: 242, 147, 140;
  --red-20-rgb: 247, 187, 177;
  --red-10-rgb: 248, 225, 222;
  --red-5-rgb: 249, 238, 238;
  --red-cool-90-rgb: 30, 21, 23;
  --red-cool-80-rgb: 64, 40, 44;
  --red-cool-70-rgb: 104, 54, 63;
  --red-cool-60-rgb: 158, 57, 75;
  --red-cool-50-rgb: 205, 66, 91;
  --red-cool-40-rgb: 225, 107, 128;
  --red-cool-30-rgb: 224, 154, 166;
  --red-cool-20-rgb: 236, 190, 198;
  --red-cool-10-rgb: 243, 225, 228;
  --red-cool-5-rgb: 248, 239, 241;
  --red-warm-90-rgb: 31, 28, 24;
  --red-warm-80-rgb: 51, 45, 41;
  --red-warm-70-rgb: 82, 66, 54;
  --red-warm-60-rgb: 128, 80, 57;
  --red-warm-50-rgb: 195, 81, 44;
  --red-warm-40-rgb: 210, 122, 86;
  --red-warm-30-rgb: 220, 160, 129;
  --red-warm-20-rgb: 236, 192, 167;
  --red-warm-10-rgb: 244, 227, 219;
  --red-warm-5-rgb: 246, 239, 234;
  --red-vivid-80-rgb: 92, 17, 17;
  --red-vivid-70-rgb: 139, 10, 3;
  --red-vivid-60-rgb: 181, 9, 9;
  --red-vivid-50-rgb: 229, 34, 7;
  --red-vivid-40-rgb: 251, 90, 71;
  --red-vivid-30-rgb: 255, 141, 123;
  --red-vivid-20-rgb: 253, 184, 174;
  --red-vivid-10-rgb: 253, 224, 219;
  --red-vivid-5-rgb: 255, 243, 242;
  --red-cool-vivid-80-rgb: 79, 28, 36;
  --red-cool-vivid-70-rgb: 130, 33, 51;
  --red-cool-vivid-60-rgb: 178, 29, 56;
  --red-cool-vivid-50-rgb: 228, 29, 61;
  --red-cool-vivid-40-rgb: 244, 93, 121;
  --red-cool-vivid-30-rgb: 253, 139, 160;
  --red-cool-vivid-20-rgb: 248, 185, 197;
  --red-cool-vivid-10-rgb: 248, 223, 226;
  --red-cool-vivid-5-rgb: 255, 242, 245;
  --red-warm-vivid-80-rgb: 62, 42, 30;
  --red-warm-vivid-70-rgb: 99, 52, 15;
  --red-warm-vivid-60-rgb: 156, 61, 16;
  --red-warm-vivid-50-rgb: 213, 67, 9;
  --red-warm-vivid-40-rgb: 239, 94, 37;
  --red-warm-vivid-30-rgb: 243, 146, 104;
  --red-warm-vivid-20-rgb: 246, 189, 156;
  --red-warm-vivid-10-rgb: 252, 225, 212;
  --red-warm-vivid-5-rgb: 255, 245, 238;
  --violet-90: #18161d;
  --violet-80: #312b3f;
  --violet-70: #4c3d69;
  --violet-60: #665190;
  --violet-50: #8168b3;
  --violet-40: #9d84d2;
  --violet-30: #b8a2e3;
  --violet-20: #d0c3e9;
  --violet-10: #ebe3f9;
  --violet-5: #f4f1f9;
  --violet-warm-90: #1b151b;
  --violet-warm-80: #382936;
  --violet-warm-70: #5c395a;
  --violet-warm-60: #864381;
  --violet-warm-50: #b04abd;
  --violet-warm-40: #bf77c8;
  --violet-warm-30: #d29ad8;
  --violet-warm-20: #e2bee4;
  --violet-warm-10: #f6dff8;
  --violet-warm-5: #f8f0f9;
  --violet-vivid-80: #39215e;
  --violet-vivid-70: #54278f;
  --violet-vivid-60: #783cb9;
  --violet-vivid-50: #9355dc;
  --violet-vivid-40: #ad79e9;
  --violet-vivid-30: #c39deb;
  --violet-vivid-20: #d5bfff;
  --violet-vivid-10: #ede3ff;
  --violet-vivid-5: #f7f2ff;
  --violet-warm-vivid-80: #481441;
  --violet-warm-vivid-70: #711e6c;
  --violet-warm-vivid-60: #93348c;
  --violet-warm-vivid-50: #be32d0;
  --violet-warm-vivid-40: #d85bef;
  --violet-warm-vivid-30: #ee83ff;
  --violet-warm-vivid-20: #f4b2ff;
  --violet-warm-vivid-10: #fbdcff;
  --violet-warm-vivid-5: #fef2ff;
  --violet-90-rgb: 24, 22, 29;
  --violet-80-rgb: 49, 43, 63;
  --violet-70-rgb: 76, 61, 105;
  --violet-60-rgb: 102, 81, 144;
  --violet-50-rgb: 129, 104, 179;
  --violet-40-rgb: 157, 132, 210;
  --violet-30-rgb: 184, 162, 227;
  --violet-20-rgb: 208, 195, 233;
  --violet-10-rgb: 235, 227, 249;
  --violet-5-rgb: 244, 241, 249;
  --violet-warm-90-rgb: 27, 21, 27;
  --violet-warm-80-rgb: 56, 41, 54;
  --violet-warm-70-rgb: 92, 57, 90;
  --violet-warm-60-rgb: 134, 67, 129;
  --violet-warm-50-rgb: 176, 74, 189;
  --violet-warm-40-rgb: 191, 119, 200;
  --violet-warm-30-rgb: 210, 154, 216;
  --violet-warm-20-rgb: 226, 190, 228;
  --violet-warm-10-rgb: 246, 223, 248;
  --violet-warm-5-rgb: 248, 240, 249;
  --violet-vivid-80-rgb: 57, 33, 94;
  --violet-vivid-70-rgb: 84, 39, 143;
  --violet-vivid-60-rgb: 120, 60, 185;
  --violet-vivid-50-rgb: 147, 85, 220;
  --violet-vivid-40-rgb: 173, 121, 233;
  --violet-vivid-30-rgb: 195, 157, 235;
  --violet-vivid-20-rgb: 213, 191, 255;
  --violet-vivid-10-rgb: 237, 227, 255;
  --violet-vivid-5-rgb: 247, 242, 255;
  --violet-warm-vivid-80-rgb: 72, 20, 65;
  --violet-warm-vivid-70-rgb: 113, 30, 108;
  --violet-warm-vivid-60-rgb: 147, 52, 140;
  --violet-warm-vivid-50-rgb: 190, 50, 208;
  --violet-warm-vivid-40-rgb: 216, 91, 239;
  --violet-warm-vivid-30-rgb: 238, 131, 255;
  --violet-warm-vivid-20-rgb: 244, 178, 255;
  --violet-warm-vivid-10-rgb: 251, 220, 255;
  --violet-warm-vivid-5-rgb: 254, 242, 255;
  --yellow-90: #1a1614;
  --yellow-80: #332d27;
  --yellow-70: #504332;
  --yellow-60: #6b5a39;
  --yellow-50: #8a7237;
  --yellow-40: #a88f48;
  --yellow-30: #c9ab48;
  --yellow-20: #e6c74c;
  --yellow-10: #f5e6af;
  --yellow-5: #faf3d1;
  --yellow-vivid-80: #422d19;
  --yellow-vivid-70: #5c4809;
  --yellow-vivid-60: #776017;
  --yellow-vivid-50: #947100;
  --yellow-vivid-40: #b38c00;
  --yellow-vivid-30: #ddaa01;
  --yellow-vivid-20: #ffcd07;
  --yellow-vivid-10: #fee685;
  --yellow-vivid-5: #fff5c2;
  --yellow-90-rgb: 26, 22, 20;
  --yellow-80-rgb: 51, 45, 39;
  --yellow-70-rgb: 80, 67, 50;
  --yellow-60-rgb: 107, 90, 57;
  --yellow-50-rgb: 138, 114, 55;
  --yellow-40-rgb: 168, 143, 72;
  --yellow-30-rgb: 201, 171, 72;
  --yellow-20-rgb: 230, 199, 76;
  --yellow-10-rgb: 245, 230, 175;
  --yellow-5-rgb: 250, 243, 209;
  --yellow-vivid-80-rgb: 66, 45, 25;
  --yellow-vivid-70-rgb: 92, 72, 9;
  --yellow-vivid-60-rgb: 119, 96, 23;
  --yellow-vivid-50-rgb: 148, 113, 0;
  --yellow-vivid-40-rgb: 179, 140, 0;
  --yellow-vivid-30-rgb: 221, 170, 1;
  --yellow-vivid-20-rgb: 255, 205, 7;
  --yellow-vivid-10-rgb: 254, 230, 133;
  --yellow-vivid-5-rgb: 255, 245, 194;
  --background: var(--background-light);
  --background-rgb: var(--background-light-rgb);
  --background-light: var(--pure-0);
  --background-light-rgb: var(--pure-0-rgb);
  --background-alternative: var(--gray-5);
  --background-alternative-rgb: var(--gray-5-rgb);
  --background-dark: var(--blue-warm-vivid-90);
  --background-dark-rgb: var(--blue-warm-vivid-90-rgb);
  --border-color: var(--gray-20);
  --border-color-alternative: var(--gray-40);
  --border-style: solid;
  --border-width: var(--surface-width-sm);
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --color-light: var(--gray-80);
  --color-light-rgb: var(--gray-80-rgb);
  --color-dark: var(--pure-0);
  --color-dark-rgb: var(--pure-0-rgb);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--interactive-light-rgb);
  --interactive-light: var(--blue-warm-vivid-70);
  --interactive-light-rgb: var(--blue-warm-vivid-70-rgb);
  --interactive-dark: var(--blue-warm-20);
  --interactive-dark-rgb: var(--blue-warm-20-rgb);
  --interactive-alternative: #335bae;
  --interactive-alternative-rgb: var(--green-cool-vivid-50-rgb);
  --info: var(--blue-warm-vivid-60);
  --info-alternative: var(--blue-warm-vivid-10);
  --info-rgb: var(--blue-warm-vivid-60-rgb);
  --info-alternative-rgb: var(--blue-warm-vivid-10-rgb);
  --success: var(--green-cool-vivid-50);
  --success-alternative: var(--green-cool-vivid-5);
  --success-rgb: var(--green-cool-vivid-50-rgb);
  --success-alternative-rgb: var(--green-cool-vivid-5-rgb);
  --warning: var(--yellow-vivid-20);
  --warning-alternative: var(--yellow-vivid-5);
  --warning-rgb: var(--yellow-vivid-20-rgb);
  --warning-alternative-rgb: var(--yellow-vivid-5-rgb);
  --danger: var(--red-vivid-50);
  --danger-alternative: var(--red-vivid-10);
  --danger-rgb: var(--red-vivid-50-rgb);
  --danger-alternative-rgb: var(--red-vivid-20-rgb);
  --font-family-base: Rawline, Raleway, sans-serif;
  --surface-overlay-scrim: rgba(
    var(--rgb-secondary-09),
    var(--surface-opacity-md)
  );
  --surface-overlay-text: linear-gradient(
    rgba(var(--rgb-secondary-01), var(--surface-opacity-none)),
    rgba(var(--rgb-secondary-09), var(--surface-opacity-default))
  );
  --visited: var(--visited-light);
  --visited-light: var(--blue-warm-vivid-80);
  --visited-dark: var(--gray-20);
  --disabled: var(--surface-opacity-md);
  --hover: var(--hover-light);
  --hover-light: var(--surface-opacity-xs);
  --hover-dark: var(--surface-opacity-sm);
  --hover-effect: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
  --pressed: var(--pressed-light);
  --pressed-light: var(--surface-opacity-md);
  --pressed-dark: var(--surface-opacity-lg);
  --pressed-effect: linear-gradient(
    rgba(var(--interactive-rgb), var(--pressed)),
    rgba(var(--interactive-rgb), var(--pressed))
  );
  --focus-style: dashed;
  --focus-color: var(--focus-color-light);
  --focus-color-light: var(--gold-vivid-40);
  --focus-color-dark: var(--gold-vivid-20);
  --focus: var(--focus-color);
  --focus-offset: var(--spacing-scale-half);
  --focus-width: var(--surface-width-lg);
  --active: var(--blue-warm-vivid-80);
  --active-rgb: var(--blue-warm-vivid-80-rgb);
  --selected: var(--blue-warm-vivid-50);
  --on: var(--blue-warm-vivid-40);
  --on-rgb: var(--blue-warm-vivid-40-rgb);
  --off: var(--gray-20);
  --off-rgb: var(--gray-20-rgb);
  --ondrag-border-color: var(--interactive);
  --ondrag-border-width: var(--surface-width-md);
  --ondrag-border-style: solid;
  --ondrag-shadow-offset-x: var(--surface-offset-none);
  --ondrag-shadow-offset-y: var(--surface-offset-md);
  --ondrag-shadow-blur: var(--surface-blur-lg);
  --ondrag-shadow-color: var(--interactive-rgb);
  --ondrag-shadow-opacity: var(--surface-opacity-sm);
  --ondrag-opacity: var(--surface-opacity-xl);
  --ondrag-rotate: -5deg;
  --ondrag-cursor: grabbing;
  --draggable-icon: grip-vertical;
  --draggable-cursor: grab;
  --dropzone-border-color: var(--interactive);
  --dropzone-border-width: var(--surface-width-sm);
  --dropzone-border-style: dashed;
  --ondrop-background: var(--interactive-rgb);
  --ondrop-opacity: var(--surface-opacity-sm);
  --ondrop-cursor: copy;
  --z-index-layer-0: 0;
  --z-index-layer-1: 1000;
  --z-index-layer-2: 2000;
  --z-index-layer-3: 3000;
  --z-index-layer-4: 4000;
  --color-primary-pastel-01: #c5d4eb;
  --color-primary-pastel-02: #dbe8fb;
  --color-primary-lighten-01: #2670e8;
  --color-primary-lighten-02: #5992ed;
  --color-primary-default: #1351b4;
  --color-primary-darken-01: #0c326f;
  --color-primary-darken-02: #071d41;
  --color-secondary-01: #fff;
  --color-secondary-02: #f8f8f8;
  --color-secondary-03: #ededed;
  --color-secondary-04: #ccc;
  --color-secondary-05: #9e9d9d;
  --color-secondary-06: #888;
  --color-secondary-07: #555;
  --color-secondary-08: #333;
  --color-secondary-09: #000;
  --color-highlight: #268744;
  --color-support-01: #36a191;
  --color-support-02: #f2e317;
  --color-support-03: #db4800;
  --color-support-04: #a26739;
  --color-support-05: #40e0d0;
  --color-support-06: #48cbeb;
  --color-support-07: #c72487;
  --color-support-08: #63007f;
  --color-support-09: #f08080;
  --color-support-10: #ff8c00;
  --color-support-11: #fdf5e6;
  --icon-size-base: 16px;
  --icon-size-xs: 8px;
  --icon-size-sm: 12px;
  --icon-size-lg: 20px;
  --icon-size-2x: 32px;
  --icon-size-3x: 48px;
  --icon-size-4x: 64px;
  --icon-size-5x: 80px;
  --icon-size-6x: 96px;
  --icon-size-7x: 112px;
  --icon-size-8x: 128px;
  --icon-size-9x: 144px;
  --icon-size-10x: 160px;
  --surface-width-none: 0;
  --surface-width-sm: 1px;
  --surface-width-md: 2px;
  --surface-width-lg: 4px;
  --surface-rounder-none: 0;
  --surface-rounder-sm: 4px;
  --surface-rounder-md: 8px;
  --surface-rounder-lg: 16px;
  --surface-rounder-pill: 999em;
  --surface-opacity-none: 0;
  --surface-opacity-default: 1;
  --surface-opacity-xs: 0.16;
  --surface-opacity-sm: 0.3;
  --surface-opacity-md: 0.45;
  --surface-opacity-lg: 0.65;
  --surface-opacity-xl: 0.85;
  --surface-border-solid-none: var(--surface-width-none) solid
    var(--color-secondary-06);
  --surface-border-solid-sm: var(--surface-width-sm) solid
    var(--color-secondary-06);
  --surface-border-solid-md: var(--surface-width-md) solid
    var(--color-secondary-06);
  --surface-border-solid-lg: var(--surface-width-lg) solid
    var(--color-secondary-06);
  --surface-border-dashed-none: var(--surface-width-none) dashed
    var(--color-secondary-06);
  --surface-border-dashed-sm: var(--surface-width-sm) dashed
    var(--color-secondary-06);
  --surface-border-dashed-md: var(--surface-width-md) dashed
    var(--color-secondary-06);
  --surface-border-dashed-lg: var(--surface-width-lg) dashed
    var(--color-secondary-06);
  --surface-blur-none: 0;
  --surface-blur-sm: 1px;
  --surface-blur-md: 3px;
  --surface-blur-lg: 6px;
  --surface-blur-xl: 9px;
  --surface-offset-none: 0;
  --surface-offset-sm: 1px;
  --surface-offset-md: 3px;
  --surface-offset-lg: 6px;
  --surface-offset-xl: 9px;
  --surface-offset-sm-n: -1px;
  --surface-offset-md-n: -3px;
  --surface-offset-lg-n: -6px;
  --surface-offset-xl-n: -9px;
  --surface-shadow-color: var(--rgb-secondary-09);
  --surface-shadow-none: none;
  --surface-shadow-sm: var(--surface-offset-none) var(--surface-offset-sm)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-md: var(--surface-offset-none) var(--surface-offset-md)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-lg: var(--surface-offset-none) var(--surface-offset-lg)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-xl: var(--surface-offset-none) var(--surface-offset-xl)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-sm-inset: var(--surface-offset-none)
    var(--surface-offset-sm-n) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-md-inset: var(--surface-offset-none)
    var(--surface-offset-md-n) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-lg-inset: var(--surface-offset-none)
    var(--surface-offset-lg-n) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-xl-inset: var(--surface-offset-none)
    var(--surface-offset-xl-n) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-sm-up: var(--surface-offset-none) var(--surface-offset-sm-n)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-md-up: var(--surface-offset-none) var(--surface-offset-md-n)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-lg-up: var(--surface-offset-none) var(--surface-offset-lg-n)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-xl-up: var(--surface-offset-none) var(--surface-offset-xl-n)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-sm-inset-up: var(--surface-offset-none)
    var(--surface-offset-sm) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-md-inset-up: var(--surface-offset-none)
    var(--surface-offset-md) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-lg-inset-up: var(--surface-offset-none)
    var(--surface-offset-lg) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-xl-inset-up: var(--surface-offset-none)
    var(--surface-offset-xl) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-sm-right: var(--surface-offset-sm) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-md-right: var(--surface-offset-md) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-lg-right: var(--surface-offset-lg) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-xl-right: var(--surface-offset-xl) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-sm-inset-right: var(--surface-offset-sm-n)
    var(--surface-offset-none) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-md-inset-right: var(--surface-offset-md-n)
    var(--surface-offset-none) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-lg-inset-right: var(--surface-offset-lg-n)
    var(--surface-offset-none) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-xl-inset-right: var(--surface-offset-xl-n)
    var(--surface-offset-none) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-sm-left: var(--surface-offset-sm-n)
    var(--surface-offset-none) var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-md-left: var(--surface-offset-md-n)
    var(--surface-offset-none) var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-lg-left: var(--surface-offset-lg-n)
    var(--surface-offset-none) var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-xl-left: var(--surface-offset-xl-n)
    var(--surface-offset-none) var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-sm-inset-left: var(--surface-offset-sm)
    var(--surface-offset-none) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-md-inset-left: var(--surface-offset-md)
    var(--surface-offset-none) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-lg-inset-left: var(--surface-offset-lg)
    var(--surface-offset-none) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --surface-shadow-xl-inset-left: var(--surface-offset-xl)
    var(--surface-offset-none) var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
  --animation-ease: cubic-bezier(0.25, 0.1, 0.25, 1);
  --animation-ease-in: cubic-bezier(0.42, 0, 1, 1);
  --animation-ease-out: cubic-bezier(0, 0, 0.58, 1);
  --animation-ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
  --animation-ease-linear: cubic-bezier(0, 0, 1, 1);
  --duration-very-fast: 0.1s;
  --duration-fast: 0.3s;
  --duration-moderate: 0.5s;
  --duration-slow: 0.8s;
  --duration-very-slow: 1s;
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-black: 900;
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-20: 20px;
  --font-size-32: 32px;
  --font-size-32: 48px;
  --font-size-96: 96px;
  --font-size-scale-base: 14px;
  --font-size-scale-down-01: 11.662px;
  --font-size-scale-down-02: 9.716px;
  --font-size-scale-down-03: 8.106px;
  --font-size-scale-up-01: 16.8px;
  --font-size-scale-up-02: 20.16px;
  --font-size-scale-up-03: 24.192px;
  --font-size-scale-up-04: 29.036px;
  --font-size-scale-up-05: 34.832px;
  --font-size-scale-up-06: 41.804px;
  --font-size-scale-up-07: 50.162px;
  --font-size-scale-up-08: 60.2px;
  --font-size-scale-up-09: 72.24px;
  --font-size-scale-up-10: 86.688px;
  --font-size-scale-up-11: 104.02px;
  --font-line-height-low: 1.15;
  --font-line-height-medium: 1.45;
  --font-line-height-high: 1.85;
  --grid-breakpoint-xs: 0;
  --grid-breakpoint-xs-max-width: 100%;
  --grid-breakpoint-sm: 576px;
  --grid-breakpoint-sm-max-width: 536px;
  --grid-breakpoint-md: 992px;
  --grid-breakpoint-md-max-width: 952px;
  --grid-breakpoint-lg: 1280px;
  --grid-breakpoint-lg-max-width: 1200px;
  --grid-breakpoint-xl: 1600px;
  --grid-breakpoint-xl-max-width: 1560px;
  --grid-portrait-maxwidth: 100%;
  --grid-portrait-gutter: 16px;
  --grid-portrait-columns: 4;
  --grid-portrait-margin: 8px;
  --grid-tablet-maxwidth: 100%;
  --grid-tablet-gutter: 24px;
  --grid-tablet-columns: 8;
  --grid-tablet-margin: 40px;
  --grid-desktop-maxwidth: 1200px;
  --grid-desktop-gutter: 24px;
  --grid-desktop-columns: 12;
  --grid-desktop-margin: 40px;
  --grid-tv-maxwidth: 1520px;
  --grid-tv-gutter: 40px;
  --grid-tv-columns: 12;
  --grid-tv-margin: 40px;
  --spacing-vertical-top: top;
  --spacing-vertical-center: center;
  --spacing-vertical-bottom: bottom;
  --spacing-horizontal-left: left;
  --spacing-horizontal-center: center;
  --spacing-horizontal-right: right;
  --spacing-scale-default: 0;
  --spacing-scale-half: 4px;
  --spacing-scale-base: 8px;
  --spacing-scale-baseh: 12px;
  --spacing-scale-2x: 16px;
  --spacing-scale-2xh: 20px;
  --spacing-scale-3x: 24px;
  --spacing-scale-3xh: 28px;
  --spacing-scale-4x: 32px;
  --spacing-scale-4xh: 36px;
  --spacing-scale-5x: 40px;
  --spacing-scale-5xh: 44px;
  --spacing-scale-6x: 48px;
  --spacing-scale-6xh: 52px;
  --spacing-scale-7x: 56px;
  --spacing-scale-7xh: 60px;
  --spacing-scale-8x: 64px;
  --spacing-scale-8xh: 68px;
  --spacing-scale-9x: 72px;
  --spacing-scale-9xh: 76px;
  --spacing-scale-10x: 80px;
  --spacing-scale-10xh: 84px;
  --rgb-primary-pastel-01: 197, 212, 235;
  --rgb-primary-pastel-02: 219, 232, 251;
  --rgb-primary-lighten-01: 38, 112, 232;
  --rgb-primary-lighten-02: 89, 146, 237;
  --rgb-primary-default: 19, 81, 180;
  --rgb-primary-darken-01: 12, 50, 111;
  --rgb-primary-darken-02: 7, 29, 65;
  --rgb-secondary-01: 255, 255, 255;
  --rgb-secondary-02: 248, 248, 248;
  --rgb-secondary-03: 237, 237, 237;
  --rgb-secondary-04: 204, 204, 204;
  --rgb-secondary-05: 158, 157, 157;
  --rgb-secondary-06: 136, 136, 136;
  --rgb-secondary-07: 85, 85, 85;
  --rgb-secondary-08: 51, 51, 51;
  --rgb-secondary-09: 0, 0, 0;
  --rgb-highlight: 38, 135, 68;
  --rgb-support-01: 54, 161, 145;
  --rgb-support-02: 242, 227, 23;
  --rgb-support-03: 219, 72, 0;
  --rgb-support-04: 162, 103, 57;
  --rgb-support-05: 64, 224, 208;
  --rgb-support-06: 72, 203, 235;
  --rgb-support-07: 199, 36, 135;
  --rgb-support-08: 99, 0, 127;
  --rgb-support-09: 240, 128, 128;
  --rgb-support-10: 255, 140, 0;
  --rgb-support-11: 253, 245, 230;
}
*,
:after,
:before {
  box-sizing: border-box;
}
html {
  text-size-adjust: 100%;
  line-height: var(--font-line-height-medium);
}
body {
  margin: 0;
}
main {
  display: block;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: var(--font-line-height-medium);
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  appearance: none;
}
::-webkit-file-upload-button {
  appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
[hidden],
template {
  display: none;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1280px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-6 {
  order: 6 !important;
}
.order-7 {
  order: 7 !important;
}
.order-8 {
  order: 8 !important;
}
.order-9 {
  order: 9 !important;
}
.order-10 {
  order: 10 !important;
}
.order-11 {
  order: 11 !important;
}
.order-12 {
  order: 12 !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-evenly {
  align-content: space-evenly !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-grow-sm-1 {
    flex-grow: 1 !important;
  }
  .flex-grow-sm-0 {
    flex-grow: 0 !important;
  }
  .flex-shrink-sm-1 {
    flex-shrink: 1 !important;
  }
  .flex-shrink-sm-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-6 {
    order: 6 !important;
  }
  .order-sm-7 {
    order: 7 !important;
  }
  .order-sm-8 {
    order: 8 !important;
  }
  .order-sm-9 {
    order: 9 !important;
  }
  .order-sm-10 {
    order: 10 !important;
  }
  .order-sm-11 {
    order: 11 !important;
  }
  .order-sm-12 {
    order: 12 !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-evenly {
    align-content: space-evenly !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-grow-md-1 {
    flex-grow: 1 !important;
  }
  .flex-grow-md-0 {
    flex-grow: 0 !important;
  }
  .flex-shrink-md-1 {
    flex-shrink: 1 !important;
  }
  .flex-shrink-md-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-6 {
    order: 6 !important;
  }
  .order-md-7 {
    order: 7 !important;
  }
  .order-md-8 {
    order: 8 !important;
  }
  .order-md-9 {
    order: 9 !important;
  }
  .order-md-10 {
    order: 10 !important;
  }
  .order-md-11 {
    order: 11 !important;
  }
  .order-md-12 {
    order: 12 !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-evenly {
    align-content: space-evenly !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
}
@media (min-width: 1280px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-grow-lg-1 {
    flex-grow: 1 !important;
  }
  .flex-grow-lg-0 {
    flex-grow: 0 !important;
  }
  .flex-shrink-lg-1 {
    flex-shrink: 1 !important;
  }
  .flex-shrink-lg-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-6 {
    order: 6 !important;
  }
  .order-lg-7 {
    order: 7 !important;
  }
  .order-lg-8 {
    order: 8 !important;
  }
  .order-lg-9 {
    order: 9 !important;
  }
  .order-lg-10 {
    order: 10 !important;
  }
  .order-lg-11 {
    order: 11 !important;
  }
  .order-lg-12 {
    order: 12 !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-evenly {
    align-content: space-evenly !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
}
@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-grow-xl-1 {
    flex-grow: 1 !important;
  }
  .flex-grow-xl-0 {
    flex-grow: 0 !important;
  }
  .flex-shrink-xl-1 {
    flex-shrink: 1 !important;
  }
  .flex-shrink-xl-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-6 {
    order: 6 !important;
  }
  .order-xl-7 {
    order: 7 !important;
  }
  .order-xl-8 {
    order: 8 !important;
  }
  .order-xl-9 {
    order: 9 !important;
  }
  .order-xl-10 {
    order: 10 !important;
  }
  .order-xl-11 {
    order: 11 !important;
  }
  .order-xl-12 {
    order: 12 !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-evenly {
    align-content: space-evenly !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
}
.br-col,
[class*="col-"] {
  padding-left: calc(var(--grid-gutter) * 0.5);
  padding-right: calc(var(--grid-gutter) * 0.5);
  width: 100%;
}
.br-col {
  flex: 1 0 0%;
  min-width: 8.3333333333%;
}
.br-col-auto {
  flex: 0 0 auto;
  width: auto;
}
.br-col-1 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}
.br-col-2 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}
.br-col-3 {
  flex-basis: 25%;
  max-width: 25%;
}
.br-col-4 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}
.br-col-5 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}
.br-col-6 {
  flex-basis: 50%;
  max-width: 50%;
}
.br-col-7 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}
.br-col-8 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}
.br-col-9 {
  flex-basis: 75%;
  max-width: 75%;
}
.br-col-10 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}
.br-col-11 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}
.br-col-12 {
  flex-basis: 100%;
  max-width: 100%;
}
.br-container,
.br-container-fluid,
.br-container-lg,
.br-container-md,
.br-container-sm,
.br-container-xl {
  --grid-margin: var(--grid-portrait-margin);
  --grid-maxwidth: var(--grid-portrait-maxwidth);
  margin-left: auto;
  margin-right: auto;
  max-width: var(--grid-maxwidth);
  padding-left: var(--grid-margin);
  padding-right: var(--grid-margin);
  width: 100%;
}
.br-row {
  --grid-gutter: var(--grid-portrait-gutter);
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 576px) {
  .br-container,
  .br-container-fluid,
  .br-container-lg,
  .br-container-md,
  .br-container-sm,
  .br-container-xl {
    --grid-margin: var(--grid-tablet-margin);
  }
  .br-row {
    --grid-gutter: var(--grid-tablet-gutter);
  }
  .br-container,
  .br-container-sm {
    --grid-maxwidth: calc(
      var(--grid-breakpoint-sm) - var(--grid-tablet-margin)
    );
  }
  .br-col-sm {
    flex: 1 0 0%;
    min-width: 8.3333333333%;
  }
  .br-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .br-col-sm-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .br-col-sm-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .br-col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .br-col-sm-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .br-col-sm-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .br-col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .br-col-sm-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .br-col-sm-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .br-col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .br-col-sm-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .br-col-sm-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .br-col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .br-container,
  .br-container-fluid,
  .br-container-lg,
  .br-container-md,
  .br-container-sm,
  .br-container-xl {
    --grid-margin: var(--grid-tablet-margin);
  }
  .br-row {
    --grid-gutter: var(--grid-tablet-gutter);
  }
  .br-container,
  .br-container-md,
  .br-container-sm {
    --grid-maxwidth: calc(
      var(--grid-breakpoint-md) - var(--grid-tablet-margin)
    );
  }
  .br-col-md {
    flex: 1 0 0%;
    min-width: 8.3333333333%;
  }
  .br-col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .br-col-md-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .br-col-md-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .br-col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .br-col-md-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .br-col-md-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .br-col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .br-col-md-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .br-col-md-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .br-col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .br-col-md-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .br-col-md-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .br-col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1280px) {
  .br-container,
  .br-container-fluid,
  .br-container-lg,
  .br-container-md,
  .br-container-sm,
  .br-container-xl {
    --grid-margin: var(--grid-desktop-margin);
  }
  .br-row {
    --grid-gutter: var(--grid-desktop-gutter);
  }
  .br-container,
  .br-container-lg,
  .br-container-md,
  .br-container-sm {
    --grid-maxwidth: calc(
      var(--grid-breakpoint-lg) - var(--grid-desktop-margin)
    );
  }
  .br-col-lg {
    flex: 1 0 0%;
    min-width: 8.3333333333%;
  }
  .br-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .br-col-lg-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .br-col-lg-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .br-col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .br-col-lg-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .br-col-lg-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .br-col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .br-col-lg-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .br-col-lg-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .br-col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .br-col-lg-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .br-col-lg-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .br-col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1600px) {
  .br-container,
  .br-container-fluid,
  .br-container-lg,
  .br-container-md,
  .br-container-sm,
  .br-container-xl {
    --grid-margin: var(--grid-tv-margin);
  }
  .br-row {
    --grid-gutter: var(--grid-tv-gutter);
  }
  .br-container,
  .br-container-lg,
  .br-container-md,
  .br-container-sm,
  .br-container-xl {
    --grid-maxwidth: calc(var(--grid-breakpoint-xl) - var(--grid-tv-margin));
  }
  .br-col-xl {
    flex: 1 0 0%;
    min-width: 8.3333333333%;
  }
  .br-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .br-col-xl-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .br-col-xl-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .br-col-xl-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  .br-col-xl-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .br-col-xl-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .br-col-xl-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  .br-col-xl-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .br-col-xl-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .br-col-xl-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  .br-col-xl-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .br-col-xl-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .br-col-xl-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.m-0 {
  margin: var(--spacing-scale-default) !important;
}
.mx-0 {
  margin-left: var(--spacing-scale-default) !important;
  margin-right: var(--spacing-scale-default) !important;
}
.my-0 {
  margin-bottom: var(--spacing-scale-default) !important;
}
.mt-0,
.my-0 {
  margin-top: var(--spacing-scale-default) !important;
}
.mr-0 {
  margin-right: var(--spacing-scale-default) !important;
}
.mb-0 {
  margin-bottom: var(--spacing-scale-default) !important;
}
.ml-0 {
  margin-left: var(--spacing-scale-default) !important;
}
.m-half {
  margin: var(--spacing-scale-half) !important;
}
.mx-half {
  margin-left: var(--spacing-scale-half) !important;
  margin-right: var(--spacing-scale-half) !important;
}
.my-half {
  margin-bottom: var(--spacing-scale-half) !important;
}
.mt-half,
.my-half {
  margin-top: var(--spacing-scale-half) !important;
}
.mt-nhalf {
  margin-top: calc(var(--spacing-scale-half) * -1) !important;
}
.mr-half {
  margin-right: var(--spacing-scale-half) !important;
}
.mr-nhalf {
  margin-right: calc(var(--spacing-scale-half) * -1) !important;
}
.mb-half {
  margin-bottom: var(--spacing-scale-half) !important;
}
.mb-nhalf {
  margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
}
.ml-half {
  margin-left: var(--spacing-scale-half) !important;
}
.ml-nhalf {
  margin-left: calc(var(--spacing-scale-half) * -1) !important;
}
.m-nhalf {
  margin: calc(var(--spacing-scale-half) * -1) !important;
}
.mx-nhalf {
  margin-left: calc(var(--spacing-scale-half) * -1) !important;
  margin-right: calc(var(--spacing-scale-half) * -1) !important;
}
.my-nhalf {
  margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
  margin-top: calc(var(--spacing-scale-half) * -1) !important;
}
.m-base {
  margin: var(--spacing-scale-base) !important;
}
.mx-base {
  margin-left: var(--spacing-scale-base) !important;
  margin-right: var(--spacing-scale-base) !important;
}
.my-base {
  margin-bottom: var(--spacing-scale-base) !important;
}
.mt-base,
.my-base {
  margin-top: var(--spacing-scale-base) !important;
}
.mt-nbase {
  margin-top: calc(var(--spacing-scale-base) * -1) !important;
}
.mr-base {
  margin-right: var(--spacing-scale-base) !important;
}
.mr-nbase {
  margin-right: calc(var(--spacing-scale-base) * -1) !important;
}
.mb-base {
  margin-bottom: var(--spacing-scale-base) !important;
}
.mb-nbase {
  margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
}
.ml-base {
  margin-left: var(--spacing-scale-base) !important;
}
.ml-nbase {
  margin-left: calc(var(--spacing-scale-base) * -1) !important;
}
.m-nbase {
  margin: calc(var(--spacing-scale-base) * -1) !important;
}
.mx-nbase {
  margin-left: calc(var(--spacing-scale-base) * -1) !important;
  margin-right: calc(var(--spacing-scale-base) * -1) !important;
}
.my-nbase {
  margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
  margin-top: calc(var(--spacing-scale-base) * -1) !important;
}
.m-baseh {
  margin: var(--spacing-scale-baseh) !important;
}
.mx-baseh {
  margin-left: var(--spacing-scale-baseh) !important;
  margin-right: var(--spacing-scale-baseh) !important;
}
.my-baseh {
  margin-bottom: var(--spacing-scale-baseh) !important;
}
.mt-baseh,
.my-baseh {
  margin-top: var(--spacing-scale-baseh) !important;
}
.mt-nbaseh {
  margin-top: calc(var(--spacing-scale-baseh) * -1) !important;
}
.mr-baseh {
  margin-right: var(--spacing-scale-baseh) !important;
}
.mr-nbaseh {
  margin-right: calc(var(--spacing-scale-baseh) * -1) !important;
}
.mb-baseh {
  margin-bottom: var(--spacing-scale-baseh) !important;
}
.mb-nbaseh {
  margin-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
}
.ml-baseh {
  margin-left: var(--spacing-scale-baseh) !important;
}
.ml-nbaseh {
  margin-left: calc(var(--spacing-scale-baseh) * -1) !important;
}
.m-nbaseh {
  margin: calc(var(--spacing-scale-baseh) * -1) !important;
}
.mx-nbaseh {
  margin-left: calc(var(--spacing-scale-baseh) * -1) !important;
  margin-right: calc(var(--spacing-scale-baseh) * -1) !important;
}
.my-nbaseh {
  margin-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
  margin-top: calc(var(--spacing-scale-baseh) * -1) !important;
}
.m-1 {
  margin: var(--spacing-scale-half) !important;
}
.mx-1 {
  margin-left: var(--spacing-scale-half) !important;
  margin-right: var(--spacing-scale-half) !important;
}
.my-1 {
  margin-bottom: var(--spacing-scale-half) !important;
}
.mt-1,
.my-1 {
  margin-top: var(--spacing-scale-half) !important;
}
.mt-n1 {
  margin-top: calc(var(--spacing-scale-half) * -1) !important;
}
.mr-1 {
  margin-right: var(--spacing-scale-half) !important;
}
.mr-n1 {
  margin-right: calc(var(--spacing-scale-half) * -1) !important;
}
.mb-1 {
  margin-bottom: var(--spacing-scale-half) !important;
}
.mb-n1 {
  margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
}
.ml-1 {
  margin-left: var(--spacing-scale-half) !important;
}
.ml-n1 {
  margin-left: calc(var(--spacing-scale-half) * -1) !important;
}
.m-n1 {
  margin: calc(var(--spacing-scale-half) * -1) !important;
}
.mx-n1 {
  margin-left: calc(var(--spacing-scale-half) * -1) !important;
  margin-right: calc(var(--spacing-scale-half) * -1) !important;
}
.my-n1 {
  margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
  margin-top: calc(var(--spacing-scale-half) * -1) !important;
}
.m-2 {
  margin: var(--spacing-scale-base) !important;
}
.mx-2 {
  margin-left: var(--spacing-scale-base) !important;
  margin-right: var(--spacing-scale-base) !important;
}
.my-2 {
  margin-bottom: var(--spacing-scale-base) !important;
}
.mt-2,
.my-2 {
  margin-top: var(--spacing-scale-base) !important;
}
.mt-n2 {
  margin-top: calc(var(--spacing-scale-base) * -1) !important;
}
.mr-2 {
  margin-right: var(--spacing-scale-base) !important;
}
.mr-n2 {
  margin-right: calc(var(--spacing-scale-base) * -1) !important;
}
.mb-2 {
  margin-bottom: var(--spacing-scale-base) !important;
}
.mb-n2 {
  margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
}
.ml-2 {
  margin-left: var(--spacing-scale-base) !important;
}
.ml-n2 {
  margin-left: calc(var(--spacing-scale-base) * -1) !important;
}
.m-n2 {
  margin: calc(var(--spacing-scale-base) * -1) !important;
}
.mx-n2 {
  margin-left: calc(var(--spacing-scale-base) * -1) !important;
  margin-right: calc(var(--spacing-scale-base) * -1) !important;
}
.my-n2 {
  margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
  margin-top: calc(var(--spacing-scale-base) * -1) !important;
}
.m-3 {
  margin: var(--spacing-scale-2x) !important;
}
.mx-3 {
  margin-left: var(--spacing-scale-2x) !important;
  margin-right: var(--spacing-scale-2x) !important;
}
.my-3 {
  margin-bottom: var(--spacing-scale-2x) !important;
}
.mt-3,
.my-3 {
  margin-top: var(--spacing-scale-2x) !important;
}
.mt-n3 {
  margin-top: calc(var(--spacing-scale-2x) * -1) !important;
}
.mr-3 {
  margin-right: var(--spacing-scale-2x) !important;
}
.mr-n3 {
  margin-right: calc(var(--spacing-scale-2x) * -1) !important;
}
.mb-3 {
  margin-bottom: var(--spacing-scale-2x) !important;
}
.mb-n3 {
  margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
}
.ml-3 {
  margin-left: var(--spacing-scale-2x) !important;
}
.ml-n3 {
  margin-left: calc(var(--spacing-scale-2x) * -1) !important;
}
.m-n3 {
  margin: calc(var(--spacing-scale-2x) * -1) !important;
}
.mx-n3 {
  margin-left: calc(var(--spacing-scale-2x) * -1) !important;
  margin-right: calc(var(--spacing-scale-2x) * -1) !important;
}
.my-n3 {
  margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  margin-top: calc(var(--spacing-scale-2x) * -1) !important;
}
.m-4 {
  margin: var(--spacing-scale-3x) !important;
}
.mx-4 {
  margin-left: var(--spacing-scale-3x) !important;
  margin-right: var(--spacing-scale-3x) !important;
}
.my-4 {
  margin-bottom: var(--spacing-scale-3x) !important;
}
.mt-4,
.my-4 {
  margin-top: var(--spacing-scale-3x) !important;
}
.mt-n4 {
  margin-top: calc(var(--spacing-scale-3x) * -1) !important;
}
.mr-4 {
  margin-right: var(--spacing-scale-3x) !important;
}
.mr-n4 {
  margin-right: calc(var(--spacing-scale-3x) * -1) !important;
}
.mb-4 {
  margin-bottom: var(--spacing-scale-3x) !important;
}
.mb-n4 {
  margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
}
.ml-4 {
  margin-left: var(--spacing-scale-3x) !important;
}
.ml-n4 {
  margin-left: calc(var(--spacing-scale-3x) * -1) !important;
}
.m-n4 {
  margin: calc(var(--spacing-scale-3x) * -1) !important;
}
.mx-n4 {
  margin-left: calc(var(--spacing-scale-3x) * -1) !important;
  margin-right: calc(var(--spacing-scale-3x) * -1) !important;
}
.my-n4 {
  margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  margin-top: calc(var(--spacing-scale-3x) * -1) !important;
}
.m-5 {
  margin: var(--spacing-scale-4x) !important;
}
.mx-5 {
  margin-left: var(--spacing-scale-4x) !important;
  margin-right: var(--spacing-scale-4x) !important;
}
.my-5 {
  margin-bottom: var(--spacing-scale-4x) !important;
}
.mt-5,
.my-5 {
  margin-top: var(--spacing-scale-4x) !important;
}
.mt-n5 {
  margin-top: calc(var(--spacing-scale-4x) * -1) !important;
}
.mr-5 {
  margin-right: var(--spacing-scale-4x) !important;
}
.mr-n5 {
  margin-right: calc(var(--spacing-scale-4x) * -1) !important;
}
.mb-5 {
  margin-bottom: var(--spacing-scale-4x) !important;
}
.mb-n5 {
  margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
}
.ml-5 {
  margin-left: var(--spacing-scale-4x) !important;
}
.ml-n5 {
  margin-left: calc(var(--spacing-scale-4x) * -1) !important;
}
.m-n5 {
  margin: calc(var(--spacing-scale-4x) * -1) !important;
}
.mx-n5 {
  margin-left: calc(var(--spacing-scale-4x) * -1) !important;
  margin-right: calc(var(--spacing-scale-4x) * -1) !important;
}
.my-n5 {
  margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  margin-top: calc(var(--spacing-scale-4x) * -1) !important;
}
.m-6 {
  margin: var(--spacing-scale-5x) !important;
}
.mx-6 {
  margin-left: var(--spacing-scale-5x) !important;
  margin-right: var(--spacing-scale-5x) !important;
}
.my-6 {
  margin-bottom: var(--spacing-scale-5x) !important;
}
.mt-6,
.my-6 {
  margin-top: var(--spacing-scale-5x) !important;
}
.mt-n6 {
  margin-top: calc(var(--spacing-scale-5x) * -1) !important;
}
.mr-6 {
  margin-right: var(--spacing-scale-5x) !important;
}
.mr-n6 {
  margin-right: calc(var(--spacing-scale-5x) * -1) !important;
}
.mb-6 {
  margin-bottom: var(--spacing-scale-5x) !important;
}
.mb-n6 {
  margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
}
.ml-6 {
  margin-left: var(--spacing-scale-5x) !important;
}
.ml-n6 {
  margin-left: calc(var(--spacing-scale-5x) * -1) !important;
}
.m-n6 {
  margin: calc(var(--spacing-scale-5x) * -1) !important;
}
.mx-n6 {
  margin-left: calc(var(--spacing-scale-5x) * -1) !important;
  margin-right: calc(var(--spacing-scale-5x) * -1) !important;
}
.my-n6 {
  margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  margin-top: calc(var(--spacing-scale-5x) * -1) !important;
}
.m-2x {
  margin: var(--spacing-scale-2x) !important;
}
.mx-2x {
  margin-left: var(--spacing-scale-2x) !important;
  margin-right: var(--spacing-scale-2x) !important;
}
.my-2x {
  margin-bottom: var(--spacing-scale-2x) !important;
}
.mt-2x,
.my-2x {
  margin-top: var(--spacing-scale-2x) !important;
}
.mt-n2x {
  margin-top: calc(var(--spacing-scale-2x) * -1) !important;
}
.mr-2x {
  margin-right: var(--spacing-scale-2x) !important;
}
.mr-n2x {
  margin-right: calc(var(--spacing-scale-2x) * -1) !important;
}
.mb-2x {
  margin-bottom: var(--spacing-scale-2x) !important;
}
.mb-n2x {
  margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
}
.ml-2x {
  margin-left: var(--spacing-scale-2x) !important;
}
.ml-n2x {
  margin-left: calc(var(--spacing-scale-2x) * -1) !important;
}
.m-n2x {
  margin: calc(var(--spacing-scale-2x) * -1) !important;
}
.mx-n2x {
  margin-left: calc(var(--spacing-scale-2x) * -1) !important;
  margin-right: calc(var(--spacing-scale-2x) * -1) !important;
}
.my-n2x {
  margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  margin-top: calc(var(--spacing-scale-2x) * -1) !important;
}
.m-2xh {
  margin: var(--spacing-scale-2xh) !important;
}
.mx-2xh {
  margin-left: var(--spacing-scale-2xh) !important;
  margin-right: var(--spacing-scale-2xh) !important;
}
.my-2xh {
  margin-bottom: var(--spacing-scale-2xh) !important;
}
.mt-2xh,
.my-2xh {
  margin-top: var(--spacing-scale-2xh) !important;
}
.mt-n2xh {
  margin-top: calc(var(--spacing-scale-2xh) * -1) !important;
}
.mr-2xh {
  margin-right: var(--spacing-scale-2xh) !important;
}
.mr-n2xh {
  margin-right: calc(var(--spacing-scale-2xh) * -1) !important;
}
.mb-2xh {
  margin-bottom: var(--spacing-scale-2xh) !important;
}
.mb-n2xh {
  margin-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
}
.ml-2xh {
  margin-left: var(--spacing-scale-2xh) !important;
}
.ml-n2xh {
  margin-left: calc(var(--spacing-scale-2xh) * -1) !important;
}
.m-n2xh {
  margin: calc(var(--spacing-scale-2xh) * -1) !important;
}
.mx-n2xh {
  margin-left: calc(var(--spacing-scale-2xh) * -1) !important;
  margin-right: calc(var(--spacing-scale-2xh) * -1) !important;
}
.my-n2xh {
  margin-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
  margin-top: calc(var(--spacing-scale-2xh) * -1) !important;
}
.m-3x {
  margin: var(--spacing-scale-3x) !important;
}
.mx-3x {
  margin-left: var(--spacing-scale-3x) !important;
  margin-right: var(--spacing-scale-3x) !important;
}
.my-3x {
  margin-bottom: var(--spacing-scale-3x) !important;
}
.mt-3x,
.my-3x {
  margin-top: var(--spacing-scale-3x) !important;
}
.mt-n3x {
  margin-top: calc(var(--spacing-scale-3x) * -1) !important;
}
.mr-3x {
  margin-right: var(--spacing-scale-3x) !important;
}
.mr-n3x {
  margin-right: calc(var(--spacing-scale-3x) * -1) !important;
}
.mb-3x {
  margin-bottom: var(--spacing-scale-3x) !important;
}
.mb-n3x {
  margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
}
.ml-3x {
  margin-left: var(--spacing-scale-3x) !important;
}
.ml-n3x {
  margin-left: calc(var(--spacing-scale-3x) * -1) !important;
}
.m-n3x {
  margin: calc(var(--spacing-scale-3x) * -1) !important;
}
.mx-n3x {
  margin-left: calc(var(--spacing-scale-3x) * -1) !important;
  margin-right: calc(var(--spacing-scale-3x) * -1) !important;
}
.my-n3x {
  margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  margin-top: calc(var(--spacing-scale-3x) * -1) !important;
}
.m-3xh {
  margin: var(--spacing-scale-3xh) !important;
}
.mx-3xh {
  margin-left: var(--spacing-scale-3xh) !important;
  margin-right: var(--spacing-scale-3xh) !important;
}
.my-3xh {
  margin-bottom: var(--spacing-scale-3xh) !important;
}
.mt-3xh,
.my-3xh {
  margin-top: var(--spacing-scale-3xh) !important;
}
.mt-n3xh {
  margin-top: calc(var(--spacing-scale-3xh) * -1) !important;
}
.mr-3xh {
  margin-right: var(--spacing-scale-3xh) !important;
}
.mr-n3xh {
  margin-right: calc(var(--spacing-scale-3xh) * -1) !important;
}
.mb-3xh {
  margin-bottom: var(--spacing-scale-3xh) !important;
}
.mb-n3xh {
  margin-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
}
.ml-3xh {
  margin-left: var(--spacing-scale-3xh) !important;
}
.ml-n3xh {
  margin-left: calc(var(--spacing-scale-3xh) * -1) !important;
}
.m-n3xh {
  margin: calc(var(--spacing-scale-3xh) * -1) !important;
}
.mx-n3xh {
  margin-left: calc(var(--spacing-scale-3xh) * -1) !important;
  margin-right: calc(var(--spacing-scale-3xh) * -1) !important;
}
.my-n3xh {
  margin-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
  margin-top: calc(var(--spacing-scale-3xh) * -1) !important;
}
.m-4x {
  margin: var(--spacing-scale-4x) !important;
}
.mx-4x {
  margin-left: var(--spacing-scale-4x) !important;
  margin-right: var(--spacing-scale-4x) !important;
}
.my-4x {
  margin-bottom: var(--spacing-scale-4x) !important;
}
.mt-4x,
.my-4x {
  margin-top: var(--spacing-scale-4x) !important;
}
.mt-n4x {
  margin-top: calc(var(--spacing-scale-4x) * -1) !important;
}
.mr-4x {
  margin-right: var(--spacing-scale-4x) !important;
}
.mr-n4x {
  margin-right: calc(var(--spacing-scale-4x) * -1) !important;
}
.mb-4x {
  margin-bottom: var(--spacing-scale-4x) !important;
}
.mb-n4x {
  margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
}
.ml-4x {
  margin-left: var(--spacing-scale-4x) !important;
}
.ml-n4x {
  margin-left: calc(var(--spacing-scale-4x) * -1) !important;
}
.m-n4x {
  margin: calc(var(--spacing-scale-4x) * -1) !important;
}
.mx-n4x {
  margin-left: calc(var(--spacing-scale-4x) * -1) !important;
  margin-right: calc(var(--spacing-scale-4x) * -1) !important;
}
.my-n4x {
  margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  margin-top: calc(var(--spacing-scale-4x) * -1) !important;
}
.m-4xh {
  margin: var(--spacing-scale-4xh) !important;
}
.mx-4xh {
  margin-left: var(--spacing-scale-4xh) !important;
  margin-right: var(--spacing-scale-4xh) !important;
}
.my-4xh {
  margin-bottom: var(--spacing-scale-4xh) !important;
}
.mt-4xh,
.my-4xh {
  margin-top: var(--spacing-scale-4xh) !important;
}
.mt-n4xh {
  margin-top: calc(var(--spacing-scale-4xh) * -1) !important;
}
.mr-4xh {
  margin-right: var(--spacing-scale-4xh) !important;
}
.mr-n4xh {
  margin-right: calc(var(--spacing-scale-4xh) * -1) !important;
}
.mb-4xh {
  margin-bottom: var(--spacing-scale-4xh) !important;
}
.mb-n4xh {
  margin-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
}
.ml-4xh {
  margin-left: var(--spacing-scale-4xh) !important;
}
.ml-n4xh {
  margin-left: calc(var(--spacing-scale-4xh) * -1) !important;
}
.m-n4xh {
  margin: calc(var(--spacing-scale-4xh) * -1) !important;
}
.mx-n4xh {
  margin-left: calc(var(--spacing-scale-4xh) * -1) !important;
  margin-right: calc(var(--spacing-scale-4xh) * -1) !important;
}
.my-n4xh {
  margin-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
  margin-top: calc(var(--spacing-scale-4xh) * -1) !important;
}
.m-5x {
  margin: var(--spacing-scale-5x) !important;
}
.mx-5x {
  margin-left: var(--spacing-scale-5x) !important;
  margin-right: var(--spacing-scale-5x) !important;
}
.my-5x {
  margin-bottom: var(--spacing-scale-5x) !important;
}
.mt-5x,
.my-5x {
  margin-top: var(--spacing-scale-5x) !important;
}
.mt-n5x {
  margin-top: calc(var(--spacing-scale-5x) * -1) !important;
}
.mr-5x {
  margin-right: var(--spacing-scale-5x) !important;
}
.mr-n5x {
  margin-right: calc(var(--spacing-scale-5x) * -1) !important;
}
.mb-5x {
  margin-bottom: var(--spacing-scale-5x) !important;
}
.mb-n5x {
  margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
}
.ml-5x {
  margin-left: var(--spacing-scale-5x) !important;
}
.ml-n5x {
  margin-left: calc(var(--spacing-scale-5x) * -1) !important;
}
.m-n5x {
  margin: calc(var(--spacing-scale-5x) * -1) !important;
}
.mx-n5x {
  margin-left: calc(var(--spacing-scale-5x) * -1) !important;
  margin-right: calc(var(--spacing-scale-5x) * -1) !important;
}
.my-n5x {
  margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  margin-top: calc(var(--spacing-scale-5x) * -1) !important;
}
.m-5xh {
  margin: var(--spacing-scale-5xh) !important;
}
.mx-5xh {
  margin-left: var(--spacing-scale-5xh) !important;
  margin-right: var(--spacing-scale-5xh) !important;
}
.my-5xh {
  margin-bottom: var(--spacing-scale-5xh) !important;
}
.mt-5xh,
.my-5xh {
  margin-top: var(--spacing-scale-5xh) !important;
}
.mt-n5xh {
  margin-top: calc(var(--spacing-scale-5xh) * -1) !important;
}
.mr-5xh {
  margin-right: var(--spacing-scale-5xh) !important;
}
.mr-n5xh {
  margin-right: calc(var(--spacing-scale-5xh) * -1) !important;
}
.mb-5xh {
  margin-bottom: var(--spacing-scale-5xh) !important;
}
.mb-n5xh {
  margin-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
}
.ml-5xh {
  margin-left: var(--spacing-scale-5xh) !important;
}
.ml-n5xh {
  margin-left: calc(var(--spacing-scale-5xh) * -1) !important;
}
.m-n5xh {
  margin: calc(var(--spacing-scale-5xh) * -1) !important;
}
.mx-n5xh {
  margin-left: calc(var(--spacing-scale-5xh) * -1) !important;
  margin-right: calc(var(--spacing-scale-5xh) * -1) !important;
}
.my-n5xh {
  margin-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
  margin-top: calc(var(--spacing-scale-5xh) * -1) !important;
}
.m-6x {
  margin: var(--spacing-scale-6x) !important;
}
.mx-6x {
  margin-left: var(--spacing-scale-6x) !important;
  margin-right: var(--spacing-scale-6x) !important;
}
.my-6x {
  margin-bottom: var(--spacing-scale-6x) !important;
}
.mt-6x,
.my-6x {
  margin-top: var(--spacing-scale-6x) !important;
}
.mt-n6x {
  margin-top: calc(var(--spacing-scale-6x) * -1) !important;
}
.mr-6x {
  margin-right: var(--spacing-scale-6x) !important;
}
.mr-n6x {
  margin-right: calc(var(--spacing-scale-6x) * -1) !important;
}
.mb-6x {
  margin-bottom: var(--spacing-scale-6x) !important;
}
.mb-n6x {
  margin-bottom: calc(var(--spacing-scale-6x) * -1) !important;
}
.ml-6x {
  margin-left: var(--spacing-scale-6x) !important;
}
.ml-n6x {
  margin-left: calc(var(--spacing-scale-6x) * -1) !important;
}
.m-n6x {
  margin: calc(var(--spacing-scale-6x) * -1) !important;
}
.mx-n6x {
  margin-left: calc(var(--spacing-scale-6x) * -1) !important;
  margin-right: calc(var(--spacing-scale-6x) * -1) !important;
}
.my-n6x {
  margin-bottom: calc(var(--spacing-scale-6x) * -1) !important;
  margin-top: calc(var(--spacing-scale-6x) * -1) !important;
}
.m-6xh {
  margin: var(--spacing-scale-6xh) !important;
}
.mx-6xh {
  margin-left: var(--spacing-scale-6xh) !important;
  margin-right: var(--spacing-scale-6xh) !important;
}
.my-6xh {
  margin-bottom: var(--spacing-scale-6xh) !important;
}
.mt-6xh,
.my-6xh {
  margin-top: var(--spacing-scale-6xh) !important;
}
.mt-n6xh {
  margin-top: calc(var(--spacing-scale-6xh) * -1) !important;
}
.mr-6xh {
  margin-right: var(--spacing-scale-6xh) !important;
}
.mr-n6xh {
  margin-right: calc(var(--spacing-scale-6xh) * -1) !important;
}
.mb-6xh {
  margin-bottom: var(--spacing-scale-6xh) !important;
}
.mb-n6xh {
  margin-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
}
.ml-6xh {
  margin-left: var(--spacing-scale-6xh) !important;
}
.ml-n6xh {
  margin-left: calc(var(--spacing-scale-6xh) * -1) !important;
}
.m-n6xh {
  margin: calc(var(--spacing-scale-6xh) * -1) !important;
}
.mx-n6xh {
  margin-left: calc(var(--spacing-scale-6xh) * -1) !important;
  margin-right: calc(var(--spacing-scale-6xh) * -1) !important;
}
.my-n6xh {
  margin-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
  margin-top: calc(var(--spacing-scale-6xh) * -1) !important;
}
.m-7x {
  margin: var(--spacing-scale-7x) !important;
}
.mx-7x {
  margin-left: var(--spacing-scale-7x) !important;
  margin-right: var(--spacing-scale-7x) !important;
}
.my-7x {
  margin-bottom: var(--spacing-scale-7x) !important;
}
.mt-7x,
.my-7x {
  margin-top: var(--spacing-scale-7x) !important;
}
.mt-n7x {
  margin-top: calc(var(--spacing-scale-7x) * -1) !important;
}
.mr-7x {
  margin-right: var(--spacing-scale-7x) !important;
}
.mr-n7x {
  margin-right: calc(var(--spacing-scale-7x) * -1) !important;
}
.mb-7x {
  margin-bottom: var(--spacing-scale-7x) !important;
}
.mb-n7x {
  margin-bottom: calc(var(--spacing-scale-7x) * -1) !important;
}
.ml-7x {
  margin-left: var(--spacing-scale-7x) !important;
}
.ml-n7x {
  margin-left: calc(var(--spacing-scale-7x) * -1) !important;
}
.m-n7x {
  margin: calc(var(--spacing-scale-7x) * -1) !important;
}
.mx-n7x {
  margin-left: calc(var(--spacing-scale-7x) * -1) !important;
  margin-right: calc(var(--spacing-scale-7x) * -1) !important;
}
.my-n7x {
  margin-bottom: calc(var(--spacing-scale-7x) * -1) !important;
  margin-top: calc(var(--spacing-scale-7x) * -1) !important;
}
.m-7xh {
  margin: var(--spacing-scale-7xh) !important;
}
.mx-7xh {
  margin-left: var(--spacing-scale-7xh) !important;
  margin-right: var(--spacing-scale-7xh) !important;
}
.my-7xh {
  margin-bottom: var(--spacing-scale-7xh) !important;
}
.mt-7xh,
.my-7xh {
  margin-top: var(--spacing-scale-7xh) !important;
}
.mt-n7xh {
  margin-top: calc(var(--spacing-scale-7xh) * -1) !important;
}
.mr-7xh {
  margin-right: var(--spacing-scale-7xh) !important;
}
.mr-n7xh {
  margin-right: calc(var(--spacing-scale-7xh) * -1) !important;
}
.mb-7xh {
  margin-bottom: var(--spacing-scale-7xh) !important;
}
.mb-n7xh {
  margin-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
}
.ml-7xh {
  margin-left: var(--spacing-scale-7xh) !important;
}
.ml-n7xh {
  margin-left: calc(var(--spacing-scale-7xh) * -1) !important;
}
.m-n7xh {
  margin: calc(var(--spacing-scale-7xh) * -1) !important;
}
.mx-n7xh {
  margin-left: calc(var(--spacing-scale-7xh) * -1) !important;
  margin-right: calc(var(--spacing-scale-7xh) * -1) !important;
}
.my-n7xh {
  margin-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
  margin-top: calc(var(--spacing-scale-7xh) * -1) !important;
}
.m-8x {
  margin: var(--spacing-scale-8x) !important;
}
.mx-8x {
  margin-left: var(--spacing-scale-8x) !important;
  margin-right: var(--spacing-scale-8x) !important;
}
.my-8x {
  margin-bottom: var(--spacing-scale-8x) !important;
}
.mt-8x,
.my-8x {
  margin-top: var(--spacing-scale-8x) !important;
}
.mt-n8x {
  margin-top: calc(var(--spacing-scale-8x) * -1) !important;
}
.mr-8x {
  margin-right: var(--spacing-scale-8x) !important;
}
.mr-n8x {
  margin-right: calc(var(--spacing-scale-8x) * -1) !important;
}
.mb-8x {
  margin-bottom: var(--spacing-scale-8x) !important;
}
.mb-n8x {
  margin-bottom: calc(var(--spacing-scale-8x) * -1) !important;
}
.ml-8x {
  margin-left: var(--spacing-scale-8x) !important;
}
.ml-n8x {
  margin-left: calc(var(--spacing-scale-8x) * -1) !important;
}
.m-n8x {
  margin: calc(var(--spacing-scale-8x) * -1) !important;
}
.mx-n8x {
  margin-left: calc(var(--spacing-scale-8x) * -1) !important;
  margin-right: calc(var(--spacing-scale-8x) * -1) !important;
}
.my-n8x {
  margin-bottom: calc(var(--spacing-scale-8x) * -1) !important;
  margin-top: calc(var(--spacing-scale-8x) * -1) !important;
}
.m-8xh {
  margin: var(--spacing-scale-8xh) !important;
}
.mx-8xh {
  margin-left: var(--spacing-scale-8xh) !important;
  margin-right: var(--spacing-scale-8xh) !important;
}
.my-8xh {
  margin-bottom: var(--spacing-scale-8xh) !important;
}
.mt-8xh,
.my-8xh {
  margin-top: var(--spacing-scale-8xh) !important;
}
.mt-n8xh {
  margin-top: calc(var(--spacing-scale-8xh) * -1) !important;
}
.mr-8xh {
  margin-right: var(--spacing-scale-8xh) !important;
}
.mr-n8xh {
  margin-right: calc(var(--spacing-scale-8xh) * -1) !important;
}
.mb-8xh {
  margin-bottom: var(--spacing-scale-8xh) !important;
}
.mb-n8xh {
  margin-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
}
.ml-8xh {
  margin-left: var(--spacing-scale-8xh) !important;
}
.ml-n8xh {
  margin-left: calc(var(--spacing-scale-8xh) * -1) !important;
}
.m-n8xh {
  margin: calc(var(--spacing-scale-8xh) * -1) !important;
}
.mx-n8xh {
  margin-left: calc(var(--spacing-scale-8xh) * -1) !important;
  margin-right: calc(var(--spacing-scale-8xh) * -1) !important;
}
.my-n8xh {
  margin-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
  margin-top: calc(var(--spacing-scale-8xh) * -1) !important;
}
.m-9x {
  margin: var(--spacing-scale-9x) !important;
}
.mx-9x {
  margin-left: var(--spacing-scale-9x) !important;
  margin-right: var(--spacing-scale-9x) !important;
}
.my-9x {
  margin-bottom: var(--spacing-scale-9x) !important;
}
.mt-9x,
.my-9x {
  margin-top: var(--spacing-scale-9x) !important;
}
.mt-n9x {
  margin-top: calc(var(--spacing-scale-9x) * -1) !important;
}
.mr-9x {
  margin-right: var(--spacing-scale-9x) !important;
}
.mr-n9x {
  margin-right: calc(var(--spacing-scale-9x) * -1) !important;
}
.mb-9x {
  margin-bottom: var(--spacing-scale-9x) !important;
}
.mb-n9x {
  margin-bottom: calc(var(--spacing-scale-9x) * -1) !important;
}
.ml-9x {
  margin-left: var(--spacing-scale-9x) !important;
}
.ml-n9x {
  margin-left: calc(var(--spacing-scale-9x) * -1) !important;
}
.m-n9x {
  margin: calc(var(--spacing-scale-9x) * -1) !important;
}
.mx-n9x {
  margin-left: calc(var(--spacing-scale-9x) * -1) !important;
  margin-right: calc(var(--spacing-scale-9x) * -1) !important;
}
.my-n9x {
  margin-bottom: calc(var(--spacing-scale-9x) * -1) !important;
  margin-top: calc(var(--spacing-scale-9x) * -1) !important;
}
.m-9xh {
  margin: var(--spacing-scale-9xh) !important;
}
.mx-9xh {
  margin-left: var(--spacing-scale-9xh) !important;
  margin-right: var(--spacing-scale-9xh) !important;
}
.my-9xh {
  margin-bottom: var(--spacing-scale-9xh) !important;
}
.mt-9xh,
.my-9xh {
  margin-top: var(--spacing-scale-9xh) !important;
}
.mt-n9xh {
  margin-top: calc(var(--spacing-scale-9xh) * -1) !important;
}
.mr-9xh {
  margin-right: var(--spacing-scale-9xh) !important;
}
.mr-n9xh {
  margin-right: calc(var(--spacing-scale-9xh) * -1) !important;
}
.mb-9xh {
  margin-bottom: var(--spacing-scale-9xh) !important;
}
.mb-n9xh {
  margin-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
}
.ml-9xh {
  margin-left: var(--spacing-scale-9xh) !important;
}
.ml-n9xh {
  margin-left: calc(var(--spacing-scale-9xh) * -1) !important;
}
.m-n9xh {
  margin: calc(var(--spacing-scale-9xh) * -1) !important;
}
.mx-n9xh {
  margin-left: calc(var(--spacing-scale-9xh) * -1) !important;
  margin-right: calc(var(--spacing-scale-9xh) * -1) !important;
}
.my-n9xh {
  margin-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
  margin-top: calc(var(--spacing-scale-9xh) * -1) !important;
}
.m-10x {
  margin: var(--spacing-scale-10x) !important;
}
.mx-10x {
  margin-left: var(--spacing-scale-10x) !important;
  margin-right: var(--spacing-scale-10x) !important;
}
.my-10x {
  margin-bottom: var(--spacing-scale-10x) !important;
}
.mt-10x,
.my-10x {
  margin-top: var(--spacing-scale-10x) !important;
}
.mt-n10x {
  margin-top: calc(var(--spacing-scale-10x) * -1) !important;
}
.mr-10x {
  margin-right: var(--spacing-scale-10x) !important;
}
.mr-n10x {
  margin-right: calc(var(--spacing-scale-10x) * -1) !important;
}
.mb-10x {
  margin-bottom: var(--spacing-scale-10x) !important;
}
.mb-n10x {
  margin-bottom: calc(var(--spacing-scale-10x) * -1) !important;
}
.ml-10x {
  margin-left: var(--spacing-scale-10x) !important;
}
.ml-n10x {
  margin-left: calc(var(--spacing-scale-10x) * -1) !important;
}
.m-n10x {
  margin: calc(var(--spacing-scale-10x) * -1) !important;
}
.mx-n10x {
  margin-left: calc(var(--spacing-scale-10x) * -1) !important;
  margin-right: calc(var(--spacing-scale-10x) * -1) !important;
}
.my-n10x {
  margin-bottom: calc(var(--spacing-scale-10x) * -1) !important;
  margin-top: calc(var(--spacing-scale-10x) * -1) !important;
}
.m-10xh {
  margin: var(--spacing-scale-10xh) !important;
}
.mx-10xh {
  margin-left: var(--spacing-scale-10xh) !important;
  margin-right: var(--spacing-scale-10xh) !important;
}
.my-10xh {
  margin-bottom: var(--spacing-scale-10xh) !important;
}
.mt-10xh,
.my-10xh {
  margin-top: var(--spacing-scale-10xh) !important;
}
.mt-n10xh {
  margin-top: calc(var(--spacing-scale-10xh) * -1) !important;
}
.mr-10xh {
  margin-right: var(--spacing-scale-10xh) !important;
}
.mr-n10xh {
  margin-right: calc(var(--spacing-scale-10xh) * -1) !important;
}
.mb-10xh {
  margin-bottom: var(--spacing-scale-10xh) !important;
}
.mb-n10xh {
  margin-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
}
.ml-10xh {
  margin-left: var(--spacing-scale-10xh) !important;
}
.ml-n10xh {
  margin-left: calc(var(--spacing-scale-10xh) * -1) !important;
}
.m-n10xh {
  margin: calc(var(--spacing-scale-10xh) * -1) !important;
}
.mx-n10xh {
  margin-left: calc(var(--spacing-scale-10xh) * -1) !important;
  margin-right: calc(var(--spacing-scale-10xh) * -1) !important;
}
.my-n10xh {
  margin-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
  margin-top: calc(var(--spacing-scale-10xh) * -1) !important;
}
.m-auto {
  margin: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-auto {
  margin-bottom: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.p-0 {
  padding: var(--spacing-scale-default) !important;
}
.px-0 {
  padding-left: var(--spacing-scale-default) !important;
  padding-right: var(--spacing-scale-default) !important;
}
.py-0 {
  padding-bottom: var(--spacing-scale-default) !important;
}
.pt-0,
.py-0 {
  padding-top: var(--spacing-scale-default) !important;
}
.pr-0 {
  padding-right: var(--spacing-scale-default) !important;
}
.pb-0 {
  padding-bottom: var(--spacing-scale-default) !important;
}
.pl-0 {
  padding-left: var(--spacing-scale-default) !important;
}
.p-half {
  padding: var(--spacing-scale-half) !important;
}
.px-half {
  padding-left: var(--spacing-scale-half) !important;
  padding-right: var(--spacing-scale-half) !important;
}
.py-half {
  padding-bottom: var(--spacing-scale-half) !important;
}
.pt-half,
.py-half {
  padding-top: var(--spacing-scale-half) !important;
}
.pt-nhalf {
  padding-top: calc(var(--spacing-scale-half) * -1) !important;
}
.pr-half {
  padding-right: var(--spacing-scale-half) !important;
}
.pr-nhalf {
  padding-right: calc(var(--spacing-scale-half) * -1) !important;
}
.pb-half {
  padding-bottom: var(--spacing-scale-half) !important;
}
.pb-nhalf {
  padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
}
.pl-half {
  padding-left: var(--spacing-scale-half) !important;
}
.pl-nhalf {
  padding-left: calc(var(--spacing-scale-half) * -1) !important;
}
.p-nhalf {
  padding: calc(var(--spacing-scale-half) * -1) !important;
}
.px-nhalf {
  padding-left: calc(var(--spacing-scale-half) * -1) !important;
  padding-right: calc(var(--spacing-scale-half) * -1) !important;
}
.py-nhalf {
  padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
  padding-top: calc(var(--spacing-scale-half) * -1) !important;
}
.p-base {
  padding: var(--spacing-scale-base) !important;
}
.px-base {
  padding-left: var(--spacing-scale-base) !important;
  padding-right: var(--spacing-scale-base) !important;
}
.py-base {
  padding-bottom: var(--spacing-scale-base) !important;
}
.pt-base,
.py-base {
  padding-top: var(--spacing-scale-base) !important;
}
.pt-nbase {
  padding-top: calc(var(--spacing-scale-base) * -1) !important;
}
.pr-base {
  padding-right: var(--spacing-scale-base) !important;
}
.pr-nbase {
  padding-right: calc(var(--spacing-scale-base) * -1) !important;
}
.pb-base {
  padding-bottom: var(--spacing-scale-base) !important;
}
.pb-nbase {
  padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
}
.pl-base {
  padding-left: var(--spacing-scale-base) !important;
}
.pl-nbase {
  padding-left: calc(var(--spacing-scale-base) * -1) !important;
}
.p-nbase {
  padding: calc(var(--spacing-scale-base) * -1) !important;
}
.px-nbase {
  padding-left: calc(var(--spacing-scale-base) * -1) !important;
  padding-right: calc(var(--spacing-scale-base) * -1) !important;
}
.py-nbase {
  padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
  padding-top: calc(var(--spacing-scale-base) * -1) !important;
}
.p-baseh {
  padding: var(--spacing-scale-baseh) !important;
}
.px-baseh {
  padding-left: var(--spacing-scale-baseh) !important;
  padding-right: var(--spacing-scale-baseh) !important;
}
.py-baseh {
  padding-bottom: var(--spacing-scale-baseh) !important;
}
.pt-baseh,
.py-baseh {
  padding-top: var(--spacing-scale-baseh) !important;
}
.pt-nbaseh {
  padding-top: calc(var(--spacing-scale-baseh) * -1) !important;
}
.pr-baseh {
  padding-right: var(--spacing-scale-baseh) !important;
}
.pr-nbaseh {
  padding-right: calc(var(--spacing-scale-baseh) * -1) !important;
}
.pb-baseh {
  padding-bottom: var(--spacing-scale-baseh) !important;
}
.pb-nbaseh {
  padding-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
}
.pl-baseh {
  padding-left: var(--spacing-scale-baseh) !important;
}
.pl-nbaseh {
  padding-left: calc(var(--spacing-scale-baseh) * -1) !important;
}
.p-nbaseh {
  padding: calc(var(--spacing-scale-baseh) * -1) !important;
}
.px-nbaseh {
  padding-left: calc(var(--spacing-scale-baseh) * -1) !important;
  padding-right: calc(var(--spacing-scale-baseh) * -1) !important;
}
.py-nbaseh {
  padding-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
  padding-top: calc(var(--spacing-scale-baseh) * -1) !important;
}
.p-1 {
  padding: var(--spacing-scale-half) !important;
}
.px-1 {
  padding-left: var(--spacing-scale-half) !important;
  padding-right: var(--spacing-scale-half) !important;
}
.py-1 {
  padding-bottom: var(--spacing-scale-half) !important;
}
.pt-1,
.py-1 {
  padding-top: var(--spacing-scale-half) !important;
}
.pt-n1 {
  padding-top: calc(var(--spacing-scale-half) * -1) !important;
}
.pr-1 {
  padding-right: var(--spacing-scale-half) !important;
}
.pr-n1 {
  padding-right: calc(var(--spacing-scale-half) * -1) !important;
}
.pb-1 {
  padding-bottom: var(--spacing-scale-half) !important;
}
.pb-n1 {
  padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
}
.pl-1 {
  padding-left: var(--spacing-scale-half) !important;
}
.pl-n1 {
  padding-left: calc(var(--spacing-scale-half) * -1) !important;
}
.p-n1 {
  padding: calc(var(--spacing-scale-half) * -1) !important;
}
.px-n1 {
  padding-left: calc(var(--spacing-scale-half) * -1) !important;
  padding-right: calc(var(--spacing-scale-half) * -1) !important;
}
.py-n1 {
  padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
  padding-top: calc(var(--spacing-scale-half) * -1) !important;
}
.p-2 {
  padding: var(--spacing-scale-base) !important;
}
.px-2 {
  padding-left: var(--spacing-scale-base) !important;
  padding-right: var(--spacing-scale-base) !important;
}
.py-2 {
  padding-bottom: var(--spacing-scale-base) !important;
}
.pt-2,
.py-2 {
  padding-top: var(--spacing-scale-base) !important;
}
.pt-n2 {
  padding-top: calc(var(--spacing-scale-base) * -1) !important;
}
.pr-2 {
  padding-right: var(--spacing-scale-base) !important;
}
.pr-n2 {
  padding-right: calc(var(--spacing-scale-base) * -1) !important;
}
.pb-2 {
  padding-bottom: var(--spacing-scale-base) !important;
}
.pb-n2 {
  padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
}
.pl-2 {
  padding-left: var(--spacing-scale-base) !important;
}
.pl-n2 {
  padding-left: calc(var(--spacing-scale-base) * -1) !important;
}
.p-n2 {
  padding: calc(var(--spacing-scale-base) * -1) !important;
}
.px-n2 {
  padding-left: calc(var(--spacing-scale-base) * -1) !important;
  padding-right: calc(var(--spacing-scale-base) * -1) !important;
}
.py-n2 {
  padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
  padding-top: calc(var(--spacing-scale-base) * -1) !important;
}
.p-3 {
  padding: var(--spacing-scale-2x) !important;
}
.px-3 {
  padding-left: var(--spacing-scale-2x) !important;
  padding-right: var(--spacing-scale-2x) !important;
}
.py-3 {
  padding-bottom: var(--spacing-scale-2x) !important;
}
.pt-3,
.py-3 {
  padding-top: var(--spacing-scale-2x) !important;
}
.pt-n3 {
  padding-top: calc(var(--spacing-scale-2x) * -1) !important;
}
.pr-3 {
  padding-right: var(--spacing-scale-2x) !important;
}
.pr-n3 {
  padding-right: calc(var(--spacing-scale-2x) * -1) !important;
}
.pb-3 {
  padding-bottom: var(--spacing-scale-2x) !important;
}
.pb-n3 {
  padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
}
.pl-3 {
  padding-left: var(--spacing-scale-2x) !important;
}
.pl-n3 {
  padding-left: calc(var(--spacing-scale-2x) * -1) !important;
}
.p-n3 {
  padding: calc(var(--spacing-scale-2x) * -1) !important;
}
.px-n3 {
  padding-left: calc(var(--spacing-scale-2x) * -1) !important;
  padding-right: calc(var(--spacing-scale-2x) * -1) !important;
}
.py-n3 {
  padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  padding-top: calc(var(--spacing-scale-2x) * -1) !important;
}
.p-4 {
  padding: var(--spacing-scale-3x) !important;
}
.px-4 {
  padding-left: var(--spacing-scale-3x) !important;
  padding-right: var(--spacing-scale-3x) !important;
}
.py-4 {
  padding-bottom: var(--spacing-scale-3x) !important;
}
.pt-4,
.py-4 {
  padding-top: var(--spacing-scale-3x) !important;
}
.pt-n4 {
  padding-top: calc(var(--spacing-scale-3x) * -1) !important;
}
.pr-4 {
  padding-right: var(--spacing-scale-3x) !important;
}
.pr-n4 {
  padding-right: calc(var(--spacing-scale-3x) * -1) !important;
}
.pb-4 {
  padding-bottom: var(--spacing-scale-3x) !important;
}
.pb-n4 {
  padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
}
.pl-4 {
  padding-left: var(--spacing-scale-3x) !important;
}
.pl-n4 {
  padding-left: calc(var(--spacing-scale-3x) * -1) !important;
}
.p-n4 {
  padding: calc(var(--spacing-scale-3x) * -1) !important;
}
.px-n4 {
  padding-left: calc(var(--spacing-scale-3x) * -1) !important;
  padding-right: calc(var(--spacing-scale-3x) * -1) !important;
}
.py-n4 {
  padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  padding-top: calc(var(--spacing-scale-3x) * -1) !important;
}
.p-5 {
  padding: var(--spacing-scale-4x) !important;
}
.px-5 {
  padding-left: var(--spacing-scale-4x) !important;
  padding-right: var(--spacing-scale-4x) !important;
}
.py-5 {
  padding-bottom: var(--spacing-scale-4x) !important;
}
.pt-5,
.py-5 {
  padding-top: var(--spacing-scale-4x) !important;
}
.pt-n5 {
  padding-top: calc(var(--spacing-scale-4x) * -1) !important;
}
.pr-5 {
  padding-right: var(--spacing-scale-4x) !important;
}
.pr-n5 {
  padding-right: calc(var(--spacing-scale-4x) * -1) !important;
}
.pb-5 {
  padding-bottom: var(--spacing-scale-4x) !important;
}
.pb-n5 {
  padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
}
.pl-5 {
  padding-left: var(--spacing-scale-4x) !important;
}
.pl-n5 {
  padding-left: calc(var(--spacing-scale-4x) * -1) !important;
}
.p-n5 {
  padding: calc(var(--spacing-scale-4x) * -1) !important;
}
.px-n5 {
  padding-left: calc(var(--spacing-scale-4x) * -1) !important;
  padding-right: calc(var(--spacing-scale-4x) * -1) !important;
}
.py-n5 {
  padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  padding-top: calc(var(--spacing-scale-4x) * -1) !important;
}
.p-6 {
  padding: var(--spacing-scale-5x) !important;
}
.px-6 {
  padding-left: var(--spacing-scale-5x) !important;
  padding-right: var(--spacing-scale-5x) !important;
}
.py-6 {
  padding-bottom: var(--spacing-scale-5x) !important;
}
.pt-6,
.py-6 {
  padding-top: var(--spacing-scale-5x) !important;
}
.pt-n6 {
  padding-top: calc(var(--spacing-scale-5x) * -1) !important;
}
.pr-6 {
  padding-right: var(--spacing-scale-5x) !important;
}
.pr-n6 {
  padding-right: calc(var(--spacing-scale-5x) * -1) !important;
}
.pb-6 {
  padding-bottom: var(--spacing-scale-5x) !important;
}
.pb-n6 {
  padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
}
.pl-6 {
  padding-left: var(--spacing-scale-5x) !important;
}
.pl-n6 {
  padding-left: calc(var(--spacing-scale-5x) * -1) !important;
}
.p-n6 {
  padding: calc(var(--spacing-scale-5x) * -1) !important;
}
.px-n6 {
  padding-left: calc(var(--spacing-scale-5x) * -1) !important;
  padding-right: calc(var(--spacing-scale-5x) * -1) !important;
}
.py-n6 {
  padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  padding-top: calc(var(--spacing-scale-5x) * -1) !important;
}
.p-2x {
  padding: var(--spacing-scale-2x) !important;
}
.px-2x {
  padding-left: var(--spacing-scale-2x) !important;
  padding-right: var(--spacing-scale-2x) !important;
}
.py-2x {
  padding-bottom: var(--spacing-scale-2x) !important;
}
.pt-2x,
.py-2x {
  padding-top: var(--spacing-scale-2x) !important;
}
.pt-n2x {
  padding-top: calc(var(--spacing-scale-2x) * -1) !important;
}
.pr-2x {
  padding-right: var(--spacing-scale-2x) !important;
}
.pr-n2x {
  padding-right: calc(var(--spacing-scale-2x) * -1) !important;
}
.pb-2x {
  padding-bottom: var(--spacing-scale-2x) !important;
}
.pb-n2x {
  padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
}
.pl-2x {
  padding-left: var(--spacing-scale-2x) !important;
}
.pl-n2x {
  padding-left: calc(var(--spacing-scale-2x) * -1) !important;
}
.p-n2x {
  padding: calc(var(--spacing-scale-2x) * -1) !important;
}
.px-n2x {
  padding-left: calc(var(--spacing-scale-2x) * -1) !important;
  padding-right: calc(var(--spacing-scale-2x) * -1) !important;
}
.py-n2x {
  padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  padding-top: calc(var(--spacing-scale-2x) * -1) !important;
}
.p-2xh {
  padding: var(--spacing-scale-2xh) !important;
}
.px-2xh {
  padding-left: var(--spacing-scale-2xh) !important;
  padding-right: var(--spacing-scale-2xh) !important;
}
.py-2xh {
  padding-bottom: var(--spacing-scale-2xh) !important;
}
.pt-2xh,
.py-2xh {
  padding-top: var(--spacing-scale-2xh) !important;
}
.pt-n2xh {
  padding-top: calc(var(--spacing-scale-2xh) * -1) !important;
}
.pr-2xh {
  padding-right: var(--spacing-scale-2xh) !important;
}
.pr-n2xh {
  padding-right: calc(var(--spacing-scale-2xh) * -1) !important;
}
.pb-2xh {
  padding-bottom: var(--spacing-scale-2xh) !important;
}
.pb-n2xh {
  padding-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
}
.pl-2xh {
  padding-left: var(--spacing-scale-2xh) !important;
}
.pl-n2xh {
  padding-left: calc(var(--spacing-scale-2xh) * -1) !important;
}
.p-n2xh {
  padding: calc(var(--spacing-scale-2xh) * -1) !important;
}
.px-n2xh {
  padding-left: calc(var(--spacing-scale-2xh) * -1) !important;
  padding-right: calc(var(--spacing-scale-2xh) * -1) !important;
}
.py-n2xh {
  padding-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
  padding-top: calc(var(--spacing-scale-2xh) * -1) !important;
}
.p-3x {
  padding: var(--spacing-scale-3x) !important;
}
.px-3x {
  padding-left: var(--spacing-scale-3x) !important;
  padding-right: var(--spacing-scale-3x) !important;
}
.py-3x {
  padding-bottom: var(--spacing-scale-3x) !important;
}
.pt-3x,
.py-3x {
  padding-top: var(--spacing-scale-3x) !important;
}
.pt-n3x {
  padding-top: calc(var(--spacing-scale-3x) * -1) !important;
}
.pr-3x {
  padding-right: var(--spacing-scale-3x) !important;
}
.pr-n3x {
  padding-right: calc(var(--spacing-scale-3x) * -1) !important;
}
.pb-3x {
  padding-bottom: var(--spacing-scale-3x) !important;
}
.pb-n3x {
  padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
}
.pl-3x {
  padding-left: var(--spacing-scale-3x) !important;
}
.pl-n3x {
  padding-left: calc(var(--spacing-scale-3x) * -1) !important;
}
.p-n3x {
  padding: calc(var(--spacing-scale-3x) * -1) !important;
}
.px-n3x {
  padding-left: calc(var(--spacing-scale-3x) * -1) !important;
  padding-right: calc(var(--spacing-scale-3x) * -1) !important;
}
.py-n3x {
  padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  padding-top: calc(var(--spacing-scale-3x) * -1) !important;
}
.p-3xh {
  padding: var(--spacing-scale-3xh) !important;
}
.px-3xh {
  padding-left: var(--spacing-scale-3xh) !important;
  padding-right: var(--spacing-scale-3xh) !important;
}
.py-3xh {
  padding-bottom: var(--spacing-scale-3xh) !important;
}
.pt-3xh,
.py-3xh {
  padding-top: var(--spacing-scale-3xh) !important;
}
.pt-n3xh {
  padding-top: calc(var(--spacing-scale-3xh) * -1) !important;
}
.pr-3xh {
  padding-right: var(--spacing-scale-3xh) !important;
}
.pr-n3xh {
  padding-right: calc(var(--spacing-scale-3xh) * -1) !important;
}
.pb-3xh {
  padding-bottom: var(--spacing-scale-3xh) !important;
}
.pb-n3xh {
  padding-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
}
.pl-3xh {
  padding-left: var(--spacing-scale-3xh) !important;
}
.pl-n3xh {
  padding-left: calc(var(--spacing-scale-3xh) * -1) !important;
}
.p-n3xh {
  padding: calc(var(--spacing-scale-3xh) * -1) !important;
}
.px-n3xh {
  padding-left: calc(var(--spacing-scale-3xh) * -1) !important;
  padding-right: calc(var(--spacing-scale-3xh) * -1) !important;
}
.py-n3xh {
  padding-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
  padding-top: calc(var(--spacing-scale-3xh) * -1) !important;
}
.p-4x {
  padding: var(--spacing-scale-4x) !important;
}
.px-4x {
  padding-left: var(--spacing-scale-4x) !important;
  padding-right: var(--spacing-scale-4x) !important;
}
.py-4x {
  padding-bottom: var(--spacing-scale-4x) !important;
}
.pt-4x,
.py-4x {
  padding-top: var(--spacing-scale-4x) !important;
}
.pt-n4x {
  padding-top: calc(var(--spacing-scale-4x) * -1) !important;
}
.pr-4x {
  padding-right: var(--spacing-scale-4x) !important;
}
.pr-n4x {
  padding-right: calc(var(--spacing-scale-4x) * -1) !important;
}
.pb-4x {
  padding-bottom: var(--spacing-scale-4x) !important;
}
.pb-n4x {
  padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
}
.pl-4x {
  padding-left: var(--spacing-scale-4x) !important;
}
.pl-n4x {
  padding-left: calc(var(--spacing-scale-4x) * -1) !important;
}
.p-n4x {
  padding: calc(var(--spacing-scale-4x) * -1) !important;
}
.px-n4x {
  padding-left: calc(var(--spacing-scale-4x) * -1) !important;
  padding-right: calc(var(--spacing-scale-4x) * -1) !important;
}
.py-n4x {
  padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  padding-top: calc(var(--spacing-scale-4x) * -1) !important;
}
.p-4xh {
  padding: var(--spacing-scale-4xh) !important;
}
.px-4xh {
  padding-left: var(--spacing-scale-4xh) !important;
  padding-right: var(--spacing-scale-4xh) !important;
}
.py-4xh {
  padding-bottom: var(--spacing-scale-4xh) !important;
}
.pt-4xh,
.py-4xh {
  padding-top: var(--spacing-scale-4xh) !important;
}
.pt-n4xh {
  padding-top: calc(var(--spacing-scale-4xh) * -1) !important;
}
.pr-4xh {
  padding-right: var(--spacing-scale-4xh) !important;
}
.pr-n4xh {
  padding-right: calc(var(--spacing-scale-4xh) * -1) !important;
}
.pb-4xh {
  padding-bottom: var(--spacing-scale-4xh) !important;
}
.pb-n4xh {
  padding-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
}
.pl-4xh {
  padding-left: var(--spacing-scale-4xh) !important;
}
.pl-n4xh {
  padding-left: calc(var(--spacing-scale-4xh) * -1) !important;
}
.p-n4xh {
  padding: calc(var(--spacing-scale-4xh) * -1) !important;
}
.px-n4xh {
  padding-left: calc(var(--spacing-scale-4xh) * -1) !important;
  padding-right: calc(var(--spacing-scale-4xh) * -1) !important;
}
.py-n4xh {
  padding-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
  padding-top: calc(var(--spacing-scale-4xh) * -1) !important;
}
.p-5x {
  padding: var(--spacing-scale-5x) !important;
}
.px-5x {
  padding-left: var(--spacing-scale-5x) !important;
  padding-right: var(--spacing-scale-5x) !important;
}
.py-5x {
  padding-bottom: var(--spacing-scale-5x) !important;
}
.pt-5x,
.py-5x {
  padding-top: var(--spacing-scale-5x) !important;
}
.pt-n5x {
  padding-top: calc(var(--spacing-scale-5x) * -1) !important;
}
.pr-5x {
  padding-right: var(--spacing-scale-5x) !important;
}
.pr-n5x {
  padding-right: calc(var(--spacing-scale-5x) * -1) !important;
}
.pb-5x {
  padding-bottom: var(--spacing-scale-5x) !important;
}
.pb-n5x {
  padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
}
.pl-5x {
  padding-left: var(--spacing-scale-5x) !important;
}
.pl-n5x {
  padding-left: calc(var(--spacing-scale-5x) * -1) !important;
}
.p-n5x {
  padding: calc(var(--spacing-scale-5x) * -1) !important;
}
.px-n5x {
  padding-left: calc(var(--spacing-scale-5x) * -1) !important;
  padding-right: calc(var(--spacing-scale-5x) * -1) !important;
}
.py-n5x {
  padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  padding-top: calc(var(--spacing-scale-5x) * -1) !important;
}
.p-5xh {
  padding: var(--spacing-scale-5xh) !important;
}
.px-5xh {
  padding-left: var(--spacing-scale-5xh) !important;
  padding-right: var(--spacing-scale-5xh) !important;
}
.py-5xh {
  padding-bottom: var(--spacing-scale-5xh) !important;
}
.pt-5xh,
.py-5xh {
  padding-top: var(--spacing-scale-5xh) !important;
}
.pt-n5xh {
  padding-top: calc(var(--spacing-scale-5xh) * -1) !important;
}
.pr-5xh {
  padding-right: var(--spacing-scale-5xh) !important;
}
.pr-n5xh {
  padding-right: calc(var(--spacing-scale-5xh) * -1) !important;
}
.pb-5xh {
  padding-bottom: var(--spacing-scale-5xh) !important;
}
.pb-n5xh {
  padding-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
}
.pl-5xh {
  padding-left: var(--spacing-scale-5xh) !important;
}
.pl-n5xh {
  padding-left: calc(var(--spacing-scale-5xh) * -1) !important;
}
.p-n5xh {
  padding: calc(var(--spacing-scale-5xh) * -1) !important;
}
.px-n5xh {
  padding-left: calc(var(--spacing-scale-5xh) * -1) !important;
  padding-right: calc(var(--spacing-scale-5xh) * -1) !important;
}
.py-n5xh {
  padding-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
  padding-top: calc(var(--spacing-scale-5xh) * -1) !important;
}
.p-6x {
  padding: var(--spacing-scale-6x) !important;
}
.px-6x {
  padding-left: var(--spacing-scale-6x) !important;
  padding-right: var(--spacing-scale-6x) !important;
}
.py-6x {
  padding-bottom: var(--spacing-scale-6x) !important;
}
.pt-6x,
.py-6x {
  padding-top: var(--spacing-scale-6x) !important;
}
.pt-n6x {
  padding-top: calc(var(--spacing-scale-6x) * -1) !important;
}
.pr-6x {
  padding-right: var(--spacing-scale-6x) !important;
}
.pr-n6x {
  padding-right: calc(var(--spacing-scale-6x) * -1) !important;
}
.pb-6x {
  padding-bottom: var(--spacing-scale-6x) !important;
}
.pb-n6x {
  padding-bottom: calc(var(--spacing-scale-6x) * -1) !important;
}
.pl-6x {
  padding-left: var(--spacing-scale-6x) !important;
}
.pl-n6x {
  padding-left: calc(var(--spacing-scale-6x) * -1) !important;
}
.p-n6x {
  padding: calc(var(--spacing-scale-6x) * -1) !important;
}
.px-n6x {
  padding-left: calc(var(--spacing-scale-6x) * -1) !important;
  padding-right: calc(var(--spacing-scale-6x) * -1) !important;
}
.py-n6x {
  padding-bottom: calc(var(--spacing-scale-6x) * -1) !important;
  padding-top: calc(var(--spacing-scale-6x) * -1) !important;
}
.p-6xh {
  padding: var(--spacing-scale-6xh) !important;
}
.px-6xh {
  padding-left: var(--spacing-scale-6xh) !important;
  padding-right: var(--spacing-scale-6xh) !important;
}
.py-6xh {
  padding-bottom: var(--spacing-scale-6xh) !important;
}
.pt-6xh,
.py-6xh {
  padding-top: var(--spacing-scale-6xh) !important;
}
.pt-n6xh {
  padding-top: calc(var(--spacing-scale-6xh) * -1) !important;
}
.pr-6xh {
  padding-right: var(--spacing-scale-6xh) !important;
}
.pr-n6xh {
  padding-right: calc(var(--spacing-scale-6xh) * -1) !important;
}
.pb-6xh {
  padding-bottom: var(--spacing-scale-6xh) !important;
}
.pb-n6xh {
  padding-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
}
.pl-6xh {
  padding-left: var(--spacing-scale-6xh) !important;
}
.pl-n6xh {
  padding-left: calc(var(--spacing-scale-6xh) * -1) !important;
}
.p-n6xh {
  padding: calc(var(--spacing-scale-6xh) * -1) !important;
}
.px-n6xh {
  padding-left: calc(var(--spacing-scale-6xh) * -1) !important;
  padding-right: calc(var(--spacing-scale-6xh) * -1) !important;
}
.py-n6xh {
  padding-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
  padding-top: calc(var(--spacing-scale-6xh) * -1) !important;
}
.p-7x {
  padding: var(--spacing-scale-7x) !important;
}
.px-7x {
  padding-left: var(--spacing-scale-7x) !important;
  padding-right: var(--spacing-scale-7x) !important;
}
.py-7x {
  padding-bottom: var(--spacing-scale-7x) !important;
}
.pt-7x,
.py-7x {
  padding-top: var(--spacing-scale-7x) !important;
}
.pt-n7x {
  padding-top: calc(var(--spacing-scale-7x) * -1) !important;
}
.pr-7x {
  padding-right: var(--spacing-scale-7x) !important;
}
.pr-n7x {
  padding-right: calc(var(--spacing-scale-7x) * -1) !important;
}
.pb-7x {
  padding-bottom: var(--spacing-scale-7x) !important;
}
.pb-n7x {
  padding-bottom: calc(var(--spacing-scale-7x) * -1) !important;
}
.pl-7x {
  padding-left: var(--spacing-scale-7x) !important;
}
.pl-n7x {
  padding-left: calc(var(--spacing-scale-7x) * -1) !important;
}
.p-n7x {
  padding: calc(var(--spacing-scale-7x) * -1) !important;
}
.px-n7x {
  padding-left: calc(var(--spacing-scale-7x) * -1) !important;
  padding-right: calc(var(--spacing-scale-7x) * -1) !important;
}
.py-n7x {
  padding-bottom: calc(var(--spacing-scale-7x) * -1) !important;
  padding-top: calc(var(--spacing-scale-7x) * -1) !important;
}
.p-7xh {
  padding: var(--spacing-scale-7xh) !important;
}
.px-7xh {
  padding-left: var(--spacing-scale-7xh) !important;
  padding-right: var(--spacing-scale-7xh) !important;
}
.py-7xh {
  padding-bottom: var(--spacing-scale-7xh) !important;
}
.pt-7xh,
.py-7xh {
  padding-top: var(--spacing-scale-7xh) !important;
}
.pt-n7xh {
  padding-top: calc(var(--spacing-scale-7xh) * -1) !important;
}
.pr-7xh {
  padding-right: var(--spacing-scale-7xh) !important;
}
.pr-n7xh {
  padding-right: calc(var(--spacing-scale-7xh) * -1) !important;
}
.pb-7xh {
  padding-bottom: var(--spacing-scale-7xh) !important;
}
.pb-n7xh {
  padding-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
}
.pl-7xh {
  padding-left: var(--spacing-scale-7xh) !important;
}
.pl-n7xh {
  padding-left: calc(var(--spacing-scale-7xh) * -1) !important;
}
.p-n7xh {
  padding: calc(var(--spacing-scale-7xh) * -1) !important;
}
.px-n7xh {
  padding-left: calc(var(--spacing-scale-7xh) * -1) !important;
  padding-right: calc(var(--spacing-scale-7xh) * -1) !important;
}
.py-n7xh {
  padding-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
  padding-top: calc(var(--spacing-scale-7xh) * -1) !important;
}
.p-8x {
  padding: var(--spacing-scale-8x) !important;
}
.px-8x {
  padding-left: var(--spacing-scale-8x) !important;
  padding-right: var(--spacing-scale-8x) !important;
}
.py-8x {
  padding-bottom: var(--spacing-scale-8x) !important;
}
.pt-8x,
.py-8x {
  padding-top: var(--spacing-scale-8x) !important;
}
.pt-n8x {
  padding-top: calc(var(--spacing-scale-8x) * -1) !important;
}
.pr-8x {
  padding-right: var(--spacing-scale-8x) !important;
}
.pr-n8x {
  padding-right: calc(var(--spacing-scale-8x) * -1) !important;
}
.pb-8x {
  padding-bottom: var(--spacing-scale-8x) !important;
}
.pb-n8x {
  padding-bottom: calc(var(--spacing-scale-8x) * -1) !important;
}
.pl-8x {
  padding-left: var(--spacing-scale-8x) !important;
}
.pl-n8x {
  padding-left: calc(var(--spacing-scale-8x) * -1) !important;
}
.p-n8x {
  padding: calc(var(--spacing-scale-8x) * -1) !important;
}
.px-n8x {
  padding-left: calc(var(--spacing-scale-8x) * -1) !important;
  padding-right: calc(var(--spacing-scale-8x) * -1) !important;
}
.py-n8x {
  padding-bottom: calc(var(--spacing-scale-8x) * -1) !important;
  padding-top: calc(var(--spacing-scale-8x) * -1) !important;
}
.p-8xh {
  padding: var(--spacing-scale-8xh) !important;
}
.px-8xh {
  padding-left: var(--spacing-scale-8xh) !important;
  padding-right: var(--spacing-scale-8xh) !important;
}
.py-8xh {
  padding-bottom: var(--spacing-scale-8xh) !important;
}
.pt-8xh,
.py-8xh {
  padding-top: var(--spacing-scale-8xh) !important;
}
.pt-n8xh {
  padding-top: calc(var(--spacing-scale-8xh) * -1) !important;
}
.pr-8xh {
  padding-right: var(--spacing-scale-8xh) !important;
}
.pr-n8xh {
  padding-right: calc(var(--spacing-scale-8xh) * -1) !important;
}
.pb-8xh {
  padding-bottom: var(--spacing-scale-8xh) !important;
}
.pb-n8xh {
  padding-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
}
.pl-8xh {
  padding-left: var(--spacing-scale-8xh) !important;
}
.pl-n8xh {
  padding-left: calc(var(--spacing-scale-8xh) * -1) !important;
}
.p-n8xh {
  padding: calc(var(--spacing-scale-8xh) * -1) !important;
}
.px-n8xh {
  padding-left: calc(var(--spacing-scale-8xh) * -1) !important;
  padding-right: calc(var(--spacing-scale-8xh) * -1) !important;
}
.py-n8xh {
  padding-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
  padding-top: calc(var(--spacing-scale-8xh) * -1) !important;
}
.p-9x {
  padding: var(--spacing-scale-9x) !important;
}
.px-9x {
  padding-left: var(--spacing-scale-9x) !important;
  padding-right: var(--spacing-scale-9x) !important;
}
.py-9x {
  padding-bottom: var(--spacing-scale-9x) !important;
}
.pt-9x,
.py-9x {
  padding-top: var(--spacing-scale-9x) !important;
}
.pt-n9x {
  padding-top: calc(var(--spacing-scale-9x) * -1) !important;
}
.pr-9x {
  padding-right: var(--spacing-scale-9x) !important;
}
.pr-n9x {
  padding-right: calc(var(--spacing-scale-9x) * -1) !important;
}
.pb-9x {
  padding-bottom: var(--spacing-scale-9x) !important;
}
.pb-n9x {
  padding-bottom: calc(var(--spacing-scale-9x) * -1) !important;
}
.pl-9x {
  padding-left: var(--spacing-scale-9x) !important;
}
.pl-n9x {
  padding-left: calc(var(--spacing-scale-9x) * -1) !important;
}
.p-n9x {
  padding: calc(var(--spacing-scale-9x) * -1) !important;
}
.px-n9x {
  padding-left: calc(var(--spacing-scale-9x) * -1) !important;
  padding-right: calc(var(--spacing-scale-9x) * -1) !important;
}
.py-n9x {
  padding-bottom: calc(var(--spacing-scale-9x) * -1) !important;
  padding-top: calc(var(--spacing-scale-9x) * -1) !important;
}
.p-9xh {
  padding: var(--spacing-scale-9xh) !important;
}
.px-9xh {
  padding-left: var(--spacing-scale-9xh) !important;
  padding-right: var(--spacing-scale-9xh) !important;
}
.py-9xh {
  padding-bottom: var(--spacing-scale-9xh) !important;
}
.pt-9xh,
.py-9xh {
  padding-top: var(--spacing-scale-9xh) !important;
}
.pt-n9xh {
  padding-top: calc(var(--spacing-scale-9xh) * -1) !important;
}
.pr-9xh {
  padding-right: var(--spacing-scale-9xh) !important;
}
.pr-n9xh {
  padding-right: calc(var(--spacing-scale-9xh) * -1) !important;
}
.pb-9xh {
  padding-bottom: var(--spacing-scale-9xh) !important;
}
.pb-n9xh {
  padding-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
}
.pl-9xh {
  padding-left: var(--spacing-scale-9xh) !important;
}
.pl-n9xh {
  padding-left: calc(var(--spacing-scale-9xh) * -1) !important;
}
.p-n9xh {
  padding: calc(var(--spacing-scale-9xh) * -1) !important;
}
.px-n9xh {
  padding-left: calc(var(--spacing-scale-9xh) * -1) !important;
  padding-right: calc(var(--spacing-scale-9xh) * -1) !important;
}
.py-n9xh {
  padding-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
  padding-top: calc(var(--spacing-scale-9xh) * -1) !important;
}
.p-10x {
  padding: var(--spacing-scale-10x) !important;
}
.px-10x {
  padding-left: var(--spacing-scale-10x) !important;
  padding-right: var(--spacing-scale-10x) !important;
}
.py-10x {
  padding-bottom: var(--spacing-scale-10x) !important;
}
.pt-10x,
.py-10x {
  padding-top: var(--spacing-scale-10x) !important;
}
.pt-n10x {
  padding-top: calc(var(--spacing-scale-10x) * -1) !important;
}
.pr-10x {
  padding-right: var(--spacing-scale-10x) !important;
}
.pr-n10x {
  padding-right: calc(var(--spacing-scale-10x) * -1) !important;
}
.pb-10x {
  padding-bottom: var(--spacing-scale-10x) !important;
}
.pb-n10x {
  padding-bottom: calc(var(--spacing-scale-10x) * -1) !important;
}
.pl-10x {
  padding-left: var(--spacing-scale-10x) !important;
}
.pl-n10x {
  padding-left: calc(var(--spacing-scale-10x) * -1) !important;
}
.p-n10x {
  padding: calc(var(--spacing-scale-10x) * -1) !important;
}
.px-n10x {
  padding-left: calc(var(--spacing-scale-10x) * -1) !important;
  padding-right: calc(var(--spacing-scale-10x) * -1) !important;
}
.py-n10x {
  padding-bottom: calc(var(--spacing-scale-10x) * -1) !important;
  padding-top: calc(var(--spacing-scale-10x) * -1) !important;
}
.p-10xh {
  padding: var(--spacing-scale-10xh) !important;
}
.px-10xh {
  padding-left: var(--spacing-scale-10xh) !important;
  padding-right: var(--spacing-scale-10xh) !important;
}
.py-10xh {
  padding-bottom: var(--spacing-scale-10xh) !important;
}
.pt-10xh,
.py-10xh {
  padding-top: var(--spacing-scale-10xh) !important;
}
.pt-n10xh {
  padding-top: calc(var(--spacing-scale-10xh) * -1) !important;
}
.pr-10xh {
  padding-right: var(--spacing-scale-10xh) !important;
}
.pr-n10xh {
  padding-right: calc(var(--spacing-scale-10xh) * -1) !important;
}
.pb-10xh {
  padding-bottom: var(--spacing-scale-10xh) !important;
}
.pb-n10xh {
  padding-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
}
.pl-10xh {
  padding-left: var(--spacing-scale-10xh) !important;
}
.pl-n10xh {
  padding-left: calc(var(--spacing-scale-10xh) * -1) !important;
}
.p-n10xh {
  padding: calc(var(--spacing-scale-10xh) * -1) !important;
}
.px-n10xh {
  padding-left: calc(var(--spacing-scale-10xh) * -1) !important;
  padding-right: calc(var(--spacing-scale-10xh) * -1) !important;
}
.py-n10xh {
  padding-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
  padding-top: calc(var(--spacing-scale-10xh) * -1) !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: var(--spacing-scale-default) !important;
  }
  .mx-sm-0 {
    margin-left: var(--spacing-scale-default) !important;
    margin-right: var(--spacing-scale-default) !important;
  }
  .my-sm-0 {
    margin-bottom: var(--spacing-scale-default) !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: var(--spacing-scale-default) !important;
  }
  .mr-sm-0 {
    margin-right: var(--spacing-scale-default) !important;
  }
  .mb-sm-0 {
    margin-bottom: var(--spacing-scale-default) !important;
  }
  .ml-sm-0 {
    margin-left: var(--spacing-scale-default) !important;
  }
  .m-sm-half {
    margin: var(--spacing-scale-half) !important;
  }
  .mx-sm-half {
    margin-left: var(--spacing-scale-half) !important;
    margin-right: var(--spacing-scale-half) !important;
  }
  .my-sm-half {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mt-sm-half,
  .my-sm-half {
    margin-top: var(--spacing-scale-half) !important;
  }
  .mt-sm-nhalf {
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mr-sm-half {
    margin-right: var(--spacing-scale-half) !important;
  }
  .mr-sm-nhalf {
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mb-sm-half {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mb-sm-nhalf {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .ml-sm-half {
    margin-left: var(--spacing-scale-half) !important;
  }
  .ml-sm-nhalf {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-sm-nhalf {
    margin: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mx-sm-nhalf {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .my-sm-nhalf {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-sm-base {
    margin: var(--spacing-scale-base) !important;
  }
  .mx-sm-base {
    margin-left: var(--spacing-scale-base) !important;
    margin-right: var(--spacing-scale-base) !important;
  }
  .my-sm-base {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mt-sm-base,
  .my-sm-base {
    margin-top: var(--spacing-scale-base) !important;
  }
  .mt-sm-nbase {
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mr-sm-base {
    margin-right: var(--spacing-scale-base) !important;
  }
  .mr-sm-nbase {
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mb-sm-base {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mb-sm-nbase {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .ml-sm-base {
    margin-left: var(--spacing-scale-base) !important;
  }
  .ml-sm-nbase {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-sm-nbase {
    margin: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mx-sm-nbase {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .my-sm-nbase {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-sm-baseh {
    margin: var(--spacing-scale-baseh) !important;
  }
  .mx-sm-baseh {
    margin-left: var(--spacing-scale-baseh) !important;
    margin-right: var(--spacing-scale-baseh) !important;
  }
  .my-sm-baseh {
    margin-bottom: var(--spacing-scale-baseh) !important;
  }
  .mt-sm-baseh,
  .my-sm-baseh {
    margin-top: var(--spacing-scale-baseh) !important;
  }
  .mt-sm-nbaseh {
    margin-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mr-sm-baseh {
    margin-right: var(--spacing-scale-baseh) !important;
  }
  .mr-sm-nbaseh {
    margin-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mb-sm-baseh {
    margin-bottom: var(--spacing-scale-baseh) !important;
  }
  .mb-sm-nbaseh {
    margin-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .ml-sm-baseh {
    margin-left: var(--spacing-scale-baseh) !important;
  }
  .ml-sm-nbaseh {
    margin-left: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .m-sm-nbaseh {
    margin: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mx-sm-nbaseh {
    margin-left: calc(var(--spacing-scale-baseh) * -1) !important;
    margin-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .my-sm-nbaseh {
    margin-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
    margin-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .m-sm-1 {
    margin: var(--spacing-scale-half) !important;
  }
  .mx-sm-1 {
    margin-left: var(--spacing-scale-half) !important;
    margin-right: var(--spacing-scale-half) !important;
  }
  .my-sm-1 {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: var(--spacing-scale-half) !important;
  }
  .mt-sm-n1 {
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mr-sm-1 {
    margin-right: var(--spacing-scale-half) !important;
  }
  .mr-sm-n1 {
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mb-sm-1 {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mb-sm-n1 {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .ml-sm-1 {
    margin-left: var(--spacing-scale-half) !important;
  }
  .ml-sm-n1 {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-sm-n1 {
    margin: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mx-sm-n1 {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .my-sm-n1 {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-sm-2 {
    margin: var(--spacing-scale-base) !important;
  }
  .mx-sm-2 {
    margin-left: var(--spacing-scale-base) !important;
    margin-right: var(--spacing-scale-base) !important;
  }
  .my-sm-2 {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: var(--spacing-scale-base) !important;
  }
  .mt-sm-n2 {
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mr-sm-2 {
    margin-right: var(--spacing-scale-base) !important;
  }
  .mr-sm-n2 {
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mb-sm-2 {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mb-sm-n2 {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .ml-sm-2 {
    margin-left: var(--spacing-scale-base) !important;
  }
  .ml-sm-n2 {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-sm-n2 {
    margin: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mx-sm-n2 {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .my-sm-n2 {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-sm-3 {
    margin: var(--spacing-scale-2x) !important;
  }
  .mx-sm-3 {
    margin-left: var(--spacing-scale-2x) !important;
    margin-right: var(--spacing-scale-2x) !important;
  }
  .my-sm-3 {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: var(--spacing-scale-2x) !important;
  }
  .mt-sm-n3 {
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mr-sm-3 {
    margin-right: var(--spacing-scale-2x) !important;
  }
  .mr-sm-n3 {
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mb-sm-3 {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mb-sm-n3 {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .ml-sm-3 {
    margin-left: var(--spacing-scale-2x) !important;
  }
  .ml-sm-n3 {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-sm-n3 {
    margin: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mx-sm-n3 {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .my-sm-n3 {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-sm-4 {
    margin: var(--spacing-scale-3x) !important;
  }
  .mx-sm-4 {
    margin-left: var(--spacing-scale-3x) !important;
    margin-right: var(--spacing-scale-3x) !important;
  }
  .my-sm-4 {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: var(--spacing-scale-3x) !important;
  }
  .mt-sm-n4 {
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mr-sm-4 {
    margin-right: var(--spacing-scale-3x) !important;
  }
  .mr-sm-n4 {
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mb-sm-4 {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mb-sm-n4 {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .ml-sm-4 {
    margin-left: var(--spacing-scale-3x) !important;
  }
  .ml-sm-n4 {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-sm-n4 {
    margin: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mx-sm-n4 {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .my-sm-n4 {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-sm-5 {
    margin: var(--spacing-scale-4x) !important;
  }
  .mx-sm-5 {
    margin-left: var(--spacing-scale-4x) !important;
    margin-right: var(--spacing-scale-4x) !important;
  }
  .my-sm-5 {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: var(--spacing-scale-4x) !important;
  }
  .mt-sm-n5 {
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mr-sm-5 {
    margin-right: var(--spacing-scale-4x) !important;
  }
  .mr-sm-n5 {
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mb-sm-5 {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mb-sm-n5 {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .ml-sm-5 {
    margin-left: var(--spacing-scale-4x) !important;
  }
  .ml-sm-n5 {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-sm-n5 {
    margin: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mx-sm-n5 {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .my-sm-n5 {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-sm-6 {
    margin: var(--spacing-scale-5x) !important;
  }
  .mx-sm-6 {
    margin-left: var(--spacing-scale-5x) !important;
    margin-right: var(--spacing-scale-5x) !important;
  }
  .my-sm-6 {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: var(--spacing-scale-5x) !important;
  }
  .mt-sm-n6 {
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mr-sm-6 {
    margin-right: var(--spacing-scale-5x) !important;
  }
  .mr-sm-n6 {
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mb-sm-6 {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mb-sm-n6 {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .ml-sm-6 {
    margin-left: var(--spacing-scale-5x) !important;
  }
  .ml-sm-n6 {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-sm-n6 {
    margin: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mx-sm-n6 {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .my-sm-n6 {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-sm-2x {
    margin: var(--spacing-scale-2x) !important;
  }
  .mx-sm-2x {
    margin-left: var(--spacing-scale-2x) !important;
    margin-right: var(--spacing-scale-2x) !important;
  }
  .my-sm-2x {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mt-sm-2x,
  .my-sm-2x {
    margin-top: var(--spacing-scale-2x) !important;
  }
  .mt-sm-n2x {
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mr-sm-2x {
    margin-right: var(--spacing-scale-2x) !important;
  }
  .mr-sm-n2x {
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mb-sm-2x {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mb-sm-n2x {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .ml-sm-2x {
    margin-left: var(--spacing-scale-2x) !important;
  }
  .ml-sm-n2x {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-sm-n2x {
    margin: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mx-sm-n2x {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .my-sm-n2x {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-sm-2xh {
    margin: var(--spacing-scale-2xh) !important;
  }
  .mx-sm-2xh {
    margin-left: var(--spacing-scale-2xh) !important;
    margin-right: var(--spacing-scale-2xh) !important;
  }
  .my-sm-2xh {
    margin-bottom: var(--spacing-scale-2xh) !important;
  }
  .mt-sm-2xh,
  .my-sm-2xh {
    margin-top: var(--spacing-scale-2xh) !important;
  }
  .mt-sm-n2xh {
    margin-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mr-sm-2xh {
    margin-right: var(--spacing-scale-2xh) !important;
  }
  .mr-sm-n2xh {
    margin-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mb-sm-2xh {
    margin-bottom: var(--spacing-scale-2xh) !important;
  }
  .mb-sm-n2xh {
    margin-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .ml-sm-2xh {
    margin-left: var(--spacing-scale-2xh) !important;
  }
  .ml-sm-n2xh {
    margin-left: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .m-sm-n2xh {
    margin: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mx-sm-n2xh {
    margin-left: calc(var(--spacing-scale-2xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .my-sm-n2xh {
    margin-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .m-sm-3x {
    margin: var(--spacing-scale-3x) !important;
  }
  .mx-sm-3x {
    margin-left: var(--spacing-scale-3x) !important;
    margin-right: var(--spacing-scale-3x) !important;
  }
  .my-sm-3x {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mt-sm-3x,
  .my-sm-3x {
    margin-top: var(--spacing-scale-3x) !important;
  }
  .mt-sm-n3x {
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mr-sm-3x {
    margin-right: var(--spacing-scale-3x) !important;
  }
  .mr-sm-n3x {
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mb-sm-3x {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mb-sm-n3x {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .ml-sm-3x {
    margin-left: var(--spacing-scale-3x) !important;
  }
  .ml-sm-n3x {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-sm-n3x {
    margin: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mx-sm-n3x {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .my-sm-n3x {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-sm-3xh {
    margin: var(--spacing-scale-3xh) !important;
  }
  .mx-sm-3xh {
    margin-left: var(--spacing-scale-3xh) !important;
    margin-right: var(--spacing-scale-3xh) !important;
  }
  .my-sm-3xh {
    margin-bottom: var(--spacing-scale-3xh) !important;
  }
  .mt-sm-3xh,
  .my-sm-3xh {
    margin-top: var(--spacing-scale-3xh) !important;
  }
  .mt-sm-n3xh {
    margin-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mr-sm-3xh {
    margin-right: var(--spacing-scale-3xh) !important;
  }
  .mr-sm-n3xh {
    margin-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mb-sm-3xh {
    margin-bottom: var(--spacing-scale-3xh) !important;
  }
  .mb-sm-n3xh {
    margin-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .ml-sm-3xh {
    margin-left: var(--spacing-scale-3xh) !important;
  }
  .ml-sm-n3xh {
    margin-left: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .m-sm-n3xh {
    margin: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mx-sm-n3xh {
    margin-left: calc(var(--spacing-scale-3xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .my-sm-n3xh {
    margin-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .m-sm-4x {
    margin: var(--spacing-scale-4x) !important;
  }
  .mx-sm-4x {
    margin-left: var(--spacing-scale-4x) !important;
    margin-right: var(--spacing-scale-4x) !important;
  }
  .my-sm-4x {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mt-sm-4x,
  .my-sm-4x {
    margin-top: var(--spacing-scale-4x) !important;
  }
  .mt-sm-n4x {
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mr-sm-4x {
    margin-right: var(--spacing-scale-4x) !important;
  }
  .mr-sm-n4x {
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mb-sm-4x {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mb-sm-n4x {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .ml-sm-4x {
    margin-left: var(--spacing-scale-4x) !important;
  }
  .ml-sm-n4x {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-sm-n4x {
    margin: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mx-sm-n4x {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .my-sm-n4x {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-sm-4xh {
    margin: var(--spacing-scale-4xh) !important;
  }
  .mx-sm-4xh {
    margin-left: var(--spacing-scale-4xh) !important;
    margin-right: var(--spacing-scale-4xh) !important;
  }
  .my-sm-4xh {
    margin-bottom: var(--spacing-scale-4xh) !important;
  }
  .mt-sm-4xh,
  .my-sm-4xh {
    margin-top: var(--spacing-scale-4xh) !important;
  }
  .mt-sm-n4xh {
    margin-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mr-sm-4xh {
    margin-right: var(--spacing-scale-4xh) !important;
  }
  .mr-sm-n4xh {
    margin-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mb-sm-4xh {
    margin-bottom: var(--spacing-scale-4xh) !important;
  }
  .mb-sm-n4xh {
    margin-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .ml-sm-4xh {
    margin-left: var(--spacing-scale-4xh) !important;
  }
  .ml-sm-n4xh {
    margin-left: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .m-sm-n4xh {
    margin: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mx-sm-n4xh {
    margin-left: calc(var(--spacing-scale-4xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .my-sm-n4xh {
    margin-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .m-sm-5x {
    margin: var(--spacing-scale-5x) !important;
  }
  .mx-sm-5x {
    margin-left: var(--spacing-scale-5x) !important;
    margin-right: var(--spacing-scale-5x) !important;
  }
  .my-sm-5x {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mt-sm-5x,
  .my-sm-5x {
    margin-top: var(--spacing-scale-5x) !important;
  }
  .mt-sm-n5x {
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mr-sm-5x {
    margin-right: var(--spacing-scale-5x) !important;
  }
  .mr-sm-n5x {
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mb-sm-5x {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mb-sm-n5x {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .ml-sm-5x {
    margin-left: var(--spacing-scale-5x) !important;
  }
  .ml-sm-n5x {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-sm-n5x {
    margin: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mx-sm-n5x {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .my-sm-n5x {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-sm-5xh {
    margin: var(--spacing-scale-5xh) !important;
  }
  .mx-sm-5xh {
    margin-left: var(--spacing-scale-5xh) !important;
    margin-right: var(--spacing-scale-5xh) !important;
  }
  .my-sm-5xh {
    margin-bottom: var(--spacing-scale-5xh) !important;
  }
  .mt-sm-5xh,
  .my-sm-5xh {
    margin-top: var(--spacing-scale-5xh) !important;
  }
  .mt-sm-n5xh {
    margin-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mr-sm-5xh {
    margin-right: var(--spacing-scale-5xh) !important;
  }
  .mr-sm-n5xh {
    margin-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mb-sm-5xh {
    margin-bottom: var(--spacing-scale-5xh) !important;
  }
  .mb-sm-n5xh {
    margin-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .ml-sm-5xh {
    margin-left: var(--spacing-scale-5xh) !important;
  }
  .ml-sm-n5xh {
    margin-left: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .m-sm-n5xh {
    margin: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mx-sm-n5xh {
    margin-left: calc(var(--spacing-scale-5xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .my-sm-n5xh {
    margin-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .m-sm-6x {
    margin: var(--spacing-scale-6x) !important;
  }
  .mx-sm-6x {
    margin-left: var(--spacing-scale-6x) !important;
    margin-right: var(--spacing-scale-6x) !important;
  }
  .my-sm-6x {
    margin-bottom: var(--spacing-scale-6x) !important;
  }
  .mt-sm-6x,
  .my-sm-6x {
    margin-top: var(--spacing-scale-6x) !important;
  }
  .mt-sm-n6x {
    margin-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mr-sm-6x {
    margin-right: var(--spacing-scale-6x) !important;
  }
  .mr-sm-n6x {
    margin-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mb-sm-6x {
    margin-bottom: var(--spacing-scale-6x) !important;
  }
  .mb-sm-n6x {
    margin-bottom: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .ml-sm-6x {
    margin-left: var(--spacing-scale-6x) !important;
  }
  .ml-sm-n6x {
    margin-left: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .m-sm-n6x {
    margin: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mx-sm-n6x {
    margin-left: calc(var(--spacing-scale-6x) * -1) !important;
    margin-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .my-sm-n6x {
    margin-bottom: calc(var(--spacing-scale-6x) * -1) !important;
    margin-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .m-sm-6xh {
    margin: var(--spacing-scale-6xh) !important;
  }
  .mx-sm-6xh {
    margin-left: var(--spacing-scale-6xh) !important;
    margin-right: var(--spacing-scale-6xh) !important;
  }
  .my-sm-6xh {
    margin-bottom: var(--spacing-scale-6xh) !important;
  }
  .mt-sm-6xh,
  .my-sm-6xh {
    margin-top: var(--spacing-scale-6xh) !important;
  }
  .mt-sm-n6xh {
    margin-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mr-sm-6xh {
    margin-right: var(--spacing-scale-6xh) !important;
  }
  .mr-sm-n6xh {
    margin-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mb-sm-6xh {
    margin-bottom: var(--spacing-scale-6xh) !important;
  }
  .mb-sm-n6xh {
    margin-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .ml-sm-6xh {
    margin-left: var(--spacing-scale-6xh) !important;
  }
  .ml-sm-n6xh {
    margin-left: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .m-sm-n6xh {
    margin: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mx-sm-n6xh {
    margin-left: calc(var(--spacing-scale-6xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .my-sm-n6xh {
    margin-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .m-sm-7x {
    margin: var(--spacing-scale-7x) !important;
  }
  .mx-sm-7x {
    margin-left: var(--spacing-scale-7x) !important;
    margin-right: var(--spacing-scale-7x) !important;
  }
  .my-sm-7x {
    margin-bottom: var(--spacing-scale-7x) !important;
  }
  .mt-sm-7x,
  .my-sm-7x {
    margin-top: var(--spacing-scale-7x) !important;
  }
  .mt-sm-n7x {
    margin-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mr-sm-7x {
    margin-right: var(--spacing-scale-7x) !important;
  }
  .mr-sm-n7x {
    margin-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mb-sm-7x {
    margin-bottom: var(--spacing-scale-7x) !important;
  }
  .mb-sm-n7x {
    margin-bottom: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .ml-sm-7x {
    margin-left: var(--spacing-scale-7x) !important;
  }
  .ml-sm-n7x {
    margin-left: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .m-sm-n7x {
    margin: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mx-sm-n7x {
    margin-left: calc(var(--spacing-scale-7x) * -1) !important;
    margin-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .my-sm-n7x {
    margin-bottom: calc(var(--spacing-scale-7x) * -1) !important;
    margin-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .m-sm-7xh {
    margin: var(--spacing-scale-7xh) !important;
  }
  .mx-sm-7xh {
    margin-left: var(--spacing-scale-7xh) !important;
    margin-right: var(--spacing-scale-7xh) !important;
  }
  .my-sm-7xh {
    margin-bottom: var(--spacing-scale-7xh) !important;
  }
  .mt-sm-7xh,
  .my-sm-7xh {
    margin-top: var(--spacing-scale-7xh) !important;
  }
  .mt-sm-n7xh {
    margin-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mr-sm-7xh {
    margin-right: var(--spacing-scale-7xh) !important;
  }
  .mr-sm-n7xh {
    margin-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mb-sm-7xh {
    margin-bottom: var(--spacing-scale-7xh) !important;
  }
  .mb-sm-n7xh {
    margin-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .ml-sm-7xh {
    margin-left: var(--spacing-scale-7xh) !important;
  }
  .ml-sm-n7xh {
    margin-left: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .m-sm-n7xh {
    margin: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mx-sm-n7xh {
    margin-left: calc(var(--spacing-scale-7xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .my-sm-n7xh {
    margin-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .m-sm-8x {
    margin: var(--spacing-scale-8x) !important;
  }
  .mx-sm-8x {
    margin-left: var(--spacing-scale-8x) !important;
    margin-right: var(--spacing-scale-8x) !important;
  }
  .my-sm-8x {
    margin-bottom: var(--spacing-scale-8x) !important;
  }
  .mt-sm-8x,
  .my-sm-8x {
    margin-top: var(--spacing-scale-8x) !important;
  }
  .mt-sm-n8x {
    margin-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mr-sm-8x {
    margin-right: var(--spacing-scale-8x) !important;
  }
  .mr-sm-n8x {
    margin-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mb-sm-8x {
    margin-bottom: var(--spacing-scale-8x) !important;
  }
  .mb-sm-n8x {
    margin-bottom: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .ml-sm-8x {
    margin-left: var(--spacing-scale-8x) !important;
  }
  .ml-sm-n8x {
    margin-left: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .m-sm-n8x {
    margin: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mx-sm-n8x {
    margin-left: calc(var(--spacing-scale-8x) * -1) !important;
    margin-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .my-sm-n8x {
    margin-bottom: calc(var(--spacing-scale-8x) * -1) !important;
    margin-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .m-sm-8xh {
    margin: var(--spacing-scale-8xh) !important;
  }
  .mx-sm-8xh {
    margin-left: var(--spacing-scale-8xh) !important;
    margin-right: var(--spacing-scale-8xh) !important;
  }
  .my-sm-8xh {
    margin-bottom: var(--spacing-scale-8xh) !important;
  }
  .mt-sm-8xh,
  .my-sm-8xh {
    margin-top: var(--spacing-scale-8xh) !important;
  }
  .mt-sm-n8xh {
    margin-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mr-sm-8xh {
    margin-right: var(--spacing-scale-8xh) !important;
  }
  .mr-sm-n8xh {
    margin-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mb-sm-8xh {
    margin-bottom: var(--spacing-scale-8xh) !important;
  }
  .mb-sm-n8xh {
    margin-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .ml-sm-8xh {
    margin-left: var(--spacing-scale-8xh) !important;
  }
  .ml-sm-n8xh {
    margin-left: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .m-sm-n8xh {
    margin: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mx-sm-n8xh {
    margin-left: calc(var(--spacing-scale-8xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .my-sm-n8xh {
    margin-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .m-sm-9x {
    margin: var(--spacing-scale-9x) !important;
  }
  .mx-sm-9x {
    margin-left: var(--spacing-scale-9x) !important;
    margin-right: var(--spacing-scale-9x) !important;
  }
  .my-sm-9x {
    margin-bottom: var(--spacing-scale-9x) !important;
  }
  .mt-sm-9x,
  .my-sm-9x {
    margin-top: var(--spacing-scale-9x) !important;
  }
  .mt-sm-n9x {
    margin-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mr-sm-9x {
    margin-right: var(--spacing-scale-9x) !important;
  }
  .mr-sm-n9x {
    margin-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mb-sm-9x {
    margin-bottom: var(--spacing-scale-9x) !important;
  }
  .mb-sm-n9x {
    margin-bottom: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .ml-sm-9x {
    margin-left: var(--spacing-scale-9x) !important;
  }
  .ml-sm-n9x {
    margin-left: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .m-sm-n9x {
    margin: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mx-sm-n9x {
    margin-left: calc(var(--spacing-scale-9x) * -1) !important;
    margin-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .my-sm-n9x {
    margin-bottom: calc(var(--spacing-scale-9x) * -1) !important;
    margin-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .m-sm-9xh {
    margin: var(--spacing-scale-9xh) !important;
  }
  .mx-sm-9xh {
    margin-left: var(--spacing-scale-9xh) !important;
    margin-right: var(--spacing-scale-9xh) !important;
  }
  .my-sm-9xh {
    margin-bottom: var(--spacing-scale-9xh) !important;
  }
  .mt-sm-9xh,
  .my-sm-9xh {
    margin-top: var(--spacing-scale-9xh) !important;
  }
  .mt-sm-n9xh {
    margin-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mr-sm-9xh {
    margin-right: var(--spacing-scale-9xh) !important;
  }
  .mr-sm-n9xh {
    margin-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mb-sm-9xh {
    margin-bottom: var(--spacing-scale-9xh) !important;
  }
  .mb-sm-n9xh {
    margin-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .ml-sm-9xh {
    margin-left: var(--spacing-scale-9xh) !important;
  }
  .ml-sm-n9xh {
    margin-left: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .m-sm-n9xh {
    margin: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mx-sm-n9xh {
    margin-left: calc(var(--spacing-scale-9xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .my-sm-n9xh {
    margin-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .m-sm-10x {
    margin: var(--spacing-scale-10x) !important;
  }
  .mx-sm-10x {
    margin-left: var(--spacing-scale-10x) !important;
    margin-right: var(--spacing-scale-10x) !important;
  }
  .my-sm-10x {
    margin-bottom: var(--spacing-scale-10x) !important;
  }
  .mt-sm-10x,
  .my-sm-10x {
    margin-top: var(--spacing-scale-10x) !important;
  }
  .mt-sm-n10x {
    margin-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mr-sm-10x {
    margin-right: var(--spacing-scale-10x) !important;
  }
  .mr-sm-n10x {
    margin-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mb-sm-10x {
    margin-bottom: var(--spacing-scale-10x) !important;
  }
  .mb-sm-n10x {
    margin-bottom: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .ml-sm-10x {
    margin-left: var(--spacing-scale-10x) !important;
  }
  .ml-sm-n10x {
    margin-left: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .m-sm-n10x {
    margin: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mx-sm-n10x {
    margin-left: calc(var(--spacing-scale-10x) * -1) !important;
    margin-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .my-sm-n10x {
    margin-bottom: calc(var(--spacing-scale-10x) * -1) !important;
    margin-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .m-sm-10xh {
    margin: var(--spacing-scale-10xh) !important;
  }
  .mx-sm-10xh {
    margin-left: var(--spacing-scale-10xh) !important;
    margin-right: var(--spacing-scale-10xh) !important;
  }
  .my-sm-10xh {
    margin-bottom: var(--spacing-scale-10xh) !important;
  }
  .mt-sm-10xh,
  .my-sm-10xh {
    margin-top: var(--spacing-scale-10xh) !important;
  }
  .mt-sm-n10xh {
    margin-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mr-sm-10xh {
    margin-right: var(--spacing-scale-10xh) !important;
  }
  .mr-sm-n10xh {
    margin-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mb-sm-10xh {
    margin-bottom: var(--spacing-scale-10xh) !important;
  }
  .mb-sm-n10xh {
    margin-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .ml-sm-10xh {
    margin-left: var(--spacing-scale-10xh) !important;
  }
  .ml-sm-n10xh {
    margin-left: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .m-sm-n10xh {
    margin: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mx-sm-n10xh {
    margin-left: calc(var(--spacing-scale-10xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .my-sm-n10xh {
    margin-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: var(--spacing-scale-default) !important;
  }
  .px-sm-0 {
    padding-left: var(--spacing-scale-default) !important;
    padding-right: var(--spacing-scale-default) !important;
  }
  .py-sm-0 {
    padding-bottom: var(--spacing-scale-default) !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: var(--spacing-scale-default) !important;
  }
  .pr-sm-0 {
    padding-right: var(--spacing-scale-default) !important;
  }
  .pb-sm-0 {
    padding-bottom: var(--spacing-scale-default) !important;
  }
  .pl-sm-0 {
    padding-left: var(--spacing-scale-default) !important;
  }
  .p-sm-half {
    padding: var(--spacing-scale-half) !important;
  }
  .px-sm-half {
    padding-left: var(--spacing-scale-half) !important;
    padding-right: var(--spacing-scale-half) !important;
  }
  .py-sm-half {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pt-sm-half,
  .py-sm-half {
    padding-top: var(--spacing-scale-half) !important;
  }
  .pt-sm-nhalf {
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pr-sm-half {
    padding-right: var(--spacing-scale-half) !important;
  }
  .pr-sm-nhalf {
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pb-sm-half {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pb-sm-nhalf {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pl-sm-half {
    padding-left: var(--spacing-scale-half) !important;
  }
  .pl-sm-nhalf {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-sm-nhalf {
    padding: calc(var(--spacing-scale-half) * -1) !important;
  }
  .px-sm-nhalf {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .py-sm-nhalf {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-sm-base {
    padding: var(--spacing-scale-base) !important;
  }
  .px-sm-base {
    padding-left: var(--spacing-scale-base) !important;
    padding-right: var(--spacing-scale-base) !important;
  }
  .py-sm-base {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pt-sm-base,
  .py-sm-base {
    padding-top: var(--spacing-scale-base) !important;
  }
  .pt-sm-nbase {
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pr-sm-base {
    padding-right: var(--spacing-scale-base) !important;
  }
  .pr-sm-nbase {
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pb-sm-base {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pb-sm-nbase {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pl-sm-base {
    padding-left: var(--spacing-scale-base) !important;
  }
  .pl-sm-nbase {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-sm-nbase {
    padding: calc(var(--spacing-scale-base) * -1) !important;
  }
  .px-sm-nbase {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .py-sm-nbase {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-sm-baseh {
    padding: var(--spacing-scale-baseh) !important;
  }
  .px-sm-baseh {
    padding-left: var(--spacing-scale-baseh) !important;
    padding-right: var(--spacing-scale-baseh) !important;
  }
  .py-sm-baseh {
    padding-bottom: var(--spacing-scale-baseh) !important;
  }
  .pt-sm-baseh,
  .py-sm-baseh {
    padding-top: var(--spacing-scale-baseh) !important;
  }
  .pt-sm-nbaseh {
    padding-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pr-sm-baseh {
    padding-right: var(--spacing-scale-baseh) !important;
  }
  .pr-sm-nbaseh {
    padding-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pb-sm-baseh {
    padding-bottom: var(--spacing-scale-baseh) !important;
  }
  .pb-sm-nbaseh {
    padding-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pl-sm-baseh {
    padding-left: var(--spacing-scale-baseh) !important;
  }
  .pl-sm-nbaseh {
    padding-left: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .p-sm-nbaseh {
    padding: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .px-sm-nbaseh {
    padding-left: calc(var(--spacing-scale-baseh) * -1) !important;
    padding-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .py-sm-nbaseh {
    padding-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
    padding-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .p-sm-1 {
    padding: var(--spacing-scale-half) !important;
  }
  .px-sm-1 {
    padding-left: var(--spacing-scale-half) !important;
    padding-right: var(--spacing-scale-half) !important;
  }
  .py-sm-1 {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: var(--spacing-scale-half) !important;
  }
  .pt-sm-n1 {
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pr-sm-1 {
    padding-right: var(--spacing-scale-half) !important;
  }
  .pr-sm-n1 {
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pb-sm-1 {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pb-sm-n1 {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pl-sm-1 {
    padding-left: var(--spacing-scale-half) !important;
  }
  .pl-sm-n1 {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-sm-n1 {
    padding: calc(var(--spacing-scale-half) * -1) !important;
  }
  .px-sm-n1 {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .py-sm-n1 {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-sm-2 {
    padding: var(--spacing-scale-base) !important;
  }
  .px-sm-2 {
    padding-left: var(--spacing-scale-base) !important;
    padding-right: var(--spacing-scale-base) !important;
  }
  .py-sm-2 {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: var(--spacing-scale-base) !important;
  }
  .pt-sm-n2 {
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pr-sm-2 {
    padding-right: var(--spacing-scale-base) !important;
  }
  .pr-sm-n2 {
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pb-sm-2 {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pb-sm-n2 {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pl-sm-2 {
    padding-left: var(--spacing-scale-base) !important;
  }
  .pl-sm-n2 {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-sm-n2 {
    padding: calc(var(--spacing-scale-base) * -1) !important;
  }
  .px-sm-n2 {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .py-sm-n2 {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-sm-3 {
    padding: var(--spacing-scale-2x) !important;
  }
  .px-sm-3 {
    padding-left: var(--spacing-scale-2x) !important;
    padding-right: var(--spacing-scale-2x) !important;
  }
  .py-sm-3 {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: var(--spacing-scale-2x) !important;
  }
  .pt-sm-n3 {
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pr-sm-3 {
    padding-right: var(--spacing-scale-2x) !important;
  }
  .pr-sm-n3 {
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pb-sm-3 {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pb-sm-n3 {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pl-sm-3 {
    padding-left: var(--spacing-scale-2x) !important;
  }
  .pl-sm-n3 {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-sm-n3 {
    padding: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .px-sm-n3 {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .py-sm-n3 {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-sm-4 {
    padding: var(--spacing-scale-3x) !important;
  }
  .px-sm-4 {
    padding-left: var(--spacing-scale-3x) !important;
    padding-right: var(--spacing-scale-3x) !important;
  }
  .py-sm-4 {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: var(--spacing-scale-3x) !important;
  }
  .pt-sm-n4 {
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pr-sm-4 {
    padding-right: var(--spacing-scale-3x) !important;
  }
  .pr-sm-n4 {
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pb-sm-4 {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pb-sm-n4 {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pl-sm-4 {
    padding-left: var(--spacing-scale-3x) !important;
  }
  .pl-sm-n4 {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-sm-n4 {
    padding: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .px-sm-n4 {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .py-sm-n4 {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-sm-5 {
    padding: var(--spacing-scale-4x) !important;
  }
  .px-sm-5 {
    padding-left: var(--spacing-scale-4x) !important;
    padding-right: var(--spacing-scale-4x) !important;
  }
  .py-sm-5 {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: var(--spacing-scale-4x) !important;
  }
  .pt-sm-n5 {
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pr-sm-5 {
    padding-right: var(--spacing-scale-4x) !important;
  }
  .pr-sm-n5 {
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pb-sm-5 {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pb-sm-n5 {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pl-sm-5 {
    padding-left: var(--spacing-scale-4x) !important;
  }
  .pl-sm-n5 {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-sm-n5 {
    padding: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .px-sm-n5 {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .py-sm-n5 {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-sm-6 {
    padding: var(--spacing-scale-5x) !important;
  }
  .px-sm-6 {
    padding-left: var(--spacing-scale-5x) !important;
    padding-right: var(--spacing-scale-5x) !important;
  }
  .py-sm-6 {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: var(--spacing-scale-5x) !important;
  }
  .pt-sm-n6 {
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pr-sm-6 {
    padding-right: var(--spacing-scale-5x) !important;
  }
  .pr-sm-n6 {
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pb-sm-6 {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pb-sm-n6 {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pl-sm-6 {
    padding-left: var(--spacing-scale-5x) !important;
  }
  .pl-sm-n6 {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-sm-n6 {
    padding: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .px-sm-n6 {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .py-sm-n6 {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-sm-2x {
    padding: var(--spacing-scale-2x) !important;
  }
  .px-sm-2x {
    padding-left: var(--spacing-scale-2x) !important;
    padding-right: var(--spacing-scale-2x) !important;
  }
  .py-sm-2x {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pt-sm-2x,
  .py-sm-2x {
    padding-top: var(--spacing-scale-2x) !important;
  }
  .pt-sm-n2x {
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pr-sm-2x {
    padding-right: var(--spacing-scale-2x) !important;
  }
  .pr-sm-n2x {
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pb-sm-2x {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pb-sm-n2x {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pl-sm-2x {
    padding-left: var(--spacing-scale-2x) !important;
  }
  .pl-sm-n2x {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-sm-n2x {
    padding: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .px-sm-n2x {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .py-sm-n2x {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-sm-2xh {
    padding: var(--spacing-scale-2xh) !important;
  }
  .px-sm-2xh {
    padding-left: var(--spacing-scale-2xh) !important;
    padding-right: var(--spacing-scale-2xh) !important;
  }
  .py-sm-2xh {
    padding-bottom: var(--spacing-scale-2xh) !important;
  }
  .pt-sm-2xh,
  .py-sm-2xh {
    padding-top: var(--spacing-scale-2xh) !important;
  }
  .pt-sm-n2xh {
    padding-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pr-sm-2xh {
    padding-right: var(--spacing-scale-2xh) !important;
  }
  .pr-sm-n2xh {
    padding-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pb-sm-2xh {
    padding-bottom: var(--spacing-scale-2xh) !important;
  }
  .pb-sm-n2xh {
    padding-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pl-sm-2xh {
    padding-left: var(--spacing-scale-2xh) !important;
  }
  .pl-sm-n2xh {
    padding-left: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .p-sm-n2xh {
    padding: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .px-sm-n2xh {
    padding-left: calc(var(--spacing-scale-2xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .py-sm-n2xh {
    padding-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .p-sm-3x {
    padding: var(--spacing-scale-3x) !important;
  }
  .px-sm-3x {
    padding-left: var(--spacing-scale-3x) !important;
    padding-right: var(--spacing-scale-3x) !important;
  }
  .py-sm-3x {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pt-sm-3x,
  .py-sm-3x {
    padding-top: var(--spacing-scale-3x) !important;
  }
  .pt-sm-n3x {
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pr-sm-3x {
    padding-right: var(--spacing-scale-3x) !important;
  }
  .pr-sm-n3x {
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pb-sm-3x {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pb-sm-n3x {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pl-sm-3x {
    padding-left: var(--spacing-scale-3x) !important;
  }
  .pl-sm-n3x {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-sm-n3x {
    padding: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .px-sm-n3x {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .py-sm-n3x {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-sm-3xh {
    padding: var(--spacing-scale-3xh) !important;
  }
  .px-sm-3xh {
    padding-left: var(--spacing-scale-3xh) !important;
    padding-right: var(--spacing-scale-3xh) !important;
  }
  .py-sm-3xh {
    padding-bottom: var(--spacing-scale-3xh) !important;
  }
  .pt-sm-3xh,
  .py-sm-3xh {
    padding-top: var(--spacing-scale-3xh) !important;
  }
  .pt-sm-n3xh {
    padding-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pr-sm-3xh {
    padding-right: var(--spacing-scale-3xh) !important;
  }
  .pr-sm-n3xh {
    padding-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pb-sm-3xh {
    padding-bottom: var(--spacing-scale-3xh) !important;
  }
  .pb-sm-n3xh {
    padding-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pl-sm-3xh {
    padding-left: var(--spacing-scale-3xh) !important;
  }
  .pl-sm-n3xh {
    padding-left: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .p-sm-n3xh {
    padding: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .px-sm-n3xh {
    padding-left: calc(var(--spacing-scale-3xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .py-sm-n3xh {
    padding-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .p-sm-4x {
    padding: var(--spacing-scale-4x) !important;
  }
  .px-sm-4x {
    padding-left: var(--spacing-scale-4x) !important;
    padding-right: var(--spacing-scale-4x) !important;
  }
  .py-sm-4x {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pt-sm-4x,
  .py-sm-4x {
    padding-top: var(--spacing-scale-4x) !important;
  }
  .pt-sm-n4x {
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pr-sm-4x {
    padding-right: var(--spacing-scale-4x) !important;
  }
  .pr-sm-n4x {
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pb-sm-4x {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pb-sm-n4x {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pl-sm-4x {
    padding-left: var(--spacing-scale-4x) !important;
  }
  .pl-sm-n4x {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-sm-n4x {
    padding: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .px-sm-n4x {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .py-sm-n4x {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-sm-4xh {
    padding: var(--spacing-scale-4xh) !important;
  }
  .px-sm-4xh {
    padding-left: var(--spacing-scale-4xh) !important;
    padding-right: var(--spacing-scale-4xh) !important;
  }
  .py-sm-4xh {
    padding-bottom: var(--spacing-scale-4xh) !important;
  }
  .pt-sm-4xh,
  .py-sm-4xh {
    padding-top: var(--spacing-scale-4xh) !important;
  }
  .pt-sm-n4xh {
    padding-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pr-sm-4xh {
    padding-right: var(--spacing-scale-4xh) !important;
  }
  .pr-sm-n4xh {
    padding-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pb-sm-4xh {
    padding-bottom: var(--spacing-scale-4xh) !important;
  }
  .pb-sm-n4xh {
    padding-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pl-sm-4xh {
    padding-left: var(--spacing-scale-4xh) !important;
  }
  .pl-sm-n4xh {
    padding-left: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .p-sm-n4xh {
    padding: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .px-sm-n4xh {
    padding-left: calc(var(--spacing-scale-4xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .py-sm-n4xh {
    padding-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .p-sm-5x {
    padding: var(--spacing-scale-5x) !important;
  }
  .px-sm-5x {
    padding-left: var(--spacing-scale-5x) !important;
    padding-right: var(--spacing-scale-5x) !important;
  }
  .py-sm-5x {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pt-sm-5x,
  .py-sm-5x {
    padding-top: var(--spacing-scale-5x) !important;
  }
  .pt-sm-n5x {
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pr-sm-5x {
    padding-right: var(--spacing-scale-5x) !important;
  }
  .pr-sm-n5x {
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pb-sm-5x {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pb-sm-n5x {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pl-sm-5x {
    padding-left: var(--spacing-scale-5x) !important;
  }
  .pl-sm-n5x {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-sm-n5x {
    padding: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .px-sm-n5x {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .py-sm-n5x {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-sm-5xh {
    padding: var(--spacing-scale-5xh) !important;
  }
  .px-sm-5xh {
    padding-left: var(--spacing-scale-5xh) !important;
    padding-right: var(--spacing-scale-5xh) !important;
  }
  .py-sm-5xh {
    padding-bottom: var(--spacing-scale-5xh) !important;
  }
  .pt-sm-5xh,
  .py-sm-5xh {
    padding-top: var(--spacing-scale-5xh) !important;
  }
  .pt-sm-n5xh {
    padding-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pr-sm-5xh {
    padding-right: var(--spacing-scale-5xh) !important;
  }
  .pr-sm-n5xh {
    padding-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pb-sm-5xh {
    padding-bottom: var(--spacing-scale-5xh) !important;
  }
  .pb-sm-n5xh {
    padding-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pl-sm-5xh {
    padding-left: var(--spacing-scale-5xh) !important;
  }
  .pl-sm-n5xh {
    padding-left: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .p-sm-n5xh {
    padding: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .px-sm-n5xh {
    padding-left: calc(var(--spacing-scale-5xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .py-sm-n5xh {
    padding-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .p-sm-6x {
    padding: var(--spacing-scale-6x) !important;
  }
  .px-sm-6x {
    padding-left: var(--spacing-scale-6x) !important;
    padding-right: var(--spacing-scale-6x) !important;
  }
  .py-sm-6x {
    padding-bottom: var(--spacing-scale-6x) !important;
  }
  .pt-sm-6x,
  .py-sm-6x {
    padding-top: var(--spacing-scale-6x) !important;
  }
  .pt-sm-n6x {
    padding-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pr-sm-6x {
    padding-right: var(--spacing-scale-6x) !important;
  }
  .pr-sm-n6x {
    padding-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pb-sm-6x {
    padding-bottom: var(--spacing-scale-6x) !important;
  }
  .pb-sm-n6x {
    padding-bottom: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pl-sm-6x {
    padding-left: var(--spacing-scale-6x) !important;
  }
  .pl-sm-n6x {
    padding-left: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .p-sm-n6x {
    padding: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .px-sm-n6x {
    padding-left: calc(var(--spacing-scale-6x) * -1) !important;
    padding-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .py-sm-n6x {
    padding-bottom: calc(var(--spacing-scale-6x) * -1) !important;
    padding-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .p-sm-6xh {
    padding: var(--spacing-scale-6xh) !important;
  }
  .px-sm-6xh {
    padding-left: var(--spacing-scale-6xh) !important;
    padding-right: var(--spacing-scale-6xh) !important;
  }
  .py-sm-6xh {
    padding-bottom: var(--spacing-scale-6xh) !important;
  }
  .pt-sm-6xh,
  .py-sm-6xh {
    padding-top: var(--spacing-scale-6xh) !important;
  }
  .pt-sm-n6xh {
    padding-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pr-sm-6xh {
    padding-right: var(--spacing-scale-6xh) !important;
  }
  .pr-sm-n6xh {
    padding-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pb-sm-6xh {
    padding-bottom: var(--spacing-scale-6xh) !important;
  }
  .pb-sm-n6xh {
    padding-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pl-sm-6xh {
    padding-left: var(--spacing-scale-6xh) !important;
  }
  .pl-sm-n6xh {
    padding-left: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .p-sm-n6xh {
    padding: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .px-sm-n6xh {
    padding-left: calc(var(--spacing-scale-6xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .py-sm-n6xh {
    padding-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .p-sm-7x {
    padding: var(--spacing-scale-7x) !important;
  }
  .px-sm-7x {
    padding-left: var(--spacing-scale-7x) !important;
    padding-right: var(--spacing-scale-7x) !important;
  }
  .py-sm-7x {
    padding-bottom: var(--spacing-scale-7x) !important;
  }
  .pt-sm-7x,
  .py-sm-7x {
    padding-top: var(--spacing-scale-7x) !important;
  }
  .pt-sm-n7x {
    padding-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pr-sm-7x {
    padding-right: var(--spacing-scale-7x) !important;
  }
  .pr-sm-n7x {
    padding-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pb-sm-7x {
    padding-bottom: var(--spacing-scale-7x) !important;
  }
  .pb-sm-n7x {
    padding-bottom: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pl-sm-7x {
    padding-left: var(--spacing-scale-7x) !important;
  }
  .pl-sm-n7x {
    padding-left: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .p-sm-n7x {
    padding: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .px-sm-n7x {
    padding-left: calc(var(--spacing-scale-7x) * -1) !important;
    padding-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .py-sm-n7x {
    padding-bottom: calc(var(--spacing-scale-7x) * -1) !important;
    padding-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .p-sm-7xh {
    padding: var(--spacing-scale-7xh) !important;
  }
  .px-sm-7xh {
    padding-left: var(--spacing-scale-7xh) !important;
    padding-right: var(--spacing-scale-7xh) !important;
  }
  .py-sm-7xh {
    padding-bottom: var(--spacing-scale-7xh) !important;
  }
  .pt-sm-7xh,
  .py-sm-7xh {
    padding-top: var(--spacing-scale-7xh) !important;
  }
  .pt-sm-n7xh {
    padding-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pr-sm-7xh {
    padding-right: var(--spacing-scale-7xh) !important;
  }
  .pr-sm-n7xh {
    padding-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pb-sm-7xh {
    padding-bottom: var(--spacing-scale-7xh) !important;
  }
  .pb-sm-n7xh {
    padding-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pl-sm-7xh {
    padding-left: var(--spacing-scale-7xh) !important;
  }
  .pl-sm-n7xh {
    padding-left: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .p-sm-n7xh {
    padding: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .px-sm-n7xh {
    padding-left: calc(var(--spacing-scale-7xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .py-sm-n7xh {
    padding-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .p-sm-8x {
    padding: var(--spacing-scale-8x) !important;
  }
  .px-sm-8x {
    padding-left: var(--spacing-scale-8x) !important;
    padding-right: var(--spacing-scale-8x) !important;
  }
  .py-sm-8x {
    padding-bottom: var(--spacing-scale-8x) !important;
  }
  .pt-sm-8x,
  .py-sm-8x {
    padding-top: var(--spacing-scale-8x) !important;
  }
  .pt-sm-n8x {
    padding-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pr-sm-8x {
    padding-right: var(--spacing-scale-8x) !important;
  }
  .pr-sm-n8x {
    padding-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pb-sm-8x {
    padding-bottom: var(--spacing-scale-8x) !important;
  }
  .pb-sm-n8x {
    padding-bottom: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pl-sm-8x {
    padding-left: var(--spacing-scale-8x) !important;
  }
  .pl-sm-n8x {
    padding-left: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .p-sm-n8x {
    padding: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .px-sm-n8x {
    padding-left: calc(var(--spacing-scale-8x) * -1) !important;
    padding-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .py-sm-n8x {
    padding-bottom: calc(var(--spacing-scale-8x) * -1) !important;
    padding-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .p-sm-8xh {
    padding: var(--spacing-scale-8xh) !important;
  }
  .px-sm-8xh {
    padding-left: var(--spacing-scale-8xh) !important;
    padding-right: var(--spacing-scale-8xh) !important;
  }
  .py-sm-8xh {
    padding-bottom: var(--spacing-scale-8xh) !important;
  }
  .pt-sm-8xh,
  .py-sm-8xh {
    padding-top: var(--spacing-scale-8xh) !important;
  }
  .pt-sm-n8xh {
    padding-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pr-sm-8xh {
    padding-right: var(--spacing-scale-8xh) !important;
  }
  .pr-sm-n8xh {
    padding-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pb-sm-8xh {
    padding-bottom: var(--spacing-scale-8xh) !important;
  }
  .pb-sm-n8xh {
    padding-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pl-sm-8xh {
    padding-left: var(--spacing-scale-8xh) !important;
  }
  .pl-sm-n8xh {
    padding-left: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .p-sm-n8xh {
    padding: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .px-sm-n8xh {
    padding-left: calc(var(--spacing-scale-8xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .py-sm-n8xh {
    padding-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .p-sm-9x {
    padding: var(--spacing-scale-9x) !important;
  }
  .px-sm-9x {
    padding-left: var(--spacing-scale-9x) !important;
    padding-right: var(--spacing-scale-9x) !important;
  }
  .py-sm-9x {
    padding-bottom: var(--spacing-scale-9x) !important;
  }
  .pt-sm-9x,
  .py-sm-9x {
    padding-top: var(--spacing-scale-9x) !important;
  }
  .pt-sm-n9x {
    padding-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pr-sm-9x {
    padding-right: var(--spacing-scale-9x) !important;
  }
  .pr-sm-n9x {
    padding-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pb-sm-9x {
    padding-bottom: var(--spacing-scale-9x) !important;
  }
  .pb-sm-n9x {
    padding-bottom: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pl-sm-9x {
    padding-left: var(--spacing-scale-9x) !important;
  }
  .pl-sm-n9x {
    padding-left: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .p-sm-n9x {
    padding: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .px-sm-n9x {
    padding-left: calc(var(--spacing-scale-9x) * -1) !important;
    padding-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .py-sm-n9x {
    padding-bottom: calc(var(--spacing-scale-9x) * -1) !important;
    padding-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .p-sm-9xh {
    padding: var(--spacing-scale-9xh) !important;
  }
  .px-sm-9xh {
    padding-left: var(--spacing-scale-9xh) !important;
    padding-right: var(--spacing-scale-9xh) !important;
  }
  .py-sm-9xh {
    padding-bottom: var(--spacing-scale-9xh) !important;
  }
  .pt-sm-9xh,
  .py-sm-9xh {
    padding-top: var(--spacing-scale-9xh) !important;
  }
  .pt-sm-n9xh {
    padding-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pr-sm-9xh {
    padding-right: var(--spacing-scale-9xh) !important;
  }
  .pr-sm-n9xh {
    padding-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pb-sm-9xh {
    padding-bottom: var(--spacing-scale-9xh) !important;
  }
  .pb-sm-n9xh {
    padding-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pl-sm-9xh {
    padding-left: var(--spacing-scale-9xh) !important;
  }
  .pl-sm-n9xh {
    padding-left: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .p-sm-n9xh {
    padding: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .px-sm-n9xh {
    padding-left: calc(var(--spacing-scale-9xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .py-sm-n9xh {
    padding-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .p-sm-10x {
    padding: var(--spacing-scale-10x) !important;
  }
  .px-sm-10x {
    padding-left: var(--spacing-scale-10x) !important;
    padding-right: var(--spacing-scale-10x) !important;
  }
  .py-sm-10x {
    padding-bottom: var(--spacing-scale-10x) !important;
  }
  .pt-sm-10x,
  .py-sm-10x {
    padding-top: var(--spacing-scale-10x) !important;
  }
  .pt-sm-n10x {
    padding-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pr-sm-10x {
    padding-right: var(--spacing-scale-10x) !important;
  }
  .pr-sm-n10x {
    padding-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pb-sm-10x {
    padding-bottom: var(--spacing-scale-10x) !important;
  }
  .pb-sm-n10x {
    padding-bottom: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pl-sm-10x {
    padding-left: var(--spacing-scale-10x) !important;
  }
  .pl-sm-n10x {
    padding-left: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .p-sm-n10x {
    padding: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .px-sm-n10x {
    padding-left: calc(var(--spacing-scale-10x) * -1) !important;
    padding-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .py-sm-n10x {
    padding-bottom: calc(var(--spacing-scale-10x) * -1) !important;
    padding-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .p-sm-10xh {
    padding: var(--spacing-scale-10xh) !important;
  }
  .px-sm-10xh {
    padding-left: var(--spacing-scale-10xh) !important;
    padding-right: var(--spacing-scale-10xh) !important;
  }
  .py-sm-10xh {
    padding-bottom: var(--spacing-scale-10xh) !important;
  }
  .pt-sm-10xh,
  .py-sm-10xh {
    padding-top: var(--spacing-scale-10xh) !important;
  }
  .pt-sm-n10xh {
    padding-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pr-sm-10xh {
    padding-right: var(--spacing-scale-10xh) !important;
  }
  .pr-sm-n10xh {
    padding-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pb-sm-10xh {
    padding-bottom: var(--spacing-scale-10xh) !important;
  }
  .pb-sm-n10xh {
    padding-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pl-sm-10xh {
    padding-left: var(--spacing-scale-10xh) !important;
  }
  .pl-sm-n10xh {
    padding-left: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .p-sm-n10xh {
    padding: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .px-sm-n10xh {
    padding-left: calc(var(--spacing-scale-10xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .py-sm-n10xh {
    padding-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
}
@media (min-width: 992px) {
  .m-md-0 {
    margin: var(--spacing-scale-default) !important;
  }
  .mx-md-0 {
    margin-left: var(--spacing-scale-default) !important;
    margin-right: var(--spacing-scale-default) !important;
  }
  .my-md-0 {
    margin-bottom: var(--spacing-scale-default) !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: var(--spacing-scale-default) !important;
  }
  .mr-md-0 {
    margin-right: var(--spacing-scale-default) !important;
  }
  .mb-md-0 {
    margin-bottom: var(--spacing-scale-default) !important;
  }
  .ml-md-0 {
    margin-left: var(--spacing-scale-default) !important;
  }
  .m-md-half {
    margin: var(--spacing-scale-half) !important;
  }
  .mx-md-half {
    margin-left: var(--spacing-scale-half) !important;
    margin-right: var(--spacing-scale-half) !important;
  }
  .my-md-half {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mt-md-half,
  .my-md-half {
    margin-top: var(--spacing-scale-half) !important;
  }
  .mt-md-nhalf {
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mr-md-half {
    margin-right: var(--spacing-scale-half) !important;
  }
  .mr-md-nhalf {
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mb-md-half {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mb-md-nhalf {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .ml-md-half {
    margin-left: var(--spacing-scale-half) !important;
  }
  .ml-md-nhalf {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-md-nhalf {
    margin: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mx-md-nhalf {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .my-md-nhalf {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-md-base {
    margin: var(--spacing-scale-base) !important;
  }
  .mx-md-base {
    margin-left: var(--spacing-scale-base) !important;
    margin-right: var(--spacing-scale-base) !important;
  }
  .my-md-base {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mt-md-base,
  .my-md-base {
    margin-top: var(--spacing-scale-base) !important;
  }
  .mt-md-nbase {
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mr-md-base {
    margin-right: var(--spacing-scale-base) !important;
  }
  .mr-md-nbase {
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mb-md-base {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mb-md-nbase {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .ml-md-base {
    margin-left: var(--spacing-scale-base) !important;
  }
  .ml-md-nbase {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-md-nbase {
    margin: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mx-md-nbase {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .my-md-nbase {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-md-baseh {
    margin: var(--spacing-scale-baseh) !important;
  }
  .mx-md-baseh {
    margin-left: var(--spacing-scale-baseh) !important;
    margin-right: var(--spacing-scale-baseh) !important;
  }
  .my-md-baseh {
    margin-bottom: var(--spacing-scale-baseh) !important;
  }
  .mt-md-baseh,
  .my-md-baseh {
    margin-top: var(--spacing-scale-baseh) !important;
  }
  .mt-md-nbaseh {
    margin-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mr-md-baseh {
    margin-right: var(--spacing-scale-baseh) !important;
  }
  .mr-md-nbaseh {
    margin-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mb-md-baseh {
    margin-bottom: var(--spacing-scale-baseh) !important;
  }
  .mb-md-nbaseh {
    margin-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .ml-md-baseh {
    margin-left: var(--spacing-scale-baseh) !important;
  }
  .ml-md-nbaseh {
    margin-left: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .m-md-nbaseh {
    margin: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mx-md-nbaseh {
    margin-left: calc(var(--spacing-scale-baseh) * -1) !important;
    margin-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .my-md-nbaseh {
    margin-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
    margin-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .m-md-1 {
    margin: var(--spacing-scale-half) !important;
  }
  .mx-md-1 {
    margin-left: var(--spacing-scale-half) !important;
    margin-right: var(--spacing-scale-half) !important;
  }
  .my-md-1 {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: var(--spacing-scale-half) !important;
  }
  .mt-md-n1 {
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mr-md-1 {
    margin-right: var(--spacing-scale-half) !important;
  }
  .mr-md-n1 {
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mb-md-1 {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mb-md-n1 {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .ml-md-1 {
    margin-left: var(--spacing-scale-half) !important;
  }
  .ml-md-n1 {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-md-n1 {
    margin: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mx-md-n1 {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .my-md-n1 {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-md-2 {
    margin: var(--spacing-scale-base) !important;
  }
  .mx-md-2 {
    margin-left: var(--spacing-scale-base) !important;
    margin-right: var(--spacing-scale-base) !important;
  }
  .my-md-2 {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: var(--spacing-scale-base) !important;
  }
  .mt-md-n2 {
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mr-md-2 {
    margin-right: var(--spacing-scale-base) !important;
  }
  .mr-md-n2 {
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mb-md-2 {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mb-md-n2 {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .ml-md-2 {
    margin-left: var(--spacing-scale-base) !important;
  }
  .ml-md-n2 {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-md-n2 {
    margin: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mx-md-n2 {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .my-md-n2 {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-md-3 {
    margin: var(--spacing-scale-2x) !important;
  }
  .mx-md-3 {
    margin-left: var(--spacing-scale-2x) !important;
    margin-right: var(--spacing-scale-2x) !important;
  }
  .my-md-3 {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: var(--spacing-scale-2x) !important;
  }
  .mt-md-n3 {
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mr-md-3 {
    margin-right: var(--spacing-scale-2x) !important;
  }
  .mr-md-n3 {
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mb-md-3 {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mb-md-n3 {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .ml-md-3 {
    margin-left: var(--spacing-scale-2x) !important;
  }
  .ml-md-n3 {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-md-n3 {
    margin: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mx-md-n3 {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .my-md-n3 {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-md-4 {
    margin: var(--spacing-scale-3x) !important;
  }
  .mx-md-4 {
    margin-left: var(--spacing-scale-3x) !important;
    margin-right: var(--spacing-scale-3x) !important;
  }
  .my-md-4 {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: var(--spacing-scale-3x) !important;
  }
  .mt-md-n4 {
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mr-md-4 {
    margin-right: var(--spacing-scale-3x) !important;
  }
  .mr-md-n4 {
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mb-md-4 {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mb-md-n4 {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .ml-md-4 {
    margin-left: var(--spacing-scale-3x) !important;
  }
  .ml-md-n4 {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-md-n4 {
    margin: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mx-md-n4 {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .my-md-n4 {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-md-5 {
    margin: var(--spacing-scale-4x) !important;
  }
  .mx-md-5 {
    margin-left: var(--spacing-scale-4x) !important;
    margin-right: var(--spacing-scale-4x) !important;
  }
  .my-md-5 {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: var(--spacing-scale-4x) !important;
  }
  .mt-md-n5 {
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mr-md-5 {
    margin-right: var(--spacing-scale-4x) !important;
  }
  .mr-md-n5 {
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mb-md-5 {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mb-md-n5 {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .ml-md-5 {
    margin-left: var(--spacing-scale-4x) !important;
  }
  .ml-md-n5 {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-md-n5 {
    margin: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mx-md-n5 {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .my-md-n5 {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-md-6 {
    margin: var(--spacing-scale-5x) !important;
  }
  .mx-md-6 {
    margin-left: var(--spacing-scale-5x) !important;
    margin-right: var(--spacing-scale-5x) !important;
  }
  .my-md-6 {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: var(--spacing-scale-5x) !important;
  }
  .mt-md-n6 {
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mr-md-6 {
    margin-right: var(--spacing-scale-5x) !important;
  }
  .mr-md-n6 {
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mb-md-6 {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mb-md-n6 {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .ml-md-6 {
    margin-left: var(--spacing-scale-5x) !important;
  }
  .ml-md-n6 {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-md-n6 {
    margin: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mx-md-n6 {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .my-md-n6 {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-md-2x {
    margin: var(--spacing-scale-2x) !important;
  }
  .mx-md-2x {
    margin-left: var(--spacing-scale-2x) !important;
    margin-right: var(--spacing-scale-2x) !important;
  }
  .my-md-2x {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mt-md-2x,
  .my-md-2x {
    margin-top: var(--spacing-scale-2x) !important;
  }
  .mt-md-n2x {
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mr-md-2x {
    margin-right: var(--spacing-scale-2x) !important;
  }
  .mr-md-n2x {
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mb-md-2x {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mb-md-n2x {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .ml-md-2x {
    margin-left: var(--spacing-scale-2x) !important;
  }
  .ml-md-n2x {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-md-n2x {
    margin: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mx-md-n2x {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .my-md-n2x {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-md-2xh {
    margin: var(--spacing-scale-2xh) !important;
  }
  .mx-md-2xh {
    margin-left: var(--spacing-scale-2xh) !important;
    margin-right: var(--spacing-scale-2xh) !important;
  }
  .my-md-2xh {
    margin-bottom: var(--spacing-scale-2xh) !important;
  }
  .mt-md-2xh,
  .my-md-2xh {
    margin-top: var(--spacing-scale-2xh) !important;
  }
  .mt-md-n2xh {
    margin-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mr-md-2xh {
    margin-right: var(--spacing-scale-2xh) !important;
  }
  .mr-md-n2xh {
    margin-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mb-md-2xh {
    margin-bottom: var(--spacing-scale-2xh) !important;
  }
  .mb-md-n2xh {
    margin-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .ml-md-2xh {
    margin-left: var(--spacing-scale-2xh) !important;
  }
  .ml-md-n2xh {
    margin-left: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .m-md-n2xh {
    margin: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mx-md-n2xh {
    margin-left: calc(var(--spacing-scale-2xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .my-md-n2xh {
    margin-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .m-md-3x {
    margin: var(--spacing-scale-3x) !important;
  }
  .mx-md-3x {
    margin-left: var(--spacing-scale-3x) !important;
    margin-right: var(--spacing-scale-3x) !important;
  }
  .my-md-3x {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mt-md-3x,
  .my-md-3x {
    margin-top: var(--spacing-scale-3x) !important;
  }
  .mt-md-n3x {
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mr-md-3x {
    margin-right: var(--spacing-scale-3x) !important;
  }
  .mr-md-n3x {
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mb-md-3x {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mb-md-n3x {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .ml-md-3x {
    margin-left: var(--spacing-scale-3x) !important;
  }
  .ml-md-n3x {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-md-n3x {
    margin: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mx-md-n3x {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .my-md-n3x {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-md-3xh {
    margin: var(--spacing-scale-3xh) !important;
  }
  .mx-md-3xh {
    margin-left: var(--spacing-scale-3xh) !important;
    margin-right: var(--spacing-scale-3xh) !important;
  }
  .my-md-3xh {
    margin-bottom: var(--spacing-scale-3xh) !important;
  }
  .mt-md-3xh,
  .my-md-3xh {
    margin-top: var(--spacing-scale-3xh) !important;
  }
  .mt-md-n3xh {
    margin-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mr-md-3xh {
    margin-right: var(--spacing-scale-3xh) !important;
  }
  .mr-md-n3xh {
    margin-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mb-md-3xh {
    margin-bottom: var(--spacing-scale-3xh) !important;
  }
  .mb-md-n3xh {
    margin-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .ml-md-3xh {
    margin-left: var(--spacing-scale-3xh) !important;
  }
  .ml-md-n3xh {
    margin-left: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .m-md-n3xh {
    margin: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mx-md-n3xh {
    margin-left: calc(var(--spacing-scale-3xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .my-md-n3xh {
    margin-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .m-md-4x {
    margin: var(--spacing-scale-4x) !important;
  }
  .mx-md-4x {
    margin-left: var(--spacing-scale-4x) !important;
    margin-right: var(--spacing-scale-4x) !important;
  }
  .my-md-4x {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mt-md-4x,
  .my-md-4x {
    margin-top: var(--spacing-scale-4x) !important;
  }
  .mt-md-n4x {
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mr-md-4x {
    margin-right: var(--spacing-scale-4x) !important;
  }
  .mr-md-n4x {
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mb-md-4x {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mb-md-n4x {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .ml-md-4x {
    margin-left: var(--spacing-scale-4x) !important;
  }
  .ml-md-n4x {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-md-n4x {
    margin: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mx-md-n4x {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .my-md-n4x {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-md-4xh {
    margin: var(--spacing-scale-4xh) !important;
  }
  .mx-md-4xh {
    margin-left: var(--spacing-scale-4xh) !important;
    margin-right: var(--spacing-scale-4xh) !important;
  }
  .my-md-4xh {
    margin-bottom: var(--spacing-scale-4xh) !important;
  }
  .mt-md-4xh,
  .my-md-4xh {
    margin-top: var(--spacing-scale-4xh) !important;
  }
  .mt-md-n4xh {
    margin-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mr-md-4xh {
    margin-right: var(--spacing-scale-4xh) !important;
  }
  .mr-md-n4xh {
    margin-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mb-md-4xh {
    margin-bottom: var(--spacing-scale-4xh) !important;
  }
  .mb-md-n4xh {
    margin-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .ml-md-4xh {
    margin-left: var(--spacing-scale-4xh) !important;
  }
  .ml-md-n4xh {
    margin-left: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .m-md-n4xh {
    margin: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mx-md-n4xh {
    margin-left: calc(var(--spacing-scale-4xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .my-md-n4xh {
    margin-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .m-md-5x {
    margin: var(--spacing-scale-5x) !important;
  }
  .mx-md-5x {
    margin-left: var(--spacing-scale-5x) !important;
    margin-right: var(--spacing-scale-5x) !important;
  }
  .my-md-5x {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mt-md-5x,
  .my-md-5x {
    margin-top: var(--spacing-scale-5x) !important;
  }
  .mt-md-n5x {
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mr-md-5x {
    margin-right: var(--spacing-scale-5x) !important;
  }
  .mr-md-n5x {
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mb-md-5x {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mb-md-n5x {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .ml-md-5x {
    margin-left: var(--spacing-scale-5x) !important;
  }
  .ml-md-n5x {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-md-n5x {
    margin: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mx-md-n5x {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .my-md-n5x {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-md-5xh {
    margin: var(--spacing-scale-5xh) !important;
  }
  .mx-md-5xh {
    margin-left: var(--spacing-scale-5xh) !important;
    margin-right: var(--spacing-scale-5xh) !important;
  }
  .my-md-5xh {
    margin-bottom: var(--spacing-scale-5xh) !important;
  }
  .mt-md-5xh,
  .my-md-5xh {
    margin-top: var(--spacing-scale-5xh) !important;
  }
  .mt-md-n5xh {
    margin-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mr-md-5xh {
    margin-right: var(--spacing-scale-5xh) !important;
  }
  .mr-md-n5xh {
    margin-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mb-md-5xh {
    margin-bottom: var(--spacing-scale-5xh) !important;
  }
  .mb-md-n5xh {
    margin-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .ml-md-5xh {
    margin-left: var(--spacing-scale-5xh) !important;
  }
  .ml-md-n5xh {
    margin-left: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .m-md-n5xh {
    margin: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mx-md-n5xh {
    margin-left: calc(var(--spacing-scale-5xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .my-md-n5xh {
    margin-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .m-md-6x {
    margin: var(--spacing-scale-6x) !important;
  }
  .mx-md-6x {
    margin-left: var(--spacing-scale-6x) !important;
    margin-right: var(--spacing-scale-6x) !important;
  }
  .my-md-6x {
    margin-bottom: var(--spacing-scale-6x) !important;
  }
  .mt-md-6x,
  .my-md-6x {
    margin-top: var(--spacing-scale-6x) !important;
  }
  .mt-md-n6x {
    margin-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mr-md-6x {
    margin-right: var(--spacing-scale-6x) !important;
  }
  .mr-md-n6x {
    margin-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mb-md-6x {
    margin-bottom: var(--spacing-scale-6x) !important;
  }
  .mb-md-n6x {
    margin-bottom: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .ml-md-6x {
    margin-left: var(--spacing-scale-6x) !important;
  }
  .ml-md-n6x {
    margin-left: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .m-md-n6x {
    margin: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mx-md-n6x {
    margin-left: calc(var(--spacing-scale-6x) * -1) !important;
    margin-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .my-md-n6x {
    margin-bottom: calc(var(--spacing-scale-6x) * -1) !important;
    margin-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .m-md-6xh {
    margin: var(--spacing-scale-6xh) !important;
  }
  .mx-md-6xh {
    margin-left: var(--spacing-scale-6xh) !important;
    margin-right: var(--spacing-scale-6xh) !important;
  }
  .my-md-6xh {
    margin-bottom: var(--spacing-scale-6xh) !important;
  }
  .mt-md-6xh,
  .my-md-6xh {
    margin-top: var(--spacing-scale-6xh) !important;
  }
  .mt-md-n6xh {
    margin-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mr-md-6xh {
    margin-right: var(--spacing-scale-6xh) !important;
  }
  .mr-md-n6xh {
    margin-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mb-md-6xh {
    margin-bottom: var(--spacing-scale-6xh) !important;
  }
  .mb-md-n6xh {
    margin-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .ml-md-6xh {
    margin-left: var(--spacing-scale-6xh) !important;
  }
  .ml-md-n6xh {
    margin-left: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .m-md-n6xh {
    margin: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mx-md-n6xh {
    margin-left: calc(var(--spacing-scale-6xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .my-md-n6xh {
    margin-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .m-md-7x {
    margin: var(--spacing-scale-7x) !important;
  }
  .mx-md-7x {
    margin-left: var(--spacing-scale-7x) !important;
    margin-right: var(--spacing-scale-7x) !important;
  }
  .my-md-7x {
    margin-bottom: var(--spacing-scale-7x) !important;
  }
  .mt-md-7x,
  .my-md-7x {
    margin-top: var(--spacing-scale-7x) !important;
  }
  .mt-md-n7x {
    margin-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mr-md-7x {
    margin-right: var(--spacing-scale-7x) !important;
  }
  .mr-md-n7x {
    margin-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mb-md-7x {
    margin-bottom: var(--spacing-scale-7x) !important;
  }
  .mb-md-n7x {
    margin-bottom: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .ml-md-7x {
    margin-left: var(--spacing-scale-7x) !important;
  }
  .ml-md-n7x {
    margin-left: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .m-md-n7x {
    margin: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mx-md-n7x {
    margin-left: calc(var(--spacing-scale-7x) * -1) !important;
    margin-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .my-md-n7x {
    margin-bottom: calc(var(--spacing-scale-7x) * -1) !important;
    margin-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .m-md-7xh {
    margin: var(--spacing-scale-7xh) !important;
  }
  .mx-md-7xh {
    margin-left: var(--spacing-scale-7xh) !important;
    margin-right: var(--spacing-scale-7xh) !important;
  }
  .my-md-7xh {
    margin-bottom: var(--spacing-scale-7xh) !important;
  }
  .mt-md-7xh,
  .my-md-7xh {
    margin-top: var(--spacing-scale-7xh) !important;
  }
  .mt-md-n7xh {
    margin-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mr-md-7xh {
    margin-right: var(--spacing-scale-7xh) !important;
  }
  .mr-md-n7xh {
    margin-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mb-md-7xh {
    margin-bottom: var(--spacing-scale-7xh) !important;
  }
  .mb-md-n7xh {
    margin-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .ml-md-7xh {
    margin-left: var(--spacing-scale-7xh) !important;
  }
  .ml-md-n7xh {
    margin-left: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .m-md-n7xh {
    margin: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mx-md-n7xh {
    margin-left: calc(var(--spacing-scale-7xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .my-md-n7xh {
    margin-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .m-md-8x {
    margin: var(--spacing-scale-8x) !important;
  }
  .mx-md-8x {
    margin-left: var(--spacing-scale-8x) !important;
    margin-right: var(--spacing-scale-8x) !important;
  }
  .my-md-8x {
    margin-bottom: var(--spacing-scale-8x) !important;
  }
  .mt-md-8x,
  .my-md-8x {
    margin-top: var(--spacing-scale-8x) !important;
  }
  .mt-md-n8x {
    margin-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mr-md-8x {
    margin-right: var(--spacing-scale-8x) !important;
  }
  .mr-md-n8x {
    margin-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mb-md-8x {
    margin-bottom: var(--spacing-scale-8x) !important;
  }
  .mb-md-n8x {
    margin-bottom: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .ml-md-8x {
    margin-left: var(--spacing-scale-8x) !important;
  }
  .ml-md-n8x {
    margin-left: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .m-md-n8x {
    margin: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mx-md-n8x {
    margin-left: calc(var(--spacing-scale-8x) * -1) !important;
    margin-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .my-md-n8x {
    margin-bottom: calc(var(--spacing-scale-8x) * -1) !important;
    margin-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .m-md-8xh {
    margin: var(--spacing-scale-8xh) !important;
  }
  .mx-md-8xh {
    margin-left: var(--spacing-scale-8xh) !important;
    margin-right: var(--spacing-scale-8xh) !important;
  }
  .my-md-8xh {
    margin-bottom: var(--spacing-scale-8xh) !important;
  }
  .mt-md-8xh,
  .my-md-8xh {
    margin-top: var(--spacing-scale-8xh) !important;
  }
  .mt-md-n8xh {
    margin-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mr-md-8xh {
    margin-right: var(--spacing-scale-8xh) !important;
  }
  .mr-md-n8xh {
    margin-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mb-md-8xh {
    margin-bottom: var(--spacing-scale-8xh) !important;
  }
  .mb-md-n8xh {
    margin-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .ml-md-8xh {
    margin-left: var(--spacing-scale-8xh) !important;
  }
  .ml-md-n8xh {
    margin-left: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .m-md-n8xh {
    margin: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mx-md-n8xh {
    margin-left: calc(var(--spacing-scale-8xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .my-md-n8xh {
    margin-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .m-md-9x {
    margin: var(--spacing-scale-9x) !important;
  }
  .mx-md-9x {
    margin-left: var(--spacing-scale-9x) !important;
    margin-right: var(--spacing-scale-9x) !important;
  }
  .my-md-9x {
    margin-bottom: var(--spacing-scale-9x) !important;
  }
  .mt-md-9x,
  .my-md-9x {
    margin-top: var(--spacing-scale-9x) !important;
  }
  .mt-md-n9x {
    margin-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mr-md-9x {
    margin-right: var(--spacing-scale-9x) !important;
  }
  .mr-md-n9x {
    margin-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mb-md-9x {
    margin-bottom: var(--spacing-scale-9x) !important;
  }
  .mb-md-n9x {
    margin-bottom: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .ml-md-9x {
    margin-left: var(--spacing-scale-9x) !important;
  }
  .ml-md-n9x {
    margin-left: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .m-md-n9x {
    margin: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mx-md-n9x {
    margin-left: calc(var(--spacing-scale-9x) * -1) !important;
    margin-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .my-md-n9x {
    margin-bottom: calc(var(--spacing-scale-9x) * -1) !important;
    margin-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .m-md-9xh {
    margin: var(--spacing-scale-9xh) !important;
  }
  .mx-md-9xh {
    margin-left: var(--spacing-scale-9xh) !important;
    margin-right: var(--spacing-scale-9xh) !important;
  }
  .my-md-9xh {
    margin-bottom: var(--spacing-scale-9xh) !important;
  }
  .mt-md-9xh,
  .my-md-9xh {
    margin-top: var(--spacing-scale-9xh) !important;
  }
  .mt-md-n9xh {
    margin-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mr-md-9xh {
    margin-right: var(--spacing-scale-9xh) !important;
  }
  .mr-md-n9xh {
    margin-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mb-md-9xh {
    margin-bottom: var(--spacing-scale-9xh) !important;
  }
  .mb-md-n9xh {
    margin-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .ml-md-9xh {
    margin-left: var(--spacing-scale-9xh) !important;
  }
  .ml-md-n9xh {
    margin-left: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .m-md-n9xh {
    margin: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mx-md-n9xh {
    margin-left: calc(var(--spacing-scale-9xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .my-md-n9xh {
    margin-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .m-md-10x {
    margin: var(--spacing-scale-10x) !important;
  }
  .mx-md-10x {
    margin-left: var(--spacing-scale-10x) !important;
    margin-right: var(--spacing-scale-10x) !important;
  }
  .my-md-10x {
    margin-bottom: var(--spacing-scale-10x) !important;
  }
  .mt-md-10x,
  .my-md-10x {
    margin-top: var(--spacing-scale-10x) !important;
  }
  .mt-md-n10x {
    margin-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mr-md-10x {
    margin-right: var(--spacing-scale-10x) !important;
  }
  .mr-md-n10x {
    margin-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mb-md-10x {
    margin-bottom: var(--spacing-scale-10x) !important;
  }
  .mb-md-n10x {
    margin-bottom: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .ml-md-10x {
    margin-left: var(--spacing-scale-10x) !important;
  }
  .ml-md-n10x {
    margin-left: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .m-md-n10x {
    margin: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mx-md-n10x {
    margin-left: calc(var(--spacing-scale-10x) * -1) !important;
    margin-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .my-md-n10x {
    margin-bottom: calc(var(--spacing-scale-10x) * -1) !important;
    margin-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .m-md-10xh {
    margin: var(--spacing-scale-10xh) !important;
  }
  .mx-md-10xh {
    margin-left: var(--spacing-scale-10xh) !important;
    margin-right: var(--spacing-scale-10xh) !important;
  }
  .my-md-10xh {
    margin-bottom: var(--spacing-scale-10xh) !important;
  }
  .mt-md-10xh,
  .my-md-10xh {
    margin-top: var(--spacing-scale-10xh) !important;
  }
  .mt-md-n10xh {
    margin-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mr-md-10xh {
    margin-right: var(--spacing-scale-10xh) !important;
  }
  .mr-md-n10xh {
    margin-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mb-md-10xh {
    margin-bottom: var(--spacing-scale-10xh) !important;
  }
  .mb-md-n10xh {
    margin-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .ml-md-10xh {
    margin-left: var(--spacing-scale-10xh) !important;
  }
  .ml-md-n10xh {
    margin-left: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .m-md-n10xh {
    margin: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mx-md-n10xh {
    margin-left: calc(var(--spacing-scale-10xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .my-md-n10xh {
    margin-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: var(--spacing-scale-default) !important;
  }
  .px-md-0 {
    padding-left: var(--spacing-scale-default) !important;
    padding-right: var(--spacing-scale-default) !important;
  }
  .py-md-0 {
    padding-bottom: var(--spacing-scale-default) !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: var(--spacing-scale-default) !important;
  }
  .pr-md-0 {
    padding-right: var(--spacing-scale-default) !important;
  }
  .pb-md-0 {
    padding-bottom: var(--spacing-scale-default) !important;
  }
  .pl-md-0 {
    padding-left: var(--spacing-scale-default) !important;
  }
  .p-md-half {
    padding: var(--spacing-scale-half) !important;
  }
  .px-md-half {
    padding-left: var(--spacing-scale-half) !important;
    padding-right: var(--spacing-scale-half) !important;
  }
  .py-md-half {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pt-md-half,
  .py-md-half {
    padding-top: var(--spacing-scale-half) !important;
  }
  .pt-md-nhalf {
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pr-md-half {
    padding-right: var(--spacing-scale-half) !important;
  }
  .pr-md-nhalf {
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pb-md-half {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pb-md-nhalf {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pl-md-half {
    padding-left: var(--spacing-scale-half) !important;
  }
  .pl-md-nhalf {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-md-nhalf {
    padding: calc(var(--spacing-scale-half) * -1) !important;
  }
  .px-md-nhalf {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .py-md-nhalf {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-md-base {
    padding: var(--spacing-scale-base) !important;
  }
  .px-md-base {
    padding-left: var(--spacing-scale-base) !important;
    padding-right: var(--spacing-scale-base) !important;
  }
  .py-md-base {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pt-md-base,
  .py-md-base {
    padding-top: var(--spacing-scale-base) !important;
  }
  .pt-md-nbase {
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pr-md-base {
    padding-right: var(--spacing-scale-base) !important;
  }
  .pr-md-nbase {
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pb-md-base {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pb-md-nbase {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pl-md-base {
    padding-left: var(--spacing-scale-base) !important;
  }
  .pl-md-nbase {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-md-nbase {
    padding: calc(var(--spacing-scale-base) * -1) !important;
  }
  .px-md-nbase {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .py-md-nbase {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-md-baseh {
    padding: var(--spacing-scale-baseh) !important;
  }
  .px-md-baseh {
    padding-left: var(--spacing-scale-baseh) !important;
    padding-right: var(--spacing-scale-baseh) !important;
  }
  .py-md-baseh {
    padding-bottom: var(--spacing-scale-baseh) !important;
  }
  .pt-md-baseh,
  .py-md-baseh {
    padding-top: var(--spacing-scale-baseh) !important;
  }
  .pt-md-nbaseh {
    padding-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pr-md-baseh {
    padding-right: var(--spacing-scale-baseh) !important;
  }
  .pr-md-nbaseh {
    padding-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pb-md-baseh {
    padding-bottom: var(--spacing-scale-baseh) !important;
  }
  .pb-md-nbaseh {
    padding-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pl-md-baseh {
    padding-left: var(--spacing-scale-baseh) !important;
  }
  .pl-md-nbaseh {
    padding-left: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .p-md-nbaseh {
    padding: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .px-md-nbaseh {
    padding-left: calc(var(--spacing-scale-baseh) * -1) !important;
    padding-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .py-md-nbaseh {
    padding-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
    padding-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .p-md-1 {
    padding: var(--spacing-scale-half) !important;
  }
  .px-md-1 {
    padding-left: var(--spacing-scale-half) !important;
    padding-right: var(--spacing-scale-half) !important;
  }
  .py-md-1 {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: var(--spacing-scale-half) !important;
  }
  .pt-md-n1 {
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pr-md-1 {
    padding-right: var(--spacing-scale-half) !important;
  }
  .pr-md-n1 {
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pb-md-1 {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pb-md-n1 {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pl-md-1 {
    padding-left: var(--spacing-scale-half) !important;
  }
  .pl-md-n1 {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-md-n1 {
    padding: calc(var(--spacing-scale-half) * -1) !important;
  }
  .px-md-n1 {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .py-md-n1 {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-md-2 {
    padding: var(--spacing-scale-base) !important;
  }
  .px-md-2 {
    padding-left: var(--spacing-scale-base) !important;
    padding-right: var(--spacing-scale-base) !important;
  }
  .py-md-2 {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: var(--spacing-scale-base) !important;
  }
  .pt-md-n2 {
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pr-md-2 {
    padding-right: var(--spacing-scale-base) !important;
  }
  .pr-md-n2 {
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pb-md-2 {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pb-md-n2 {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pl-md-2 {
    padding-left: var(--spacing-scale-base) !important;
  }
  .pl-md-n2 {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-md-n2 {
    padding: calc(var(--spacing-scale-base) * -1) !important;
  }
  .px-md-n2 {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .py-md-n2 {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-md-3 {
    padding: var(--spacing-scale-2x) !important;
  }
  .px-md-3 {
    padding-left: var(--spacing-scale-2x) !important;
    padding-right: var(--spacing-scale-2x) !important;
  }
  .py-md-3 {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: var(--spacing-scale-2x) !important;
  }
  .pt-md-n3 {
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pr-md-3 {
    padding-right: var(--spacing-scale-2x) !important;
  }
  .pr-md-n3 {
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pb-md-3 {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pb-md-n3 {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pl-md-3 {
    padding-left: var(--spacing-scale-2x) !important;
  }
  .pl-md-n3 {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-md-n3 {
    padding: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .px-md-n3 {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .py-md-n3 {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-md-4 {
    padding: var(--spacing-scale-3x) !important;
  }
  .px-md-4 {
    padding-left: var(--spacing-scale-3x) !important;
    padding-right: var(--spacing-scale-3x) !important;
  }
  .py-md-4 {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: var(--spacing-scale-3x) !important;
  }
  .pt-md-n4 {
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pr-md-4 {
    padding-right: var(--spacing-scale-3x) !important;
  }
  .pr-md-n4 {
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pb-md-4 {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pb-md-n4 {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pl-md-4 {
    padding-left: var(--spacing-scale-3x) !important;
  }
  .pl-md-n4 {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-md-n4 {
    padding: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .px-md-n4 {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .py-md-n4 {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-md-5 {
    padding: var(--spacing-scale-4x) !important;
  }
  .px-md-5 {
    padding-left: var(--spacing-scale-4x) !important;
    padding-right: var(--spacing-scale-4x) !important;
  }
  .py-md-5 {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: var(--spacing-scale-4x) !important;
  }
  .pt-md-n5 {
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pr-md-5 {
    padding-right: var(--spacing-scale-4x) !important;
  }
  .pr-md-n5 {
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pb-md-5 {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pb-md-n5 {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pl-md-5 {
    padding-left: var(--spacing-scale-4x) !important;
  }
  .pl-md-n5 {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-md-n5 {
    padding: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .px-md-n5 {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .py-md-n5 {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-md-6 {
    padding: var(--spacing-scale-5x) !important;
  }
  .px-md-6 {
    padding-left: var(--spacing-scale-5x) !important;
    padding-right: var(--spacing-scale-5x) !important;
  }
  .py-md-6 {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: var(--spacing-scale-5x) !important;
  }
  .pt-md-n6 {
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pr-md-6 {
    padding-right: var(--spacing-scale-5x) !important;
  }
  .pr-md-n6 {
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pb-md-6 {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pb-md-n6 {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pl-md-6 {
    padding-left: var(--spacing-scale-5x) !important;
  }
  .pl-md-n6 {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-md-n6 {
    padding: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .px-md-n6 {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .py-md-n6 {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-md-2x {
    padding: var(--spacing-scale-2x) !important;
  }
  .px-md-2x {
    padding-left: var(--spacing-scale-2x) !important;
    padding-right: var(--spacing-scale-2x) !important;
  }
  .py-md-2x {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pt-md-2x,
  .py-md-2x {
    padding-top: var(--spacing-scale-2x) !important;
  }
  .pt-md-n2x {
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pr-md-2x {
    padding-right: var(--spacing-scale-2x) !important;
  }
  .pr-md-n2x {
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pb-md-2x {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pb-md-n2x {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pl-md-2x {
    padding-left: var(--spacing-scale-2x) !important;
  }
  .pl-md-n2x {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-md-n2x {
    padding: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .px-md-n2x {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .py-md-n2x {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-md-2xh {
    padding: var(--spacing-scale-2xh) !important;
  }
  .px-md-2xh {
    padding-left: var(--spacing-scale-2xh) !important;
    padding-right: var(--spacing-scale-2xh) !important;
  }
  .py-md-2xh {
    padding-bottom: var(--spacing-scale-2xh) !important;
  }
  .pt-md-2xh,
  .py-md-2xh {
    padding-top: var(--spacing-scale-2xh) !important;
  }
  .pt-md-n2xh {
    padding-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pr-md-2xh {
    padding-right: var(--spacing-scale-2xh) !important;
  }
  .pr-md-n2xh {
    padding-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pb-md-2xh {
    padding-bottom: var(--spacing-scale-2xh) !important;
  }
  .pb-md-n2xh {
    padding-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pl-md-2xh {
    padding-left: var(--spacing-scale-2xh) !important;
  }
  .pl-md-n2xh {
    padding-left: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .p-md-n2xh {
    padding: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .px-md-n2xh {
    padding-left: calc(var(--spacing-scale-2xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .py-md-n2xh {
    padding-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .p-md-3x {
    padding: var(--spacing-scale-3x) !important;
  }
  .px-md-3x {
    padding-left: var(--spacing-scale-3x) !important;
    padding-right: var(--spacing-scale-3x) !important;
  }
  .py-md-3x {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pt-md-3x,
  .py-md-3x {
    padding-top: var(--spacing-scale-3x) !important;
  }
  .pt-md-n3x {
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pr-md-3x {
    padding-right: var(--spacing-scale-3x) !important;
  }
  .pr-md-n3x {
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pb-md-3x {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pb-md-n3x {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pl-md-3x {
    padding-left: var(--spacing-scale-3x) !important;
  }
  .pl-md-n3x {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-md-n3x {
    padding: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .px-md-n3x {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .py-md-n3x {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-md-3xh {
    padding: var(--spacing-scale-3xh) !important;
  }
  .px-md-3xh {
    padding-left: var(--spacing-scale-3xh) !important;
    padding-right: var(--spacing-scale-3xh) !important;
  }
  .py-md-3xh {
    padding-bottom: var(--spacing-scale-3xh) !important;
  }
  .pt-md-3xh,
  .py-md-3xh {
    padding-top: var(--spacing-scale-3xh) !important;
  }
  .pt-md-n3xh {
    padding-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pr-md-3xh {
    padding-right: var(--spacing-scale-3xh) !important;
  }
  .pr-md-n3xh {
    padding-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pb-md-3xh {
    padding-bottom: var(--spacing-scale-3xh) !important;
  }
  .pb-md-n3xh {
    padding-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pl-md-3xh {
    padding-left: var(--spacing-scale-3xh) !important;
  }
  .pl-md-n3xh {
    padding-left: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .p-md-n3xh {
    padding: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .px-md-n3xh {
    padding-left: calc(var(--spacing-scale-3xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .py-md-n3xh {
    padding-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .p-md-4x {
    padding: var(--spacing-scale-4x) !important;
  }
  .px-md-4x {
    padding-left: var(--spacing-scale-4x) !important;
    padding-right: var(--spacing-scale-4x) !important;
  }
  .py-md-4x {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pt-md-4x,
  .py-md-4x {
    padding-top: var(--spacing-scale-4x) !important;
  }
  .pt-md-n4x {
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pr-md-4x {
    padding-right: var(--spacing-scale-4x) !important;
  }
  .pr-md-n4x {
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pb-md-4x {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pb-md-n4x {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pl-md-4x {
    padding-left: var(--spacing-scale-4x) !important;
  }
  .pl-md-n4x {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-md-n4x {
    padding: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .px-md-n4x {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .py-md-n4x {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-md-4xh {
    padding: var(--spacing-scale-4xh) !important;
  }
  .px-md-4xh {
    padding-left: var(--spacing-scale-4xh) !important;
    padding-right: var(--spacing-scale-4xh) !important;
  }
  .py-md-4xh {
    padding-bottom: var(--spacing-scale-4xh) !important;
  }
  .pt-md-4xh,
  .py-md-4xh {
    padding-top: var(--spacing-scale-4xh) !important;
  }
  .pt-md-n4xh {
    padding-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pr-md-4xh {
    padding-right: var(--spacing-scale-4xh) !important;
  }
  .pr-md-n4xh {
    padding-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pb-md-4xh {
    padding-bottom: var(--spacing-scale-4xh) !important;
  }
  .pb-md-n4xh {
    padding-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pl-md-4xh {
    padding-left: var(--spacing-scale-4xh) !important;
  }
  .pl-md-n4xh {
    padding-left: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .p-md-n4xh {
    padding: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .px-md-n4xh {
    padding-left: calc(var(--spacing-scale-4xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .py-md-n4xh {
    padding-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .p-md-5x {
    padding: var(--spacing-scale-5x) !important;
  }
  .px-md-5x {
    padding-left: var(--spacing-scale-5x) !important;
    padding-right: var(--spacing-scale-5x) !important;
  }
  .py-md-5x {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pt-md-5x,
  .py-md-5x {
    padding-top: var(--spacing-scale-5x) !important;
  }
  .pt-md-n5x {
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pr-md-5x {
    padding-right: var(--spacing-scale-5x) !important;
  }
  .pr-md-n5x {
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pb-md-5x {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pb-md-n5x {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pl-md-5x {
    padding-left: var(--spacing-scale-5x) !important;
  }
  .pl-md-n5x {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-md-n5x {
    padding: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .px-md-n5x {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .py-md-n5x {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-md-5xh {
    padding: var(--spacing-scale-5xh) !important;
  }
  .px-md-5xh {
    padding-left: var(--spacing-scale-5xh) !important;
    padding-right: var(--spacing-scale-5xh) !important;
  }
  .py-md-5xh {
    padding-bottom: var(--spacing-scale-5xh) !important;
  }
  .pt-md-5xh,
  .py-md-5xh {
    padding-top: var(--spacing-scale-5xh) !important;
  }
  .pt-md-n5xh {
    padding-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pr-md-5xh {
    padding-right: var(--spacing-scale-5xh) !important;
  }
  .pr-md-n5xh {
    padding-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pb-md-5xh {
    padding-bottom: var(--spacing-scale-5xh) !important;
  }
  .pb-md-n5xh {
    padding-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pl-md-5xh {
    padding-left: var(--spacing-scale-5xh) !important;
  }
  .pl-md-n5xh {
    padding-left: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .p-md-n5xh {
    padding: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .px-md-n5xh {
    padding-left: calc(var(--spacing-scale-5xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .py-md-n5xh {
    padding-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .p-md-6x {
    padding: var(--spacing-scale-6x) !important;
  }
  .px-md-6x {
    padding-left: var(--spacing-scale-6x) !important;
    padding-right: var(--spacing-scale-6x) !important;
  }
  .py-md-6x {
    padding-bottom: var(--spacing-scale-6x) !important;
  }
  .pt-md-6x,
  .py-md-6x {
    padding-top: var(--spacing-scale-6x) !important;
  }
  .pt-md-n6x {
    padding-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pr-md-6x {
    padding-right: var(--spacing-scale-6x) !important;
  }
  .pr-md-n6x {
    padding-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pb-md-6x {
    padding-bottom: var(--spacing-scale-6x) !important;
  }
  .pb-md-n6x {
    padding-bottom: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pl-md-6x {
    padding-left: var(--spacing-scale-6x) !important;
  }
  .pl-md-n6x {
    padding-left: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .p-md-n6x {
    padding: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .px-md-n6x {
    padding-left: calc(var(--spacing-scale-6x) * -1) !important;
    padding-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .py-md-n6x {
    padding-bottom: calc(var(--spacing-scale-6x) * -1) !important;
    padding-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .p-md-6xh {
    padding: var(--spacing-scale-6xh) !important;
  }
  .px-md-6xh {
    padding-left: var(--spacing-scale-6xh) !important;
    padding-right: var(--spacing-scale-6xh) !important;
  }
  .py-md-6xh {
    padding-bottom: var(--spacing-scale-6xh) !important;
  }
  .pt-md-6xh,
  .py-md-6xh {
    padding-top: var(--spacing-scale-6xh) !important;
  }
  .pt-md-n6xh {
    padding-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pr-md-6xh {
    padding-right: var(--spacing-scale-6xh) !important;
  }
  .pr-md-n6xh {
    padding-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pb-md-6xh {
    padding-bottom: var(--spacing-scale-6xh) !important;
  }
  .pb-md-n6xh {
    padding-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pl-md-6xh {
    padding-left: var(--spacing-scale-6xh) !important;
  }
  .pl-md-n6xh {
    padding-left: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .p-md-n6xh {
    padding: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .px-md-n6xh {
    padding-left: calc(var(--spacing-scale-6xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .py-md-n6xh {
    padding-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .p-md-7x {
    padding: var(--spacing-scale-7x) !important;
  }
  .px-md-7x {
    padding-left: var(--spacing-scale-7x) !important;
    padding-right: var(--spacing-scale-7x) !important;
  }
  .py-md-7x {
    padding-bottom: var(--spacing-scale-7x) !important;
  }
  .pt-md-7x,
  .py-md-7x {
    padding-top: var(--spacing-scale-7x) !important;
  }
  .pt-md-n7x {
    padding-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pr-md-7x {
    padding-right: var(--spacing-scale-7x) !important;
  }
  .pr-md-n7x {
    padding-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pb-md-7x {
    padding-bottom: var(--spacing-scale-7x) !important;
  }
  .pb-md-n7x {
    padding-bottom: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pl-md-7x {
    padding-left: var(--spacing-scale-7x) !important;
  }
  .pl-md-n7x {
    padding-left: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .p-md-n7x {
    padding: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .px-md-n7x {
    padding-left: calc(var(--spacing-scale-7x) * -1) !important;
    padding-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .py-md-n7x {
    padding-bottom: calc(var(--spacing-scale-7x) * -1) !important;
    padding-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .p-md-7xh {
    padding: var(--spacing-scale-7xh) !important;
  }
  .px-md-7xh {
    padding-left: var(--spacing-scale-7xh) !important;
    padding-right: var(--spacing-scale-7xh) !important;
  }
  .py-md-7xh {
    padding-bottom: var(--spacing-scale-7xh) !important;
  }
  .pt-md-7xh,
  .py-md-7xh {
    padding-top: var(--spacing-scale-7xh) !important;
  }
  .pt-md-n7xh {
    padding-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pr-md-7xh {
    padding-right: var(--spacing-scale-7xh) !important;
  }
  .pr-md-n7xh {
    padding-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pb-md-7xh {
    padding-bottom: var(--spacing-scale-7xh) !important;
  }
  .pb-md-n7xh {
    padding-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pl-md-7xh {
    padding-left: var(--spacing-scale-7xh) !important;
  }
  .pl-md-n7xh {
    padding-left: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .p-md-n7xh {
    padding: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .px-md-n7xh {
    padding-left: calc(var(--spacing-scale-7xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .py-md-n7xh {
    padding-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .p-md-8x {
    padding: var(--spacing-scale-8x) !important;
  }
  .px-md-8x {
    padding-left: var(--spacing-scale-8x) !important;
    padding-right: var(--spacing-scale-8x) !important;
  }
  .py-md-8x {
    padding-bottom: var(--spacing-scale-8x) !important;
  }
  .pt-md-8x,
  .py-md-8x {
    padding-top: var(--spacing-scale-8x) !important;
  }
  .pt-md-n8x {
    padding-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pr-md-8x {
    padding-right: var(--spacing-scale-8x) !important;
  }
  .pr-md-n8x {
    padding-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pb-md-8x {
    padding-bottom: var(--spacing-scale-8x) !important;
  }
  .pb-md-n8x {
    padding-bottom: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pl-md-8x {
    padding-left: var(--spacing-scale-8x) !important;
  }
  .pl-md-n8x {
    padding-left: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .p-md-n8x {
    padding: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .px-md-n8x {
    padding-left: calc(var(--spacing-scale-8x) * -1) !important;
    padding-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .py-md-n8x {
    padding-bottom: calc(var(--spacing-scale-8x) * -1) !important;
    padding-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .p-md-8xh {
    padding: var(--spacing-scale-8xh) !important;
  }
  .px-md-8xh {
    padding-left: var(--spacing-scale-8xh) !important;
    padding-right: var(--spacing-scale-8xh) !important;
  }
  .py-md-8xh {
    padding-bottom: var(--spacing-scale-8xh) !important;
  }
  .pt-md-8xh,
  .py-md-8xh {
    padding-top: var(--spacing-scale-8xh) !important;
  }
  .pt-md-n8xh {
    padding-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pr-md-8xh {
    padding-right: var(--spacing-scale-8xh) !important;
  }
  .pr-md-n8xh {
    padding-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pb-md-8xh {
    padding-bottom: var(--spacing-scale-8xh) !important;
  }
  .pb-md-n8xh {
    padding-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pl-md-8xh {
    padding-left: var(--spacing-scale-8xh) !important;
  }
  .pl-md-n8xh {
    padding-left: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .p-md-n8xh {
    padding: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .px-md-n8xh {
    padding-left: calc(var(--spacing-scale-8xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .py-md-n8xh {
    padding-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .p-md-9x {
    padding: var(--spacing-scale-9x) !important;
  }
  .px-md-9x {
    padding-left: var(--spacing-scale-9x) !important;
    padding-right: var(--spacing-scale-9x) !important;
  }
  .py-md-9x {
    padding-bottom: var(--spacing-scale-9x) !important;
  }
  .pt-md-9x,
  .py-md-9x {
    padding-top: var(--spacing-scale-9x) !important;
  }
  .pt-md-n9x {
    padding-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pr-md-9x {
    padding-right: var(--spacing-scale-9x) !important;
  }
  .pr-md-n9x {
    padding-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pb-md-9x {
    padding-bottom: var(--spacing-scale-9x) !important;
  }
  .pb-md-n9x {
    padding-bottom: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pl-md-9x {
    padding-left: var(--spacing-scale-9x) !important;
  }
  .pl-md-n9x {
    padding-left: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .p-md-n9x {
    padding: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .px-md-n9x {
    padding-left: calc(var(--spacing-scale-9x) * -1) !important;
    padding-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .py-md-n9x {
    padding-bottom: calc(var(--spacing-scale-9x) * -1) !important;
    padding-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .p-md-9xh {
    padding: var(--spacing-scale-9xh) !important;
  }
  .px-md-9xh {
    padding-left: var(--spacing-scale-9xh) !important;
    padding-right: var(--spacing-scale-9xh) !important;
  }
  .py-md-9xh {
    padding-bottom: var(--spacing-scale-9xh) !important;
  }
  .pt-md-9xh,
  .py-md-9xh {
    padding-top: var(--spacing-scale-9xh) !important;
  }
  .pt-md-n9xh {
    padding-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pr-md-9xh {
    padding-right: var(--spacing-scale-9xh) !important;
  }
  .pr-md-n9xh {
    padding-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pb-md-9xh {
    padding-bottom: var(--spacing-scale-9xh) !important;
  }
  .pb-md-n9xh {
    padding-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pl-md-9xh {
    padding-left: var(--spacing-scale-9xh) !important;
  }
  .pl-md-n9xh {
    padding-left: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .p-md-n9xh {
    padding: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .px-md-n9xh {
    padding-left: calc(var(--spacing-scale-9xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .py-md-n9xh {
    padding-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .p-md-10x {
    padding: var(--spacing-scale-10x) !important;
  }
  .px-md-10x {
    padding-left: var(--spacing-scale-10x) !important;
    padding-right: var(--spacing-scale-10x) !important;
  }
  .py-md-10x {
    padding-bottom: var(--spacing-scale-10x) !important;
  }
  .pt-md-10x,
  .py-md-10x {
    padding-top: var(--spacing-scale-10x) !important;
  }
  .pt-md-n10x {
    padding-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pr-md-10x {
    padding-right: var(--spacing-scale-10x) !important;
  }
  .pr-md-n10x {
    padding-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pb-md-10x {
    padding-bottom: var(--spacing-scale-10x) !important;
  }
  .pb-md-n10x {
    padding-bottom: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pl-md-10x {
    padding-left: var(--spacing-scale-10x) !important;
  }
  .pl-md-n10x {
    padding-left: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .p-md-n10x {
    padding: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .px-md-n10x {
    padding-left: calc(var(--spacing-scale-10x) * -1) !important;
    padding-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .py-md-n10x {
    padding-bottom: calc(var(--spacing-scale-10x) * -1) !important;
    padding-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .p-md-10xh {
    padding: var(--spacing-scale-10xh) !important;
  }
  .px-md-10xh {
    padding-left: var(--spacing-scale-10xh) !important;
    padding-right: var(--spacing-scale-10xh) !important;
  }
  .py-md-10xh {
    padding-bottom: var(--spacing-scale-10xh) !important;
  }
  .pt-md-10xh,
  .py-md-10xh {
    padding-top: var(--spacing-scale-10xh) !important;
  }
  .pt-md-n10xh {
    padding-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pr-md-10xh {
    padding-right: var(--spacing-scale-10xh) !important;
  }
  .pr-md-n10xh {
    padding-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pb-md-10xh {
    padding-bottom: var(--spacing-scale-10xh) !important;
  }
  .pb-md-n10xh {
    padding-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pl-md-10xh {
    padding-left: var(--spacing-scale-10xh) !important;
  }
  .pl-md-n10xh {
    padding-left: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .p-md-n10xh {
    padding: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .px-md-n10xh {
    padding-left: calc(var(--spacing-scale-10xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .py-md-n10xh {
    padding-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-0 {
    margin: var(--spacing-scale-default) !important;
  }
  .mx-lg-0 {
    margin-left: var(--spacing-scale-default) !important;
    margin-right: var(--spacing-scale-default) !important;
  }
  .my-lg-0 {
    margin-bottom: var(--spacing-scale-default) !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: var(--spacing-scale-default) !important;
  }
  .mr-lg-0 {
    margin-right: var(--spacing-scale-default) !important;
  }
  .mb-lg-0 {
    margin-bottom: var(--spacing-scale-default) !important;
  }
  .ml-lg-0 {
    margin-left: var(--spacing-scale-default) !important;
  }
  .m-lg-half {
    margin: var(--spacing-scale-half) !important;
  }
  .mx-lg-half {
    margin-left: var(--spacing-scale-half) !important;
    margin-right: var(--spacing-scale-half) !important;
  }
  .my-lg-half {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mt-lg-half,
  .my-lg-half {
    margin-top: var(--spacing-scale-half) !important;
  }
  .mt-lg-nhalf {
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mr-lg-half {
    margin-right: var(--spacing-scale-half) !important;
  }
  .mr-lg-nhalf {
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mb-lg-half {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mb-lg-nhalf {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .ml-lg-half {
    margin-left: var(--spacing-scale-half) !important;
  }
  .ml-lg-nhalf {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-lg-nhalf {
    margin: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mx-lg-nhalf {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .my-lg-nhalf {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-lg-base {
    margin: var(--spacing-scale-base) !important;
  }
  .mx-lg-base {
    margin-left: var(--spacing-scale-base) !important;
    margin-right: var(--spacing-scale-base) !important;
  }
  .my-lg-base {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mt-lg-base,
  .my-lg-base {
    margin-top: var(--spacing-scale-base) !important;
  }
  .mt-lg-nbase {
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mr-lg-base {
    margin-right: var(--spacing-scale-base) !important;
  }
  .mr-lg-nbase {
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mb-lg-base {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mb-lg-nbase {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .ml-lg-base {
    margin-left: var(--spacing-scale-base) !important;
  }
  .ml-lg-nbase {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-lg-nbase {
    margin: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mx-lg-nbase {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .my-lg-nbase {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-lg-baseh {
    margin: var(--spacing-scale-baseh) !important;
  }
  .mx-lg-baseh {
    margin-left: var(--spacing-scale-baseh) !important;
    margin-right: var(--spacing-scale-baseh) !important;
  }
  .my-lg-baseh {
    margin-bottom: var(--spacing-scale-baseh) !important;
  }
  .mt-lg-baseh,
  .my-lg-baseh {
    margin-top: var(--spacing-scale-baseh) !important;
  }
  .mt-lg-nbaseh {
    margin-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mr-lg-baseh {
    margin-right: var(--spacing-scale-baseh) !important;
  }
  .mr-lg-nbaseh {
    margin-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mb-lg-baseh {
    margin-bottom: var(--spacing-scale-baseh) !important;
  }
  .mb-lg-nbaseh {
    margin-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .ml-lg-baseh {
    margin-left: var(--spacing-scale-baseh) !important;
  }
  .ml-lg-nbaseh {
    margin-left: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .m-lg-nbaseh {
    margin: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mx-lg-nbaseh {
    margin-left: calc(var(--spacing-scale-baseh) * -1) !important;
    margin-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .my-lg-nbaseh {
    margin-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
    margin-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .m-lg-1 {
    margin: var(--spacing-scale-half) !important;
  }
  .mx-lg-1 {
    margin-left: var(--spacing-scale-half) !important;
    margin-right: var(--spacing-scale-half) !important;
  }
  .my-lg-1 {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: var(--spacing-scale-half) !important;
  }
  .mt-lg-n1 {
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mr-lg-1 {
    margin-right: var(--spacing-scale-half) !important;
  }
  .mr-lg-n1 {
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mb-lg-1 {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mb-lg-n1 {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .ml-lg-1 {
    margin-left: var(--spacing-scale-half) !important;
  }
  .ml-lg-n1 {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-lg-n1 {
    margin: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mx-lg-n1 {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .my-lg-n1 {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-lg-2 {
    margin: var(--spacing-scale-base) !important;
  }
  .mx-lg-2 {
    margin-left: var(--spacing-scale-base) !important;
    margin-right: var(--spacing-scale-base) !important;
  }
  .my-lg-2 {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: var(--spacing-scale-base) !important;
  }
  .mt-lg-n2 {
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mr-lg-2 {
    margin-right: var(--spacing-scale-base) !important;
  }
  .mr-lg-n2 {
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mb-lg-2 {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mb-lg-n2 {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .ml-lg-2 {
    margin-left: var(--spacing-scale-base) !important;
  }
  .ml-lg-n2 {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-lg-n2 {
    margin: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mx-lg-n2 {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .my-lg-n2 {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-lg-3 {
    margin: var(--spacing-scale-2x) !important;
  }
  .mx-lg-3 {
    margin-left: var(--spacing-scale-2x) !important;
    margin-right: var(--spacing-scale-2x) !important;
  }
  .my-lg-3 {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: var(--spacing-scale-2x) !important;
  }
  .mt-lg-n3 {
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mr-lg-3 {
    margin-right: var(--spacing-scale-2x) !important;
  }
  .mr-lg-n3 {
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mb-lg-3 {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mb-lg-n3 {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .ml-lg-3 {
    margin-left: var(--spacing-scale-2x) !important;
  }
  .ml-lg-n3 {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-lg-n3 {
    margin: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mx-lg-n3 {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .my-lg-n3 {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-lg-4 {
    margin: var(--spacing-scale-3x) !important;
  }
  .mx-lg-4 {
    margin-left: var(--spacing-scale-3x) !important;
    margin-right: var(--spacing-scale-3x) !important;
  }
  .my-lg-4 {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: var(--spacing-scale-3x) !important;
  }
  .mt-lg-n4 {
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mr-lg-4 {
    margin-right: var(--spacing-scale-3x) !important;
  }
  .mr-lg-n4 {
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mb-lg-4 {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mb-lg-n4 {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .ml-lg-4 {
    margin-left: var(--spacing-scale-3x) !important;
  }
  .ml-lg-n4 {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-lg-n4 {
    margin: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mx-lg-n4 {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .my-lg-n4 {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-lg-5 {
    margin: var(--spacing-scale-4x) !important;
  }
  .mx-lg-5 {
    margin-left: var(--spacing-scale-4x) !important;
    margin-right: var(--spacing-scale-4x) !important;
  }
  .my-lg-5 {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: var(--spacing-scale-4x) !important;
  }
  .mt-lg-n5 {
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mr-lg-5 {
    margin-right: var(--spacing-scale-4x) !important;
  }
  .mr-lg-n5 {
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mb-lg-5 {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mb-lg-n5 {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .ml-lg-5 {
    margin-left: var(--spacing-scale-4x) !important;
  }
  .ml-lg-n5 {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-lg-n5 {
    margin: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mx-lg-n5 {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .my-lg-n5 {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-lg-6 {
    margin: var(--spacing-scale-5x) !important;
  }
  .mx-lg-6 {
    margin-left: var(--spacing-scale-5x) !important;
    margin-right: var(--spacing-scale-5x) !important;
  }
  .my-lg-6 {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: var(--spacing-scale-5x) !important;
  }
  .mt-lg-n6 {
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mr-lg-6 {
    margin-right: var(--spacing-scale-5x) !important;
  }
  .mr-lg-n6 {
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mb-lg-6 {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mb-lg-n6 {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .ml-lg-6 {
    margin-left: var(--spacing-scale-5x) !important;
  }
  .ml-lg-n6 {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-lg-n6 {
    margin: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mx-lg-n6 {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .my-lg-n6 {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-lg-2x {
    margin: var(--spacing-scale-2x) !important;
  }
  .mx-lg-2x {
    margin-left: var(--spacing-scale-2x) !important;
    margin-right: var(--spacing-scale-2x) !important;
  }
  .my-lg-2x {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mt-lg-2x,
  .my-lg-2x {
    margin-top: var(--spacing-scale-2x) !important;
  }
  .mt-lg-n2x {
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mr-lg-2x {
    margin-right: var(--spacing-scale-2x) !important;
  }
  .mr-lg-n2x {
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mb-lg-2x {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mb-lg-n2x {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .ml-lg-2x {
    margin-left: var(--spacing-scale-2x) !important;
  }
  .ml-lg-n2x {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-lg-n2x {
    margin: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mx-lg-n2x {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .my-lg-n2x {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-lg-2xh {
    margin: var(--spacing-scale-2xh) !important;
  }
  .mx-lg-2xh {
    margin-left: var(--spacing-scale-2xh) !important;
    margin-right: var(--spacing-scale-2xh) !important;
  }
  .my-lg-2xh {
    margin-bottom: var(--spacing-scale-2xh) !important;
  }
  .mt-lg-2xh,
  .my-lg-2xh {
    margin-top: var(--spacing-scale-2xh) !important;
  }
  .mt-lg-n2xh {
    margin-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mr-lg-2xh {
    margin-right: var(--spacing-scale-2xh) !important;
  }
  .mr-lg-n2xh {
    margin-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mb-lg-2xh {
    margin-bottom: var(--spacing-scale-2xh) !important;
  }
  .mb-lg-n2xh {
    margin-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .ml-lg-2xh {
    margin-left: var(--spacing-scale-2xh) !important;
  }
  .ml-lg-n2xh {
    margin-left: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .m-lg-n2xh {
    margin: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mx-lg-n2xh {
    margin-left: calc(var(--spacing-scale-2xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .my-lg-n2xh {
    margin-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .m-lg-3x {
    margin: var(--spacing-scale-3x) !important;
  }
  .mx-lg-3x {
    margin-left: var(--spacing-scale-3x) !important;
    margin-right: var(--spacing-scale-3x) !important;
  }
  .my-lg-3x {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mt-lg-3x,
  .my-lg-3x {
    margin-top: var(--spacing-scale-3x) !important;
  }
  .mt-lg-n3x {
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mr-lg-3x {
    margin-right: var(--spacing-scale-3x) !important;
  }
  .mr-lg-n3x {
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mb-lg-3x {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mb-lg-n3x {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .ml-lg-3x {
    margin-left: var(--spacing-scale-3x) !important;
  }
  .ml-lg-n3x {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-lg-n3x {
    margin: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mx-lg-n3x {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .my-lg-n3x {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-lg-3xh {
    margin: var(--spacing-scale-3xh) !important;
  }
  .mx-lg-3xh {
    margin-left: var(--spacing-scale-3xh) !important;
    margin-right: var(--spacing-scale-3xh) !important;
  }
  .my-lg-3xh {
    margin-bottom: var(--spacing-scale-3xh) !important;
  }
  .mt-lg-3xh,
  .my-lg-3xh {
    margin-top: var(--spacing-scale-3xh) !important;
  }
  .mt-lg-n3xh {
    margin-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mr-lg-3xh {
    margin-right: var(--spacing-scale-3xh) !important;
  }
  .mr-lg-n3xh {
    margin-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mb-lg-3xh {
    margin-bottom: var(--spacing-scale-3xh) !important;
  }
  .mb-lg-n3xh {
    margin-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .ml-lg-3xh {
    margin-left: var(--spacing-scale-3xh) !important;
  }
  .ml-lg-n3xh {
    margin-left: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .m-lg-n3xh {
    margin: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mx-lg-n3xh {
    margin-left: calc(var(--spacing-scale-3xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .my-lg-n3xh {
    margin-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .m-lg-4x {
    margin: var(--spacing-scale-4x) !important;
  }
  .mx-lg-4x {
    margin-left: var(--spacing-scale-4x) !important;
    margin-right: var(--spacing-scale-4x) !important;
  }
  .my-lg-4x {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mt-lg-4x,
  .my-lg-4x {
    margin-top: var(--spacing-scale-4x) !important;
  }
  .mt-lg-n4x {
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mr-lg-4x {
    margin-right: var(--spacing-scale-4x) !important;
  }
  .mr-lg-n4x {
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mb-lg-4x {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mb-lg-n4x {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .ml-lg-4x {
    margin-left: var(--spacing-scale-4x) !important;
  }
  .ml-lg-n4x {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-lg-n4x {
    margin: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mx-lg-n4x {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .my-lg-n4x {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-lg-4xh {
    margin: var(--spacing-scale-4xh) !important;
  }
  .mx-lg-4xh {
    margin-left: var(--spacing-scale-4xh) !important;
    margin-right: var(--spacing-scale-4xh) !important;
  }
  .my-lg-4xh {
    margin-bottom: var(--spacing-scale-4xh) !important;
  }
  .mt-lg-4xh,
  .my-lg-4xh {
    margin-top: var(--spacing-scale-4xh) !important;
  }
  .mt-lg-n4xh {
    margin-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mr-lg-4xh {
    margin-right: var(--spacing-scale-4xh) !important;
  }
  .mr-lg-n4xh {
    margin-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mb-lg-4xh {
    margin-bottom: var(--spacing-scale-4xh) !important;
  }
  .mb-lg-n4xh {
    margin-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .ml-lg-4xh {
    margin-left: var(--spacing-scale-4xh) !important;
  }
  .ml-lg-n4xh {
    margin-left: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .m-lg-n4xh {
    margin: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mx-lg-n4xh {
    margin-left: calc(var(--spacing-scale-4xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .my-lg-n4xh {
    margin-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .m-lg-5x {
    margin: var(--spacing-scale-5x) !important;
  }
  .mx-lg-5x {
    margin-left: var(--spacing-scale-5x) !important;
    margin-right: var(--spacing-scale-5x) !important;
  }
  .my-lg-5x {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mt-lg-5x,
  .my-lg-5x {
    margin-top: var(--spacing-scale-5x) !important;
  }
  .mt-lg-n5x {
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mr-lg-5x {
    margin-right: var(--spacing-scale-5x) !important;
  }
  .mr-lg-n5x {
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mb-lg-5x {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mb-lg-n5x {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .ml-lg-5x {
    margin-left: var(--spacing-scale-5x) !important;
  }
  .ml-lg-n5x {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-lg-n5x {
    margin: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mx-lg-n5x {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .my-lg-n5x {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-lg-5xh {
    margin: var(--spacing-scale-5xh) !important;
  }
  .mx-lg-5xh {
    margin-left: var(--spacing-scale-5xh) !important;
    margin-right: var(--spacing-scale-5xh) !important;
  }
  .my-lg-5xh {
    margin-bottom: var(--spacing-scale-5xh) !important;
  }
  .mt-lg-5xh,
  .my-lg-5xh {
    margin-top: var(--spacing-scale-5xh) !important;
  }
  .mt-lg-n5xh {
    margin-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mr-lg-5xh {
    margin-right: var(--spacing-scale-5xh) !important;
  }
  .mr-lg-n5xh {
    margin-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mb-lg-5xh {
    margin-bottom: var(--spacing-scale-5xh) !important;
  }
  .mb-lg-n5xh {
    margin-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .ml-lg-5xh {
    margin-left: var(--spacing-scale-5xh) !important;
  }
  .ml-lg-n5xh {
    margin-left: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .m-lg-n5xh {
    margin: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mx-lg-n5xh {
    margin-left: calc(var(--spacing-scale-5xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .my-lg-n5xh {
    margin-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .m-lg-6x {
    margin: var(--spacing-scale-6x) !important;
  }
  .mx-lg-6x {
    margin-left: var(--spacing-scale-6x) !important;
    margin-right: var(--spacing-scale-6x) !important;
  }
  .my-lg-6x {
    margin-bottom: var(--spacing-scale-6x) !important;
  }
  .mt-lg-6x,
  .my-lg-6x {
    margin-top: var(--spacing-scale-6x) !important;
  }
  .mt-lg-n6x {
    margin-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mr-lg-6x {
    margin-right: var(--spacing-scale-6x) !important;
  }
  .mr-lg-n6x {
    margin-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mb-lg-6x {
    margin-bottom: var(--spacing-scale-6x) !important;
  }
  .mb-lg-n6x {
    margin-bottom: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .ml-lg-6x {
    margin-left: var(--spacing-scale-6x) !important;
  }
  .ml-lg-n6x {
    margin-left: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .m-lg-n6x {
    margin: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mx-lg-n6x {
    margin-left: calc(var(--spacing-scale-6x) * -1) !important;
    margin-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .my-lg-n6x {
    margin-bottom: calc(var(--spacing-scale-6x) * -1) !important;
    margin-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .m-lg-6xh {
    margin: var(--spacing-scale-6xh) !important;
  }
  .mx-lg-6xh {
    margin-left: var(--spacing-scale-6xh) !important;
    margin-right: var(--spacing-scale-6xh) !important;
  }
  .my-lg-6xh {
    margin-bottom: var(--spacing-scale-6xh) !important;
  }
  .mt-lg-6xh,
  .my-lg-6xh {
    margin-top: var(--spacing-scale-6xh) !important;
  }
  .mt-lg-n6xh {
    margin-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mr-lg-6xh {
    margin-right: var(--spacing-scale-6xh) !important;
  }
  .mr-lg-n6xh {
    margin-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mb-lg-6xh {
    margin-bottom: var(--spacing-scale-6xh) !important;
  }
  .mb-lg-n6xh {
    margin-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .ml-lg-6xh {
    margin-left: var(--spacing-scale-6xh) !important;
  }
  .ml-lg-n6xh {
    margin-left: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .m-lg-n6xh {
    margin: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mx-lg-n6xh {
    margin-left: calc(var(--spacing-scale-6xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .my-lg-n6xh {
    margin-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .m-lg-7x {
    margin: var(--spacing-scale-7x) !important;
  }
  .mx-lg-7x {
    margin-left: var(--spacing-scale-7x) !important;
    margin-right: var(--spacing-scale-7x) !important;
  }
  .my-lg-7x {
    margin-bottom: var(--spacing-scale-7x) !important;
  }
  .mt-lg-7x,
  .my-lg-7x {
    margin-top: var(--spacing-scale-7x) !important;
  }
  .mt-lg-n7x {
    margin-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mr-lg-7x {
    margin-right: var(--spacing-scale-7x) !important;
  }
  .mr-lg-n7x {
    margin-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mb-lg-7x {
    margin-bottom: var(--spacing-scale-7x) !important;
  }
  .mb-lg-n7x {
    margin-bottom: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .ml-lg-7x {
    margin-left: var(--spacing-scale-7x) !important;
  }
  .ml-lg-n7x {
    margin-left: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .m-lg-n7x {
    margin: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mx-lg-n7x {
    margin-left: calc(var(--spacing-scale-7x) * -1) !important;
    margin-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .my-lg-n7x {
    margin-bottom: calc(var(--spacing-scale-7x) * -1) !important;
    margin-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .m-lg-7xh {
    margin: var(--spacing-scale-7xh) !important;
  }
  .mx-lg-7xh {
    margin-left: var(--spacing-scale-7xh) !important;
    margin-right: var(--spacing-scale-7xh) !important;
  }
  .my-lg-7xh {
    margin-bottom: var(--spacing-scale-7xh) !important;
  }
  .mt-lg-7xh,
  .my-lg-7xh {
    margin-top: var(--spacing-scale-7xh) !important;
  }
  .mt-lg-n7xh {
    margin-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mr-lg-7xh {
    margin-right: var(--spacing-scale-7xh) !important;
  }
  .mr-lg-n7xh {
    margin-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mb-lg-7xh {
    margin-bottom: var(--spacing-scale-7xh) !important;
  }
  .mb-lg-n7xh {
    margin-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .ml-lg-7xh {
    margin-left: var(--spacing-scale-7xh) !important;
  }
  .ml-lg-n7xh {
    margin-left: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .m-lg-n7xh {
    margin: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mx-lg-n7xh {
    margin-left: calc(var(--spacing-scale-7xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .my-lg-n7xh {
    margin-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .m-lg-8x {
    margin: var(--spacing-scale-8x) !important;
  }
  .mx-lg-8x {
    margin-left: var(--spacing-scale-8x) !important;
    margin-right: var(--spacing-scale-8x) !important;
  }
  .my-lg-8x {
    margin-bottom: var(--spacing-scale-8x) !important;
  }
  .mt-lg-8x,
  .my-lg-8x {
    margin-top: var(--spacing-scale-8x) !important;
  }
  .mt-lg-n8x {
    margin-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mr-lg-8x {
    margin-right: var(--spacing-scale-8x) !important;
  }
  .mr-lg-n8x {
    margin-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mb-lg-8x {
    margin-bottom: var(--spacing-scale-8x) !important;
  }
  .mb-lg-n8x {
    margin-bottom: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .ml-lg-8x {
    margin-left: var(--spacing-scale-8x) !important;
  }
  .ml-lg-n8x {
    margin-left: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .m-lg-n8x {
    margin: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mx-lg-n8x {
    margin-left: calc(var(--spacing-scale-8x) * -1) !important;
    margin-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .my-lg-n8x {
    margin-bottom: calc(var(--spacing-scale-8x) * -1) !important;
    margin-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .m-lg-8xh {
    margin: var(--spacing-scale-8xh) !important;
  }
  .mx-lg-8xh {
    margin-left: var(--spacing-scale-8xh) !important;
    margin-right: var(--spacing-scale-8xh) !important;
  }
  .my-lg-8xh {
    margin-bottom: var(--spacing-scale-8xh) !important;
  }
  .mt-lg-8xh,
  .my-lg-8xh {
    margin-top: var(--spacing-scale-8xh) !important;
  }
  .mt-lg-n8xh {
    margin-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mr-lg-8xh {
    margin-right: var(--spacing-scale-8xh) !important;
  }
  .mr-lg-n8xh {
    margin-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mb-lg-8xh {
    margin-bottom: var(--spacing-scale-8xh) !important;
  }
  .mb-lg-n8xh {
    margin-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .ml-lg-8xh {
    margin-left: var(--spacing-scale-8xh) !important;
  }
  .ml-lg-n8xh {
    margin-left: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .m-lg-n8xh {
    margin: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mx-lg-n8xh {
    margin-left: calc(var(--spacing-scale-8xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .my-lg-n8xh {
    margin-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .m-lg-9x {
    margin: var(--spacing-scale-9x) !important;
  }
  .mx-lg-9x {
    margin-left: var(--spacing-scale-9x) !important;
    margin-right: var(--spacing-scale-9x) !important;
  }
  .my-lg-9x {
    margin-bottom: var(--spacing-scale-9x) !important;
  }
  .mt-lg-9x,
  .my-lg-9x {
    margin-top: var(--spacing-scale-9x) !important;
  }
  .mt-lg-n9x {
    margin-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mr-lg-9x {
    margin-right: var(--spacing-scale-9x) !important;
  }
  .mr-lg-n9x {
    margin-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mb-lg-9x {
    margin-bottom: var(--spacing-scale-9x) !important;
  }
  .mb-lg-n9x {
    margin-bottom: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .ml-lg-9x {
    margin-left: var(--spacing-scale-9x) !important;
  }
  .ml-lg-n9x {
    margin-left: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .m-lg-n9x {
    margin: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mx-lg-n9x {
    margin-left: calc(var(--spacing-scale-9x) * -1) !important;
    margin-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .my-lg-n9x {
    margin-bottom: calc(var(--spacing-scale-9x) * -1) !important;
    margin-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .m-lg-9xh {
    margin: var(--spacing-scale-9xh) !important;
  }
  .mx-lg-9xh {
    margin-left: var(--spacing-scale-9xh) !important;
    margin-right: var(--spacing-scale-9xh) !important;
  }
  .my-lg-9xh {
    margin-bottom: var(--spacing-scale-9xh) !important;
  }
  .mt-lg-9xh,
  .my-lg-9xh {
    margin-top: var(--spacing-scale-9xh) !important;
  }
  .mt-lg-n9xh {
    margin-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mr-lg-9xh {
    margin-right: var(--spacing-scale-9xh) !important;
  }
  .mr-lg-n9xh {
    margin-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mb-lg-9xh {
    margin-bottom: var(--spacing-scale-9xh) !important;
  }
  .mb-lg-n9xh {
    margin-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .ml-lg-9xh {
    margin-left: var(--spacing-scale-9xh) !important;
  }
  .ml-lg-n9xh {
    margin-left: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .m-lg-n9xh {
    margin: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mx-lg-n9xh {
    margin-left: calc(var(--spacing-scale-9xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .my-lg-n9xh {
    margin-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .m-lg-10x {
    margin: var(--spacing-scale-10x) !important;
  }
  .mx-lg-10x {
    margin-left: var(--spacing-scale-10x) !important;
    margin-right: var(--spacing-scale-10x) !important;
  }
  .my-lg-10x {
    margin-bottom: var(--spacing-scale-10x) !important;
  }
  .mt-lg-10x,
  .my-lg-10x {
    margin-top: var(--spacing-scale-10x) !important;
  }
  .mt-lg-n10x {
    margin-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mr-lg-10x {
    margin-right: var(--spacing-scale-10x) !important;
  }
  .mr-lg-n10x {
    margin-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mb-lg-10x {
    margin-bottom: var(--spacing-scale-10x) !important;
  }
  .mb-lg-n10x {
    margin-bottom: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .ml-lg-10x {
    margin-left: var(--spacing-scale-10x) !important;
  }
  .ml-lg-n10x {
    margin-left: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .m-lg-n10x {
    margin: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mx-lg-n10x {
    margin-left: calc(var(--spacing-scale-10x) * -1) !important;
    margin-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .my-lg-n10x {
    margin-bottom: calc(var(--spacing-scale-10x) * -1) !important;
    margin-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .m-lg-10xh {
    margin: var(--spacing-scale-10xh) !important;
  }
  .mx-lg-10xh {
    margin-left: var(--spacing-scale-10xh) !important;
    margin-right: var(--spacing-scale-10xh) !important;
  }
  .my-lg-10xh {
    margin-bottom: var(--spacing-scale-10xh) !important;
  }
  .mt-lg-10xh,
  .my-lg-10xh {
    margin-top: var(--spacing-scale-10xh) !important;
  }
  .mt-lg-n10xh {
    margin-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mr-lg-10xh {
    margin-right: var(--spacing-scale-10xh) !important;
  }
  .mr-lg-n10xh {
    margin-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mb-lg-10xh {
    margin-bottom: var(--spacing-scale-10xh) !important;
  }
  .mb-lg-n10xh {
    margin-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .ml-lg-10xh {
    margin-left: var(--spacing-scale-10xh) !important;
  }
  .ml-lg-n10xh {
    margin-left: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .m-lg-n10xh {
    margin: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mx-lg-n10xh {
    margin-left: calc(var(--spacing-scale-10xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .my-lg-n10xh {
    margin-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: var(--spacing-scale-default) !important;
  }
  .px-lg-0 {
    padding-left: var(--spacing-scale-default) !important;
    padding-right: var(--spacing-scale-default) !important;
  }
  .py-lg-0 {
    padding-bottom: var(--spacing-scale-default) !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: var(--spacing-scale-default) !important;
  }
  .pr-lg-0 {
    padding-right: var(--spacing-scale-default) !important;
  }
  .pb-lg-0 {
    padding-bottom: var(--spacing-scale-default) !important;
  }
  .pl-lg-0 {
    padding-left: var(--spacing-scale-default) !important;
  }
  .p-lg-half {
    padding: var(--spacing-scale-half) !important;
  }
  .px-lg-half {
    padding-left: var(--spacing-scale-half) !important;
    padding-right: var(--spacing-scale-half) !important;
  }
  .py-lg-half {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pt-lg-half,
  .py-lg-half {
    padding-top: var(--spacing-scale-half) !important;
  }
  .pt-lg-nhalf {
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pr-lg-half {
    padding-right: var(--spacing-scale-half) !important;
  }
  .pr-lg-nhalf {
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pb-lg-half {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pb-lg-nhalf {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pl-lg-half {
    padding-left: var(--spacing-scale-half) !important;
  }
  .pl-lg-nhalf {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-lg-nhalf {
    padding: calc(var(--spacing-scale-half) * -1) !important;
  }
  .px-lg-nhalf {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .py-lg-nhalf {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-lg-base {
    padding: var(--spacing-scale-base) !important;
  }
  .px-lg-base {
    padding-left: var(--spacing-scale-base) !important;
    padding-right: var(--spacing-scale-base) !important;
  }
  .py-lg-base {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pt-lg-base,
  .py-lg-base {
    padding-top: var(--spacing-scale-base) !important;
  }
  .pt-lg-nbase {
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pr-lg-base {
    padding-right: var(--spacing-scale-base) !important;
  }
  .pr-lg-nbase {
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pb-lg-base {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pb-lg-nbase {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pl-lg-base {
    padding-left: var(--spacing-scale-base) !important;
  }
  .pl-lg-nbase {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-lg-nbase {
    padding: calc(var(--spacing-scale-base) * -1) !important;
  }
  .px-lg-nbase {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .py-lg-nbase {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-lg-baseh {
    padding: var(--spacing-scale-baseh) !important;
  }
  .px-lg-baseh {
    padding-left: var(--spacing-scale-baseh) !important;
    padding-right: var(--spacing-scale-baseh) !important;
  }
  .py-lg-baseh {
    padding-bottom: var(--spacing-scale-baseh) !important;
  }
  .pt-lg-baseh,
  .py-lg-baseh {
    padding-top: var(--spacing-scale-baseh) !important;
  }
  .pt-lg-nbaseh {
    padding-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pr-lg-baseh {
    padding-right: var(--spacing-scale-baseh) !important;
  }
  .pr-lg-nbaseh {
    padding-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pb-lg-baseh {
    padding-bottom: var(--spacing-scale-baseh) !important;
  }
  .pb-lg-nbaseh {
    padding-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pl-lg-baseh {
    padding-left: var(--spacing-scale-baseh) !important;
  }
  .pl-lg-nbaseh {
    padding-left: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .p-lg-nbaseh {
    padding: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .px-lg-nbaseh {
    padding-left: calc(var(--spacing-scale-baseh) * -1) !important;
    padding-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .py-lg-nbaseh {
    padding-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
    padding-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .p-lg-1 {
    padding: var(--spacing-scale-half) !important;
  }
  .px-lg-1 {
    padding-left: var(--spacing-scale-half) !important;
    padding-right: var(--spacing-scale-half) !important;
  }
  .py-lg-1 {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: var(--spacing-scale-half) !important;
  }
  .pt-lg-n1 {
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pr-lg-1 {
    padding-right: var(--spacing-scale-half) !important;
  }
  .pr-lg-n1 {
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pb-lg-1 {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pb-lg-n1 {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pl-lg-1 {
    padding-left: var(--spacing-scale-half) !important;
  }
  .pl-lg-n1 {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-lg-n1 {
    padding: calc(var(--spacing-scale-half) * -1) !important;
  }
  .px-lg-n1 {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .py-lg-n1 {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-lg-2 {
    padding: var(--spacing-scale-base) !important;
  }
  .px-lg-2 {
    padding-left: var(--spacing-scale-base) !important;
    padding-right: var(--spacing-scale-base) !important;
  }
  .py-lg-2 {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: var(--spacing-scale-base) !important;
  }
  .pt-lg-n2 {
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pr-lg-2 {
    padding-right: var(--spacing-scale-base) !important;
  }
  .pr-lg-n2 {
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pb-lg-2 {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pb-lg-n2 {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pl-lg-2 {
    padding-left: var(--spacing-scale-base) !important;
  }
  .pl-lg-n2 {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-lg-n2 {
    padding: calc(var(--spacing-scale-base) * -1) !important;
  }
  .px-lg-n2 {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .py-lg-n2 {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-lg-3 {
    padding: var(--spacing-scale-2x) !important;
  }
  .px-lg-3 {
    padding-left: var(--spacing-scale-2x) !important;
    padding-right: var(--spacing-scale-2x) !important;
  }
  .py-lg-3 {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: var(--spacing-scale-2x) !important;
  }
  .pt-lg-n3 {
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pr-lg-3 {
    padding-right: var(--spacing-scale-2x) !important;
  }
  .pr-lg-n3 {
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pb-lg-3 {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pb-lg-n3 {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pl-lg-3 {
    padding-left: var(--spacing-scale-2x) !important;
  }
  .pl-lg-n3 {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-lg-n3 {
    padding: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .px-lg-n3 {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .py-lg-n3 {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-lg-4 {
    padding: var(--spacing-scale-3x) !important;
  }
  .px-lg-4 {
    padding-left: var(--spacing-scale-3x) !important;
    padding-right: var(--spacing-scale-3x) !important;
  }
  .py-lg-4 {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: var(--spacing-scale-3x) !important;
  }
  .pt-lg-n4 {
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pr-lg-4 {
    padding-right: var(--spacing-scale-3x) !important;
  }
  .pr-lg-n4 {
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pb-lg-4 {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pb-lg-n4 {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pl-lg-4 {
    padding-left: var(--spacing-scale-3x) !important;
  }
  .pl-lg-n4 {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-lg-n4 {
    padding: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .px-lg-n4 {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .py-lg-n4 {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-lg-5 {
    padding: var(--spacing-scale-4x) !important;
  }
  .px-lg-5 {
    padding-left: var(--spacing-scale-4x) !important;
    padding-right: var(--spacing-scale-4x) !important;
  }
  .py-lg-5 {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: var(--spacing-scale-4x) !important;
  }
  .pt-lg-n5 {
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pr-lg-5 {
    padding-right: var(--spacing-scale-4x) !important;
  }
  .pr-lg-n5 {
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pb-lg-5 {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pb-lg-n5 {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pl-lg-5 {
    padding-left: var(--spacing-scale-4x) !important;
  }
  .pl-lg-n5 {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-lg-n5 {
    padding: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .px-lg-n5 {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .py-lg-n5 {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-lg-6 {
    padding: var(--spacing-scale-5x) !important;
  }
  .px-lg-6 {
    padding-left: var(--spacing-scale-5x) !important;
    padding-right: var(--spacing-scale-5x) !important;
  }
  .py-lg-6 {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: var(--spacing-scale-5x) !important;
  }
  .pt-lg-n6 {
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pr-lg-6 {
    padding-right: var(--spacing-scale-5x) !important;
  }
  .pr-lg-n6 {
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pb-lg-6 {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pb-lg-n6 {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pl-lg-6 {
    padding-left: var(--spacing-scale-5x) !important;
  }
  .pl-lg-n6 {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-lg-n6 {
    padding: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .px-lg-n6 {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .py-lg-n6 {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-lg-2x {
    padding: var(--spacing-scale-2x) !important;
  }
  .px-lg-2x {
    padding-left: var(--spacing-scale-2x) !important;
    padding-right: var(--spacing-scale-2x) !important;
  }
  .py-lg-2x {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pt-lg-2x,
  .py-lg-2x {
    padding-top: var(--spacing-scale-2x) !important;
  }
  .pt-lg-n2x {
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pr-lg-2x {
    padding-right: var(--spacing-scale-2x) !important;
  }
  .pr-lg-n2x {
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pb-lg-2x {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pb-lg-n2x {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pl-lg-2x {
    padding-left: var(--spacing-scale-2x) !important;
  }
  .pl-lg-n2x {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-lg-n2x {
    padding: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .px-lg-n2x {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .py-lg-n2x {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-lg-2xh {
    padding: var(--spacing-scale-2xh) !important;
  }
  .px-lg-2xh {
    padding-left: var(--spacing-scale-2xh) !important;
    padding-right: var(--spacing-scale-2xh) !important;
  }
  .py-lg-2xh {
    padding-bottom: var(--spacing-scale-2xh) !important;
  }
  .pt-lg-2xh,
  .py-lg-2xh {
    padding-top: var(--spacing-scale-2xh) !important;
  }
  .pt-lg-n2xh {
    padding-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pr-lg-2xh {
    padding-right: var(--spacing-scale-2xh) !important;
  }
  .pr-lg-n2xh {
    padding-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pb-lg-2xh {
    padding-bottom: var(--spacing-scale-2xh) !important;
  }
  .pb-lg-n2xh {
    padding-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pl-lg-2xh {
    padding-left: var(--spacing-scale-2xh) !important;
  }
  .pl-lg-n2xh {
    padding-left: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .p-lg-n2xh {
    padding: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .px-lg-n2xh {
    padding-left: calc(var(--spacing-scale-2xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .py-lg-n2xh {
    padding-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .p-lg-3x {
    padding: var(--spacing-scale-3x) !important;
  }
  .px-lg-3x {
    padding-left: var(--spacing-scale-3x) !important;
    padding-right: var(--spacing-scale-3x) !important;
  }
  .py-lg-3x {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pt-lg-3x,
  .py-lg-3x {
    padding-top: var(--spacing-scale-3x) !important;
  }
  .pt-lg-n3x {
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pr-lg-3x {
    padding-right: var(--spacing-scale-3x) !important;
  }
  .pr-lg-n3x {
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pb-lg-3x {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pb-lg-n3x {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pl-lg-3x {
    padding-left: var(--spacing-scale-3x) !important;
  }
  .pl-lg-n3x {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-lg-n3x {
    padding: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .px-lg-n3x {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .py-lg-n3x {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-lg-3xh {
    padding: var(--spacing-scale-3xh) !important;
  }
  .px-lg-3xh {
    padding-left: var(--spacing-scale-3xh) !important;
    padding-right: var(--spacing-scale-3xh) !important;
  }
  .py-lg-3xh {
    padding-bottom: var(--spacing-scale-3xh) !important;
  }
  .pt-lg-3xh,
  .py-lg-3xh {
    padding-top: var(--spacing-scale-3xh) !important;
  }
  .pt-lg-n3xh {
    padding-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pr-lg-3xh {
    padding-right: var(--spacing-scale-3xh) !important;
  }
  .pr-lg-n3xh {
    padding-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pb-lg-3xh {
    padding-bottom: var(--spacing-scale-3xh) !important;
  }
  .pb-lg-n3xh {
    padding-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pl-lg-3xh {
    padding-left: var(--spacing-scale-3xh) !important;
  }
  .pl-lg-n3xh {
    padding-left: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .p-lg-n3xh {
    padding: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .px-lg-n3xh {
    padding-left: calc(var(--spacing-scale-3xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .py-lg-n3xh {
    padding-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .p-lg-4x {
    padding: var(--spacing-scale-4x) !important;
  }
  .px-lg-4x {
    padding-left: var(--spacing-scale-4x) !important;
    padding-right: var(--spacing-scale-4x) !important;
  }
  .py-lg-4x {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pt-lg-4x,
  .py-lg-4x {
    padding-top: var(--spacing-scale-4x) !important;
  }
  .pt-lg-n4x {
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pr-lg-4x {
    padding-right: var(--spacing-scale-4x) !important;
  }
  .pr-lg-n4x {
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pb-lg-4x {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pb-lg-n4x {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pl-lg-4x {
    padding-left: var(--spacing-scale-4x) !important;
  }
  .pl-lg-n4x {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-lg-n4x {
    padding: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .px-lg-n4x {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .py-lg-n4x {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-lg-4xh {
    padding: var(--spacing-scale-4xh) !important;
  }
  .px-lg-4xh {
    padding-left: var(--spacing-scale-4xh) !important;
    padding-right: var(--spacing-scale-4xh) !important;
  }
  .py-lg-4xh {
    padding-bottom: var(--spacing-scale-4xh) !important;
  }
  .pt-lg-4xh,
  .py-lg-4xh {
    padding-top: var(--spacing-scale-4xh) !important;
  }
  .pt-lg-n4xh {
    padding-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pr-lg-4xh {
    padding-right: var(--spacing-scale-4xh) !important;
  }
  .pr-lg-n4xh {
    padding-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pb-lg-4xh {
    padding-bottom: var(--spacing-scale-4xh) !important;
  }
  .pb-lg-n4xh {
    padding-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pl-lg-4xh {
    padding-left: var(--spacing-scale-4xh) !important;
  }
  .pl-lg-n4xh {
    padding-left: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .p-lg-n4xh {
    padding: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .px-lg-n4xh {
    padding-left: calc(var(--spacing-scale-4xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .py-lg-n4xh {
    padding-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .p-lg-5x {
    padding: var(--spacing-scale-5x) !important;
  }
  .px-lg-5x {
    padding-left: var(--spacing-scale-5x) !important;
    padding-right: var(--spacing-scale-5x) !important;
  }
  .py-lg-5x {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pt-lg-5x,
  .py-lg-5x {
    padding-top: var(--spacing-scale-5x) !important;
  }
  .pt-lg-n5x {
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pr-lg-5x {
    padding-right: var(--spacing-scale-5x) !important;
  }
  .pr-lg-n5x {
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pb-lg-5x {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pb-lg-n5x {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pl-lg-5x {
    padding-left: var(--spacing-scale-5x) !important;
  }
  .pl-lg-n5x {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-lg-n5x {
    padding: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .px-lg-n5x {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .py-lg-n5x {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-lg-5xh {
    padding: var(--spacing-scale-5xh) !important;
  }
  .px-lg-5xh {
    padding-left: var(--spacing-scale-5xh) !important;
    padding-right: var(--spacing-scale-5xh) !important;
  }
  .py-lg-5xh {
    padding-bottom: var(--spacing-scale-5xh) !important;
  }
  .pt-lg-5xh,
  .py-lg-5xh {
    padding-top: var(--spacing-scale-5xh) !important;
  }
  .pt-lg-n5xh {
    padding-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pr-lg-5xh {
    padding-right: var(--spacing-scale-5xh) !important;
  }
  .pr-lg-n5xh {
    padding-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pb-lg-5xh {
    padding-bottom: var(--spacing-scale-5xh) !important;
  }
  .pb-lg-n5xh {
    padding-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pl-lg-5xh {
    padding-left: var(--spacing-scale-5xh) !important;
  }
  .pl-lg-n5xh {
    padding-left: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .p-lg-n5xh {
    padding: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .px-lg-n5xh {
    padding-left: calc(var(--spacing-scale-5xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .py-lg-n5xh {
    padding-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .p-lg-6x {
    padding: var(--spacing-scale-6x) !important;
  }
  .px-lg-6x {
    padding-left: var(--spacing-scale-6x) !important;
    padding-right: var(--spacing-scale-6x) !important;
  }
  .py-lg-6x {
    padding-bottom: var(--spacing-scale-6x) !important;
  }
  .pt-lg-6x,
  .py-lg-6x {
    padding-top: var(--spacing-scale-6x) !important;
  }
  .pt-lg-n6x {
    padding-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pr-lg-6x {
    padding-right: var(--spacing-scale-6x) !important;
  }
  .pr-lg-n6x {
    padding-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pb-lg-6x {
    padding-bottom: var(--spacing-scale-6x) !important;
  }
  .pb-lg-n6x {
    padding-bottom: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pl-lg-6x {
    padding-left: var(--spacing-scale-6x) !important;
  }
  .pl-lg-n6x {
    padding-left: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .p-lg-n6x {
    padding: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .px-lg-n6x {
    padding-left: calc(var(--spacing-scale-6x) * -1) !important;
    padding-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .py-lg-n6x {
    padding-bottom: calc(var(--spacing-scale-6x) * -1) !important;
    padding-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .p-lg-6xh {
    padding: var(--spacing-scale-6xh) !important;
  }
  .px-lg-6xh {
    padding-left: var(--spacing-scale-6xh) !important;
    padding-right: var(--spacing-scale-6xh) !important;
  }
  .py-lg-6xh {
    padding-bottom: var(--spacing-scale-6xh) !important;
  }
  .pt-lg-6xh,
  .py-lg-6xh {
    padding-top: var(--spacing-scale-6xh) !important;
  }
  .pt-lg-n6xh {
    padding-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pr-lg-6xh {
    padding-right: var(--spacing-scale-6xh) !important;
  }
  .pr-lg-n6xh {
    padding-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pb-lg-6xh {
    padding-bottom: var(--spacing-scale-6xh) !important;
  }
  .pb-lg-n6xh {
    padding-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pl-lg-6xh {
    padding-left: var(--spacing-scale-6xh) !important;
  }
  .pl-lg-n6xh {
    padding-left: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .p-lg-n6xh {
    padding: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .px-lg-n6xh {
    padding-left: calc(var(--spacing-scale-6xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .py-lg-n6xh {
    padding-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .p-lg-7x {
    padding: var(--spacing-scale-7x) !important;
  }
  .px-lg-7x {
    padding-left: var(--spacing-scale-7x) !important;
    padding-right: var(--spacing-scale-7x) !important;
  }
  .py-lg-7x {
    padding-bottom: var(--spacing-scale-7x) !important;
  }
  .pt-lg-7x,
  .py-lg-7x {
    padding-top: var(--spacing-scale-7x) !important;
  }
  .pt-lg-n7x {
    padding-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pr-lg-7x {
    padding-right: var(--spacing-scale-7x) !important;
  }
  .pr-lg-n7x {
    padding-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pb-lg-7x {
    padding-bottom: var(--spacing-scale-7x) !important;
  }
  .pb-lg-n7x {
    padding-bottom: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pl-lg-7x {
    padding-left: var(--spacing-scale-7x) !important;
  }
  .pl-lg-n7x {
    padding-left: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .p-lg-n7x {
    padding: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .px-lg-n7x {
    padding-left: calc(var(--spacing-scale-7x) * -1) !important;
    padding-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .py-lg-n7x {
    padding-bottom: calc(var(--spacing-scale-7x) * -1) !important;
    padding-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .p-lg-7xh {
    padding: var(--spacing-scale-7xh) !important;
  }
  .px-lg-7xh {
    padding-left: var(--spacing-scale-7xh) !important;
    padding-right: var(--spacing-scale-7xh) !important;
  }
  .py-lg-7xh {
    padding-bottom: var(--spacing-scale-7xh) !important;
  }
  .pt-lg-7xh,
  .py-lg-7xh {
    padding-top: var(--spacing-scale-7xh) !important;
  }
  .pt-lg-n7xh {
    padding-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pr-lg-7xh {
    padding-right: var(--spacing-scale-7xh) !important;
  }
  .pr-lg-n7xh {
    padding-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pb-lg-7xh {
    padding-bottom: var(--spacing-scale-7xh) !important;
  }
  .pb-lg-n7xh {
    padding-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pl-lg-7xh {
    padding-left: var(--spacing-scale-7xh) !important;
  }
  .pl-lg-n7xh {
    padding-left: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .p-lg-n7xh {
    padding: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .px-lg-n7xh {
    padding-left: calc(var(--spacing-scale-7xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .py-lg-n7xh {
    padding-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .p-lg-8x {
    padding: var(--spacing-scale-8x) !important;
  }
  .px-lg-8x {
    padding-left: var(--spacing-scale-8x) !important;
    padding-right: var(--spacing-scale-8x) !important;
  }
  .py-lg-8x {
    padding-bottom: var(--spacing-scale-8x) !important;
  }
  .pt-lg-8x,
  .py-lg-8x {
    padding-top: var(--spacing-scale-8x) !important;
  }
  .pt-lg-n8x {
    padding-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pr-lg-8x {
    padding-right: var(--spacing-scale-8x) !important;
  }
  .pr-lg-n8x {
    padding-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pb-lg-8x {
    padding-bottom: var(--spacing-scale-8x) !important;
  }
  .pb-lg-n8x {
    padding-bottom: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pl-lg-8x {
    padding-left: var(--spacing-scale-8x) !important;
  }
  .pl-lg-n8x {
    padding-left: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .p-lg-n8x {
    padding: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .px-lg-n8x {
    padding-left: calc(var(--spacing-scale-8x) * -1) !important;
    padding-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .py-lg-n8x {
    padding-bottom: calc(var(--spacing-scale-8x) * -1) !important;
    padding-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .p-lg-8xh {
    padding: var(--spacing-scale-8xh) !important;
  }
  .px-lg-8xh {
    padding-left: var(--spacing-scale-8xh) !important;
    padding-right: var(--spacing-scale-8xh) !important;
  }
  .py-lg-8xh {
    padding-bottom: var(--spacing-scale-8xh) !important;
  }
  .pt-lg-8xh,
  .py-lg-8xh {
    padding-top: var(--spacing-scale-8xh) !important;
  }
  .pt-lg-n8xh {
    padding-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pr-lg-8xh {
    padding-right: var(--spacing-scale-8xh) !important;
  }
  .pr-lg-n8xh {
    padding-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pb-lg-8xh {
    padding-bottom: var(--spacing-scale-8xh) !important;
  }
  .pb-lg-n8xh {
    padding-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pl-lg-8xh {
    padding-left: var(--spacing-scale-8xh) !important;
  }
  .pl-lg-n8xh {
    padding-left: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .p-lg-n8xh {
    padding: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .px-lg-n8xh {
    padding-left: calc(var(--spacing-scale-8xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .py-lg-n8xh {
    padding-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .p-lg-9x {
    padding: var(--spacing-scale-9x) !important;
  }
  .px-lg-9x {
    padding-left: var(--spacing-scale-9x) !important;
    padding-right: var(--spacing-scale-9x) !important;
  }
  .py-lg-9x {
    padding-bottom: var(--spacing-scale-9x) !important;
  }
  .pt-lg-9x,
  .py-lg-9x {
    padding-top: var(--spacing-scale-9x) !important;
  }
  .pt-lg-n9x {
    padding-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pr-lg-9x {
    padding-right: var(--spacing-scale-9x) !important;
  }
  .pr-lg-n9x {
    padding-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pb-lg-9x {
    padding-bottom: var(--spacing-scale-9x) !important;
  }
  .pb-lg-n9x {
    padding-bottom: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pl-lg-9x {
    padding-left: var(--spacing-scale-9x) !important;
  }
  .pl-lg-n9x {
    padding-left: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .p-lg-n9x {
    padding: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .px-lg-n9x {
    padding-left: calc(var(--spacing-scale-9x) * -1) !important;
    padding-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .py-lg-n9x {
    padding-bottom: calc(var(--spacing-scale-9x) * -1) !important;
    padding-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .p-lg-9xh {
    padding: var(--spacing-scale-9xh) !important;
  }
  .px-lg-9xh {
    padding-left: var(--spacing-scale-9xh) !important;
    padding-right: var(--spacing-scale-9xh) !important;
  }
  .py-lg-9xh {
    padding-bottom: var(--spacing-scale-9xh) !important;
  }
  .pt-lg-9xh,
  .py-lg-9xh {
    padding-top: var(--spacing-scale-9xh) !important;
  }
  .pt-lg-n9xh {
    padding-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pr-lg-9xh {
    padding-right: var(--spacing-scale-9xh) !important;
  }
  .pr-lg-n9xh {
    padding-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pb-lg-9xh {
    padding-bottom: var(--spacing-scale-9xh) !important;
  }
  .pb-lg-n9xh {
    padding-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pl-lg-9xh {
    padding-left: var(--spacing-scale-9xh) !important;
  }
  .pl-lg-n9xh {
    padding-left: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .p-lg-n9xh {
    padding: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .px-lg-n9xh {
    padding-left: calc(var(--spacing-scale-9xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .py-lg-n9xh {
    padding-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .p-lg-10x {
    padding: var(--spacing-scale-10x) !important;
  }
  .px-lg-10x {
    padding-left: var(--spacing-scale-10x) !important;
    padding-right: var(--spacing-scale-10x) !important;
  }
  .py-lg-10x {
    padding-bottom: var(--spacing-scale-10x) !important;
  }
  .pt-lg-10x,
  .py-lg-10x {
    padding-top: var(--spacing-scale-10x) !important;
  }
  .pt-lg-n10x {
    padding-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pr-lg-10x {
    padding-right: var(--spacing-scale-10x) !important;
  }
  .pr-lg-n10x {
    padding-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pb-lg-10x {
    padding-bottom: var(--spacing-scale-10x) !important;
  }
  .pb-lg-n10x {
    padding-bottom: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pl-lg-10x {
    padding-left: var(--spacing-scale-10x) !important;
  }
  .pl-lg-n10x {
    padding-left: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .p-lg-n10x {
    padding: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .px-lg-n10x {
    padding-left: calc(var(--spacing-scale-10x) * -1) !important;
    padding-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .py-lg-n10x {
    padding-bottom: calc(var(--spacing-scale-10x) * -1) !important;
    padding-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .p-lg-10xh {
    padding: var(--spacing-scale-10xh) !important;
  }
  .px-lg-10xh {
    padding-left: var(--spacing-scale-10xh) !important;
    padding-right: var(--spacing-scale-10xh) !important;
  }
  .py-lg-10xh {
    padding-bottom: var(--spacing-scale-10xh) !important;
  }
  .pt-lg-10xh,
  .py-lg-10xh {
    padding-top: var(--spacing-scale-10xh) !important;
  }
  .pt-lg-n10xh {
    padding-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pr-lg-10xh {
    padding-right: var(--spacing-scale-10xh) !important;
  }
  .pr-lg-n10xh {
    padding-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pb-lg-10xh {
    padding-bottom: var(--spacing-scale-10xh) !important;
  }
  .pb-lg-n10xh {
    padding-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pl-lg-10xh {
    padding-left: var(--spacing-scale-10xh) !important;
  }
  .pl-lg-n10xh {
    padding-left: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .p-lg-n10xh {
    padding: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .px-lg-n10xh {
    padding-left: calc(var(--spacing-scale-10xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .py-lg-n10xh {
    padding-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
}
@media (min-width: 1600px) {
  .m-xl-0 {
    margin: var(--spacing-scale-default) !important;
  }
  .mx-xl-0 {
    margin-left: var(--spacing-scale-default) !important;
    margin-right: var(--spacing-scale-default) !important;
  }
  .my-xl-0 {
    margin-bottom: var(--spacing-scale-default) !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: var(--spacing-scale-default) !important;
  }
  .mr-xl-0 {
    margin-right: var(--spacing-scale-default) !important;
  }
  .mb-xl-0 {
    margin-bottom: var(--spacing-scale-default) !important;
  }
  .ml-xl-0 {
    margin-left: var(--spacing-scale-default) !important;
  }
  .m-xl-half {
    margin: var(--spacing-scale-half) !important;
  }
  .mx-xl-half {
    margin-left: var(--spacing-scale-half) !important;
    margin-right: var(--spacing-scale-half) !important;
  }
  .my-xl-half {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mt-xl-half,
  .my-xl-half {
    margin-top: var(--spacing-scale-half) !important;
  }
  .mt-xl-nhalf {
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mr-xl-half {
    margin-right: var(--spacing-scale-half) !important;
  }
  .mr-xl-nhalf {
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mb-xl-half {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mb-xl-nhalf {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .ml-xl-half {
    margin-left: var(--spacing-scale-half) !important;
  }
  .ml-xl-nhalf {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-xl-nhalf {
    margin: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mx-xl-nhalf {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .my-xl-nhalf {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-xl-base {
    margin: var(--spacing-scale-base) !important;
  }
  .mx-xl-base {
    margin-left: var(--spacing-scale-base) !important;
    margin-right: var(--spacing-scale-base) !important;
  }
  .my-xl-base {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mt-xl-base,
  .my-xl-base {
    margin-top: var(--spacing-scale-base) !important;
  }
  .mt-xl-nbase {
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mr-xl-base {
    margin-right: var(--spacing-scale-base) !important;
  }
  .mr-xl-nbase {
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mb-xl-base {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mb-xl-nbase {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .ml-xl-base {
    margin-left: var(--spacing-scale-base) !important;
  }
  .ml-xl-nbase {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-xl-nbase {
    margin: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mx-xl-nbase {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .my-xl-nbase {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-xl-baseh {
    margin: var(--spacing-scale-baseh) !important;
  }
  .mx-xl-baseh {
    margin-left: var(--spacing-scale-baseh) !important;
    margin-right: var(--spacing-scale-baseh) !important;
  }
  .my-xl-baseh {
    margin-bottom: var(--spacing-scale-baseh) !important;
  }
  .mt-xl-baseh,
  .my-xl-baseh {
    margin-top: var(--spacing-scale-baseh) !important;
  }
  .mt-xl-nbaseh {
    margin-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mr-xl-baseh {
    margin-right: var(--spacing-scale-baseh) !important;
  }
  .mr-xl-nbaseh {
    margin-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mb-xl-baseh {
    margin-bottom: var(--spacing-scale-baseh) !important;
  }
  .mb-xl-nbaseh {
    margin-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .ml-xl-baseh {
    margin-left: var(--spacing-scale-baseh) !important;
  }
  .ml-xl-nbaseh {
    margin-left: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .m-xl-nbaseh {
    margin: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .mx-xl-nbaseh {
    margin-left: calc(var(--spacing-scale-baseh) * -1) !important;
    margin-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .my-xl-nbaseh {
    margin-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
    margin-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .m-xl-1 {
    margin: var(--spacing-scale-half) !important;
  }
  .mx-xl-1 {
    margin-left: var(--spacing-scale-half) !important;
    margin-right: var(--spacing-scale-half) !important;
  }
  .my-xl-1 {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: var(--spacing-scale-half) !important;
  }
  .mt-xl-n1 {
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mr-xl-1 {
    margin-right: var(--spacing-scale-half) !important;
  }
  .mr-xl-n1 {
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mb-xl-1 {
    margin-bottom: var(--spacing-scale-half) !important;
  }
  .mb-xl-n1 {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .ml-xl-1 {
    margin-left: var(--spacing-scale-half) !important;
  }
  .ml-xl-n1 {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-xl-n1 {
    margin: calc(var(--spacing-scale-half) * -1) !important;
  }
  .mx-xl-n1 {
    margin-left: calc(var(--spacing-scale-half) * -1) !important;
    margin-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .my-xl-n1 {
    margin-bottom: calc(var(--spacing-scale-half) * -1) !important;
    margin-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .m-xl-2 {
    margin: var(--spacing-scale-base) !important;
  }
  .mx-xl-2 {
    margin-left: var(--spacing-scale-base) !important;
    margin-right: var(--spacing-scale-base) !important;
  }
  .my-xl-2 {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: var(--spacing-scale-base) !important;
  }
  .mt-xl-n2 {
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mr-xl-2 {
    margin-right: var(--spacing-scale-base) !important;
  }
  .mr-xl-n2 {
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mb-xl-2 {
    margin-bottom: var(--spacing-scale-base) !important;
  }
  .mb-xl-n2 {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .ml-xl-2 {
    margin-left: var(--spacing-scale-base) !important;
  }
  .ml-xl-n2 {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-xl-n2 {
    margin: calc(var(--spacing-scale-base) * -1) !important;
  }
  .mx-xl-n2 {
    margin-left: calc(var(--spacing-scale-base) * -1) !important;
    margin-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .my-xl-n2 {
    margin-bottom: calc(var(--spacing-scale-base) * -1) !important;
    margin-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .m-xl-3 {
    margin: var(--spacing-scale-2x) !important;
  }
  .mx-xl-3 {
    margin-left: var(--spacing-scale-2x) !important;
    margin-right: var(--spacing-scale-2x) !important;
  }
  .my-xl-3 {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: var(--spacing-scale-2x) !important;
  }
  .mt-xl-n3 {
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mr-xl-3 {
    margin-right: var(--spacing-scale-2x) !important;
  }
  .mr-xl-n3 {
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mb-xl-3 {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mb-xl-n3 {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .ml-xl-3 {
    margin-left: var(--spacing-scale-2x) !important;
  }
  .ml-xl-n3 {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-xl-n3 {
    margin: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mx-xl-n3 {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .my-xl-n3 {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-xl-4 {
    margin: var(--spacing-scale-3x) !important;
  }
  .mx-xl-4 {
    margin-left: var(--spacing-scale-3x) !important;
    margin-right: var(--spacing-scale-3x) !important;
  }
  .my-xl-4 {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: var(--spacing-scale-3x) !important;
  }
  .mt-xl-n4 {
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mr-xl-4 {
    margin-right: var(--spacing-scale-3x) !important;
  }
  .mr-xl-n4 {
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mb-xl-4 {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mb-xl-n4 {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .ml-xl-4 {
    margin-left: var(--spacing-scale-3x) !important;
  }
  .ml-xl-n4 {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-xl-n4 {
    margin: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mx-xl-n4 {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .my-xl-n4 {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-xl-5 {
    margin: var(--spacing-scale-4x) !important;
  }
  .mx-xl-5 {
    margin-left: var(--spacing-scale-4x) !important;
    margin-right: var(--spacing-scale-4x) !important;
  }
  .my-xl-5 {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: var(--spacing-scale-4x) !important;
  }
  .mt-xl-n5 {
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mr-xl-5 {
    margin-right: var(--spacing-scale-4x) !important;
  }
  .mr-xl-n5 {
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mb-xl-5 {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mb-xl-n5 {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .ml-xl-5 {
    margin-left: var(--spacing-scale-4x) !important;
  }
  .ml-xl-n5 {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-xl-n5 {
    margin: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mx-xl-n5 {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .my-xl-n5 {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-xl-6 {
    margin: var(--spacing-scale-5x) !important;
  }
  .mx-xl-6 {
    margin-left: var(--spacing-scale-5x) !important;
    margin-right: var(--spacing-scale-5x) !important;
  }
  .my-xl-6 {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: var(--spacing-scale-5x) !important;
  }
  .mt-xl-n6 {
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mr-xl-6 {
    margin-right: var(--spacing-scale-5x) !important;
  }
  .mr-xl-n6 {
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mb-xl-6 {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mb-xl-n6 {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .ml-xl-6 {
    margin-left: var(--spacing-scale-5x) !important;
  }
  .ml-xl-n6 {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-xl-n6 {
    margin: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mx-xl-n6 {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .my-xl-n6 {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-xl-2x {
    margin: var(--spacing-scale-2x) !important;
  }
  .mx-xl-2x {
    margin-left: var(--spacing-scale-2x) !important;
    margin-right: var(--spacing-scale-2x) !important;
  }
  .my-xl-2x {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mt-xl-2x,
  .my-xl-2x {
    margin-top: var(--spacing-scale-2x) !important;
  }
  .mt-xl-n2x {
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mr-xl-2x {
    margin-right: var(--spacing-scale-2x) !important;
  }
  .mr-xl-n2x {
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mb-xl-2x {
    margin-bottom: var(--spacing-scale-2x) !important;
  }
  .mb-xl-n2x {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .ml-xl-2x {
    margin-left: var(--spacing-scale-2x) !important;
  }
  .ml-xl-n2x {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-xl-n2x {
    margin: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .mx-xl-n2x {
    margin-left: calc(var(--spacing-scale-2x) * -1) !important;
    margin-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .my-xl-n2x {
    margin-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    margin-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .m-xl-2xh {
    margin: var(--spacing-scale-2xh) !important;
  }
  .mx-xl-2xh {
    margin-left: var(--spacing-scale-2xh) !important;
    margin-right: var(--spacing-scale-2xh) !important;
  }
  .my-xl-2xh {
    margin-bottom: var(--spacing-scale-2xh) !important;
  }
  .mt-xl-2xh,
  .my-xl-2xh {
    margin-top: var(--spacing-scale-2xh) !important;
  }
  .mt-xl-n2xh {
    margin-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mr-xl-2xh {
    margin-right: var(--spacing-scale-2xh) !important;
  }
  .mr-xl-n2xh {
    margin-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mb-xl-2xh {
    margin-bottom: var(--spacing-scale-2xh) !important;
  }
  .mb-xl-n2xh {
    margin-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .ml-xl-2xh {
    margin-left: var(--spacing-scale-2xh) !important;
  }
  .ml-xl-n2xh {
    margin-left: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .m-xl-n2xh {
    margin: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .mx-xl-n2xh {
    margin-left: calc(var(--spacing-scale-2xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .my-xl-n2xh {
    margin-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .m-xl-3x {
    margin: var(--spacing-scale-3x) !important;
  }
  .mx-xl-3x {
    margin-left: var(--spacing-scale-3x) !important;
    margin-right: var(--spacing-scale-3x) !important;
  }
  .my-xl-3x {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mt-xl-3x,
  .my-xl-3x {
    margin-top: var(--spacing-scale-3x) !important;
  }
  .mt-xl-n3x {
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mr-xl-3x {
    margin-right: var(--spacing-scale-3x) !important;
  }
  .mr-xl-n3x {
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mb-xl-3x {
    margin-bottom: var(--spacing-scale-3x) !important;
  }
  .mb-xl-n3x {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .ml-xl-3x {
    margin-left: var(--spacing-scale-3x) !important;
  }
  .ml-xl-n3x {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-xl-n3x {
    margin: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .mx-xl-n3x {
    margin-left: calc(var(--spacing-scale-3x) * -1) !important;
    margin-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .my-xl-n3x {
    margin-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    margin-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .m-xl-3xh {
    margin: var(--spacing-scale-3xh) !important;
  }
  .mx-xl-3xh {
    margin-left: var(--spacing-scale-3xh) !important;
    margin-right: var(--spacing-scale-3xh) !important;
  }
  .my-xl-3xh {
    margin-bottom: var(--spacing-scale-3xh) !important;
  }
  .mt-xl-3xh,
  .my-xl-3xh {
    margin-top: var(--spacing-scale-3xh) !important;
  }
  .mt-xl-n3xh {
    margin-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mr-xl-3xh {
    margin-right: var(--spacing-scale-3xh) !important;
  }
  .mr-xl-n3xh {
    margin-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mb-xl-3xh {
    margin-bottom: var(--spacing-scale-3xh) !important;
  }
  .mb-xl-n3xh {
    margin-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .ml-xl-3xh {
    margin-left: var(--spacing-scale-3xh) !important;
  }
  .ml-xl-n3xh {
    margin-left: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .m-xl-n3xh {
    margin: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .mx-xl-n3xh {
    margin-left: calc(var(--spacing-scale-3xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .my-xl-n3xh {
    margin-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .m-xl-4x {
    margin: var(--spacing-scale-4x) !important;
  }
  .mx-xl-4x {
    margin-left: var(--spacing-scale-4x) !important;
    margin-right: var(--spacing-scale-4x) !important;
  }
  .my-xl-4x {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mt-xl-4x,
  .my-xl-4x {
    margin-top: var(--spacing-scale-4x) !important;
  }
  .mt-xl-n4x {
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mr-xl-4x {
    margin-right: var(--spacing-scale-4x) !important;
  }
  .mr-xl-n4x {
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mb-xl-4x {
    margin-bottom: var(--spacing-scale-4x) !important;
  }
  .mb-xl-n4x {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .ml-xl-4x {
    margin-left: var(--spacing-scale-4x) !important;
  }
  .ml-xl-n4x {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-xl-n4x {
    margin: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .mx-xl-n4x {
    margin-left: calc(var(--spacing-scale-4x) * -1) !important;
    margin-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .my-xl-n4x {
    margin-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    margin-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .m-xl-4xh {
    margin: var(--spacing-scale-4xh) !important;
  }
  .mx-xl-4xh {
    margin-left: var(--spacing-scale-4xh) !important;
    margin-right: var(--spacing-scale-4xh) !important;
  }
  .my-xl-4xh {
    margin-bottom: var(--spacing-scale-4xh) !important;
  }
  .mt-xl-4xh,
  .my-xl-4xh {
    margin-top: var(--spacing-scale-4xh) !important;
  }
  .mt-xl-n4xh {
    margin-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mr-xl-4xh {
    margin-right: var(--spacing-scale-4xh) !important;
  }
  .mr-xl-n4xh {
    margin-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mb-xl-4xh {
    margin-bottom: var(--spacing-scale-4xh) !important;
  }
  .mb-xl-n4xh {
    margin-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .ml-xl-4xh {
    margin-left: var(--spacing-scale-4xh) !important;
  }
  .ml-xl-n4xh {
    margin-left: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .m-xl-n4xh {
    margin: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .mx-xl-n4xh {
    margin-left: calc(var(--spacing-scale-4xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .my-xl-n4xh {
    margin-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .m-xl-5x {
    margin: var(--spacing-scale-5x) !important;
  }
  .mx-xl-5x {
    margin-left: var(--spacing-scale-5x) !important;
    margin-right: var(--spacing-scale-5x) !important;
  }
  .my-xl-5x {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mt-xl-5x,
  .my-xl-5x {
    margin-top: var(--spacing-scale-5x) !important;
  }
  .mt-xl-n5x {
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mr-xl-5x {
    margin-right: var(--spacing-scale-5x) !important;
  }
  .mr-xl-n5x {
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mb-xl-5x {
    margin-bottom: var(--spacing-scale-5x) !important;
  }
  .mb-xl-n5x {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .ml-xl-5x {
    margin-left: var(--spacing-scale-5x) !important;
  }
  .ml-xl-n5x {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-xl-n5x {
    margin: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .mx-xl-n5x {
    margin-left: calc(var(--spacing-scale-5x) * -1) !important;
    margin-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .my-xl-n5x {
    margin-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    margin-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .m-xl-5xh {
    margin: var(--spacing-scale-5xh) !important;
  }
  .mx-xl-5xh {
    margin-left: var(--spacing-scale-5xh) !important;
    margin-right: var(--spacing-scale-5xh) !important;
  }
  .my-xl-5xh {
    margin-bottom: var(--spacing-scale-5xh) !important;
  }
  .mt-xl-5xh,
  .my-xl-5xh {
    margin-top: var(--spacing-scale-5xh) !important;
  }
  .mt-xl-n5xh {
    margin-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mr-xl-5xh {
    margin-right: var(--spacing-scale-5xh) !important;
  }
  .mr-xl-n5xh {
    margin-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mb-xl-5xh {
    margin-bottom: var(--spacing-scale-5xh) !important;
  }
  .mb-xl-n5xh {
    margin-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .ml-xl-5xh {
    margin-left: var(--spacing-scale-5xh) !important;
  }
  .ml-xl-n5xh {
    margin-left: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .m-xl-n5xh {
    margin: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .mx-xl-n5xh {
    margin-left: calc(var(--spacing-scale-5xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .my-xl-n5xh {
    margin-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .m-xl-6x {
    margin: var(--spacing-scale-6x) !important;
  }
  .mx-xl-6x {
    margin-left: var(--spacing-scale-6x) !important;
    margin-right: var(--spacing-scale-6x) !important;
  }
  .my-xl-6x {
    margin-bottom: var(--spacing-scale-6x) !important;
  }
  .mt-xl-6x,
  .my-xl-6x {
    margin-top: var(--spacing-scale-6x) !important;
  }
  .mt-xl-n6x {
    margin-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mr-xl-6x {
    margin-right: var(--spacing-scale-6x) !important;
  }
  .mr-xl-n6x {
    margin-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mb-xl-6x {
    margin-bottom: var(--spacing-scale-6x) !important;
  }
  .mb-xl-n6x {
    margin-bottom: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .ml-xl-6x {
    margin-left: var(--spacing-scale-6x) !important;
  }
  .ml-xl-n6x {
    margin-left: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .m-xl-n6x {
    margin: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .mx-xl-n6x {
    margin-left: calc(var(--spacing-scale-6x) * -1) !important;
    margin-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .my-xl-n6x {
    margin-bottom: calc(var(--spacing-scale-6x) * -1) !important;
    margin-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .m-xl-6xh {
    margin: var(--spacing-scale-6xh) !important;
  }
  .mx-xl-6xh {
    margin-left: var(--spacing-scale-6xh) !important;
    margin-right: var(--spacing-scale-6xh) !important;
  }
  .my-xl-6xh {
    margin-bottom: var(--spacing-scale-6xh) !important;
  }
  .mt-xl-6xh,
  .my-xl-6xh {
    margin-top: var(--spacing-scale-6xh) !important;
  }
  .mt-xl-n6xh {
    margin-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mr-xl-6xh {
    margin-right: var(--spacing-scale-6xh) !important;
  }
  .mr-xl-n6xh {
    margin-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mb-xl-6xh {
    margin-bottom: var(--spacing-scale-6xh) !important;
  }
  .mb-xl-n6xh {
    margin-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .ml-xl-6xh {
    margin-left: var(--spacing-scale-6xh) !important;
  }
  .ml-xl-n6xh {
    margin-left: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .m-xl-n6xh {
    margin: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .mx-xl-n6xh {
    margin-left: calc(var(--spacing-scale-6xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .my-xl-n6xh {
    margin-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .m-xl-7x {
    margin: var(--spacing-scale-7x) !important;
  }
  .mx-xl-7x {
    margin-left: var(--spacing-scale-7x) !important;
    margin-right: var(--spacing-scale-7x) !important;
  }
  .my-xl-7x {
    margin-bottom: var(--spacing-scale-7x) !important;
  }
  .mt-xl-7x,
  .my-xl-7x {
    margin-top: var(--spacing-scale-7x) !important;
  }
  .mt-xl-n7x {
    margin-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mr-xl-7x {
    margin-right: var(--spacing-scale-7x) !important;
  }
  .mr-xl-n7x {
    margin-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mb-xl-7x {
    margin-bottom: var(--spacing-scale-7x) !important;
  }
  .mb-xl-n7x {
    margin-bottom: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .ml-xl-7x {
    margin-left: var(--spacing-scale-7x) !important;
  }
  .ml-xl-n7x {
    margin-left: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .m-xl-n7x {
    margin: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .mx-xl-n7x {
    margin-left: calc(var(--spacing-scale-7x) * -1) !important;
    margin-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .my-xl-n7x {
    margin-bottom: calc(var(--spacing-scale-7x) * -1) !important;
    margin-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .m-xl-7xh {
    margin: var(--spacing-scale-7xh) !important;
  }
  .mx-xl-7xh {
    margin-left: var(--spacing-scale-7xh) !important;
    margin-right: var(--spacing-scale-7xh) !important;
  }
  .my-xl-7xh {
    margin-bottom: var(--spacing-scale-7xh) !important;
  }
  .mt-xl-7xh,
  .my-xl-7xh {
    margin-top: var(--spacing-scale-7xh) !important;
  }
  .mt-xl-n7xh {
    margin-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mr-xl-7xh {
    margin-right: var(--spacing-scale-7xh) !important;
  }
  .mr-xl-n7xh {
    margin-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mb-xl-7xh {
    margin-bottom: var(--spacing-scale-7xh) !important;
  }
  .mb-xl-n7xh {
    margin-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .ml-xl-7xh {
    margin-left: var(--spacing-scale-7xh) !important;
  }
  .ml-xl-n7xh {
    margin-left: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .m-xl-n7xh {
    margin: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .mx-xl-n7xh {
    margin-left: calc(var(--spacing-scale-7xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .my-xl-n7xh {
    margin-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .m-xl-8x {
    margin: var(--spacing-scale-8x) !important;
  }
  .mx-xl-8x {
    margin-left: var(--spacing-scale-8x) !important;
    margin-right: var(--spacing-scale-8x) !important;
  }
  .my-xl-8x {
    margin-bottom: var(--spacing-scale-8x) !important;
  }
  .mt-xl-8x,
  .my-xl-8x {
    margin-top: var(--spacing-scale-8x) !important;
  }
  .mt-xl-n8x {
    margin-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mr-xl-8x {
    margin-right: var(--spacing-scale-8x) !important;
  }
  .mr-xl-n8x {
    margin-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mb-xl-8x {
    margin-bottom: var(--spacing-scale-8x) !important;
  }
  .mb-xl-n8x {
    margin-bottom: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .ml-xl-8x {
    margin-left: var(--spacing-scale-8x) !important;
  }
  .ml-xl-n8x {
    margin-left: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .m-xl-n8x {
    margin: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .mx-xl-n8x {
    margin-left: calc(var(--spacing-scale-8x) * -1) !important;
    margin-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .my-xl-n8x {
    margin-bottom: calc(var(--spacing-scale-8x) * -1) !important;
    margin-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .m-xl-8xh {
    margin: var(--spacing-scale-8xh) !important;
  }
  .mx-xl-8xh {
    margin-left: var(--spacing-scale-8xh) !important;
    margin-right: var(--spacing-scale-8xh) !important;
  }
  .my-xl-8xh {
    margin-bottom: var(--spacing-scale-8xh) !important;
  }
  .mt-xl-8xh,
  .my-xl-8xh {
    margin-top: var(--spacing-scale-8xh) !important;
  }
  .mt-xl-n8xh {
    margin-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mr-xl-8xh {
    margin-right: var(--spacing-scale-8xh) !important;
  }
  .mr-xl-n8xh {
    margin-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mb-xl-8xh {
    margin-bottom: var(--spacing-scale-8xh) !important;
  }
  .mb-xl-n8xh {
    margin-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .ml-xl-8xh {
    margin-left: var(--spacing-scale-8xh) !important;
  }
  .ml-xl-n8xh {
    margin-left: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .m-xl-n8xh {
    margin: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .mx-xl-n8xh {
    margin-left: calc(var(--spacing-scale-8xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .my-xl-n8xh {
    margin-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .m-xl-9x {
    margin: var(--spacing-scale-9x) !important;
  }
  .mx-xl-9x {
    margin-left: var(--spacing-scale-9x) !important;
    margin-right: var(--spacing-scale-9x) !important;
  }
  .my-xl-9x {
    margin-bottom: var(--spacing-scale-9x) !important;
  }
  .mt-xl-9x,
  .my-xl-9x {
    margin-top: var(--spacing-scale-9x) !important;
  }
  .mt-xl-n9x {
    margin-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mr-xl-9x {
    margin-right: var(--spacing-scale-9x) !important;
  }
  .mr-xl-n9x {
    margin-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mb-xl-9x {
    margin-bottom: var(--spacing-scale-9x) !important;
  }
  .mb-xl-n9x {
    margin-bottom: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .ml-xl-9x {
    margin-left: var(--spacing-scale-9x) !important;
  }
  .ml-xl-n9x {
    margin-left: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .m-xl-n9x {
    margin: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .mx-xl-n9x {
    margin-left: calc(var(--spacing-scale-9x) * -1) !important;
    margin-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .my-xl-n9x {
    margin-bottom: calc(var(--spacing-scale-9x) * -1) !important;
    margin-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .m-xl-9xh {
    margin: var(--spacing-scale-9xh) !important;
  }
  .mx-xl-9xh {
    margin-left: var(--spacing-scale-9xh) !important;
    margin-right: var(--spacing-scale-9xh) !important;
  }
  .my-xl-9xh {
    margin-bottom: var(--spacing-scale-9xh) !important;
  }
  .mt-xl-9xh,
  .my-xl-9xh {
    margin-top: var(--spacing-scale-9xh) !important;
  }
  .mt-xl-n9xh {
    margin-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mr-xl-9xh {
    margin-right: var(--spacing-scale-9xh) !important;
  }
  .mr-xl-n9xh {
    margin-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mb-xl-9xh {
    margin-bottom: var(--spacing-scale-9xh) !important;
  }
  .mb-xl-n9xh {
    margin-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .ml-xl-9xh {
    margin-left: var(--spacing-scale-9xh) !important;
  }
  .ml-xl-n9xh {
    margin-left: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .m-xl-n9xh {
    margin: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .mx-xl-n9xh {
    margin-left: calc(var(--spacing-scale-9xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .my-xl-n9xh {
    margin-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .m-xl-10x {
    margin: var(--spacing-scale-10x) !important;
  }
  .mx-xl-10x {
    margin-left: var(--spacing-scale-10x) !important;
    margin-right: var(--spacing-scale-10x) !important;
  }
  .my-xl-10x {
    margin-bottom: var(--spacing-scale-10x) !important;
  }
  .mt-xl-10x,
  .my-xl-10x {
    margin-top: var(--spacing-scale-10x) !important;
  }
  .mt-xl-n10x {
    margin-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mr-xl-10x {
    margin-right: var(--spacing-scale-10x) !important;
  }
  .mr-xl-n10x {
    margin-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mb-xl-10x {
    margin-bottom: var(--spacing-scale-10x) !important;
  }
  .mb-xl-n10x {
    margin-bottom: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .ml-xl-10x {
    margin-left: var(--spacing-scale-10x) !important;
  }
  .ml-xl-n10x {
    margin-left: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .m-xl-n10x {
    margin: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .mx-xl-n10x {
    margin-left: calc(var(--spacing-scale-10x) * -1) !important;
    margin-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .my-xl-n10x {
    margin-bottom: calc(var(--spacing-scale-10x) * -1) !important;
    margin-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .m-xl-10xh {
    margin: var(--spacing-scale-10xh) !important;
  }
  .mx-xl-10xh {
    margin-left: var(--spacing-scale-10xh) !important;
    margin-right: var(--spacing-scale-10xh) !important;
  }
  .my-xl-10xh {
    margin-bottom: var(--spacing-scale-10xh) !important;
  }
  .mt-xl-10xh,
  .my-xl-10xh {
    margin-top: var(--spacing-scale-10xh) !important;
  }
  .mt-xl-n10xh {
    margin-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mr-xl-10xh {
    margin-right: var(--spacing-scale-10xh) !important;
  }
  .mr-xl-n10xh {
    margin-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mb-xl-10xh {
    margin-bottom: var(--spacing-scale-10xh) !important;
  }
  .mb-xl-n10xh {
    margin-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .ml-xl-10xh {
    margin-left: var(--spacing-scale-10xh) !important;
  }
  .ml-xl-n10xh {
    margin-left: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .m-xl-n10xh {
    margin: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .mx-xl-n10xh {
    margin-left: calc(var(--spacing-scale-10xh) * -1) !important;
    margin-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .my-xl-n10xh {
    margin-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
    margin-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: var(--spacing-scale-default) !important;
  }
  .px-xl-0 {
    padding-left: var(--spacing-scale-default) !important;
    padding-right: var(--spacing-scale-default) !important;
  }
  .py-xl-0 {
    padding-bottom: var(--spacing-scale-default) !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: var(--spacing-scale-default) !important;
  }
  .pr-xl-0 {
    padding-right: var(--spacing-scale-default) !important;
  }
  .pb-xl-0 {
    padding-bottom: var(--spacing-scale-default) !important;
  }
  .pl-xl-0 {
    padding-left: var(--spacing-scale-default) !important;
  }
  .p-xl-half {
    padding: var(--spacing-scale-half) !important;
  }
  .px-xl-half {
    padding-left: var(--spacing-scale-half) !important;
    padding-right: var(--spacing-scale-half) !important;
  }
  .py-xl-half {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pt-xl-half,
  .py-xl-half {
    padding-top: var(--spacing-scale-half) !important;
  }
  .pt-xl-nhalf {
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pr-xl-half {
    padding-right: var(--spacing-scale-half) !important;
  }
  .pr-xl-nhalf {
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pb-xl-half {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pb-xl-nhalf {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pl-xl-half {
    padding-left: var(--spacing-scale-half) !important;
  }
  .pl-xl-nhalf {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-xl-nhalf {
    padding: calc(var(--spacing-scale-half) * -1) !important;
  }
  .px-xl-nhalf {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .py-xl-nhalf {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-xl-base {
    padding: var(--spacing-scale-base) !important;
  }
  .px-xl-base {
    padding-left: var(--spacing-scale-base) !important;
    padding-right: var(--spacing-scale-base) !important;
  }
  .py-xl-base {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pt-xl-base,
  .py-xl-base {
    padding-top: var(--spacing-scale-base) !important;
  }
  .pt-xl-nbase {
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pr-xl-base {
    padding-right: var(--spacing-scale-base) !important;
  }
  .pr-xl-nbase {
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pb-xl-base {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pb-xl-nbase {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pl-xl-base {
    padding-left: var(--spacing-scale-base) !important;
  }
  .pl-xl-nbase {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-xl-nbase {
    padding: calc(var(--spacing-scale-base) * -1) !important;
  }
  .px-xl-nbase {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .py-xl-nbase {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-xl-baseh {
    padding: var(--spacing-scale-baseh) !important;
  }
  .px-xl-baseh {
    padding-left: var(--spacing-scale-baseh) !important;
    padding-right: var(--spacing-scale-baseh) !important;
  }
  .py-xl-baseh {
    padding-bottom: var(--spacing-scale-baseh) !important;
  }
  .pt-xl-baseh,
  .py-xl-baseh {
    padding-top: var(--spacing-scale-baseh) !important;
  }
  .pt-xl-nbaseh {
    padding-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pr-xl-baseh {
    padding-right: var(--spacing-scale-baseh) !important;
  }
  .pr-xl-nbaseh {
    padding-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pb-xl-baseh {
    padding-bottom: var(--spacing-scale-baseh) !important;
  }
  .pb-xl-nbaseh {
    padding-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .pl-xl-baseh {
    padding-left: var(--spacing-scale-baseh) !important;
  }
  .pl-xl-nbaseh {
    padding-left: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .p-xl-nbaseh {
    padding: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .px-xl-nbaseh {
    padding-left: calc(var(--spacing-scale-baseh) * -1) !important;
    padding-right: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .py-xl-nbaseh {
    padding-bottom: calc(var(--spacing-scale-baseh) * -1) !important;
    padding-top: calc(var(--spacing-scale-baseh) * -1) !important;
  }
  .p-xl-1 {
    padding: var(--spacing-scale-half) !important;
  }
  .px-xl-1 {
    padding-left: var(--spacing-scale-half) !important;
    padding-right: var(--spacing-scale-half) !important;
  }
  .py-xl-1 {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: var(--spacing-scale-half) !important;
  }
  .pt-xl-n1 {
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pr-xl-1 {
    padding-right: var(--spacing-scale-half) !important;
  }
  .pr-xl-n1 {
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pb-xl-1 {
    padding-bottom: var(--spacing-scale-half) !important;
  }
  .pb-xl-n1 {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
  }
  .pl-xl-1 {
    padding-left: var(--spacing-scale-half) !important;
  }
  .pl-xl-n1 {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-xl-n1 {
    padding: calc(var(--spacing-scale-half) * -1) !important;
  }
  .px-xl-n1 {
    padding-left: calc(var(--spacing-scale-half) * -1) !important;
    padding-right: calc(var(--spacing-scale-half) * -1) !important;
  }
  .py-xl-n1 {
    padding-bottom: calc(var(--spacing-scale-half) * -1) !important;
    padding-top: calc(var(--spacing-scale-half) * -1) !important;
  }
  .p-xl-2 {
    padding: var(--spacing-scale-base) !important;
  }
  .px-xl-2 {
    padding-left: var(--spacing-scale-base) !important;
    padding-right: var(--spacing-scale-base) !important;
  }
  .py-xl-2 {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: var(--spacing-scale-base) !important;
  }
  .pt-xl-n2 {
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pr-xl-2 {
    padding-right: var(--spacing-scale-base) !important;
  }
  .pr-xl-n2 {
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pb-xl-2 {
    padding-bottom: var(--spacing-scale-base) !important;
  }
  .pb-xl-n2 {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
  }
  .pl-xl-2 {
    padding-left: var(--spacing-scale-base) !important;
  }
  .pl-xl-n2 {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-xl-n2 {
    padding: calc(var(--spacing-scale-base) * -1) !important;
  }
  .px-xl-n2 {
    padding-left: calc(var(--spacing-scale-base) * -1) !important;
    padding-right: calc(var(--spacing-scale-base) * -1) !important;
  }
  .py-xl-n2 {
    padding-bottom: calc(var(--spacing-scale-base) * -1) !important;
    padding-top: calc(var(--spacing-scale-base) * -1) !important;
  }
  .p-xl-3 {
    padding: var(--spacing-scale-2x) !important;
  }
  .px-xl-3 {
    padding-left: var(--spacing-scale-2x) !important;
    padding-right: var(--spacing-scale-2x) !important;
  }
  .py-xl-3 {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: var(--spacing-scale-2x) !important;
  }
  .pt-xl-n3 {
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pr-xl-3 {
    padding-right: var(--spacing-scale-2x) !important;
  }
  .pr-xl-n3 {
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pb-xl-3 {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pb-xl-n3 {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pl-xl-3 {
    padding-left: var(--spacing-scale-2x) !important;
  }
  .pl-xl-n3 {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-xl-n3 {
    padding: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .px-xl-n3 {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .py-xl-n3 {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-xl-4 {
    padding: var(--spacing-scale-3x) !important;
  }
  .px-xl-4 {
    padding-left: var(--spacing-scale-3x) !important;
    padding-right: var(--spacing-scale-3x) !important;
  }
  .py-xl-4 {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: var(--spacing-scale-3x) !important;
  }
  .pt-xl-n4 {
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pr-xl-4 {
    padding-right: var(--spacing-scale-3x) !important;
  }
  .pr-xl-n4 {
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pb-xl-4 {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pb-xl-n4 {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pl-xl-4 {
    padding-left: var(--spacing-scale-3x) !important;
  }
  .pl-xl-n4 {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-xl-n4 {
    padding: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .px-xl-n4 {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .py-xl-n4 {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-xl-5 {
    padding: var(--spacing-scale-4x) !important;
  }
  .px-xl-5 {
    padding-left: var(--spacing-scale-4x) !important;
    padding-right: var(--spacing-scale-4x) !important;
  }
  .py-xl-5 {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: var(--spacing-scale-4x) !important;
  }
  .pt-xl-n5 {
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pr-xl-5 {
    padding-right: var(--spacing-scale-4x) !important;
  }
  .pr-xl-n5 {
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pb-xl-5 {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pb-xl-n5 {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pl-xl-5 {
    padding-left: var(--spacing-scale-4x) !important;
  }
  .pl-xl-n5 {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-xl-n5 {
    padding: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .px-xl-n5 {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .py-xl-n5 {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-xl-6 {
    padding: var(--spacing-scale-5x) !important;
  }
  .px-xl-6 {
    padding-left: var(--spacing-scale-5x) !important;
    padding-right: var(--spacing-scale-5x) !important;
  }
  .py-xl-6 {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: var(--spacing-scale-5x) !important;
  }
  .pt-xl-n6 {
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pr-xl-6 {
    padding-right: var(--spacing-scale-5x) !important;
  }
  .pr-xl-n6 {
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pb-xl-6 {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pb-xl-n6 {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pl-xl-6 {
    padding-left: var(--spacing-scale-5x) !important;
  }
  .pl-xl-n6 {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-xl-n6 {
    padding: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .px-xl-n6 {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .py-xl-n6 {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-xl-2x {
    padding: var(--spacing-scale-2x) !important;
  }
  .px-xl-2x {
    padding-left: var(--spacing-scale-2x) !important;
    padding-right: var(--spacing-scale-2x) !important;
  }
  .py-xl-2x {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pt-xl-2x,
  .py-xl-2x {
    padding-top: var(--spacing-scale-2x) !important;
  }
  .pt-xl-n2x {
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pr-xl-2x {
    padding-right: var(--spacing-scale-2x) !important;
  }
  .pr-xl-n2x {
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pb-xl-2x {
    padding-bottom: var(--spacing-scale-2x) !important;
  }
  .pb-xl-n2x {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .pl-xl-2x {
    padding-left: var(--spacing-scale-2x) !important;
  }
  .pl-xl-n2x {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-xl-n2x {
    padding: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .px-xl-n2x {
    padding-left: calc(var(--spacing-scale-2x) * -1) !important;
    padding-right: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .py-xl-n2x {
    padding-bottom: calc(var(--spacing-scale-2x) * -1) !important;
    padding-top: calc(var(--spacing-scale-2x) * -1) !important;
  }
  .p-xl-2xh {
    padding: var(--spacing-scale-2xh) !important;
  }
  .px-xl-2xh {
    padding-left: var(--spacing-scale-2xh) !important;
    padding-right: var(--spacing-scale-2xh) !important;
  }
  .py-xl-2xh {
    padding-bottom: var(--spacing-scale-2xh) !important;
  }
  .pt-xl-2xh,
  .py-xl-2xh {
    padding-top: var(--spacing-scale-2xh) !important;
  }
  .pt-xl-n2xh {
    padding-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pr-xl-2xh {
    padding-right: var(--spacing-scale-2xh) !important;
  }
  .pr-xl-n2xh {
    padding-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pb-xl-2xh {
    padding-bottom: var(--spacing-scale-2xh) !important;
  }
  .pb-xl-n2xh {
    padding-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .pl-xl-2xh {
    padding-left: var(--spacing-scale-2xh) !important;
  }
  .pl-xl-n2xh {
    padding-left: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .p-xl-n2xh {
    padding: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .px-xl-n2xh {
    padding-left: calc(var(--spacing-scale-2xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .py-xl-n2xh {
    padding-bottom: calc(var(--spacing-scale-2xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-2xh) * -1) !important;
  }
  .p-xl-3x {
    padding: var(--spacing-scale-3x) !important;
  }
  .px-xl-3x {
    padding-left: var(--spacing-scale-3x) !important;
    padding-right: var(--spacing-scale-3x) !important;
  }
  .py-xl-3x {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pt-xl-3x,
  .py-xl-3x {
    padding-top: var(--spacing-scale-3x) !important;
  }
  .pt-xl-n3x {
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pr-xl-3x {
    padding-right: var(--spacing-scale-3x) !important;
  }
  .pr-xl-n3x {
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pb-xl-3x {
    padding-bottom: var(--spacing-scale-3x) !important;
  }
  .pb-xl-n3x {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .pl-xl-3x {
    padding-left: var(--spacing-scale-3x) !important;
  }
  .pl-xl-n3x {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-xl-n3x {
    padding: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .px-xl-n3x {
    padding-left: calc(var(--spacing-scale-3x) * -1) !important;
    padding-right: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .py-xl-n3x {
    padding-bottom: calc(var(--spacing-scale-3x) * -1) !important;
    padding-top: calc(var(--spacing-scale-3x) * -1) !important;
  }
  .p-xl-3xh {
    padding: var(--spacing-scale-3xh) !important;
  }
  .px-xl-3xh {
    padding-left: var(--spacing-scale-3xh) !important;
    padding-right: var(--spacing-scale-3xh) !important;
  }
  .py-xl-3xh {
    padding-bottom: var(--spacing-scale-3xh) !important;
  }
  .pt-xl-3xh,
  .py-xl-3xh {
    padding-top: var(--spacing-scale-3xh) !important;
  }
  .pt-xl-n3xh {
    padding-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pr-xl-3xh {
    padding-right: var(--spacing-scale-3xh) !important;
  }
  .pr-xl-n3xh {
    padding-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pb-xl-3xh {
    padding-bottom: var(--spacing-scale-3xh) !important;
  }
  .pb-xl-n3xh {
    padding-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .pl-xl-3xh {
    padding-left: var(--spacing-scale-3xh) !important;
  }
  .pl-xl-n3xh {
    padding-left: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .p-xl-n3xh {
    padding: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .px-xl-n3xh {
    padding-left: calc(var(--spacing-scale-3xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .py-xl-n3xh {
    padding-bottom: calc(var(--spacing-scale-3xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-3xh) * -1) !important;
  }
  .p-xl-4x {
    padding: var(--spacing-scale-4x) !important;
  }
  .px-xl-4x {
    padding-left: var(--spacing-scale-4x) !important;
    padding-right: var(--spacing-scale-4x) !important;
  }
  .py-xl-4x {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pt-xl-4x,
  .py-xl-4x {
    padding-top: var(--spacing-scale-4x) !important;
  }
  .pt-xl-n4x {
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pr-xl-4x {
    padding-right: var(--spacing-scale-4x) !important;
  }
  .pr-xl-n4x {
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pb-xl-4x {
    padding-bottom: var(--spacing-scale-4x) !important;
  }
  .pb-xl-n4x {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .pl-xl-4x {
    padding-left: var(--spacing-scale-4x) !important;
  }
  .pl-xl-n4x {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-xl-n4x {
    padding: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .px-xl-n4x {
    padding-left: calc(var(--spacing-scale-4x) * -1) !important;
    padding-right: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .py-xl-n4x {
    padding-bottom: calc(var(--spacing-scale-4x) * -1) !important;
    padding-top: calc(var(--spacing-scale-4x) * -1) !important;
  }
  .p-xl-4xh {
    padding: var(--spacing-scale-4xh) !important;
  }
  .px-xl-4xh {
    padding-left: var(--spacing-scale-4xh) !important;
    padding-right: var(--spacing-scale-4xh) !important;
  }
  .py-xl-4xh {
    padding-bottom: var(--spacing-scale-4xh) !important;
  }
  .pt-xl-4xh,
  .py-xl-4xh {
    padding-top: var(--spacing-scale-4xh) !important;
  }
  .pt-xl-n4xh {
    padding-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pr-xl-4xh {
    padding-right: var(--spacing-scale-4xh) !important;
  }
  .pr-xl-n4xh {
    padding-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pb-xl-4xh {
    padding-bottom: var(--spacing-scale-4xh) !important;
  }
  .pb-xl-n4xh {
    padding-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .pl-xl-4xh {
    padding-left: var(--spacing-scale-4xh) !important;
  }
  .pl-xl-n4xh {
    padding-left: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .p-xl-n4xh {
    padding: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .px-xl-n4xh {
    padding-left: calc(var(--spacing-scale-4xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .py-xl-n4xh {
    padding-bottom: calc(var(--spacing-scale-4xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-4xh) * -1) !important;
  }
  .p-xl-5x {
    padding: var(--spacing-scale-5x) !important;
  }
  .px-xl-5x {
    padding-left: var(--spacing-scale-5x) !important;
    padding-right: var(--spacing-scale-5x) !important;
  }
  .py-xl-5x {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pt-xl-5x,
  .py-xl-5x {
    padding-top: var(--spacing-scale-5x) !important;
  }
  .pt-xl-n5x {
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pr-xl-5x {
    padding-right: var(--spacing-scale-5x) !important;
  }
  .pr-xl-n5x {
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pb-xl-5x {
    padding-bottom: var(--spacing-scale-5x) !important;
  }
  .pb-xl-n5x {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .pl-xl-5x {
    padding-left: var(--spacing-scale-5x) !important;
  }
  .pl-xl-n5x {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-xl-n5x {
    padding: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .px-xl-n5x {
    padding-left: calc(var(--spacing-scale-5x) * -1) !important;
    padding-right: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .py-xl-n5x {
    padding-bottom: calc(var(--spacing-scale-5x) * -1) !important;
    padding-top: calc(var(--spacing-scale-5x) * -1) !important;
  }
  .p-xl-5xh {
    padding: var(--spacing-scale-5xh) !important;
  }
  .px-xl-5xh {
    padding-left: var(--spacing-scale-5xh) !important;
    padding-right: var(--spacing-scale-5xh) !important;
  }
  .py-xl-5xh {
    padding-bottom: var(--spacing-scale-5xh) !important;
  }
  .pt-xl-5xh,
  .py-xl-5xh {
    padding-top: var(--spacing-scale-5xh) !important;
  }
  .pt-xl-n5xh {
    padding-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pr-xl-5xh {
    padding-right: var(--spacing-scale-5xh) !important;
  }
  .pr-xl-n5xh {
    padding-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pb-xl-5xh {
    padding-bottom: var(--spacing-scale-5xh) !important;
  }
  .pb-xl-n5xh {
    padding-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .pl-xl-5xh {
    padding-left: var(--spacing-scale-5xh) !important;
  }
  .pl-xl-n5xh {
    padding-left: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .p-xl-n5xh {
    padding: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .px-xl-n5xh {
    padding-left: calc(var(--spacing-scale-5xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .py-xl-n5xh {
    padding-bottom: calc(var(--spacing-scale-5xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-5xh) * -1) !important;
  }
  .p-xl-6x {
    padding: var(--spacing-scale-6x) !important;
  }
  .px-xl-6x {
    padding-left: var(--spacing-scale-6x) !important;
    padding-right: var(--spacing-scale-6x) !important;
  }
  .py-xl-6x {
    padding-bottom: var(--spacing-scale-6x) !important;
  }
  .pt-xl-6x,
  .py-xl-6x {
    padding-top: var(--spacing-scale-6x) !important;
  }
  .pt-xl-n6x {
    padding-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pr-xl-6x {
    padding-right: var(--spacing-scale-6x) !important;
  }
  .pr-xl-n6x {
    padding-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pb-xl-6x {
    padding-bottom: var(--spacing-scale-6x) !important;
  }
  .pb-xl-n6x {
    padding-bottom: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .pl-xl-6x {
    padding-left: var(--spacing-scale-6x) !important;
  }
  .pl-xl-n6x {
    padding-left: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .p-xl-n6x {
    padding: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .px-xl-n6x {
    padding-left: calc(var(--spacing-scale-6x) * -1) !important;
    padding-right: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .py-xl-n6x {
    padding-bottom: calc(var(--spacing-scale-6x) * -1) !important;
    padding-top: calc(var(--spacing-scale-6x) * -1) !important;
  }
  .p-xl-6xh {
    padding: var(--spacing-scale-6xh) !important;
  }
  .px-xl-6xh {
    padding-left: var(--spacing-scale-6xh) !important;
    padding-right: var(--spacing-scale-6xh) !important;
  }
  .py-xl-6xh {
    padding-bottom: var(--spacing-scale-6xh) !important;
  }
  .pt-xl-6xh,
  .py-xl-6xh {
    padding-top: var(--spacing-scale-6xh) !important;
  }
  .pt-xl-n6xh {
    padding-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pr-xl-6xh {
    padding-right: var(--spacing-scale-6xh) !important;
  }
  .pr-xl-n6xh {
    padding-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pb-xl-6xh {
    padding-bottom: var(--spacing-scale-6xh) !important;
  }
  .pb-xl-n6xh {
    padding-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .pl-xl-6xh {
    padding-left: var(--spacing-scale-6xh) !important;
  }
  .pl-xl-n6xh {
    padding-left: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .p-xl-n6xh {
    padding: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .px-xl-n6xh {
    padding-left: calc(var(--spacing-scale-6xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .py-xl-n6xh {
    padding-bottom: calc(var(--spacing-scale-6xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-6xh) * -1) !important;
  }
  .p-xl-7x {
    padding: var(--spacing-scale-7x) !important;
  }
  .px-xl-7x {
    padding-left: var(--spacing-scale-7x) !important;
    padding-right: var(--spacing-scale-7x) !important;
  }
  .py-xl-7x {
    padding-bottom: var(--spacing-scale-7x) !important;
  }
  .pt-xl-7x,
  .py-xl-7x {
    padding-top: var(--spacing-scale-7x) !important;
  }
  .pt-xl-n7x {
    padding-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pr-xl-7x {
    padding-right: var(--spacing-scale-7x) !important;
  }
  .pr-xl-n7x {
    padding-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pb-xl-7x {
    padding-bottom: var(--spacing-scale-7x) !important;
  }
  .pb-xl-n7x {
    padding-bottom: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .pl-xl-7x {
    padding-left: var(--spacing-scale-7x) !important;
  }
  .pl-xl-n7x {
    padding-left: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .p-xl-n7x {
    padding: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .px-xl-n7x {
    padding-left: calc(var(--spacing-scale-7x) * -1) !important;
    padding-right: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .py-xl-n7x {
    padding-bottom: calc(var(--spacing-scale-7x) * -1) !important;
    padding-top: calc(var(--spacing-scale-7x) * -1) !important;
  }
  .p-xl-7xh {
    padding: var(--spacing-scale-7xh) !important;
  }
  .px-xl-7xh {
    padding-left: var(--spacing-scale-7xh) !important;
    padding-right: var(--spacing-scale-7xh) !important;
  }
  .py-xl-7xh {
    padding-bottom: var(--spacing-scale-7xh) !important;
  }
  .pt-xl-7xh,
  .py-xl-7xh {
    padding-top: var(--spacing-scale-7xh) !important;
  }
  .pt-xl-n7xh {
    padding-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pr-xl-7xh {
    padding-right: var(--spacing-scale-7xh) !important;
  }
  .pr-xl-n7xh {
    padding-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pb-xl-7xh {
    padding-bottom: var(--spacing-scale-7xh) !important;
  }
  .pb-xl-n7xh {
    padding-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .pl-xl-7xh {
    padding-left: var(--spacing-scale-7xh) !important;
  }
  .pl-xl-n7xh {
    padding-left: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .p-xl-n7xh {
    padding: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .px-xl-n7xh {
    padding-left: calc(var(--spacing-scale-7xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .py-xl-n7xh {
    padding-bottom: calc(var(--spacing-scale-7xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-7xh) * -1) !important;
  }
  .p-xl-8x {
    padding: var(--spacing-scale-8x) !important;
  }
  .px-xl-8x {
    padding-left: var(--spacing-scale-8x) !important;
    padding-right: var(--spacing-scale-8x) !important;
  }
  .py-xl-8x {
    padding-bottom: var(--spacing-scale-8x) !important;
  }
  .pt-xl-8x,
  .py-xl-8x {
    padding-top: var(--spacing-scale-8x) !important;
  }
  .pt-xl-n8x {
    padding-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pr-xl-8x {
    padding-right: var(--spacing-scale-8x) !important;
  }
  .pr-xl-n8x {
    padding-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pb-xl-8x {
    padding-bottom: var(--spacing-scale-8x) !important;
  }
  .pb-xl-n8x {
    padding-bottom: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .pl-xl-8x {
    padding-left: var(--spacing-scale-8x) !important;
  }
  .pl-xl-n8x {
    padding-left: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .p-xl-n8x {
    padding: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .px-xl-n8x {
    padding-left: calc(var(--spacing-scale-8x) * -1) !important;
    padding-right: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .py-xl-n8x {
    padding-bottom: calc(var(--spacing-scale-8x) * -1) !important;
    padding-top: calc(var(--spacing-scale-8x) * -1) !important;
  }
  .p-xl-8xh {
    padding: var(--spacing-scale-8xh) !important;
  }
  .px-xl-8xh {
    padding-left: var(--spacing-scale-8xh) !important;
    padding-right: var(--spacing-scale-8xh) !important;
  }
  .py-xl-8xh {
    padding-bottom: var(--spacing-scale-8xh) !important;
  }
  .pt-xl-8xh,
  .py-xl-8xh {
    padding-top: var(--spacing-scale-8xh) !important;
  }
  .pt-xl-n8xh {
    padding-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pr-xl-8xh {
    padding-right: var(--spacing-scale-8xh) !important;
  }
  .pr-xl-n8xh {
    padding-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pb-xl-8xh {
    padding-bottom: var(--spacing-scale-8xh) !important;
  }
  .pb-xl-n8xh {
    padding-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .pl-xl-8xh {
    padding-left: var(--spacing-scale-8xh) !important;
  }
  .pl-xl-n8xh {
    padding-left: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .p-xl-n8xh {
    padding: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .px-xl-n8xh {
    padding-left: calc(var(--spacing-scale-8xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .py-xl-n8xh {
    padding-bottom: calc(var(--spacing-scale-8xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-8xh) * -1) !important;
  }
  .p-xl-9x {
    padding: var(--spacing-scale-9x) !important;
  }
  .px-xl-9x {
    padding-left: var(--spacing-scale-9x) !important;
    padding-right: var(--spacing-scale-9x) !important;
  }
  .py-xl-9x {
    padding-bottom: var(--spacing-scale-9x) !important;
  }
  .pt-xl-9x,
  .py-xl-9x {
    padding-top: var(--spacing-scale-9x) !important;
  }
  .pt-xl-n9x {
    padding-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pr-xl-9x {
    padding-right: var(--spacing-scale-9x) !important;
  }
  .pr-xl-n9x {
    padding-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pb-xl-9x {
    padding-bottom: var(--spacing-scale-9x) !important;
  }
  .pb-xl-n9x {
    padding-bottom: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .pl-xl-9x {
    padding-left: var(--spacing-scale-9x) !important;
  }
  .pl-xl-n9x {
    padding-left: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .p-xl-n9x {
    padding: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .px-xl-n9x {
    padding-left: calc(var(--spacing-scale-9x) * -1) !important;
    padding-right: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .py-xl-n9x {
    padding-bottom: calc(var(--spacing-scale-9x) * -1) !important;
    padding-top: calc(var(--spacing-scale-9x) * -1) !important;
  }
  .p-xl-9xh {
    padding: var(--spacing-scale-9xh) !important;
  }
  .px-xl-9xh {
    padding-left: var(--spacing-scale-9xh) !important;
    padding-right: var(--spacing-scale-9xh) !important;
  }
  .py-xl-9xh {
    padding-bottom: var(--spacing-scale-9xh) !important;
  }
  .pt-xl-9xh,
  .py-xl-9xh {
    padding-top: var(--spacing-scale-9xh) !important;
  }
  .pt-xl-n9xh {
    padding-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pr-xl-9xh {
    padding-right: var(--spacing-scale-9xh) !important;
  }
  .pr-xl-n9xh {
    padding-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pb-xl-9xh {
    padding-bottom: var(--spacing-scale-9xh) !important;
  }
  .pb-xl-n9xh {
    padding-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .pl-xl-9xh {
    padding-left: var(--spacing-scale-9xh) !important;
  }
  .pl-xl-n9xh {
    padding-left: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .p-xl-n9xh {
    padding: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .px-xl-n9xh {
    padding-left: calc(var(--spacing-scale-9xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .py-xl-n9xh {
    padding-bottom: calc(var(--spacing-scale-9xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-9xh) * -1) !important;
  }
  .p-xl-10x {
    padding: var(--spacing-scale-10x) !important;
  }
  .px-xl-10x {
    padding-left: var(--spacing-scale-10x) !important;
    padding-right: var(--spacing-scale-10x) !important;
  }
  .py-xl-10x {
    padding-bottom: var(--spacing-scale-10x) !important;
  }
  .pt-xl-10x,
  .py-xl-10x {
    padding-top: var(--spacing-scale-10x) !important;
  }
  .pt-xl-n10x {
    padding-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pr-xl-10x {
    padding-right: var(--spacing-scale-10x) !important;
  }
  .pr-xl-n10x {
    padding-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pb-xl-10x {
    padding-bottom: var(--spacing-scale-10x) !important;
  }
  .pb-xl-n10x {
    padding-bottom: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .pl-xl-10x {
    padding-left: var(--spacing-scale-10x) !important;
  }
  .pl-xl-n10x {
    padding-left: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .p-xl-n10x {
    padding: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .px-xl-n10x {
    padding-left: calc(var(--spacing-scale-10x) * -1) !important;
    padding-right: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .py-xl-n10x {
    padding-bottom: calc(var(--spacing-scale-10x) * -1) !important;
    padding-top: calc(var(--spacing-scale-10x) * -1) !important;
  }
  .p-xl-10xh {
    padding: var(--spacing-scale-10xh) !important;
  }
  .px-xl-10xh {
    padding-left: var(--spacing-scale-10xh) !important;
    padding-right: var(--spacing-scale-10xh) !important;
  }
  .py-xl-10xh {
    padding-bottom: var(--spacing-scale-10xh) !important;
  }
  .pt-xl-10xh,
  .py-xl-10xh {
    padding-top: var(--spacing-scale-10xh) !important;
  }
  .pt-xl-n10xh {
    padding-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pr-xl-10xh {
    padding-right: var(--spacing-scale-10xh) !important;
  }
  .pr-xl-n10xh {
    padding-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pb-xl-10xh {
    padding-bottom: var(--spacing-scale-10xh) !important;
  }
  .pb-xl-n10xh {
    padding-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .pl-xl-10xh {
    padding-left: var(--spacing-scale-10xh) !important;
  }
  .pl-xl-n10xh {
    padding-left: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .p-xl-n10xh {
    padding: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .px-xl-n10xh {
    padding-left: calc(var(--spacing-scale-10xh) * -1) !important;
    padding-right: calc(var(--spacing-scale-10xh) * -1) !important;
  }
  .py-xl-n10xh {
    padding-bottom: calc(var(--spacing-scale-10xh) * -1) !important;
    padding-top: calc(var(--spacing-scale-10xh) * -1) !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
@media (min-width: 576px) {
  .br-magic-button,
  .br-magic-button .br-button {
    width: fit-content !important;
  }
  .overflow-sm-auto {
    overflow: auto !important;
  }
  .overflow-sm-hidden {
    overflow: hidden !important;
  }
}
@media (min-width: 992px) {
  .overflow-md-auto {
    overflow: auto !important;
  }
  .overflow-md-hidden {
    overflow: hidden !important;
  }
}
@media (min-width: 1280px) {
  .overflow-lg-auto {
    overflow: auto !important;
  }
  .overflow-lg-hidden {
    overflow: hidden !important;
  }
}
@media (min-width: 1600px) {
  .overflow-xl-auto {
    overflow: auto !important;
  }
  .overflow-xl-hidden {
    overflow: hidden !important;
  }
}
body {
  --bg-color: var(--background);
  --bg-color-rgb: var(--background-rgb);
  --bg-color-hsl: var(--background-hsl);
  --font-size: var(--font-size-scale-base);
  --font-weight: var(--font-weight-regular);
  --link-color: var(--interactive);
  --link-color-rgb: var(--interactive-rgb);
  --link-color-hsl: var(--interactive-hsl);
  --line-height: var(--font-line-height-medium);
  --text-color: var(--color);
  --text-color-rgb: var(--color-rgb);
  --text-color-hsl: var(--color-hsl);
  background: var(--bg-color);
  color: var(--text-color);
  font-family: var(--font-family-base);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
}
p {
  color: var(--color);
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--font-line-height-medium);
  margin-bottom: var(--spacing-scale-2x);
  margin-top: 0;
}
@media (min-width: 576px) {
  p {
    font-size: var(--font-size-scale-up-01);
  }
}
a {
  --focus-offset: var(--spacing-scale-half);
  color: var(--interactive);
  text-decoration: none;
}
a:focus {
  outline: none;
}
a.focus-visible,
a:focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
a:not(:disabled):active {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--pressed)),
    rgba(var(--interactive-rgb), var(--pressed))
  );
}
.main-content a {
  text-decoration: underline;
}
button {
  cursor: pointer;
}
img {
  max-width: 100%;
  vertical-align: middle;
}
.h1,
h1 {
  color: var(--color);
  font-size: var(--font-size-scale-up-04);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-low);
  margin-bottom: var(--spacing-scale-2xh);
  margin-top: 0;
}
@media (min-width: 576px) {
  .h1,
  h1 {
    font-size: var(--font-size-scale-up-06);
    font-weight: var(--font-weight-light);
    margin-bottom: var(--spacing-scale-4x);
  }
}
.h2,
h2 {
  color: var(--color);
  font-size: var(--font-size-scale-up-03);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--font-line-height-low);
  margin-bottom: var(--spacing-scale-2xh);
  margin-top: var(--spacing-scale-3xh);
  padding-bottom: var(--spacing-scale-2xh);
}
@media (min-width: 576px) {
  .h2,
  h2 {
    font-size: var(--font-size-scale-up-05);
    font-weight: var(--font-weight-regular);
  }
}
.h3,
h3 {
  color: var(--color);
  font-size: var(--font-size-scale-up-02);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-line-height-low);
  margin-bottom: var(--spacing-scale-2xh);
  margin-top: var(--spacing-scale-3xh);
}
@media (min-width: 576px) {
  .h3,
  h3 {
    font-size: var(--font-size-scale-up-04);
    font-weight: var(--font-weight-medium);
  }
}
.h4,
h4 {
  color: var(--color);
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-line-height-low);
  margin-bottom: var(--spacing-scale-2xh);
  margin-top: var(--spacing-scale-2x);
}
@media (min-width: 576px) {
  .h4,
  h4 {
    font-size: var(--font-size-scale-up-03);
    font-weight: var(--font-weight-semi-bold);
    margin-top: var(--spacing-scale-3xh);
  }
}
.h5,
h5 {
  color: var(--color);
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-extra-bold);
  line-height: var(--font-line-height-low);
  margin-bottom: var(--spacing-scale-2x);
  margin-top: var(--spacing-scale-2x);
  padding-bottom: var(--spacing-scale-base);
}
@media (min-width: 576px) {
  .h5,
  h5 {
    font-size: var(--font-size-scale-up-02);
    font-weight: var(--font-weight-bold);
    margin-top: var(--spacing-scale-3xh);
  }
}
.h6,
h6 {
  color: var(--color);
  font-size: var(--font-size-scale-down-01);
  font-weight: var(--font-weight-extra-bold);
  line-height: var(--font-line-height-low);
  margin-bottom: var(--spacing-scale-2x);
  margin-top: var(--spacing-scale-2x);
  padding-bottom: var(--spacing-scale-base);
  text-transform: uppercase;
}
@media (min-width: 576px) {
  .h6,
  h6 {
    font-size: var(--font-size-scale-up-01);
    font-weight: var(--font-weight-extra-bold);
    margin-top: var(--spacing-scale-3xh);
  }
}
.br-ol,
.br-ul {
  margin-bottom: var(--spacing-scale-2x);
  margin-top: 0;
  padding: 0 0 0 var(--spacing-scale-5x);
}
blockquote {
  background-color: var(--color-secondary-02);
  border-left: 4px solid #268744;
  font-style: italic;
  margin: var(--spacing-scale-2x) 0;
  padding: var(--spacing-scale-2x);
  position: relative;
}
blockquote > :last-child {
  margin-bottom: 0;
}
table {
  --table-background: var(--background);
  --table-color: var(--color);
  --table-border-color: var(--gray-20);
  --table-border-style: solid;
  --table-border-width: 1px;
  --table-hover: var(--color-rgb);
  --table-padding: var(--spacing-scale-3x);
  --table-font-weight: var(--font-weight-medium);
  --table-th-background: var(--background-alternative);
  --table-th-font-weight: var(--font-weight-semi-bold);
  --table-row-size: var(--spacing-scale-2x);
  border-collapse: collapse;
  width: 100%;
}
th {
  background-color: var(--background-alternative);
  font-weight: var(--table-th-font-weight);
}
td {
  font-weight: var(--table-font-weight);
}
td,
th {
  color: var(--color);
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-medium);
  padding: var(--table-row-size) var(--table-padding);
}
td:not([align]),
th:not([align]) {
  text-align: left;
}
tr.is-selected,
tr.selected {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
tr.is-selected td,
tr.is-selected th,
tr.selected td,
tr.selected th {
  background-color: var(--selected);
}
tr:hover td {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
tbody td,
tbody th {
  border-bottom: var(--table-border-width) var(--table-border-style)
    var(--table-border-color);
}
.label,
label {
  color: var(--color);
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--font-line-height-medium);
  margin-bottom: var(--spacing-scale-half);
}
.fieldset,
fieldset {
  border: var(--surface-width-none);
  margin-bottom: var(--spacing-scale-5x);
  margin-top: 0;
  padding: 0;
}
.legend,
legend {
  color: var(--color);
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--font-line-height-low);
  margin-bottom: var(--spacing-scale-2x);
  margin-top: var(--spacing-scale-2x);
}
.placeholder,
::placeholder {
  color: var(--pure-100) !important;
  font-size: var(--font-size-scale-base);
  font-style: italic;
  font-weight: var(--font-weight-regular);
  line-height: var(--font-line-height-medium);
  margin-bottom: var(--spacing-scale-half);
  margin-top: var(--spacing-scale-half);
  opacity: 1;
}
.input,
input,
textarea {
  color: var(--color-light);
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-low);
  margin-bottom: var(--spacing-scale-half);
}
.code,
.help,
code {
  font-size: var(--font-size-scale-base);
}
.code,
code {
  border-radius: 4px;
  color: var(--gray-80);
  display: inline-block;
  font-family: monospace;
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-low);
  padding: var(--spacing-scale-half);
}
.code,
.pre,
code,
pre {
  background: var(--gray-5);
}
.pre,
pre {
  margin: var(--spacing-scale-2x) 0 var(--spacing-scale-4x);
  padding: var(--spacing-scale-2x);
}
.pre code,
pre code {
  display: inline;
  padding: 0;
}
.mark,
mark {
  background: var(--red-warm-vivid-10);
  color: var(--color);
}
.border-solid-none {
  border: var(--surface-width-none) solid var(--color-secondary-06) !important;
}
.border-solid-sm {
  border: var(--surface-width-sm) solid var(--color-secondary-06) !important;
}
.border-solid-md {
  border: var(--surface-width-md) solid var(--color-secondary-06) !important;
}
.border-solid-lg {
  border: var(--surface-width-lg) solid var(--color-secondary-06) !important;
}
.border-dashed-none {
  border: var(--surface-width-none) dashed var(--color-secondary-06) !important;
}
.border-dashed-sm {
  border: var(--surface-width-sm) dashed var(--color-secondary-06) !important;
}
.border-dashed-md {
  border: var(--surface-width-md) dashed var(--color-secondary-06) !important;
}
.border-dashed-lg {
  border: var(--surface-width-lg) dashed var(--color-secondary-06) !important;
}
.border-top {
  border-top: var(--border-width) var(--border-style) var(--border-color) !important;
}
.border-right {
  border-right: var(--border-width) var(--border-style) var(--border-color) !important;
}
.border-bottom {
  border-bottom: var(--border-width) var(--border-style) var(--border-color) !important;
}
.border-left {
  border-left: var(--border-width) var(--border-style) var(--border-color) !important;
}
.rounder-none {
  --radius: var(--surface-rounder-none);
}
.rounder-none,
.rounder-sm {
  border-radius: var(--radius) !important;
}
.rounder-sm {
  --radius: var(--surface-rounder-sm);
}
.rounder-md {
  --radius: var(--surface-rounder-md);
}
.rounder-lg,
.rounder-md {
  border-radius: var(--radius) !important;
}
.rounder-lg {
  --radius: var(--surface-rounder-lg);
}
.rounder-pill {
  --radius: var(--surface-rounder-pill);
  border-radius: var(--radius) !important;
  -webkit-border-radius: var(--radius) !important;
  -moz-border-radius: var(--radius) !important;
  -ms-border-radius: var(--radius) !important;
  -o-border-radius: var(--radius) !important;
}
.opacity-none {
  opacity: 0;
}
.opacity-default {
  opacity: 1;
}
.opacity-xs {
  opacity: 0.16;
}
.opacity-sm {
  opacity: 0.3;
}
.opacity-md {
  opacity: 0.45;
}
.opacity-lg {
  opacity: 0.65;
}
.opacity-xl {
  opacity: 0.85;
}
.overlay-scrim {
  background: rgba(var(--rgb-secondary-09), var(--surface-opacity-md));
}
.overlay-text {
  background: linear-gradient(
    rgba(var(--rgb-secondary-01), var(--surface-opacity-none)),
    rgba(var(--rgb-secondary-09), var(--surface-opacity-default))
  );
}
.bg-blue-90 {
  --background: var(--blue-90);
  background: var(--blue-90) !important;
}
.text-blue-90 {
  color: var(--blue-90) !important;
}
.border-blue-90 {
  border-color: var(--blue-90) !important;
}
.bg-blue-80 {
  --background: var(--blue-80);
  background: var(--blue-80) !important;
}
.text-blue-80 {
  color: var(--blue-80) !important;
}
.border-blue-80 {
  border-color: var(--blue-80) !important;
}
.bg-blue-70 {
  --background: var(--blue-70);
  background: var(--blue-70) !important;
}
.text-blue-70 {
  color: var(--blue-70) !important;
}
.border-blue-70 {
  border-color: var(--blue-70) !important;
}
.bg-blue-60 {
  --background: var(--blue-60);
  background: var(--blue-60) !important;
}
.text-blue-60 {
  color: var(--blue-60) !important;
}
.border-blue-60 {
  border-color: var(--blue-60) !important;
}
.bg-blue-50 {
  --background: var(--blue-50);
  background: var(--blue-50) !important;
}
.text-blue-50 {
  color: var(--blue-50) !important;
}
.border-blue-50 {
  border-color: var(--blue-50) !important;
}
.bg-blue-40 {
  --background: var(--blue-40);
  background: var(--blue-40) !important;
}
.text-blue-40 {
  color: var(--blue-40) !important;
}
.border-blue-40 {
  border-color: var(--blue-40) !important;
}
.bg-blue-30 {
  --background: var(--blue-30);
  background: var(--blue-30) !important;
}
.text-blue-30 {
  color: var(--blue-30) !important;
}
.border-blue-30 {
  border-color: var(--blue-30) !important;
}
.bg-blue-20 {
  --background: var(--blue-20);
  background: var(--blue-20) !important;
}
.text-blue-20 {
  color: var(--blue-20) !important;
}
.border-blue-20 {
  border-color: var(--blue-20) !important;
}
.bg-blue-10 {
  --background: var(--blue-10);
  background: var(--blue-10) !important;
}
.text-blue-10 {
  color: var(--blue-10) !important;
}
.border-blue-10 {
  border-color: var(--blue-10) !important;
}
.bg-blue-5 {
  --background: var(--blue-5);
  background: var(--blue-5) !important;
}
.text-blue-5 {
  color: var(--blue-5) !important;
}
.border-blue-5 {
  border-color: var(--blue-5) !important;
}
.bg-blue-cool-90 {
  --background: var(--blue-cool-90);
  background: var(--blue-cool-90) !important;
}
.text-blue-cool-90 {
  color: var(--blue-cool-90) !important;
}
.border-blue-cool-90 {
  border-color: var(--blue-cool-90) !important;
}
.bg-blue-cool-80 {
  --background: var(--blue-cool-80);
  background: var(--blue-cool-80) !important;
}
.text-blue-cool-80 {
  color: var(--blue-cool-80) !important;
}
.border-blue-cool-80 {
  border-color: var(--blue-cool-80) !important;
}
.bg-blue-cool-70 {
  --background: var(--blue-cool-70);
  background: var(--blue-cool-70) !important;
}
.text-blue-cool-70 {
  color: var(--blue-cool-70) !important;
}
.border-blue-cool-70 {
  border-color: var(--blue-cool-70) !important;
}
.bg-blue-cool-60 {
  --background: var(--blue-cool-60);
  background: var(--blue-cool-60) !important;
}
.text-blue-cool-60 {
  color: var(--blue-cool-60) !important;
}
.border-blue-cool-60 {
  border-color: var(--blue-cool-60) !important;
}
.bg-blue-cool-50 {
  --background: var(--blue-cool-50);
  background: var(--blue-cool-50) !important;
}
.text-blue-cool-50 {
  color: var(--blue-cool-50) !important;
}
.border-blue-cool-50 {
  border-color: var(--blue-cool-50) !important;
}
.bg-blue-cool-40 {
  --background: var(--blue-cool-40);
  background: var(--blue-cool-40) !important;
}
.text-blue-cool-40 {
  color: var(--blue-cool-40) !important;
}
.border-blue-cool-40 {
  border-color: var(--blue-cool-40) !important;
}
.bg-blue-cool-30 {
  --background: var(--blue-cool-30);
  background: var(--blue-cool-30) !important;
}
.text-blue-cool-30 {
  color: var(--blue-cool-30) !important;
}
.border-blue-cool-30 {
  border-color: var(--blue-cool-30) !important;
}
.bg-blue-cool-20 {
  --background: var(--blue-cool-20);
  background: var(--blue-cool-20) !important;
}
.text-blue-cool-20 {
  color: var(--blue-cool-20) !important;
}
.border-blue-cool-20 {
  border-color: var(--blue-cool-20) !important;
}
.bg-blue-cool-10 {
  --background: var(--blue-cool-10);
  background: var(--blue-cool-10) !important;
}
.text-blue-cool-10 {
  color: var(--blue-cool-10) !important;
}
.border-blue-cool-10 {
  border-color: var(--blue-cool-10) !important;
}
.bg-blue-cool-5 {
  --background: var(--blue-cool-5);
  background: var(--blue-cool-5) !important;
}
.text-blue-cool-5 {
  color: var(--blue-cool-5) !important;
}
.border-blue-cool-5 {
  border-color: var(--blue-cool-5) !important;
}
.bg-blue-warm-90 {
  --background: var(--blue-warm-90);
  background: var(--blue-warm-90) !important;
}
.text-blue-warm-90 {
  color: var(--blue-warm-90) !important;
}
.border-blue-warm-90 {
  border-color: var(--blue-warm-90) !important;
}
.bg-blue-warm-80 {
  --background: var(--blue-warm-80);
  background: var(--blue-warm-80) !important;
}
.text-blue-warm-80 {
  color: var(--blue-warm-80) !important;
}
.border-blue-warm-80 {
  border-color: var(--blue-warm-80) !important;
}
.bg-blue-warm-70 {
  --background: var(--blue-warm-70);
  background: var(--blue-warm-70) !important;
}
.text-blue-warm-70 {
  color: var(--blue-warm-70) !important;
}
.border-blue-warm-70 {
  border-color: var(--blue-warm-70) !important;
}
.bg-blue-warm-60 {
  --background: var(--blue-warm-60);
  background: var(--blue-warm-60) !important;
}
.text-blue-warm-60 {
  color: var(--blue-warm-60) !important;
}
.border-blue-warm-60 {
  border-color: var(--blue-warm-60) !important;
}
.bg-blue-warm-50 {
  --background: var(--blue-warm-50);
  background: var(--blue-warm-50) !important;
}
.text-blue-warm-50 {
  color: var(--blue-warm-50) !important;
}
.border-blue-warm-50 {
  border-color: var(--blue-warm-50) !important;
}
.bg-blue-warm-40 {
  --background: var(--blue-warm-40);
  background: var(--blue-warm-40) !important;
}
.text-blue-warm-40 {
  color: var(--blue-warm-40) !important;
}
.border-blue-warm-40 {
  border-color: var(--blue-warm-40) !important;
}
.bg-blue-warm-30 {
  --background: var(--blue-warm-30);
  background: var(--blue-warm-30) !important;
}
.text-blue-warm-30 {
  color: var(--blue-warm-30) !important;
}
.border-blue-warm-30 {
  border-color: var(--blue-warm-30) !important;
}
.bg-blue-warm-20 {
  --background: var(--blue-warm-20);
  background: var(--blue-warm-20) !important;
}
.text-blue-warm-20 {
  color: var(--blue-warm-20) !important;
}
.border-blue-warm-20 {
  border-color: var(--blue-warm-20) !important;
}
.bg-blue-warm-10 {
  --background: var(--blue-warm-10);
  background: var(--blue-warm-10) !important;
}
.text-blue-warm-10 {
  color: var(--blue-warm-10) !important;
}
.border-blue-warm-10 {
  border-color: var(--blue-warm-10) !important;
}
.bg-blue-warm-5 {
  --background: var(--blue-warm-5);
  background: var(--blue-warm-5) !important;
}
.text-blue-warm-5 {
  color: var(--blue-warm-5) !important;
}
.border-blue-warm-5 {
  border-color: var(--blue-warm-5) !important;
}
.bg-blue-vivid-80 {
  --background: var(--blue-vivid-80);
  background: var(--blue-vivid-80) !important;
}
.text-blue-vivid-80 {
  color: var(--blue-vivid-80) !important;
}
.border-blue-vivid-80 {
  border-color: var(--blue-vivid-80) !important;
}
.bg-blue-vivid-70 {
  --background: var(--blue-vivid-70);
  background: var(--blue-vivid-70) !important;
}
.text-blue-vivid-70 {
  color: var(--blue-vivid-70) !important;
}
.border-blue-vivid-70 {
  border-color: var(--blue-vivid-70) !important;
}
.bg-blue-vivid-60 {
  --background: var(--blue-vivid-60);
  background: var(--blue-vivid-60) !important;
}
.text-blue-vivid-60 {
  color: var(--blue-vivid-60) !important;
}
.border-blue-vivid-60 {
  border-color: var(--blue-vivid-60) !important;
}
.bg-blue-vivid-50 {
  --background: var(--blue-vivid-50);
  background: var(--blue-vivid-50) !important;
}
.text-blue-vivid-50 {
  color: var(--blue-vivid-50) !important;
}
.border-blue-vivid-50 {
  border-color: var(--blue-vivid-50) !important;
}
.bg-blue-vivid-40 {
  --background: var(--blue-vivid-40);
  background: var(--blue-vivid-40) !important;
}
.text-blue-vivid-40 {
  color: var(--blue-vivid-40) !important;
}
.border-blue-vivid-40 {
  border-color: var(--blue-vivid-40) !important;
}
.bg-blue-vivid-30 {
  --background: var(--blue-vivid-30);
  background: var(--blue-vivid-30) !important;
}
.text-blue-vivid-30 {
  color: var(--blue-vivid-30) !important;
}
.border-blue-vivid-30 {
  border-color: var(--blue-vivid-30) !important;
}
.bg-blue-vivid-20 {
  --background: var(--blue-vivid-20);
  background: var(--blue-vivid-20) !important;
}
.text-blue-vivid-20 {
  color: var(--blue-vivid-20) !important;
}
.border-blue-vivid-20 {
  border-color: var(--blue-vivid-20) !important;
}
.bg-blue-vivid-10 {
  --background: var(--blue-vivid-10);
  background: var(--blue-vivid-10) !important;
}
.text-blue-vivid-10 {
  color: var(--blue-vivid-10) !important;
}
.border-blue-vivid-10 {
  border-color: var(--blue-vivid-10) !important;
}
.bg-blue-vivid-5 {
  --background: var(--blue-vivid-5);
  background: var(--blue-vivid-5) !important;
}
.text-blue-vivid-5 {
  color: var(--blue-vivid-5) !important;
}
.border-blue-vivid-5 {
  border-color: var(--blue-vivid-5) !important;
}
.bg-blue-cool-vivid-80 {
  --background: var(--blue-cool-vivid-80);
  background: var(--blue-cool-vivid-80) !important;
}
.text-blue-cool-vivid-80 {
  color: var(--blue-cool-vivid-80) !important;
}
.border-blue-cool-vivid-80 {
  border-color: var(--blue-cool-vivid-80) !important;
}
.bg-blue-cool-vivid-70 {
  --background: var(--blue-cool-vivid-70);
  background: var(--blue-cool-vivid-70) !important;
}
.text-blue-cool-vivid-70 {
  color: var(--blue-cool-vivid-70) !important;
}
.border-blue-cool-vivid-70 {
  border-color: var(--blue-cool-vivid-70) !important;
}
.bg-blue-cool-vivid-60 {
  --background: var(--blue-cool-vivid-60);
  background: var(--blue-cool-vivid-60) !important;
}
.text-blue-cool-vivid-60 {
  color: var(--blue-cool-vivid-60) !important;
}
.border-blue-cool-vivid-60 {
  border-color: var(--blue-cool-vivid-60) !important;
}
.bg-blue-cool-vivid-50 {
  --background: var(--blue-cool-vivid-50);
  background: var(--blue-cool-vivid-50) !important;
}
.text-blue-cool-vivid-50 {
  color: var(--blue-cool-vivid-50) !important;
}
.border-blue-cool-vivid-50 {
  border-color: var(--blue-cool-vivid-50) !important;
}
.bg-blue-cool-vivid-40 {
  --background: var(--blue-cool-vivid-40);
  background: var(--blue-cool-vivid-40) !important;
}
.text-blue-cool-vivid-40 {
  color: var(--blue-cool-vivid-40) !important;
}
.border-blue-cool-vivid-40 {
  border-color: var(--blue-cool-vivid-40) !important;
}
.bg-blue-cool-vivid-30 {
  --background: var(--blue-cool-vivid-30);
  background: var(--blue-cool-vivid-30) !important;
}
.text-blue-cool-vivid-30 {
  color: var(--blue-cool-vivid-30) !important;
}
.border-blue-cool-vivid-30 {
  border-color: var(--blue-cool-vivid-30) !important;
}
.bg-blue-cool-vivid-20 {
  --background: var(--blue-cool-vivid-20);
  background: var(--blue-cool-vivid-20) !important;
}
.text-blue-cool-vivid-20 {
  color: var(--blue-cool-vivid-20) !important;
}
.border-blue-cool-vivid-20 {
  border-color: var(--blue-cool-vivid-20) !important;
}
.bg-blue-cool-vivid-10 {
  --background: var(--blue-cool-vivid-10);
  background: var(--blue-cool-vivid-10) !important;
}
.text-blue-cool-vivid-10 {
  color: var(--blue-cool-vivid-10) !important;
}
.border-blue-cool-vivid-10 {
  border-color: var(--blue-cool-vivid-10) !important;
}
.bg-blue-cool-vivid-5 {
  --background: var(--blue-cool-vivid-5);
  background: var(--blue-cool-vivid-5) !important;
}
.text-blue-cool-vivid-5 {
  color: var(--blue-cool-vivid-5) !important;
}
.border-blue-cool-vivid-5 {
  border-color: var(--blue-cool-vivid-5) !important;
}
.bg-blue-warm-vivid-90 {
  --background: var(--blue-warm-vivid-90);
  background: var(--blue-warm-vivid-90) !important;
}
.text-blue-warm-vivid-90 {
  color: var(--blue-warm-vivid-90) !important;
}
.border-blue-warm-vivid-90 {
  border-color: var(--blue-warm-vivid-90) !important;
}
.bg-blue-warm-vivid-80 {
  --background: var(--blue-warm-vivid-80);
  background: var(--blue-warm-vivid-80) !important;
}
.text-blue-warm-vivid-80 {
  color: var(--blue-warm-vivid-80) !important;
}
.border-blue-warm-vivid-80 {
  border-color: var(--blue-warm-vivid-80) !important;
}
.bg-blue-warm-vivid-70 {
  --background: var(--blue-warm-vivid-70);
  background: var(--blue-warm-vivid-70) !important;
}
.text-blue-warm-vivid-70 {
  color: var(--blue-warm-vivid-70) !important;
}
.border-blue-warm-vivid-70 {
  border-color: var(--blue-warm-vivid-70) !important;
}
.bg-blue-warm-vivid-60 {
  --background: var(--blue-warm-vivid-60);
  background: var(--blue-warm-vivid-60) !important;
}
.text-blue-warm-vivid-60 {
  color: var(--blue-warm-vivid-60) !important;
}
.border-blue-warm-vivid-60 {
  border-color: var(--blue-warm-vivid-60) !important;
}
.bg-blue-warm-vivid-50 {
  --background: var(--blue-warm-vivid-50);
  background: var(--blue-warm-vivid-50) !important;
}
.text-blue-warm-vivid-50 {
  color: var(--blue-warm-vivid-50) !important;
}
.border-blue-warm-vivid-50 {
  border-color: var(--blue-warm-vivid-50) !important;
}
.bg-blue-warm-vivid-40 {
  --background: var(--blue-warm-vivid-40);
  background: var(--blue-warm-vivid-40) !important;
}
.text-blue-warm-vivid-40 {
  color: var(--blue-warm-vivid-40) !important;
}
.border-blue-warm-vivid-40 {
  border-color: var(--blue-warm-vivid-40) !important;
}
.bg-blue-warm-vivid-30 {
  --background: var(--blue-warm-vivid-30);
  background: var(--blue-warm-vivid-30) !important;
}
.text-blue-warm-vivid-30 {
  color: var(--blue-warm-vivid-30) !important;
}
.border-blue-warm-vivid-30 {
  border-color: var(--blue-warm-vivid-30) !important;
}
.bg-blue-warm-vivid-20 {
  --background: var(--blue-warm-vivid-20);
  background: var(--blue-warm-vivid-20) !important;
}
.text-blue-warm-vivid-20 {
  color: var(--blue-warm-vivid-20) !important;
}
.border-blue-warm-vivid-20 {
  border-color: var(--blue-warm-vivid-20) !important;
}
.bg-blue-warm-vivid-10 {
  --background: var(--blue-warm-vivid-10);
  background: var(--blue-warm-vivid-10) !important;
}
.text-blue-warm-vivid-10 {
  color: var(--blue-warm-vivid-10) !important;
}
.border-blue-warm-vivid-10 {
  border-color: var(--blue-warm-vivid-10) !important;
}
.bg-blue-warm-vivid-5 {
  --background: var(--blue-warm-vivid-5);
  background: var(--blue-warm-vivid-5) !important;
}
.text-blue-warm-vivid-5 {
  color: var(--blue-warm-vivid-5) !important;
}
.border-blue-warm-vivid-5 {
  border-color: var(--blue-warm-vivid-5) !important;
}
.bg-cyan-90 {
  --background: var(--cyan-90);
  background: var(--cyan-90) !important;
}
.text-cyan-90 {
  color: var(--cyan-90) !important;
}
.border-cyan-90 {
  border-color: var(--cyan-90) !important;
}
.bg-cyan-80 {
  --background: var(--cyan-80);
  background: var(--cyan-80) !important;
}
.text-cyan-80 {
  color: var(--cyan-80) !important;
}
.border-cyan-80 {
  border-color: var(--cyan-80) !important;
}
.bg-cyan-70 {
  --background: var(--cyan-70);
  background: var(--cyan-70) !important;
}
.text-cyan-70 {
  color: var(--cyan-70) !important;
}
.border-cyan-70 {
  border-color: var(--cyan-70) !important;
}
.bg-cyan-60 {
  --background: var(--cyan-60);
  background: var(--cyan-60) !important;
}
.text-cyan-60 {
  color: var(--cyan-60) !important;
}
.border-cyan-60 {
  border-color: var(--cyan-60) !important;
}
.bg-cyan-50 {
  --background: var(--cyan-50);
  background: var(--cyan-50) !important;
}
.text-cyan-50 {
  color: var(--cyan-50) !important;
}
.border-cyan-50 {
  border-color: var(--cyan-50) !important;
}
.bg-cyan-40 {
  --background: var(--cyan-40);
  background: var(--cyan-40) !important;
}
.text-cyan-40 {
  color: var(--cyan-40) !important;
}
.border-cyan-40 {
  border-color: var(--cyan-40) !important;
}
.bg-cyan-30 {
  --background: var(--cyan-30);
  background: var(--cyan-30) !important;
}
.text-cyan-30 {
  color: var(--cyan-30) !important;
}
.border-cyan-30 {
  border-color: var(--cyan-30) !important;
}
.bg-cyan-20 {
  --background: var(--cyan-20);
  background: var(--cyan-20) !important;
}
.text-cyan-20 {
  color: var(--cyan-20) !important;
}
.border-cyan-20 {
  border-color: var(--cyan-20) !important;
}
.bg-cyan-10 {
  --background: var(--cyan-10);
  background: var(--cyan-10) !important;
}
.text-cyan-10 {
  color: var(--cyan-10) !important;
}
.border-cyan-10 {
  border-color: var(--cyan-10) !important;
}
.bg-cyan-5 {
  --background: var(--cyan-5);
  background: var(--cyan-5) !important;
}
.text-cyan-5 {
  color: var(--cyan-5) !important;
}
.border-cyan-5 {
  border-color: var(--cyan-5) !important;
}
.bg-cyan-vivid-80 {
  --background: var(--cyan-vivid-80);
  background: var(--cyan-vivid-80) !important;
}
.text-cyan-vivid-80 {
  color: var(--cyan-vivid-80) !important;
}
.border-cyan-vivid-80 {
  border-color: var(--cyan-vivid-80) !important;
}
.bg-cyan-vivid-70 {
  --background: var(--cyan-vivid-70);
  background: var(--cyan-vivid-70) !important;
}
.text-cyan-vivid-70 {
  color: var(--cyan-vivid-70) !important;
}
.border-cyan-vivid-70 {
  border-color: var(--cyan-vivid-70) !important;
}
.bg-cyan-vivid-60 {
  --background: var(--cyan-vivid-60);
  background: var(--cyan-vivid-60) !important;
}
.text-cyan-vivid-60 {
  color: var(--cyan-vivid-60) !important;
}
.border-cyan-vivid-60 {
  border-color: var(--cyan-vivid-60) !important;
}
.bg-cyan-vivid-50 {
  --background: var(--cyan-vivid-50);
  background: var(--cyan-vivid-50) !important;
}
.text-cyan-vivid-50 {
  color: var(--cyan-vivid-50) !important;
}
.border-cyan-vivid-50 {
  border-color: var(--cyan-vivid-50) !important;
}
.bg-cyan-vivid-40 {
  --background: var(--cyan-vivid-40);
  background: var(--cyan-vivid-40) !important;
}
.text-cyan-vivid-40 {
  color: var(--cyan-vivid-40) !important;
}
.border-cyan-vivid-40 {
  border-color: var(--cyan-vivid-40) !important;
}
.bg-cyan-vivid-30 {
  --background: var(--cyan-vivid-30);
  background: var(--cyan-vivid-30) !important;
}
.text-cyan-vivid-30 {
  color: var(--cyan-vivid-30) !important;
}
.border-cyan-vivid-30 {
  border-color: var(--cyan-vivid-30) !important;
}
.bg-cyan-vivid-20 {
  --background: var(--cyan-vivid-20);
  background: var(--cyan-vivid-20) !important;
}
.text-cyan-vivid-20 {
  color: var(--cyan-vivid-20) !important;
}
.border-cyan-vivid-20 {
  border-color: var(--cyan-vivid-20) !important;
}
.bg-cyan-vivid-10 {
  --background: var(--cyan-vivid-10);
  background: var(--cyan-vivid-10) !important;
}
.text-cyan-vivid-10 {
  color: var(--cyan-vivid-10) !important;
}
.border-cyan-vivid-10 {
  border-color: var(--cyan-vivid-10) !important;
}
.bg-cyan-vivid-5 {
  --background: var(--cyan-vivid-5);
  background: var(--cyan-vivid-5) !important;
}
.text-cyan-vivid-5 {
  color: var(--cyan-vivid-5) !important;
}
.border-cyan-vivid-5 {
  border-color: var(--cyan-vivid-5) !important;
}
.bg-gold-90 {
  --background: var(--gold-90);
  background: var(--gold-90) !important;
}
.text-gold-90 {
  color: var(--gold-90) !important;
}
.border-gold-90 {
  border-color: var(--gold-90) !important;
}
.bg-gold-80 {
  --background: var(--gold-80);
  background: var(--gold-80) !important;
}
.text-gold-80 {
  color: var(--gold-80) !important;
}
.border-gold-80 {
  border-color: var(--gold-80) !important;
}
.bg-gold-70 {
  --background: var(--gold-70);
  background: var(--gold-70) !important;
}
.text-gold-70 {
  color: var(--gold-70) !important;
}
.border-gold-70 {
  border-color: var(--gold-70) !important;
}
.bg-gold-60 {
  --background: var(--gold-60);
  background: var(--gold-60) !important;
}
.text-gold-60 {
  color: var(--gold-60) !important;
}
.border-gold-60 {
  border-color: var(--gold-60) !important;
}
.bg-gold-50 {
  --background: var(--gold-50);
  background: var(--gold-50) !important;
}
.text-gold-50 {
  color: var(--gold-50) !important;
}
.border-gold-50 {
  border-color: var(--gold-50) !important;
}
.bg-gold-40 {
  --background: var(--gold-40);
  background: var(--gold-40) !important;
}
.text-gold-40 {
  color: var(--gold-40) !important;
}
.border-gold-40 {
  border-color: var(--gold-40) !important;
}
.bg-gold-30 {
  --background: var(--gold-30);
  background: var(--gold-30) !important;
}
.text-gold-30 {
  color: var(--gold-30) !important;
}
.border-gold-30 {
  border-color: var(--gold-30) !important;
}
.bg-gold-20 {
  --background: var(--gold-20);
  background: var(--gold-20) !important;
}
.text-gold-20 {
  color: var(--gold-20) !important;
}
.border-gold-20 {
  border-color: var(--gold-20) !important;
}
.bg-gold-10 {
  --background: var(--gold-10);
  background: var(--gold-10) !important;
}
.text-gold-10 {
  color: var(--gold-10) !important;
}
.border-gold-10 {
  border-color: var(--gold-10) !important;
}
.bg-gold-5 {
  --background: var(--gold-5);
  background: var(--gold-5) !important;
}
.text-gold-5 {
  color: var(--gold-5) !important;
}
.border-gold-5 {
  border-color: var(--gold-5) !important;
}
.bg-gold-vivid-80 {
  --background: var(--gold-vivid-80);
  background: var(--gold-vivid-80) !important;
}
.text-gold-vivid-80 {
  color: var(--gold-vivid-80) !important;
}
.border-gold-vivid-80 {
  border-color: var(--gold-vivid-80) !important;
}
.bg-gold-vivid-70 {
  --background: var(--gold-vivid-70);
  background: var(--gold-vivid-70) !important;
}
.text-gold-vivid-70 {
  color: var(--gold-vivid-70) !important;
}
.border-gold-vivid-70 {
  border-color: var(--gold-vivid-70) !important;
}
.bg-gold-vivid-60 {
  --background: var(--gold-vivid-60);
  background: var(--gold-vivid-60) !important;
}
.text-gold-vivid-60 {
  color: var(--gold-vivid-60) !important;
}
.border-gold-vivid-60 {
  border-color: var(--gold-vivid-60) !important;
}
.bg-gold-vivid-50 {
  --background: var(--gold-vivid-50);
  background: var(--gold-vivid-50) !important;
}
.text-gold-vivid-50 {
  color: var(--gold-vivid-50) !important;
}
.border-gold-vivid-50 {
  border-color: var(--gold-vivid-50) !important;
}
.bg-gold-vivid-40 {
  --background: var(--gold-vivid-40);
  background: var(--gold-vivid-40) !important;
}
.text-gold-vivid-40 {
  color: var(--gold-vivid-40) !important;
}
.border-gold-vivid-40 {
  border-color: var(--gold-vivid-40) !important;
}
.bg-gold-vivid-30 {
  --background: var(--gold-vivid-30);
  background: var(--gold-vivid-30) !important;
}
.text-gold-vivid-30 {
  color: var(--gold-vivid-30) !important;
}
.border-gold-vivid-30 {
  border-color: var(--gold-vivid-30) !important;
}
.bg-gold-vivid-20 {
  --background: var(--gold-vivid-20);
  background: var(--gold-vivid-20) !important;
}
.text-gold-vivid-20 {
  color: var(--gold-vivid-20) !important;
}
.border-gold-vivid-20 {
  border-color: var(--gold-vivid-20) !important;
}
.bg-gold-vivid-10 {
  --background: var(--gold-vivid-10);
  background: var(--gold-vivid-10) !important;
}
.text-gold-vivid-10 {
  color: var(--gold-vivid-10) !important;
}
.border-gold-vivid-10 {
  border-color: var(--gold-vivid-10) !important;
}
.bg-gold-vivid-5 {
  --background: var(--gold-vivid-5);
  background: var(--gold-vivid-5) !important;
}
.text-gold-vivid-5 {
  color: var(--gold-vivid-5) !important;
}
.border-gold-vivid-5 {
  border-color: var(--gold-vivid-5) !important;
}
.bg-gray-90 {
  --background: var(--gray-90);
  background: var(--gray-90) !important;
}
.text-gray-90 {
  color: var(--gray-90) !important;
}
.border-gray-90 {
  border-color: var(--gray-90) !important;
}
.bg-gray-80 {
  --background: var(--gray-80);
  background: var(--gray-80) !important;
}
.text-gray-80 {
  color: var(--gray-80) !important;
}
.border-gray-80 {
  border-color: var(--gray-80) !important;
}
.bg-gray-70 {
  --background: var(--gray-70);
  background: var(--gray-70) !important;
}
.text-gray-70 {
  color: var(--gray-70) !important;
}
.border-gray-70 {
  border-color: var(--gray-70) !important;
}
.bg-gray-60 {
  --background: var(--gray-60);
  background: var(--gray-60) !important;
}
.text-gray-60 {
  color: var(--gray-60) !important;
}
.border-gray-60 {
  border-color: var(--gray-60) !important;
}
.bg-gray-50 {
  --background: var(--gray-50);
  background: var(--gray-50) !important;
}
.text-gray-50 {
  color: var(--gray-50) !important;
}
.border-gray-50 {
  border-color: var(--gray-50) !important;
}
.bg-gray-40 {
  --background: var(--gray-40);
  background: var(--gray-40) !important;
}
.text-gray-40 {
  color: var(--gray-40) !important;
}
.border-gray-40 {
  border-color: var(--gray-40) !important;
}
.bg-gray-30 {
  --background: var(--gray-30);
  background: var(--gray-30) !important;
}
.text-gray-30 {
  color: var(--gray-30) !important;
}
.border-gray-30 {
  border-color: var(--gray-30) !important;
}
.bg-gray-20 {
  --background: var(--gray-20);
  background: var(--gray-20) !important;
}
.text-gray-20 {
  color: var(--gray-20) !important;
}
.border-gray-20 {
  border-color: var(--gray-20) !important;
}
.bg-gray-10 {
  --background: var(--gray-10);
  background: var(--gray-10) !important;
}
.text-gray-10 {
  color: var(--gray-10) !important;
}
.border-gray-10 {
  border-color: var(--gray-10) !important;
}
.bg-gray-5 {
  --background: var(--gray-5);
  background: var(--gray-5) !important;
}
.text-gray-5 {
  color: var(--gray-5) !important;
}
.border-gray-5 {
  border-color: var(--gray-5) !important;
}
.bg-gray-4 {
  --background: var(--gray-4);
  background: var(--gray-4) !important;
}
.text-gray-4 {
  color: var(--gray-4) !important;
}
.border-gray-4 {
  border-color: var(--gray-4) !important;
}
.bg-gray-3 {
  --background: var(--gray-3);
  background: var(--gray-3) !important;
}
.text-gray-3 {
  color: var(--gray-3) !important;
}
.border-gray-3 {
  border-color: var(--gray-3) !important;
}
.bg-gray-2 {
  --background: var(--gray-2);
  background: var(--gray-2) !important;
}
.text-gray-2 {
  color: var(--gray-2) !important;
}
.border-gray-2 {
  border-color: var(--gray-2) !important;
}
.bg-gray-1 {
  --background: var(--gray-1);
  background: var(--gray-1) !important;
}
.text-gray-1 {
  color: var(--gray-1) !important;
}
.border-gray-1 {
  border-color: var(--gray-1) !important;
}
.bg-gray-cool-90 {
  --background: var(--gray-cool-90);
  background: var(--gray-cool-90) !important;
}
.text-gray-cool-90 {
  color: var(--gray-cool-90) !important;
}
.border-gray-cool-90 {
  border-color: var(--gray-cool-90) !important;
}
.bg-gray-cool-80 {
  --background: var(--gray-cool-80);
  background: var(--gray-cool-80) !important;
}
.text-gray-cool-80 {
  color: var(--gray-cool-80) !important;
}
.border-gray-cool-80 {
  border-color: var(--gray-cool-80) !important;
}
.bg-gray-cool-70 {
  --background: var(--gray-cool-70);
  background: var(--gray-cool-70) !important;
}
.text-gray-cool-70 {
  color: var(--gray-cool-70) !important;
}
.border-gray-cool-70 {
  border-color: var(--gray-cool-70) !important;
}
.bg-gray-cool-60 {
  --background: var(--gray-cool-60);
  background: var(--gray-cool-60) !important;
}
.text-gray-cool-60 {
  color: var(--gray-cool-60) !important;
}
.border-gray-cool-60 {
  border-color: var(--gray-cool-60) !important;
}
.bg-gray-cool-50 {
  --background: var(--gray-cool-50);
  background: var(--gray-cool-50) !important;
}
.text-gray-cool-50 {
  color: var(--gray-cool-50) !important;
}
.border-gray-cool-50 {
  border-color: var(--gray-cool-50) !important;
}
.bg-gray-cool-40 {
  --background: var(--gray-cool-40);
  background: var(--gray-cool-40) !important;
}
.text-gray-cool-40 {
  color: var(--gray-cool-40) !important;
}
.border-gray-cool-40 {
  border-color: var(--gray-cool-40) !important;
}
.bg-gray-cool-30 {
  --background: var(--gray-cool-30);
  background: var(--gray-cool-30) !important;
}
.text-gray-cool-30 {
  color: var(--gray-cool-30) !important;
}
.border-gray-cool-30 {
  border-color: var(--gray-cool-30) !important;
}
.bg-gray-cool-20 {
  --background: var(--gray-cool-20);
  background: var(--gray-cool-20) !important;
}
.text-gray-cool-20 {
  color: var(--gray-cool-20) !important;
}
.border-gray-cool-20 {
  border-color: var(--gray-cool-20) !important;
}
.bg-gray-cool-10 {
  --background: var(--gray-cool-10);
  background: var(--gray-cool-10) !important;
}
.text-gray-cool-10 {
  color: var(--gray-cool-10) !important;
}
.border-gray-cool-10 {
  border-color: var(--gray-cool-10) !important;
}
.bg-gray-cool-5 {
  --background: var(--gray-cool-5);
  background: var(--gray-cool-5) !important;
}
.text-gray-cool-5 {
  color: var(--gray-cool-5) !important;
}
.border-gray-cool-5 {
  border-color: var(--gray-cool-5) !important;
}
.bg-gray-cool-4 {
  --background: var(--gray-cool-4);
  background: var(--gray-cool-4) !important;
}
.text-gray-cool-4 {
  color: var(--gray-cool-4) !important;
}
.border-gray-cool-4 {
  border-color: var(--gray-cool-4) !important;
}
.bg-gray-cool-3 {
  --background: var(--gray-cool-3);
  background: var(--gray-cool-3) !important;
}
.text-gray-cool-3 {
  color: var(--gray-cool-3) !important;
}
.border-gray-cool-3 {
  border-color: var(--gray-cool-3) !important;
}
.bg-gray-cool-2 {
  --background: var(--gray-cool-2);
  background: var(--gray-cool-2) !important;
}
.text-gray-cool-2 {
  color: var(--gray-cool-2) !important;
}
.border-gray-cool-2 {
  border-color: var(--gray-cool-2) !important;
}
.bg-gray-cool-1 {
  --background: var(--gray-cool-1);
  background: var(--gray-cool-1) !important;
}
.text-gray-cool-1 {
  color: var(--gray-cool-1) !important;
}
.border-gray-cool-1 {
  border-color: var(--gray-cool-1) !important;
}
.bg-gray-warm-90 {
  --background: var(--gray-warm-90);
  background: var(--gray-warm-90) !important;
}
.text-gray-warm-90 {
  color: var(--gray-warm-90) !important;
}
.border-gray-warm-90 {
  border-color: var(--gray-warm-90) !important;
}
.bg-gray-warm-80 {
  --background: var(--gray-warm-80);
  background: var(--gray-warm-80) !important;
}
.text-gray-warm-80 {
  color: var(--gray-warm-80) !important;
}
.border-gray-warm-80 {
  border-color: var(--gray-warm-80) !important;
}
.bg-gray-warm-70 {
  --background: var(--gray-warm-70);
  background: var(--gray-warm-70) !important;
}
.text-gray-warm-70 {
  color: var(--gray-warm-70) !important;
}
.border-gray-warm-70 {
  border-color: var(--gray-warm-70) !important;
}
.bg-gray-warm-60 {
  --background: var(--gray-warm-60);
  background: var(--gray-warm-60) !important;
}
.text-gray-warm-60 {
  color: var(--gray-warm-60) !important;
}
.border-gray-warm-60 {
  border-color: var(--gray-warm-60) !important;
}
.bg-gray-warm-50 {
  --background: var(--gray-warm-50);
  background: var(--gray-warm-50) !important;
}
.text-gray-warm-50 {
  color: var(--gray-warm-50) !important;
}
.border-gray-warm-50 {
  border-color: var(--gray-warm-50) !important;
}
.bg-gray-warm-40 {
  --background: var(--gray-warm-40);
  background: var(--gray-warm-40) !important;
}
.text-gray-warm-40 {
  color: var(--gray-warm-40) !important;
}
.border-gray-warm-40 {
  border-color: var(--gray-warm-40) !important;
}
.bg-gray-warm-30 {
  --background: var(--gray-warm-30);
  background: var(--gray-warm-30) !important;
}
.text-gray-warm-30 {
  color: var(--gray-warm-30) !important;
}
.border-gray-warm-30 {
  border-color: var(--gray-warm-30) !important;
}
.bg-gray-warm-20 {
  --background: var(--gray-warm-20);
  background: var(--gray-warm-20) !important;
}
.text-gray-warm-20 {
  color: var(--gray-warm-20) !important;
}
.border-gray-warm-20 {
  border-color: var(--gray-warm-20) !important;
}
.bg-gray-warm-10 {
  --background: var(--gray-warm-10);
  background: var(--gray-warm-10) !important;
}
.text-gray-warm-10 {
  color: var(--gray-warm-10) !important;
}
.border-gray-warm-10 {
  border-color: var(--gray-warm-10) !important;
}
.bg-gray-warm-5 {
  --background: var(--gray-warm-5);
  background: var(--gray-warm-5) !important;
}
.text-gray-warm-5 {
  color: var(--gray-warm-5) !important;
}
.border-gray-warm-5 {
  border-color: var(--gray-warm-5) !important;
}
.bg-gray-warm-4 {
  --background: var(--gray-warm-4);
  background: var(--gray-warm-4) !important;
}
.text-gray-warm-4 {
  color: var(--gray-warm-4) !important;
}
.border-gray-warm-4 {
  border-color: var(--gray-warm-4) !important;
}
.bg-gray-warm-3 {
  --background: var(--gray-warm-3);
  background: var(--gray-warm-3) !important;
}
.text-gray-warm-3 {
  color: var(--gray-warm-3) !important;
}
.border-gray-warm-3 {
  border-color: var(--gray-warm-3) !important;
}
.bg-gray-warm-2 {
  --background: var(--gray-warm-2);
  background: var(--gray-warm-2) !important;
}
.text-gray-warm-2 {
  color: var(--gray-warm-2) !important;
}
.border-gray-warm-2 {
  border-color: var(--gray-warm-2) !important;
}
.bg-gray-warm-1 {
  --background: var(--gray-warm-1);
  background: var(--gray-warm-1) !important;
}
.text-gray-warm-1 {
  color: var(--gray-warm-1) !important;
}
.border-gray-warm-1 {
  border-color: var(--gray-warm-1) !important;
}
.bg-green-90 {
  --background: var(--green-90);
  background: var(--green-90) !important;
}
.text-green-90 {
  color: var(--green-90) !important;
}
.border-green-90 {
  border-color: var(--green-90) !important;
}
.bg-green-80 {
  --background: var(--green-80);
  background: var(--green-80) !important;
}
.text-green-80 {
  color: var(--green-80) !important;
}
.border-green-80 {
  border-color: var(--green-80) !important;
}
.bg-green-70 {
  --background: var(--green-70);
  background: var(--green-70) !important;
}
.text-green-70 {
  color: var(--green-70) !important;
}
.border-green-70 {
  border-color: var(--green-70) !important;
}
.bg-green-60 {
  --background: var(--green-60);
  background: var(--green-60) !important;
}
.text-green-60 {
  color: var(--green-60) !important;
}
.border-green-60 {
  border-color: var(--green-60) !important;
}
.bg-green-50 {
  --background: var(--green-50);
  background: var(--green-50) !important;
}
.text-green-50 {
  color: var(--green-50) !important;
}
.border-green-50 {
  border-color: var(--green-50) !important;
}
.bg-green-40 {
  --background: var(--green-40);
  background: var(--green-40) !important;
}
.text-green-40 {
  color: var(--green-40) !important;
}
.border-green-40 {
  border-color: var(--green-40) !important;
}
.bg-green-30 {
  --background: var(--green-30);
  background: var(--green-30) !important;
}
.text-green-30 {
  color: var(--green-30) !important;
}
.border-green-30 {
  border-color: var(--green-30) !important;
}
.bg-green-20 {
  --background: var(--green-20);
  background: var(--green-20) !important;
}
.text-green-20 {
  color: var(--green-20) !important;
}
.border-green-20 {
  border-color: var(--green-20) !important;
}
.bg-green-10 {
  --background: var(--green-10);
  background: var(--green-10) !important;
}
.text-green-10 {
  color: var(--green-10) !important;
}
.border-green-10 {
  border-color: var(--green-10) !important;
}
.bg-green-5 {
  --background: var(--green-5);
  background: var(--green-5) !important;
}
.text-green-5 {
  color: var(--green-5) !important;
}
.border-green-5 {
  border-color: var(--green-5) !important;
}
.bg-green-cool-90 {
  --background: var(--green-cool-90);
  background: var(--green-cool-90) !important;
}
.text-green-cool-90 {
  color: var(--green-cool-90) !important;
}
.border-green-cool-90 {
  border-color: var(--green-cool-90) !important;
}
.bg-green-cool-80 {
  --background: var(--green-cool-80);
  background: var(--green-cool-80) !important;
}
.text-green-cool-80 {
  color: var(--green-cool-80) !important;
}
.border-green-cool-80 {
  border-color: var(--green-cool-80) !important;
}
.bg-green-cool-70 {
  --background: var(--green-cool-70);
  background: var(--green-cool-70) !important;
}
.text-green-cool-70 {
  color: var(--green-cool-70) !important;
}
.border-green-cool-70 {
  border-color: var(--green-cool-70) !important;
}
.bg-green-cool-60 {
  --background: var(--green-cool-60);
  background: var(--green-cool-60) !important;
}
.text-green-cool-60 {
  color: var(--green-cool-60) !important;
}
.border-green-cool-60 {
  border-color: var(--green-cool-60) !important;
}
.bg-green-cool-50 {
  --background: var(--green-cool-50);
  background: var(--green-cool-50) !important;
}
.text-green-cool-50 {
  color: var(--green-cool-50) !important;
}
.border-green-cool-50 {
  border-color: var(--green-cool-50) !important;
}
.bg-green-cool-40 {
  --background: var(--green-cool-40);
  background: var(--green-cool-40) !important;
}
.text-green-cool-40 {
  color: var(--green-cool-40) !important;
}
.border-green-cool-40 {
  border-color: var(--green-cool-40) !important;
}
.bg-green-cool-30 {
  --background: var(--green-cool-30);
  background: var(--green-cool-30) !important;
}
.text-green-cool-30 {
  color: var(--green-cool-30) !important;
}
.border-green-cool-30 {
  border-color: var(--green-cool-30) !important;
}
.bg-green-cool-20 {
  --background: var(--green-cool-20);
  background: var(--green-cool-20) !important;
}
.text-green-cool-20 {
  color: var(--green-cool-20) !important;
}
.border-green-cool-20 {
  border-color: var(--green-cool-20) !important;
}
.bg-green-cool-10 {
  --background: var(--green-cool-10);
  background: var(--green-cool-10) !important;
}
.text-green-cool-10 {
  color: var(--green-cool-10) !important;
}
.border-green-cool-10 {
  border-color: var(--green-cool-10) !important;
}
.bg-green-cool-5 {
  --background: var(--green-cool-5);
  background: var(--green-cool-5) !important;
}
.text-green-cool-5 {
  color: var(--green-cool-5) !important;
}
.border-green-cool-5 {
  border-color: var(--green-cool-5) !important;
}
.bg-green-warm-90 {
  --background: var(--green-warm-90);
  background: var(--green-warm-90) !important;
}
.text-green-warm-90 {
  color: var(--green-warm-90) !important;
}
.border-green-warm-90 {
  border-color: var(--green-warm-90) !important;
}
.bg-green-warm-80 {
  --background: var(--green-warm-80);
  background: var(--green-warm-80) !important;
}
.text-green-warm-80 {
  color: var(--green-warm-80) !important;
}
.border-green-warm-80 {
  border-color: var(--green-warm-80) !important;
}
.bg-green-warm-70 {
  --background: var(--green-warm-70);
  background: var(--green-warm-70) !important;
}
.text-green-warm-70 {
  color: var(--green-warm-70) !important;
}
.border-green-warm-70 {
  border-color: var(--green-warm-70) !important;
}
.bg-green-warm-60 {
  --background: var(--green-warm-60);
  background: var(--green-warm-60) !important;
}
.text-green-warm-60 {
  color: var(--green-warm-60) !important;
}
.border-green-warm-60 {
  border-color: var(--green-warm-60) !important;
}
.bg-green-warm-50 {
  --background: var(--green-warm-50);
  background: var(--green-warm-50) !important;
}
.text-green-warm-50 {
  color: var(--green-warm-50) !important;
}
.border-green-warm-50 {
  border-color: var(--green-warm-50) !important;
}
.bg-green-warm-40 {
  --background: var(--green-warm-40);
  background: var(--green-warm-40) !important;
}
.text-green-warm-40 {
  color: var(--green-warm-40) !important;
}
.border-green-warm-40 {
  border-color: var(--green-warm-40) !important;
}
.bg-green-warm-30 {
  --background: var(--green-warm-30);
  background: var(--green-warm-30) !important;
}
.text-green-warm-30 {
  color: var(--green-warm-30) !important;
}
.border-green-warm-30 {
  border-color: var(--green-warm-30) !important;
}
.bg-green-warm-20 {
  --background: var(--green-warm-20);
  background: var(--green-warm-20) !important;
}
.text-green-warm-20 {
  color: var(--green-warm-20) !important;
}
.border-green-warm-20 {
  border-color: var(--green-warm-20) !important;
}
.bg-green-warm-10 {
  --background: var(--green-warm-10);
  background: var(--green-warm-10) !important;
}
.text-green-warm-10 {
  color: var(--green-warm-10) !important;
}
.border-green-warm-10 {
  border-color: var(--green-warm-10) !important;
}
.bg-green-warm-5 {
  --background: var(--green-warm-5);
  background: var(--green-warm-5) !important;
}
.text-green-warm-5 {
  color: var(--green-warm-5) !important;
}
.border-green-warm-5 {
  border-color: var(--green-warm-5) !important;
}
.bg-green-vivid-80 {
  --background: var(--green-vivid-80);
  background: var(--green-vivid-80) !important;
}
.text-green-vivid-80 {
  color: var(--green-vivid-80) !important;
}
.border-green-vivid-80 {
  border-color: var(--green-vivid-80) !important;
}
.bg-green-vivid-70 {
  --background: var(--green-vivid-70);
  background: var(--green-vivid-70) !important;
}
.text-green-vivid-70 {
  color: var(--green-vivid-70) !important;
}
.border-green-vivid-70 {
  border-color: var(--green-vivid-70) !important;
}
.bg-green-vivid-60 {
  --background: var(--green-vivid-60);
  background: var(--green-vivid-60) !important;
}
.text-green-vivid-60 {
  color: var(--green-vivid-60) !important;
}
.border-green-vivid-60 {
  border-color: var(--green-vivid-60) !important;
}
.bg-green-vivid-50 {
  --background: var(--green-vivid-50);
  background: var(--green-vivid-50) !important;
}
.text-green-vivid-50 {
  color: var(--green-vivid-50) !important;
}
.border-green-vivid-50 {
  border-color: var(--green-vivid-50) !important;
}
.bg-green-vivid-40 {
  --background: var(--green-vivid-40);
  background: var(--green-vivid-40) !important;
}
.text-green-vivid-40 {
  color: var(--green-vivid-40) !important;
}
.border-green-vivid-40 {
  border-color: var(--green-vivid-40) !important;
}
.bg-green-vivid-30 {
  --background: var(--green-vivid-30);
  background: var(--green-vivid-30) !important;
}
.text-green-vivid-30 {
  color: var(--green-vivid-30) !important;
}
.border-green-vivid-30 {
  border-color: var(--green-vivid-30) !important;
}
.bg-green-vivid-20 {
  --background: var(--green-vivid-20);
  background: var(--green-vivid-20) !important;
}
.text-green-vivid-20 {
  color: var(--green-vivid-20) !important;
}
.border-green-vivid-20 {
  border-color: var(--green-vivid-20) !important;
}
.bg-green-vivid-10 {
  --background: var(--green-vivid-10);
  background: var(--green-vivid-10) !important;
}
.text-green-vivid-10 {
  color: var(--green-vivid-10) !important;
}
.border-green-vivid-10 {
  border-color: var(--green-vivid-10) !important;
}
.bg-green-vivid-5 {
  --background: var(--green-vivid-5);
  background: var(--green-vivid-5) !important;
}
.text-green-vivid-5 {
  color: var(--green-vivid-5) !important;
}
.border-green-vivid-5 {
  border-color: var(--green-vivid-5) !important;
}
.bg-green-cool-vivid-80 {
  --background: var(--green-cool-vivid-80);
  background: var(--green-cool-vivid-80) !important;
}
.text-green-cool-vivid-80 {
  color: var(--green-cool-vivid-80) !important;
}
.border-green-cool-vivid-80 {
  border-color: var(--green-cool-vivid-80) !important;
}
.bg-green-cool-vivid-70 {
  --background: var(--green-cool-vivid-70);
  background: var(--green-cool-vivid-70) !important;
}
.text-green-cool-vivid-70 {
  color: var(--green-cool-vivid-70) !important;
}
.border-green-cool-vivid-70 {
  border-color: var(--green-cool-vivid-70) !important;
}
.bg-green-cool-vivid-60 {
  --background: var(--green-cool-vivid-60);
  background: var(--green-cool-vivid-60) !important;
}
.text-green-cool-vivid-60 {
  color: var(--green-cool-vivid-60) !important;
}
.border-green-cool-vivid-60 {
  border-color: var(--green-cool-vivid-60) !important;
}
.bg-green-cool-vivid-50 {
  --background: var(--green-cool-vivid-50);
  background: var(--green-cool-vivid-50) !important;
}
.text-green-cool-vivid-50 {
  color: var(--green-cool-vivid-50) !important;
}
.border-green-cool-vivid-50 {
  border-color: var(--green-cool-vivid-50) !important;
}
.bg-green-cool-vivid-40 {
  --background: var(--green-cool-vivid-40);
  background: var(--green-cool-vivid-40) !important;
}
.text-green-cool-vivid-40 {
  color: var(--green-cool-vivid-40) !important;
}
.border-green-cool-vivid-40 {
  border-color: var(--green-cool-vivid-40) !important;
}
.bg-green-cool-vivid-30 {
  --background: var(--green-cool-vivid-30);
  background: var(--green-cool-vivid-30) !important;
}
.text-green-cool-vivid-30 {
  color: var(--green-cool-vivid-30) !important;
}
.border-green-cool-vivid-30 {
  border-color: var(--green-cool-vivid-30) !important;
}
.bg-green-cool-vivid-20 {
  --background: var(--green-cool-vivid-20);
  background: var(--green-cool-vivid-20) !important;
}
.text-green-cool-vivid-20 {
  color: var(--green-cool-vivid-20) !important;
}
.border-green-cool-vivid-20 {
  border-color: var(--green-cool-vivid-20) !important;
}
.bg-green-cool-vivid-10 {
  --background: var(--green-cool-vivid-10);
  background: var(--green-cool-vivid-10) !important;
}
.text-green-cool-vivid-10 {
  color: var(--green-cool-vivid-10) !important;
}
.border-green-cool-vivid-10 {
  border-color: var(--green-cool-vivid-10) !important;
}
.bg-green-cool-vivid-5 {
  --background: var(--green-cool-vivid-5);
  background: var(--green-cool-vivid-5) !important;
}
.text-green-cool-vivid-5 {
  color: var(--green-cool-vivid-5) !important;
}
.border-green-cool-vivid-5 {
  border-color: var(--green-cool-vivid-5) !important;
}
.bg-green-warm-vivid-80 {
  --background: var(--green-warm-vivid-80);
  background: var(--green-warm-vivid-80) !important;
}
.text-green-warm-vivid-80 {
  color: var(--green-warm-vivid-80) !important;
}
.border-green-warm-vivid-80 {
  border-color: var(--green-warm-vivid-80) !important;
}
.bg-green-warm-vivid-70 {
  --background: var(--green-warm-vivid-70);
  background: var(--green-warm-vivid-70) !important;
}
.text-green-warm-vivid-70 {
  color: var(--green-warm-vivid-70) !important;
}
.border-green-warm-vivid-70 {
  border-color: var(--green-warm-vivid-70) !important;
}
.bg-green-warm-vivid-60 {
  --background: var(--green-warm-vivid-60);
  background: var(--green-warm-vivid-60) !important;
}
.text-green-warm-vivid-60 {
  color: var(--green-warm-vivid-60) !important;
}
.border-green-warm-vivid-60 {
  border-color: var(--green-warm-vivid-60) !important;
}
.bg-green-warm-vivid-50 {
  --background: var(--green-warm-vivid-50);
  background: var(--green-warm-vivid-50) !important;
}
.text-green-warm-vivid-50 {
  color: var(--green-warm-vivid-50) !important;
}
.border-green-warm-vivid-50 {
  border-color: var(--green-warm-vivid-50) !important;
}
.bg-green-warm-vivid-40 {
  --background: var(--green-warm-vivid-40);
  background: var(--green-warm-vivid-40) !important;
}
.text-green-warm-vivid-40 {
  color: var(--green-warm-vivid-40) !important;
}
.border-green-warm-vivid-40 {
  border-color: var(--green-warm-vivid-40) !important;
}
.bg-green-warm-vivid-30 {
  --background: var(--green-warm-vivid-30);
  background: var(--green-warm-vivid-30) !important;
}
.text-green-warm-vivid-30 {
  color: var(--green-warm-vivid-30) !important;
}
.border-green-warm-vivid-30 {
  border-color: var(--green-warm-vivid-30) !important;
}
.bg-green-warm-vivid-20 {
  --background: var(--green-warm-vivid-20);
  background: var(--green-warm-vivid-20) !important;
}
.text-green-warm-vivid-20 {
  color: var(--green-warm-vivid-20) !important;
}
.border-green-warm-vivid-20 {
  border-color: var(--green-warm-vivid-20) !important;
}
.bg-green-warm-vivid-10 {
  --background: var(--green-warm-vivid-10);
  background: var(--green-warm-vivid-10) !important;
}
.text-green-warm-vivid-10 {
  color: var(--green-warm-vivid-10) !important;
}
.border-green-warm-vivid-10 {
  border-color: var(--green-warm-vivid-10) !important;
}
.bg-green-warm-vivid-5 {
  --background: var(--green-warm-vivid-5);
  background: var(--green-warm-vivid-5) !important;
}
.text-green-warm-vivid-5 {
  color: var(--green-warm-vivid-5) !important;
}
.border-green-warm-vivid-5 {
  border-color: var(--green-warm-vivid-5) !important;
}
.bg-indigo-90 {
  --background: var(--indigo-90);
  background: var(--indigo-90) !important;
}
.text-indigo-90 {
  color: var(--indigo-90) !important;
}
.border-indigo-90 {
  border-color: var(--indigo-90) !important;
}
.bg-indigo-80 {
  --background: var(--indigo-80);
  background: var(--indigo-80) !important;
}
.text-indigo-80 {
  color: var(--indigo-80) !important;
}
.border-indigo-80 {
  border-color: var(--indigo-80) !important;
}
.bg-indigo-70 {
  --background: var(--indigo-70);
  background: var(--indigo-70) !important;
}
.text-indigo-70 {
  color: var(--indigo-70) !important;
}
.border-indigo-70 {
  border-color: var(--indigo-70) !important;
}
.bg-indigo-60 {
  --background: var(--indigo-60);
  background: var(--indigo-60) !important;
}
.text-indigo-60 {
  color: var(--indigo-60) !important;
}
.border-indigo-60 {
  border-color: var(--indigo-60) !important;
}
.bg-indigo-50 {
  --background: var(--indigo-50);
  background: var(--indigo-50) !important;
}
.text-indigo-50 {
  color: var(--indigo-50) !important;
}
.border-indigo-50 {
  border-color: var(--indigo-50) !important;
}
.bg-indigo-40 {
  --background: var(--indigo-40);
  background: var(--indigo-40) !important;
}
.text-indigo-40 {
  color: var(--indigo-40) !important;
}
.border-indigo-40 {
  border-color: var(--indigo-40) !important;
}
.bg-indigo-30 {
  --background: var(--indigo-30);
  background: var(--indigo-30) !important;
}
.text-indigo-30 {
  color: var(--indigo-30) !important;
}
.border-indigo-30 {
  border-color: var(--indigo-30) !important;
}
.bg-indigo-20 {
  --background: var(--indigo-20);
  background: var(--indigo-20) !important;
}
.text-indigo-20 {
  color: var(--indigo-20) !important;
}
.border-indigo-20 {
  border-color: var(--indigo-20) !important;
}
.bg-indigo-10 {
  --background: var(--indigo-10);
  background: var(--indigo-10) !important;
}
.text-indigo-10 {
  color: var(--indigo-10) !important;
}
.border-indigo-10 {
  border-color: var(--indigo-10) !important;
}
.bg-indigo-5 {
  --background: var(--indigo-5);
  background: var(--indigo-5) !important;
}
.text-indigo-5 {
  color: var(--indigo-5) !important;
}
.border-indigo-5 {
  border-color: var(--indigo-5) !important;
}
.bg-indigo-cool-90 {
  --background: var(--indigo-cool-90);
  background: var(--indigo-cool-90) !important;
}
.text-indigo-cool-90 {
  color: var(--indigo-cool-90) !important;
}
.border-indigo-cool-90 {
  border-color: var(--indigo-cool-90) !important;
}
.bg-indigo-cool-80 {
  --background: var(--indigo-cool-80);
  background: var(--indigo-cool-80) !important;
}
.text-indigo-cool-80 {
  color: var(--indigo-cool-80) !important;
}
.border-indigo-cool-80 {
  border-color: var(--indigo-cool-80) !important;
}
.bg-indigo-cool-70 {
  --background: var(--indigo-cool-70);
  background: var(--indigo-cool-70) !important;
}
.text-indigo-cool-70 {
  color: var(--indigo-cool-70) !important;
}
.border-indigo-cool-70 {
  border-color: var(--indigo-cool-70) !important;
}
.bg-indigo-cool-60 {
  --background: var(--indigo-cool-60);
  background: var(--indigo-cool-60) !important;
}
.text-indigo-cool-60 {
  color: var(--indigo-cool-60) !important;
}
.border-indigo-cool-60 {
  border-color: var(--indigo-cool-60) !important;
}
.bg-indigo-cool-50 {
  --background: var(--indigo-cool-50);
  background: var(--indigo-cool-50) !important;
}
.text-indigo-cool-50 {
  color: var(--indigo-cool-50) !important;
}
.border-indigo-cool-50 {
  border-color: var(--indigo-cool-50) !important;
}
.bg-indigo-cool-40 {
  --background: var(--indigo-cool-40);
  background: var(--indigo-cool-40) !important;
}
.text-indigo-cool-40 {
  color: var(--indigo-cool-40) !important;
}
.border-indigo-cool-40 {
  border-color: var(--indigo-cool-40) !important;
}
.bg-indigo-cool-30 {
  --background: var(--indigo-cool-30);
  background: var(--indigo-cool-30) !important;
}
.text-indigo-cool-30 {
  color: var(--indigo-cool-30) !important;
}
.border-indigo-cool-30 {
  border-color: var(--indigo-cool-30) !important;
}
.bg-indigo-cool-20 {
  --background: var(--indigo-cool-20);
  background: var(--indigo-cool-20) !important;
}
.text-indigo-cool-20 {
  color: var(--indigo-cool-20) !important;
}
.border-indigo-cool-20 {
  border-color: var(--indigo-cool-20) !important;
}
.bg-indigo-cool-10 {
  --background: var(--indigo-cool-10);
  background: var(--indigo-cool-10) !important;
}
.text-indigo-cool-10 {
  color: var(--indigo-cool-10) !important;
}
.border-indigo-cool-10 {
  border-color: var(--indigo-cool-10) !important;
}
.bg-indigo-cool-5 {
  --background: var(--indigo-cool-5);
  background: var(--indigo-cool-5) !important;
}
.text-indigo-cool-5 {
  color: var(--indigo-cool-5) !important;
}
.border-indigo-cool-5 {
  border-color: var(--indigo-cool-5) !important;
}
.bg-indigo-warm-90 {
  --background: var(--indigo-warm-90);
  background: var(--indigo-warm-90) !important;
}
.text-indigo-warm-90 {
  color: var(--indigo-warm-90) !important;
}
.border-indigo-warm-90 {
  border-color: var(--indigo-warm-90) !important;
}
.bg-indigo-warm-80 {
  --background: var(--indigo-warm-80);
  background: var(--indigo-warm-80) !important;
}
.text-indigo-warm-80 {
  color: var(--indigo-warm-80) !important;
}
.border-indigo-warm-80 {
  border-color: var(--indigo-warm-80) !important;
}
.bg-indigo-warm-70 {
  --background: var(--indigo-warm-70);
  background: var(--indigo-warm-70) !important;
}
.text-indigo-warm-70 {
  color: var(--indigo-warm-70) !important;
}
.border-indigo-warm-70 {
  border-color: var(--indigo-warm-70) !important;
}
.bg-indigo-warm-60 {
  --background: var(--indigo-warm-60);
  background: var(--indigo-warm-60) !important;
}
.text-indigo-warm-60 {
  color: var(--indigo-warm-60) !important;
}
.border-indigo-warm-60 {
  border-color: var(--indigo-warm-60) !important;
}
.bg-indigo-warm-50 {
  --background: var(--indigo-warm-50);
  background: var(--indigo-warm-50) !important;
}
.text-indigo-warm-50 {
  color: var(--indigo-warm-50) !important;
}
.border-indigo-warm-50 {
  border-color: var(--indigo-warm-50) !important;
}
.bg-indigo-warm-40 {
  --background: var(--indigo-warm-40);
  background: var(--indigo-warm-40) !important;
}
.text-indigo-warm-40 {
  color: var(--indigo-warm-40) !important;
}
.border-indigo-warm-40 {
  border-color: var(--indigo-warm-40) !important;
}
.bg-indigo-warm-30 {
  --background: var(--indigo-warm-30);
  background: var(--indigo-warm-30) !important;
}
.text-indigo-warm-30 {
  color: var(--indigo-warm-30) !important;
}
.border-indigo-warm-30 {
  border-color: var(--indigo-warm-30) !important;
}
.bg-indigo-warm-20 {
  --background: var(--indigo-warm-20);
  background: var(--indigo-warm-20) !important;
}
.text-indigo-warm-20 {
  color: var(--indigo-warm-20) !important;
}
.border-indigo-warm-20 {
  border-color: var(--indigo-warm-20) !important;
}
.bg-indigo-warm-10 {
  --background: var(--indigo-warm-10);
  background: var(--indigo-warm-10) !important;
}
.text-indigo-warm-10 {
  color: var(--indigo-warm-10) !important;
}
.border-indigo-warm-10 {
  border-color: var(--indigo-warm-10) !important;
}
.bg-indigo-warm-5 {
  --background: var(--indigo-warm-5);
  background: var(--indigo-warm-5) !important;
}
.text-indigo-warm-5 {
  color: var(--indigo-warm-5) !important;
}
.border-indigo-warm-5 {
  border-color: var(--indigo-warm-5) !important;
}
.bg-indigo-vivid-80 {
  --background: var(--indigo-vivid-80);
  background: var(--indigo-vivid-80) !important;
}
.text-indigo-vivid-80 {
  color: var(--indigo-vivid-80) !important;
}
.border-indigo-vivid-80 {
  border-color: var(--indigo-vivid-80) !important;
}
.bg-indigo-vivid-70 {
  --background: var(--indigo-vivid-70);
  background: var(--indigo-vivid-70) !important;
}
.text-indigo-vivid-70 {
  color: var(--indigo-vivid-70) !important;
}
.border-indigo-vivid-70 {
  border-color: var(--indigo-vivid-70) !important;
}
.bg-indigo-vivid-60 {
  --background: var(--indigo-vivid-60);
  background: var(--indigo-vivid-60) !important;
}
.text-indigo-vivid-60 {
  color: var(--indigo-vivid-60) !important;
}
.border-indigo-vivid-60 {
  border-color: var(--indigo-vivid-60) !important;
}
.bg-indigo-vivid-50 {
  --background: var(--indigo-vivid-50);
  background: var(--indigo-vivid-50) !important;
}
.text-indigo-vivid-50 {
  color: var(--indigo-vivid-50) !important;
}
.border-indigo-vivid-50 {
  border-color: var(--indigo-vivid-50) !important;
}
.bg-indigo-vivid-40 {
  --background: var(--indigo-vivid-40);
  background: var(--indigo-vivid-40) !important;
}
.text-indigo-vivid-40 {
  color: var(--indigo-vivid-40) !important;
}
.border-indigo-vivid-40 {
  border-color: var(--indigo-vivid-40) !important;
}
.bg-indigo-vivid-30 {
  --background: var(--indigo-vivid-30);
  background: var(--indigo-vivid-30) !important;
}
.text-indigo-vivid-30 {
  color: var(--indigo-vivid-30) !important;
}
.border-indigo-vivid-30 {
  border-color: var(--indigo-vivid-30) !important;
}
.bg-indigo-vivid-20 {
  --background: var(--indigo-vivid-20);
  background: var(--indigo-vivid-20) !important;
}
.text-indigo-vivid-20 {
  color: var(--indigo-vivid-20) !important;
}
.border-indigo-vivid-20 {
  border-color: var(--indigo-vivid-20) !important;
}
.bg-indigo-vivid-10 {
  --background: var(--indigo-vivid-10);
  background: var(--indigo-vivid-10) !important;
}
.text-indigo-vivid-10 {
  color: var(--indigo-vivid-10) !important;
}
.border-indigo-vivid-10 {
  border-color: var(--indigo-vivid-10) !important;
}
.bg-indigo-vivid-5 {
  --background: var(--indigo-vivid-5);
  background: var(--indigo-vivid-5) !important;
}
.text-indigo-vivid-5 {
  color: var(--indigo-vivid-5) !important;
}
.border-indigo-vivid-5 {
  border-color: var(--indigo-vivid-5) !important;
}
.bg-indigo-cool-vivid-80 {
  --background: var(--indigo-cool-vivid-80);
  background: var(--indigo-cool-vivid-80) !important;
}
.text-indigo-cool-vivid-80 {
  color: var(--indigo-cool-vivid-80) !important;
}
.border-indigo-cool-vivid-80 {
  border-color: var(--indigo-cool-vivid-80) !important;
}
.bg-indigo-cool-vivid-70 {
  --background: var(--indigo-cool-vivid-70);
  background: var(--indigo-cool-vivid-70) !important;
}
.text-indigo-cool-vivid-70 {
  color: var(--indigo-cool-vivid-70) !important;
}
.border-indigo-cool-vivid-70 {
  border-color: var(--indigo-cool-vivid-70) !important;
}
.bg-indigo-cool-vivid-60 {
  --background: var(--indigo-cool-vivid-60);
  background: var(--indigo-cool-vivid-60) !important;
}
.text-indigo-cool-vivid-60 {
  color: var(--indigo-cool-vivid-60) !important;
}
.border-indigo-cool-vivid-60 {
  border-color: var(--indigo-cool-vivid-60) !important;
}
.bg-indigo-cool-vivid-50 {
  --background: var(--indigo-cool-vivid-50);
  background: var(--indigo-cool-vivid-50) !important;
}
.text-indigo-cool-vivid-50 {
  color: var(--indigo-cool-vivid-50) !important;
}
.border-indigo-cool-vivid-50 {
  border-color: var(--indigo-cool-vivid-50) !important;
}
.bg-indigo-cool-vivid-40 {
  --background: var(--indigo-cool-vivid-40);
  background: var(--indigo-cool-vivid-40) !important;
}
.text-indigo-cool-vivid-40 {
  color: var(--indigo-cool-vivid-40) !important;
}
.border-indigo-cool-vivid-40 {
  border-color: var(--indigo-cool-vivid-40) !important;
}
.bg-indigo-cool-vivid-30 {
  --background: var(--indigo-cool-vivid-30);
  background: var(--indigo-cool-vivid-30) !important;
}
.text-indigo-cool-vivid-30 {
  color: var(--indigo-cool-vivid-30) !important;
}
.border-indigo-cool-vivid-30 {
  border-color: var(--indigo-cool-vivid-30) !important;
}
.bg-indigo-cool-vivid-20 {
  --background: var(--indigo-cool-vivid-20);
  background: var(--indigo-cool-vivid-20) !important;
}
.text-indigo-cool-vivid-20 {
  color: var(--indigo-cool-vivid-20) !important;
}
.border-indigo-cool-vivid-20 {
  border-color: var(--indigo-cool-vivid-20) !important;
}
.bg-indigo-cool-vivid-10 {
  --background: var(--indigo-cool-vivid-10);
  background: var(--indigo-cool-vivid-10) !important;
}
.text-indigo-cool-vivid-10 {
  color: var(--indigo-cool-vivid-10) !important;
}
.border-indigo-cool-vivid-10 {
  border-color: var(--indigo-cool-vivid-10) !important;
}
.bg-indigo-cool-vivid-5 {
  --background: var(--indigo-cool-vivid-5);
  background: var(--indigo-cool-vivid-5) !important;
}
.text-indigo-cool-vivid-5 {
  color: var(--indigo-cool-vivid-5) !important;
}
.border-indigo-cool-vivid-5 {
  border-color: var(--indigo-cool-vivid-5) !important;
}
.bg-indigo-warm-vivid-80 {
  --background: var(--indigo-warm-vivid-80);
  background: var(--indigo-warm-vivid-80) !important;
}
.text-indigo-warm-vivid-80 {
  color: var(--indigo-warm-vivid-80) !important;
}
.border-indigo-warm-vivid-80 {
  border-color: var(--indigo-warm-vivid-80) !important;
}
.bg-indigo-warm-vivid-70 {
  --background: var(--indigo-warm-vivid-70);
  background: var(--indigo-warm-vivid-70) !important;
}
.text-indigo-warm-vivid-70 {
  color: var(--indigo-warm-vivid-70) !important;
}
.border-indigo-warm-vivid-70 {
  border-color: var(--indigo-warm-vivid-70) !important;
}
.bg-indigo-warm-vivid-60 {
  --background: var(--indigo-warm-vivid-60);
  background: var(--indigo-warm-vivid-60) !important;
}
.text-indigo-warm-vivid-60 {
  color: var(--indigo-warm-vivid-60) !important;
}
.border-indigo-warm-vivid-60 {
  border-color: var(--indigo-warm-vivid-60) !important;
}
.bg-indigo-warm-vivid-50 {
  --background: var(--indigo-warm-vivid-50);
  background: var(--indigo-warm-vivid-50) !important;
}
.text-indigo-warm-vivid-50 {
  color: var(--indigo-warm-vivid-50) !important;
}
.border-indigo-warm-vivid-50 {
  border-color: var(--indigo-warm-vivid-50) !important;
}
.bg-indigo-warm-vivid-40 {
  --background: var(--indigo-warm-vivid-40);
  background: var(--indigo-warm-vivid-40) !important;
}
.text-indigo-warm-vivid-40 {
  color: var(--indigo-warm-vivid-40) !important;
}
.border-indigo-warm-vivid-40 {
  border-color: var(--indigo-warm-vivid-40) !important;
}
.bg-indigo-warm-vivid-30 {
  --background: var(--indigo-warm-vivid-30);
  background: var(--indigo-warm-vivid-30) !important;
}
.text-indigo-warm-vivid-30 {
  color: var(--indigo-warm-vivid-30) !important;
}
.border-indigo-warm-vivid-30 {
  border-color: var(--indigo-warm-vivid-30) !important;
}
.bg-indigo-warm-vivid-20 {
  --background: var(--indigo-warm-vivid-20);
  background: var(--indigo-warm-vivid-20) !important;
}
.text-indigo-warm-vivid-20 {
  color: var(--indigo-warm-vivid-20) !important;
}
.border-indigo-warm-vivid-20 {
  border-color: var(--indigo-warm-vivid-20) !important;
}
.bg-indigo-warm-vivid-10 {
  --background: var(--indigo-warm-vivid-10);
  background: var(--indigo-warm-vivid-10) !important;
}
.text-indigo-warm-vivid-10 {
  color: var(--indigo-warm-vivid-10) !important;
}
.border-indigo-warm-vivid-10 {
  border-color: var(--indigo-warm-vivid-10) !important;
}
.bg-indigo-warm-vivid-5 {
  --background: var(--indigo-warm-vivid-5);
  background: var(--indigo-warm-vivid-5) !important;
}
.text-indigo-warm-vivid-5 {
  color: var(--indigo-warm-vivid-5) !important;
}
.border-indigo-warm-vivid-5 {
  border-color: var(--indigo-warm-vivid-5) !important;
}
.bg-magenta-90 {
  --background: var(--magenta-90);
  background: var(--magenta-90) !important;
}
.text-magenta-90 {
  color: var(--magenta-90) !important;
}
.border-magenta-90 {
  border-color: var(--magenta-90) !important;
}
.bg-magenta-80 {
  --background: var(--magenta-80);
  background: var(--magenta-80) !important;
}
.text-magenta-80 {
  color: var(--magenta-80) !important;
}
.border-magenta-80 {
  border-color: var(--magenta-80) !important;
}
.bg-magenta-70 {
  --background: var(--magenta-70);
  background: var(--magenta-70) !important;
}
.text-magenta-70 {
  color: var(--magenta-70) !important;
}
.border-magenta-70 {
  border-color: var(--magenta-70) !important;
}
.bg-magenta-60 {
  --background: var(--magenta-60);
  background: var(--magenta-60) !important;
}
.text-magenta-60 {
  color: var(--magenta-60) !important;
}
.border-magenta-60 {
  border-color: var(--magenta-60) !important;
}
.bg-magenta-50 {
  --background: var(--magenta-50);
  background: var(--magenta-50) !important;
}
.text-magenta-50 {
  color: var(--magenta-50) !important;
}
.border-magenta-50 {
  border-color: var(--magenta-50) !important;
}
.bg-magenta-40 {
  --background: var(--magenta-40);
  background: var(--magenta-40) !important;
}
.text-magenta-40 {
  color: var(--magenta-40) !important;
}
.border-magenta-40 {
  border-color: var(--magenta-40) !important;
}
.bg-magenta-30 {
  --background: var(--magenta-30);
  background: var(--magenta-30) !important;
}
.text-magenta-30 {
  color: var(--magenta-30) !important;
}
.border-magenta-30 {
  border-color: var(--magenta-30) !important;
}
.bg-magenta-20 {
  --background: var(--magenta-20);
  background: var(--magenta-20) !important;
}
.text-magenta-20 {
  color: var(--magenta-20) !important;
}
.border-magenta-20 {
  border-color: var(--magenta-20) !important;
}
.bg-magenta-10 {
  --background: var(--magenta-10);
  background: var(--magenta-10) !important;
}
.text-magenta-10 {
  color: var(--magenta-10) !important;
}
.border-magenta-10 {
  border-color: var(--magenta-10) !important;
}
.bg-magenta-5 {
  --background: var(--magenta-5);
  background: var(--magenta-5) !important;
}
.text-magenta-5 {
  color: var(--magenta-5) !important;
}
.border-magenta-5 {
  border-color: var(--magenta-5) !important;
}
.bg-magenta-vivid-80 {
  --background: var(--magenta-vivid-80);
  background: var(--magenta-vivid-80) !important;
}
.text-magenta-vivid-80 {
  color: var(--magenta-vivid-80) !important;
}
.border-magenta-vivid-80 {
  border-color: var(--magenta-vivid-80) !important;
}
.bg-magenta-vivid-70 {
  --background: var(--magenta-vivid-70);
  background: var(--magenta-vivid-70) !important;
}
.text-magenta-vivid-70 {
  color: var(--magenta-vivid-70) !important;
}
.border-magenta-vivid-70 {
  border-color: var(--magenta-vivid-70) !important;
}
.bg-magenta-vivid-60 {
  --background: var(--magenta-vivid-60);
  background: var(--magenta-vivid-60) !important;
}
.text-magenta-vivid-60 {
  color: var(--magenta-vivid-60) !important;
}
.border-magenta-vivid-60 {
  border-color: var(--magenta-vivid-60) !important;
}
.bg-magenta-vivid-50 {
  --background: var(--magenta-vivid-50);
  background: var(--magenta-vivid-50) !important;
}
.text-magenta-vivid-50 {
  color: var(--magenta-vivid-50) !important;
}
.border-magenta-vivid-50 {
  border-color: var(--magenta-vivid-50) !important;
}
.bg-magenta-vivid-40 {
  --background: var(--magenta-vivid-40);
  background: var(--magenta-vivid-40) !important;
}
.text-magenta-vivid-40 {
  color: var(--magenta-vivid-40) !important;
}
.border-magenta-vivid-40 {
  border-color: var(--magenta-vivid-40) !important;
}
.bg-magenta-vivid-30 {
  --background: var(--magenta-vivid-30);
  background: var(--magenta-vivid-30) !important;
}
.text-magenta-vivid-30 {
  color: var(--magenta-vivid-30) !important;
}
.border-magenta-vivid-30 {
  border-color: var(--magenta-vivid-30) !important;
}
.bg-magenta-vivid-20 {
  --background: var(--magenta-vivid-20);
  background: var(--magenta-vivid-20) !important;
}
.text-magenta-vivid-20 {
  color: var(--magenta-vivid-20) !important;
}
.border-magenta-vivid-20 {
  border-color: var(--magenta-vivid-20) !important;
}
.bg-magenta-vivid-10 {
  --background: var(--magenta-vivid-10);
  background: var(--magenta-vivid-10) !important;
}
.text-magenta-vivid-10 {
  color: var(--magenta-vivid-10) !important;
}
.border-magenta-vivid-10 {
  border-color: var(--magenta-vivid-10) !important;
}
.bg-magenta-vivid-5 {
  --background: var(--magenta-vivid-5);
  background: var(--magenta-vivid-5) !important;
}
.text-magenta-vivid-5 {
  color: var(--magenta-vivid-5) !important;
}
.border-magenta-vivid-5 {
  border-color: var(--magenta-vivid-5) !important;
}
.bg-mint-90 {
  --background: var(--mint-90);
  background: var(--mint-90) !important;
}
.text-mint-90 {
  color: var(--mint-90) !important;
}
.border-mint-90 {
  border-color: var(--mint-90) !important;
}
.bg-mint-80 {
  --background: var(--mint-80);
  background: var(--mint-80) !important;
}
.text-mint-80 {
  color: var(--mint-80) !important;
}
.border-mint-80 {
  border-color: var(--mint-80) !important;
}
.bg-mint-70 {
  --background: var(--mint-70);
  background: var(--mint-70) !important;
}
.text-mint-70 {
  color: var(--mint-70) !important;
}
.border-mint-70 {
  border-color: var(--mint-70) !important;
}
.bg-mint-60 {
  --background: var(--mint-60);
  background: var(--mint-60) !important;
}
.text-mint-60 {
  color: var(--mint-60) !important;
}
.border-mint-60 {
  border-color: var(--mint-60) !important;
}
.bg-mint-50 {
  --background: var(--mint-50);
  background: var(--mint-50) !important;
}
.text-mint-50 {
  color: var(--mint-50) !important;
}
.border-mint-50 {
  border-color: var(--mint-50) !important;
}
.bg-mint-40 {
  --background: var(--mint-40);
  background: var(--mint-40) !important;
}
.text-mint-40 {
  color: var(--mint-40) !important;
}
.border-mint-40 {
  border-color: var(--mint-40) !important;
}
.bg-mint-30 {
  --background: var(--mint-30);
  background: var(--mint-30) !important;
}
.text-mint-30 {
  color: var(--mint-30) !important;
}
.border-mint-30 {
  border-color: var(--mint-30) !important;
}
.bg-mint-20 {
  --background: var(--mint-20);
  background: var(--mint-20) !important;
}
.text-mint-20 {
  color: var(--mint-20) !important;
}
.border-mint-20 {
  border-color: var(--mint-20) !important;
}
.bg-mint-10 {
  --background: var(--mint-10);
  background: var(--mint-10) !important;
}
.text-mint-10 {
  color: var(--mint-10) !important;
}
.border-mint-10 {
  border-color: var(--mint-10) !important;
}
.bg-mint-5 {
  --background: var(--mint-5);
  background: var(--mint-5) !important;
}
.text-mint-5 {
  color: var(--mint-5) !important;
}
.border-mint-5 {
  border-color: var(--mint-5) !important;
}
.bg-mint-cool-90 {
  --background: var(--mint-cool-90);
  background: var(--mint-cool-90) !important;
}
.text-mint-cool-90 {
  color: var(--mint-cool-90) !important;
}
.border-mint-cool-90 {
  border-color: var(--mint-cool-90) !important;
}
.bg-mint-cool-80 {
  --background: var(--mint-cool-80);
  background: var(--mint-cool-80) !important;
}
.text-mint-cool-80 {
  color: var(--mint-cool-80) !important;
}
.border-mint-cool-80 {
  border-color: var(--mint-cool-80) !important;
}
.bg-mint-cool-70 {
  --background: var(--mint-cool-70);
  background: var(--mint-cool-70) !important;
}
.text-mint-cool-70 {
  color: var(--mint-cool-70) !important;
}
.border-mint-cool-70 {
  border-color: var(--mint-cool-70) !important;
}
.bg-mint-cool-60 {
  --background: var(--mint-cool-60);
  background: var(--mint-cool-60) !important;
}
.text-mint-cool-60 {
  color: var(--mint-cool-60) !important;
}
.border-mint-cool-60 {
  border-color: var(--mint-cool-60) !important;
}
.bg-mint-cool-50 {
  --background: var(--mint-cool-50);
  background: var(--mint-cool-50) !important;
}
.text-mint-cool-50 {
  color: var(--mint-cool-50) !important;
}
.border-mint-cool-50 {
  border-color: var(--mint-cool-50) !important;
}
.bg-mint-cool-40 {
  --background: var(--mint-cool-40);
  background: var(--mint-cool-40) !important;
}
.text-mint-cool-40 {
  color: var(--mint-cool-40) !important;
}
.border-mint-cool-40 {
  border-color: var(--mint-cool-40) !important;
}
.bg-mint-cool-30 {
  --background: var(--mint-cool-30);
  background: var(--mint-cool-30) !important;
}
.text-mint-cool-30 {
  color: var(--mint-cool-30) !important;
}
.border-mint-cool-30 {
  border-color: var(--mint-cool-30) !important;
}
.bg-mint-cool-20 {
  --background: var(--mint-cool-20);
  background: var(--mint-cool-20) !important;
}
.text-mint-cool-20 {
  color: var(--mint-cool-20) !important;
}
.border-mint-cool-20 {
  border-color: var(--mint-cool-20) !important;
}
.bg-mint-cool-10 {
  --background: var(--mint-cool-10);
  background: var(--mint-cool-10) !important;
}
.text-mint-cool-10 {
  color: var(--mint-cool-10) !important;
}
.border-mint-cool-10 {
  border-color: var(--mint-cool-10) !important;
}
.bg-mint-cool-5 {
  --background: var(--mint-cool-5);
  background: var(--mint-cool-5) !important;
}
.text-mint-cool-5 {
  color: var(--mint-cool-5) !important;
}
.border-mint-cool-5 {
  border-color: var(--mint-cool-5) !important;
}
.bg-mint-vivid-80 {
  --background: var(--mint-vivid-80);
  background: var(--mint-vivid-80) !important;
}
.text-mint-vivid-80 {
  color: var(--mint-vivid-80) !important;
}
.border-mint-vivid-80 {
  border-color: var(--mint-vivid-80) !important;
}
.bg-mint-vivid-70 {
  --background: var(--mint-vivid-70);
  background: var(--mint-vivid-70) !important;
}
.text-mint-vivid-70 {
  color: var(--mint-vivid-70) !important;
}
.border-mint-vivid-70 {
  border-color: var(--mint-vivid-70) !important;
}
.bg-mint-vivid-60 {
  --background: var(--mint-vivid-60);
  background: var(--mint-vivid-60) !important;
}
.text-mint-vivid-60 {
  color: var(--mint-vivid-60) !important;
}
.border-mint-vivid-60 {
  border-color: var(--mint-vivid-60) !important;
}
.bg-mint-vivid-50 {
  --background: var(--mint-vivid-50);
  background: var(--mint-vivid-50) !important;
}
.text-mint-vivid-50 {
  color: var(--mint-vivid-50) !important;
}
.border-mint-vivid-50 {
  border-color: var(--mint-vivid-50) !important;
}
.bg-mint-vivid-40 {
  --background: var(--mint-vivid-40);
  background: var(--mint-vivid-40) !important;
}
.text-mint-vivid-40 {
  color: var(--mint-vivid-40) !important;
}
.border-mint-vivid-40 {
  border-color: var(--mint-vivid-40) !important;
}
.bg-mint-vivid-30 {
  --background: var(--mint-vivid-30);
  background: var(--mint-vivid-30) !important;
}
.text-mint-vivid-30 {
  color: var(--mint-vivid-30) !important;
}
.border-mint-vivid-30 {
  border-color: var(--mint-vivid-30) !important;
}
.bg-mint-vivid-20 {
  --background: var(--mint-vivid-20);
  background: var(--mint-vivid-20) !important;
}
.text-mint-vivid-20 {
  color: var(--mint-vivid-20) !important;
}
.border-mint-vivid-20 {
  border-color: var(--mint-vivid-20) !important;
}
.bg-mint-vivid-10 {
  --background: var(--mint-vivid-10);
  background: var(--mint-vivid-10) !important;
}
.text-mint-vivid-10 {
  color: var(--mint-vivid-10) !important;
}
.border-mint-vivid-10 {
  border-color: var(--mint-vivid-10) !important;
}
.bg-mint-vivid-5 {
  --background: var(--mint-vivid-5);
  background: var(--mint-vivid-5) !important;
}
.text-mint-vivid-5 {
  color: var(--mint-vivid-5) !important;
}
.border-mint-vivid-5 {
  border-color: var(--mint-vivid-5) !important;
}
.bg-mint-cool-vivid-80 {
  --background: var(--mint-cool-vivid-80);
  background: var(--mint-cool-vivid-80) !important;
}
.text-mint-cool-vivid-80 {
  color: var(--mint-cool-vivid-80) !important;
}
.border-mint-cool-vivid-80 {
  border-color: var(--mint-cool-vivid-80) !important;
}
.bg-mint-cool-vivid-70 {
  --background: var(--mint-cool-vivid-70);
  background: var(--mint-cool-vivid-70) !important;
}
.text-mint-cool-vivid-70 {
  color: var(--mint-cool-vivid-70) !important;
}
.border-mint-cool-vivid-70 {
  border-color: var(--mint-cool-vivid-70) !important;
}
.bg-mint-cool-vivid-60 {
  --background: var(--mint-cool-vivid-60);
  background: var(--mint-cool-vivid-60) !important;
}
.text-mint-cool-vivid-60 {
  color: var(--mint-cool-vivid-60) !important;
}
.border-mint-cool-vivid-60 {
  border-color: var(--mint-cool-vivid-60) !important;
}
.bg-mint-cool-vivid-50 {
  --background: var(--mint-cool-vivid-50);
  background: var(--mint-cool-vivid-50) !important;
}
.text-mint-cool-vivid-50 {
  color: var(--mint-cool-vivid-50) !important;
}
.border-mint-cool-vivid-50 {
  border-color: var(--mint-cool-vivid-50) !important;
}
.bg-mint-cool-vivid-40 {
  --background: var(--mint-cool-vivid-40);
  background: var(--mint-cool-vivid-40) !important;
}
.text-mint-cool-vivid-40 {
  color: var(--mint-cool-vivid-40) !important;
}
.border-mint-cool-vivid-40 {
  border-color: var(--mint-cool-vivid-40) !important;
}
.bg-mint-cool-vivid-30 {
  --background: var(--mint-cool-vivid-30);
  background: var(--mint-cool-vivid-30) !important;
}
.text-mint-cool-vivid-30 {
  color: var(--mint-cool-vivid-30) !important;
}
.border-mint-cool-vivid-30 {
  border-color: var(--mint-cool-vivid-30) !important;
}
.bg-mint-cool-vivid-20 {
  --background: var(--mint-cool-vivid-20);
  background: var(--mint-cool-vivid-20) !important;
}
.text-mint-cool-vivid-20 {
  color: var(--mint-cool-vivid-20) !important;
}
.border-mint-cool-vivid-20 {
  border-color: var(--mint-cool-vivid-20) !important;
}
.bg-mint-cool-vivid-10 {
  --background: var(--mint-cool-vivid-10);
  background: var(--mint-cool-vivid-10) !important;
}
.text-mint-cool-vivid-10 {
  color: var(--mint-cool-vivid-10) !important;
}
.border-mint-cool-vivid-10 {
  border-color: var(--mint-cool-vivid-10) !important;
}
.bg-mint-cool-vivid-5 {
  --background: var(--mint-cool-vivid-5);
  background: var(--mint-cool-vivid-5) !important;
}
.text-mint-cool-vivid-5 {
  color: var(--mint-cool-vivid-5) !important;
}
.border-mint-cool-vivid-5 {
  border-color: var(--mint-cool-vivid-5) !important;
}
.bg-orange-90 {
  --background: var(--orange-90);
  background: var(--orange-90) !important;
}
.text-orange-90 {
  color: var(--orange-90) !important;
}
.border-orange-90 {
  border-color: var(--orange-90) !important;
}
.bg-orange-80 {
  --background: var(--orange-80);
  background: var(--orange-80) !important;
}
.text-orange-80 {
  color: var(--orange-80) !important;
}
.border-orange-80 {
  border-color: var(--orange-80) !important;
}
.bg-orange-70 {
  --background: var(--orange-70);
  background: var(--orange-70) !important;
}
.text-orange-70 {
  color: var(--orange-70) !important;
}
.border-orange-70 {
  border-color: var(--orange-70) !important;
}
.bg-orange-60 {
  --background: var(--orange-60);
  background: var(--orange-60) !important;
}
.text-orange-60 {
  color: var(--orange-60) !important;
}
.border-orange-60 {
  border-color: var(--orange-60) !important;
}
.bg-orange-50 {
  --background: var(--orange-50);
  background: var(--orange-50) !important;
}
.text-orange-50 {
  color: var(--orange-50) !important;
}
.border-orange-50 {
  border-color: var(--orange-50) !important;
}
.bg-orange-40 {
  --background: var(--orange-40);
  background: var(--orange-40) !important;
}
.text-orange-40 {
  color: var(--orange-40) !important;
}
.border-orange-40 {
  border-color: var(--orange-40) !important;
}
.bg-orange-30 {
  --background: var(--orange-30);
  background: var(--orange-30) !important;
}
.text-orange-30 {
  color: var(--orange-30) !important;
}
.border-orange-30 {
  border-color: var(--orange-30) !important;
}
.bg-orange-20 {
  --background: var(--orange-20);
  background: var(--orange-20) !important;
}
.text-orange-20 {
  color: var(--orange-20) !important;
}
.border-orange-20 {
  border-color: var(--orange-20) !important;
}
.bg-orange-10 {
  --background: var(--orange-10);
  background: var(--orange-10) !important;
}
.text-orange-10 {
  color: var(--orange-10) !important;
}
.border-orange-10 {
  border-color: var(--orange-10) !important;
}
.bg-orange-5 {
  --background: var(--orange-5);
  background: var(--orange-5) !important;
}
.text-orange-5 {
  color: var(--orange-5) !important;
}
.border-orange-5 {
  border-color: var(--orange-5) !important;
}
.bg-orange-warm-90 {
  --background: var(--orange-warm-90);
  background: var(--orange-warm-90) !important;
}
.text-orange-warm-90 {
  color: var(--orange-warm-90) !important;
}
.border-orange-warm-90 {
  border-color: var(--orange-warm-90) !important;
}
.bg-orange-warm-80 {
  --background: var(--orange-warm-80);
  background: var(--orange-warm-80) !important;
}
.text-orange-warm-80 {
  color: var(--orange-warm-80) !important;
}
.border-orange-warm-80 {
  border-color: var(--orange-warm-80) !important;
}
.bg-orange-warm-70 {
  --background: var(--orange-warm-70);
  background: var(--orange-warm-70) !important;
}
.text-orange-warm-70 {
  color: var(--orange-warm-70) !important;
}
.border-orange-warm-70 {
  border-color: var(--orange-warm-70) !important;
}
.bg-orange-warm-60 {
  --background: var(--orange-warm-60);
  background: var(--orange-warm-60) !important;
}
.text-orange-warm-60 {
  color: var(--orange-warm-60) !important;
}
.border-orange-warm-60 {
  border-color: var(--orange-warm-60) !important;
}
.bg-orange-warm-50 {
  --background: var(--orange-warm-50);
  background: var(--orange-warm-50) !important;
}
.text-orange-warm-50 {
  color: var(--orange-warm-50) !important;
}
.border-orange-warm-50 {
  border-color: var(--orange-warm-50) !important;
}
.bg-orange-warm-40 {
  --background: var(--orange-warm-40);
  background: var(--orange-warm-40) !important;
}
.text-orange-warm-40 {
  color: var(--orange-warm-40) !important;
}
.border-orange-warm-40 {
  border-color: var(--orange-warm-40) !important;
}
.bg-orange-warm-30 {
  --background: var(--orange-warm-30);
  background: var(--orange-warm-30) !important;
}
.text-orange-warm-30 {
  color: var(--orange-warm-30) !important;
}
.border-orange-warm-30 {
  border-color: var(--orange-warm-30) !important;
}
.bg-orange-warm-20 {
  --background: var(--orange-warm-20);
  background: var(--orange-warm-20) !important;
}
.text-orange-warm-20 {
  color: var(--orange-warm-20) !important;
}
.border-orange-warm-20 {
  border-color: var(--orange-warm-20) !important;
}
.bg-orange-warm-10 {
  --background: var(--orange-warm-10);
  background: var(--orange-warm-10) !important;
}
.text-orange-warm-10 {
  color: var(--orange-warm-10) !important;
}
.border-orange-warm-10 {
  border-color: var(--orange-warm-10) !important;
}
.bg-orange-warm-5 {
  --background: var(--orange-warm-5);
  background: var(--orange-warm-5) !important;
}
.text-orange-warm-5 {
  color: var(--orange-warm-5) !important;
}
.border-orange-warm-5 {
  border-color: var(--orange-warm-5) !important;
}
.bg-orange-vivid-80 {
  --background: var(--orange-vivid-80);
  background: var(--orange-vivid-80) !important;
}
.text-orange-vivid-80 {
  color: var(--orange-vivid-80) !important;
}
.border-orange-vivid-80 {
  border-color: var(--orange-vivid-80) !important;
}
.bg-orange-vivid-70 {
  --background: var(--orange-vivid-70);
  background: var(--orange-vivid-70) !important;
}
.text-orange-vivid-70 {
  color: var(--orange-vivid-70) !important;
}
.border-orange-vivid-70 {
  border-color: var(--orange-vivid-70) !important;
}
.bg-orange-vivid-60 {
  --background: var(--orange-vivid-60);
  background: var(--orange-vivid-60) !important;
}
.text-orange-vivid-60 {
  color: var(--orange-vivid-60) !important;
}
.border-orange-vivid-60 {
  border-color: var(--orange-vivid-60) !important;
}
.bg-orange-vivid-50 {
  --background: var(--orange-vivid-50);
  background: var(--orange-vivid-50) !important;
}
.text-orange-vivid-50 {
  color: var(--orange-vivid-50) !important;
}
.border-orange-vivid-50 {
  border-color: var(--orange-vivid-50) !important;
}
.bg-orange-vivid-40 {
  --background: var(--orange-vivid-40);
  background: var(--orange-vivid-40) !important;
}
.text-orange-vivid-40 {
  color: var(--orange-vivid-40) !important;
}
.border-orange-vivid-40 {
  border-color: var(--orange-vivid-40) !important;
}
.bg-orange-vivid-30 {
  --background: var(--orange-vivid-30);
  background: var(--orange-vivid-30) !important;
}
.text-orange-vivid-30 {
  color: var(--orange-vivid-30) !important;
}
.border-orange-vivid-30 {
  border-color: var(--orange-vivid-30) !important;
}
.bg-orange-vivid-20 {
  --background: var(--orange-vivid-20);
  background: var(--orange-vivid-20) !important;
}
.text-orange-vivid-20 {
  color: var(--orange-vivid-20) !important;
}
.border-orange-vivid-20 {
  border-color: var(--orange-vivid-20) !important;
}
.bg-orange-vivid-10 {
  --background: var(--orange-vivid-10);
  background: var(--orange-vivid-10) !important;
}
.text-orange-vivid-10 {
  color: var(--orange-vivid-10) !important;
}
.border-orange-vivid-10 {
  border-color: var(--orange-vivid-10) !important;
}
.bg-orange-vivid-5 {
  --background: var(--orange-vivid-5);
  background: var(--orange-vivid-5) !important;
}
.text-orange-vivid-5 {
  color: var(--orange-vivid-5) !important;
}
.border-orange-vivid-5 {
  border-color: var(--orange-vivid-5) !important;
}
.bg-orange-warm-vivid-80 {
  --background: var(--orange-warm-vivid-80);
  background: var(--orange-warm-vivid-80) !important;
}
.text-orange-warm-vivid-80 {
  color: var(--orange-warm-vivid-80) !important;
}
.border-orange-warm-vivid-80 {
  border-color: var(--orange-warm-vivid-80) !important;
}
.bg-orange-warm-vivid-70 {
  --background: var(--orange-warm-vivid-70);
  background: var(--orange-warm-vivid-70) !important;
}
.text-orange-warm-vivid-70 {
  color: var(--orange-warm-vivid-70) !important;
}
.border-orange-warm-vivid-70 {
  border-color: var(--orange-warm-vivid-70) !important;
}
.bg-orange-warm-vivid-60 {
  --background: var(--orange-warm-vivid-60);
  background: var(--orange-warm-vivid-60) !important;
}
.text-orange-warm-vivid-60 {
  color: var(--orange-warm-vivid-60) !important;
}
.border-orange-warm-vivid-60 {
  border-color: var(--orange-warm-vivid-60) !important;
}
.bg-orange-warm-vivid-50 {
  --background: var(--orange-warm-vivid-50);
  background: var(--orange-warm-vivid-50) !important;
}
.text-orange-warm-vivid-50 {
  color: var(--orange-warm-vivid-50) !important;
}
.border-orange-warm-vivid-50 {
  border-color: var(--orange-warm-vivid-50) !important;
}
.bg-orange-warm-vivid-40 {
  --background: var(--orange-warm-vivid-40);
  background: var(--orange-warm-vivid-40) !important;
}
.text-orange-warm-vivid-40 {
  color: var(--orange-warm-vivid-40) !important;
}
.border-orange-warm-vivid-40 {
  border-color: var(--orange-warm-vivid-40) !important;
}
.bg-orange-warm-vivid-30 {
  --background: var(--orange-warm-vivid-30);
  background: var(--orange-warm-vivid-30) !important;
}
.text-orange-warm-vivid-30 {
  color: var(--orange-warm-vivid-30) !important;
}
.border-orange-warm-vivid-30 {
  border-color: var(--orange-warm-vivid-30) !important;
}
.bg-orange-warm-vivid-20 {
  --background: var(--orange-warm-vivid-20);
  background: var(--orange-warm-vivid-20) !important;
}
.text-orange-warm-vivid-20 {
  color: var(--orange-warm-vivid-20) !important;
}
.border-orange-warm-vivid-20 {
  border-color: var(--orange-warm-vivid-20) !important;
}
.bg-orange-warm-vivid-10 {
  --background: var(--orange-warm-vivid-10);
  background: var(--orange-warm-vivid-10) !important;
}
.text-orange-warm-vivid-10 {
  color: var(--orange-warm-vivid-10) !important;
}
.border-orange-warm-vivid-10 {
  border-color: var(--orange-warm-vivid-10) !important;
}
.bg-orange-warm-vivid-5 {
  --background: var(--orange-warm-vivid-5);
  background: var(--orange-warm-vivid-5) !important;
}
.text-orange-warm-vivid-5 {
  color: var(--orange-warm-vivid-5) !important;
}
.border-orange-warm-vivid-5 {
  border-color: var(--orange-warm-vivid-5) !important;
}
.bg-pure-100 {
  --background: var(--pure-100);
  background: var(--pure-100) !important;
}
.text-pure-100 {
  color: var(--pure-100) !important;
}
.border-pure-100 {
  border-color: var(--pure-100) !important;
}
.bg-pure-0 {
  --background: var(--pure-0);
  background: var(--pure-0) !important;
}
.text-pure-0 {
  color: var(--pure-0) !important;
}
.border-pure-0 {
  border-color: var(--pure-0) !important;
}
.bg-red-90 {
  --background: var(--red-90);
  background: var(--red-90) !important;
}
.text-red-90 {
  color: var(--red-90) !important;
}
.border-red-90 {
  border-color: var(--red-90) !important;
}
.bg-red-80 {
  --background: var(--red-80);
  background: var(--red-80) !important;
}
.text-red-80 {
  color: var(--red-80) !important;
}
.border-red-80 {
  border-color: var(--red-80) !important;
}
.bg-red-70 {
  --background: var(--red-70);
  background: var(--red-70) !important;
}
.text-red-70 {
  color: var(--red-70) !important;
}
.border-red-70 {
  border-color: var(--red-70) !important;
}
.bg-red-60 {
  --background: var(--red-60);
  background: var(--red-60) !important;
}
.text-red-60 {
  color: var(--red-60) !important;
}
.border-red-60 {
  border-color: var(--red-60) !important;
}
.bg-red-50 {
  --background: var(--red-50);
  background: var(--red-50) !important;
}
.text-red-50 {
  color: var(--red-50) !important;
}
.border-red-50 {
  border-color: var(--red-50) !important;
}
.bg-red-40 {
  --background: var(--red-40);
  background: var(--red-40) !important;
}
.text-red-40 {
  color: var(--red-40) !important;
}
.border-red-40 {
  border-color: var(--red-40) !important;
}
.bg-red-30 {
  --background: var(--red-30);
  background: var(--red-30) !important;
}
.text-red-30 {
  color: var(--red-30) !important;
}
.border-red-30 {
  border-color: var(--red-30) !important;
}
.bg-red-20 {
  --background: var(--red-20);
  background: var(--red-20) !important;
}
.text-red-20 {
  color: var(--red-20) !important;
}
.border-red-20 {
  border-color: var(--red-20) !important;
}
.bg-red-10 {
  --background: var(--red-10);
  background: var(--red-10) !important;
}
.text-red-10 {
  color: var(--red-10) !important;
}
.border-red-10 {
  border-color: var(--red-10) !important;
}
.bg-red-5 {
  --background: var(--red-5);
  background: var(--red-5) !important;
}
.text-red-5 {
  color: var(--red-5) !important;
}
.border-red-5 {
  border-color: var(--red-5) !important;
}
.bg-red-cool-90 {
  --background: var(--red-cool-90);
  background: var(--red-cool-90) !important;
}
.text-red-cool-90 {
  color: var(--red-cool-90) !important;
}
.border-red-cool-90 {
  border-color: var(--red-cool-90) !important;
}
.bg-red-cool-80 {
  --background: var(--red-cool-80);
  background: var(--red-cool-80) !important;
}
.text-red-cool-80 {
  color: var(--red-cool-80) !important;
}
.border-red-cool-80 {
  border-color: var(--red-cool-80) !important;
}
.bg-red-cool-70 {
  --background: var(--red-cool-70);
  background: var(--red-cool-70) !important;
}
.text-red-cool-70 {
  color: var(--red-cool-70) !important;
}
.border-red-cool-70 {
  border-color: var(--red-cool-70) !important;
}
.bg-red-cool-60 {
  --background: var(--red-cool-60);
  background: var(--red-cool-60) !important;
}
.text-red-cool-60 {
  color: var(--red-cool-60) !important;
}
.border-red-cool-60 {
  border-color: var(--red-cool-60) !important;
}
.bg-red-cool-50 {
  --background: var(--red-cool-50);
  background: var(--red-cool-50) !important;
}
.text-red-cool-50 {
  color: var(--red-cool-50) !important;
}
.border-red-cool-50 {
  border-color: var(--red-cool-50) !important;
}
.bg-red-cool-40 {
  --background: var(--red-cool-40);
  background: var(--red-cool-40) !important;
}
.text-red-cool-40 {
  color: var(--red-cool-40) !important;
}
.border-red-cool-40 {
  border-color: var(--red-cool-40) !important;
}
.bg-red-cool-30 {
  --background: var(--red-cool-30);
  background: var(--red-cool-30) !important;
}
.text-red-cool-30 {
  color: var(--red-cool-30) !important;
}
.border-red-cool-30 {
  border-color: var(--red-cool-30) !important;
}
.bg-red-cool-20 {
  --background: var(--red-cool-20);
  background: var(--red-cool-20) !important;
}
.text-red-cool-20 {
  color: var(--red-cool-20) !important;
}
.border-red-cool-20 {
  border-color: var(--red-cool-20) !important;
}
.bg-red-cool-10 {
  --background: var(--red-cool-10);
  background: var(--red-cool-10) !important;
}
.text-red-cool-10 {
  color: var(--red-cool-10) !important;
}
.border-red-cool-10 {
  border-color: var(--red-cool-10) !important;
}
.bg-red-cool-5 {
  --background: var(--red-cool-5);
  background: var(--red-cool-5) !important;
}
.text-red-cool-5 {
  color: var(--red-cool-5) !important;
}
.border-red-cool-5 {
  border-color: var(--red-cool-5) !important;
}
.bg-red-warm-90 {
  --background: var(--red-warm-90);
  background: var(--red-warm-90) !important;
}
.text-red-warm-90 {
  color: var(--red-warm-90) !important;
}
.border-red-warm-90 {
  border-color: var(--red-warm-90) !important;
}
.bg-red-warm-80 {
  --background: var(--red-warm-80);
  background: var(--red-warm-80) !important;
}
.text-red-warm-80 {
  color: var(--red-warm-80) !important;
}
.border-red-warm-80 {
  border-color: var(--red-warm-80) !important;
}
.bg-red-warm-70 {
  --background: var(--red-warm-70);
  background: var(--red-warm-70) !important;
}
.text-red-warm-70 {
  color: var(--red-warm-70) !important;
}
.border-red-warm-70 {
  border-color: var(--red-warm-70) !important;
}
.bg-red-warm-60 {
  --background: var(--red-warm-60);
  background: var(--red-warm-60) !important;
}
.text-red-warm-60 {
  color: var(--red-warm-60) !important;
}
.border-red-warm-60 {
  border-color: var(--red-warm-60) !important;
}
.bg-red-warm-50 {
  --background: var(--red-warm-50);
  background: var(--red-warm-50) !important;
}
.text-red-warm-50 {
  color: var(--red-warm-50) !important;
}
.border-red-warm-50 {
  border-color: var(--red-warm-50) !important;
}
.bg-red-warm-40 {
  --background: var(--red-warm-40);
  background: var(--red-warm-40) !important;
}
.text-red-warm-40 {
  color: var(--red-warm-40) !important;
}
.border-red-warm-40 {
  border-color: var(--red-warm-40) !important;
}
.bg-red-warm-30 {
  --background: var(--red-warm-30);
  background: var(--red-warm-30) !important;
}
.text-red-warm-30 {
  color: var(--red-warm-30) !important;
}
.border-red-warm-30 {
  border-color: var(--red-warm-30) !important;
}
.bg-red-warm-20 {
  --background: var(--red-warm-20);
  background: var(--red-warm-20) !important;
}
.text-red-warm-20 {
  color: var(--red-warm-20) !important;
}
.border-red-warm-20 {
  border-color: var(--red-warm-20) !important;
}
.bg-red-warm-10 {
  --background: var(--red-warm-10);
  background: var(--red-warm-10) !important;
}
.text-red-warm-10 {
  color: var(--red-warm-10) !important;
}
.border-red-warm-10 {
  border-color: var(--red-warm-10) !important;
}
.bg-red-warm-5 {
  --background: var(--red-warm-5);
  background: var(--red-warm-5) !important;
}
.text-red-warm-5 {
  color: var(--red-warm-5) !important;
}
.border-red-warm-5 {
  border-color: var(--red-warm-5) !important;
}
.bg-red-vivid-80 {
  --background: var(--red-vivid-80);
  background: var(--red-vivid-80) !important;
}
.text-red-vivid-80 {
  color: var(--red-vivid-80) !important;
}
.border-red-vivid-80 {
  border-color: var(--red-vivid-80) !important;
}
.bg-red-vivid-70 {
  --background: var(--red-vivid-70);
  background: var(--red-vivid-70) !important;
}
.text-red-vivid-70 {
  color: var(--red-vivid-70) !important;
}
.border-red-vivid-70 {
  border-color: var(--red-vivid-70) !important;
}
.bg-red-vivid-60 {
  --background: var(--red-vivid-60);
  background: var(--red-vivid-60) !important;
}
.text-red-vivid-60 {
  color: var(--red-vivid-60) !important;
}
.border-red-vivid-60 {
  border-color: var(--red-vivid-60) !important;
}
.bg-red-vivid-50 {
  --background: var(--red-vivid-50);
  background: var(--red-vivid-50) !important;
}
.text-red-vivid-50 {
  color: var(--red-vivid-50) !important;
}
.border-red-vivid-50 {
  border-color: var(--red-vivid-50) !important;
}
.bg-red-vivid-40 {
  --background: var(--red-vivid-40);
  background: var(--red-vivid-40) !important;
}
.text-red-vivid-40 {
  color: var(--red-vivid-40) !important;
}
.border-red-vivid-40 {
  border-color: var(--red-vivid-40) !important;
}
.bg-red-vivid-30 {
  --background: var(--red-vivid-30);
  background: var(--red-vivid-30) !important;
}
.text-red-vivid-30 {
  color: var(--red-vivid-30) !important;
}
.border-red-vivid-30 {
  border-color: var(--red-vivid-30) !important;
}
.bg-red-vivid-20 {
  --background: var(--red-vivid-20);
  background: var(--red-vivid-20) !important;
}
.text-red-vivid-20 {
  color: var(--red-vivid-20) !important;
}
.border-red-vivid-20 {
  border-color: var(--red-vivid-20) !important;
}
.bg-red-vivid-10 {
  --background: var(--red-vivid-10);
  background: var(--red-vivid-10) !important;
}
.text-red-vivid-10 {
  color: var(--red-vivid-10) !important;
}
.border-red-vivid-10 {
  border-color: var(--red-vivid-10) !important;
}
.bg-red-vivid-5 {
  --background: var(--red-vivid-5);
  background: var(--red-vivid-5) !important;
}
.text-red-vivid-5 {
  color: var(--red-vivid-5) !important;
}
.border-red-vivid-5 {
  border-color: var(--red-vivid-5) !important;
}
.bg-red-cool-vivid-80 {
  --background: var(--red-cool-vivid-80);
  background: var(--red-cool-vivid-80) !important;
}
.text-red-cool-vivid-80 {
  color: var(--red-cool-vivid-80) !important;
}
.border-red-cool-vivid-80 {
  border-color: var(--red-cool-vivid-80) !important;
}
.bg-red-cool-vivid-70 {
  --background: var(--red-cool-vivid-70);
  background: var(--red-cool-vivid-70) !important;
}
.text-red-cool-vivid-70 {
  color: var(--red-cool-vivid-70) !important;
}
.border-red-cool-vivid-70 {
  border-color: var(--red-cool-vivid-70) !important;
}
.bg-red-cool-vivid-60 {
  --background: var(--red-cool-vivid-60);
  background: var(--red-cool-vivid-60) !important;
}
.text-red-cool-vivid-60 {
  color: var(--red-cool-vivid-60) !important;
}
.border-red-cool-vivid-60 {
  border-color: var(--red-cool-vivid-60) !important;
}
.bg-red-cool-vivid-50 {
  --background: var(--red-cool-vivid-50);
  background: var(--red-cool-vivid-50) !important;
}
.text-red-cool-vivid-50 {
  color: var(--red-cool-vivid-50) !important;
}
.border-red-cool-vivid-50 {
  border-color: var(--red-cool-vivid-50) !important;
}
.bg-red-cool-vivid-40 {
  --background: var(--red-cool-vivid-40);
  background: var(--red-cool-vivid-40) !important;
}
.text-red-cool-vivid-40 {
  color: var(--red-cool-vivid-40) !important;
}
.border-red-cool-vivid-40 {
  border-color: var(--red-cool-vivid-40) !important;
}
.bg-red-cool-vivid-30 {
  --background: var(--red-cool-vivid-30);
  background: var(--red-cool-vivid-30) !important;
}
.text-red-cool-vivid-30 {
  color: var(--red-cool-vivid-30) !important;
}
.border-red-cool-vivid-30 {
  border-color: var(--red-cool-vivid-30) !important;
}
.bg-red-cool-vivid-20 {
  --background: var(--red-cool-vivid-20);
  background: var(--red-cool-vivid-20) !important;
}
.text-red-cool-vivid-20 {
  color: var(--red-cool-vivid-20) !important;
}
.border-red-cool-vivid-20 {
  border-color: var(--red-cool-vivid-20) !important;
}
.bg-red-cool-vivid-10 {
  --background: var(--red-cool-vivid-10);
  background: var(--red-cool-vivid-10) !important;
}
.text-red-cool-vivid-10 {
  color: var(--red-cool-vivid-10) !important;
}
.border-red-cool-vivid-10 {
  border-color: var(--red-cool-vivid-10) !important;
}
.bg-red-cool-vivid-5 {
  --background: var(--red-cool-vivid-5);
  background: var(--red-cool-vivid-5) !important;
}
.text-red-cool-vivid-5 {
  color: var(--red-cool-vivid-5) !important;
}
.border-red-cool-vivid-5 {
  border-color: var(--red-cool-vivid-5) !important;
}
.bg-red-warm-vivid-80 {
  --background: var(--red-warm-vivid-80);
  background: var(--red-warm-vivid-80) !important;
}
.text-red-warm-vivid-80 {
  color: var(--red-warm-vivid-80) !important;
}
.border-red-warm-vivid-80 {
  border-color: var(--red-warm-vivid-80) !important;
}
.bg-red-warm-vivid-70 {
  --background: var(--red-warm-vivid-70);
  background: var(--red-warm-vivid-70) !important;
}
.text-red-warm-vivid-70 {
  color: var(--red-warm-vivid-70) !important;
}
.border-red-warm-vivid-70 {
  border-color: var(--red-warm-vivid-70) !important;
}
.bg-red-warm-vivid-60 {
  --background: var(--red-warm-vivid-60);
  background: var(--red-warm-vivid-60) !important;
}
.text-red-warm-vivid-60 {
  color: var(--red-warm-vivid-60) !important;
}
.border-red-warm-vivid-60 {
  border-color: var(--red-warm-vivid-60) !important;
}
.bg-red-warm-vivid-50 {
  --background: var(--red-warm-vivid-50);
  background: var(--red-warm-vivid-50) !important;
}
.text-red-warm-vivid-50 {
  color: var(--red-warm-vivid-50) !important;
}
.border-red-warm-vivid-50 {
  border-color: var(--red-warm-vivid-50) !important;
}
.bg-red-warm-vivid-40 {
  --background: var(--red-warm-vivid-40);
  background: var(--red-warm-vivid-40) !important;
}
.text-red-warm-vivid-40 {
  color: var(--red-warm-vivid-40) !important;
}
.border-red-warm-vivid-40 {
  border-color: var(--red-warm-vivid-40) !important;
}
.bg-red-warm-vivid-30 {
  --background: var(--red-warm-vivid-30);
  background: var(--red-warm-vivid-30) !important;
}
.text-red-warm-vivid-30 {
  color: var(--red-warm-vivid-30) !important;
}
.border-red-warm-vivid-30 {
  border-color: var(--red-warm-vivid-30) !important;
}
.bg-red-warm-vivid-20 {
  --background: var(--red-warm-vivid-20);
  background: var(--red-warm-vivid-20) !important;
}
.text-red-warm-vivid-20 {
  color: var(--red-warm-vivid-20) !important;
}
.border-red-warm-vivid-20 {
  border-color: var(--red-warm-vivid-20) !important;
}
.bg-red-warm-vivid-10 {
  --background: var(--red-warm-vivid-10);
  background: var(--red-warm-vivid-10) !important;
}
.text-red-warm-vivid-10 {
  color: var(--red-warm-vivid-10) !important;
}
.border-red-warm-vivid-10 {
  border-color: var(--red-warm-vivid-10) !important;
}
.bg-red-warm-vivid-5 {
  --background: var(--red-warm-vivid-5);
  background: var(--red-warm-vivid-5) !important;
}
.text-red-warm-vivid-5 {
  color: var(--red-warm-vivid-5) !important;
}
.border-red-warm-vivid-5 {
  border-color: var(--red-warm-vivid-5) !important;
}
.bg-violet-90 {
  --background: var(--violet-90);
  background: var(--violet-90) !important;
}
.text-violet-90 {
  color: var(--violet-90) !important;
}
.border-violet-90 {
  border-color: var(--violet-90) !important;
}
.bg-violet-80 {
  --background: var(--violet-80);
  background: var(--violet-80) !important;
}
.text-violet-80 {
  color: var(--violet-80) !important;
}
.border-violet-80 {
  border-color: var(--violet-80) !important;
}
.bg-violet-70 {
  --background: var(--violet-70);
  background: var(--violet-70) !important;
}
.text-violet-70 {
  color: var(--violet-70) !important;
}
.border-violet-70 {
  border-color: var(--violet-70) !important;
}
.bg-violet-60 {
  --background: var(--violet-60);
  background: var(--violet-60) !important;
}
.text-violet-60 {
  color: var(--violet-60) !important;
}
.border-violet-60 {
  border-color: var(--violet-60) !important;
}
.bg-violet-50 {
  --background: var(--violet-50);
  background: var(--violet-50) !important;
}
.text-violet-50 {
  color: var(--violet-50) !important;
}
.border-violet-50 {
  border-color: var(--violet-50) !important;
}
.bg-violet-40 {
  --background: var(--violet-40);
  background: var(--violet-40) !important;
}
.text-violet-40 {
  color: var(--violet-40) !important;
}
.border-violet-40 {
  border-color: var(--violet-40) !important;
}
.bg-violet-30 {
  --background: var(--violet-30);
  background: var(--violet-30) !important;
}
.text-violet-30 {
  color: var(--violet-30) !important;
}
.border-violet-30 {
  border-color: var(--violet-30) !important;
}
.bg-violet-20 {
  --background: var(--violet-20);
  background: var(--violet-20) !important;
}
.text-violet-20 {
  color: var(--violet-20) !important;
}
.border-violet-20 {
  border-color: var(--violet-20) !important;
}
.bg-violet-10 {
  --background: var(--violet-10);
  background: var(--violet-10) !important;
}
.text-violet-10 {
  color: var(--violet-10) !important;
}
.border-violet-10 {
  border-color: var(--violet-10) !important;
}
.bg-violet-5 {
  --background: var(--violet-5);
  background: var(--violet-5) !important;
}
.text-violet-5 {
  color: var(--violet-5) !important;
}
.border-violet-5 {
  border-color: var(--violet-5) !important;
}
.bg-violet-warm-90 {
  --background: var(--violet-warm-90);
  background: var(--violet-warm-90) !important;
}
.text-violet-warm-90 {
  color: var(--violet-warm-90) !important;
}
.border-violet-warm-90 {
  border-color: var(--violet-warm-90) !important;
}
.bg-violet-warm-80 {
  --background: var(--violet-warm-80);
  background: var(--violet-warm-80) !important;
}
.text-violet-warm-80 {
  color: var(--violet-warm-80) !important;
}
.border-violet-warm-80 {
  border-color: var(--violet-warm-80) !important;
}
.bg-violet-warm-70 {
  --background: var(--violet-warm-70);
  background: var(--violet-warm-70) !important;
}
.text-violet-warm-70 {
  color: var(--violet-warm-70) !important;
}
.border-violet-warm-70 {
  border-color: var(--violet-warm-70) !important;
}
.bg-violet-warm-60 {
  --background: var(--violet-warm-60);
  background: var(--violet-warm-60) !important;
}
.text-violet-warm-60 {
  color: var(--violet-warm-60) !important;
}
.border-violet-warm-60 {
  border-color: var(--violet-warm-60) !important;
}
.bg-violet-warm-50 {
  --background: var(--violet-warm-50);
  background: var(--violet-warm-50) !important;
}
.text-violet-warm-50 {
  color: var(--violet-warm-50) !important;
}
.border-violet-warm-50 {
  border-color: var(--violet-warm-50) !important;
}
.bg-violet-warm-40 {
  --background: var(--violet-warm-40);
  background: var(--violet-warm-40) !important;
}
.text-violet-warm-40 {
  color: var(--violet-warm-40) !important;
}
.border-violet-warm-40 {
  border-color: var(--violet-warm-40) !important;
}
.bg-violet-warm-30 {
  --background: var(--violet-warm-30);
  background: var(--violet-warm-30) !important;
}
.text-violet-warm-30 {
  color: var(--violet-warm-30) !important;
}
.border-violet-warm-30 {
  border-color: var(--violet-warm-30) !important;
}
.bg-violet-warm-20 {
  --background: var(--violet-warm-20);
  background: var(--violet-warm-20) !important;
}
.text-violet-warm-20 {
  color: var(--violet-warm-20) !important;
}
.border-violet-warm-20 {
  border-color: var(--violet-warm-20) !important;
}
.bg-violet-warm-10 {
  --background: var(--violet-warm-10);
  background: var(--violet-warm-10) !important;
}
.text-violet-warm-10 {
  color: var(--violet-warm-10) !important;
}
.border-violet-warm-10 {
  border-color: var(--violet-warm-10) !important;
}
.bg-violet-warm-5 {
  --background: var(--violet-warm-5);
  background: var(--violet-warm-5) !important;
}
.text-violet-warm-5 {
  color: var(--violet-warm-5) !important;
}
.border-violet-warm-5 {
  border-color: var(--violet-warm-5) !important;
}
.bg-violet-vivid-80 {
  --background: var(--violet-vivid-80);
  background: var(--violet-vivid-80) !important;
}
.text-violet-vivid-80 {
  color: var(--violet-vivid-80) !important;
}
.border-violet-vivid-80 {
  border-color: var(--violet-vivid-80) !important;
}
.bg-violet-vivid-70 {
  --background: var(--violet-vivid-70);
  background: var(--violet-vivid-70) !important;
}
.text-violet-vivid-70 {
  color: var(--violet-vivid-70) !important;
}
.border-violet-vivid-70 {
  border-color: var(--violet-vivid-70) !important;
}
.bg-violet-vivid-60 {
  --background: var(--violet-vivid-60);
  background: var(--violet-vivid-60) !important;
}
.text-violet-vivid-60 {
  color: var(--violet-vivid-60) !important;
}
.border-violet-vivid-60 {
  border-color: var(--violet-vivid-60) !important;
}
.bg-violet-vivid-50 {
  --background: var(--violet-vivid-50);
  background: var(--violet-vivid-50) !important;
}
.text-violet-vivid-50 {
  color: var(--violet-vivid-50) !important;
}
.border-violet-vivid-50 {
  border-color: var(--violet-vivid-50) !important;
}
.bg-violet-vivid-40 {
  --background: var(--violet-vivid-40);
  background: var(--violet-vivid-40) !important;
}
.text-violet-vivid-40 {
  color: var(--violet-vivid-40) !important;
}
.border-violet-vivid-40 {
  border-color: var(--violet-vivid-40) !important;
}
.bg-violet-vivid-30 {
  --background: var(--violet-vivid-30);
  background: var(--violet-vivid-30) !important;
}
.text-violet-vivid-30 {
  color: var(--violet-vivid-30) !important;
}
.border-violet-vivid-30 {
  border-color: var(--violet-vivid-30) !important;
}
.bg-violet-vivid-20 {
  --background: var(--violet-vivid-20);
  background: var(--violet-vivid-20) !important;
}
.text-violet-vivid-20 {
  color: var(--violet-vivid-20) !important;
}
.border-violet-vivid-20 {
  border-color: var(--violet-vivid-20) !important;
}
.bg-violet-vivid-10 {
  --background: var(--violet-vivid-10);
  background: var(--violet-vivid-10) !important;
}
.text-violet-vivid-10 {
  color: var(--violet-vivid-10) !important;
}
.border-violet-vivid-10 {
  border-color: var(--violet-vivid-10) !important;
}
.bg-violet-vivid-5 {
  --background: var(--violet-vivid-5);
  background: var(--violet-vivid-5) !important;
}
.text-violet-vivid-5 {
  color: var(--violet-vivid-5) !important;
}
.border-violet-vivid-5 {
  border-color: var(--violet-vivid-5) !important;
}
.bg-violet-warm-vivid-80 {
  --background: var(--violet-warm-vivid-80);
  background: var(--violet-warm-vivid-80) !important;
}
.text-violet-warm-vivid-80 {
  color: var(--violet-warm-vivid-80) !important;
}
.border-violet-warm-vivid-80 {
  border-color: var(--violet-warm-vivid-80) !important;
}
.bg-violet-warm-vivid-70 {
  --background: var(--violet-warm-vivid-70);
  background: var(--violet-warm-vivid-70) !important;
}
.text-violet-warm-vivid-70 {
  color: var(--violet-warm-vivid-70) !important;
}
.border-violet-warm-vivid-70 {
  border-color: var(--violet-warm-vivid-70) !important;
}
.bg-violet-warm-vivid-60 {
  --background: var(--violet-warm-vivid-60);
  background: var(--violet-warm-vivid-60) !important;
}
.text-violet-warm-vivid-60 {
  color: var(--violet-warm-vivid-60) !important;
}
.border-violet-warm-vivid-60 {
  border-color: var(--violet-warm-vivid-60) !important;
}
.bg-violet-warm-vivid-50 {
  --background: var(--violet-warm-vivid-50);
  background: var(--violet-warm-vivid-50) !important;
}
.text-violet-warm-vivid-50 {
  color: var(--violet-warm-vivid-50) !important;
}
.border-violet-warm-vivid-50 {
  border-color: var(--violet-warm-vivid-50) !important;
}
.bg-violet-warm-vivid-40 {
  --background: var(--violet-warm-vivid-40);
  background: var(--violet-warm-vivid-40) !important;
}
.text-violet-warm-vivid-40 {
  color: var(--violet-warm-vivid-40) !important;
}
.border-violet-warm-vivid-40 {
  border-color: var(--violet-warm-vivid-40) !important;
}
.bg-violet-warm-vivid-30 {
  --background: var(--violet-warm-vivid-30);
  background: var(--violet-warm-vivid-30) !important;
}
.text-violet-warm-vivid-30 {
  color: var(--violet-warm-vivid-30) !important;
}
.border-violet-warm-vivid-30 {
  border-color: var(--violet-warm-vivid-30) !important;
}
.bg-violet-warm-vivid-20 {
  --background: var(--violet-warm-vivid-20);
  background: var(--violet-warm-vivid-20) !important;
}
.text-violet-warm-vivid-20 {
  color: var(--violet-warm-vivid-20) !important;
}
.border-violet-warm-vivid-20 {
  border-color: var(--violet-warm-vivid-20) !important;
}
.bg-violet-warm-vivid-10 {
  --background: var(--violet-warm-vivid-10);
  background: var(--violet-warm-vivid-10) !important;
}
.text-violet-warm-vivid-10 {
  color: var(--violet-warm-vivid-10) !important;
}
.border-violet-warm-vivid-10 {
  border-color: var(--violet-warm-vivid-10) !important;
}
.bg-violet-warm-vivid-5 {
  --background: var(--violet-warm-vivid-5);
  background: var(--violet-warm-vivid-5) !important;
}
.text-violet-warm-vivid-5 {
  color: var(--violet-warm-vivid-5) !important;
}
.border-violet-warm-vivid-5 {
  border-color: var(--violet-warm-vivid-5) !important;
}
.bg-yellow-90 {
  --background: var(--yellow-90);
  background: var(--yellow-90) !important;
}
.text-yellow-90 {
  color: var(--yellow-90) !important;
}
.border-yellow-90 {
  border-color: var(--yellow-90) !important;
}
.bg-yellow-80 {
  --background: var(--yellow-80);
  background: var(--yellow-80) !important;
}
.text-yellow-80 {
  color: var(--yellow-80) !important;
}
.border-yellow-80 {
  border-color: var(--yellow-80) !important;
}
.bg-yellow-70 {
  --background: var(--yellow-70);
  background: var(--yellow-70) !important;
}
.text-yellow-70 {
  color: var(--yellow-70) !important;
}
.border-yellow-70 {
  border-color: var(--yellow-70) !important;
}
.bg-yellow-60 {
  --background: var(--yellow-60);
  background: var(--yellow-60) !important;
}
.text-yellow-60 {
  color: var(--yellow-60) !important;
}
.border-yellow-60 {
  border-color: var(--yellow-60) !important;
}
.bg-yellow-50 {
  --background: var(--yellow-50);
  background: var(--yellow-50) !important;
}
.text-yellow-50 {
  color: var(--yellow-50) !important;
}
.border-yellow-50 {
  border-color: var(--yellow-50) !important;
}
.bg-yellow-40 {
  --background: var(--yellow-40);
  background: var(--yellow-40) !important;
}
.text-yellow-40 {
  color: var(--yellow-40) !important;
}
.border-yellow-40 {
  border-color: var(--yellow-40) !important;
}
.bg-yellow-30 {
  --background: var(--yellow-30);
  background: var(--yellow-30) !important;
}
.text-yellow-30 {
  color: var(--yellow-30) !important;
}
.border-yellow-30 {
  border-color: var(--yellow-30) !important;
}
.bg-yellow-20 {
  --background: var(--yellow-20);
  background: var(--yellow-20) !important;
}
.text-yellow-20 {
  color: var(--yellow-20) !important;
}
.border-yellow-20 {
  border-color: var(--yellow-20) !important;
}
.bg-yellow-10 {
  --background: var(--yellow-10);
  background: var(--yellow-10) !important;
}
.text-yellow-10 {
  color: var(--yellow-10) !important;
}
.border-yellow-10 {
  border-color: var(--yellow-10) !important;
}
.bg-yellow-5 {
  --background: var(--yellow-5);
  background: var(--yellow-5) !important;
}
.text-yellow-5 {
  color: var(--yellow-5) !important;
}
.border-yellow-5 {
  border-color: var(--yellow-5) !important;
}
.bg-yellow-vivid-80 {
  --background: var(--yellow-vivid-80);
  background: var(--yellow-vivid-80) !important;
}
.text-yellow-vivid-80 {
  color: var(--yellow-vivid-80) !important;
}
.border-yellow-vivid-80 {
  border-color: var(--yellow-vivid-80) !important;
}
.bg-yellow-vivid-70 {
  --background: var(--yellow-vivid-70);
  background: var(--yellow-vivid-70) !important;
}
.text-yellow-vivid-70 {
  color: var(--yellow-vivid-70) !important;
}
.border-yellow-vivid-70 {
  border-color: var(--yellow-vivid-70) !important;
}
.bg-yellow-vivid-60 {
  --background: var(--yellow-vivid-60);
  background: var(--yellow-vivid-60) !important;
}
.text-yellow-vivid-60 {
  color: var(--yellow-vivid-60) !important;
}
.border-yellow-vivid-60 {
  border-color: var(--yellow-vivid-60) !important;
}
.bg-yellow-vivid-50 {
  --background: var(--yellow-vivid-50);
  background: var(--yellow-vivid-50) !important;
}
.text-yellow-vivid-50 {
  color: var(--yellow-vivid-50) !important;
}
.border-yellow-vivid-50 {
  border-color: var(--yellow-vivid-50) !important;
}
.bg-yellow-vivid-40 {
  --background: var(--yellow-vivid-40);
  background: var(--yellow-vivid-40) !important;
}
.text-yellow-vivid-40 {
  color: var(--yellow-vivid-40) !important;
}
.border-yellow-vivid-40 {
  border-color: var(--yellow-vivid-40) !important;
}
.bg-yellow-vivid-30 {
  --background: var(--yellow-vivid-30);
  background: var(--yellow-vivid-30) !important;
}
.text-yellow-vivid-30 {
  color: var(--yellow-vivid-30) !important;
}
.border-yellow-vivid-30 {
  border-color: var(--yellow-vivid-30) !important;
}
.bg-yellow-vivid-20 {
  --background: var(--yellow-vivid-20);
  background: var(--yellow-vivid-20) !important;
}
.text-yellow-vivid-20 {
  color: var(--yellow-vivid-20) !important;
}
.border-yellow-vivid-20 {
  border-color: var(--yellow-vivid-20) !important;
}
.bg-yellow-vivid-10 {
  --background: var(--yellow-vivid-10);
  background: var(--yellow-vivid-10) !important;
}
.text-yellow-vivid-10 {
  color: var(--yellow-vivid-10) !important;
}
.border-yellow-vivid-10 {
  border-color: var(--yellow-vivid-10) !important;
}
.bg-yellow-vivid-5 {
  --background: var(--yellow-vivid-5);
  background: var(--yellow-vivid-5) !important;
}
.text-yellow-vivid-5 {
  color: var(--yellow-vivid-5) !important;
}
.border-yellow-vivid-5 {
  border-color: var(--yellow-vivid-5) !important;
}
.bg-interactive {
  --background: var(--interactive);
  background: var(--interactive) !important;
}
.text-interactive {
  color: var(--interactive) !important;
}
.border-interactive {
  border-color: var(--interactive) !important;
}
.bg-interactive-dark {
  --background: var(--interactive-dark);
  background: var(--interactive-dark) !important;
}
.text-interactive-dark {
  color: var(--interactive-dark) !important;
}
.border-interactive-dark {
  border-color: var(--interactive-dark) !important;
}
.bg-danger {
  --background: var(--danger);
  background: var(--danger) !important;
}
.text-danger {
  color: var(--danger) !important;
}
.border-danger {
  border-color: var(--danger) !important;
}
.bg-warning {
  --background: var(--warning);
  background: var(--warning) !important;
}
.text-warning {
  color: var(--warning) !important;
}
.border-warning {
  border-color: var(--warning) !important;
}
.bg-success {
  --background: var(--success);
  background: var(--success) !important;
}
.text-success {
  color: var(--success) !important;
}
.border-success {
  border-color: var(--success) !important;
}
.bg-info {
  --background: var(--info);
  background: var(--info) !important;
}
.text-info {
  color: var(--info) !important;
}
.border-info {
  border-color: var(--info) !important;
}
.bg-primary-pastel-01 {
  background: var(--color-primary-pastel-01) !important;
}
.text-primary-pastel-01 {
  color: var(--color-primary-pastel-01) !important;
}
.border-primary-pastel-01 {
  border-color: var(--color-primary-pastel-01) !important;
}
.bg-primary-pastel-02 {
  background: var(--color-primary-pastel-02) !important;
}
.text-primary-pastel-02 {
  color: var(--color-primary-pastel-02) !important;
}
.border-primary-pastel-02 {
  border-color: var(--color-primary-pastel-02) !important;
}
.bg-primary-lighten-01 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-primary-lighten-01) !important;
}
.text-primary-lighten-01 {
  color: var(--color-primary-lighten-01) !important;
}
.border-primary-lighten-01 {
  border-color: var(--color-primary-lighten-01) !important;
}
.bg-primary-lighten-02 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-primary-lighten-02) !important;
}
.text-primary-lighten-02 {
  color: var(--color-primary-lighten-02) !important;
}
.border-primary-lighten-02 {
  border-color: var(--color-primary-lighten-02) !important;
}
.bg-primary-default {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-primary-default) !important;
}
.text-primary-default {
  color: var(--color-primary-default) !important;
}
.border-primary-default {
  border-color: var(--color-primary-default) !important;
}
.bg-primary-darken-01 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-primary-darken-01) !important;
}
.text-primary-darken-01 {
  color: var(--color-primary-darken-01) !important;
}
.border-primary-darken-01 {
  border-color: var(--color-primary-darken-01) !important;
}
.bg-primary-darken-02 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-primary-darken-02) !important;
}
.text-primary-darken-02 {
  color: var(--color-primary-darken-02) !important;
}
.border-primary-darken-02 {
  border-color: var(--color-primary-darken-02) !important;
}
.bg-secondary-01 {
  background: var(--color-secondary-01) !important;
}
.text-secondary-01 {
  color: var(--color-secondary-01) !important;
}
.border-secondary-01 {
  border-color: var(--color-secondary-01) !important;
}
.bg-secondary-02 {
  background: var(--color-secondary-02) !important;
}
.text-secondary-02 {
  color: var(--color-secondary-02) !important;
}
.border-secondary-02 {
  border-color: var(--color-secondary-02) !important;
}
.bg-secondary-03 {
  background: var(--color-secondary-03) !important;
}
.text-secondary-03 {
  color: var(--color-secondary-03) !important;
}
.border-secondary-03 {
  border-color: var(--color-secondary-03) !important;
}
.bg-secondary-04 {
  background: var(--color-secondary-04) !important;
}
.text-secondary-04 {
  color: var(--color-secondary-04) !important;
}
.border-secondary-04 {
  border-color: var(--color-secondary-04) !important;
}
.bg-secondary-05 {
  background: var(--color-secondary-05) !important;
}
.text-secondary-05 {
  color: var(--color-secondary-05) !important;
}
.border-secondary-05 {
  border-color: var(--color-secondary-05) !important;
}
.bg-secondary-06 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-secondary-06) !important;
}
.text-secondary-06 {
  color: var(--color-secondary-06) !important;
}
.border-secondary-06 {
  border-color: var(--color-secondary-06) !important;
}
.bg-secondary-07 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-secondary-07) !important;
}
.text-secondary-07 {
  color: var(--color-secondary-07) !important;
}
.border-secondary-07 {
  border-color: var(--color-secondary-07) !important;
}
.bg-secondary-08 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-secondary-08) !important;
}
.text-secondary-08 {
  color: var(--color-secondary-08) !important;
}
.border-secondary-08 {
  border-color: var(--color-secondary-08) !important;
}
.bg-secondary-09 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-secondary-09) !important;
}
.text-secondary-09 {
  color: var(--color-secondary-09) !important;
}
.border-secondary-09 {
  border-color: var(--color-secondary-09) !important;
}
.bg-highlight {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-highlight) !important;
}
.text-highlight {
  color: var(--color-highlight) !important;
}
.border-highlight {
  border-color: var(--color-highlight) !important;
}
.bg-support-01 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-support-01) !important;
}
.text-support-01 {
  color: var(--color-support-01) !important;
}
.border-support-01 {
  border-color: var(--color-support-01) !important;
}
.bg-support-02 {
  background: var(--color-support-02) !important;
}
.text-support-02 {
  color: var(--color-support-02) !important;
}
.border-support-02 {
  border-color: var(--color-support-02) !important;
}
.bg-support-03 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-support-03) !important;
}
.text-support-03 {
  color: var(--color-support-03) !important;
}
.border-support-03 {
  border-color: var(--color-support-03) !important;
}
.bg-support-04 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-support-04) !important;
}
.text-support-04 {
  color: var(--color-support-04) !important;
}
.border-support-04 {
  border-color: var(--color-support-04) !important;
}
.bg-support-05 {
  background: var(--color-support-05) !important;
}
.text-support-05 {
  color: var(--color-support-05) !important;
}
.border-support-05 {
  border-color: var(--color-support-05) !important;
}
.bg-support-06 {
  background: var(--color-support-06) !important;
}
.text-support-06 {
  color: var(--color-support-06) !important;
}
.border-support-06 {
  border-color: var(--color-support-06) !important;
}
.bg-support-07 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-support-07) !important;
}
.text-support-07 {
  color: var(--color-support-07) !important;
}
.border-support-07 {
  border-color: var(--color-support-07) !important;
}
.bg-support-08 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  background: var(--color-support-08) !important;
}
.text-support-08 {
  color: var(--color-support-08) !important;
}
.border-support-08 {
  border-color: var(--color-support-08) !important;
}
.bg-support-09 {
  background: var(--color-support-09) !important;
}
.text-support-09 {
  color: var(--color-support-09) !important;
}
.border-support-09 {
  border-color: var(--color-support-09) !important;
}
.bg-support-10 {
  background: var(--color-support-10) !important;
}
.text-support-10 {
  color: var(--color-support-10) !important;
}
.border-support-10 {
  border-color: var(--color-support-10) !important;
}
.bg-support-11 {
  background: var(--color-support-11) !important;
}
.text-support-11 {
  color: var(--color-support-11) !important;
}
.border-support-11 {
  border-color: var(--color-support-11) !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-thin,
.text-weight-thin {
  font-weight: 100 !important;
}
.text-extra-light,
.text-weight-extra-light {
  font-weight: 200 !important;
}
.text-light,
.text-weight-light {
  font-weight: 300 !important;
}
.text-regular,
.text-weight-regular {
  font-weight: 400 !important;
}
.text-medium,
.text-weight-medium {
  font-weight: 500 !important;
}
.text-semi-bold,
.text-weight-semi-bold {
  font-weight: 600 !important;
}
.text-bold,
.text-weight-bold {
  font-weight: 700 !important;
}
.text-extra-bold,
.text-weight-extra-bold {
  font-weight: 800 !important;
}
.text-black,
.text-weight-black {
  font-weight: 900 !important;
}
.text-base {
  font-size: var(--font-size-scale-base) !important;
}
.text-down-01 {
  font-size: var(--font-size-scale-down-01) !important;
}
.text-down-02 {
  font-size: var(--font-size-scale-down-02) !important;
}
.text-down-03 {
  font-size: var(--font-size-scale-down-03) !important;
}
.text-up-01 {
  font-size: var(--font-size-scale-up-01) !important;
}
.text-up-02 {
  font-size: var(--font-size-scale-up-02) !important;
}
.text-up-03 {
  font-size: var(--font-size-scale-up-03) !important;
}
.text-up-04 {
  font-size: var(--font-size-scale-up-04) !important;
}
.text-up-05 {
  font-size: var(--font-size-scale-up-05) !important;
}
.text-up-06 {
  font-size: var(--font-size-scale-up-06) !important;
}
.text-up-07 {
  font-size: var(--font-size-scale-up-07) !important;
}
.text-up-08 {
  font-size: var(--font-size-scale-up-08) !important;
}
.text-up-09 {
  font-size: var(--font-size-scale-up-09) !important;
}
.text-up-10 {
  font-size: var(--font-size-scale-up-10) !important;
}
.text-up-11 {
  font-size: var(--font-size-scale-up-11) !important;
}
@media (min-width: 576px) {
  .text-sm-center {
    text-align: center !important;
  }
  .text-sm-justify {
    text-align: justify !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-wrap {
    white-space: normal !important;
  }
  .text-sm-nowrap {
    white-space: nowrap !important;
  }
  .text-sm-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-sm-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
  }
  .text-sm-lowercase {
    text-transform: lowercase !important;
  }
  .text-sm-uppercase {
    text-transform: uppercase !important;
  }
  .text-sm-capitalize {
    text-transform: capitalize !important;
  }
  .text-sm-thin,
  .text-sm-weight-thin {
    font-weight: 100 !important;
  }
  .text-sm-extra-light,
  .text-sm-weight-extra-light {
    font-weight: 200 !important;
  }
  .text-sm-light,
  .text-sm-weight-light {
    font-weight: 300 !important;
  }
  .text-sm-regular,
  .text-sm-weight-regular {
    font-weight: 400 !important;
  }
  .text-sm-medium,
  .text-sm-weight-medium {
    font-weight: 500 !important;
  }
  .text-sm-semi-bold,
  .text-sm-weight-semi-bold {
    font-weight: 600 !important;
  }
  .text-sm-bold,
  .text-sm-weight-bold {
    font-weight: 700 !important;
  }
  .text-sm-extra-bold,
  .text-sm-weight-extra-bold {
    font-weight: 800 !important;
  }
  .text-sm-black,
  .text-sm-weight-black {
    font-weight: 900 !important;
  }
  .text-sm-base {
    font-size: var(--font-size-scale-base) !important;
  }
  .text-sm-down-01 {
    font-size: var(--font-size-scale-down-01) !important;
  }
  .text-sm-down-02 {
    font-size: var(--font-size-scale-down-02) !important;
  }
  .text-sm-down-03 {
    font-size: var(--font-size-scale-down-03) !important;
  }
  .text-sm-up-01 {
    font-size: var(--font-size-scale-up-01) !important;
  }
  .text-sm-up-02 {
    font-size: var(--font-size-scale-up-02) !important;
  }
  .text-sm-up-03 {
    font-size: var(--font-size-scale-up-03) !important;
  }
  .text-sm-up-04 {
    font-size: var(--font-size-scale-up-04) !important;
  }
  .text-sm-up-05 {
    font-size: var(--font-size-scale-up-05) !important;
  }
  .text-sm-up-06 {
    font-size: var(--font-size-scale-up-06) !important;
  }
  .text-sm-up-07 {
    font-size: var(--font-size-scale-up-07) !important;
  }
  .text-sm-up-08 {
    font-size: var(--font-size-scale-up-08) !important;
  }
  .text-sm-up-09 {
    font-size: var(--font-size-scale-up-09) !important;
  }
  .text-sm-up-10 {
    font-size: var(--font-size-scale-up-10) !important;
  }
  .text-sm-up-11 {
    font-size: var(--font-size-scale-up-11) !important;
  }
}
@media (min-width: 992px) {
  .text-md-center {
    text-align: center !important;
  }
  .text-md-justify {
    text-align: justify !important;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-wrap {
    white-space: normal !important;
  }
  .text-md-nowrap {
    white-space: nowrap !important;
  }
  .text-md-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-md-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
  }
  .text-md-lowercase {
    text-transform: lowercase !important;
  }
  .text-md-uppercase {
    text-transform: uppercase !important;
  }
  .text-md-capitalize {
    text-transform: capitalize !important;
  }
  .text-md-thin,
  .text-md-weight-thin {
    font-weight: 100 !important;
  }
  .text-md-extra-light,
  .text-md-weight-extra-light {
    font-weight: 200 !important;
  }
  .text-md-light,
  .text-md-weight-light {
    font-weight: 300 !important;
  }
  .text-md-regular,
  .text-md-weight-regular {
    font-weight: 400 !important;
  }
  .text-md-medium,
  .text-md-weight-medium {
    font-weight: 500 !important;
  }
  .text-md-semi-bold,
  .text-md-weight-semi-bold {
    font-weight: 600 !important;
  }
  .text-md-bold,
  .text-md-weight-bold {
    font-weight: 700 !important;
  }
  .text-md-extra-bold,
  .text-md-weight-extra-bold {
    font-weight: 800 !important;
  }
  .text-md-black,
  .text-md-weight-black {
    font-weight: 900 !important;
  }
  .text-md-base {
    font-size: var(--font-size-scale-base) !important;
  }
  .text-md-down-01 {
    font-size: var(--font-size-scale-down-01) !important;
  }
  .text-md-down-02 {
    font-size: var(--font-size-scale-down-02) !important;
  }
  .text-md-down-03 {
    font-size: var(--font-size-scale-down-03) !important;
  }
  .text-md-up-01 {
    font-size: var(--font-size-scale-up-01) !important;
  }
  .text-md-up-02 {
    font-size: var(--font-size-scale-up-02) !important;
  }
  .text-md-up-03 {
    font-size: var(--font-size-scale-up-03) !important;
  }
  .text-md-up-04 {
    font-size: var(--font-size-scale-up-04) !important;
  }
  .text-md-up-05 {
    font-size: var(--font-size-scale-up-05) !important;
  }
  .text-md-up-06 {
    font-size: var(--font-size-scale-up-06) !important;
  }
  .text-md-up-07 {
    font-size: var(--font-size-scale-up-07) !important;
  }
  .text-md-up-08 {
    font-size: var(--font-size-scale-up-08) !important;
  }
  .text-md-up-09 {
    font-size: var(--font-size-scale-up-09) !important;
  }
  .text-md-up-10 {
    font-size: var(--font-size-scale-up-10) !important;
  }
  .text-md-up-11 {
    font-size: var(--font-size-scale-up-11) !important;
  }
}
@media (min-width: 1280px) {
  .text-lg-center {
    text-align: center !important;
  }
  .text-lg-justify {
    text-align: justify !important;
  }
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-wrap {
    white-space: normal !important;
  }
  .text-lg-nowrap {
    white-space: nowrap !important;
  }
  .text-lg-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-lg-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
  }
  .text-lg-lowercase {
    text-transform: lowercase !important;
  }
  .text-lg-uppercase {
    text-transform: uppercase !important;
  }
  .text-lg-capitalize {
    text-transform: capitalize !important;
  }
  .text-lg-thin,
  .text-lg-weight-thin {
    font-weight: 100 !important;
  }
  .text-lg-extra-light,
  .text-lg-weight-extra-light {
    font-weight: 200 !important;
  }
  .text-lg-light,
  .text-lg-weight-light {
    font-weight: 300 !important;
  }
  .text-lg-regular,
  .text-lg-weight-regular {
    font-weight: 400 !important;
  }
  .text-lg-medium,
  .text-lg-weight-medium {
    font-weight: 500 !important;
  }
  .text-lg-semi-bold,
  .text-lg-weight-semi-bold {
    font-weight: 600 !important;
  }
  .text-lg-bold,
  .text-lg-weight-bold {
    font-weight: 700 !important;
  }
  .text-lg-extra-bold,
  .text-lg-weight-extra-bold {
    font-weight: 800 !important;
  }
  .text-lg-black,
  .text-lg-weight-black {
    font-weight: 900 !important;
  }
  .text-lg-base {
    font-size: var(--font-size-scale-base) !important;
  }
  .text-lg-down-01 {
    font-size: var(--font-size-scale-down-01) !important;
  }
  .text-lg-down-02 {
    font-size: var(--font-size-scale-down-02) !important;
  }
  .text-lg-down-03 {
    font-size: var(--font-size-scale-down-03) !important;
  }
  .text-lg-up-01 {
    font-size: var(--font-size-scale-up-01) !important;
  }
  .text-lg-up-02 {
    font-size: var(--font-size-scale-up-02) !important;
  }
  .text-lg-up-03 {
    font-size: var(--font-size-scale-up-03) !important;
  }
  .text-lg-up-04 {
    font-size: var(--font-size-scale-up-04) !important;
  }
  .text-lg-up-05 {
    font-size: var(--font-size-scale-up-05) !important;
  }
  .text-lg-up-06 {
    font-size: var(--font-size-scale-up-06) !important;
  }
  .text-lg-up-07 {
    font-size: var(--font-size-scale-up-07) !important;
  }
  .text-lg-up-08 {
    font-size: var(--font-size-scale-up-08) !important;
  }
  .text-lg-up-09 {
    font-size: var(--font-size-scale-up-09) !important;
  }
  .text-lg-up-10 {
    font-size: var(--font-size-scale-up-10) !important;
  }
  .text-lg-up-11 {
    font-size: var(--font-size-scale-up-11) !important;
  }
}
@media (min-width: 1600px) {
  .text-xl-center {
    text-align: center !important;
  }
  .text-xl-justify {
    text-align: justify !important;
  }
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-wrap {
    white-space: normal !important;
  }
  .text-xl-nowrap {
    white-space: nowrap !important;
  }
  .text-xl-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-xl-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
  }
  .text-xl-lowercase {
    text-transform: lowercase !important;
  }
  .text-xl-uppercase {
    text-transform: uppercase !important;
  }
  .text-xl-capitalize {
    text-transform: capitalize !important;
  }
  .text-xl-thin,
  .text-xl-weight-thin {
    font-weight: 100 !important;
  }
  .text-xl-extra-light,
  .text-xl-weight-extra-light {
    font-weight: 200 !important;
  }
  .text-xl-light,
  .text-xl-weight-light {
    font-weight: 300 !important;
  }
  .text-xl-regular,
  .text-xl-weight-regular {
    font-weight: 400 !important;
  }
  .text-xl-medium,
  .text-xl-weight-medium {
    font-weight: 500 !important;
  }
  .text-xl-semi-bold,
  .text-xl-weight-semi-bold {
    font-weight: 600 !important;
  }
  .text-xl-bold,
  .text-xl-weight-bold {
    font-weight: 700 !important;
  }
  .text-xl-extra-bold,
  .text-xl-weight-extra-bold {
    font-weight: 800 !important;
  }
  .text-xl-black,
  .text-xl-weight-black {
    font-weight: 900 !important;
  }
  .text-xl-base {
    font-size: var(--font-size-scale-base) !important;
  }
  .text-xl-down-01 {
    font-size: var(--font-size-scale-down-01) !important;
  }
  .text-xl-down-02 {
    font-size: var(--font-size-scale-down-02) !important;
  }
  .text-xl-down-03 {
    font-size: var(--font-size-scale-down-03) !important;
  }
  .text-xl-up-01 {
    font-size: var(--font-size-scale-up-01) !important;
  }
  .text-xl-up-02 {
    font-size: var(--font-size-scale-up-02) !important;
  }
  .text-xl-up-03 {
    font-size: var(--font-size-scale-up-03) !important;
  }
  .text-xl-up-04 {
    font-size: var(--font-size-scale-up-04) !important;
  }
  .text-xl-up-05 {
    font-size: var(--font-size-scale-up-05) !important;
  }
  .text-xl-up-06 {
    font-size: var(--font-size-scale-up-06) !important;
  }
  .text-xl-up-07 {
    font-size: var(--font-size-scale-up-07) !important;
  }
  .text-xl-up-08 {
    font-size: var(--font-size-scale-up-08) !important;
  }
  .text-xl-up-09 {
    font-size: var(--font-size-scale-up-09) !important;
  }
  .text-xl-up-10 {
    font-size: var(--font-size-scale-up-10) !important;
  }
  .text-xl-up-11 {
    font-size: var(--font-size-scale-up-11) !important;
  }
}
.shadow-color {
  box-shadow: var(--rgb-secondary-09);
}
.shadow-none {
  box-shadow: none;
}
.shadow-sm {
  box-shadow: var(--surface-offset-none) var(--surface-offset-sm)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-md {
  box-shadow: var(--surface-offset-none) var(--surface-offset-md)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-lg {
  box-shadow: var(--surface-offset-none) var(--surface-offset-lg)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-xl {
  box-shadow: var(--surface-offset-none) var(--surface-offset-xl)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-sm-inset {
  box-shadow: var(--surface-offset-none) var(--surface-offset-sm-n)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-md-inset {
  box-shadow: var(--surface-offset-none) var(--surface-offset-md-n)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-lg-inset {
  box-shadow: var(--surface-offset-none) var(--surface-offset-lg-n)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-xl-inset {
  box-shadow: var(--surface-offset-none) var(--surface-offset-xl-n)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-sm-up {
  box-shadow: var(--surface-offset-none) var(--surface-offset-sm-n)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-md-up {
  box-shadow: var(--surface-offset-none) var(--surface-offset-md-n)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-lg-up {
  box-shadow: var(--surface-offset-none) var(--surface-offset-lg-n)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-xl-up {
  box-shadow: var(--surface-offset-none) var(--surface-offset-xl-n)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-sm-inset-up {
  box-shadow: var(--surface-offset-none) var(--surface-offset-sm)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-md-inset-up {
  box-shadow: var(--surface-offset-none) var(--surface-offset-md)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-lg-inset-up {
  box-shadow: var(--surface-offset-none) var(--surface-offset-lg)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-xl-inset-up {
  box-shadow: var(--surface-offset-none) var(--surface-offset-xl)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-sm-right {
  box-shadow: var(--surface-offset-sm) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-md-right {
  box-shadow: var(--surface-offset-md) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-lg-right {
  box-shadow: var(--surface-offset-lg) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-xl-right {
  box-shadow: var(--surface-offset-xl) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-sm-inset-right {
  box-shadow: var(--surface-offset-sm-n) var(--surface-offset-none)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-md-inset-right {
  box-shadow: var(--surface-offset-md-n) var(--surface-offset-none)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-lg-inset-right {
  box-shadow: var(--surface-offset-lg-n) var(--surface-offset-none)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-xl-inset-right {
  box-shadow: var(--surface-offset-xl-n) var(--surface-offset-none)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-sm-left {
  box-shadow: var(--surface-offset-sm-n) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-md-left {
  box-shadow: var(--surface-offset-md-n) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-lg-left {
  box-shadow: var(--surface-offset-lg-n) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-xl-left {
  box-shadow: var(--surface-offset-xl-n) var(--surface-offset-none)
    var(--surface-blur-lg)
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-sm-inset-left {
  box-shadow: var(--surface-offset-sm) var(--surface-offset-none)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-md-inset-left {
  box-shadow: var(--surface-offset-md) var(--surface-offset-none)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-lg-inset-left {
  box-shadow: var(--surface-offset-lg) var(--surface-offset-none)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.shadow-xl-inset-left {
  box-shadow: var(--surface-offset-xl) var(--surface-offset-none)
    var(--surface-blur-lg) inset
    rgba(var(--surface-shadow-color), var(--surface-opacity-xs));
}
.layer-0 {
  z-index: var(--z-index-layer-0);
}
.layer-1 {
  z-index: var(--z-index-layer-1);
}
.layer-2 {
  z-index: var(--z-index-layer-2);
}
.layer-3 {
  z-index: var(--z-index-layer-3);
}
.layer-4 {
  z-index: var(--z-index-layer-4);
}
.ease {
  animation-timing-function: ease;
}
.ease-in {
  animation-timing-function: ease-in;
}
.ease-out {
  animation-timing-function: ease-out;
}
.ease-in-out {
  animation-timing-function: ease-in-out;
}
.ease-linear {
  animation-timing-function: linear;
}
.very-fast {
  animation-duration: var(--duration-very-fast);
}
.fast {
  animation-duration: var(--duration-fast);
}
.moderate {
  animation-duration: var(--duration-moderate);
}
.slow {
  animation-duration: var(--duration-slow);
}
.very-slow {
  animation-duration: var(--duration-very-slow);
}
.fa,
.fab,
.fad,
.fal,
.far,
.fas,
.svg-inline--fa {
  --icon-size: var(--icon-size-base);
  display: inline-block;
  font-size: var(--icon-size);
  text-align: center;
  width: calc(var(--icon-size) + 8px);
}
.svg-inline--fa.fa-w-1,
.svg-inline--fa.fa-w-10,
.svg-inline--fa.fa-w-11,
.svg-inline--fa.fa-w-12,
.svg-inline--fa.fa-w-13,
.svg-inline--fa.fa-w-14,
.svg-inline--fa.fa-w-15,
.svg-inline--fa.fa-w-16,
.svg-inline--fa.fa-w-17,
.svg-inline--fa.fa-w-18,
.svg-inline--fa.fa-w-19,
.svg-inline--fa.fa-w-2,
.svg-inline--fa.fa-w-20,
.svg-inline--fa.fa-w-3,
.svg-inline--fa.fa-w-4,
.svg-inline--fa.fa-w-5,
.svg-inline--fa.fa-w-6,
.svg-inline--fa.fa-w-7,
.svg-inline--fa.fa-w-8,
.svg-inline--fa.fa-w-9 {
  width: calc(var(--icon-size) + 8px);
}
.fa-xs,
.svg-inline--fa.fa-xs {
  --icon-size: var(--icon-size-xs);
}
.fa-sm,
.svg-inline--fa.fa-sm {
  --icon-size: var(--icon-size-sm);
}
.fa-lg,
.svg-inline--fa.fa-lg {
  --icon-size: var(--icon-size-lg);
  vertical-align: -0.125em;
}
.fa-2x,
.svg-inline--fa.fa-2x {
  --icon-size: var(--icon-size-2x);
}
.fa-3x,
.svg-inline--fa.fa-3x {
  --icon-size: var(--icon-size-3x);
}
.fa-4x,
.svg-inline--fa.fa-4x {
  --icon-size: var(--icon-size-4x);
}
.fa-5x,
.svg-inline--fa.fa-5x {
  --icon-size: var(--icon-size-5x);
}
.fa-6x,
.svg-inline--fa.fa-6x {
  --icon-size: var(--icon-size-6x);
}
.fa-7x,
.svg-inline--fa.fa-7x {
  --icon-size: var(--icon-size-7x);
}
.fa-8x,
.svg-inline--fa.fa-8x {
  --icon-size: var(--icon-size-8x);
}
.fa-9x,
.svg-inline--fa.fa-9x {
  --icon-size: var(--icon-size-9x);
}
.fa-10x,
.svg-inline--fa.fa-10x {
  --icon-size: var(--icon-size-10x);
}
.highlight {
  background: var(--status-highlight-background)
    linear-gradient(
      var(--status-highlight-overlay),
      var(--status-highlight-overlay)
    );
  color: var(--status-highlight-text);
  padding: 0 var(--spacing-scale-half);
}
.disabled,
[disabled] {
  cursor: not-allowed;
  opacity: var(--disabled);
}
.disabled *,
[disabled] * {
  pointer-events: none;
}
.dragged {
  background: var(--status-dragged-background);
  box-shadow: 0 3px 6px var(--status-dragged-shadow);
  color: var(--status-dragged-text);
}
[data-toggle="dropdown"] + * {
  position: absolute;
}
.dropdown {
  position: relative;
}
.dropdown [data-toggle="dropdown-legacy"] + *,
.dropdown [data-toggle="dropdown"] + * {
  box-shadow: var(--surface-shadow-sm);
  left: 0;
  position: absolute;
  top: 100%;
}
.dropdown > .br-notification {
  left: auto;
  right: 0;
}
.dropdown .br-item {
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --text-color: var(--color-light);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--color-rgb);
  --visited: var(--visited-light);
  --hover: var(--hover-light);
  --pressed: var(--pressed-light);
  --focus-color: var(--focus-color-light);
  --focus: var(--focus-color-light);
  --item-padding-y: var(--spacing-scale-2x);
  background-color: var(--background-light);
  color: var(--color);
}
.dropdown .br-item:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}
.dropdown .br-item.selected {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.template-base {
  display: flex;
  flex-direction: column;
  //min-height: 100vh;
}
.br-accordion {
  background: var(--bg-color);
  border-top: 1px solid var(--color-secondary-04);
}
.br-accordion .item {
  border-bottom: 1px solid var(--color-secondary-04);
  display: flex;
  flex-direction: column;
}
.br-accordion .item[active] {
  border-bottom: 0;
}
.br-accordion .item[active] .header {
  font-weight: var(--font-weight-semi-bold);
}
.br-accordion .item[active] + .content {
  border-bottom: 1px solid var(--color-secondary-04);
  display: block;
}
.br-accordion .header {
  --bg-color: transparent;
  background: var(--bg-color);
  border: 0;
  color: var(--interactive);
  display: flex;
  font-size: var(--font-size-scale-up-01);
  justify-content: space-between;
  outline: none;
  padding: var(--spacing-scale-2x) 0;
  text-align: left;
  text-decoration: none;
  width: 100%;
}
.br-accordion .header .icon {
  margin: 0 var(--spacing-scale-2x) 0 var(--spacing-scale-baseh);
}
.br-accordion .header .title {
  flex: 1;
  margin: 0;
}
.br-accordion .header:focus {
  outline: none;
}
.br-accordion .header.focus-visible,
.br-accordion .header:focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.br-accordion .header:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-accordion .content {
  color: var(--text-color);
  display: none;
  font-size: var(--font-size-scale-base);
  margin: 0 var(--spacing-scale-base);
  padding: var(--spacing-scale-base) var(--spacing-scale-8x)
    var(--spacing-scale-2x);
}
.br-accordion .content :last-child {
  margin-bottom: 0;
}
.br-accordion[negative] {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  --bg-color: var(--background-dark);
}
.br-avatar {
  --avatar-size: var(--avatar-small);
  --avatar-icon-size: var(--avatar-icon-small);
  --avatar-text-size: var(--avatar-text-small);
  --avatar-small: 40px;
  --avatar-medium: 100px;
  --avatar-large: 160px;
  --avatar-icon-small: var(--icon-size-2x);
  --avatar-icon-medium: var(--icon-size-5x);
  --avatar-icon-large: var(--icon-size-8x);
  --avatar-text-small: var(--font-size-scale-up-03);
  --avatar-text-medium: var(--font-size-scale-up-07);
  --avatar-text-large: var(--font-size-scale-up-11);
  background-color: transparent;
  border: 0;
  font-weight: var(--font-weight-bold);
  line-height: var(--avatar-size);
  vertical-align: middle;
}
.br-avatar,
.br-avatar .content {
  align-items: center;
  display: inline-flex;
  font-size: var(--avatar-text-size);
  justify-content: center;
}
.br-avatar .content {
  background-color: var(--blue-10);
  border-radius: 50%;
  color: var(--blue-warm-20);
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
}
.br-avatar .content,
.br-avatar .content img {
  height: var(--avatar-size);
  vertical-align: inherit;
  width: var(--avatar-size);
}
.br-avatar .content .fa,
.br-avatar .content .fab,
.br-avatar .content .fad,
.br-avatar .content .fal,
.br-avatar .content .far,
.br-avatar .content .fas,
.br-avatar .content .svg-inline--fa {
  --icon-size: var(--avatar-icon-size);
  margin-top: 0.25em;
}
.br-avatar.is-small,
.br-avatar.small,
.br-avatar[small] {
  --avatar-size: var(--avatar-small);
  --avatar-icon-size: var(--avatar-icon-small);
  --avatar-text-size: var(--avatar-text-small);
}
.br-avatar.is-medium,
.br-avatar.medium,
.br-avatar[medium] {
  --avatar-size: var(--avatar-medium);
  --avatar-icon-size: var(--avatar-icon-medium);
  --avatar-text-size: var(--avatar-text-medium);
}
.br-avatar.is-large,
.br-avatar.large,
.br-avatar[large] {
  --avatar-size: var(--avatar-large);
  --avatar-icon-size: var(--avatar-icon-large);
  --avatar-text-size: var(--avatar-text-large);
}
.br-avatar-action {
  background: transparent;
  border: 0;
}
.br-avatar-action:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-avatar-action:focus {
  outline: none;
}
.br-avatar-action.focus-visible,
.br-avatar-action:focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.br-avatar[data-toggle="dropdown"]:focus {
  outline: none;
}
.br-avatar[data-toggle="dropdown"].focus-visible,
.br-avatar[data-toggle="dropdown"]:focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.br-avatar .image,
.br-avatar .letter {
  align-items: center;
  background-color: var(--blue-10);
  border-radius: 50%;
  color: var(--blue-warm-20);
  display: inline-flex;
  font-size: var(--avatar-text-size);
  justify-content: center;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
}
.br-avatar .image,
.br-avatar .image img,
.br-avatar .letter,
.br-avatar .letter img {
  height: var(--avatar-size);
  vertical-align: inherit;
  width: var(--avatar-size);
}
.br-avatar .image .fa,
.br-avatar .image .fab,
.br-avatar .image .fad,
.br-avatar .image .fal,
.br-avatar .image .far,
.br-avatar .image .fas,
.br-avatar .image .svg-inline--fa,
.br-avatar .letter .fa,
.br-avatar .letter .fab,
.br-avatar .letter .fad,
.br-avatar .letter .fal,
.br-avatar .letter .far,
.br-avatar .letter .fas,
.br-avatar .letter .svg-inline--fa {
  --icon-size: var(--avatar-icon-size);
  margin-top: 0.25em;
}
.br-breadcrumb {
  display: inline-grid;
  font-size: var(--font-size-scale-down-01);
  font-weight: var(--font-weight-medium);
  position: relative;
}
.br-breadcrumb .crumb-list {
  align-items: center;
  border: 0;
  display: flex;
  list-style: none;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
}
.br-breadcrumb .crumb {
  align-items: center;
  display: flex;
  height: var(--spacing-scale-5x);
  margin: auto 0 auto -5px;
}
.br-breadcrumb .crumb .icon {
  color: var(--border-color);
  font-size: var(--icon-size-sm);
  margin-right: -6px;
}
.br-breadcrumb .crumb a {
  max-width: 180px;
  overflow: hidden;
  padding: 0 6px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.br-breadcrumb .crumb:last-child span {
  font-weight: var(--font-weight-medium);
  padding: 0 0 0 var(--spacing-scale-base);
  white-space: nowrap;
}
.br-breadcrumb .home,
.br-breadcrumb .menu-mobil {
  --focus-offset: calc(var(--spacing-scale-half) * -1);
  margin-left: 0;
  margin-right: -3px;
}
@media (max-width: 991px) {
  .br-breadcrumb .crumb a:not(.br-button) {
    display: block;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .br-breadcrumb .home + .crumb,
  .br-breadcrumb .menu-mobil,
  .br-breadcrumb .menu-mobil + .crumb {
    display: flex;
  }
}
.br-breadcrumb .br-card {
  left: var(--spacing-scale-9x);
  min-width: fit-content;
  position: absolute;
  top: var(--spacing-scale-7x);
  z-index: var(--z-index-layer-1);
}
.br-breadcrumb .br-item {
  color: var(--color);
  cursor: pointer;
  padding: 0;
}
.br-breadcrumb .br-item:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}
.br-breadcrumb .br-item a {
  --interactive: var(--color);
  --interactive-rgb: var(--color-rgb);
  display: block;
  padding: var(--spacing-scale-2x);
}
@media (max-width: 575px) {
  .br-breadcrumb .menu-mobil > .icon {
    display: none;
  }
  .br-breadcrumb .br-card {
    left: var(--spacing-scale-base);
    width: 250px;
  }
}
.br-button {
  --button-radius: 100em;
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;
  --button-size: var(--button-medium);
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: var(--button-radius) !important;
  color: var(--interactive);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-semi-bold);
  height: var(--button-size);
  justify-content: center;
  overflow: hidden;
  padding: 0 var(--spacing-scale-3x);
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}
.br-button.block {
  width: 100%;
}
@media (min-width: 576px) {
  .br-button.block-sm {
    width: 100%;
  }
  .br-button.auto-sm {
    width: auto;
  }
}
@media (min-width: 992px) {
  .br-button.block-md {
    width: 100%;
  }
  .br-button.auto-md {
    width: auto;
  }
}
@media (min-width: 1280px) {
  .br-button.block-lg {
    width: 100%;
  }
  .br-button.auto-lg {
    width: auto;
  }
}
@media (min-width: 1600px) {
  .br-button.block-xl {
    width: 100%;
  }
  .br-button.auto-xl {
    width: auto;
  }
}
.br-button.circle,
.br-button.is-circle,
.br-button[circle] {
  border-radius: 50%;
  padding: 0;
  width: var(--button-size);
}
.br-button.is-xsmall,
.br-button.xsmall,
.br-button[xsmall] {
  --button-size: var(--button-xsmall);
}
.br-button.is-small,
.br-button.small,
.br-button[small] {
  --button-size: var(--button-small);
}
.br-button.is-medium,
.br-button.medium,
.br-button[medium] {
  --button-size: var(--button-medium);
}
.br-button.is-large,
.br-button.large,
.br-button[large] {
  --button-size: var(--button-large);
}
.br-button.is-primary,
.br-button.primary,
.br-button[primary] {
  --interactive-rgb: var(--color-dark-rgb);
  background-color: var(--interactive-light);
  color: var(--color-dark);
}
.br-button.is-secondary,
.br-button.secondary,
.br-button[secondary] {
  background-color: var(--background-light);
  border: 1px solid var(--interactive);
}
.br-button.danger,
.br-button.is-danger,
.br-button[danger] {
  --interactive-rgb: var(--color-dark-rgb);
  background-color: var(--danger);
  color: var(--color-dark);
}
.br-button.is-success,
.br-button.success,
.br-button[success] {
  --interactive-rgb: var(--color-dark-rgb);
  background-color: var(--success);
  color: var(--color-dark);
}
.br-button.is-warning,
.br-button.warning,
.br-button[warning] {
  --interactive-rgb: var(--color-light-rgb);
  background-color: var(--warning);
  color: var(--color-light);
}
.br-button.info,
.br-button.is-info,
.br-button[info] {
  --interactive-rgb: var(--color-dark-rgb);
  background-color: var(--info);
  color: var(--color-dark);
}
.br-button:disabled {
  cursor: not-allowed;
}
.br-button:not(:disabled) {
  --focus-offset: var(--spacing-scale-half);
}
.br-button:not(:disabled):focus {
  outline: none;
}
.br-button:not(:disabled).focus-visible,
.br-button:not(:disabled):focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.br-button:not(:disabled):not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-button:not(:disabled):not(:disabled):active {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--pressed)),
    rgba(var(--interactive-rgb), var(--pressed))
  );
}
.br-button.active,
.br-button.is-active,
.br-button[active] {
  --hover: var(--hover-dark);
  background-color: var(--active);
  color: var(--color-dark);
}
.br-button.is-loading:after,
.br-button.loading:after,
.br-button[loading]:after {
  border-color: var(--interactive) var(--interactive) transparent;
  border-style: solid;
}
.br-button.is-loading.danger:after,
.br-button.is-loading.info:after,
.br-button.is-loading.primary:after,
.br-button.is-loading.success:after,
.br-button.loading.danger:after,
.br-button.loading.info:after,
.br-button.loading.primary:after,
.br-button.loading.success:after,
.br-button[loading].danger:after,
.br-button[loading].info:after,
.br-button[loading].primary:after,
.br-button[loading].success:after {
  border-color: var(--background) var(--background) transparent;
}
.br-button.dark-mode,
.br-button.inverted,
.br-button.is-inverted,
.br-button[inverted] {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  color: var(--interactive-dark);
}
.br-button.dark-mode.is-primary,
.br-button.dark-mode.primary,
.br-button.dark-mode[primary],
.br-button.inverted.is-primary,
.br-button.inverted.primary,
.br-button.inverted[primary],
.br-button.is-inverted.is-primary,
.br-button.is-inverted.primary,
.br-button.is-inverted[primary],
.br-button[inverted].is-primary,
.br-button[inverted].primary,
.br-button[inverted][primary] {
  --interactive-rgb: var(--background-dark-rgb);
  background-color: var(--interactive-dark);
  color: var(--background-dark);
}
.br-button.dark-mode.is-secondary,
.br-button.dark-mode.secondary,
.br-button.dark-mode[secondary],
.br-button.inverted.is-secondary,
.br-button.inverted.secondary,
.br-button.inverted[secondary],
.br-button.is-inverted.is-secondary,
.br-button.is-inverted.secondary,
.br-button.is-inverted[secondary],
.br-button[inverted].is-secondary,
.br-button[inverted].secondary,
.br-button[inverted][secondary] {
  background-color: var(--background-dark);
}
.br-button.dark-mode.active,
.br-button.inverted.active,
.br-button.is-inverted.active,
.br-button[inverted].active {
  --hover: var(--hover-light);
  --interactive-rgb: var(--active-rgb);
  background-color: var(--background-light);
  color: var(--active);
}
a.br-button {
  font-weight: var(--font-weight-semi-bold);
  text-decoration: none !important;
  color: var(--interactive);
}
.br-card {
  --card-padding: var(--spacing-scale-2x);
  --card-height-fixed: 250px;
  background: var(--background);
  box-shadow: var(--surface-shadow-sm);
  color: var(--color);
  margin-bottom: var(--spacing-scale-2x);
}
.br-card .card-content,
.br-card .front .content {
  padding: var(--card-padding);
}
.br-card .card-content :last-child,
.br-card .front .content :last-child {
  margin-bottom: 0;
}
.br-card .card-header,
.br-card .front .header {
  padding: var(--card-padding) var(--card-padding) 0;
}
.br-card .card-footer,
.br-card .front .footer {
  padding: 0 var(--card-padding) var(--card-padding);
}
.br-card.h-fixed .card-content {
  max-height: var(--card-height-fixed);
  overflow-y: auto;
}
.br-card.h-fixed .card-content::-webkit-scrollbar {
  height: var(--spacing-scale-base);
  width: var(--spacing-scale-base);
}
.br-card.h-fixed .card-content::-webkit-scrollbar-track {
  background: var(--gray-10);
}
.br-card.h-fixed .card-content::-webkit-scrollbar-thumb {
  background: var(--gray-30);
}
.br-card.h-fixed .card-content:hover::-webkit-scrollbar-thumb {
  background: var(--gray-40);
}
.br-card.h-fixed .card-footer {
  padding-top: var(--card-padding);
}
.br-card.hover:hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
.br-card.dark-mode,
.br-card.inverted {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.br-carousel {
  --carousel-button-margin: var(--spacing-scale-default);
  --carousel-buton-icon-size: var(--–icon-size-base);
  --carousel-step-height: 40px;
  --carousel-step-progress-min-height: 32px;
  --carousel-full-size: 100%;
  --carousel-min-height: 230px;
  --carousel-button-fix-height: 52px;
  --carousel-button-fix-width: 100px;
  --carousel-step-progress-background: transparent;
  display: flex;
  height: var(--carousel-full-size);
  min-height: var(--carousel-min-height);
  position: relative;
  width: var(--carousel-full-size);
}
.br-carousel.show-desktop {
  display: none;
}
.br-carousel .carousel-stage {
  display: block;
  flex-wrap: nowrap;
  height: var(--carousel-step-height);
  overflow: hidden;
  position: relative;
  width: var(--carousel-full-width);
  z-index: 0;
}
.br-carousel .carousel-stage .carousel-page {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: var(--carousel-full-size);
  margin-bottom: var(--spacing-scale-4x);
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: var(--carousel-full-size);
}
.br-carousel .carousel-stage .carousel-page,
.br-carousel .carousel-stage .carousel-page[active] {
  height: var(--carousel-full-size);
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: ease-in-out;
}
.br-carousel .carousel-stage .carousel-page[active] {
  left: 0 !important;
  opacity: 1;
  visibility: visible;
}
.br-carousel .carousel-stage .carousel-page .carousel-content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: var(--carousel-full-size);
  justify-content: center;
  width: var(--carousel-full-size);
}
.br-carousel .carousel-stage .carousel-page .carousel-content .carousel-title {
  position: absolute;
}
.br-carousel .carousel-step {
  bottom: 8px;
  height: var(--carousel-step-height);
  position: absolute;
  width: var(--carousel-full-size);
}
.br-carousel .carousel-step .br-step[data-type="simple"] .step-progress,
.br-carousel .carousel-step .br-step[data-type="text"] .step-progress {
  background: var(--carousel-step-progress-background);
  min-height: var(--carousel-step-progress-min-height);
}
.br-carousel
  .carousel-step
  .br-step[data-type="simple"]
  .step-progress
  .step-progress-btn,
.br-carousel
  .carousel-step
  .br-step[data-type="text"]
  .step-progress
  .step-progress-btn {
  top: 0;
}
.br-carousel
  .carousel-step
  .br-step[data-type="simple"]
  .step-progress
  .step-progress-btn:hover:not([disabled]):before,
.br-carousel
  .carousel-step
  .br-step[data-type="text"]
  .step-progress
  .step-progress-btn:hover:not([disabled]):before {
  background-image: none;
}
.br-carousel
  .carousel-step
  .br-step[data-type="text"]
  .step-progress
  .step-progress-btn {
  cursor: default;
  margin: 0;
}
.br-carousel
  .carousel-step
  .br-step[data-type="text"]
  .step-progress
  .step-progress-btn.focus-visible:before {
  outline-color: transparent !important;
}
.br-carousel .carousel-button {
  align-items: center;
  display: flex;
  height: var(--carousel-full-size);
  left: unset;
  position: absolute;
  right: 0;
  width: fit-content;
  z-index: 1;
}
.br-carousel .carousel-button:first-child {
  left: 0;
  right: unset;
}
.br-carousel .carousel-button .carousel-btn-next,
.br-carousel .carousel-button .carousel-btn-prev {
  margin: var(--carousel-button-margin);
  padding: 0;
}
.br-carousel .carousel-button .carousel-btn-next i.fas,
.br-carousel .carousel-button .carousel-btn-prev i.fas {
  font-size: var(--carousel-buton-icon-size);
}
.br-carousel[data-stage="in"] .carousel-button {
  height: var(--carousel-full-size);
}
.br-carousel[data-stage="in"] .carousel-button .carousel-btn-next,
.br-carousel[data-stage="in"] .carousel-button .carousel-btn-prev {
  border-radius: 0;
  height: var(--carousel-full-size);
  margin: 0;
  padding: 0 var(--spacing-scale-default);
}
.br-carousel[data-stage="in"] .carousel-stage {
  height: var(--carousel-full-size);
  left: 0;
  width: var(--carousel-full-size);
}
.br-carousel .carousel-button,
.br-carousel .carousel-stage,
.br-carousel[data-stage="out"] .carousel-button,
.br-carousel[data-stage="out"] .carousel-stage {
  height: calc(var(--carousel-full-size) - var(--carousel-button-fix-height));
}
.br-carousel .carousel-stage,
.br-carousel[data-stage="out"] .carousel-stage {
  left: 50px;
  width: calc(var(--carousel-full-size) - var(--carousel-button-fix-width));
}
.br-carousel[data-stage="hibh"] .carousel-button {
  height: var(--carousel-full-size);
}
.br-carousel[data-stage="hibh"] .carousel-stage {
  height: var(--carousel-full-size);
  left: 50px;
  width: calc(var(--carousel-full-size) - var(--carousel-button-fix-width));
}
.br-carousel[data-stage="hibw"] .carousel-button {
  height: calc(var(--carousel-full-size) - var(--carousel-button-fix-height));
}
.br-carousel[data-stage="hibw"] .carousel-button .carousel-btn-next,
.br-carousel[data-stage="hibw"] .carousel-button .carousel-btn-prev {
  border-radius: 0;
  height: var(--carousel-full-size);
  margin: 0;
  padding: 0 var(--spacing-scale-default);
}
.br-carousel[data-stage="hibw"] .carousel-stage {
  height: calc(var(--carousel-full-size) - var(--carousel-button-fix-height));
  left: 0;
  width: var(--carousel-full-size);
}
@media (max-width: 991px) {
  .br-carousel {
    max-width: var(--carousel-full-size);
    width: var(--carousel-full-size);
  }
  .br-carousel .carousel-button {
    height: calc(var(--carousel-full-size) - var(--carousel-button-fix-height));
  }
  .br-carousel .carousel-button .carousel-btn-next,
  .br-carousel .carousel-button .carousel-btn-prev {
    border-radius: 0;
    display: none;
    height: var(--carousel-full-size);
    margin: 0;
    padding: 0 var(--spacing-scale-default);
  }
  .br-carousel[data-mobile-nav] .carousel-button .carousel-btn-next,
  .br-carousel[data-mobile-nav] .carousel-button .carousel-btn-prev {
    display: flex;
  }
  .br-carousel .carousel-stage {
    height: calc(var(--carousel-full-size) - var(--carousel-button-fix-height));
    left: 0;
    width: var(--carousel-full-size);
  }
  .br-carousel .carousel-step .br-step[data-type="simple"] .step-progress,
  .br-carousel .carousel-step .br-step[data-type="text"] .step-progress {
    padding-top: 0 !important;
  }
}
@media (min-width: 360px) {
  .br-carousel {
    --carousel-min-height: 260px;
  }
}
@media (min-width: 400px) {
  .br-carousel {
    --carousel-min-height: 280px;
  }
}
@media (min-width: 768px) {
  .br-carousel {
    --carousel-min-height: 480px;
  }
}
@media (min-width: 1280px) {
  .br-carousel.show-mobile {
    display: none;
  }
  .br-carousel.show-desktop {
    display: flex;
  }
  .br-carousel {
    --carousel-min-height: 465px;
  }
  .br-carousel .carousel-stage,
  .br-carousel[data-stage="out"] .carousel-stage {
    left: 0;
    width: var(--carousel-full-size);
  }
}
@media (min-width: 1600px) {
  .br-carousel {
    --carousel-min-height: 595px;
  }
}
.br-carousel.dark-mode,
.br-carousel.dark-mode .br-step,
.br-carousel.inverted,
.br-carousel.inverted .br-step {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.br-carousel.dark-mode .br-step .step-progress-btn[data-alert="success"],
.br-carousel.dark-mode
  .br-step
  .step-progress-btn[data-alert="success"]
  .step-info,
.br-carousel.inverted .br-step .step-progress-btn[data-alert="success"],
.br-carousel.inverted
  .br-step
  .step-progress-btn[data-alert="success"]
  .step-info {
  color: var(--success-alternative);
}
.br-carousel.dark-mode
  .br-step
  .step-progress-btn[data-alert="success"]
  .step-alert:after,
.br-carousel.inverted
  .br-step
  .step-progress-btn[data-alert="success"]
  .step-alert:after {
  content: var(--step-alert-success-icon);
}
.br-carousel.dark-mode .br-step .step-progress-btn[data-alert="info"],
.br-carousel.dark-mode
  .br-step
  .step-progress-btn[data-alert="info"]
  .step-info,
.br-carousel.inverted .br-step .step-progress-btn[data-alert="info"],
.br-carousel.inverted
  .br-step
  .step-progress-btn[data-alert="info"]
  .step-info {
  color: var(--info-alternative);
}
.br-carousel.dark-mode
  .br-step
  .step-progress-btn[data-alert="info"]
  .step-alert:after,
.br-carousel.inverted
  .br-step
  .step-progress-btn[data-alert="info"]
  .step-alert:after {
  content: var(--step-alert-info-icon);
}
.br-carousel.dark-mode
  .br-step
  .step-progress-btn[data-alert="warning"]
  .step-alert:after,
.br-carousel.inverted
  .br-step
  .step-progress-btn[data-alert="warning"]
  .step-alert:after {
  content: var(--step-alert-warning-icon);
}
.br-carousel.dark-mode .br-step .step-progress-btn[data-alert="danger"],
.br-carousel.dark-mode
  .br-step
  .step-progress-btn[data-alert="danger"]
  .step-info,
.br-carousel.inverted .br-step .step-progress-btn[data-alert="danger"],
.br-carousel.inverted
  .br-step
  .step-progress-btn[data-alert="danger"]
  .step-info {
  color: var(--danger-alternative);
}
.br-carousel.dark-mode
  .br-step
  .step-progress-btn[data-alert="danger"]
  .step-alert:after,
.br-carousel.inverted
  .br-step
  .step-progress-btn[data-alert="danger"]
  .step-alert:after {
  content: var(--step-alert-danger-icon);
}
.br-carousel.dark-mode .br-step .step-progress-btn.active,
.br-carousel.dark-mode .br-step .step-progress-btn[active],
.br-carousel.inverted .br-step .step-progress-btn.active,
.br-carousel.inverted .br-step .step-progress-btn[active] {
  color: var(--color-dark);
}
.br-carousel.dark-mode .br-step .step-progress-btn.active:before,
.br-carousel.dark-mode .br-step .step-progress-btn[active]:before,
.br-carousel.inverted .br-step .step-progress-btn.active:before,
.br-carousel.inverted .br-step .step-progress-btn[active]:before {
  background-color: var(--background-light);
  border-color: var(--background-light);
  color: var(--active);
}
.br-carousel.dark-mode .br-step .step-progress-btn.active .step-icon,
.br-carousel.dark-mode .br-step .step-progress-btn[active] .step-icon,
.br-carousel.inverted .br-step .step-progress-btn.active .step-icon,
.br-carousel.inverted .br-step .step-progress-btn[active] .step-icon {
  color: var(--active);
}
.br-carousel.dark-mode
  .br-step[data-type="void"]
  .step-progress-btn.active
  .step-alert,
.br-carousel.dark-mode
  .br-step[data-type="void"]
  .step-progress-btn[active]
  .step-alert,
.br-carousel.inverted
  .br-step[data-type="void"]
  .step-progress-btn.active
  .step-alert,
.br-carousel.inverted
  .br-step[data-type="void"]
  .step-progress-btn[active]
  .step-alert {
  border-color: var(--color-dark);
}
.br-carousel.dark-mode .br-step[data-type="text"] .step-progress-btn:before,
.br-carousel.inverted .br-step[data-type="text"] .step-progress-btn:before {
  background-color: transparent;
  color: var(--color);
}
.br-checkbox {
  --checkbox-padding: var(--spacing-scale-base);
  --checkbox-size: 24px;
  display: flex;
}
.br-checkbox + .br-checkbox {
  margin-top: var(--spacing-scale-base);
}
.br-checkbox input {
  margin: 0;
  opacity: 0;
  position: absolute;
}
.br-checkbox input + label {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  min-height: var(--checkbox-size);
  min-width: var(--checkbox-size);
  padding-left: calc(var(--spacing-scale-base) + var(--checkbox-size));
  position: relative;
}
.br-checkbox input + label:before {
  background: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  content: "";
  height: var(--checkbox-size);
  left: 0;
  position: absolute;
  width: var(--checkbox-size);
}
.br-checkbox input + label:empty {
  padding: 0;
}
.br-checkbox label {
  font-weight: var(--font-weight-medium);
}
.br-checkbox.hidden-label label {
  padding-left: calc(var(--checkbox-size) + var(--surface-width-md) * 2);
  text-indent: -10000px;
  white-space: nowrap;
  width: 0;
}
.br-checkbox input:checked + label:after {
  border: solid var(--selected);
  border-width: 0 3px 3px 0;
  content: "";
  height: var(--icon-size-sm);
  left: 8px;
  position: absolute;
  top: 4px;
  transform: rotate(45deg);
  width: 8px;
}
.br-checkbox input[indeterminate] + label:before {
  --interactive-rgb: var(--color-rgb);
  background: var(--selected);
  border-color: var(--selected);
}
.br-checkbox input[indeterminate]:hover:not(:disabled) + label:before {
  --interactive-rgb: var(--color-dark-rgb);
}
.br-checkbox input[indeterminate]:checked + label:after {
  border-color: var(--background-light);
  border-width: 0 0 3px;
  top: 2px;
  transform: none;
}
.br-checkbox.invalid input + label:before,
.br-checkbox.is-invalid input + label:before,
.br-checkbox[invalid] input + label:before {
  --border-color: var(--danger);
}
.br-checkbox.invalid input.focus-visible + label:before,
.br-checkbox.invalid input.focus-visible:checked + label:before,
.br-checkbox.invalid input:focus-visible + label:before,
.br-checkbox.invalid input:focus-visible:checked + label:before,
.br-checkbox.is-invalid input.focus-visible + label:before,
.br-checkbox.is-invalid input.focus-visible:checked + label:before,
.br-checkbox.is-invalid input:focus-visible + label:before,
.br-checkbox.is-invalid input:focus-visible:checked + label:before,
.br-checkbox[invalid] input.focus-visible + label:before,
.br-checkbox[invalid] input.focus-visible:checked + label:before,
.br-checkbox[invalid] input:focus-visible + label:before,
.br-checkbox[invalid] input:focus-visible:checked + label:before {
  --border-color: var(--focus-color);
}
.br-checkbox.invalid input:checked + label:before,
.br-checkbox.is-invalid input:checked + label:before,
.br-checkbox[invalid] input:checked + label:before {
  --border-color: var(--danger);
}
.br-checkbox.is-valid input + label:before,
.br-checkbox.valid input + label:before,
.br-checkbox[valid] input + label:before {
  --border-color: var(--success);
}
.br-checkbox.is-valid input.focus-visible + label:before,
.br-checkbox.is-valid input.focus-visible:checked + label:before,
.br-checkbox.is-valid input:focus-visible + label:before,
.br-checkbox.is-valid input:focus-visible:checked + label:before,
.br-checkbox.valid input.focus-visible + label:before,
.br-checkbox.valid input.focus-visible:checked + label:before,
.br-checkbox.valid input:focus-visible + label:before,
.br-checkbox.valid input:focus-visible:checked + label:before,
.br-checkbox[valid] input.focus-visible + label:before,
.br-checkbox[valid] input.focus-visible:checked + label:before,
.br-checkbox[valid] input:focus-visible + label:before,
.br-checkbox[valid] input:focus-visible:checked + label:before {
  --border-color: var(--focus-color);
}
.br-checkbox.is-valid input:checked + label:before,
.br-checkbox.valid input:checked + label:before,
.br-checkbox[valid] input:checked + label:before {
  --border-color: var(--success);
}
.br-checkbox.is-small input + label,
.br-checkbox.small input + label,
.br-checkbox[small] input + label {
  line-height: var(--spacing-scale-2xh);
  min-height: var(--spacing-scale-2xh);
}
.br-checkbox.is-small input + label:before,
.br-checkbox.small input + label:before,
.br-checkbox[small] input + label:before {
  height: var(--spacing-scale-2xh);
  width: var(--spacing-scale-2xh);
}
.br-checkbox.is-small input:checked + label:after,
.br-checkbox.small input:checked + label:after,
.br-checkbox[small] input:checked + label:after {
  border-width: 0 2px 2px 0;
  height: var(--icon-size-sm);
  left: 7px;
  top: 6px;
  width: 6px;
}
.br-checkbox.is-small input:checked[indeterminate] + label:after,
.br-checkbox.small input:checked[indeterminate] + label:after,
.br-checkbox[small] input:checked[indeterminate] + label:after {
  border-color: var(--background-light);
  border-width: 0 0 3px;
  top: 2px;
  transform: none;
}
.br-checkbox input:invalid + label:before {
  --border-color: var(--danger);
}
.br-checkbox input.focus-visible + label:before,
.br-checkbox input.focus-visible:checked + label:before,
.br-checkbox input:focus-visible + label:before,
.br-checkbox input:focus-visible:checked + label:before {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
  outline: none;
}
.br-checkbox input:hover:not(:disabled) + label:before {
  --interactive-rgb: var(--interactive-light-rgb);
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-checkbox:not(.disabled) input:disabled + label {
  cursor: not-allowed;
  opacity: var(--disabled);
}
.br-checkbox:not(.disabled) input:disabled + label * {
  pointer-events: none;
}
.br-checkbox.dark-mode,
.br-checkbox.dark-mode label,
.br-checkbox.inverted,
.br-checkbox.inverted label {
  color: var(--color-dark);
}
.br-radio {
  --radio-size: 24px;
  --radio-bullet-size: 16px;
  --radio-bullet-position: calc(
    (var(--radio-size) - var(--radio-bullet-size)) * 0.5
  );
  display: block;
}
.br-radio + .br-radio {
  margin-top: var(--spacing-scale-base);
}
.br-radio input {
  opacity: 0;
  position: absolute;
}
.br-radio input + label {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  min-height: var(--radio-size);
  min-width: var(--radio-size);
  padding-left: calc(var(--spacing-scale-base) + var(--radio-size));
  position: relative;
}
.br-radio input + label:before {
  background: var(--background);
  border: 1px solid var(--border-color);
  border-radius: 50%;
  content: "";
  height: var(--radio-size);
  left: 0;
  position: absolute;
  top: -1px;
  width: var(--radio-size);
}
.br-radio input + label:empty {
  padding: 0;
}
.br-radio input + label:empty:before {
  top: 0;
}
.br-radio label {
  font-weight: var(--font-weight-medium);
}
.br-radio input:checked + label:after {
  background: var(--selected);
  border: 7px solid var(--selected);
  border-radius: 50%;
  content: "";
  height: var(--radio-bullet-size);
  left: var(--radio-bullet-position);
  position: absolute;
  right: 0;
  top: calc(var(--radio-bullet-position) - 1px);
  width: var(--radio-bullet-size);
}
.br-radio input:checked + label:empty:after {
  top: var(--radio-bullet-position);
}
.br-radio.is-valid input + label:before,
.br-radio.valid input + label:before,
.br-radio[valid] input + label:before {
  border-color: var(--success);
}
.br-radio.invalid input + label:before,
.br-radio.is-invalid input + label:before,
.br-radio[invalid] input + label:before {
  border-color: var(--danger);
}
.br-radio.is-small input + label,
.br-radio.small input + label,
.br-radio[small] input + label {
  line-height: var(--spacing-scale-2xh);
  min-height: var(--spacing-scale-2xh);
}
.br-radio.is-small input + label:before,
.br-radio.small input + label:before,
.br-radio[small] input + label:before {
  height: var(--spacing-scale-2xh);
  width: var(--spacing-scale-2xh);
}
.br-radio.is-small input:checked + label:after,
.br-radio.small input:checked + label:after,
.br-radio[small] input:checked + label:after {
  border-width: 4px;
  height: 10px;
  width: 10px;
}
.br-radio input:invalid + label:before {
  border-color: var(--danger);
}
.br-radio input.focus-visible + label:before,
.br-radio input.focus-visible:checked + label:before,
.br-radio input:focus-visible + label:before,
.br-radio input:focus-visible:checked + label:before {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
  outline: none;
}
.br-radio input:hover:not(:disabled) + label:before {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-radio.dark-mode,
.br-radio.dark-mode label,
.br-radio.inverted,
.br-radio.inverted label {
  color: var(--color-dark);
}
.br-cookiebar {
  --cookiebar-shadow: var(--surface-shadow-lg-up);
  --cookiebar-layer: var(--z-index-layer-4);
  bottom: 0;
  box-shadow: var(--cookiebar-shadow);
  color: var(--color-secondary-08);
  cursor: default;
  font-family: inherit;
  font-size: var(--font-size-scale-up-01);
  left: 0;
  outline: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--cookiebar-layer);
}
@media (min-width: 576px) {
  .br-cookiebar .actions .br-button.secondary {
    margin-right: var(--spacing-scale-5x);
  }
}
@media (max-width: 575px) {
  .br-cookiebar {
    font-size: var(--font-size-scale-down-01);
  }
  .br-cookiebar .actions .br-button.primary {
    margin-bottom: var(--spacing-scale-2xh);
  }
  .br-cookiebar .actions .br-button.secondary {
    margin-bottom: var(--spacing-scale-2xh);
    margin-right: 0;
  }
  .br-cookiebar .br-modal-header.entry-content .br-modal-title {
    margin-bottom: 0;
  }
  .br-cookiebar .br-modal-header.entry-content .br-modal-title p {
    font-size: var(--font-size-scale-up-02);
  }
  .br-cookiebar .br-modal-header.entry-content .last-update,
  .br-cookiebar .br-switch .switch-data:before,
  .br-cookiebar .br-switch label,
  .br-cookiebar .group-description,
  .br-cookiebar .group-name,
  .br-cookiebar .group-size {
    font-size: var(--font-size-scale-down-01);
  }
}
.br-cookiebar.default {
  top: unset;
}
.br-cookiebar.default .entry-content {
  display: none;
}
.br-cookiebar.default .info-text {
  display: block;
}
.br-cookiebar.default .complementary-content,
.br-cookiebar.default .main-content {
  display: none;
}
.br-cookiebar.default .br-modal > .br-card {
  --card-shadow: var(--surface-offset-none);
}
.br-cookiebar.default .br-modal .wrapper {
  overflow-y: unset;
}
.br-cookiebar.default .actions {
  border-top: 0;
}
.br-cookiebar.default .br-container-fluid.p-2xh {
  padding-bottom: var(--spacing-scale-3x) !important;
}
.br-cookiebar .br-row {
  float: none;
  width: auto;
}
.br-cookiebar .entry-text {
  margin-bottom: 0;
}
.br-cookiebar .wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 6px !important;
}
.br-cookiebar .info-text {
  display: none;
  line-height: var(--font-line-height-medium);
}
.br-cookiebar .br-modal-header .br-modal-title {
  font-size: var(--font-size-scale-up-06);
  margin-bottom: 0;
  margin-right: 0;
  max-height: unset;
  width: auto;
}
.br-cookiebar .br-modal-header .close {
  margin-top: var(--spacing-scale-base);
  opacity: 1;
}
.br-cookiebar .br-modal-header .close:hover:after {
  background: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: var(--hover);
  position: absolute;
  right: 0;
  top: 0;
}
.br-cookiebar .br-modal-header .last-update {
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-semi-bold);
}
.br-cookiebar .br-modal-header .last-update span {
  display: inline-block;
  margin-left: var(--spacing-scale-half);
}
.br-cookiebar .br-modal-body {
  flex: 1;
  margin: 0;
}
.br-cookiebar .br-modal-body:focus {
  outline: none;
}
.br-cookiebar .br-modal-body .br-list .br-list {
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}
.br-cookiebar .br-modal-body .br-list .br-item.open .br-list,
.br-cookiebar .br-modal-body .br-list .br-item.open + .br-list {
  max-height: 200em;
}
.br-cookiebar .br-modal-body .br-list .header {
  display: block;
}
.br-cookiebar .br-modal-body .br-list .header .br-row div:nth-child(2) {
  flex: 0 0 185px;
}
.br-cookiebar .br-modal-footer.actions {
  padding-top: var(--spacing-scale-2xh);
}
.br-cookiebar .cookies-checked,
.br-cookiebar .group-name,
.br-cookiebar .group-size {
  color: var(--color-primary-default);
  cursor: pointer;
}
.br-cookiebar .group-info .br-row div:nth-child(2) {
  flex: 0 0 225px;
}
.br-cookiebar .group-info .fa {
  font-weight: 900;
}
.br-cookiebar .group-info .group-size {
  display: inline-block;
}
.br-cookiebar .group-info.selected {
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --text-color: var(--color-light);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--interactive-light-rgb);
  --visited: var(--visited-light);
  --hover: var(--hover-light);
  --pressed: var(--pressed-light);
  --focus-color: var(--focus-color-light);
  --focus: var(--focus-color-light);
  background-color: var(--background);
}
.br-cookiebar .group-info .br-button {
  color: var(--color-primary-default);
}
.br-cookiebar .cookie-info .br-switch,
.br-cookiebar .group-info .br-switch {
  margin-bottom: var(--spacing-scale-2x);
  margin-top: var(--spacing-scale-2x);
  padding: var(--spacing-scale-half);
  width: 120px;
}
.br-cookiebar .cookie-info .fixed-width,
.br-cookiebar .group-info .fixed-width {
  padding-left: var(--spacing-scale-base);
  width: 100px;
}
.br-cookiebar .always-active {
  color: var(--color-highlight);
  font-weight: var(--font-weight-semi-bold);
}
.br-cookiebar .cookie-info .br-card {
  margin-bottom: var(--card-margin);
}
.br-cookiebar .cookie-info .cookie-term,
.br-cookiebar .notes p:first-child {
  font-weight: var(--font-weight-semi-bold);
}
.br-cookiebar .actions {
  border-top: 1px solid var(--color-secondary-05);
  padding-left: var(--spacing-scale-3x);
  padding-right: var(--spacing-scale-2xh);
  padding-top: var(--spacing-scale-3x);
}
.br-cookiebar .complementary-content {
  padding: var(--spacing-scale-base) 0;
}
.br-cookiebar [class^="container"] {
  --grid-margin: 0;
}
.br-cookiebar .br-checkbox:not(.hidden-label) {
  --item-padding-y: 1px;
}
.br-cookiebar .br-modal {
  --modal-size: var(--modal-auto);
}

.br-h1 {
  font-size: 13px !important;
  font-weight: 800 !important;
  color: #333 !important;
  line-height: normal !important;
  margin-bottom: 5px !important;
}

.br-subtitle {
  font-size: 12px;
}

.br-a {
  display: block;
  font-size: 13px;
  margin-bottom: 12px;
  margin-top: 3px;
  text-decoration: underline;
  font-weight: bold;
  color: #2453ae;
}

.br-group {
  display: block;
  font-size: 12px;
  color: #333;
  font-weight: 600;
  max-width: 300px;
  margin-bottom: 10px;
  cursor: auto;
  padding-left: 8px;
}

.flatpickr-calendar {
  --flatpicker-padding: var(--spacing-scale-2x);
  --datetimepicker-day-size: 24px;
  --datetimepicker-arrows: 32px;
  box-shadow: var(--surface-shadow-md);
}
.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
  border-color: transparent;
}
.flatpickr-calendar.open {
  z-index: var(--z-index-layer-2);
}
.flatpickr-calendar .flatpickr-months {
  align-items: center;
  display: flex;
  padding: var(--flatpicker-padding) var(--flatpicker-padding) 0;
}
.flatpickr-calendar .flatpickr-months .flatpickr-next-month,
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month {
  height: auto;
  padding: 0;
  position: static;
}
.flatpickr-calendar .flatpickr-months .flatpickr-next-month.flatpickr-disabled,
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
  cursor: not-allowed;
  display: block;
  opacity: var(--disabled);
}
.flatpickr-calendar
  .flatpickr-months
  .flatpickr-next-month.flatpickr-disabled
  *,
.flatpickr-calendar
  .flatpickr-months
  .flatpickr-prev-month.flatpickr-disabled
  * {
  pointer-events: none;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month {
  flex: 1;
  height: auto;
  overflow: visible;
}
.flatpickr-calendar .flatpickr-months .flatpickr-current-month {
  display: flex;
  height: auto;
  padding: 0 var(--flatpicker-padding);
  position: static;
  width: 100%;
}
.flatpickr-calendar .flatpickr-months .flatpickr-monthDropdown-months {
  border-radius: var(--surface-rounder-sm);
  color: var(--interactive);
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-semi-bold);
  margin: 0;
  padding: 0;
}
.flatpickr-calendar
  .flatpickr-months
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  color: var(--color);
}
.flatpickr-calendar .flatpickr-months .flatpickr-monthDropdown-months:hover {
  background: transparent;
}
.flatpickr-calendar .flatpickr-months .numInputWrapper {
  flex: 1;
  margin-left: var(--flatpicker-padding);
  width: auto;
}
.flatpickr-calendar .flatpickr-months .numInputWrapper input.cur-year {
  border: var(--surface-width-sm) solid var(--border-color);
  border-radius: var(--surface-rounder-sm);
  color: var(--color);
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-semi-bold);
  padding: 0 var(--spacing-scale-base);
}
.flatpickr-calendar .flatpickr-months .numInputWrapper span.arrowDown,
.flatpickr-calendar .flatpickr-months .numInputWrapper span.arrowUp {
  display: none;
}
.flatpickr-calendar .flatpickr-weekdaycontainer {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 0 var(--flatpicker-padding);
}
.flatpickr-calendar span.flatpickr-weekday {
  color: var(--color);
  font-size: var(--font-size);
  font-weight: var(--font-weight-medium);
}
.flatpickr-calendar .dayContainer {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 0 var(--flatpicker-padding) var(--flatpicker-padding);
}
.flatpickr-calendar .flatpickr-day {
  color: var(--interactive);
  font-weight: var(--font-weight-semi-bold);
  height: var(--datetimepicker-day-size);
  line-height: var(--font-line-height-medium);
  margin: calc(var(--flatpicker-padding) * 0.25) auto;
  max-width: var(--datetimepicker-day-size);
  width: var(--datetimepicker-day-size);
}
.flatpickr-calendar .flatpickr-day:focus {
  outline: none;
}
.flatpickr-calendar .flatpickr-day.focus-visible,
.flatpickr-calendar .flatpickr-day:focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.flatpickr-calendar .flatpickr-day.focus-visible {
  --focus-offset: 0;
  background: transparent;
  border-color: transparent;
}
.flatpickr-calendar .flatpickr-day:hover {
  background: var(--hover-effect);
}
.flatpickr-calendar .flatpickr-day.nextMonthDay,
.flatpickr-calendar .flatpickr-day.prevMonthDay {
  font-weight: var(--font-weight-medium);
  opacity: 0.6;
}
.flatpickr-calendar .flatpickr-day.today {
  background: var(--orange-vivid-5);
  border-color: transparent;
  color: var(--red-warm-vivid-50);
}
.flatpickr-calendar .flatpickr-day.flatpickr-disabled {
  color: var(--color);
  cursor: not-allowed;
  opacity: var(--disabled);
}
.flatpickr-calendar .flatpickr-day.flatpickr-disabled * {
  pointer-events: none;
}
.flatpickr-calendar .flatpickr-day.flatpickr-disabled:hover {
  background: transparent;
}
.flatpickr-calendar .flatpickr-day.inRange {
  --interactive-rgb: var(--blue-warm-vivid-50-rgb);
  --hover: var(--hover-dark);
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
  border-color: transparent;
  box-shadow: none;
  color: var(--color-dark);
  opacity: 1;
}
.flatpickr-calendar .flatpickr-day.endRange,
.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.startRange {
  background: var(--selected);
  border-color: transparent;
  color: var(--color-dark);
  opacity: 1;
}
.flatpickr-calendar .flatpickr-time {
  display: flex;
  justify-content: center;
  margin: 0 var(--flatpicker-padding);
  max-height: none;
}
.flatpickr-calendar .flatpickr-time input {
  --input-background: var(--bg-color);
  --input-border-color: var(--color-secondary-06);
  --input-border-width: 1px;
  --input-border-style: solid;
  --input-padding: 0 var(--spacing-scale-half);
  --input-radius: var(--surface-rounder-sm);
  background: var(--input-background);
  border: var(--input-border-width) var(--input-border-style)
    var(--input-border-color);
  border-radius: var(--input-radius);
  color: var(--text-color);
  display: block;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-medium);
  height: var(--input-size);
  margin: calc(var(--flatpicker-padding) * 0.25) 0;
  padding: var(--input-padding);
  width: 100%;
}
.flatpickr-calendar .flatpickr-time input:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
.flatpickr-calendar .flatpickr-time input.focus-visible,
.flatpickr-calendar .flatpickr-time input:focus,
.flatpickr-calendar .flatpickr-time input:focus-visible {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
  outline: none;
}
.flatpickr-calendar .flatpickr-time input.has-icon {
  padding-right: var(--spacing-scale-5x);
}
.flatpickr-calendar .flatpickr-time input[type="password"]::-ms-clear,
.flatpickr-calendar .flatpickr-time input[type="password"]::-ms-reveal {
  display: none;
}
.flatpickr-calendar .flatpickr-time .numInputWrapper {
  align-items: center;
  display: flex;
  flex: 0 1 90px;
  flex-flow: column;
  height: auto;
  padding: calc(var(--flatpicker-padding) * 0.5) var(--flatpicker-padding);
}
.flatpickr-calendar .flatpickr-time .numInputWrapper:hover {
  background: transparent;
}
.flatpickr-calendar .flatpickr-time .numInputWrapper span {
  align-items: center;
  border: 0;
  border-radius: 50px;
  color: var(--interactive);
  display: flex;
  height: var(--datetimepicker-arrows);
  justify-content: center;
  opacity: 1;
  padding: 0;
  position: static;
  width: var(--datetimepicker-arrows);
}
.flatpickr-calendar .flatpickr-time .numInputWrapper span:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.flatpickr-calendar .flatpickr-time .numInputWrapper span:after {
  border: none;
  position: static;
}
.flatpickr-calendar .flatpickr-time .numInputWrapper span.arrowUp {
  order: -1;
}
.flatpickr-calendar .flatpickr-time .numInputWrapper span.arrowUp:after {
  transform: rotate(225deg) translate(-1px, -1px);
}
.flatpickr-calendar.hasTime .flatpickr-time {
  border-color: var(--border-color);
  height: auto;
}
.flatpickr-calendar.hasTime.noCalendar .flatpickr-time {
  border-color: transparent;
}
.br-datetimepicker.dark-mode .br-input,
.br-datetimepicker.dark-mode .br-input label,
.br-datetimepicker.inverted .br-input,
.br-datetimepicker.inverted .br-input label {
  --color: var(--color-dark);
  --focus-color: var(--focus-color-dark);
}
.br-datetimepicker input[disabled] {
  cursor: not-allowed;
}
.br-divider,
hr {
  --divider-size: var(--surface-width-sm);
  border-color: var(--border-color);
  border-style: solid;
  border-width: 0;
  border-top-width: var(--divider-size);
  display: block;
}
.br-divider.content,
hr.content {
  align-items: center;
  border: 0;
  display: flex;
  justify-content: center;
}
.br-divider.content:after,
.br-divider.content:before,
hr.content:after,
hr.content:before {
  border-color: var(--border-color);
  border-style: solid;
  border-width: 0;
  border-top-width: var(--divider-size);
  content: "";
  flex: 1;
}
.br-divider.content:after,
hr.content:after {
  margin-left: var(--spacing-scale-base);
}
.br-divider.content:before,
hr.content:before {
  margin-right: var(--spacing-scale-base);
}
.br-divider.vertical,
hr.vertical {
  align-self: stretch;
  border-right-width: var(--divider-size);
  border-top-width: 0;
}
.br-divider.vertical.content,
hr.vertical.content {
  flex-direction: column;
}
.br-divider.vertical.content:after,
.br-divider.vertical.content:before,
hr.vertical.content:after,
hr.vertical.content:before {
  border-right-width: var(--divider-size);
  border-top-width: 0;
  margin: 0;
}
.br-divider.dark-mode,
.br-divider.inverted,
hr.dark-mode,
hr.inverted {
  --border-color: var(--pure-0);
}
.br-divider.dashed,
hr.dashed {
  border-style: dashed;
}
.br-divider.sm,
hr.sm {
  --divider-size: var(--surface-width-sm);
}
.br-divider.md,
hr.md {
  --divider-size: var(--surface-width-md);
}
.br-divider.lg,
hr.lg {
  --divider-size: var(--surface-width-lg);
}
hr {
  --divider-padding: var(--spacing-scale-2x);
  margin: var(--divider-padding) 0;
}

.footer{
  margin-top: auto;
}

.br-footer {
  --footer-logo-height: 48px;
  --footer-logo-width: 180px;
  --footer-logo-padding: var(--spacing-scale-6x) 0 var(--spacing-scale-4x);
  --footer-assigns-height: 46px;
  --footer-assigns-width: 180px;
  --background: var(--blue-warm-vivid-80);
  background: var(--blue-warm-vivid-80);
  border-top: 1px solid var(--border-color);
  color: var(--color);
  display: block;
}
.br-footer,
.br-footer .br-item {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.br-footer.inverted,
.br-footer[inverted] {
  --background: var(--background-light);
}
.br-footer.inverted,
.br-footer.inverted .br-item,
.br-footer[inverted],
.br-footer[inverted] .br-item {
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --text-color: var(--color-light);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--interactive-light-rgb);
  --visited: var(--visited-light);
  --hover: var(--hover-light);
  --pressed: var(--pressed-light);
  --focus-color: var(--focus-color-light);
  --focus: var(--focus-color-light);
}
.br-footer .logo {
  padding: 2rem;
}
@media (min-width: 991px) {
  .br-footer .logo {
    padding: var(--footer-logo-padding);
  }
}
.br-footer .logo img {
  max-height: 170px;
  max-width: var(--footer-logo-width);
}
.br-footer a {
  color: var(--color);
}
.br-footer .footer-nav {
  text-align: center;
}
.br-footer .footer-nav li {
  display: block;
  padding: 10px 0;
}
.br-footer .footer-nav a {
  display: block;
}
.br-footer .br-decidim-license {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-align: center;
  border-top: 1px solid #303947;
  border-bottom: 1px solid #303947;
  padding: 40px 0;
  margin: 0 30px;
}

.br-footer .cc-badge {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 2px;
}
.br-footer .cclIcon {
  height: 16px;
  width: 16px;
}

.br-footer-url:hover {
  text-decoration: underline;
  color: var(--color-secondary-01);
}
.p-footer {
  color: var(--color-secondary-01);
  padding: 0;
  margin: 0;
}
.br-footer .br-decidim-logo {
  padding: 40px 0;
  text-align: center;
}
.br-footer .br-list.horizontal {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;
}
.br-footer .br-list.horizontal .header {
  width: 100%;
}
.br-footer .br-list.horizontal .br-col,
.br-footer .br-list.horizontal [class*="col-"] {
  flex-grow: 0;
  padding: 0;
}
.mini-footer {
  display: none;
}

.br-footer .br-footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  padding: 16px 0;
  margin: 0 30px;
  flex-wrap: wrap;
}

.br-footer .br-decidim-logo {
  padding: 0;
  text-align: right;
}

@media (max-width: 991px) {
  .br-footer .br-list.horizontal .br-col,
  .br-footer .br-list.horizontal [class*="col-"] {
    flex-basis: 100% !important;
    max-width: 100%;
  }
}
.br-footer .social-network {
  border: 0;
}
.br-footer .social-network .social-network-title {
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-extra-bold);
  margin-bottom: var(--spacing-scale-2x);
  text-transform: uppercase;
}
.br-footer .social-network a:hover {
  background-image: none;
}
.br-footer .social-network img {
  max-height: var(--footer-assigns-height);
  max-width: var(--footer-assigns-height);
}
.br-footer .assigns img {
  max-height: var(--footer-assigns-height);
  max-width: var(--footer-assigns-width);
}
@media (min-width: 992px) {
  .br-footer .info {
    text-align: center;
  }
}
.br-footer .br-list {
  --background: var(--blue-warm-vivid-80);
}
.br-footer .br-list.horizontal .br-item,
.br-footer .br-list.horizontal .br-list .br-item {
  min-height: 4em;
}
.br-footer .br-list.horizontal .br-item .content,
.br-footer .br-list.horizontal .br-list .br-item .content {
  height: auto;
  line-height: 16px;
  padding: 0 var(--spacing-scale-2x);
}
.br-footer .br-item {
  align-items: center;
  display: flex;
}
@media (min-width: 992px) {
  .br-footer .br-item.horizontal .br- {
    background-image: none;
    max-height: 200em !important;
  }
}
.br-footer .br-item.horizontal .br-item {
  min-height: 4em;
}
.br-footer .br-item.horizontal .br-item .content {
  height: auto;
  line-height: 16px;
  padding: 0 var(--spacing-scale-2x);
}
.br-footer .br-item.horizontal .br-col .br-item.header.active,
.br-footer .br-item.horizontal .br-col .br-item.header.active + .br-list,
.br-footer .br-item.horizontal [class*="col-"] .br-item.header.active,
.br-footer
  .br-item.horizontal
  [class*="col-"]
  .br-item.header.active
  + .br-list {
  border-bottom: 1px solid var(--border-color);
}
.br-footer .br-item .content {
  flex: 1;
}
.br-footer .br-item:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
  color: var(--interactive);
}
.br-item.header .support i {
  color: var(--pure-0);
}
.br-footer .br-item.active {
  background: transparent;
}
@media (min-width: 992px) {
  .br-footer .br-item .support:last-child {
    display: none;
    pointer-events: none;
  }
  .br-footer .br-item .content {
    flex: 1;
  }
}
.br-footer.inverted .br-list,
.br-footer[inverted] .br-list {
  --background: var(--background-light);
}
.br-header {
  --header-padding: var(--header-padding-medium);
  --header-padding-small: var(--spacing-scale-base);
  --header-padding-medium: var(--spacing-scale-2x);
  --header-padding-large: var(--spacing-scale-3x);
  --header-logo-size: var(--header-logo-medium);
  --header-logo-small: 16px;
  --header-logo-medium: 24px;
  --header-logo-large: 40px;
  --header-title-size: var(--font-size-scale-base);
  --header-subtitle-size: var(--font-size-scale-down-01);
  --header-logo-desktop-width: 120px;
  --header-logo-desktop-height: 48px;
  --header-logo-compact: 60px;
  --header-logo-compact-height: 32px;
  --header-logo-mobile-width: 80px;
  --header-logo-mobile-height: 40px;
  background: var(--background);
  box-shadow: var(--surface-shadow-sm);
  padding: var(--header-padding) 0;
  position: relative;
}
.br-header,
.br-header .header-bottom,
.br-header .header-top {
  display: flex;
  margin: 0 !important;
}
.br-header .header-top {
  justify-content: flex-end;
  margin-bottom: 10px !important;
}
.br-header .header-bottom {
  justify-content: space-between;
  margin-top: var(--header-padding-small);
}
@media (min-width: 576px) {
  .br-header {
    --header-padding: var(--header-padding-medium);
  }
}
.br-header.small {
  --header-padding: var(--header-padding-small);
}
.br-header.medium {
  --header-padding: var(--header-padding-medium);
}
.br-header.large {
  --header-padding: var(--header-padding-large);
}
.br-header .header-logo {
  align-items: center;
  display: flex;
  flex: 1;
  float: left;
  gap: var(--spacing-scale-2x);
}
.br-header .header-logo img,
.br-header .header-logo svg {
  max-height: var(--header-logo-size);
}
.br-header .header-logo .br-divider {
  display: none;
}
.br-header .header-logo .header-sign {
  color: var(--color);
  display: none;
  font-weight: 500;
  overflow: hidden;
}
.br-header[data-no-logo] .header-logo img,
.br-header[data-no-sign] .header-logo .header-sign {
  display: none;
}
@media (min-width: 576px) {
  .br-header .header-logo {
    --header-logo-size: var(--header-logo-large);
    margin: 0;
  }
  .br-header .header-logo .br-divider,
  .br-header .header-logo .header-sign {
    display: block;
  }
}
.br-header .header-actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}
.br-header .header-actions .dropdown.show .br-list {
  display: block;
  z-index: var(--z-index-layer-1);
}
.br-header .header-actions .dropdown.show .br-button.active .fa,
.br-header .header-actions .dropdown.show .br-button.active .fab,
.br-header .header-actions .dropdown.show .br-button.active .fad,
.br-header .header-actions .dropdown.show .br-button.active .fal,
.br-header .header-actions .dropdown.show .br-button.active .far,
.br-header .header-actions .dropdown.show .br-button.active .fas,
.br-header .header-actions .dropdown.show .br-button.active .svg-inline--fa {
  transform: rotate(180deg);
}
.br-header .header-actions .dropdown.show .br-notification {
  display: block;
  right: -16px;
  width: 100vw;
  z-index: var(--z-index-layer-1);
}
.br-header .header-actions .dropdown.show .br-notification div.close,
.br-header .header-actions .dropdown:not(.show) .br-notification {
  display: none;
}
@media (max-width: 1279px) {
  .br-header .header-actions .dropdown:not(.show) .br-list {
    display: none;
  }
  .br-header .header-actions .dropdown:not(.show) .br-list-dropdown {
    display: block;
  }
}
.br-header .header-actions .header-functions .br-list,
.br-header .header-actions .header-links .br-list {
  background: var(--background);
  left: -4em;
}
.br-header .header-actions .header-functions .br-item,
.br-header .header-actions .header-links .br-item {
  white-space: nowrap;
}
.br-header .header-actions .header-functions ul,
.br-header .header-actions .header-links ul {
  display: none;
}
.br-header .header-actions .header-functions .br-list .br-button {
  --button-size: auto;
  --button-font-size: var(--font-size-scale-base);
  --button-font-weight: var(--item-font-weight);
  border-radius: 0;
  color: var(--color);
  font-size: var(--font-size-scale-base);
  font-weight: var(--item-font-weight);
  justify-content: flex-start;
  padding: var(--item-padding);
  width: 100%;
}
.br-header .header-actions .header-functions .br-list .br-button .text {
  margin-left: var(--spacing-scale-2x);
}
.br-header .header-actions .header-functions .br-item {
  padding: 0;
}
.br-header .header-actions .header-functions ul {
  display: none;
}
.br-header .header-actions .header-login {
  margin-left: var(--spacing-scale-base);
}
.br-header .header-actions .sign-in {
  --button-padding: 0 var(--spacing-scale-2x);
}
.br-header .header-actions .avatar {
  align-items: center;
  display: flex;
}
#avatar-menu {
  z-index: 1;
}
#avatar-dropdown-trigger {
  height: auto;
  padding: var(--spacing-scale-base);
}
.br-header .header-actions .avatar .br-list {
  min-width: 200px;
  right: 0;
  top: 100%;
}
.br-header[data-no-functions] .header-actions .header-functions,
.br-header[data-no-links] .header-actions .header-links,
.br-header[data-no-login] .header-actions .header-login {
  display: none;
}
@media (min-width: 576px) {
  .br-header .header-actions .header-functions .br-list,
  .br-header .header-actions .header-links .br-list {
    background: var(--background);
    left: auto;
    right: 0;
  }
  .br-header .header-actions .header-functions .br-notification,
  .br-header .header-actions .header-links .br-notification {
    width: 100vw;
  }
}
@media (min-width: 1280px) {
  .br-header .header-actions .header-functions [data-toggle="dropdown"],
  .br-header .header-actions .header-links [data-toggle="dropdown"] {
    display: none;
  }
  .br-header .header-actions .header-functions .br-list,
  .br-header .header-actions .header-links .br-list {
    box-shadow: none;
    display: flex;
    margin-right: var(--spacing-scale-2x);
    position: static;
  }
  .br-header .header-actions .header-functions .br-list .header,
  .br-header .header-actions .header-links .br-list .header {
    display: none;
  }
  .br-header .header-actions .header-functions .br-item,
  .br-header .header-actions .header-links .br-item {
    --focus-offset: var(--spacing-scale-half);
    --interactive-rgb: var(--interactive-light-rgb);
    color: var(--interactive-light);
    padding: 0;
  }
  .br-header .header-actions .header-functions .br-item:not(:last-child),
  .br-header .header-actions .header-links .br-item:not(:last-child) {
    border-bottom: 0;
    margin-right: var(--spacing-scale-3x);
  }
  .br-header .header-actions .header-functions .br-notification,
  .br-header .header-actions .header-links .br-notification {
    width: 50vw;
  }
  .br-header .header-actions .header-functions .br-list {
    margin-right: 0;
  }
  .br-header .header-actions .header-functions .br-list .br-button {
    --button-size: var(--button-small);
    border-radius: 50%;
    color: var(--interactive);
    justify-content: center;
    padding: 0;
    width: var(--button-size);
  }
  .br-header .header-actions .header-functions .br-list .br-button .text {
    display: none;
  }
  .br-header .header-actions .header-functions .br-item,
  .br-header .header-actions .header-functions .br-item:not(:last-child) {
    margin-right: var(--spacing-scale-base);
  }
  .br-header .header-actions .header-search-trigger {
    display: none;
  }
}
.br-header .header-menu {
  align-items: center;
  display: flex;
  flex: 1;
}
.br-header .header-menu .header-menu-trigger + .header-info {
  margin-left: var(--spacing-scale-2x);
}
.br-header .header-menu .header-title {
  color: var(--color);
  font-size: var(--header-title-size);
}
.br-header .header-menu .header-subtitle {
  color: var(--color);
  display: none;
  font-size: var(--header-subtitle-size);
  font-weight: 500;
  margin-top: var(--spacing-scale-half);
}
.br-header[data-no-subtitle] .header-menu .header-subtitle {
  display: none;
}
@media (min-width: 576px) {
  .br-header .header-menu {
    --header-title-size: var(--font-size-scale-up-02);
    align-items: flex-start;
  }
  .br-header .header-menu .header-menu-trigger {
    margin-top: 3px;
  }
  .br-header .header-menu .header-info {
    padding-top: var(--spacing-scale-half);
  }
  .br-header .header-menu .header-subtitle {
    display: block;
  }
}
@media (min-width: 1280px) {
  .br-header .header-menu {
    --header-title-size: var(--font-size-scale-up-03);
    --header-subtitle-size: var(--font-size-scale-base);
  }
  .br-header .header-menu .header-info {
    padding-top: 0;
  }
}
.br-header .header-search,
.br-header.compact .header-search {
  align-items: center;
  background: var(--background);
  bottom: 0;
  display: none;
  left: 0;
  padding: 0 var(--spacing-scale-2x);
  position: absolute;
  right: 0;
  top: 0;
}
.br-header .header-search .br-input,
.br-header.compact .header-search .br-input {
  align-items: center;
  display: flex;
  flex: 1;
}
.br-header .header-search .br-input label,
.br-header.compact .header-search .br-input label {
  display: none;
}
.br-header .header-search .br-input input,
.br-header.compact .header-search .br-input input {
  border-color: transparent;
}
.br-header .header-search .br-input input:not(:focus),
.br-header.compact .header-search .br-input input:not(:focus) {
  background: var(--gray-2);
}
.br-header .header-search .br-input .br-button,
.br-header .header-search .br-input .icon,
.br-header.compact .header-search .br-input .br-button,
.br-header.compact .header-search .br-input .icon {
  --focus-offset: 0;
  bottom: auto;
  right: 4px;
  top: auto;
}
.br-header .header-search .search-close,
.br-header.compact .header-search .search-close {
  --focus-offset: calc(var(--spacing-scale-half) * -1);
  display: none;
}
.br-header .header-search.active,
.br-header.compact .header-search.active {
  display: flex;
}
.br-header .header-search.active .search-close,
.br-header.compact .header-search.active .search-close {
  display: inline-flex;
}
.br-header[data-no-search] .header-search {
  display: none;
}
@media (min-width: 1280px) {
  .br-header .header-menu {
    justify-content: flex-start;
  }
  .br-header .header-search {
    display: flex;
    padding: 0;
    position: static;
  }
  .br-header .header-search.active .search-close {
    display: none;
  }
  .br-header .br-input {
    min-width: 385px;
  }
}
.br-header .br-input.has-icon .br-button.circle {
  margin-top: -12px;
  position: absolute;
  right: 4px;
}
button.br-button.circle.search-close.ml-1 {
  margin-top: -10px;
}
.br-header.compact {
  --header-logo-size: var(--header-logo-small);
  --header-padding: var(--header-padding-small);
}
.br-header.compact .header-actions .header-search-trigger {
  display: block;
}
@media (min-width: 576px) {
  .br-header.compact .br-container-lg {
    position: relative;
  }
  .br-header.compact .header-top {
    display: block;
  }
  .br-header.compact .header-logo {
    --header-logo-size: var(--header-logo-small);
    float: left;
  }
  .br-header.compact .header-logo .br-divider,
  .br-header.compact .header-sign {
    display: none;
  }
  .br-header.compact .header-actions {
    float: right;
    margin-top: var(--spacing-scale-2x);
    position: relative;
  }
  .br-header.compact .header-bottom {
    clear: left;
  }
  .br-header.compact .header-menu {
    --header-title-size: var(--font-size-scale-up-01);
  }
  .br-header.compact .header-menu .header-menu-trigger {
    margin-top: 0;
  }
}
@media (min-width: 1280px) {
  .br-header.compact .header-menu {
    --header-title-size: var(--font-size-scale-up-02);
  }
}
.br-header[data-sticky] {
  position: sticky;
  top: 0;
  z-index: var(--z-index-layer-2);
}
.br-header[data-sticky],
.br-header[data-sticky] .header-actions,
.br-header[data-sticky] .header-logo img,
.br-header[data-sticky] .header-menu-trigger,
.br-header[data-sticky] .header-title {
  transition: all 0.15s ease;
}
.br-header[data-sticky] .header-logo img {
  margin-right: var(--spacing-scale-base);
}
.br-input {
  --input-button-margin: var(--spacing-scale-half);
  --input-padding: 0 var(--spacing-scale-2x);
  --input-padding-button: var(--spacing-scale-5x);
  --input-padding-icon: var(--spacing-scale-5x);
  --input-size: var(--input-medium);
  --input-small: 32px;
  --input-medium: 40px;
  --input-large: 48px;
  --input-highlight: 56px;
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --text-color: var(--color-light);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--interactive-light-rgb);
  --visited: var(--visited-light);
  --hover: var(--hover-light);
  --pressed: var(--pressed-light);
  --focus-color: var(--focus-color-light);
  --focus: var(--focus-color-light);
  color: var(--color);
  position: relative;
}
.br-input input {
  background-color: var(--background-light);
  border-color: var(--border-color-alternative);
  border-radius: var(--surface-rounder-md);
  border-style: var(--border-style);
  border-width: var(--border-width);
  color: var(--color-light);
  display: block;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-medium);
  height: var(--input-size);
  margin-top: var(--spacing-scale-half);
  padding-bottom: 0;
  padding-left: var(--spacing-scale-2x);
  padding-right: var(--spacing-scale-2x);
  padding-top: 0;
  width: 100%;
}
.br-input input::-ms-clear,
.br-input input::-ms-reveal,
.br-input input::-webkit-calendar-picker-indicator {
  display: none;
}
.br-input input[type="search"]::-webkit-search-cancel-button,
.br-input input[type="search"]::-webkit-search-decoration,
.br-input input[type="search"]::-webkit-search-results-button,
.br-input input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}
.br-input.input-inline {
  display: flex;
}
.br-input.input-inline .input-label {
  margin-right: var(--spacing-scale-baseh);
  margin-top: calc(var(--input-size) * 0.5 - var(--spacing-scale-half));
}
.br-input.input-inline .input-content {
  flex: 1;
}
.br-input .input-group {
  position: relative;
}
.br-input .input-icon {
  align-items: center;
  color: var(--border-color-alternative);
  display: flex;
  height: var(--input-size);
  margin-left: var(--spacing-scale-baseh);
  position: absolute;
}
.br-input .input-icon + input {
  padding-left: var(--input-padding-icon);
}
.br-input .br-button {
  --button-size: var(--button-small);
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --text-color: var(--color-light);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--interactive-light-rgb);
  --visited: var(--visited-light);
  --hover: var(--hover-light);
  --pressed: var(--pressed-light);
  --focus-color: var(--focus-color-light);
  --focus: var(--focus-color-light);
  border-radius: 50%;
  float: right;
  margin-right: var(--input-button-margin);
  margin-top: calc((var(--input-size) + var(--spacing-scale-half)) * -1);
  padding: 0;
  position: relative;
  transform: translateY(calc((var(--input-size) - var(--button-size)) * 0.5));
  width: var(--button-size);
}
.br-input.input-button input {
  padding-right: var(--input-padding-button);
}
.br-input.has-icon input {
  padding-right: var(--input-padding-icon);
}
.br-input.has-icon .br-button.circle {
  float: right;
  margin-right: var(--spacing-scale-half);
  margin-top: calc(
    (var(--button-size) + var(--spacing-scale-half) + var(--spacing-scale-half)) *
      -1
  );
  transform: translateY(0);
}
.br-input.has-icon.small .br-button.circle {
  margin-top: calc((var(--button-size) + var(--spacing-scale-base)) * -1);
}
.br-input.has-icon.large .br-button.circle {
  margin-top: calc(var(--button-size) * -1);
}
.br-input.input-highlight {
  --input-button-margin: var(--spacing-scale-2x);
  --input-padding: 0 var(--spacing-scale-3x);
  --input-padding-button: var(--spacing-scale-7x);
  --input-padding-icon: var(--spacing-scale-7x);
  --input-size: var(--input-highlight);
}
.br-input.input-highlight input {
  background-color: var(--gray-2);
  border-color: transparent;
  padding-left: var(--spacing-scale-3x);
  padding-right: var(--spacing-scale-3x);
}
.br-input.input-highlight .input-icon {
  margin-left: var(--spacing-scale-3x);
}
.br-input.input-highlight .input-icon + input {
  padding-left: var(--spacing-scale-7x);
}
.br-input .br-list {
  box-shadow: var(--surface-shadow-md);
  max-height: 530px;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: var(--z-index-layer-2);
}
.br-input .br-list::-webkit-scrollbar {
  height: var(--spacing-scale-base);
  width: var(--spacing-scale-base);
}
.br-input .br-list::-webkit-scrollbar-track {
  background: var(--gray-10);
}
.br-input .br-list::-webkit-scrollbar-thumb {
  background: var(--gray-30);
}
.br-input .br-list:hover::-webkit-scrollbar-thumb {
  background: var(--gray-40);
}
.br-input .br-list .br-item {
  --item-padding-y: var(--spacing-scale-2x);
}
.br-input .br-list .br-item:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
.br-input .br-list .br-item:not(:first-child) {
  border-top: var(--surface-width-sm) solid var(--border-color);
}
.br-input.success input,
.br-input[data-success] input {
  border-color: var(--success);
  border-width: 2px;
}
.br-input.danger input,
.br-input[data-danger] input {
  border-color: var(--danger);
  border-width: 2px;
}
.br-input.warning input,
.br-input[data-warning] input {
  border-color: var(--warning);
  border-width: 2px;
}
.br-input.info input,
.br-input[data-info] input {
  border-color: var(--info);
  border-width: 2px;
}
.br-input input:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
.br-input input.focus-visible,
.br-input input:focus,
.br-input input:focus-visible {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
  outline: none;
}
.br-input .feedback {
  margin-bottom: var(--spacing-scale-half);
}
.br-input.small,
.br-input[data-small] {
  --input-size: var(--input-small);
}
.br-input.medium,
.br-input[data-medium] {
  --input-size: var(--input-medium);
}
.br-input.large,
.br-input[data-large] {
  --input-size: var(--input-large);
}
.br-input.dark-mode,
.br-input.dark-mode label,
.br-input.inverted,
.br-input.inverted label {
  --color: var(--color-dark);
  --focus-color: var(--focus-color-dark);
}
.br-item {
  --item-padding-x: var(--spacing-scale-2x);
  --item-padding-y: var(--spacing-scale-base);
  --item-padding: var(--item-padding-y) var(--item-padding-x);
  --focus-offset: calc(var(--focus-width) * -1);
  background: var(--background);
  border: 0;
  color: var(--color);
  display: block;
  font-weight: var(--font-weight);
  padding: var(--item-padding);
  text-align: left;
  width: 100%;
}
.br-item .br-row {
  margin-left: calc(var(--spacing-scale-base) * -1);
  margin-right: calc(var(--spacing-scale-base) * -1);
}
.br-item .br-row .br-col,
.br-item .br-row [class*="col-"] {
  padding-left: var(--spacing-scale-base);
  padding-right: var(--spacing-scale-base);
}
.br-item:after {
  clear: both;
  content: "";
  display: block;
}
[data-toggle] {
  margin: 0 !important;
}
.br-item[data-toggle] {
  cursor: pointer;
}
.br-item[data-toggle="selection"]:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
.br-item .br-checkbox:not(.hidden-label),
.br-item .br-radio:not(.hidden-label) {
  margin: calc(var(--item-padding-y) * -1) calc(var(--item-padding-x) * -1);
}
.br-item .br-checkbox:not(.hidden-label) input + label,
.br-item .br-radio:not(.hidden-label) input + label {
  padding-bottom: var(--item-padding-y);
  padding-top: var(--item-padding-y);
  top: 1px;
  width: 100%;
}
.br-item .br-checkbox:not(.hidden-label) input + label:before,
.br-item .br-radio:not(.hidden-label) input + label:before {
  left: var(--item-padding-x);
  top: calc(var(--item-padding-y) - 1px);
}
.br-item .br-checkbox:not(.hidden-label) input + label:empty,
.br-item .br-radio:not(.hidden-label) input + label:empty {
  left: 0;
  top: 1px;
}
.br-item
  .br-checkbox:not(.hidden-label)
  input:hover:not(:disabled)
  + label:before,
.br-item
  .br-radio:not(.hidden-label)
  input:hover:not(:disabled)
  + label:before {
  background-image: none;
}
.br-item .br-checkbox input + label {
  min-height: calc(var(--checkbox-size) + var(--item-padding-y) * 2);
}
.br-item .br-checkbox input + label:not(:empty) {
  padding-left: calc(
    var(--checkbox-padding) + var(--checkbox-size) + var(--item-padding-x)
  );
}
.br-item .br-checkbox input + label:after {
  left: calc(var(--item-padding-x) + 8px);
  top: calc(var(--item-padding-y) + 4px);
}
.br-item .br-checkbox input + label:empty {
  min-height: var(--checkbox-size);
}
.br-item .br-checkbox input + label:empty:after {
  left: 8px;
  top: 4px;
}
.br-item .br-radio input + label {
  min-height: calc(var(--radio-size) + var(--item-padding-y) * 2);
  padding-left: calc(
    var(--spacing-scale-base) + var(--radio-size) + var(--item-padding-x)
  );
}
.br-item .br-radio input + label:after {
  left: calc(var(--item-padding-x) + var(--radio-bullet-position));
  top: calc(var(--item-padding-y) + var(--radio-bullet-position) - 1px);
}
.br-item .br-radio input + label:empty {
  min-height: var(--radio-size);
}
.br-item .br-radio input + label:empty:after {
  left: var(--radio-bullet-position);
  top: var(--radio-bullet-position);
}
.br-item.highlight,
.br-item.primary-darken-01,
.br-item.primary-darken-02,
.br-item.primary-default,
.br-item.primary-lighten-01,
.br-item.primary-lighten-02,
.br-item.secondary-06,
.br-item.secondary-07,
.br-item.secondary-08,
.br-item.secondary-09,
.br-item.selected,
.br-item.support-01,
.br-item.support-03,
.br-item.support-04,
.br-item.support-07,
.br-item.support-08 {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.br-item.selected {
  background-color: var(--selected);
}
.br-item.active {
  background-color: var(--active);
  color: var(--color);
}
.br-item.active,
.br-item.dark-mode,
.br-item.inverted {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.br-item.dark-mode.active,
.br-item.inverted.active {
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --text-color: var(--color-light);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--active-rgb);
  --visited: var(--visited-light);
  --hover: var(--hover-light);
  --pressed: var(--pressed-light);
  --focus-color: var(--focus-color-light);
  --focus: var(--focus-color-light);
  background-color: var(--background-light);
  color: var(--active);
}
.br-item[data-toggle]:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
.br-item[data-toggle] .content i {
  color: var(--interactive);
}
.br-item .content {
  align-items: center;
  display: flex;
}
.br-item [class*="fa-"] {
  height: 16px;
  width: 14px !important;
  color: var(--blue-warm-vivid-70);
}
.br-item .toogle-icon-interactive,
a.br-item {
  color: var(--interactive);
}
a.br-item:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
a.br-item:not(:disabled):active {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--pressed)),
    rgba(var(--interactive-rgb), var(--pressed))
  );
}
button.br-item {
  background-color: transparent;
  color: var(--color);
}
button.br-item:focus {
  outline: none;
}
button.br-item.focus-visible,
button.br-item:focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
button.br-item:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
button.br-item:not(:disabled):active {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--pressed)),
    rgba(var(--color-rgb), var(--pressed))
  );
}
.br-list {
  background-color: var(--background);
}
.br-list.horizontal {
  display: flex;
  flex-wrap: wrap;
}
.br-list.horizontal > .header {
  flex-grow: 1;
  width: 100%;
}
.br-list.horizontal > .header + .br-divider {
  border-top: 1px solid var(--border-color);
  width: 100%;
}
.br-list.horizontal > .br-item,
.br-list.horizontal > .group {
  flex: 1;
  height: auto;
  min-height: 56px;
  width: auto;
}
.br-list.horizontal > .br-item + .br-divider,
.br-list.horizontal > .group + .br-divider {
  border-width: 0 1px 0 0;
  width: 1px;
}
.br-list.horizontal > .br-item.one-line > .content,
.br-list.horizontal > .group.one-line > .content {
  height: 56px;
}
.br-list.horizontal > .br-item.two-lines > .content,
.br-list.horizontal > .group.two-lines > .content {
  height: 72px;
}
.br-list.horizontal > .br-item.three-lines > .content,
.br-list.horizontal > .group.three-lines > .content {
  height: 6em;
}
.br-list.horizontal.toggle > .br-item,
.br-list.horizontal[data-toggle] > .br-item {
  display: block;
}
.br-list .br-item {
  text-align: left;
  width: 100%;
}
.br-list .br-item .fa,
.br-list .br-item .fab,
.br-list .br-item .fad,
.br-list .br-item .fal,
.br-list .br-item .far,
.br-list .br-item .fas,
.br-list .br-item .svg-inline--fa {
  text-align: center;
}
.br-list .br-item > .content {
  align-items: var(--spacing-vertical-top);
  display: flex;
}
.br-list .br-item > .content > .content {
  flex: 1;
}
.br-list .br-item .content:hover button.br-button {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.search-icon {
  width: 32px;
  height: 32px;
}
.br-list .br-item[data-toggle] {
  cursor: pointer;
}
.br-list .br-item[data-toggle] ~ .br-list .br-item {
  padding: var(--spacing-scale-base) var(--spacing-scale-3x);
}
.br-list .br-list {
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}
.br-list .header {
  align-items: var(--spacing-vertical-center);
  display: flex;
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-bold);
  justify-content: space-between;
  padding: var(--spacing-scale-base) var(--spacing-scale-2x);
}
.br-list[data-one-line] .br-item,
.br-list[data-one-line] .br-list .br-item {
  height: 2em;
}
.br-list[data-one-line] .br-item .content,
.br-list[data-one-line] .br-list .br-item .content {
  height: auto;
  overflow: hidden;
  padding: 0 var(--spacing-scale-2x);
  text-overflow: unset;
  white-space: normal;
}
.br-list[data-two-lines] .br-item,
.br-list[data-two-lines] .br-list .br-item {
  height: 4em;
}
.br-list[data-two-lines] .br-item .content,
.br-list[data-two-lines] .br-list .br-item .content {
  height: auto;
  overflow: hidden;
  padding: 0 var(--spacing-scale-2x);
  text-overflow: unset;
  white-space: normal;
}
.br-list[data-three-lines] .br-item,
.br-list[data-three-lines] .br-list .br-item {
  height: 6em;
}
.br-list[data-three-lines] .br-item .content,
.br-list[data-three-lines] .br-list .br-item .content {
  height: auto;
  overflow: hidden;
  padding: 0 var(--spacing-scale-2x);
  text-overflow: unset;
  white-space: normal;
}
.br-menu {
  --menu-zindex: var(--z-index-layer-3);
  --menu-background-submenu: var(--gray-2);
  --menu-logo: 40px;
  --menu-item-ng: var(--menu-item-medium);
  --menu-item-padding: var(--menu-item-medium);
  --menu-item-small: var(--spacing-scale-base);
  --menu-item-medium: var(--spacing-scale-2x);
  --menu-item-large: var(--spacing-scale-3x);
}
.br-menu .menu-container {
  display: none;
  background-color: transparent !important;
}
.br-menu .menu-scrim {
  background: var(--surface-overlay-scrim) !important;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.br-menu .menu-panel {
  --focus-offset: calc(var(--spacing-scale-half) * -1);
  background: var(--background);
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.br-menu.active .menu-container {
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--menu-zindex);
}
.br-menu.active .menu-panel {
  flex: 1;
  overflow: auto;
  position: relative;
  z-index: calc(var(--menu-zindex) + 1);
}
.br-menu.active .menu-scrim {
  position: absolute;
}
.br-menu .menu-header {
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  padding: var(--spacing-scale-base) var(--spacing-scale-base);
}
.br-menu .menu-header .menu-title {
  align-items: center;
  display: flex;
  flex: 1;
  margin-left: var(--spacing-scale-base);
}
.br-menu .menu-header .menu-title img {
  max-height: var(--menu-logo);
}
.br-menu .menu-header .menu-title img + * {
  margin-left: var(--spacing-scale-base);
}
.br-menu .menu-body li,
.br-menu .menu-body ul {
  margin: 0;
  padding: 0;
}
.br-menu .menu-body ul {
  list-style: none;
}
.br-menu .menu-body .menu-folder > ul > li:last-child > .menu-item,
.br-menu .menu-body > .divider {
  border-bottom: 1px solid var(--border-color);
}
.br-menu .menu-body .menu-folder > .menu-item + ul {
  background: var(--menu-background-submenu);
}
.br-menu .menu-body .menu-folder > .menu-item + ul .menu-item {
  padding-left: var(--spacing-scale-5x);
}
.br-menu .menu-body .drop-menu > .menu-item {
  border-bottom: 1px solid var(--border-color);
}
.br-menu .menu-body .drop-menu.active > .menu-item {
  border-bottom: 0;
}
.br-menu .menu-body .drop-menu > ul {
  background: var(--menu-background-submenu);
  display: none;
}
.br-menu .menu-body .drop-menu.active > .menu-item:not(:hover) {
  background-color: var(--background);
}
.br-menu .menu-body .drop-menu.active > .menu-item .support .fa,
.br-menu .menu-body .drop-menu.active > .menu-item .support .fab,
.br-menu .menu-body .drop-menu.active > .menu-item .support .fad,
.br-menu .menu-body .drop-menu.active > .menu-item .support .fal,
.br-menu .menu-body .drop-menu.active > .menu-item .support .far,
.br-menu .menu-body .drop-menu.active > .menu-item .support .fas,
.br-menu .menu-body .drop-menu.active > .menu-item .support .svg-inline--fa {
  transform: rotate(180deg);
}
.br-menu .menu-body .drop-menu.active > ul {
  display: block;
}
.br-menu .menu-footer > :not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}
.br-menu .menu-footer .menu-logos {
  column-gap: var(--spacing-scale-2x);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: var(--spacing-scale-base) var(--spacing-scale-5x);
  row-gap: var(--spacing-scale-2x);
}
.br-menu .menu-footer .menu-links {
  --focus-offset: var(--spacing-scale-half);
  padding: var(--spacing-scale-2x) var(--spacing-scale-5x);
}
.br-menu .menu-footer .menu-links a {
  display: block;
}
.br-menu .menu-footer .menu-links a:not(:last-child) {
  margin-bottom: var(--spacing-scale-2x);
}
.br-menu .menu-footer .menu-social,
.br-menu .menu-footer .social-network {
  padding: var(--spacing-scale-base) var(--spacing-scale-5x);
}
.br-menu .menu-footer .menu-social .social-network-title,
.br-menu .menu-footer .social-network .social-network-title {
  font-weight: var(--font-weight-semi-bold);
  margin-bottom: var(--spacing-scale-half);
}
.br-menu .menu-footer .menu-social .sharegroup,
.br-menu .menu-footer .social-network .sharegroup {
  display: flex;
}
.br-menu .menu-footer .menu-info {
  padding: var(--spacing-scale-2x);
}
.br-menu .menu-item {
  --focus-offset: calc(var(--spacing-scale-half) * -1);
  background: transparent;
  display: flex;
  padding: var(--menu-item-padding) var(--spacing-scale-2x);
}
.br-menu .menu-item .icon {
  margin-right: var(--spacing-scale-2x);
}
.br-menu .menu-item .content {
  flex: 1;
}
.br-menu a.menu-item {
  color: var(--interactive);
}
.br-menu .side-menu > ul {
  background: var(--menu-background-submenu);
  display: none;
}
.br-menu .side-menu.active > .menu-item {
  --interactive: var(--active);
  --interactive-rgb: var(--active-rgb);
  background-color: var(--background);
  flex-direction: row-reverse;
  font-weight: var(--font-weight-semi-bold);
}
.br-menu .side-menu.active > .menu-item .icon {
  margin-right: 0;
}
.br-menu .side-menu.active > .menu-item .support {
  margin-right: var(--spacing-scale-2x);
}
.br-menu .side-menu.active > .menu-item .support .fa,
.br-menu .side-menu.active > .menu-item .support .fab,
.br-menu .side-menu.active > .menu-item .support .fad,
.br-menu .side-menu.active > .menu-item .support .fal,
.br-menu .side-menu.active > .menu-item .support .far,
.br-menu .side-menu.active > .menu-item .support .fas,
.br-menu .side-menu.active > .menu-item .support .svg-inline--fa {
  transform: rotate(180deg);
}
.br-menu .side-menu.active > ul {
  display: block;
}
.br-menu .side-menu.active .menu-item {
  padding-left: var(--spacing-scale-2x) !important;
}
.br-menu.small .menu-item {
  --menu-item-padding: var(--menu-item-small);
}
.br-menu.medium .menu-item {
  --menu-item-padding: var(--menu-item-medium);
}
.br-menu.large .menu-item {
  --menu-item-padding: var(--menu-item-large);
}
.br-menu.push .menu-scrim,
.br-menu.push .menu-trigger {
  display: none;
}
@media (min-width: 576px) {
  .br-menu.push {
    --menu-zindex: var(--z-index-layer-0);
  }
  .br-menu.push .menu-header {
    display: none;
  }
  .br-menu.push .menu-panel {
    height: auto;
  }
  .br-menu.push .menu-scrim {
    display: none;
  }
  .br-menu.push.active .menu-container {
    position: static;
  }
}
.br-menu.contextual {
  --menu-zindex: calc(var(--z-index-layer-3) - 10);
  overflow: auto;
}
.br-menu.contextual .menu-body .menu-folder > .menu-item + ul .menu-item {
  padding-left: var(--spacing-scale-2x);
}
.br-menu.contextual .menu-body .menu-folder > ul > li:last-child > .menu-item {
  border-bottom: 0;
}
.br-menu.contextual .menu-trigger {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: calc(var(--menu-zindex) - 1);
}
.br-menu.contextual .menu-trigger .br-button {
  --button-radius: 0;
}
.br-menu.contextual .menu-container {
  display: none;
  flex-direction: column-reverse;
  position: static;
}
.br-menu.contextual .menu-panel {
  height: auto;
}
.br-menu.contextual.active {
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  z-index: var(--menu-zindex);
}
.br-menu.contextual.active .menu-trigger {
  position: relative;
  z-index: calc(var(--menu-zindex) + 1);
}
.br-menu.contextual.active .menu-trigger .fa,
.br-menu.contextual.active .menu-trigger .fab,
.br-menu.contextual.active .menu-trigger .fad,
.br-menu.contextual.active .menu-trigger .fal,
.br-menu.contextual.active .menu-trigger .far,
.br-menu.contextual.active .menu-trigger .fas,
.br-menu.contextual.active .menu-trigger .svg-inline--fa {
  transform: rotate(180deg);
}
.br-menu.contextual.active .menu-container {
  display: flex;
  overflow: auto;
}
@media (min-width: 992px) {
  .br-menu.contextual .menu-trigger {
    position: absolute;
  }
  .br-menu.contextual.active {
    height: 100%;
    position: absolute;
  }
}
.br-menu .menu-item[hidden] {
  display: none;
}
.br-menu .menu-item:disabled {
  cursor: not-allowed;
  opacity: var(--disabled);
}
.br-menu .menu-item:disabled * {
  pointer-events: none;
}
.br-menu a.menu-item:not(:disabled).active {
  --interactive: var(--color-dark);
  --interactive-rgb: var(--color-dark-rgb);
  background-color: var(--active);
}
.br-menu a.menu-item:not(:disabled).active:focus {
  outline-color: var(--focus-color-dark);
}
.br-menu a.menu-item:not(:disabled):not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-loading {
  --loading-indetermined-color: var(--interactive);
  --loading-indetermined-diameter-md: 44px;
  --loading-indetermined-diameter-sm: 24px;
  --loading-diameter: 84px;
  background-color: var(--color-secondary-04);
  border-radius: 50%;
  height: var(--loading-diameter);
  width: var(--loading-diameter);
  z-index: var(--z-index-layer-4);
}
.br-loading-fill,
.br-loading-mask {
  clip: rect(0, 84px, 84px, 42px);
  backface-visibility: hidden;
  border-radius: 50%;
  height: 84px;
  overflow: hidden;
  position: absolute;
  width: 84px;
}
.br-loading-fill {
  clip: rect(0, 42px, 84px, 0);
  background: var(--interactive);
}
.br-loading:after {
  background: var(--color-secondary-01);
  border-radius: 50%;
  color: var(--interactive);
  content: attr(data-progress) "%";
  font-size: var(--font-size-scale-up-02);
  font-weight: var(--font-weight-semi-bold);
  height: 74px;
  line-height: 74px;
  margin: 5px;
  position: absolute;
  text-align: center;
  width: 74px;
}
@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
}
.br-loading[data-progress="1"] .br-loading-fill,
.br-loading[data-progress="1"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(1.8deg);
}
.br-loading[data-progress="2"] .br-loading-fill,
.br-loading[data-progress="2"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(3.6deg);
}
.br-loading[data-progress="3"] .br-loading-fill,
.br-loading[data-progress="3"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(5.4deg);
}
.br-loading[data-progress="4"] .br-loading-fill,
.br-loading[data-progress="4"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(7.2deg);
}
.br-loading[data-progress="5"] .br-loading-fill,
.br-loading[data-progress="5"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(9deg);
}
.br-loading[data-progress="6"] .br-loading-fill,
.br-loading[data-progress="6"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(10.8deg);
}
.br-loading[data-progress="7"] .br-loading-fill,
.br-loading[data-progress="7"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(12.6deg);
}
.br-loading[data-progress="8"] .br-loading-fill,
.br-loading[data-progress="8"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(14.4deg);
}
.br-loading[data-progress="9"] .br-loading-fill,
.br-loading[data-progress="9"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(16.2deg);
}
.br-loading[data-progress="10"] .br-loading-fill,
.br-loading[data-progress="10"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(18deg);
}
.br-loading[data-progress="11"] .br-loading-fill,
.br-loading[data-progress="11"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(19.8deg);
}
.br-loading[data-progress="12"] .br-loading-fill,
.br-loading[data-progress="12"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(21.6deg);
}
.br-loading[data-progress="13"] .br-loading-fill,
.br-loading[data-progress="13"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(23.4deg);
}
.br-loading[data-progress="14"] .br-loading-fill,
.br-loading[data-progress="14"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(25.2deg);
}
.br-loading[data-progress="15"] .br-loading-fill,
.br-loading[data-progress="15"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(27deg);
}
.br-loading[data-progress="16"] .br-loading-fill,
.br-loading[data-progress="16"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(28.8deg);
}
.br-loading[data-progress="17"] .br-loading-fill,
.br-loading[data-progress="17"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(30.6deg);
}
.br-loading[data-progress="18"] .br-loading-fill,
.br-loading[data-progress="18"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(32.4deg);
}
.br-loading[data-progress="19"] .br-loading-fill,
.br-loading[data-progress="19"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(34.2deg);
}
.br-loading[data-progress="20"] .br-loading-fill,
.br-loading[data-progress="20"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(36deg);
}
.br-loading[data-progress="21"] .br-loading-fill,
.br-loading[data-progress="21"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(37.8deg);
}
.br-loading[data-progress="22"] .br-loading-fill,
.br-loading[data-progress="22"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(39.6deg);
}
.br-loading[data-progress="23"] .br-loading-fill,
.br-loading[data-progress="23"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(41.4deg);
}
.br-loading[data-progress="24"] .br-loading-fill,
.br-loading[data-progress="24"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(43.2deg);
}
.br-loading[data-progress="25"] .br-loading-fill,
.br-loading[data-progress="25"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(45deg);
}
.br-loading[data-progress="26"] .br-loading-fill,
.br-loading[data-progress="26"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(46.8deg);
}
.br-loading[data-progress="27"] .br-loading-fill,
.br-loading[data-progress="27"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(48.6deg);
}
.br-loading[data-progress="28"] .br-loading-fill,
.br-loading[data-progress="28"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(50.4deg);
}
.br-loading[data-progress="29"] .br-loading-fill,
.br-loading[data-progress="29"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(52.2deg);
}
.br-loading[data-progress="30"] .br-loading-fill,
.br-loading[data-progress="30"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(54deg);
}
.br-loading[data-progress="31"] .br-loading-fill,
.br-loading[data-progress="31"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(55.8deg);
}
.br-loading[data-progress="32"] .br-loading-fill,
.br-loading[data-progress="32"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(57.6deg);
}
.br-loading[data-progress="33"] .br-loading-fill,
.br-loading[data-progress="33"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(59.4deg);
}
.br-loading[data-progress="34"] .br-loading-fill,
.br-loading[data-progress="34"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(61.2deg);
}
.br-loading[data-progress="35"] .br-loading-fill,
.br-loading[data-progress="35"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(63deg);
}
.br-loading[data-progress="36"] .br-loading-fill,
.br-loading[data-progress="36"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(64.8deg);
}
.br-loading[data-progress="37"] .br-loading-fill,
.br-loading[data-progress="37"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(66.6deg);
}
.br-loading[data-progress="38"] .br-loading-fill,
.br-loading[data-progress="38"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(68.4deg);
}
.br-loading[data-progress="39"] .br-loading-fill,
.br-loading[data-progress="39"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(70.2deg);
}
.br-loading[data-progress="40"] .br-loading-fill,
.br-loading[data-progress="40"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(72deg);
}
.br-loading[data-progress="41"] .br-loading-fill,
.br-loading[data-progress="41"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(73.8deg);
}
.br-loading[data-progress="42"] .br-loading-fill,
.br-loading[data-progress="42"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(75.6deg);
}
.br-loading[data-progress="43"] .br-loading-fill,
.br-loading[data-progress="43"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(77.4deg);
}
.br-loading[data-progress="44"] .br-loading-fill,
.br-loading[data-progress="44"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(79.2deg);
}
.br-loading[data-progress="45"] .br-loading-fill,
.br-loading[data-progress="45"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(81deg);
}
.br-loading[data-progress="46"] .br-loading-fill,
.br-loading[data-progress="46"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(82.8deg);
}
.br-loading[data-progress="47"] .br-loading-fill,
.br-loading[data-progress="47"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(84.6deg);
}
.br-loading[data-progress="48"] .br-loading-fill,
.br-loading[data-progress="48"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(86.4deg);
}
.br-loading[data-progress="49"] .br-loading-fill,
.br-loading[data-progress="49"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(88.2deg);
}
.br-loading[data-progress="50"] .br-loading-fill,
.br-loading[data-progress="50"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(90deg);
}
.br-loading[data-progress="51"] .br-loading-fill,
.br-loading[data-progress="51"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(91.8deg);
}
.br-loading[data-progress="52"] .br-loading-fill,
.br-loading[data-progress="52"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(93.6deg);
}
.br-loading[data-progress="53"] .br-loading-fill,
.br-loading[data-progress="53"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(95.4deg);
}
.br-loading[data-progress="54"] .br-loading-fill,
.br-loading[data-progress="54"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(97.2deg);
}
.br-loading[data-progress="55"] .br-loading-fill,
.br-loading[data-progress="55"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(99deg);
}
.br-loading[data-progress="56"] .br-loading-fill,
.br-loading[data-progress="56"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(100.8deg);
}
.br-loading[data-progress="57"] .br-loading-fill,
.br-loading[data-progress="57"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(102.6deg);
}
.br-loading[data-progress="58"] .br-loading-fill,
.br-loading[data-progress="58"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(104.4deg);
}
.br-loading[data-progress="59"] .br-loading-fill,
.br-loading[data-progress="59"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(106.2deg);
}
.br-loading[data-progress="60"] .br-loading-fill,
.br-loading[data-progress="60"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(108deg);
}
.br-loading[data-progress="61"] .br-loading-fill,
.br-loading[data-progress="61"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(109.8deg);
}
.br-loading[data-progress="62"] .br-loading-fill,
.br-loading[data-progress="62"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.31turn);
}
.br-loading[data-progress="63"] .br-loading-fill,
.br-loading[data-progress="63"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(113.4deg);
}
.br-loading[data-progress="64"] .br-loading-fill,
.br-loading[data-progress="64"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.32turn);
}
.br-loading[data-progress="65"] .br-loading-fill,
.br-loading[data-progress="65"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(117deg);
}
.br-loading[data-progress="66"] .br-loading-fill,
.br-loading[data-progress="66"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.33turn);
}
.br-loading[data-progress="67"] .br-loading-fill,
.br-loading[data-progress="67"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(120.6deg);
}
.br-loading[data-progress="68"] .br-loading-fill,
.br-loading[data-progress="68"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.34turn);
}
.br-loading[data-progress="69"] .br-loading-fill,
.br-loading[data-progress="69"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(124.2deg);
}
.br-loading[data-progress="70"] .br-loading-fill,
.br-loading[data-progress="70"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(126deg);
}
.br-loading[data-progress="71"] .br-loading-fill,
.br-loading[data-progress="71"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(127.8deg);
}
.br-loading[data-progress="72"] .br-loading-fill,
.br-loading[data-progress="72"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.36turn);
}
.br-loading[data-progress="73"] .br-loading-fill,
.br-loading[data-progress="73"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(131.4deg);
}
.br-loading[data-progress="74"] .br-loading-fill,
.br-loading[data-progress="74"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.37turn);
}
.br-loading[data-progress="75"] .br-loading-fill,
.br-loading[data-progress="75"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(135deg);
}
.br-loading[data-progress="76"] .br-loading-fill,
.br-loading[data-progress="76"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.38turn);
}
.br-loading[data-progress="77"] .br-loading-fill,
.br-loading[data-progress="77"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(138.6deg);
}
.br-loading[data-progress="78"] .br-loading-fill,
.br-loading[data-progress="78"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.39turn);
}
.br-loading[data-progress="79"] .br-loading-fill,
.br-loading[data-progress="79"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(142.2deg);
}
.br-loading[data-progress="80"] .br-loading-fill,
.br-loading[data-progress="80"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(144deg);
}
.br-loading[data-progress="81"] .br-loading-fill,
.br-loading[data-progress="81"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(145.8deg);
}
.br-loading[data-progress="82"] .br-loading-fill,
.br-loading[data-progress="82"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.41turn);
}
.br-loading[data-progress="83"] .br-loading-fill,
.br-loading[data-progress="83"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(149.4deg);
}
.br-loading[data-progress="84"] .br-loading-fill,
.br-loading[data-progress="84"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.42turn);
}
.br-loading[data-progress="85"] .br-loading-fill,
.br-loading[data-progress="85"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(153deg);
}
.br-loading[data-progress="86"] .br-loading-fill,
.br-loading[data-progress="86"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(154.8deg);
}
.br-loading[data-progress="87"] .br-loading-fill,
.br-loading[data-progress="87"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(156.6deg);
}
.br-loading[data-progress="88"] .br-loading-fill,
.br-loading[data-progress="88"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.44turn);
}
.br-loading[data-progress="89"] .br-loading-fill,
.br-loading[data-progress="89"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(160.2deg);
}
.br-loading[data-progress="90"] .br-loading-fill,
.br-loading[data-progress="90"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(162deg);
}
.br-loading[data-progress="91"] .br-loading-fill,
.br-loading[data-progress="91"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(163.8deg);
}
.br-loading[data-progress="92"] .br-loading-fill,
.br-loading[data-progress="92"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(165.6deg);
}
.br-loading[data-progress="93"] .br-loading-fill,
.br-loading[data-progress="93"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(167.4deg);
}
.br-loading[data-progress="94"] .br-loading-fill,
.br-loading[data-progress="94"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.47turn);
}
.br-loading[data-progress="95"] .br-loading-fill,
.br-loading[data-progress="95"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(171deg);
}
.br-loading[data-progress="96"] .br-loading-fill,
.br-loading[data-progress="96"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(172.8deg);
}
.br-loading[data-progress="97"] .br-loading-fill,
.br-loading[data-progress="97"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(174.6deg);
}
.br-loading[data-progress="98"] .br-loading-fill,
.br-loading[data-progress="98"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(0.49turn);
}
.br-loading[data-progress="99"] .br-loading-fill,
.br-loading[data-progress="99"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(178.2deg);
}
.br-loading[data-progress="100"] .br-loading-fill,
.br-loading[data-progress="100"] .br-loading-mask.full {
  animation: fill 2s ease-in-out;
  transform: rotate(180deg);
}
.is-loading,
.loading,
[loading] {
  --loading-indetermined-color: var(--interactive);
  --loading-indetermined-diameter-md: 44px;
  --loading-indetermined-diameter-sm: 24px;
  color: transparent !important;
  min-height: 32px;
  pointer-events: none;
  position: relative;
  z-index: var(--z-index-layer-4);
}
.is-loading:after,
.loading:after,
[loading]:after {
  animation: spinAround 1.3s linear infinite;
  border: 2px solid var(--loading-indetermined-color);
  border-radius: 50%;
  border-right: 2px solid transparent;
  content: "";
  display: block;
  height: var(--loading-indetermined-diameter-sm);
  left: calc(50% - 12px);
  position: absolute;
  top: calc(50% - 12px);
  width: var(--loading-indetermined-diameter-sm);
}
.is-loading.primary:after,
.loading.primary:after,
[loading].primary:after {
  border-bottom-color: var(--color-secondary-01);
  border-left-color: var(--color-secondary-01);
  border-top-color: var(--color-secondary-01);
}
.is-loading.medium:after,
.is-loading[md]:after,
.loading.medium:after,
.loading[md]:after,
[loading].medium:after,
[loading][md]:after {
  border-width: 4px;
  height: var(--loading-indetermined-diameter-md);
  left: calc(50% - 22px);
  top: calc(50% - 22px);
  width: var(--loading-indetermined-diameter-md);
}
@keyframes spinAround {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.br-magic-button {
  --magic-size: var(--magic-medium);
  --magic-small: var(--spacing-scale-4xh);
  --magic-medium: var(--spacing-scale-5xh);
  --magic-large: var(--spacing-scale-6xh);
  --magic-support-size: var(--magic-support-medium);
  --magic-support-small: var(--spacing-scale-7x);
  --magic-support-medium: var(--spacing-scale-8x);
  --magic-support-large: var(--spacing-scale-9x);
  --magic-z-index: var(--z-index-layer-1);
  width: 100%;
  align-items: center;
  background-color: var(--gray-5);
  border-radius: 100em;
  box-shadow: var(--surface-shadow-md);
  display: inline-flex;
  height: var(--magic-support-size);
  padding: calc((var(--magic-support-size) - var(--magic-size)) * 0.5);
}
.br-magic-button.small {
  --magic-size: var(--magic-small);
  --magic-support-size: var(--magic-support-small);
}
.br-magic-button.medium {
  --magic-size: var(--magic-medium);
  --magic-support-size: var(--magic-support-medium);
}
.br-magic-button.large {
  --magic-size: var(--magic-large);
  --magic-support-size: var(--magic-support-large);
}
.br-magic-button .fa,
.br-magic-button .fab,
.br-magic-button .fad,
.br-magic-button .fal,
.br-magic-button .far,
.br-magic-button .fas,
.br-magic-button .svg-inline--fa {
  --icon-size: var(--icon-size-lg);
}
.br-magic-button .br-button {
  --focus-offset: calc(
    (var(--magic-support-size) - var(--magic-size)) * 0.5 + 4px
  );
  --button-size: var(--magic-size);
  background-color: var(--interactive-alternative);
  color: var(--color-dark);
  font-size: var(--font-size-scale-up-02);
  font-weight: var(--font-weight-semi-bold);
  width: 100%;
}
.br-magic-button .br-button:not(:disabled):not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-dark-rgb), var(--hover)),
    rgba(var(--color-dark-rgb), var(--hover))
  );
}
.br-magic-button .br-button:not(:disabled):not(:disabled):active {
  background-image: linear-gradient(
    rgba(var(--color-dark-rgb), var(--pressed)),
    rgba(var(--color-dark-rgb), var(--pressed))
  );
}
.br-message {
  --message-background: var(--background);
  --message-color-icon: var(--color);
  --feedback-background: var(--background);
  --message-text: var(--color);
  background: var(--message-background);
  display: flex;
  margin-bottom: var(--spacing-scale-2x);
}
.br-message .content {
  color: var(--message-text);
  flex: 1;
  font-size: var(--font-size-scale-up-01);
  padding: var(--spacing-scale-3x) var(--spacing-scale-base)
    var(--spacing-scale-3x) var(--spacing-scale-2x);
}
.br-message .content .message-title {
  font-weight: var(--font-weight-semi-bold);
}
.br-message .content .message-body {
  font-weight: var(--font-weigth-regular);
}
.br-message .content a {
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
}
.br-message .content :last-child {
  margin-bottom: 0;
}
.br-message .message-icon {
  align-items: center;
  color: var(--message-color-icon);
  display: flex;
  justify-content: center;
  margin: var(--spacing-vertical-center) var(--spacing-scale-2x);
  padding-left: var(--spacing-scale-2x);
  padding-right: var(--spacing-scale-2x);
}
.br-message .message-icon + .content {
  padding-left: 0;
}
.br-message .close {
  margin-right: var(--spacing-scale-base);
  margin-top: var(--spacing-scale-base);
}
.br-message.is-success,
.br-message.success,
.br-message[success] {
  --message-background: var(--success-alternative);
  --feedback-background: var(--success);
  --message-color-icon: var(--success);
}
.br-message.is-success .close .br-button,
.br-message.success .close .br-button,
.br-message[success] .close .br-button {
  --interactive: var(--message-color-icon);
  --interactive-rgb: var(--success-rgb);
}
.br-message.danger,
.br-message.is-danger,
.br-message[danger] {
  --message-background: var(--danger-alternative);
  --feedback-background: var(--danger);
  --message-color-icon: var(--danger);
}
.br-message.danger .close .br-button,
.br-message.is-danger .close .br-button,
.br-message[danger] .close .br-button {
  --interactive: var(--message-color-icon);
  --interactive-rgb: var(--danger-rgb);
}
.br-message.info,
.br-message.is-info,
.br-message[info] {
  --message-background: var(--info-alternative);
  --feedback-background: var(--info);
  --message-color-icon: var(--info);
}
.br-message.info .close .br-button,
.br-message.is-info .close .br-button,
.br-message[info] .close .br-button {
  --interactive: var(--message-color-icon);
  --interactive-rgb: var(--info-rgb);
}
.br-message.is-warning,
.br-message.warning,
.br-message[warning] {
  --message-background: var(--warning-alternative);
  --feedback-background: var(--warning);
}
.br-message.is-warning .close .br-button,
.br-message.warning .close .br-button,
.br-message[warning] .close .br-button {
  --interactive: var(--color);
  --interactive-rgb: var(--color-rgb);
}
.br-message .content {
  padding: 0;
}
.br-message {
  padding: 1rem;
  gap: 1rem;
}
.feedback {
  --message-background: var(--background);
  --message-color-icon: var(--color);
  --feedback-background: var(--background);
  --message-text: var(--color);
  align-items: center;
  background: var(--feedback-background);
  color: var(--message-text);
  display: inline-flex;
  font-style: italic;
  font-weight: var(--font-weight-medium);
  margin-bottom: 0;
  padding: var(--spacing-scale-half);
}
.feedback .fa,
.feedback .fab,
.feedback .fad,
.feedback .fal,
.feedback .far,
.feedback .fas,
.feedback .svg-inline--fa {
  margin-right: var(--spacing-scale-half);
}
.feedback:not(.is-warning):not(.warning):not([warning]) {
  --message-text: var(--pure-0);
}
.feedback.is-warning,
.feedback.warning,
.feedback[warning] {
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --text-color: var(--color-light);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--interactive-light-rgb);
  --visited: var(--visited-light);
  --hover: var(--hover-light);
  --pressed: var(--pressed-light);
  --focus-color: var(--focus-color-light);
  --focus: var(--focus-color-light);
}
.feedback.is-success,
.feedback.success,
.feedback[success] {
  --message-background: var(--success-alternative);
  --feedback-background: var(--success);
  --message-color-icon: var(--success);
}
.feedback.is-success .close .br-button,
.feedback.success .close .br-button,
.feedback[success] .close .br-button {
  --interactive: var(--message-color-icon);
  --interactive-rgb: var(--success-rgb);
}
.feedback.danger,
.feedback.is-danger,
.feedback[danger] {
  --message-background: var(--danger-alternative);
  --feedback-background: var(--danger);
  --message-color-icon: var(--danger);
}
.feedback.danger .close .br-button,
.feedback.is-danger .close .br-button,
.feedback[danger] .close .br-button {
  --interactive: var(--message-color-icon);
  --interactive-rgb: var(--danger-rgb);
}
.feedback.info,
.feedback.is-info,
.feedback[info] {
  --message-background: var(--info-alternative);
  --feedback-background: var(--info);
  --message-color-icon: var(--info);
}
.feedback.info .close .br-button,
.feedback.is-info .close .br-button,
.feedback[info] .close .br-button {
  --interactive: var(--message-color-icon);
  --interactive-rgb: var(--info-rgb);
}
.feedback.is-warning,
.feedback.warning,
.feedback[warning] {
  --message-background: var(--warning-alternative);
  --feedback-background: var(--warning);
}
.feedback.is-warning .close .br-button,
.feedback.warning .close .br-button,
.feedback[warning] .close .br-button {
  --interactive: var(--color);
  --interactive-rgb: var(--color-rgb);
}
.br-modal {
  --modal-size: var(--modal-medium);
  --modal-xsmall: 220px;
  --modal-small: 300px;
  --modal-medium: 500px;
  --modal-large: 640px;
  --modal-auto: auto;
  background: var(--background);
  box-shadow: var(--surface-shadow-sm);
  display: flex;
  flex-direction: column;
  max-width: var(--modal-size);
  z-index: var(--z-index-layer-4);
}
.br-modal-header {
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-bold);
  padding: var(--spacing-scale-2x) var(--spacing-scale-2x) 0;
  position: relative;
}
.br-modal-header .close {
  position: absolute;
  right: var(--spacing-scale-base);
  top: var(--spacing-scale-base);
}
.br-modal .modal-title {
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-bold);
  margin-right: 40px;
  max-height: calc(var(--font-size-scale-up-01) * 3);
  overflow: hidden;
  text-overflow: ellipsis;
}
.br-modal-body {
  flex: 1;
  margin: var(--spacing-scale-3x) 0 var(--spacing-scale-2x);
  overflow: auto;
  padding: 0 var(--spacing-scale-2x);
}
.br-modal-body::-webkit-scrollbar {
  height: var(--spacing-scale-base);
  width: var(--spacing-scale-base);
}
.br-modal-body::-webkit-scrollbar-track {
  background: var(--gray-10);
}
.br-modal-body::-webkit-scrollbar-thumb {
  background: var(--gray-30);
}
.br-modal-body:hover::-webkit-scrollbar-thumb {
  background: var(--gray-40);
}
.br-modal-body :last-child {
  margin-bottom: 0;
}
.br-modal-footer {
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-scale-2x);
}
.br-modal.is-xsmall,
.br-modal.xsmall {
  --modal-size: var(--modal-xsmall);
}
.br-modal.is-small,
.br-modal.small {
  --modal-size: var(--modal-small);
}
.br-modal.is-medium,
.br-modal.medium {
  --modal-size: var(--modal-medium);
}
.br-modal.is-large,
.br-modal.large {
  --modal-size: var(--modal-large);
}
.br-modal.auto,
.br-modal.is-auto {
  --modal-size: var(--modal-auto);
}
.br-modal .loading.medium {
  min-height: calc(
    var(--loading-indetermined-diameter-md) + var(--spacing-scale-2x) * 2
  );
}
.br-modal .terms {
  border: 0;
  box-shadow: var(--surface-shadow-sm-inset), var(--surface-shadow-sm-inset-up);
  font-size: var(--font-size-scale-base);
  height: 216px;
  margin-bottom: var(--spacing-scale-2x);
  margin-left: calc(var(--spacing-scale-2x) * -1);
  margin-right: calc(var(--spacing-scale-2x) * -1);
  overflow: auto;
  padding: var(--spacing-scale-2x);
  resize: none;
  width: auto;
}
.br-modal .terms::-webkit-scrollbar {
  height: var(--spacing-scale-base);
  width: var(--spacing-scale-base);
}
.br-modal .terms::-webkit-scrollbar-track {
  background: var(--gray-10);
}
.br-modal .terms::-webkit-scrollbar-thumb {
  background: var(--gray-30);
}
.br-modal .terms:hover::-webkit-scrollbar-thumb {
  background: var(--gray-40);
}
.br-modal .terms:active {
  outline: none;
}
.br-modal .terms:hover::-webkit-scrollbar-thumb {
  background: var(--color-secondary-07);
}
@media (max-width: 991px) {
  .br-modal .terms {
    margin-left: unset !important;
    margin-right: unset !important;
  }
}
.br-notification {
  --notification-padding: var(--spacing-scale-2x) var(--spacing-scale-4x);
  --notification-width: 50vw;
  --notification-height: calc(100vh - 86px);
  background: var(--background);
  box-shadow: var(--surface-shadow-md);
  max-height: var(--notification-height);
  max-width: var(--notification-width);
  overflow: auto;
  z-index: 1;
}
@media (max-width: 576px) {
  .br-notification {
    --item-padding-x: var(--spacing-scale-2x);
    --item-padding-y: var(--spacing-scale-base);
    --item-padding: var(--item-padding-y) var(--item-padding-x);
    --notification-padding: var(--item-padding);
    --notification-width: 100vw;
    min-height: var(--notification-height);
    overflow: hidden;
  }
  .br-notification .br-tab {
    --tab-size: var(--tab-small);
  }
  .br-notification .br-tab .tab-content {
    --notification-height: calc(100vh - 86px);
    height: var(--notification-height);
    overflow-x: auto;
  }
  .br-notification .status {
    left: var(--spacing-scale-half) !important;
    top: var(--spacing-scale-2x) !important;
  }
  .br-notification.close {
    display: none;
  }
}
@media (max-width: 992px) {
  .br-notification.close {
    display: none;
  }
}
@media (min-width: 992px) {
  .br-notification div.close {
    display: none;
  }
}
.br-notification .notification-header {
  border-bottom: 1px solid var(--border-color);
  padding: var(--notification-padding);
}
.br-notification .notification-header :last-child {
  margin-bottom: 0;
}
.br-notification .notification-body .br-tab .tab-item {
  padding: 0;
}
.br-notification .notification-body .br-tab .tab-content {
  overflow-y: auto;
}
.br-notification .notification-body .br-tab .tab-content .br-item {
  --interactive-rgb: var(--rgb-secondary-08);
  --item-color: var(--text-color);
  padding: var(--notification-padding);
  position: relative;
  white-space: normal;
}
.br-notification .notification-body .br-tab .tab-content .br-item .status {
  left: var(--spacing-scale-base);
  position: absolute;
  top: var(--spacing-scale-3x);
}
.br-notification .notification-body .br-tab .tab-content .br-item span {
  display: block;
}
.br-pagination {
  --pagination-margin: var(--spacing-scale-base);
  --pagination-select-width: 88px;
  --pagination-size: var(--pagination-medium);
  --pagination-small: 24px;
  --pagination-medium: 32px;
  --pagination-large: 40px;
  color: var(--color);
  display: flex;
  flex-wrap: wrap;
  font-weight: var(--font-weight);
  justify-content: center;
}
.br-pagination ul {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.br-pagination li {
  padding: 0;
}
.br-pagination .page {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 100em;
  color: var(--interactive);
  display: inline-flex;
  justify-content: center;
  margin: 0 calc(var(--pagination-margin) * 0.5);
  min-height: var(--pagination-size);
  min-width: var(--pagination-size);
  padding: 0 var(--spacing-scale-base);
  white-space: nowrap;
}
.br-pagination .page:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-pagination .page.active {
  background: var(--active);
  color: var(--color-dark);
  font-weight: var(--font-weight-semi-bold);
}
.br-pagination .pagination-ellipsis {
  margin: 0 calc(var(--pagination-margin) * 0.5);
  position: relative;
}
.br-pagination .pagination-ellipsis .br-button {
  --button-size: var(--pagination-size);
}
.br-pagination .pagination-ellipsis .br-list {
  max-height: 220px;
  min-width: 4em;
  overflow-y: auto;
  z-index: var(--z-index-layer-1);
}
.br-pagination .pagination-ellipsis .br-list::-webkit-scrollbar {
  height: var(--spacing-scale-base);
  width: var(--spacing-scale-base);
}
.br-pagination .pagination-ellipsis .br-list::-webkit-scrollbar-track {
  background: var(--gray-10);
}
.br-pagination .pagination-ellipsis .br-list::-webkit-scrollbar-thumb {
  background: var(--gray-30);
}
.br-pagination .pagination-ellipsis .br-list:hover::-webkit-scrollbar-thumb {
  background: var(--gray-40);
}
.br-pagination.small {
  --pagination-size: var(--pagination-small);
}
.br-pagination.medium {
  --pagination-size: var(--pagination-medium);
}
.br-pagination.large {
  --pagination-size: var(--pagination-large);
}
.br-pagination .pagination-go-to-page,
.br-pagination .pagination-go-to-page .br-input,
.br-pagination .pagination-information,
.br-pagination .pagination-per-page,
.br-pagination .pagination-per-page .br-input {
  align-items: center;
  display: flex;
}
.br-pagination .pagination-go-to-page .br-input label,
.br-pagination .pagination-per-page .br-input label {
  font-weight: var(--font-weight-regular);
  margin-bottom: 0;
  margin-right: var(--spacing-scale-base);
  padding-bottom: 0;
}
.br-pagination .pagination-go-to-page .br-input input,
.br-pagination .pagination-per-page .br-input input {
  --input-size: var(--pagination-medium);
  padding-left: var(--spacing-scale-base);
  padding-right: calc(var(--spacing-scale-baseh) + 32px);
  text-align: right;
  width: var(--pagination-select-width);
}
.br-pagination .pagination-go-to-page .br-input input:not(:focus),
.br-pagination .pagination-per-page .br-input input:not(:focus) {
  border-color: transparent;
}
.br-pagination .pagination-go-to-page .br-list,
.br-pagination .pagination-per-page .br-list {
  min-width: 5em;
  right: 0;
  width: auto;
}
.br-pagination .pagination-arrows {
  align-items: center;
  display: flex;
}
.br-pagination .br-select .br-input .br-button {
  bottom: auto;
  margin-top: 0;
  position: absolute;
  right: var(--spacing-scale-half);
  top: auto;
  transform: none;
}
.br-pagination .br-divider {
  border-right-width: var(--divider-size);
  border-top: 0;
}
.br-pagination.dark-mode,
.br-pagination.inverted {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.br-pagination.dark-mode.dark-mode .br-input,
.br-pagination.dark-mode.dark-mode .br-input label,
.br-pagination.dark-mode.inverted .br-input,
.br-pagination.dark-mode.inverted .br-input label,
.br-pagination.inverted.dark-mode .br-input,
.br-pagination.inverted.dark-mode .br-input label,
.br-pagination.inverted.inverted .br-input,
.br-pagination.inverted.inverted .br-input label {
  --color: var(--color-dark);
  --focus-color: var(--focus-color-dark);
}
.br-pagination.dark-mode .page.active,
.br-pagination.inverted .page.active {
  --interactive-rgb: var(--active-rgb);
  background-color: var(--background-light);
  color: var(--active);
}
.br-pagination .input-group .input-icon {
  display: none;
}
.br-scrim.foco {
  background: var(--surface-overlay-scrim);
  bottom: 0;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.br-scrim.foco .br-modal {
  left: 50%;
  max-height: 90%;
  overflow: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.br-scrim.foco.active {
  display: block;
}
.br-scrim.inibicao {
  position: relative;
}
.br-scrim.inibicao:before {
  background: var(--surface-overlay-scrim);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.br-scrim.legibilidade {
  position: relative;
}
.br-scrim.legibilidade .scrim-text {
  background: var(--surface-overlay-text);
  bottom: 0;
  left: 0;
  padding: var(--spacing-scale-3x) var(--spacing-scale-baseh);
  position: absolute;
  width: 100%;
}
.br-scrim-util.foco {
  background: var(--surface-overlay-scrim);
  bottom: 0;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.br-scrim-util.foco .br-modal {
  left: 50%;
  width: 80%;
  max-height: 90%;
  overflow: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.br-scrim-util.foco.active {
  display: block;
}
.br-scrim-util.inibicao {
  position: relative;
}
.br-scrim-util.inibicao:before {
  background: var(--surface-overlay-scrim);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.br-scrim-util.legibilidade {
  position: relative;
}
.br-scrim-util.legibilidade .scrim-text {
  background: var(--surface-overlay-text);
  bottom: 0;
  left: 0;
  padding: var(--spacing-scale-3x) var(--spacing-scale-baseh);
  position: absolute;
  width: 100%;
}
.br-select {
  --select-divider: 1px solid var(--color-secondary-04);
  --select-shadow: var(--surface-shadow-md);
  max-width: 400px;
  min-width: 100px;
  position: relative;
}
.br-select .br-input input {
  padding-right: 29px;
  text-overflow: ellipsis;
}
.br-select .br-input input:after {
  bottom: 0;
  padding: 0 5px;
  position: absolute;
  right: 0;
}
.br-select .br-list {
  background: var(--bg-color);
  box-shadow: var(--select-shadow);
  display: none;
  margin-top: -2px;
  max-height: 404px;
  overflow: auto;
  position: absolute;
  resize: none;
  width: 100%;
  z-index: 1;
}
.br-select .br-list::-webkit-scrollbar {
  height: var(--spacing-scale-base);
  width: var(--spacing-scale-base);
}
.br-select .br-list::-webkit-scrollbar-track {
  background: var(--gray-10);
}
.br-select .br-list::-webkit-scrollbar-thumb {
  background: var(--gray-30);
}
.br-select .br-list:hover::-webkit-scrollbar-thumb {
  background: var(--gray-40);
}
.br-select .br-list:active,
.br-select .br-list:focus {
  color: var(--color-secondary-09);
  outline: none;
}
.br-select .br-list:hover::-webkit-scrollbar-thumb {
  background: var(--color-secondary-07);
}
.br-select .br-list[expanded] {
  display: block;
}
.br-select .br-item {
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --text-color: var(--color-light);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--interactive-light-rgb);
  --visited: var(--visited-light);
  --hover: var(--hover-light);
  --pressed: var(--pressed-light);
  --focus-color: var(--focus-color-light);
  --focus: var(--focus-color-light);
  --item-padding-y: var(--spacing-scale-2x);
}
.br-select .br-item .content {
  padding: 0;
}
.br-select .br-item .br-checkbox,
.br-select .br-item .br-radio {
  --item-padding-x: var(--spacing-scale-2x);
  --item-padding-y: var(--spacing-scale-2x);
}
.br-select .br-item .br-checkbox input:hover:not(:disabled) + label:before,
.br-select .br-item .br-radio input:hover:not(:disabled) + label:before {
  background-image: none;
}
.br-select .br-item .br-checkbox input + label,
.br-select .br-item .br-radio input + label {
  padding-left: calc(
    var(--checkbox-padding) + var(--checkbox-size) + var(--item-padding-x)
  );
}
.br-select .br-item .br-radio input + label {
  color: var(--text-color);
  display: block;
  height: auto;
  min-height: 0;
  padding: var(--item-padding);
}
.br-select .br-item .br-radio input + label:after,
.br-select .br-item .br-radio input + label:before {
  content: none;
}
.br-select .br-item:not(:last-child) {
  border-bottom: var(--select-divider);
}
.br-select .br-item:not([disabled]) {
  --focus-offset: calc(var(--spacing-scale-half) * -1);
}
.br-select .br-item:not([disabled]):focus {
  outline: none;
}
.br-select .br-item:not([disabled]).focus-visible,
.br-select .br-item:not([disabled]):focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.br-select .br-item:not([disabled]):not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
.br-select .br-item.selected,
.br-select .br-item[selected] {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.br-select .br-item.selected:focus,
.br-select .br-item[selected]:focus {
  outline: none;
}
.br-select .br-item.selected.focus-visible,
.br-select .br-item.selected:focus-visible,
.br-select .br-item[selected].focus-visible,
.br-select .br-item[selected]:focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.br-select .br-item.selected:not(:disabled):hover,
.br-select .br-item[selected]:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
.br-select .br-item.disabled {
  line-height: calc(var(--font-line-height-high) * 2);
  padding-left: calc(var(--item-padding) + var(--spacing-scale-base));
}
.br-select .br-item.highlighted,
.br-select .br-item[highlighted] {
  background-color: var(--gray-2);
  padding-bottom: var(--spacing-scale-base);
  padding-top: var(--spacing-scale-base);
}
.br-select .br-item.highlighted .br-checkbox label,
.br-select .br-item.highlighted .br-radio label,
.br-select .br-item[highlighted] .br-checkbox label,
.br-select .br-item[highlighted] .br-radio label {
  font-weight: var(--font-weight-semi-bold);
}
.br-select .br-item.highlighted .br-checkbox:hover,
.br-select .br-item.highlighted .br-radio:hover,
.br-select .br-item[highlighted] .br-checkbox:hover,
.br-select .br-item[highlighted] .br-radio:hover {
  background: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-select .br-item.highlighted .br-checkbox:hover label,
.br-select .br-item.highlighted .br-radio:hover label,
.br-select .br-item[highlighted] .br-checkbox:hover label,
.br-select .br-item[highlighted] .br-radio:hover label {
  color: var(--text-color);
}
.br-select .br-item.highlighted.selected,
.br-select .br-item.highlighted[selected],
.br-select .br-item[highlighted].selected,
.br-select .br-item[highlighted][selected] {
  background-color: var(--selected);
}
.br-select.dark-mode .br-input,
.br-select.dark-mode .br-input label,
.br-select.inverted .br-input,
.br-select.inverted .br-input label {
  --color: var(--color-dark);
  --focus-color: var(--focus-color-dark);
}
.br-button.sign-in,
.br-sign-in {
  --button-radius: 100em;
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;
  --button-size: var(--button-medium);
  --background: var(--gray-2);
  --sign-in-img: 20px;
  align-items: center;
  background-color: transparent;
  background-color: var(--background);
  border: 0;
  border-radius: var(--button-radius) !important;
  color: var(--interactive);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-semi-bold);
  height: var(--button-size);
  justify-content: center;
  overflow: hidden;
  padding: 0 var(--spacing-scale-2x);
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}
.br-button.sign-in.block,
.br-sign-in.block {
  width: 100%;
}
@media (min-width: 576px) {
  .br-button.sign-in.block-sm,
  .br-sign-in.block-sm {
    width: 100%;
  }
  .br-button.sign-in.auto-sm,
  .br-sign-in.auto-sm {
    width: auto;
  }
}
@media (min-width: 992px) {
  .br-button.sign-in.block-md,
  .br-sign-in.block-md {
    width: 100%;
  }
  .br-button.sign-in.auto-md,
  .br-sign-in.auto-md {
    width: auto;
  }
}
@media (min-width: 1280px) {
  .br-button.sign-in.block-lg,
  .br-sign-in.block-lg {
    width: 100%;
  }
  .br-button.sign-in.auto-lg,
  .br-sign-in.auto-lg {
    width: auto;
  }
}
@media (min-width: 1600px) {
  .br-button.sign-in.block-xl,
  .br-sign-in.block-xl {
    width: 100%;
  }
  .br-button.sign-in.auto-xl,
  .br-sign-in.auto-xl {
    width: auto;
  }
}
.br-button.sign-in.circle,
.br-button.sign-in.is-circle,
.br-button.sign-in[circle],
.br-sign-in.circle,
.br-sign-in.is-circle,
.br-sign-in[circle] {
  border-radius: 50%;
  padding: 0;
  width: var(--button-size);
}
.br-button.sign-in.is-xsmall,
.br-button.sign-in.xsmall,
.br-button.sign-in[xsmall],
.br-sign-in.is-xsmall,
.br-sign-in.xsmall,
.br-sign-in[xsmall] {
  --button-size: var(--button-xsmall);
}
.br-button.sign-in.is-small,
.br-button.sign-in.small,
.br-button.sign-in[small],
.br-sign-in.is-small,
.br-sign-in.small,
.br-sign-in[small] {
  --button-size: var(--button-small);
}
.br-button.sign-in.is-medium,
.br-button.sign-in.medium,
.br-button.sign-in[medium],
.br-sign-in.is-medium,
.br-sign-in.medium,
.br-sign-in[medium] {
  --button-size: var(--button-medium);
}
.br-button.sign-in.is-large,
.br-button.sign-in.large,
.br-button.sign-in[large],
.br-sign-in.is-large,
.br-sign-in.large,
.br-sign-in[large] {
  --button-size: var(--button-large);
}
.br-button.sign-in.is-primary,
.br-button.sign-in.primary,
.br-button.sign-in[primary],
.br-sign-in.is-primary,
.br-sign-in.primary,
.br-sign-in[primary] {
  --interactive-rgb: var(--color-dark-rgb);
  background-color: var(--interactive-light);
  color: var(--color-dark);
}
.br-button.sign-in.is-secondary,
.br-button.sign-in.secondary,
.br-button.sign-in[secondary],
.br-sign-in.is-secondary,
.br-sign-in.secondary,
.br-sign-in[secondary] {
  background-color: var(--background-light);
  border: 1px solid var(--interactive);
}
.br-button.sign-in.danger,
.br-button.sign-in.is-danger,
.br-button.sign-in[danger],
.br-sign-in.danger,
.br-sign-in.is-danger,
.br-sign-in[danger] {
  --interactive-rgb: var(--color-dark-rgb);
  background-color: var(--danger);
  color: var(--color-dark);
}
.br-button.sign-in.is-success,
.br-button.sign-in.success,
.br-button.sign-in[success],
.br-sign-in.is-success,
.br-sign-in.success,
.br-sign-in[success] {
  --interactive-rgb: var(--color-dark-rgb);
  background-color: var(--success);
  color: var(--color-dark);
}
.br-button.sign-in.is-warning,
.br-button.sign-in.warning,
.br-button.sign-in[warning],
.br-sign-in.is-warning,
.br-sign-in.warning,
.br-sign-in[warning] {
  --interactive-rgb: var(--color-light-rgb);
  background-color: var(--warning);
  color: var(--color-light);
}
.br-button.sign-in.info,
.br-button.sign-in.is-info,
.br-button.sign-in[info],
.br-sign-in.info,
.br-sign-in.is-info,
.br-sign-in[info] {
  --interactive-rgb: var(--color-dark-rgb);
  background-color: var(--info);
  color: var(--color-dark);
}
.br-button.sign-in:disabled,
.br-sign-in:disabled {
  cursor: not-allowed;
}
.br-button.sign-in:not(:disabled),
.br-sign-in:not(:disabled) {
  --focus-offset: var(--spacing-scale-half);
}
.br-button.sign-in:not(:disabled):focus,
.br-sign-in:not(:disabled):focus {
  outline: none;
}
.br-button.sign-in:not(:disabled).focus-visible,
.br-button.sign-in:not(:disabled):focus-visible,
.br-sign-in:not(:disabled).focus-visible,
.br-sign-in:not(:disabled):focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.br-button.sign-in:not(:disabled):not(:disabled):hover,
.br-sign-in:not(:disabled):not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-button.sign-in:not(:disabled):not(:disabled):active,
.br-sign-in:not(:disabled):not(:disabled):active {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--pressed)),
    rgba(var(--interactive-rgb), var(--pressed))
  );
}
.br-button.sign-in.active,
.br-button.sign-in.is-active,
.br-button.sign-in[active],
.br-sign-in.active,
.br-sign-in.is-active,
.br-sign-in[active] {
  --hover: var(--hover-dark);
  background-color: var(--active);
  color: var(--color-dark);
}
.br-button.sign-in.is-loading:after,
.br-button.sign-in.loading:after,
.br-button.sign-in[loading]:after,
.br-sign-in.is-loading:after,
.br-sign-in.loading:after,
.br-sign-in[loading]:after {
  border-color: var(--interactive) var(--interactive) transparent;
  border-style: solid;
}
.br-button.sign-in.is-loading.danger:after,
.br-button.sign-in.is-loading.info:after,
.br-button.sign-in.is-loading.primary:after,
.br-button.sign-in.is-loading.success:after,
.br-button.sign-in.loading.danger:after,
.br-button.sign-in.loading.info:after,
.br-button.sign-in.loading.primary:after,
.br-button.sign-in.loading.success:after,
.br-button.sign-in[loading].danger:after,
.br-button.sign-in[loading].info:after,
.br-button.sign-in[loading].primary:after,
.br-button.sign-in[loading].success:after,
.br-sign-in.is-loading.danger:after,
.br-sign-in.is-loading.info:after,
.br-sign-in.is-loading.primary:after,
.br-sign-in.is-loading.success:after,
.br-sign-in.loading.danger:after,
.br-sign-in.loading.info:after,
.br-sign-in.loading.primary:after,
.br-sign-in.loading.success:after,
.br-sign-in[loading].danger:after,
.br-sign-in[loading].info:after,
.br-sign-in[loading].primary:after,
.br-sign-in[loading].success:after {
  border-color: var(--background) var(--background) transparent;
}
.br-button.sign-in.dark-mode,
.br-button.sign-in.inverted,
.br-button.sign-in.is-inverted,
.br-button.sign-in[inverted],
.br-sign-in.dark-mode,
.br-sign-in.inverted,
.br-sign-in.is-inverted,
.br-sign-in[inverted] {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  color: var(--interactive-dark);
}
.br-button.sign-in.dark-mode.is-primary,
.br-button.sign-in.dark-mode.primary,
.br-button.sign-in.dark-mode[primary],
.br-button.sign-in.inverted.is-primary,
.br-button.sign-in.inverted.primary,
.br-button.sign-in.inverted[primary],
.br-button.sign-in.is-inverted.is-primary,
.br-button.sign-in.is-inverted.primary,
.br-button.sign-in.is-inverted[primary],
.br-button.sign-in[inverted].is-primary,
.br-button.sign-in[inverted].primary,
.br-button.sign-in[inverted][primary],
.br-sign-in.dark-mode.is-primary,
.br-sign-in.dark-mode.primary,
.br-sign-in.dark-mode[primary],
.br-sign-in.inverted.is-primary,
.br-sign-in.inverted.primary,
.br-sign-in.inverted[primary],
.br-sign-in.is-inverted.is-primary,
.br-sign-in.is-inverted.primary,
.br-sign-in.is-inverted[primary],
.br-sign-in[inverted].is-primary,
.br-sign-in[inverted].primary,
.br-sign-in[inverted][primary] {
  --interactive-rgb: var(--background-dark-rgb);
  background-color: var(--interactive-dark);
  color: var(--background-dark);
}
.br-button.sign-in.dark-mode.is-secondary,
.br-button.sign-in.dark-mode.secondary,
.br-button.sign-in.dark-mode[secondary],
.br-button.sign-in.inverted.is-secondary,
.br-button.sign-in.inverted.secondary,
.br-button.sign-in.inverted[secondary],
.br-button.sign-in.is-inverted.is-secondary,
.br-button.sign-in.is-inverted.secondary,
.br-button.sign-in.is-inverted[secondary],
.br-button.sign-in[inverted].is-secondary,
.br-button.sign-in[inverted].secondary,
.br-button.sign-in[inverted][secondary],
.br-sign-in.dark-mode.is-secondary,
.br-sign-in.dark-mode.secondary,
.br-sign-in.dark-mode[secondary],
.br-sign-in.inverted.is-secondary,
.br-sign-in.inverted.secondary,
.br-sign-in.inverted[secondary],
.br-sign-in.is-inverted.is-secondary,
.br-sign-in.is-inverted.secondary,
.br-sign-in.is-inverted[secondary],
.br-sign-in[inverted].is-secondary,
.br-sign-in[inverted].secondary,
.br-sign-in[inverted][secondary] {
  background-color: var(--background-dark);
}
.br-button.sign-in.dark-mode.active,
.br-button.sign-in.inverted.active,
.br-button.sign-in.is-inverted.active,
.br-button.sign-in[inverted].active,
.br-sign-in.dark-mode.active,
.br-sign-in.inverted.active,
.br-sign-in.is-inverted.active,
.br-sign-in[inverted].active {
  --hover: var(--hover-light);
  --interactive-rgb: var(--active-rgb);
  background-color: var(--background-light);
  color: var(--active);
}
.br-button.sign-in img,
.br-sign-in img {
  max-height: var(--sign-in-img);
}
.br-button.sign-in.dark-mode,
.br-button.sign-in.inverted,
.br-button.sign-in.is-inverted,
.br-button.sign-in[inverted],
.br-sign-in.dark-mode,
.br-sign-in.inverted,
.br-sign-in.is-inverted,
.br-sign-in[inverted] {
  --background: var(--background-dark);
}
.br-skiplink {
  --skiplink-layer: var(--z-index-layer-4);
  --skiplink-margin: var(--spacing-scale-2x);
  --skiplink-min-width: 300px;
  --skiplink-shadow: var(--surface-shadow-lg);
  --skiplink-duration: 150ms;
  --skiplink-timing-function: ease-out;
  display: inline-flex;
  flex-direction: column;
  left: var(--skiplink-margin);
  position: fixed;
  top: 0;
  z-index: var(--skiplink-layer);
}
.br-skiplink a {
  align-items: center;
  background-color: var(--background);
  box-shadow: var(--skiplink-shadow);
  display: inline-flex;
  min-width: var(--skiplink-min-width);
  position: absolute;
  top: -100vh;
  transition: top var(--skiplink-duration) var(--skiplink-timing-function);
  white-space: nowrap;
  width: auto;
}
.br-skiplink a:focus,
.br-skiplink a:focus-visible {
  top: 0;
}
.br-skiplink.full {
  box-shadow: var(--skiplink-shadow);
  flex-direction: row;
  top: -100vh;
  transition: top var(--skiplink-duration) var(--skiplink-timing-function);
}
.br-skiplink.full a {
  box-shadow: none;
  min-width: 0;
  position: relative;
  top: 0;
}
.br-skiplink.full:focus-within {
  top: 0;
}
.br-step {
  --step-empty: 16px;
  --step-empty-border: 4px;
  --step-line: 1px;
  --step-size: var(--step-medium);
  --step-small: 32px;
  --step-medium: 40px;
  --step-large: 48px;
  --step-simple-size: var(--step-simple-medium);
  --step-simple-medium: 8px;
  --step-simple-large: 12px;
  --step-alert-success-icon: "";
  --step-alert-warning-icon: "";
  --step-alert-info-icon: "";
  --step-alert-danger-icon: "";
  display: flex;
}
.br-step .step-progress {
  display: flex;
  flex-grow: 1;
}
.br-step.vertical,
.br-step.vertical .step-progress {
  flex-direction: column;
}
.br-step .step-progress-btn {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  min-height: var(--step-size);
  padding: 0;
  position: relative;
}
.br-step[data-label="left"] .step-progress-btn {
  justify-content: end;
}
.br-step.vertical .step-progress-btn,
.br-step[data-label="right"] .step-progress-btn {
  justify-content: start;
}
.br-step.vertical[data-label="left"] .step-progress-btn {
  justify-content: end;
}
.br-step.vertical[data-label="right"] .step-progress-btn {
  justify-content: start;
}
.br-step .step-progress-btn:before {
  background-color: var(--background);
  border: var(--step-line) solid var(--interactive);
  border-radius: 50%;
  content: attr(step-num);
  font-size: var(--font-size-scale-up-03);
  font-weight: var(--font-weight-medium);
  left: 0;
  margin: auto;
  right: 0;
  z-index: 2;
}
.br-step .step-icon,
.br-step .step-progress-btn:before {
  align-items: center;
  color: var(--interactive);
  display: inline-flex;
  height: var(--step-size);
  justify-content: center;
  position: absolute;
  width: var(--step-size);
}
.br-step .step-icon {
  z-index: 4;
}
.br-step .step-alert {
  align-items: center;
  border-radius: 50%;
  color: var(--color-dark);
  display: flex;
  height: var(--font-size-scale-up-04);
  justify-content: center;
  position: absolute;
  width: var(--font-size-scale-up-04);
  z-index: 4;
}
.br-step .step-alert:after {
  display: flex;
  font-family: Font Awesome\5 Free;
  font-size: var(--font-size-scale-up-01);
  font-weight: 900;
}
.br-step[data-label="bottom"] .step-icon,
.br-step[data-label="bottom"] .step-progress-btn:before {
  top: 0;
}
.br-step[data-label="bottom"] .step-alert {
  transform: translate(
    calc(var(--step-size) * 0.5),
    calc(var(--step-size) * -0.75)
  );
}
.br-step[data-label="top"] .step-icon,
.br-step[data-label="top"] .step-progress-btn:before {
  bottom: 0;
}
.br-step[data-label="top"] .step-alert {
  transform: translate(
    calc(var(--step-size) * 0.5),
    calc(var(--step-size) * 0.25)
  );
}
.br-step[data-label="left"] .step-progress-btn:before {
  left: auto;
  right: 0;
}
.br-step[data-label="left"] .step-alert {
  transform: translate(
    calc(var(--step-size) * 0.4),
    calc(var(--step-size) * -0.25)
  );
}
.br-step[data-label="right"] .step-progress-btn:before {
  left: 0;
  right: auto;
}
.br-step[data-label="right"] .step-alert {
  transform: translate(
    calc(var(--step-size) * 0.65),
    calc(var(--step-size) * -0.25)
  );
}
.br-step.vertical .step-progress-btn:before {
  bottom: 0;
  left: auto;
  right: auto;
  top: 0;
}
.br-step.vertical[data-label="left"] .step-progress-btn:before {
  right: 0;
}
.br-step.vertical[data-label="right"] .step-progress-btn:before {
  left: 0;
}
.br-step .step-progress-btn:after {
  background-color: var(--interactive);
  content: "";
  display: block;
  height: var(--step-line);
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}
.br-step .step-progress-btn:first-child:after {
  left: 50%;
}
.br-step .step-progress-btn:last-child:after {
  right: 50%;
}
.br-step[data-label="bottom"] .step-progress-btn:after {
  top: calc(var(--step-size) * 0.5);
}
.br-step[data-label="top"] .step-progress-btn:after {
  bottom: calc(var(--step-size) * 0.5);
}
.br-step[data-label="left"] .step-progress-btn:after {
  left: 0;
}
.br-step[data-label="left"] .step-progress-btn:first-child:after {
  left: 100%;
}
.br-step[data-label="left"] .step-progress-btn:last-child:after,
.br-step[data-label="right"] .step-progress-btn:after {
  right: 0;
}
.br-step[data-label="right"] .step-progress-btn:first-child:after {
  left: 0;
}
.br-step[data-label="right"] .step-progress-btn:last-child:after {
  right: 100%;
}
.br-step.vertical .step-progress-btn:after {
  bottom: 0;
  height: auto;
  left: auto;
  right: auto;
  top: 0;
  width: var(--step-line);
}
.br-step.vertical .step-progress-btn:first-child:after {
  left: auto;
  top: 50%;
}
.br-step.vertical .step-progress-btn:last-child:after {
  bottom: 50%;
  right: auto;
}
.br-step.vertical[data-label="left"] .step-progress-btn:after {
  right: calc(var(--step-size) * 0.5);
}
.br-step.vertical[data-label="right"] .step-progress-btn:after {
  left: calc(var(--step-size) * 0.5);
}
.br-step .step-info {
  color: var(--interactive);
  display: inline-block;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-medium);
}
.br-step[data-label="bottom"] .step-info {
  margin-top: var(--step-size);
  padding-top: var(--spacing-scale-2x);
}
.br-step[data-label="top"] .step-info {
  margin-bottom: var(--step-size);
  padding-bottom: var(--spacing-scale-2x);
}
.br-step[data-label="left"] .step-info {
  display: none;
}
@media (min-width: 576px) {
  .br-step[data-label="left"] .step-info {
    background-color: var(--background);
    display: inline-block;
    margin-right: var(--step-size);
    padding: var(--spacing-scale-2x);
    position: relative;
    z-index: 3;
  }
}
.br-step[data-label="right"] .step-info {
  display: none;
}
@media (min-width: 576px) {
  .br-step[data-label="right"] .step-info {
    background-color: var(--background);
    display: inline-block;
    margin-left: var(--step-size);
    padding: var(--spacing-scale-2x);
    position: relative;
    z-index: 3;
  }
}
.br-step.vertical[data-label="left"] .step-info {
  display: inline-block;
  margin-left: 0;
  margin-right: var(--step-size);
  padding: var(--spacing-scale-2x);
}
.br-step.vertical[data-label="right"] .step-info {
  display: inline-block;
  margin-left: var(--step-size);
  margin-right: 0;
  padding: var(--spacing-scale-2x);
}
.br-step[data-type="void"] .step-progress-btn:before {
  content: "";
  height: var(--step-empty);
  width: var(--step-empty);
}
.br-step[data-type="void"] .step-info {
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
}
.br-step[data-type="simple"],
.br-step[data-type="simple"] .step-progress {
  align-items: center;
  display: flex;
  justify-content: center;
}
.br-step[data-type="simple"] .step-progress-btn {
  flex-grow: 0;
  width: 24px;
}
.br-step[data-type="simple"] .step-progress-btn:before {
  background-color: var(--gray-20);
  border-color: var(--gray-20);
  content: "";
  height: var(--step-simple-size);
  width: var(--step-simple-size);
}
.br-step[data-type="simple"] .step-progress-btn:after {
  content: none;
}
.br-step[data-type="simple"] .step-info {
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
}
.br-step[data-type="text"],
.br-step[data-type="text"] .step-progress {
  align-items: center;
  display: flex;
  justify-content: center;
}
.br-step[data-type="text"] .step-progress-btn {
  height: 0;
  min-height: var(--font-size-scale-base);
  overflow: hidden;
  position: absolute;
  width: 0;
}
.br-step[data-type="text"] .step-progress-btn:before {
  background-color: transparent;
  border: 0;
  color: var(--color);
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-regular);
  width: auto;
}
.br-step[data-type="text"] .step-progress-btn:after {
  content: none;
}
.br-step[data-type="text"] .step-info {
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
}
.br-step[data-scroll],
.br-step[scroll] {
  overflow-x: auto;
  overflow-y: hidden;
}
.br-step[data-scroll]::-webkit-scrollbar,
.br-step[scroll]::-webkit-scrollbar {
  height: var(--spacing-scale-base);
  width: var(--spacing-scale-base);
}
.br-step[data-scroll]::-webkit-scrollbar-track,
.br-step[scroll]::-webkit-scrollbar-track {
  background: var(--gray-10);
}
.br-step[data-scroll]::-webkit-scrollbar-thumb,
.br-step[scroll]::-webkit-scrollbar-thumb {
  background: var(--gray-30);
}
.br-step[data-scroll]:hover::-webkit-scrollbar-thumb,
.br-step[scroll]:hover::-webkit-scrollbar-thumb {
  background: var(--gray-40);
}
.br-step[data-scroll] .step-progress-btn,
.br-step[scroll] .step-progress-btn {
  min-width: 200px;
}
.br-step[data-scroll].vertical,
.br-step[scroll].vertical {
  overflow-x: hidden;
  overflow-y: auto;
}
.br-step[data-scroll].vertical .step-progress-btn,
.br-step[scroll].vertical .step-progress-btn {
  min-height: 100px;
  min-width: auto;
}
.br-step .step-progress-btn[data-alert="success"],
.br-step .step-progress-btn[data-alert="success"] .step-info {
  color: var(--success);
}
.br-step .step-progress-btn[data-alert="success"] .step-alert {
  background-color: var(--success);
}
.br-step .step-progress-btn[data-alert="success"] .step-alert:after {
  content: var(--step-alert-success-icon);
}
.br-step .step-progress-btn[data-alert="info"],
.br-step .step-progress-btn[data-alert="info"] .step-info {
  color: var(--info);
}
.br-step .step-progress-btn[data-alert="info"] .step-alert {
  background-color: var(--info);
}
.br-step .step-progress-btn[data-alert="info"] .step-alert:after {
  content: var(--step-alert-info-icon);
}
.br-step .step-progress-btn[data-alert="warning"] {
  color: var(--color-light);
}
.br-step .step-progress-btn[data-alert="warning"] .step-info {
  color: var(--color);
}
.br-step .step-progress-btn[data-alert="warning"] .step-alert {
  background-color: var(--warning);
}
.br-step .step-progress-btn[data-alert="warning"] .step-alert:after {
  color: var(--color-light);
  content: var(--step-alert-warning-icon);
}
.br-step .step-progress-btn[data-alert="danger"],
.br-step .step-progress-btn[data-alert="danger"] .step-info {
  color: var(--danger);
}
.br-step .step-progress-btn[data-alert="danger"] .step-alert {
  background-color: var(--danger);
}
.br-step .step-progress-btn[data-alert="danger"] .step-alert:after {
  content: var(--step-alert-danger-icon);
}
.br-step .step-progress-btn.active:before,
.br-step .step-progress-btn[active]:before {
  background-color: var(--active);
  border-color: var(--active);
  color: var(--background);
}
.br-step .step-progress-btn.active .step-icon,
.br-step .step-progress-btn[active] .step-icon {
  color: var(--background);
}
.br-step .step-progress-btn:focus {
  outline: none;
}
.br-step .step-progress-btn.focus-visible:before,
.br-step .step-progress-btn:focus-visible:before {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
  z-index: 4;
}
.br-step .step-progress-btn:hover:not(:disabled):before {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-step .step-progress-btn:disabled {
  opacity: 1;
}
.br-step .step-progress-btn:disabled:before {
  border-color: rgba(var(--interactive-rgb), var(--disabled));
}
.br-step .step-progress-btn:disabled .step-icon {
  color: rgba(var(--interactive-rgb), var(--disabled));
}
.br-step[data-type="void"] .step-progress-btn.active .step-alert,
.br-step[data-type="void"] .step-progress-btn[active] .step-alert {
  border: var(--step-empty-border) solid var(--active);
  height: calc(var(--font-size-scale-up-04) + var(--step-empty-border));
  width: calc(var(--font-size-scale-up-04) + var(--step-empty-border));
}
.br-step[data-type="void"] .step-progress-btn[data-alert].focus-visible:before,
.br-step[data-type="void"] .step-progress-btn[data-alert]:focus-visible:before {
  outline: none;
}
.br-step[data-type="void"]
  .step-progress-btn[data-alert].focus-visible
  .step-alert,
.br-step[data-type="void"]
  .step-progress-btn[data-alert]:focus-visible
  .step-alert {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
  z-index: 4;
}
.br-step[data-type="text"] .step-progress-btn {
  cursor: default;
}
.br-step[data-type="text"] .step-progress-btn.active,
.br-step[data-type="text"] .step-progress-btn[active] {
  height: auto;
  position: static;
  width: auto;
}
.br-step[data-type="text"] .step-progress-btn.active:before,
.br-step[data-type="text"] .step-progress-btn[active]:before {
  background-color: transparent;
  border: 0;
  color: var(--color);
  position: static;
}
.br-step[data-type="text"] .step-progress-btn.focus-visible:before,
.br-step[data-type="text"] .step-progress-btn:focus-visible:before,
.br-step[data-type="text"] .step-progress-btn:focus:before {
  outline: none;
}
.br-step[data-type="text"] .step-progress-btn:hover:before {
  background-image: none;
}
.br-step.dark-mode,
.br-step.inverted {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.br-step.dark-mode .step-progress-btn[data-alert="success"],
.br-step.dark-mode .step-progress-btn[data-alert="success"] .step-info,
.br-step.inverted .step-progress-btn[data-alert="success"],
.br-step.inverted .step-progress-btn[data-alert="success"] .step-info {
  color: var(--success-alternative);
}
.br-step.dark-mode .step-progress-btn[data-alert="success"] .step-alert:after,
.br-step.inverted .step-progress-btn[data-alert="success"] .step-alert:after {
  content: var(--step-alert-success-icon);
}
.br-step.dark-mode .step-progress-btn[data-alert="info"],
.br-step.dark-mode .step-progress-btn[data-alert="info"] .step-info,
.br-step.inverted .step-progress-btn[data-alert="info"],
.br-step.inverted .step-progress-btn[data-alert="info"] .step-info {
  color: var(--info-alternative);
}
.br-step.dark-mode .step-progress-btn[data-alert="info"] .step-alert:after,
.br-step.inverted .step-progress-btn[data-alert="info"] .step-alert:after {
  content: var(--step-alert-info-icon);
}
.br-step.dark-mode .step-progress-btn[data-alert="warning"] .step-alert:after,
.br-step.inverted .step-progress-btn[data-alert="warning"] .step-alert:after {
  content: var(--step-alert-warning-icon);
}
.br-step.dark-mode .step-progress-btn[data-alert="danger"],
.br-step.dark-mode .step-progress-btn[data-alert="danger"] .step-info,
.br-step.inverted .step-progress-btn[data-alert="danger"],
.br-step.inverted .step-progress-btn[data-alert="danger"] .step-info {
  color: var(--danger-alternative);
}
.br-step.dark-mode .step-progress-btn[data-alert="danger"] .step-alert:after,
.br-step.inverted .step-progress-btn[data-alert="danger"] .step-alert:after {
  content: var(--step-alert-danger-icon);
}
.br-step.dark-mode .step-progress-btn.active,
.br-step.dark-mode .step-progress-btn[active],
.br-step.inverted .step-progress-btn.active,
.br-step.inverted .step-progress-btn[active] {
  color: var(--color-dark);
}
.br-step.dark-mode .step-progress-btn.active:before,
.br-step.dark-mode .step-progress-btn[active]:before,
.br-step.inverted .step-progress-btn.active:before,
.br-step.inverted .step-progress-btn[active]:before {
  background-color: var(--background-light);
  border-color: var(--background-light);
  color: var(--active);
}
.br-step.dark-mode .step-progress-btn.active .step-icon,
.br-step.dark-mode .step-progress-btn[active] .step-icon,
.br-step.inverted .step-progress-btn.active .step-icon,
.br-step.inverted .step-progress-btn[active] .step-icon {
  color: var(--active);
}
.br-step.dark-mode[data-type="void"] .step-progress-btn.active .step-alert,
.br-step.dark-mode[data-type="void"] .step-progress-btn[active] .step-alert,
.br-step.inverted[data-type="void"] .step-progress-btn.active .step-alert,
.br-step.inverted[data-type="void"] .step-progress-btn[active] .step-alert {
  border-color: var(--color-dark);
}
.br-step.dark-mode[data-type="text"] .step-progress-btn:before,
.br-step.inverted[data-type="text"] .step-progress-btn:before {
  background-color: transparent;
  color: var(--color);
}
.br-switch {
  --switch-height: var(--switch-height-medium);
  --switch-width: var(--switch-width-medium);
  --switch-toggle-size: var(--switch-toggle-medium);
  --switch-icon-size: var(--switch-icon-medium);
  --switch-height-small: 24px;
  --switch-height-medium: 30px;
  --switch-height-large: 36px;
  --switch-width-small: 40px;
  --switch-width-medium: 52px;
  --switch-width-large: 64px;
  --switch-toggle-small: 16px;
  --switch-toggle-medium: 22px;
  --switch-toggle-large: 28px;
  --switch-icon-small: var(--icon-size-xs);
  --switch-icon-medium: var(--icon-size-sm);
  --switch-icon-large: var(--icon-size-base);
  display: inline-flex;
  min-height: var(--switch-height);
}
.br-switch input {
  appearance: none;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
}
.br-switch input + label {
  align-items: center;
  color: var(--color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-size-scale-base);
  margin-bottom: 0;
  position: relative;
}
.br-switch input + label:before {
  background: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: 100em;
  content: "";
  height: var(--switch-height);
  position: absolute;
  width: var(--switch-width);
}
.br-switch input + label:after {
  background-color: var(--off);
  border-radius: 50%;
  content: "";
  height: var(--switch-toggle-size);
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: var(--switch-toggle-size);
}
.br-switch input:checked + label:after {
  background-color: var(--on);
}
.br-switch input + label {
  padding-right: calc(var(--spacing-scale-2x) + var(--switch-width));
}
.br-switch input + label:empty {
  padding-right: var(--switch-width);
}
.br-switch input + label:before {
  right: 0;
}
.br-switch input + label:after {
  right: calc(4px + var(--switch-toggle-size));
}
.br-switch input:checked + label:after {
  right: 4px;
}
.br-switch.right input + label {
  padding-left: calc(var(--spacing-scale-2x) + var(--switch-width));
  padding-right: 0;
}
.br-switch.right input + label:before {
  left: 0;
}
.br-switch.right input + label:after {
  left: 4px;
}
.br-switch.right input:checked + label:after {
  left: calc(var(--switch-width) - var(--switch-toggle-size) - 4px);
}
.br-switch.top {
  min-height: calc(var(--switch-height) * 2);
}
.br-switch.top input + label {
  align-items: flex-start;
  padding-right: 0;
}
.br-switch.top input + label:before {
  bottom: 0;
  left: 0;
}
.br-switch.top input + label:after {
  bottom: 4px;
  left: 4px;
}
.br-switch.top input:checked + label:after {
  left: calc(var(--switch-width) - var(--switch-toggle-size) - 4px);
}
.br-switch.small {
  --switch-height: var(--switch-height-small);
  --switch-width: var(--switch-width-small);
  --switch-toggle-size: var(--switch-toggle-small);
  --switch-icon-size: var(--switch-icon-small);
}
.br-switch.medium {
  --switch-height: var(--switch-height-medium);
  --switch-width: var(--switch-width-medium);
  --switch-toggle-size: var(--switch-toggle-medium);
  --switch-icon-size: var(--switch-icon-medium);
}
.br-switch.large {
  --switch-height: var(--switch-height-large);
  --switch-width: var(--switch-width-large);
  --switch-toggle-size: var(--switch-toggle-large);
  --switch-icon-size: var(--switch-icon-large);
}
.br-switch input ~ .switch-data {
  align-self: center;
  color: var(--color);
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-regular);
  margin-left: 8px;
}
.br-switch input ~ .switch-data:before {
  content: attr(data-disabled);
}
.br-switch input:checked ~ .switch-data {
  color: var(--color);
}
.br-switch input:checked ~ .switch-data:before {
  content: attr(data-enabled);
}
.br-switch.br-icon input + label:after {
  align-items: center;
  color: var(--color-dark);
  content: "\f00d";
  display: inline-flex;
  font-family: Font Awesome\5 Free, sans-serif;
  font-size: var(--switch-icon-size);
  font-weight: var(--font-weight-black);
  justify-content: center;
}
.br-switch.br-icon input:checked + label:after {
  content: "\f00c";
}
.br-switch input.focus-visible + label:before,
.br-switch input:focus-visible + label:before {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
  outline: none;
}
.br-switch input:hover:not([disabled]):checked + label:before {
  background-image: linear-gradient(
    rgba(var(--on-rgb), var(--hover)),
    rgba(var(--on-rgb), var(--hover))
  );
}
.br-switch input:hover:not([disabled]):not(:checked) + label:before {
  background-image: linear-gradient(
    rgba(var(--off-rgb), var(--hover)),
    rgba(var(--off-rgb), var(--hover))
  );
}
.br-switch input:active:not([disabled]):checked + label:before {
  background-image: linear-gradient(
    rgba(var(--on-rgb), var(--pressed)),
    rgba(var(--on-rgb), var(--pressed))
  );
}
.br-switch input:active:not([disabled]):not(:checked) + label:before {
  background-image: linear-gradient(
    rgba(var(--off-rgb), var(--pressed)),
    rgba(var(--off-rgb), var(--pressed))
  );
}
.br-switch.dark-mode,
.br-switch.dark-mode label,
.br-switch.inverted,
.br-switch.inverted label {
  color: var(--color-dark);
}
.br-table {
  --table-padding: var(--spacing-scale-3x);
  --table-row-size: var(--table-row-medium);
  --table-row-small: var(--spacing-scale-base);
  --table-row-medium: var(--spacing-scale-2x);
  --table-row-large: var(--spacing-scale-3x);
}
.br-table caption {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.br-table .responsive {
  overflow: auto;
}
.br-table .br-column-collapse {
  width: 40px;
}
.br-table .br-column-checkbox {
  width: 24px;
}
.br-table .table-header {
  position: relative;
}
.br-table .table-header.show .top-bar {
  visibility: hidden;
}
.br-table .top-bar {
  align-items: center;
  display: flex;
  padding: var(--spacing-scale-base) var(--spacing-scale-2x)
    var(--spacing-scale-base) var(--table-padding);
}
.br-table .top-bar .table-title,
.br-table .top-bar .title {
  color: var(--color);
  flex-grow: 1;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-semi-bold);
}
.br-table .top-bar .actions-trigger {
  margin-right: var(--spacing-scale-base);
}
.br-table .top-bar .dropdown [data-toggle="dropdown-legacy"] + *,
.br-table .top-bar .dropdown [data-toggle="dropdown"] + * {
  left: auto;
  right: 0;
}
@media (min-width: 576px) {
  .br-table .top-bar title {
    font-size: var(--font-size-scale-up-03);
    line-height: var(--font-line-height-medium);
  }
}
.br-table .search-bar {
  align-items: center;
  bottom: 0;
  display: none;
  height: 56px;
  left: 0;
  padding-left: var(--table-padding);
  padding-right: var(--spacing-scale-2x);
  position: absolute;
  right: 0;
  top: 0;
}
.br-table .search-bar.show {
  display: flex;
}
.br-table .search-bar .br-input {
  flex: 1;
}
.br-table .search-bar .br-input label {
  display: none;
}
.br-table .search-bar .br-input input {
  margin-bottom: 0;
  margin-top: 0;
}
.br-table .search-bar .br-input input + .br-button {
  --button-size: var(--button-small);
  margin-top: 0;
  position: absolute;
  right: var(--spacing-scale-half);
  top: 0;
}
.br-table .search-bar .br-input + .br-button {
  margin-left: var(--table-row-medium);
}
.br-table .selected-bar,
.br-table .selection-bar {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
  --table-padding: var(--spacing-scale-base) var(--spacing-scale-2x);
  align-items: center;
  background-color: var(--interactive-light);
  bottom: 0;
  color: var(--color);
  display: none;
  left: 0;
  padding: var(--table-padding);
  right: 0;
  top: 0;
}
.br-table .selected-bar.show,
.br-table .selection-bar.show {
  display: flex;
}
.br-table .selected-bar .info,
.br-table .selection-bar .info {
  align-items: center;
  display: flex;
  flex-grow: 1;
  white-space: nowrap;
}
.br-table .selected-bar .info .select-all,
.br-table .selected-bar .info [data-toggle="check-all"],
.br-table .selection-bar .info .select-all,
.br-table .selection-bar .info [data-toggle="check-all"] {
  font-size: var(--font-size-scale-up-03);
  margin-right: var(--spacing-scale-half);
  padding: 0;
}
.br-table .selected-bar .count,
.br-table .selected-bar .text,
.br-table .selection-bar .count,
.br-table .selection-bar .text {
  line-height: var(--font-line-height-low);
  padding-right: var(--spacing-scale-half);
}
.br-table .selected-bar.active,
.br-table .selected-bar.is-active,
.br-table .selection-bar.active,
.br-table .selection-bar.is-active {
  opacity: 1;
  top: 0;
}
.br-table .footer,
.br-table .table-footer {
  padding: var(--spacing-scale-base) var(--spacing-scale-2x)
    var(--spacing-scale-base) var(--table-padding);
}
.br-table .footer .br-input .br-button,
.br-table .table-footer .br-input .br-button {
  transform: rotate(180deg);
}
.br-table .footer .br-list,
.br-table .table-footer .br-list {
  bottom: 100%;
}
.br-table.small .clone-headers .item,
.br-table.small .headers,
.br-table.small table {
  --table-row-size: var(--table-row-small);
}
.br-table.medium .clone-headers .item,
.br-table.medium .headers,
.br-table.medium table {
  --table-row-size: var(--table-row-medium);
}
.br-table.large .clone-headers .item,
.br-table.large .headers,
.br-table.large table {
  --table-row-size: var(--table-row-large);
}
.br-table.dark-mode,
.br-table.dark-mode.dark-mode,
.br-table.dark-mode.inverted,
.br-table.inverted,
.br-table.inverted.dark-mode,
.br-table.inverted.inverted {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.br-table.dark-mode.dark-mode.dark-mode .br-input,
.br-table.dark-mode.dark-mode.dark-mode .br-input label,
.br-table.dark-mode.dark-mode.inverted .br-input,
.br-table.dark-mode.dark-mode.inverted .br-input label,
.br-table.dark-mode.inverted.dark-mode .br-input,
.br-table.dark-mode.inverted.dark-mode .br-input label,
.br-table.dark-mode.inverted.inverted .br-input,
.br-table.dark-mode.inverted.inverted .br-input label,
.br-table.inverted.dark-mode.dark-mode .br-input,
.br-table.inverted.dark-mode.dark-mode .br-input label,
.br-table.inverted.dark-mode.inverted .br-input,
.br-table.inverted.dark-mode.inverted .br-input label,
.br-table.inverted.inverted.dark-mode .br-input,
.br-table.inverted.inverted.dark-mode .br-input label,
.br-table.inverted.inverted.inverted .br-input,
.br-table.inverted.inverted.inverted .br-input label {
  --color: var(--color-dark);
  --focus-color: var(--focus-color-dark);
}
.br-table.dark-mode.dark-mode .page.active,
.br-table.dark-mode.inverted .page.active,
.br-table.inverted.dark-mode .page.active,
.br-table.inverted.inverted .page.active {
  --interactive-rgb: var(--active-rgb);
  background-color: var(--background-light);
  color: var(--active);
}
.br-table.dark-mode th,
.br-table.inverted th {
  background-color: var(--background-dark);
}
.br-table.no-hover:hover td {
  background: none;
}
.br-tab {
  --tab-padding: var(--spacing-scale-3x);
  --tab-size: var(--tab-medium);
  --tab-large: var(--spacing-scale-3x);
  --tab-medium: var(--spacing-scale-2x);
  --tab-small: var(--spacing-scale-base);
}
.br-tab .tab-nav {
  overflow-x: auto;
  width: 100%;
}
.br-tab .tab-nav::-webkit-scrollbar {
  height: var(--spacing-scale-base);
  width: var(--spacing-scale-base);
}
.br-tab .tab-nav::-webkit-scrollbar-track {
  background: var(--gray-10);
}
.br-tab .tab-nav::-webkit-scrollbar-thumb {
  background: var(--gray-30);
}
.br-tab .tab-nav:hover::-webkit-scrollbar-thumb {
  background: var(--gray-40);
}
@media (max-width: 991px) {
  .br-tab .tab-nav {
    display: flex;
    overflow-x: auto;
    width: 100%;
    width: 100%; /* Ajuste a largura de acordo com a sua necessidade */
    display: flex;
    overflow: hidden; /* Oculta a parte das guias que não está visível */
    cursor: grab; /* Mude o cursor para uma "mãozinha" ao passar o mouse */
    white-space: nowrap; /* Evita que as guias quebrem para a próxima linha */
    user-drag: none; /* Evita a seleção acidental de texto ao arrastar */
  }
  .br-tab .tab-nav::-webkit-scrollbar {
    height: var(--spacing-scale-base);
    width: var(--spacing-scale-base);
  }
  .br-tab .tab-nav::-webkit-scrollbar-track {
    background: var(--gray-10);
  }
  .br-tab .tab-nav::-webkit-scrollbar-thumb {
    background: var(--gray-30);
  }
  .br-tab .tab-nav:hover::-webkit-scrollbar-thumb {
    background: var(--gray-40);
  }
}
.br-tab .tab-nav ul {
  display: flex;
  margin: 0;
  padding: 0;
}
.br-tab .tab-item {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  white-space: nowrap;
}
.br-tab .tab-item a,
.br-tab .tab-item button {
  --focus-offset: calc(var(--spacing-scale-half) * -1);
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 4px solid transparent;
  color: var(--color);
  display: inline-block;
  font-size: var(--font-size-scale-up-02);
  font-weight: var(--font-weight-medium);
  line-height: 1;
  padding: var(--tab-size) var(--tab-padding);
  text-align: center;
  white-space: nowrap;
}
.br-tab .tab-item a:focus,
.br-tab .tab-item button:focus {
  outline: none;
}
.br-tab .tab-item a.focus-visible,
.br-tab .tab-item a:focus-visible,
.br-tab .tab-item button.focus-visible,
.br-tab .tab-item button:focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.br-tab .tab-item a:not(:disabled):hover,
.br-tab .tab-item button:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
.br-tab .tab-item:first-child {
  padding-left: 0;
}
.br-tab .tab-item:last-child {
  padding-right: 0;
}
.br-tab .tab-item.active button,
.br-tab .tab-item.is-active button,
.br-tab .tab-item.active a,
.br-tab .tab-item.is-active a {
  border-bottom: 4px solid var(--active);
  color: var(--active);
}
.br-tab .tab-item.active .results,
.br-tab .tab-item.is-active .results {
  --font-weight: var(--font-weight-semi-bold);
  color: var(--active);
}
.br-tab .results {
  display: flex;
  font-weight: var(--font-weight);
  justify-content: center;
  margin-top: var(--spacing-scale-2x);
  position: absolute;
  top: 100%;
}
.br-tab[data-counter="true"] .tab-nav ul {
  margin-bottom: calc(
    var(--spacing-scale-2x) + var(--font-size-scale-up-02) +
      var(--spacing-scale-base)
  );
}
.br-tab .tab-content .tab-panel {
  color: var(--text-color);
  display: none;
}
.br-tab .tab-content .tab-panel.active,
.br-tab .tab-content .tab-panel.is-active {
  display: block;
}
.br-tab.is-small,
.br-tab.small,
.br-tab[small] {
  --tab-size: var(--tab-small);
}
.br-tab.is-medium,
.br-tab.medium,
.br-tab[medium] {
  --tab-size: var(--tab-medium);
}
.br-tab.is-large,
.br-tab.large,
.br-tab[large] {
  --tab-size: var(--tab-large);
}
.br-tab.dark-mode,
.br-tab.inverted {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.br-tab.dark-mode .tab-item.active button,
.br-tab.inverted .tab-item.active button {
  border-bottom-color: var(--background-light);
  color: var(--color);
}
.br-tag {
  --tag-size: var(--tag-medium);
  --tag-small: var(--spacing-scale-2xh);
  --tag-medium: var(--spacing-scale-3xh);
  --tag-large: var(--spacing-scale-4xh);
  align-items: center;
  background: var(--background-dark);
  border-radius: var(--surface-rounder-sm);
  color: var(--color-dark);
  display: inline-flex;
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-medium);
  justify-content: center;
  margin: 0;
  min-height: var(--tag-size);
  padding: 0 var(--spacing-scale-baseh);
}
.br-tag.status {
  --tag-small: var(--spacing-scale-baseh);
  --tag-medium: var(--spacing-scale-2x);
  --tag-large: var(--spacing-scale-3x);
  border: 1px solid var(--background-light);
  border-radius: 50%;
  min-width: var(--tag-size);
  padding: 0;
}
.br-tag.count {
  --tag-small: var(--spacing-scale-2xh);
  --tag-medium: var(--spacing-scale-3x);
  --tag-large: var(--spacing-scale-3xh);
  border: 1px solid var(--background-light);
  border-radius: 100em;
  min-width: var(--tag-size);
  padding-left: var(--spacing-scale-base);
  padding-right: var(--spacing-scale-base);
}
.br-tag.icon {
  --tag-small: var(--spacing-scale-3xh);
  --tag-medium: var(--spacing-scale-4x);
  --tag-large: var(--spacing-scale-5xh);
  border-radius: 50%;
  min-width: var(--tag-size);
  padding: 0;
}
.br-tag.icon .fa,
.br-tag.icon .fab,
.br-tag.icon .fad,
.br-tag.icon .fal,
.br-tag.icon .far,
.br-tag.icon .fas,
.br-tag.icon .svg-inline--fa {
  font-size: var(--icon-size-base);
  margin: 0 !important;
}
.br-tag.interaction,
.br-tag.interaction-select {
  --tag-small: var(--spacing-scale-4x);
  --tag-medium: var(--spacing-scale-5x);
  --tag-large: var(--spacing-scale-5xh);
  font-size: var(--font-size-scale-up-01);
}
.br-tag.interaction {
  background: var(--interactive);
}
.br-tag.interaction .br-button {
  --button-size: var(--spacing-scale-3xh);
  --focus: var(--focus-color-dark);
  --hover: var(--hover-dark);
  --interactive-rgb: var(--color-dark-rgb);
  background-color: var(--interactive-light);
  border-radius: 50%;
  color: var(--color-dark);
  margin-left: var(--spacing-scale-baseh);
  padding: 0;
  width: var(--button-size);
}
.br-tag.interaction:hover .br-button {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-tag.interaction:active .br-button {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--pressed)),
    rgba(var(--interactive-rgb), var(--pressed))
  );
}
.br-tag.interaction-select {
  background: transparent;
  padding: 0;
}
.br-tag.interaction-select label,
.status-button {
  --interactive-rgb: var(--color-dark-rgb);
  --hover: var(--hover-dark);
  align-items: center;
  background: var(--background-dark);
  background: var(--interactive);
  border-radius: var(--surface-rounder-sm);
  color: var(--color-dark);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-size-scale-base);
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-medium);
  justify-content: center;
  margin: 0;
  min-height: var(--tag-size);
  padding: 0 var(--spacing-scale-baseh);
  transition: padding 0.15s ease;
}
.br-tag.interaction-select label + .br-tag.interaction-select label {
  margin-left: var(--spacing-scale-half);
}
.br-tag.interaction-select label > .fa:first-child,
.br-tag.interaction-select label > .fab:first-child,
.br-tag.interaction-select label > .fad:first-child,
.br-tag.interaction-select label > .fal:first-child,
.br-tag.interaction-select label > .far:first-child,
.br-tag.interaction-select label > .fas:first-child,
.br-tag.interaction-select label > .svg-inline--fa:first-child {
  margin-right: var(--spacing-scale-baseh);
}
.br-tag.interaction-select input {
  opacity: 0;
  position: relative;
  width: 0;
}
.br-tag.interaction-select input:checked + label:after {
  border: solid var(--color-dark);
  border-width: 0 3px 3px 0;
  content: "";
  height: var(--icon-size-sm);
  transform: rotate(45deg) translate(12px, -14px);
  width: 8px;
}
.br-tag.interaction-select input:focus + label {
  outline-color: var(--focus-color);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.br-tag.interaction-select input:hover + label {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-tag.interaction-select input:active + label {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--pressed)),
    rgba(var(--interactive-rgb), var(--pressed))
  );
  color: var(--color-dark);
}
.br-tag.interaction-select.selected label {
  background-color: var(--selected);
  padding-right: var(--spacing-scale-4xh);
}
.br-tag.disabled input {
  cursor: not-allowed;
}
.br-tag + .br-tag {
  margin-left: var(--spacing-scale-half);
}
.br-tag > .fa:first-child,
.br-tag > .fab:first-child,
.br-tag > .fad:first-child,
.br-tag > .fal:first-child,
.br-tag > .far:first-child,
.br-tag > .fas:first-child,
.br-tag > .svg-inline--fa:first-child {
  margin-right: var(--spacing-scale-baseh);
}
.br-tag.small {
  --tag-size: var(--tag-small);
}
.br-tag.medium {
  --tag-size: var(--tag-medium);
}
.br-tag.large {
  --tag-size: var(--tag-large);
}
.br-tag.support-01 {
  --tag-background: var(--color-support-01);
}
.br-tag.support-02 {
  --tag-background: var(--color-support-02);
}
.br-tag.support-03 {
  --tag-background: var(--color-support-03);
}
.br-tag.support-04 {
  --tag-background: var(--color-support-04);
}
.br-tag.support-05 {
  --tag-background: var(--color-support-05);
}
.br-tag.support-06 {
  --tag-background: var(--color-support-06);
}
.br-tag.support-07 {
  --tag-background: var(--color-support-07);
}
.br-tag.support-08 {
  --tag-background: var(--color-support-08);
}
.br-tag.support-09 {
  --tag-background: var(--color-support-09);
}
.br-tag.support-10 {
  --tag-background: var(--color-support-10);
}
.br-tag.support-11 {
  --tag-background: var(--color-support-11);
}
.br-tag.is-primary-pastel-01,
.br-tag.primary-pastel-01,
.br-tag[primary-pastel-01] {
  background-color: #c5d4eb;
}
.br-tag.is-primary-pastel-02,
.br-tag.primary-pastel-02,
.br-tag[primary-pastel-02] {
  background-color: #dbe8fb;
}
.br-tag.is-primary-lighten-01,
.br-tag.primary-lighten-01,
.br-tag[primary-lighten-01] {
  background-color: #2670e8;
}
.br-tag.is-primary-lighten-02,
.br-tag.primary-lighten-02,
.br-tag[primary-lighten-02] {
  background-color: #5992ed;
}
.br-tag.is-primary-default,
.br-tag.primary-default,
.br-tag[primary-default] {
  background-color: #1351b4;
}
.br-tag.is-primary-darken-01,
.br-tag.primary-darken-01,
.br-tag[primary-darken-01] {
  background-color: #0c326f;
}
.br-tag.is-primary-darken-02,
.br-tag.primary-darken-02,
.br-tag[primary-darken-02] {
  background-color: #071d41;
}
.br-tag.is-secondary-01,
.br-tag.secondary-01,
.br-tag[secondary-01] {
  background-color: #fff;
}
.br-tag.is-secondary-02,
.br-tag.secondary-02,
.br-tag[secondary-02] {
  background-color: #f8f8f8;
}
.br-tag.is-secondary-03,
.br-tag.secondary-03,
.br-tag[secondary-03] {
  background-color: #ededed;
}
.br-tag.is-secondary-04,
.br-tag.secondary-04,
.br-tag[secondary-04] {
  background-color: #ccc;
}
.br-tag.is-secondary-05,
.br-tag.secondary-05,
.br-tag[secondary-05] {
  background-color: #9e9d9d;
}
.br-tag.is-secondary-06,
.br-tag.secondary-06,
.br-tag[secondary-06] {
  background-color: #888;
}
.br-tag.is-secondary-07,
.br-tag.secondary-07,
.br-tag[secondary-07] {
  background-color: #555;
}
.br-tag.is-secondary-08,
.br-tag.secondary-08,
.br-tag[secondary-08] {
  background-color: #333;
}
.br-tag.is-secondary-09,
.br-tag.secondary-09,
.br-tag[secondary-09] {
  background-color: #000;
}
.br-tag.highlight,
.br-tag.is-highlight,
.br-tag[highlight] {
  background-color: #268744;
}
.br-tag.is-support-01,
.br-tag.support-01,
.br-tag[support-01] {
  background-color: #36a191;
}
.br-tag.is-support-02,
.br-tag.support-02,
.br-tag[support-02] {
  background-color: #f2e317;
}
.br-tag.is-support-03,
.br-tag.support-03,
.br-tag[support-03] {
  background-color: #db4800;
}
.br-tag.is-support-04,
.br-tag.support-04,
.br-tag[support-04] {
  background-color: #a26739;
}
.br-tag.is-support-05,
.br-tag.support-05,
.br-tag[support-05] {
  background-color: #40e0d0;
}
.br-tag.is-support-06,
.br-tag.support-06,
.br-tag[support-06] {
  background-color: #48cbeb;
}
.br-tag.is-support-07,
.br-tag.support-07,
.br-tag[support-07] {
  background-color: #c72487;
}
.br-tag.is-support-08,
.br-tag.support-08,
.br-tag[support-08] {
  background-color: #63007f;
}
.br-tag.is-support-09,
.br-tag.support-09,
.br-tag[support-09] {
  background-color: #f08080;
}
.br-tag.is-support-10,
.br-tag.support-10,
.br-tag[support-10] {
  background-color: #ff8c00;
}
.br-tag.is-support-11,
.br-tag.support-11,
.br-tag[support-11] {
  background-color: #fdf5e6;
}
.br-tag.is-success,
.br-tag.success,
.br-tag[success] {
  background-color: var(--success);
}
.br-tag.danger,
.br-tag.is-danger,
.br-tag[danger] {
  background-color: var(--danger);
}
.br-tag.is-warning,
.br-tag.warning,
.br-tag[warning] {
  background-color: var(--warning);
}
.br-tag.info,
.br-tag.is-info,
.br-tag[info] {
  background-color: var(--info);
}
.br-tag-input {
  --input-background: var(--bg-color);
  --input-border-color: var(--color-secondary-06);
  --input-border-width: 1px;
  --input-border-style: solid;
  --input-padding: 0 var(--spacing-scale-half);
  --input-radius: var(--surface-rounder-sm);
  background: var(--input-background);
  border: var(--input-border-width) var(--input-border-style)
    var(--input-border-color);
  border-radius: var(--input-radius);
  color: var(--text-color);
  display: block;
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-medium);
  height: var(--input-size);
  padding: var(--input-padding);
  width: 100%;
}
.br-tag-input:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
.br-tag-input.focus-visible,
.br-tag-input:focus,
.br-tag-input:focus-visible {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
  outline: none;
}
.br-tag-input.has-icon {
  padding-right: var(--spacing-scale-5x);
}
.br-tag-input[type="password"]::-ms-clear,
.br-tag-input[type="password"]::-ms-reveal {
  display: none;
}
.br-tag-input input {
  flex: 1;
}
.input-tag {
  border: 0;
  box-sizing: border-box;
  color: inherit;
  flex: 1;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  padding: 0.9em 0.5em;
}
.br-textarea {
  --textarea-padding: var(--textarea-medium);
  --textarea-small: var(--spacing-scale-base);
  --textarea-medium: var(--spacing-scale-baseh);
  --textarea-large: var(--spacing-scale-2x);
  color: var(--color);
}
.br-textarea label {
  display: inline-block;
  margin-bottom: var(--spacing-scale-half);
}
.br-textarea textarea {
  background: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  color: var(--color);
  display: block;
  font-size: var(--font-size-scale-up-01);
  font-weight: var(--font-weight-medium);
  padding: var(--textarea-padding);
  width: 100%;
}
.br-textarea.small textarea,
.br-textarea[data-small] textarea {
  --textarea-padding: var(--textarea-small);
}
.br-textarea.medium textarea,
.br-textarea[data-medium] textarea {
  --textarea-padding: var(--textarea-medium);
}
.br-textarea.large textarea,
.br-textarea[data-large] textarea {
  --textarea-padding: var(--textarea-large);
}
.br-textarea.success textarea,
.br-textarea[data-success] textarea {
  border-color: var(--success);
  border-width: 2px;
}
.br-textarea.danger textarea,
.br-textarea[data-danger] textarea {
  border-color: var(--danger);
  border-width: 2px;
}
.br-textarea.warning textarea,
.br-textarea[data-warning] textarea {
  border-color: var(--warning);
  border-width: 2px;
}
.br-textarea.info textarea,
.br-textarea[data-info] textarea {
  border-color: var(--info);
  border-width: 2px;
}
.br-textarea textarea.focus-visible,
.br-textarea textarea:focus,
.br-textarea textarea:focus-visible {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
  outline: none;
}
.br-textarea textarea:hover:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--color-rgb), var(--hover)),
    rgba(var(--color-rgb), var(--hover))
  );
}
.br-textarea.dark-mode,
.br-textarea.dark-mode label,
.br-textarea.inverted,
.br-textarea.inverted label {
  --color: var(--color-dark);
  --focus-color: var(--focus-color-dark);
}
.br-tooltip {
  align-items: flex-start;
  background: var(--color-info, #155bcb);
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: var(--color-secondary-01, #fff);
  display: flex !important;
  flex-wrap: wrap;
  font-size: var(--font-size-scale-down-01, 11.662px);
  font-weight: var(--font-weight-medium, 500);
  justify-content: flex-start;
  max-width: 240px;
  opacity: 0;
  padding: var(--spacing-scale-2x);
  position: absolute !important;
  visibility: hidden;
  z-index: 999;
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.br-tooltip[data-show] {
  animation: fadeInOpacity 0.5s ease-in-out;
  opacity: 1;
  visibility: visible;
}
.br-tooltip[popover] {
  max-width: 320px;
  min-width: 240px;
  overflow-x: hidden;
  width: auto;
}
.br-tooltip[success] {
  background: var(--color-success, #168821);
}
.br-tooltip[success] .arrow {
  border-color: var(--color-success, #168821);
}
.br-tooltip[error] {
  background: var(--color-danger, #e52207);
}
.br-tooltip[error] .arrow {
  border-color: var(--color-danger, #e52207);
}
.br-tooltip[warning] {
  background: var(--color-warning, #ffcd07);
  color: var(--color-secondary-08, #333);
}
.br-tooltip[warning] .arrow {
  border-color: var(--color-warning, #ffcd07);
}
.br-tooltip,
.br-tooltip[info] {
  background: var(--color-info, #155bcb);
}
.br-tooltip .arrow,
.br-tooltip[info] .arrow {
  border-color: var(--color-info, #155bcb);
}
.br-tooltip .arrow {
  height: 0 !important;
  position: absolute !important;
  width: 0 !important;
  z-index: -1 !important;
}
.br-tooltip[data-popper-placement^="top"] > .arrow {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top-style: solid;
  border-top-width: 8px;
  bottom: -7px;
  margin-left: 0;
}
.br-tooltip[data-popper-placement^="bottom"] > .arrow {
  border-bottom-style: solid;
  border-bottom-width: 8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  margin-left: 0;
  top: -8px;
}
.br-tooltip[data-popper-placement^="left"] > .arrow {
  border-bottom: 8px solid transparent;
  border-left-style: solid;
  border-left-width: 8px;
  border-top: 8px solid transparent;
  right: calc(var(--spacing-scale-base) * -1);
}
.br-tooltip[data-popper-placement^="right"] > .arrow {
  border-bottom: 8px solid transparent;
  border-right-style: solid;
  border-right-width: 8px;
  border-top: 8px solid transparent;
  left: calc(var(--spacing-scale-base) * -1);
}
.br-tooltip .link,
.br-tooltip .subtext,
.br-tooltip .text {
  display: block;
  max-width: 100%;
  min-width: 0;
}
.br-tooltip .popover-header {
  font-size: var(--font-size-scale-base, 14px);
  margin-bottom: 1em;
  min-width: 180px;
  text-align: center;
  width: 100%;
}
.br-tooltip .popover-header .fas,
.br-tooltip .popover-header .svg-inline--fa {
  font-size: var(--font-size-scale-up-06, 41.804px);
  text-align: center;
}
.br-tooltip .popover-image {
  border: 3px solid var(--color-secondary-01, #fff);
}
.br-tooltip .popover-icon,
.br-tooltip .popover-image {
  height: auto;
  margin-left: -10px;
  max-height: 120px;
  max-width: 45%;
  overflow: hidden;
  width: auto;
}
.br-tooltip .popover-icon {
  margin-top: 10%;
}
.br-tooltip .popover-body {
  font-size: var(--font-size-scale-down-01, 11.662px);
  margin-bottom: 1em;
  min-width: 180px;
  width: 100%;
}
.br-tooltip .popover-image + div {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  width: 65%;
}
.br-tooltip .popover-image + div div {
  padding-left: var(--spacing-scale-base);
  text-align: left;
}
.br-tooltip .popover-icon + div {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  width: 65%;
}
.br-tooltip .popover-icon + div div {
  padding-left: var(--spacing-scale-base);
  text-align: left;
}
.br-tooltip .popover-footer {
  font-size: var(--font-size-scale-down-01, 11.662px);
  min-width: 180px;
  text-align: center;
  width: 100%;
}
.br-tooltip .text {
  font-size: var(--font-size-scale-base, 14px);
  font-weight: var(--font-weight-semi-bold, 600);
}
.br-tooltip .subtext {
  font-size: var(--font-size-scale-down-01, 11.662px);
  font-weight: var(--font-weight-medium, 500);
}
.br-tooltip .link {
  color: inherit;
  text-align: right;
  text-decoration: underline;
  width: 100%;
}
.br-tooltip .close {
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
}
.br-upload input {
  display: none;
}
.br-upload .upload-button {
  --button-radius: 100em;
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;
  --button-size: var(--button-medium);
  align-items: center;
  background-color: transparent;
  border: 0;
  border: var(--surface-width-sm) dashed var(--interactive);
  border-radius: var(--button-radius) !important;
  border-radius: var(--surface-rounder-sm);
  color: var(--interactive);
  cursor: pointer;
  display: inline-flex;
  display: block;
  font-size: var(--font-size-scale-up-01);
  font-size: var(--font-size-scale-base);
  font-style: italic;
  font-weight: var(--font-weight-semi-bold);
  font-weight: var(--font-weight-regular);
  height: var(--button-size);
  justify-content: center;
  margin-top: var(--spacing-scale-half);
  max-width: 550px;
  min-height: var(--button-size);
  overflow: hidden;
  padding: 0 var(--spacing-scale-3x);
  padding-left: var(--spacing-scale-2x);
  padding-right: var(--spacing-scale-2x);
  position: relative;
  text-align: center;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  width: 100%;
}
.br-upload .upload-button .fa,
.br-upload .upload-button .fab,
.br-upload .upload-button .fad,
.br-upload .upload-button .fal,
.br-upload .upload-button .far,
.br-upload .upload-button .fas,
.br-upload .upload-button .svg-inline--fa {
  margin-right: var(--spacing-scale-base);
}
.br-upload .upload-list {
  max-width: 550px;
  position: relative;
}
.br-upload .upload-list .content {
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
}
.br-upload .upload-list .support {
  align-items: center;
  display: flex;
}
.br-upload .br-tooltip {
  max-width: 93%;
}
.br-upload .upload-button:disabled {
  cursor: not-allowed;
}
.br-upload .upload-button:not(:disabled) {
  --focus-offset: var(--spacing-scale-half);
}
.br-upload .upload-button:not(:disabled):focus {
  outline: none;
}
.br-upload .upload-button:not(:disabled).focus-visible,
.br-upload .upload-button:not(:disabled):focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.br-upload .upload-button:not(:disabled):not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-upload .upload-button:not(:disabled):not(:disabled):active {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--pressed)),
    rgba(var(--interactive-rgb), var(--pressed))
  );
}
.br-upload .upload-button.active,
.br-upload .upload-button.is-active,
.br-upload .upload-button[active] {
  --hover: var(--hover-dark);
  background-color: var(--active);
  color: var(--color-dark);
}
.br-upload .upload-button.is-loading:after,
.br-upload .upload-button.loading:after,
.br-upload .upload-button[loading]:after {
  border-color: var(--interactive) var(--interactive) transparent;
  border-style: solid;
}
.br-upload .upload-button.is-loading.danger:after,
.br-upload .upload-button.is-loading.info:after,
.br-upload .upload-button.is-loading.primary:after,
.br-upload .upload-button.is-loading.success:after,
.br-upload .upload-button.loading.danger:after,
.br-upload .upload-button.loading.info:after,
.br-upload .upload-button.loading.primary:after,
.br-upload .upload-button.loading.success:after,
.br-upload .upload-button[loading].danger:after,
.br-upload .upload-button[loading].info:after,
.br-upload .upload-button[loading].primary:after,
.br-upload .upload-button[loading].success:after {
  border-color: var(--background) var(--background) transparent;
}
.br-upload.success .upload-button,
.br-upload[data-success] .upload-button {
  border-color: var(--success);
}
.br-upload.danger .upload-button,
.br-upload[data-danger] .upload-button {
  border-color: var(--danger);
}
.br-upload.warning .upload-button,
.br-upload[data-warning] .upload-button {
  border-color: var(--warning);
}
.br-upload.info .upload-button,
.br-upload[data-info] .upload-button {
  border-color: var(--info);
}
.br-upload.dragging .upload-button {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-wizard {
  --wizard-min-height: 300px;
  --wizard-max-height: 800px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: var(--spacing-scale-4x);
  max-height: var(--wizard-max-height);
  max-width: none;
  min-height: var(--wizard-min-height);
}
.br-wizard .wizard-progress {
  background-color: var(--background-alternative);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-template-rows: none;
  min-height: 164px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: var(--spacing-scale-7x);
  width: 100%;
}
@media (max-width: 991px) {
  .br-wizard .wizard-progress {
    max-height: 100px;
    min-height: 100px;
    overflow-y: hidden;
    padding-top: var(--spacing-scale-3x);
    position: relative;
  }
  .br-wizard .wizard-progress,
  .br-wizard .wizard-progress:after {
    transition-delay: 0s;
    transition-duration: 0.25s;
    transition-property: all;
    transition-timing-function: linear;
  }
  .br-wizard .wizard-progress:after {
    background-color: transparent;
    bottom: 2px;
    color: var(--interactive);
    content: "\f7a4";
    display: block;
    font-family: Font Awesome\5 Free, sans-serif;
    font-size: var(--switch-icon-size);
    font-weight: var(--font-weight-black);
    height: 1em;
    left: 50%;
    margin-left: -0.5em;
    position: absolute;
    top: unset;
    width: auto;
  }
}
.br-wizard .wizard-progress-btn {
  background-color: transparent;
  border: 0;
  box-shadow: none !important;
  color: var(--interactive);
  font-size: var(--font-size-scale-up-01, 16.8px);
  line-height: 19px;
  max-height: 90px;
  padding-bottom: 0;
  padding-top: var(--spacing-scale-2x);
  position: relative;
  text-indent: 0;
  transition-delay: 0s;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: linear;
}
.br-wizard .wizard-progress-btn[disabled] {
  color: rgba(var(--interactive-rgb), var(--disabled));
  opacity: 1;
}
.br-wizard .wizard-progress-btn[disabled]:before {
  opacity: var(--disabled);
}
.br-wizard .wizard-progress-btn:focus {
  outline: none;
}
.br-wizard
  .wizard-progress-btn.focus-visible:not([disabled]):not(.disabled):before,
.br-wizard
  .wizard-progress-btn:focus-visible:not([disabled]):not(.disabled):before {
  outline-color: var(--focus-color);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
.br-wizard .wizard-progress-btn:hover:not([disabled]):before {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.br-wizard .wizard-progress-btn:active:not([disabled]):before {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--pressed)),
    rgba(var(--interactive-rgb), var(--pressed))
  );
}
.br-wizard .wizard-progress-btn:before {
  background-color: var(--background);
  border: 2px solid var(--interactive);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--interactive);
  content: attr(step) !important;
  display: block;
  font-size: var(--font-size-scale-up-02, 20.16px);
  font-weight: var(--font-weight-semi-bold, 600);
  height: 36px;
  left: 50%;
  line-height: 29px;
  margin-top: -13px;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  transition: all 0.15s linear 0s,
    transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  width: 36px;
  z-index: 3;
}
.br-wizard .wizard-progress-btn:after {
  background-color: var(--interactive) !important;
  content: "";
  display: block;
  height: 1px;
  left: calc(-50% + 17px);
  position: absolute;
  top: 5px;
  transition-delay: 0s;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: linear;
  width: calc(100% - 34px);
  z-index: 1;
}
.br-wizard .wizard-progress-btn:first-child:after {
  display: none;
}
.br-wizard .wizard-progress-btn[active] {
  color: var(--active);
  font-weight: var(--font-weight-semi-bold, 600);
}
.br-wizard .wizard-progress-btn[active]:after {
  background-color: var(--active);
}
.br-wizard .wizard-progress-btn[active]:before {
  background-color: var(--active);
  border: 2px solid var(--background-light);
  color: var(--background-light);
  font-weight: var(--font-weight-bold, 700);
}
@media (max-width: 991px) {
  .br-wizard .wizard-progress-btn .info {
    font-size: var(--font-size-scale-down-02, 9.716px);
    line-height: 1em;
    text-align: center;
  }
  .br-wizard[collapsed] .wizard-progress {
    max-height: 64px;
    min-height: 64px;
    overflow-y: hidden;
    padding-bottom: var(--spacing-scale-3x);
    padding-top: var(--spacing-scale-3x);
  }
  .br-wizard[collapsed] .wizard-progress:after {
    margin-top: var(--spacing-scale-4xh);
  }
  .br-wizard[collapsed] .wizard-progress-btn {
    padding-bottom: var(--spacing-scale-base);
  }
  .br-wizard[collapsed] .wizard-progress-btn .info {
    display: none;
  }
  .br-wizard[collapsed] .wizard-form {
    height: auto;
  }
  .br-wizard[collapsed] .wizard-panel-content {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.br-wizard .wizard-form {
  display: flex;
  flex: 1;
  height: auto;
  overflow: hidden;
  position: relative;
}
.br-wizard .wizard-panel {
  display: none;
  flex-direction: column;
  flex-wrap: nowrap;
  height: auto;
  left: 0;
  margin-bottom: 0;
  opacity: 0;
  position: static;
  top: 0;
  visibility: hidden;
  width: 100%;
}
.br-wizard .wizard-panel[active] {
  display: flex;
  height: auto;
  left: 0 !important;
  opacity: 1;
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: linear;
  visibility: visible;
}
@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(1%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.br-wizard .wizard-panel-content {
  border-top: 1px solid var(--border-color);
  flex-grow: 1;
  height: auto;
  max-height: none;
  overflow-x: auto;
  overflow-y: auto;
  padding: var(--spacing-scale-base) var(--spacing-scale-2x);
}
.br-wizard .wizard-panel-content::-webkit-scrollbar {
  height: var(--spacing-scale-base);
  width: var(--spacing-scale-base);
}
.br-wizard .wizard-panel-content::-webkit-scrollbar-track {
  background: var(--gray-10);
}
.br-wizard .wizard-panel-content::-webkit-scrollbar-thumb {
  background: var(--gray-30);
}
.br-wizard .wizard-panel-content:hover::-webkit-scrollbar-thumb {
  background: var(--gray-40);
}
.br-wizard .wizard-panel-content > :last-child {
  margin-bottom: 0;
}
.br-wizard .wizard-panel-btn {
  align-self: flex-end;
  background-color: var(--background-alternative);
  border-top: 1px solid var(--border-color);
  height: fit-content;
  margin-top: 1px;
  padding: var(--spacing-scale-2x) 1.5%;
  width: 100%;
}
.br-wizard .wizard-btn,
.br-wizard .wizard-btn-next,
.br-wizard .wizard-btn-prev {
  float: right;
  margin-left: var(--spacing-scale-2x);
}
.br-wizard .wizard-btn-canc {
  float: left;
}
@media (max-width: 991px) {
  .br-wizard .wizard-form {
    height: auto;
  }
  .br-wizard .wizard-panel {
    border-top: 0;
    display: none;
    flex-wrap: nowrap;
    margin-bottom: 0;
    max-height: none;
  }
  .br-wizard .wizard-panel-content {
    height: auto;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: var(--spacing-scale-half) var(--spacing-scale-base);
    transition-delay: 0s;
    transition-duration: 0.25s;
    transition-property: all;
    transition-timing-function: linear;
  }
  .br-wizard .wizard-panel-btn {
    align-items: flex-end;
    background-color: var(--background-alternative);
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    justify-content: center;
    padding: 0 !important;
    position: static;
    width: 100%;
  }
  .br-wizard .wizard-btn,
  .br-wizard .wizard-btn-canc,
  .br-wizard .wizard-btn-next,
  .br-wizard .wizard-btn-prev {
    float: none;
    margin: var(--spacing-scale-base) 5%;
    width: 90%;
  }
  .br-wizard .wizard-btn,
  .br-wizard .wizard-btn-next {
    order: 1;
  }
}
.br-wizard[vertical] {
  flex-direction: row;
}
.br-wizard[vertical] .wizard-progress {
  flex: 1;
  float: none;
  grid-template-columns: 1fr;
  height: auto;
  max-width: 260px;
  overflow-x: hidden;
  padding-top: 0;
  position: relative;
  text-align: right;
}
.br-wizard[vertical] .wizard-progress-btn {
  height: 100%;
  line-height: 100%;
  max-height: 100%;
  padding-bottom: 0;
  padding-right: 70px;
  padding-top: 0;
  text-align: right;
}
.br-wizard[vertical] .wizard-progress-btn:before {
  left: calc(100% - 32px);
  line-height: 26px;
  margin-top: calc(var(--spacing-scale-2x) * -1);
  position: absolute;
  text-align: center;
  top: 50%;
}
.br-wizard[vertical] .wizard-progress-btn:after {
  height: calc(100% - 34px);
  left: calc(100% - 33px);
  position: absolute;
  top: calc(-50% + 18px);
  width: 1px;
}
.br-wizard[vertical] .wizard-progress-btn[active]:after {
  width: 1px;
}
.br-wizard[vertical][scroll] .wizard-progress {
  overflow-y: auto;
}
.br-wizard[vertical][scroll] .wizard-progress::-webkit-scrollbar {
  height: var(--spacing-scale-base);
  width: var(--spacing-scale-base);
}
.br-wizard[vertical][scroll] .wizard-progress::-webkit-scrollbar-track {
  background: var(--gray-10);
}
.br-wizard[vertical][scroll] .wizard-progress::-webkit-scrollbar-thumb {
  background: var(--gray-30);
}
.br-wizard[vertical][scroll] .wizard-progress:hover::-webkit-scrollbar-thumb {
  background: var(--gray-40);
}
.br-wizard[vertical][scroll] .wizard-progress .wizard-progress-btn {
  min-height: 100px;
}
.br-wizard[vertical] .wizard-form {
  float: none;
  height: auto;
  width: calc(100% - 260px);
}
.br-wizard[vertical] .wizard-form .wizard-panel .wizard-panel-content {
  border-top: 0;
}
@media (max-width: 991px) {
  .br-wizard[vertical] .wizard-progress {
    max-height: 100%;
    max-width: 110px;
  }
  .br-wizard[vertical] .wizard-progress:after {
    height: 1em;
    left: unset;
    margin-top: -0.5em;
    right: 2px;
    top: 50%;
    transform: rotate(-90deg);
    width: 1em;
  }
  .br-wizard[vertical] .wizard-progress-btn {
    line-height: 18px;
    padding-right: var(--spacing-scale-7x);
  }
  .br-wizard[vertical] .wizard-progress-btn .info {
    line-height: 1.6em;
    margin-top: -0.5em;
    position: absolute;
    right: 54px;
  }
  .br-wizard[vertical] .wizard-form {
    height: auto;
    max-width: calc(100% - 110px);
    min-width: calc(100% - 110px);
    transition-delay: 0s;
    transition-duration: 0.25s;
    transition-property: all;
    transition-timing-function: linear;
  }
  .br-wizard[vertical] .wizard-panel {
    height: 100%;
    max-height: 100%;
  }
  .br-wizard[vertical][collapsed] .wizard-progress {
    max-width: 60px;
    padding-bottom: 0;
    padding-top: 0;
  }
  .br-wizard[vertical][collapsed] .wizard-progress-btn {
    padding-right: 0;
  }
  .br-wizard[vertical][collapsed] .wizard-progress-btn .info {
    display: none;
  }
  .br-wizard[vertical][collapsed] .wizard-form {
    max-width: calc(100% - 60px);
    min-width: calc(100% - 60px);
  }
}
.br-wizard.dark-mode,
.br-wizard.inverted {
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color-dark);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--visited-dark);
  --hover: var(--hover-dark);
  --pressed: var(--pressed-dark);
  --focus-color: var(--focus-color-dark);
  --focus: var(--focus-color-dark);
}
.br-wizard.dark-mode .br-button.is-primary,
.br-wizard.dark-mode .br-button.primary,
.br-wizard.dark-mode .br-button[primary],
.br-wizard.inverted .br-button.is-primary,
.br-wizard.inverted .br-button.primary,
.br-wizard.inverted .br-button[primary] {
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --text-color: var(--color-light);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--background-dark-rgb);
  --visited: var(--visited-light);
  --hover: var(--hover-light);
  --pressed: var(--pressed-light);
  --focus-color: var(--focus-color-light);
  --focus: var(--focus-color-light);
  background-color: var(--interactive-dark);
  color: var(--background-dark);
}
.br-wizard.dark-mode .br-button.is-secondary,
.br-wizard.dark-mode .br-button.secondary,
.br-wizard.dark-mode .br-button[secondary],
.br-wizard.inverted .br-button.is-secondary,
.br-wizard.inverted .br-button.secondary,
.br-wizard.inverted .br-button[secondary] {
  background-color: var(--background-dark);
}
.br-wizard.dark-mode .wizard-progress,
.br-wizard.inverted .wizard-progress {
  background-color: transparent;
}
.br-wizard.dark-mode .wizard-progress-btn[active],
.br-wizard.inverted .wizard-progress-btn[active] {
  color: var(--color);
}
.br-wizard.dark-mode .wizard-progress-btn[active]:before,
.br-wizard.inverted .wizard-progress-btn[active]:before {
  --interactive-rgb: var(--active-rgb);
  background-color: var(--color);
  color: var(--active);
}
.br-wizard.dark-mode .wizard-panel .wizard-panel-btn,
.br-wizard.inverted .wizard-panel .wizard-panel-btn {
  background-color: transparent;
}
.accordion-example .br-list .header {
  align-items: var(--spacing-vertical-center);
  display: flex;
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-bold);
  justify-content: space-between;
  padding: var(--spacing-scale-base) var(--spacing-scale-2x);
}
.accordion-example .br-list .br-item {
  text-align: var(--spacing-horizontal-left);
  width: 100%;
}
.accordion-example .br-list .br-item .fa,
.accordion-example .br-list .br-item .fab,
.accordion-example .br-list .br-item .fad,
.accordion-example .br-list .br-item .fal,
.accordion-example .br-list .br-item .far,
.accordion-example .br-list .br-item .fas,
.accordion-example .br-list .br-item .svg-inline--fa {
  text-align: center;
}
.accordion-example .br-list .br-item > .content {
  align-items: var(--spacing-vertical-center);
  display: flex;
}
.accordion-example .br-list .br-item > .content > .content {
  flex: 1;
}
.accordion-example .br-list .br-item .content:hover button.br-button {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.accordion-example .br-list .br-item .br-checkbox,
.accordion-example .br-list .br-item .br-radio {
  --interactive-rgb: var(--rgb-primary-default);
  --item-padding-x: 0;
  --item-padding-y: 0;
}
.accordion-example
  .br-list
  .br-item
  .br-checkbox
  input:hover:not(:disabled)
  + label:before,
.accordion-example
  .br-list
  .br-item
  .br-radio
  input:hover:not(:disabled)
  + label:before {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.accordion-example .br-list .br-item[data-toggle] {
  cursor: pointer;
}
.accordion-example .br-list .br-item[data-toggle] ~ .br-list .br-item {
  padding: var(--spacing-scale-base) var(--spacing-scale-3x);
}
.accordion-example .br-list .br-list {
  max-height: none;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}
.accordion-example .br-list.horizontal {
  display: flex;
  flex-wrap: wrap;
}
.accordion-example .br-list.horizontal > .header {
  flex-grow: 1;
  width: 100%;
}
.accordion-example .br-list.horizontal > .header + .br-divider {
  border-top: 1px solid var(--color-secondary-04);
  width: 100%;
}
.accordion-example .br-list.horizontal > .br-item,
.accordion-example .br-list.horizontal > .group {
  flex: 1;
  height: auto;
  min-height: 56px;
  width: auto;
}
.accordion-example .br-list.horizontal > .br-item.one-line > .content,
.accordion-example .br-list.horizontal > .group.one-line > .content {
  height: 56px;
}
.accordion-example .br-list.horizontal > .br-item.two-lines > .content,
.accordion-example .br-list.horizontal > .group.two-lines > .content {
  height: 72px;
}
.accordion-example .br-list.horizontal > .br-item.three-lines > .content,
.accordion-example .br-list.horizontal > .group.three-lines > .content {
  height: 6em;
}
.accordion-example .br-list.horizontal > .br-item + .br-divider,
.accordion-example .br-list.horizontal > .group + .br-divider {
  border-right: 1px solid var(--color-secondary-04);
  border-top: 0;
  width: 1px;
}
.accordion-example .br-list.horizontal.toggle > .br-item,
.accordion-example .br-list.horizontal[data-toggle] > .br-item {
  display: block;
}
.accordion-example .br-list[data-one-line] .accordion-example .br-list .br-item,
.accordion-example .br-list[data-one-line] .br-item {
  height: 2em;
}
.accordion-example
  .br-list[data-one-line]
  .accordion-example
  .br-list
  .br-item
  .content,
.accordion-example .br-list[data-one-line] .br-item .content {
  height: auto;
  overflow: hidden;
  padding: 0 var(--spacing-scale-2x);
  text-overflow: unset;
  white-space: normal;
}
.accordion-example
  .br-list[data-two-lines]
  .accordion-example
  .br-list
  .br-item,
.accordion-example .br-list[data-two-lines] .br-item {
  height: 4em;
}
.accordion-example
  .br-list[data-two-lines]
  .accordion-example
  .br-list
  .br-item
  .content,
.accordion-example .br-list[data-two-lines] .br-item .content {
  height: auto;
  overflow: hidden;
  padding: 0 var(--spacing-scale-2x);
  text-overflow: unset;
  white-space: normal;
}
.accordion-example
  .br-list[data-three-lines]
  .accordion-example
  .br-list
  .br-item,
.accordion-example .br-list[data-three-lines] .br-item {
  height: 6em;
}
.accordion-example
  .br-list[data-three-lines]
  .accordion-example
  .br-list
  .br-item
  .content,
.accordion-example .br-list[data-three-lines] .br-item .content {
  height: auto;
  overflow: hidden;
  padding: 0 var(--spacing-scale-2x);
  text-overflow: unset;
  white-space: normal;
}
.checkgroup-example {
  --checkbox-background: var(--bg-color);
  --checkbox-color: var(--text-color);
  --checkbox-font-weight: var(--font-weight-medium);
  --checkbox-padding: var(--spacing-scale-base);
  --checkbox-size: 24px;
  --checkbox-line-height: 1.6em;
  display: block;
  line-height: 0px;
}
.checkgroup-example label {
  color: var(--checkbox-color);
  cursor: pointer;
  display: inline-block;
  font-weight: var(--checkbox-font-weight);
  line-height: var(--checkbox-line-height);
  min-height: var(--checkbox-size);
  padding-left: calc(var(--checkbox-size) + var(--checkbox-padding));
  position: relative;
}
.checkgroup-example label:empty {
  padding-left: var(--checkbox-size);
}
.checkgroup-example.hidden-label label {
  padding-left: calc(var(--checkbox-size) + var(--surface-width-md) * 2);
  text-indent: -10000px;
  white-space: nowrap;
  width: 0;
}
.checkgroup-example.d-inline label {
  padding-right: var(--spacing-scale-4x);
}
.checkgroup-example input {
  opacity: 0;
  position: absolute;
}
.checkgroup-example input.focus-visible + label:before,
.checkgroup-example input.focus-visible:checked + label:before,
.checkgroup-example input:focus-visible + label:before,
.checkgroup-example input:focus-visible:checked + label:before {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
  outline: none;
}
.checkgroup-example input:hover:not(:disabled) + label:before {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
.checkgroup-example input:disabled + label {
  cursor: not-allowed;
  opacity: var(--disabled);
}
.checkgroup-example input:disabled + label * {
  pointer-events: none;
}
.checkgroup-example input:disabled + label:hover:before {
  background-image: none;
}
.checkgroup-example input + label {
  align-items: flex-start;
  cursor: pointer;
  display: inline-block;
  font-size: var(--font-size-scale-base);
  font-weight: var(--font-weight-medium);
  min-height: var(--spacing-scale-3x);
  position: relative;
}
.checkgroup-example input + label:before {
  background: var(--color-secondary-01, #fff);
  border: 1px solid var(--color-secondary-04);
  border-radius: 4px;
  content: "";
  height: var(--checkbox-size);
  left: 0;
  position: absolute;
  width: var(--checkbox-size);
}
.checkgroup-example input + label:empty {
  padding-left: var(--spacing-scale-3x);
}
.checkgroup-example input[indeterminate] {
  color: var(--color-danger, #e52207);
}
.checkgroup-example input[indeterminate] + label:before {
  background: var(--color-primary-lighten-01);
  border-color: var(--color-primary-lighten-01);
}
.checkgroup-example input:checked + label:after {
  border: solid var(--color-primary-lighten-01);
  border-width: 0 3px 3px 0;
  content: "";
  height: var(--icon-size-sm);
  left: 8px;
  position: absolute;
  top: 4px;
  transform: rotate(45deg);
  width: 8px;
}
.checkgroup-example input:checked[indeterminate] + label:after {
  border-color: var(--color-secondary-01);
  border-width: 0 0 3px;
  top: 2px;
  transform: none;
}
.checkgroup-example input:invalid + label:before {
  border-color: var(--color-danger, #e52207);
  border-width: 2px;
}
.checkgroup-example input:disabled + label:before {
  box-shadow: none;
}
.checkgroup-example.is-small input[type="checkbox"] + label,
.checkgroup-example.small input[type="checkbox"] + label,
.checkgroup-example[small] input[type="checkbox"] + label {
  line-height: var(--spacing-scale-2xh);
  min-height: var(--spacing-scale-2xh);
}
.checkgroup-example.is-small input[type="checkbox"] + label:before,
.checkgroup-example.small input[type="checkbox"] + label:before,
.checkgroup-example[small] input[type="checkbox"] + label:before {
  height: var(--spacing-scale-2xh);
  width: var(--spacing-scale-2xh);
}
.checkgroup-example.is-small input[type="checkbox"]:checked + label:after,
.checkgroup-example.small input[type="checkbox"]:checked + label:after,
.checkgroup-example[small] input[type="checkbox"]:checked + label:after {
  border-width: 0 2px 2px 0;
  height: var(--icon-size-sm);
  left: 7px;
  top: 6px;
  width: 6px;
}
.checkgroup-example.is-small
  input[type="checkbox"]:checked[indeterminate]
  + label:after,
.checkgroup-example.small
  input[type="checkbox"]:checked[indeterminate]
  + label:after,
.checkgroup-example[small]
  input[type="checkbox"]:checked[indeterminate]
  + label:after {
  border-color: var(--color-secondary-01);
  border-width: 0 0 3px;
  top: 2px;
  transform: none;
}
.checkgroup-example.invalid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]
  + label:before,
.checkgroup-example.is-invalid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]
  + label:before,
.checkgroup-example[invalid]:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]
  + label:before {
  border-color: var(--color-danger, #e52207);
  border-width: 2px;
}
.checkgroup-example.invalid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:checked
  + label:before,
.checkgroup-example.invalid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus
  + label:before,
.checkgroup-example.invalid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus:checked
  + label:before,
.checkgroup-example.is-invalid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:checked
  + label:before,
.checkgroup-example.is-invalid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus
  + label:before,
.checkgroup-example.is-invalid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus:checked
  + label:before,
.checkgroup-example[invalid]:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:checked
  + label:before,
.checkgroup-example[invalid]:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus
  + label:before,
.checkgroup-example[invalid]:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus:checked
  + label:before {
  border-color: var(--color-danger, #e52207);
}
.checkgroup-example.is-valid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]
  + label:before,
.checkgroup-example.valid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]
  + label:before,
.checkgroup-example[valid]:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]
  + label:before {
  border-color: var(--color-success, #168821);
  border-width: 2px;
}
.checkgroup-example.is-valid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:checked
  + label:before,
.checkgroup-example.is-valid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus
  + label:before,
.checkgroup-example.is-valid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus:checked
  + label:before,
.checkgroup-example.valid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:checked
  + label:before,
.checkgroup-example.valid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus
  + label:before,
.checkgroup-example.valid:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus:checked
  + label:before,
.checkgroup-example[valid]:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:checked
  + label:before,
.checkgroup-example[valid]:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus
  + label:before,
.checkgroup-example[valid]:not(.is-inverted):not(.inverted):not([inverted])
  input[type="checkbox"]:focus:checked
  + label:before {
  border-color: var(--color-success, #168821);
}
[data-toggle="collapse"]:not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}
[data-toggle="collapse"]:focus {
  outline: none;
}
[data-toggle="collapse"].focus-visible,
[data-toggle="collapse"]:focus-visible {
  outline-color: var(--focus);
  outline-offset: var(--focus-offset);
  outline-style: var(--focus-style);
  outline-width: var(--focus-width);
}
/*# sourceMappingURL=core.min.css.map*/

[data-toggle] span {
  margin-right: 0 !important;
}

.br-dropdown-text {
  display: flex;
  width: 100px;
}

.mobile-logo {
  max-height: 28px !important;
}

#br-header {
  box-shadow: 0px 1px 6px #00000029;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
}

.current-user-dropdown {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 0.2rem;
}

.sign-in-button {
  display: contents;
}

.wpp-icon-mobile {
  margin-left: auto;
  display: block;
  margin-right: 1rem;
  margin-top: 6px;
}

.wpp-icon-fixed {
  position: fixed;
  bottom: 24px;
  right: 24px;
}

.carousel-content img {
  height: 100%;
}

.menu-title a {
  color: var(---gray-80);
}

.default-link u {
  text-decoration: none;
}

.br-decidim-logo img {
  height: 16px !important;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0)
    hue-rotate(243deg) brightness(100%) contrast(105%);
}
.br-decidim-logo {
  display: flex;
  align-items: center;
}

[type="text"],
[type="password"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="email"],
[type="number"],
[type="search"],
[type="tel"],
[type="time"],
[type="url"],
[type="color"],
textarea {
  box-shadow: none;
  border: 0;
}

.black-and-white-btn {
  border-color: var(--gray-90) !important;
  color: var(--gray-80) !important;
  gap: 8px;
}

.large-button {
  width: 100%;
}

.locale-select {
  color: var(--interactive);
  cursor: pointer;
  width: 54px;
  padding-bottom: 7px !important;
  font-weight: 500;
  margin-right: 10px;
  border: 0;
}

.locale-select:focus,
.locale-select:hover {
  background-color: var(--background);
  color: var(--interactive);
}