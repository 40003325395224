.hide-br-list-dropdown {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 0.3s, transform 0.3s, box-shadow 0.2s;
}

.br-list-dropdown {
  box-shadow: none !important;
}

.br-list-dropdown-in-view {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
  transition: box-shadow 3s;
}

.show-br-list-dropdown {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.4s, box-shadow 0.3s;
  -webkit-transition: opacity 0.5s, transform 0.4s, box-shadow 0.3s;
  -moz-transition: opacity 0.5s, transform 0.4s, box-shadow 0.3s;
  -ms-transition: opacity 0.5s, transform 0.4s, box-shadow 0.3s;
  -o-transition: opacity 0.5s, transform 0.4s, box-shadow 0.3s;
}

.foreign-login {
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.foreign-login > div, .foreign-actions-buttons  {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.foreign-login h1 {
  color: var(--blue-warm-vivid-80);
  font-size: var(--font-size-scale-up-03);
  font-weight: var(--font-weight-regular);
  margin: 0;
}

.foreign-login p {
  color: var(--gray-90);
  font-size: var(--font-size-scale-up-01) !important;
  font-weight: var(--font-weight-regular);
  line-height: normal;
  margin: 0;
}

.foreign-actions-buttons {
  margin-top: 2rem;
}

.foreign-actions-buttons a {
  color: var(--blue-warm-vivid-70);
  font-size: var(--font-size-scale-up-01) !important;
  font-weight: var(--font-weight-regular);
}

.foreign-actions-buttons .br-sign-in{
  display: flex;
  border: 1px solid var(--blue-warm-vivid-70);
  gap: 0.5rem;
}

.foreign-actions-buttons .fa-user-large {
  font-size: 1.25rem;
}

.foreign-back-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.foreign-subtitle {
  font-family: Rawline, serif;
  font-size: 1rem;
  font-weight: 400;
  color: var(--gray-90);
}

.foreign-form-explanation {
  font-family: Rawline, serif;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--blue-warm-vivid-70);
}

.login-container {
  display: flex;
  flex-direction: column;
  max-width: 752px;
  margin: 32px auto;
  text-align: center;
  gap: 24px;
  padding: 0 20px;

  h1 {
    margin: 0;
    padding: 0;
    font-size: 34.84px;
  }
  p {
    margin: 0;
    padding: 0;
  }

  @media (min-width: 768px) {
    padding: 0;
  }
}

.govbr-login-container {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 0;
  justify-content: center;

  h1 {
    margin: 0;
    padding: 0;
  }
}

.foreign-login-container {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 32px;
  justify-content: center;

  h2 {
    margin: 0;
    padding: 0;
    color: var(--blue-warm-vivid-80);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 24.19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
  }
  p {
    margin: 0;
    padding: 0;
    color: var(--gray-90);
    text-align: center;
    font-size: 16.8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

#login-form-container {
  text-align: left;
  display: none;
}

.sign_up-card {
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  margin-bottom: 24px;
}

#card__tos {
    margin-bottom: -50px;
}

.user-nickname .help-text {
  margin-top: 0;
}

#registration_user_nickname_characters {
  display: none;
}
