.participatory-text-list {
    text-decoration: none;
    margin: 0;
    padding: 0;
}

.participatory-text-title {
    font-size: 2rem;
}

.participatory-text-item {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
}

.participatory-text-item:nth-child(odd) {
    background-color: var(--gray-3);
}
.participatory-text-item:hover {
    background: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)));
}

.participatory-text-item a h6 {
    margin: 0 0 1rem 0;
    padding: 0;
    font-size: 1.1rem;
    color: var(--blue-warm-vivid-70);
    font-weight: 400;
}

.participatory-text-item button {
    color: var(--interactive);
    gap: 1rem;
}

.participatory-text-item .item-actions {
    display: flex;
    gap: 0.7rem;
    flex-direction: row;
    justify-content: flex-end;
}