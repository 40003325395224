.meetings-footer-components .section.documents {
  display: flex;
  justify-content: center;
  background-color: var(--blue-3);
  padding: 33px;
  width: 100%;
}

.download-attachment {
  width: 15px;
  height: 15px;
}

.card.br-card--list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  gap: 24px;
  background-color: var(--blue-3);
}

.card.br-card--list .br-card--list__data {
  border-radius: 10px;
  align-items: center;
}

.documents {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.documents .card {
  border-radius: 0px;
  border: none;
  box-shadow: none;
}

.br-card--list__heading.heading6 {
  display: block;
  align-items: center;
  align-content: center;
  text-transform: none;
  color: var(--blue-warm-vivid-60);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Rawline;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75%;
  white-space: nowrap;

  @media (max-width: 400px) {
    max-width: 100px;
  }
}

.br-card--list__item {
  min-width: 200px;
  display: flex;
  border-radius: 10px;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid var(--blue-warm-vivid-70);
  gap: 12px;
}

.br-card--list__text {
  display: flex;
  padding: 0px;
  max-height: 40px;
  text-align: center;
  align-items: center;
  color: var(--blue-warm-vivid-70);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Rawline;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
}

.br-card--list__data__icon {
  display: flex;
  align-items: center;
  color: var(--blue-warm-vivid-70);
}

.br-message.info {
  margin-top: 20px;
}

.card__link {
  display: flex;
  align-items: center;
}

.user-follow-stats .card__link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-follow-stats .card__link strong {
  color: var(--gray-60);
}

.doc_extension {
  text-transform: lowercase;
  text-align: left;
}

.attachments.images {
  background-color: white;
}

.section.images h3 {
  font-style: normal;
  font-weight: 600;
}

.attachments {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.attachment-item {
  display: flex;
  align-items: center;
  border: 1px solid var(--blue-3);
  border-radius: 8px;

  .download-button {
    display: flex;
    justify-content: center;
    width: 368px;
    align-items: center;
    padding: 1.5rem 0.5rem;
    gap: 8px;

    span {
      font-size: var(--font-size-scale-up-01);
      font-weight: var(--font-weight-semi-bold);
    }

    i.fa-solid {
      font-size: 1.25rem;
    }
  }
}

.attachment-file {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
  padding: 1rem;
  background-color: var(--blue-3);
  gap: 0.5rem;

  h4 {
    color: var(--blue-warm-vivid-80);
    font-weight: var(--font-weight-semi-bold);
    font-size: 1.25rem;
    text-align: left;
    padding: 0;
    margin: 0;
  }
  p {
    color: #1B1B1B;
    font-size: 1rem;
    font-weight: var(--font-weight-regular);
    text-align: left;
    margin: 0;
  }
  span {
    color: var(--blue-warm-vivid-70, #1351b4);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
}

@media (max-width: 768px) {
  .attachment-item {
    flex-direction: column;
    align-items: center;
  }
  .download-button {
    width: 328px;
  }
}

.gallery-photo {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.thumbnail {
  margin-bottom: 0.5rem;
}