.br-carousel-container {
  display: flex;
  justify-content: center;
}

.br-carousel-container {
  display: flex;
  justify-content: center;
}

.br-carousel .carousel-button .carousel-btn-next i.fas,
.br-carousel .carousel-button .carousel-btn-prev i.fas {
  font-size: var(--carousel-buton-icon-size);
  color: white;
}