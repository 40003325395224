/* css for decidim_ej */

@import "stylesheets/ej_application";

body>* {
  font-family: Rawline;
}

.card__grid:hover {
  --tw-ring-color: unset !important;
}

.loader {
  width: 25px;
  height: 25px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  display: none;
}

.ej-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  h1,
  h2,
  h3,
  p {
    margin: 0;
    padding: 0;
  }
}

.ej-stats__number {
  color: var(--gray-80, #333);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ej-stats__description {
  color: var(--gray-80, #333);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.new-comment-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .submit-new-comment-btn {
    align-self: flex-end;
  }
}

.share-ej-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ej-conversation-container {
  width: 100%;
}

.ej-conversation-card {
  display: flex;
  padding: 16px 8px;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
  background: var(--gray-04, #f3f3f3);
  width: 100%;

  &__com &__stats {
    display: flex;
    gap: 0.75rem;

    div {
      display: flex;
      gap: 0.75rem;
    }
  }

  &__title {
    margin-top: 1rem;

    div:first-child {
      color: $gray-color;
      text-transform: uppercase;
    }

    div:nth-child(2) {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__comment {
    color: var(--pure-100, #000);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0;
    margin: 0;
  }

  &__votes {
    display: flex;
    flex-direction: column;
    gap: 8px;

    button {
      flex: 1;
      gap: 8px;
      text-transform: capitalize;
      padding: 10px;
    }

    button[id="agree"] {
      background-color: $green-color;
      color: $white-color;
    }

    button[id="disagree"] {
      background-color: $red-color;
      color: $white-color;
    }

    button[id="skip"] {
      background: transparent;
      color: var(--gray-80, #333);
      border: 1px solid var(--gray-80);
    }
  }
}

.ej-conversation-header {
  &__title {
    color: var(--gray-90, #1b1b1b);
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__subtitle {
    color: var(--gray-60, #636363);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 1.26rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.ej-conversation-stats__card {
  display: flex;
  padding: 24px;
  gap: 16px;
  flex: 1;
  border-radius: 4px;
  background: var(--blue-warm-vivid-5);
}

.ej-conversation__general-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;

  i {
    color: var(--blue-warm-vivid-70, #1351b4);
    margin-top: 6px;
  }
}

.ej-conversation-stats__card-content {
  display: flex;
  flex-direction: column;
}

.ej-conversation-card__comments {
  color: var(--gray-60, #636363);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Rawline;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.conversation-quantity {
  margin: 0 0 40px 0;
}

@media (min-width: 740px) {
  .ej-conversation-card {
    padding: 48px 40px;

    &__votes {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .ej-conversation__general-stats {
    flex-direction: row;
    width: 100%;
  }

  .ej-component-container {
    align-items: flex-start;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.percentage-container {
  display: flex;
  flex-direction: column;
}

.percentage-title {
  color: var(--blue-warm-vivid-80, #0c326f);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Rawline;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  line-height: normal;
  margin: 0;
}

.percentage-bar {
  background-color: var(--blue-warm-vivid-60);
  height: 10px;
  flex-shrink: 0;
}

.conversation-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 352px);
  grid-gap: 24px;
  justify-content: center;
}

.conversation-card {
  box-shadow: 0px 3px 6px 0px #33333329;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;
  border-radius: 4px;
  border-top: solid 8px;
  border-top-color: #155bcb;
  gap: 16px;

  >.conversation-card-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #1351b4;
  }

  >.conversation-profile-info {
    display: flex;
    gap: 8px;
    margin-top: auto;

    >img {
      border-radius: 100%;
      width: 20px;
      height: 20px;
    }

    >p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0px;
      color: #1351b4;
    }
  }

  >.conversation-theme {
    padding: 4px 8px;
    margin: 8px 0px;
    background: #f8f8f8;

    >p {
      color: #1351b4;
      margin: 0;
      font-size: 12px;
      font-weight: 400;
    }
  }

  >.conversation-participants {
    display: flex;
    flex-direction: column;
    align-items: center;

    >p:first-child {
      margin-bottom: 4px;
      font-size: 20px;
      font-weight: 600;
    }

    >p:last-child {
      font-size: 14px;
      font-weight: 400;
    }
  }

  >.conversation-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0;

    >p {
      margin-bottom: 8px;
      color: var(--blue-warm-vivid-80);
      font-size: 14px;
      font-weight: 600;
    }

    >.conversation-progress-bar {
      height: 10px;
      width: calc(100% + 32px);
      border-top: solid 1px;
      border-bottom: solid 1px;
      border-color: #e6e6e6;

      >.percentage-bar {
        height: 100%;
        background: #155bcb;
      }
    }
  }
}

.ej-button {
  --button-radius: 100em;
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;

  border: 1px solid var(--blue-warm-vivid-70);
  text-transform: none;
  align-items: center;
  border: 0;
  border-radius: var(--button-radius);
  color: var(---white);
  cursor: pointer;
  display: inline-flex;
  font-weight: var(--font-weight-semi-bold);
  height: var(--button-medium);
  justify-content: center;
  overflow: hidden;
  padding: 0 var(--button-xsmall);
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: wrap;
  width: auto;
  gap: 10px;
  line-height: 1.1rem;
  margin: 0;
  background-color: #335bae;
}

.ej-button:hover {
  color: white;
  background-image: linear-gradient(rgba(var(--interactive-rgb-primary-btn), var(--hover)),
      rgba(var(--interactive-rgb-primary-btn), var(--hover)));
}

.home-sections-container h1,
.home-sections-container h2,
.home-sections-container h3,
.home-sections-container h4,
.home-sections-container h5,
.home-sections-container p {
  margin: 0 !important;
  padding: 0 !important;
}

#participatory-process-section {
  background-color: var(--blue-3);
}

.home-sections-container .home-sections-content {
  margin: 0 auto;
  width: auto;
}

#service-channels-home-section {
  background-color: var(--blue-warm-vivid-80);
}

.wrapper {
  padding: 0 !important;
}

.home-sections-container {
  background-color: var(--pure-0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 24px 16px;
}

.home-sections-container h2 {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.home-sections-container p {
  width: auto;
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.video-text-card {
  flex: 1;
}

@media (min-width: 1045px) {
  .home-sections-container {
    padding: 64px 40px;
  }

  .home-sections-container p {
    width: auto;
  }

  .home-sections-container .home-sections-content {
    margin: 0 auto;
    max-width: 1470px;
  }
}

.current {
  border: 2px solid var(--blue-warm-vivid-70);
}

.current .stage-text,
.step-process-card__content.current h3,
.step-process-card__content.current i {
  color: var(--blue-warm-vivid-80);
}

.step-process-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 16px;
  justify-content: center;
}

.step-process-card {
  display: flex;
  flex-direction: column;
  background-color: var(--pure-0);
  height: 100%;
  border-radius: 4px;
  border: 1px solid #a3b3cc;
}

.step-process-card__content {
  display: flex;
  width: 210px;
  height: 180px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  padding: 16px;
  border-radius: 4px;
}

.stage-text {
  font-size: 12px;
}

.step-process-card__content i {
  color: var(--gray-90);
  height: 14px;
}

.step-process-card__content h3 {
  color: var(--gray-90);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Rawline;
  font-size: 16.8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.step-process-card__content p {
  color: var(--gray-90);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 11.67px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 260px;
}

.step-process-card__content .step-date {
  color: var(--gray-70);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Rawline;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: auto;
}

.step-process-card__footer {
  display: flex;
  background: var(--blue-warm-vivid-80);
  color: var(--pure-0);
  font-weight: 600;
  justify-content: center;
  padding: 8px;
}

@media (min-width: 1045px) {
  .step-process-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }

  .step-arrow {
    transform: rotate(-90deg);
  }
}

.white-divider {
  display: flex;
  width: 100%;
  height: 35px;
  background-image: url(https://brasilparticipativo.presidencia.gov.br/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbDVXIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--057c192d3fe1e615a252b7269b8a8e87fb38f13b/faixa-branca.png);
  background-size: cover;
  background-repeat: repeat;
  background-color: var(--blue-3);
}

.ej-hero-info-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-wrap: wrap;
}

.ej-hero-info-img {
  object-fit: cover;
  flex: 1;
  border-radius: 8px;
  max-width: 558px;
  height: 241px;
  background: var(--gray-2, #f8f8f8);
}

.hero-info-right-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 16px;
}

.hero-info-right-content h2 {
  text-align: start;
}

.hero-info-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.process-home-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.hero-info-right-content p {
  text-align: left;
}

.hero-info-actions a {
  width: 100%;
}

@media (min-width: 768px) {
  .hero-info-actions {
    flex-direction: row;
  }

  .hero-info-actions a {
    width: fit-content;
  }

  .ej-hero-info-content {
    flex-direction: row;
  }
}

.ej-home-survey-container {
  flex-direction: column;
  padding: 2rem 0;
  background-color: var(--gray-2);
}

#comment-div-form {
  background-color: var(--pure-0);
  padding: 1rem;
  border-radius: 16px;
  margin-top: -20px;
}

.feedback-login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2.5rem;
}

.first-content-container {}

.second-content-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  max-width: 461px;
  padding: 18px;

  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 44.14px;
    text-align: left;
    color: var(--gray-80);
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.15px;
    text-align: left;
    color: var(--gray-80);
    margin: 0;
    padding: 0;
  }

  a {
    margin-top: 40px;
  }
}