
.data-picker{
  margin: 0 0 $global-margin;

  &.picker-single .picker-values div a,
  .picker-prompt a{
    background: white;
    cursor: pointer;
    display: block;
    width: 100%;
    padding: .4rem .7rem;
    outline: $input-outline;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(26, 24, 29, .1);
    -webkit-box-shadow: inset 0 1px 2px rgba(26, 24, 29, .1);
    color: #1a181d;

    &:active,
    &:focus{
      outline: $input-outline-focus;
      outline-color: var(--highlight);
      outline-offset: $input-outline-offset;
      border: 1px solid #1a181d;
      background-color: #fff;
      box-shadow: 0 0 5px #adadad;
      -webkit-box-shadow: 0 0 5px #adadad;
      transition: border-color .25s ease-in-out, -webkit-box-shadow .5s;
      -webkit-transition: border-color .25s ease-in-out, -webkit-box-shadow .5s;
    }

    &::after{
      display: block;
      width: 0;
      height: 0;
      border: inset 5px;
      content: '';
      border-right-width: 0;
      border-color: transparent transparent transparent #1a181d;
      float: right;
      margin-top: 7px;
    }
  }

  &.is-invalid-input.picker-single .picker-values div a,
  &.is-invalid-input .picker-prompt a{
    border-color: $alert-color;
    background-color: #fdeeec;
  }

  &.picker-single .picker-values:not(:empty) + .picker-prompt{
    display: none;
  }

  &.disabled.picker-single .picker-values div a,
  &.disabled .picker-prompt a{
    cursor: not-allowed;
    background: #eee;

    &:active{
      border: inherit !important;
      box-shadow: inherit !important;
      -webkit-box-shadow: inherit !important;
      transition: none !important;
      -webkit-transition: none !important;
    }
  }

  &.picker-multiple .picker-values{
    margin-top: 0.25rem;
  
    div{
      display: flex;
      width: 100%;
      height: 2.5rem;
      margin: 0;

      input{
        margin: 2px 8px 8px 2px;
        vertical-align: top;
      }
    }
  }

  .picker-values{
    .label.primary{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--gray-30); 
      color: var(--blue-warm-vivid-60);
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-regular);
      background: var(--pure-0);
      padding: 0.75rem 1rem;
      width: inherit;
      margin: 0;
      gap: 1rem;
    
      .fa-circle-xmark {
        font-size: 1.25rem;
      }
    }
  }
}

#data_picker-modal{
  height: 90%;

  .data_picker-modal-content{
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-height: 100%;

    .picker-content{
      flex-grow: 10;
      min-height: 100%;
      max-height: 100%;
      overflow: auto;

      .picker-scrollable-content{
        max-height: 100%;
        overflow: auto;
      }
    }

    h1 {
      color: var(--blue-warm-vivid-70);
      font-size: 1.25rem;
      font-weight: var(--font-weight-semi-bold);
      margin: 0;
    }
    
    #proposals_filter {
      border: 0;
      border-radius: 8px;
      background: var(--gray-2);
      margin: 1.25rem 0;
      overflow: hidden;
      color: var(--pure-100);
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-style: italic;
      font-weight: var(--font-weight-regular);
    }

    .picker-footer {
      margin-top: 1rem;
    }
  }
}

.picker-list{
  ul{
    margin-left: 0;

    li{
      list-style: none;
    }
  }
}

.picker-list::-webkit-scrollbar {
  width: 0.5rem;
}

.picker-list::-webkit-scrollbar-track {
  background-color: var(--gray-3);
}

.picker-list::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: var(--gray-20);
}

.scope-picker{
  &.picker-header{
    padding: 5px;

    li:hover{
      background: #ddd;
    }

    li ~ li a::before{
      content: '';
      width: 10px;
      height: 2em;
      float: left;
    }

    li:last-child a{
      color: #333;
    }

    a{
      display: block;
      padding: 2px;
    }
  }

  &.picker-content{
    .picker-scrollable-content{
      scroll-snap-type: mandatory;

      a{
        scroll-snap-coordinate: 0 0;
        display: block;
        border-top: 1px solid #ddd;
        line-height: 1.5rem;
        padding: 5px;

        &:hover{
          background: #ddd;
        }
      }
    }
  }
}

#participatory-space-filters{
  legend{
    float: left;
    padding-top: 10px;
  }

  .picker-prompt,
  select{
    width: 75%;
    float: right;
  }

  .filters__section{
    padding: 0;
  }

  .picker-values{
    clear: both;

    div{
      display: inline-block;
    }
  }
}

.buttons.button--double {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.buttons.button--double .button,
.buttons.button--double .button.hollow:hover {
  min-width: fit-content;
}
