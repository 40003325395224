.container-title-header {
    padding: 0px;
    margin: 0px 0px 8px 0px;
    color: #1B1B1B;
    font-size: 32px;
    font-weight: 600;
}

.container-subtitle-header {
    padding: 0px;
    margin: 0px 0px 16px 0px;
    font-size: 20px;
    color: #636363;
}

.container-conversation {
    margin: 0 auto;
}
