.comments-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.comment-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    box-shadow: 0px 3px 6px 0px #33333329;

    .comment-status-tag {
        display: flex;

        >p {
            padding: 4px 8px;
            border-radius: 30px;
            border: 2px solid;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .comment-approved>p {
        border-color: var(--green-vivid-60);
        color: var(--green-vivid-60);
    }

    .comment-rejected>p {
        border-color: var(--red-vivid-60);
        color: var(--red-vivid-60);
    }

    .comment-pending>p {
        border-color: var(--orange-vivid-50);
        color: var(--orange-vivid-50);
    }

    .comment-content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .comment-title {
            font-weight: 600;
            font-size: 20px;
            color: var(--secondary);
        }

        .comment-subtitle {
            font-weight: 600;
            font-size: 16px;
            color: var(--color-secondary-07);
        }
    }


    .comment-info {
        font-weight: 500;
        font-size: 14px;
        color: var(--color-secondary-07);
    }
}