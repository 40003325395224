.participatory-process-card {
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}

.participatory-process-card-title {
  color: var(--blue-warm-vivid-80);
  font-size: 1rem;
  font-weight: 600;
}
