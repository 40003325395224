.home_steps-container {
  display: flex;
  padding: 4rem 0;
  gap: 1.5rem;
}
.steps-section-background {
  background-color: var(--gray-1);
}

.steps_main-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 50%;
}

.steps_side-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.steps_main-container .steps_title {
  color: var(--blue-warm-vivid-80);
  font-weight: var(--font-weight-bold);
  font-size: 2rem;
  padding: 0;
  margin: 0;
}

.steps_main_text {
  text-align: justify;
  color: var(--gray-60);
  font-size: var(--font-size-scale-up-02) !important;
  font-weight: var(--font-weight-regular);
  margin: 0;
}

.step_info-container {
  display: flex;
  align-items: center;
  min-height: 100px;
  padding: 0 1rem;
}

.blue-step-container {
  display: flex;
  align-items: center;
  min-height: 100px;
  background: var(--blue-warm-vivid-5);
}

.step_index {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  text-align: center;
  min-height: 100%;
  min-width: 81px;
}

.step_index-circle {
  display: flex;
  width: 54px;
  height: 54px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: var(--pure-0);
  font-size: 24.19px;
  font-weight: var(--font-weight-medium);
}

.step_index-circle.gray-index {
  border: 1px solid var(--pure-0);
  background: var(--gray-30);
}

.step_index-circle.blue-index {
  border: 1px solid var(--pure-0);
  background: var(--blue-warm-vivid-80);
}

.step_index-circle.write-index {
  color: var(--gray-80);
  border: 1px solid var(--gray-80);
  background: var(--pure-0);
}

.index-line {
  width: 2px;
  background-color: var(--gray-70);
  flex-grow: 1;
}

.hollow-index-line {
  width: 2px;
  background-color: var(--pure-0);
  flex-grow: 1;
}

.step_info-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0;
}

.step_info-text h2 {
  font-size: var(--font-size-scale-up-01) !important;
  font-weight: var(--font-weight-semi-bold);
  padding: 0;
  margin: 0;
}

.step_info-text p {
  font-size: var(--font-size-scale-down-01) !important;
  font-weight: var(--font-weight-regular);
  margin: 0;
}

.step_info-text.gray-step h2,
.step_info-text.gray-step p {
  color: var(--gray-70);
}

.step_info-text.blue-step h2,
.step_info-text.blue-step p {
  color: var(--blue-warm-vivid-80);
}

.step_info-text.write-step h2 {
  color: var(--gray-80);
}

.step_info-text.write-step p {
  color: var(--gray-90);
}

.steps_side-container .steps_title {
  display: none;
}

@media(max-width: 768px) {
  .steps_side-container .steps_title {
    display: inline;
    text-align: center;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem;
    margin-top: 0;
    padding: 0;
    color: var(--blue-warm-vivid-80);
  }
  .steps_main-container {
    display: none;
  }
  .steps_side-container {
    width: 100%;
  }
}
