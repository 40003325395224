.docs__container {
  .docs-collection-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-20);
    padding: 1rem;
    height: 3.75rem;
    width: 100%;

    strong {
      color: var(--blue-warm-vivid-80);
      font-size: var(--font-size-scale-up-02)!important;
      font-weight: var(--font-weight-regular);
    }

    i.fa-solid {
      font-size: 1.25rem;
      color: var(--blue-warm-vivid-70);
    }
  }

  .docs-collection-label[aria-expanded~="false"] {
    border-bottom: none;
    i.fa-solid {
      transform: rotate(180deg);
    }
  }

  .docs-collection-label:focus {
    outline: none;
  }

  h6{
    cursor: pointer;
  }

  svg{
    color: $medium-gray;
  }

  .docs__content{
    border-left: 0;
    padding-left: 0;
    margin-left: 0;

    .card--list {
      display: flex;
      flex-direction: column;
      margin: 8px 0 0 0;
      gap: 1rem;
    }
  }
}

.docs__header-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  gap: 2rem;

  .br-magic-button {
    margin: 0;
  }
}

.docs__title-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .section-heading {
    font-size: 2rem;
    font-weight: var(--font-weight-semi-bold);
    color: var(--gray-90);
    text-align: left;
    margin: 0;
  }

  p {
    font-size: var(--font-size-scale-up-02) !important;
    font-weight: var(--font-weight-regular);
    text-align: left;
    margin: 0;
  }
}
