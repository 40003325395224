.home_basic_logos__section {
  padding: 2rem 1rem;
}
.home_basic_logos__container {
  max-width: 900px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 42px;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
}

@media (min-width: 870px) {
  .home_basic_logos__section {
    padding: 2rem 1rem 0 1rem;
  }
  .home_basic_logos__container {
    max-width: 900px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 42px;
    margin: 0 auto;
    flex-wrap: nowrap;
    align-items: center;
  }
}
.custom-home-sections-container h2 {
  text-align: center;
}

.custom-home-sections-container .custom-home-sections-content {
  margin: 0 auto;
  width: auto;
}
@media (min-width: 768px) {
  .custom-home-sections-container .custom-home-sections-content {
    margin: 0 auto;
    max-width: 1152px;
  }
}
