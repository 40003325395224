.call_to_action-container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding: 4rem 0;
}

.call_to_action-container.reversed {
  flex-direction: row-reverse;
}

.action_img-container {
  min-height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
  width: 50%;
  height: 20rem;
}

.action_img-container .home__video-iframe {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  min-height: inherit;
}

.action_content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  width: 50%;
}

.action_content-container .action_link {
  font-size: var(--font-size-scale-up-01) !important;
  font-weight: var(--font-weight-semi-bold);
  text-align: center;
  align-self: end;
}
.action_content-container .action_link .primary {
  color: var(--pure-0);
}

.action_content-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.action_button_container {
  display: flex;
  justify-content: end;
  gap: 1rem;

  a {
    min-width: 30%;
  }
}

.action_title {
  font-size: 2rem;
  color: var(--blue-warm-vivid-80);
  font-weight: var(--font-weight-bold);
  padding: 0;
  margin: 0;
  word-wrap: break-word;
}

.action_text {
  text-align: justify;
  color: var(--gray-60);
  font-size: 18px !important;
  font-weight: var(--font-weight-regular);
  margin: 0;
  line-height: 1.3;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .call_to_action-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem 0rem;
  }
  .call_to_action-container.reversed {
    flex-direction: column;
  }
  .action_img-container,
  .action_content-container,
  .action_content-container .action_link {
    width: 100%;
  }

  .action_button_container {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .action_content-container .action_link {
    max-width: 50%;
  }
}

.action_img_content {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}