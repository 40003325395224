.process-header {
  .heading2 {
    font-weight: bold;
  }
}

.process-header__inner {
  position: relative;

  height: 220px;
  background-color: var(--color-secondary-01);
  @include flex;
  @include flex-align($x: center);
}

.process-header__main {
  background-size: initial;
  background-position: center;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media (max-width: 820px) {
    width: 100%;
  }
}

.process-header__container {
  position: relative;
  z-index: 1;

  @include breakpoint(mediumlarge) {
    display: flex;
    align-items: center;
  }
}

.process-header__info {
  padding: 1rem 1.5rem 1rem 1rem;

  @include breakpoint(medium) {
    padding: 2rem 1.5rem 2rem 1rem;
  }

  @include breakpoint(mediumlarge) {
    padding-left: 2rem;
  }
}

.process-header__hashtag {
  font-weight: 600;

  &::after {
    content: "·";
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.process-header__nav {
  margin-top: auto;
  z-index: 1;
}
